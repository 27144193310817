package org.botdesigner.ui.screens

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTopAppBar
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.ui.common.NavigateBackButton
import org.botdesigner.ui.screens.content.SectionContextMaxWidth
import org.botdesigner.ui.theme.Dimens

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal fun PreferencesScreen(
    onBack : () -> Unit,
    modifier: Modifier = Modifier,
    title : String,
    content : @Composable ColumnScope.() -> Unit
) {
    val scrollState = rememberScrollState()
    val density = LocalDensity.current

    val isTopBarTransparent by remember(scrollState, density) {
        derivedStateOf {
            scrollState.value < density.run { Dimens.Spacing.ExtraLarge.toPx() }
        }
    }

    AdaptiveScaffold(
        modifier = modifier,
        containerColor = MaterialTheme.colorScheme.background,
        topBar = {
            AdaptiveTopAppBar(
                navigationIcon = {
                    if (LocalInterfaceIdiom.current == InterfaceIdiom.Mobile) {
                        NavigateBackButton(onClick = onBack)
                    }
                },
                title = {
                    Text(title)
                },
                adaptation = {
                    cupertino {
                        isTranslucent = CurrentPlatform.isMobile
                        isTransparent = isTranslucent && isTopBarTransparent
                    }
                }
            )
        }
    ) {

        Box(
            modifier = Modifier.fillMaxSize(),
            contentAlignment = Alignment.TopCenter
        ) {
            Column(
                Modifier
                    .verticalScroll(scrollState)
                    .padding(top = Dimens.Spacing.ExtraLarge)
                    .padding(it)
                    .widthIn(max = SectionContextMaxWidth),
                content = content
            )
        }
    }
}