package org.botdesigner.shared.util.managers

import androidx.compose.runtime.Immutable
import dev.icerock.moko.resources.desc.StringDesc
import io.github.alexzhirkevich.onetap.NativeViewController
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import org.botdesigner.api.SubscriptionType
import org.botdesigner.shared.util.runCatchingIgnoringCancellation

expect class SubscriptionPayload

@Immutable
data class Subscription(
    val type: SubscriptionType,
    val productId : String,
    val price: StringDesc?,
    val freeTrialDays : Int?,
    val payload: SubscriptionPayload?
)

class SubscriptionException(msg : String) : Exception(msg)

abstract class AbstractSubscriptionManager : SubscriptionManager {

    override fun offerings(): Flow<List<Subscription>> = flow {

        val defaultSubs by lazy {
            listOf(
                SubscriptionType.Premium,
                SubscriptionType.Pro
            ).map {
                Subscription(
                    type = it,
                    price = null,
                    payload = null,
                    freeTrialDays = null,
                    productId = ""
                )
            }
        }

        runCatchingIgnoringCancellation {
            emit(
                loadOfferings()
                    .sortedBy { it.type.ordinal }
                    .takeIf { it.isNotEmpty() }
                    ?: defaultSubs
            )
        }.onFailure {
            emit(defaultSubs)
        }
    }

    protected abstract suspend fun loadOfferings(): List<Subscription>
}

interface SubscriptionManager {

    suspend fun managementUrl() : String?

    suspend fun canMakePayments() : Boolean

    suspend fun login(userId: String)

    suspend fun logout()

    fun configure(application: NativeApplication, userId : String?)

    suspend fun purchase(viewController: NativeViewController, subscription: SubscriptionPayload)

    fun offerings() : Flow<List<Subscription>>

    object Empty : AbstractSubscriptionManager() {
        override suspend fun managementUrl(): String? = null

        override suspend fun canMakePayments(): Boolean  = false

        override suspend fun login(userId: String) {
        }

        override suspend fun logout() {
        }

        override fun configure(application: NativeApplication, userId: String?) {
        }

        override suspend fun purchase(
            viewController: NativeViewController,
            subscription: SubscriptionPayload
        ) {}

        override suspend fun loadOfferings(): List<Subscription> = error("Not available")
    }
}