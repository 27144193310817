package org.botdesigner.shared.domain.content

import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import org.botdesigner.resources.SharedRes

interface AlertConfirmationDialogComponent : ConfirmationDialogComponent {

    val title: StringDesc
    val message: StringDesc?
    val confirm : StringDesc

    val isDestructive : Boolean

    class Default(
        private val onConfirm: () -> Unit,
        private val onCancel: () -> Unit,
        override val title: StringDesc = SharedRes.strings.confirm.desc(),
        override val message: StringDesc? = null,
        override val confirm: StringDesc = SharedRes.strings.confirm.desc(),
        override val isDestructive: Boolean = false
    ) : AlertConfirmationDialogComponent,
        ConfirmationDialogComponent by ConfirmationDialogComponent.Default(
            onConfirm = onConfirm,
            onCancel = onCancel
        )
}


interface ConfirmationDialogComponent {

    fun onConfirm()
    fun onCancel()

    class Default(
        private val onConfirm: () -> Unit,
        private val onCancel: () -> Unit,
    ) : ConfirmationDialogComponent {
        override fun onConfirm() {
            onConfirm.invoke()
        }

        override fun onCancel() {
            onCancel.invoke()
        }
    }
}