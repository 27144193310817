package org.botdesigner.shared.util.managers

import io.github.alexzhirkevich.onetap.NativeViewController

interface AdManager {

    val isAvailable : Boolean

    val isLoading : Boolean

    val isReady : Boolean

    suspend fun load()

    suspend fun show(nativeActivity: NativeViewController)

    object Empty : AdManager {

        override val isAvailable: Boolean
            get() = false

        override val isLoading: Boolean
            get() = false

        override val isReady: Boolean
            get() = false

        override suspend fun load() {
        }

        override suspend fun show(nativeActivity: NativeViewController) {
        }
    }
}