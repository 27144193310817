@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.special

import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Boolean
import kotlin.Pair
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlin.collections.Map
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpExpression
import org.botdesigner.blueprint.components.functions.ExpressionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpReplaceNull")
public class BpReplaceNull(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.components.GenericPinFactory.create(
              id = Id("0"),
              elementId = elementId,
              order = o,
              name = "Nullable",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.components.GenericPinFactory.create(
              id = Id("1"),
              elementId = elementId,
              order = o,
              name = "Replace",
              isOut = i,
              required = true
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.components.GenericPinFactory.create(
                  id = Id("r"),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
) : BpExpression() {
  override val summary: AnnotatedString
    get() =
        "Returns <b>Nullable</b> if it is not null and <b>Replace</b> otherwise".trimIndent().toAnnotatedString()

  override val pinTypeDerivation: Map<Id, Pair<Id, Boolean>>
    get() = mapOf(
    Pair(Id("1"), Pair(Id("0"), false)),
    Pair(Id("r"), Pair(Id("0"), false))
    )

  override val factory: ExpressionFactory
    get() = ::BpReplaceNull

  override val expression: String
    get() = "??"

  override suspend fun calculate(input: List<Any?>): List<Any?> = listOf(ReplaceNull(
  		input[0] as kotlin.Any?,
  		requireNotNull(input[1] as kotlin.Any?) { 
  "Replace can't be null" 
  }
  	))
}
