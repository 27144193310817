package org.botdesigner.blueprint.components

import androidx.compose.runtime.Composable
import org.botdesigner.blueprint.ui.components.isInPreviewMode


interface BlueprintNodesPool {

    fun getElementById(id: Id): BlueprintNode

    val elements: List<BlueprintNode>
}

@Composable
fun BlueprintNodesPool.isPinActive(pin: Pin<*>): Boolean {
    return !isInPreviewMode() && !pin.isOut && pin.parentId != null
}

fun BlueprintNodesPool(map : Map<Id, BlueprintNode>) = object : BlueprintNodesPool{
    override fun getElementById(id: Id): BlueprintNode = requireNotNull(map[id]){
        "Element with id=$id not found"
    }

    override val elements: List<BlueprintNode> = map.values.toList()
}