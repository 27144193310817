package org.botdesigner.blueprint.components

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.ui.pins.BasePinWidget

@kotlinx.serialization.Serializable
abstract class Pin<out T> : Identifiable, Ordered, BiDirectional, Named {

    abstract val color: Color
    abstract val elId : Id
    abstract val value : T?
    abstract val required: Boolean
    abstract val parentId : Id?
    abstract val parentPinId : Id?

    open val isUtil : Boolean = false

    open fun fits(other : Pin<*>) : Boolean{
        return other::class == this::class
    }

    open fun valueToScript() : String{
        return value.toString()
    }

    abstract fun factory(
        pool: BlueprintNodesPool
    ) : PinFactory<T>

    @Composable
    open fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged : (String) -> Unit,
        onTap : (Pin<*>) -> Unit,
        value: String?,
        isSelected : Boolean,
        pool: BlueprintNodesPool
    ) {
        BasePinWidget(
            color = color,
            name = name,
            isOut = isOut,
            required = required,
            isSelected = isSelected,
            isActive = pool.isPinActive(this@Pin),
            onTap = {onTap(this@Pin)},
            modifier = modifier,
        ){}
    }


    abstract fun withReference(parent : Pin<*>?) : Pin<T>
    abstract fun withValue(value : String) : Pin<T>


}

interface PinFactory<out T> {

    fun array(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean = false,
    ): ArrayPin<T>? = null

    fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean = false,
    ): Pin<T>
}

fun Pin<*>.parentPin(pool: BlueprintNodesPool) : Pin<*>? {
    if (isOut)
        return null

    return parentId
        ?.let(pool::getElementById)
        ?.pins?.firstOrNull {
            it.id == parentPinId
        }
}