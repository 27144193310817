package org.botdesigner.ui.common

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.width
import androidx.compose.material3.DividerDefaults
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.github.alexzhirkevich.cupertino.CupertinoVerticalDivider
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveWidget


@Composable
internal fun AdaptiveVerticalDivider(
    modifier : Modifier = Modifier
) {
    AdaptiveWidget(
        cupertino = {
            CupertinoVerticalDivider(modifier)
        },
        material = {
            VerticalDivider(modifier)
        }
    )
}

