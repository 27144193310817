package org.botdesigner.shared.util.managers

import com.russhwolf.settings.nullableString
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.auth.authProvider
import io.ktor.client.plugins.auth.providers.BearerAuthProvider
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.HttpStatusCode
import kotlinx.coroutines.launch
import kotlinx.coroutines.supervisorScope
import org.botdesigner.api.auth.RefreshTokenRequest
import org.botdesigner.api.auth.RefreshTokenResponse
import org.botdesigner.shared.util.SecureSettings

interface RefreshTokenManager {

    val accessToken: String?
    val refreshToken: String?

    suspend fun saveTokens(accessToken : String, refreshToken : String)

    suspend fun refresh(notificationToken : String, client: HttpClient)

    suspend fun clearTokens(client: HttpClient)

    fun addOnExpiredRefreshTokenListener(listener : suspend () -> Unit)
}

internal class RefreshTokenManagerImpl(
    settings: SecureSettings
) : RefreshTokenManager {

    private var _accessToken by settings.nullableString("org.botdesigner.access_token")
    override val accessToken: String? get() = _accessToken

    private var _refreshToken by settings.nullableString("org.botdesigner.refresh_token")
    override val refreshToken: String? get() = _refreshToken

    private val listeners = mutableListOf<suspend () -> Unit>()

    override suspend fun saveTokens(accessToken: String, refreshToken: String) {
        this._accessToken = accessToken
        this._refreshToken = refreshToken
    }

    private suspend fun notifyListeners(){
        supervisorScope {
            listeners.forEach { launch { it.invoke() } }
        }
    }

    override suspend fun refresh(notificationToken : String, client: HttpClient) {
        val token = refreshToken ?: return notifyListeners()

        try {
            val resp = client.post("/v1/auth/token") {
                setBody(
                    RefreshTokenRequest(
                        token = token,
                        notificationToken = notificationToken
                    )
                )
            }.body<RefreshTokenResponse>()

            _accessToken = resp.accessToken
            _refreshToken = resp.refreshToken
        } catch (th: ClientRequestException) {
            if (th.response.status == HttpStatusCode.BadRequest ||
                th.response.status == HttpStatusCode.Unauthorized
            ) {
                clearTokens(client)
                notifyListeners()
            }
            throw th
        }
    }

    override suspend fun clearTokens(client: HttpClient) {
        _accessToken = null
        _refreshToken = null
        client.authProvider<BearerAuthProvider>()?.clearToken()
    }

    override fun addOnExpiredRefreshTokenListener(listener: suspend () -> Unit) {
        listeners.add(listener)
    }
}