package org.botdesigner.shared.`data`.cache

import app.cash.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import org.botdesigner.botblueprints.BlueprintType

public data class SqBlueprint(
  public val id: String,
  public val initialId: String,
  public val botId: String,
  public val type: BlueprintType,
  public val context: String,
  public val blueprint: String,
  public val createdAt: Long,
  public val editedAt: Long,
  public val deleted: Boolean,
) {
  public class Adapter(
    public val typeAdapter: ColumnAdapter<BlueprintType, String>,
  )
}
