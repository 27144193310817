package io.github.alexzhirkevich.onetap

actual fun OneTap.GoogleCM(
    serverClientId: String,
    redirectUri : String
) : SignInClient<GoogleSignInResult> = Google(
    clientId = serverClientId,
    redirectUri = redirectUri
).map {
    GoogleSignInResult(serverAuthCode = it.code)
}






