package org.botdesigner.ui

import androidx.compose.runtime.Composable
import androidx.compose.runtime.produceState
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.provider.JsStringProvider
import kotlinx.browser.window
import io.github.alexzhirkevich.onetap.NativeViewController
import io.github.alexzhirkevich.onetap.JsWindow
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async

@Composable
actual fun LocalViewController(): NativeViewController {
    return JsWindow(window)
}

@Composable
actual fun string(desk : StringDesc) : String {
    return produceState(initialValue = "", desk) {
        value = desk.localized()
            .normalizeStringResource()
    }.value
}

//TODO: js localized stringdesc
internal actual fun StringDesc.localized(viewController: NativeViewController) : String {
    return kotlin.runCatching {
        localized(provider.getCompleted())
    }.getOrDefault("")
}

private var provider = MainScope().async {
    JsStringProvider.load {  }
}
