@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.telegram.functions

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpTgKeyboardCreate")
public class BpTgKeyboardCreate(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.KeyboardButtonArrayPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Buttons",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Button Rows",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Force Reply",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Force Reply Placeholder",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Remove",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.botblueprints.telegram.pins.KeyboardPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
) : BpFunction() {
  override val summary: AnnotatedString
    get() = """
    |Create keyboard option that can be attached to a Telegram message.
    |<b>Buttons</b> will be send as keyboard grid. Rows count can be from 1 to 3.
    |<b>Force Reply</b> option will automatically force user to reply to this message. You can set a placeholder for force replied input
    |<b>Remove</b> will clear any previously sent keyboard.
    |<b>Exactly 1 option should be selected</b>
    """.trimMargin().trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4284132730)

  override val name: String
    get() = "Create Keyboard"

  override val factory: FunctionFactory
    get() = ::BpTgKeyboardCreate

  override suspend fun calculate(input: List<Any?>): List<Any?> = listOf(TgKeyboardCreate(
  		requireNotNull(input[0] as
      kotlin.collections.Iterable<org.botdesigner.telegram.KeyboardButton>?) { 
  "Buttons can't be null" 
  },
  		requireNotNull(input[1] as kotlin.Long?) { 
  "Button Rows can't be null" 
  },
  		input[2] as kotlin.Boolean?,
  		input[3] as kotlin.String?,
  		input[4] as kotlin.Boolean?
  	))
}
