package org.botdesigner.api.auth

import kotlinx.serialization.Serializable
import org.botdesigner.api.User

@Serializable
class LoginWithEmailAndPasswordRequest(
    val email : String,
    val password : String,
    override val device : String,
    override val deviceType : DeviceType,
    override val os: String,
    override val notificationToken: String
) : SignInRequest

@Serializable
class LoginWithEmailAndPasswordResponse(
    override val accessToken : String? = null,
    override val refreshToken : String? = null,
    override val userInfo: User? = null,
    val errorCode: LoginErrorCode = LoginErrorCode.Success
) : AuthResponse