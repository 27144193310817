package org.botdesigner.shared

import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpTimeout
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.coroutines.isActive
import kotlinx.coroutines.supervisorScope
import org.botdesigner.api.SharedConstants
import org.botdesigner.api.SubscriptionType
import org.botdesigner.blueprint.BlueprintLogcat
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.execute
import org.botdesigner.blueprint.integrations.context.PushNotificationContext
import org.botdesigner.blueprint.io.context.NetworkContext
import org.botdesigner.blueprint.queue.BlueprintHandlerQueue
import org.botdesigner.blueprint.queue.BlueprintHandlerQueueImpl
import org.botdesigner.blueprint.queue.launchWithIncrementalTimeout
import org.botdesigner.blueprint.stdlib.context.asLogcatContext
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext
import org.botdesigner.core.Bot
import org.botdesigner.core.tokenForDebug
import org.botdesigner.shared.data.repo.CustomFunctionsRepository
import org.botdesigner.shared.data.repo.asCustomProcedureContext
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.runCatchingIgnoringCancellation
import org.botdesigner.telegram.TelegramClient
import org.botdesigner.telegram.getUpdates
import kotlin.time.Duration.Companion.seconds

interface DebugBotManager {
    suspend fun launch()
}


private val DEFAULT_POOLING_TIMEOUT = 30
class TelegramDebugBotManager(
    private val bot: Bot,
    private val poolingTimeout: Int = DEFAULT_POOLING_TIMEOUT,
    private val customFunctionsRepository: CustomFunctionsRepository,
    private val subscriptionType: () -> SubscriptionType,
    private val logcat: BlueprintLogcat,
    private val queue: BlueprintHandlerQueue<TelegramBotBlueprintContext> = BlueprintHandlerQueueImpl(),
    private val blueprintManager: BlueprintManager,
    private val dispatchers: Dispatchers,
    private val notificationContext: PushNotificationContext
) : DebugBotManager {


    private var lastUpdate = -1L

    private val networkContext = object : NetworkContext {
        override val httpClient: HttpClient = HttpClient {
            expectSuccess = true
            install(HttpTimeout) {
                requestTimeoutMillis = (poolingTimeout + 1).seconds.inWholeMilliseconds
            }
//            Logging {
//                this.level = LogLevel.ALL
//                this.logger = object : Logger {
//                    override fun log(message: String) {
//                        println(message)
//                    }
//                }
//            }
        }
    }

    private val client = TelegramClient(
        token = bot.tokenForDebug.orEmpty(),
        httpClient = networkContext.httpClient
    )

    override suspend fun launch() {
        supervisorScope {
            while (currentCoroutineContext().isActive) {
                runCatchingIgnoringCancellation {
                    val updates = client.getUpdates(
                        offset = lastUpdate,
                        timeout = poolingTimeout.toLong(),
                        allowed_updates = SharedConstants.telegramAllowedUpdated
                    )

                    if (updates.result.isNotEmpty()) {
                        lastUpdate = updates.result.last().update_id + 1
                    }
                    if (!updates.ok || updates.result.isEmpty()) {
                        return@runCatchingIgnoringCancellation
                    }

                    val sub = subscriptionType()

                    updates.result.forEach { update ->
                        launchWithIncrementalTimeout(
                            timeMillis = sub.timeout.inWholeMilliseconds,
                            totalMaxTimeout = sub.totalMaxTimeout.inWholeMilliseconds
                        ) { timeout ->
                            val bp = dispatchers.runOnUI {
                                blueprintManager.save()
                            }
                            val context = TelegramBotBlueprintContext(
                                client = client,
                                update = update,
                                botId = bot.id,
                                botName = bot.name,
                                procedureContext = customFunctionsRepository
                                    .asCustomProcedureContext(bp),
                                pushNotificationContext = notificationContext,
                                logcatContext = logcat.asLogcatContext(),
                                networkContext = networkContext,
                            )

                            queue.enqueue(timeout, context) {
                                bp.execute(context)
                            }
                        }
                    }
                }
            }
        }
    }
}