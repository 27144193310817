package org.botdesigner.ui.screens.appearance

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.calculateEndPadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.TextDecrease
import androidx.compose.material.icons.rounded.TextIncrease
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.dp
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveSlider
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveSwitch
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.section.CupertinoSection
import io.github.alexzhirkevich.cupertino.section.CupertinoSectionDefaults
import io.github.alexzhirkevich.cupertino.section.SectionItem
import io.github.alexzhirkevich.cupertino.section.SectionScope
import io.github.alexzhirkevich.cupertino.section.sectionTitle
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.appearance.AppearanceAccent
import org.botdesigner.shared.domain.content.appearance.AppearanceComponent
import org.botdesigner.shared.domain.content.appearance.AppearanceState
import org.botdesigner.shared.domain.content.appearance.accentAvailable
import org.botdesigner.shared.domain.content.appearance.darkModeAvailable
import org.botdesigner.ui.screens.PreferencesScreen
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

@OptIn(ExperimentalAdaptiveApi::class, ExperimentalCupertinoApi::class)
@Composable
internal fun AppearanceScreen(
    component: AppearanceComponent,
    modifier: Modifier = Modifier
) {

    val state by component.state.collectAsState()

    PreferencesScreen(
        modifier = modifier,
        onBack = component::onBack,
        title = string(SharedRes.strings.appearance),
    ) {
        CupertinoSection(
            title = {
                Text(string(SharedRes.strings.automatic).sectionTitle())
            }
        ) {
            SectionItem(
                trailingContent = {
                    AdaptiveSwitch(
                        checked = state.autoNightMode,
                        onCheckedChange = component::onAutoNightModeChanged,
                    )
                }
            ) {
                Text(string(SharedRes.strings.auto_night_mode))
            }

            SectionItem(
                trailingContent = {
                    AdaptiveSwitch(
                        checked = state.systemAppearance,
                        onCheckedChange = component::onSystemAppearanceChanged,
                    )
                }
            ) {
                Text(string(SharedRes.strings.system_appearance))
            }
        }

        Box {
            CupertinoSection(
                title = {
                    Text(string(SharedRes.strings.manual).sectionTitle())
                }
            ) {

                SectionItem(
                    trailingContent = {
                        AdaptiveSwitch(
                            checked = state.darkMode,
                            enabled = state.darkModeAvailable,
                            onCheckedChange = component::onDarkModeChanged,
                        )
                    }
                ) {
                    Text(
                        text = string(SharedRes.strings.dark_mode),
                        modifier = Modifier.alpha(
                            if (state.darkModeAvailable) 1f else .5f
                        )
                    )
                }

                Accent(
                    value = state.accent,
                    onChange = component::onAccentChanged,
                    enabled = state.accentAvailable,
                )
            }
        }

        CupertinoSection(
            title = {
                Text(string(SharedRes.strings.font_scale).sectionTitle())
            }
        ) {
            SectionItem {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.spacedBy(Dimens.Spacing.Medium)
                ) {
                    Icon(
                        imageVector = Icons.Rounded.TextDecrease,
                        contentDescription = null,
                        modifier = Modifier.size(18.dp)
                    )

                    // font scale changes cause slider state recreation
                    CompositionLocalProvider(
                        LocalDensity provides Density(LocalDensity.current.density, 1f)
                    ) {
                        AdaptiveSlider(
                            modifier = Modifier.weight(1f),
                            value = state.fontScale,
                            onValueChange = component::onFontScaleChanged,
                            valueRange = AppearanceState.FontScaleRange,
                            steps = 5
                        )
                    }
                    Icon(
                        imageVector = Icons.Rounded.TextIncrease,
                        contentDescription = null
                    )
                }
            }
        }
    }
}

@OptIn(ExperimentalStdlibApi::class, ExperimentalCupertinoApi::class)
@Composable
fun SectionScope.Accent(
    value : AppearanceAccent,
    onChange : (AppearanceAccent) -> Unit,
    enabled : Boolean
) {

    SectionItem {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement
                .spacedBy(Dimens.Spacing.Small),
            modifier = Modifier.padding(
                end = CupertinoSectionDefaults.PaddingValues
                    .calculateEndPadding(LocalLayoutDirection.current)
            ).alpha(if (enabled) 1f else .5f)
        ) {
            Text(
                text = string(SharedRes.strings.accent)
            )
            Spacer(Modifier.weight(1f))

            AppearanceAccent.entries.forEach {
                Box(
                    modifier = Modifier
                        .size(28.dp)
                        .clip(CircleShape)
                        .background(it.color(CupertinoTheme.colorScheme.isDark))
                        .clickable(enabled = enabled) {
                            onChange(it)
                        }
                        .border(
                            width = if (it == value) 3.dp else 1.dp,
                            color = MaterialTheme.colorScheme.onSurface,
                            shape = CircleShape
                        )
                )
            }
        }
    }
}