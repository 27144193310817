package org.botdesigner.blueprint.stdlib.functions.string

import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpExpression
import org.botdesigner.blueprint.components.functions.ExpressionFactory
import org.botdesigner.blueprint.stdlib.pins.string
import org.botdesigner.blueprint.toAnnotatedString

@kotlinx.serialization.Serializable
@SerialName("BpStringCreate")
class BpStringCreate(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id){
        input {
            string(maxLines = 30)
        }
        output {
            string()
        }
    }
) : BpExpression() {

    override val expression: String
        get() = ""

    override val name: String
        get() = "Create String"

    override val summary: AnnotatedString
        get() = "Create multiline string"
            .toAnnotatedString()

    override val factory: ExpressionFactory
        get() = ::BpStringCreate

    override suspend fun calculate(input: List<*>): List<*> {
        return input
    }
}
