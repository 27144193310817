package org.botdesigner.blueprint.integrations

import org.botdesigner.blueprint.integrations.google.BpGoogleDrivePublicUpload
import org.botdesigner.blueprint.integrations.google.BpGoogleSheetValue
import org.botdesigner.blueprint.integrations.services.BpSendPushNotification
import org.botdesigner.blueprint.store.BlueprintNodeCategory
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.blueprint.store.BlueprintNodeStoreRecord

public fun BlueprintNodeStoreOwner.register_org_botdesigner_blueprint_integrations() {
  BlueprintNodeStoreOwner.register(
  	BlueprintNodeCategory("Network"),
  	BlueprintNodeStoreRecord("Google", ::BpGoogleSheetValue),
  	BlueprintNodeStoreRecord("Google", ::BpGoogleDrivePublicUpload),
  	BlueprintNodeStoreRecord("Push", ::BpSendPushNotification)
  )
}
