package org.botdesigner.botblueprints.telegram

import org.botdesigner.blueprint.context.SuspensionContextImpl
import org.botdesigner.blueprint.integrations.context.CustomProcedureContext
import org.botdesigner.blueprint.integrations.context.PushNotificationContext
import org.botdesigner.blueprint.io.context.NetworkContext
import org.botdesigner.blueprint.stdlib.context.LogcatContext
import org.botdesigner.botblueprints.BotBlueprintContext
import org.botdesigner.telegram.TelegramClient
import org.botdesigner.telegram.Update


class TelegramBotBlueprintContext constructor(
    val client : TelegramClient,
    val update: Update,
    override val botId: String,
    override val botName: String,
    private val procedureContext: CustomProcedureContext,
    private val pushNotificationContext: PushNotificationContext,
    private val logcatContext: LogcatContext,
    private val networkContext: NetworkContext,
) : SuspensionContextImpl<TelegramBotBlueprintContext>(),
    BotBlueprintContext,
    CustomProcedureContext by procedureContext,
    PushNotificationContext by pushNotificationContext,
    LogcatContext by logcatContext,
    NetworkContext by networkContext {

    override val key: String? = update.message?.let { "$botId-${it.chat.id}" }
}