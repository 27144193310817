package org.botdesigner.blueprint.ui.components

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.absoluteOffset
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.material.icons.outlined.Cancel
import androidx.compose.material.icons.outlined.Info
import androidx.compose.material3.BasicAlertDialog
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.State
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.setValue
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.dp
import org.botdesigner.blueprint.AbstractBlueprintNodeStateHolder
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.EmptyBlueprintManager
import org.botdesigner.blueprint.components.BlueprintInstance
import org.botdesigner.blueprint.components.BlueprintReturn
import org.botdesigner.blueprint.components.BlueprintTrigger
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.plus
import org.botdesigner.blueprint.components.toPoint

private enum class ComponentState {
    Preview, Demo, Actual
}

private val LocalComponentState = compositionLocalOf {
    ComponentState.Actual
}

@Composable
fun BlueprintDemo(
    content : @Composable (BlueprintManager) -> Unit
) {
    CompositionLocalProvider(LocalComponentState provides ComponentState.Demo){
        content(EmptyBlueprintManager)
    }
}

@Composable
fun BlueprintPreview(
    content : @Composable (BlueprintManager) -> Unit
) {
    CompositionLocalProvider(LocalComponentState provides  ComponentState.Preview){
        content(EmptyBlueprintManager)
    }
}

@Composable
fun isInPreviewMode() : Boolean {
    return LocalComponentState.current == ComponentState.Preview
}


@Composable
fun isInDemo() : Boolean {
    return LocalComponentState.current == ComponentState.Demo
}

val LocalDeviceDensity = staticCompositionLocalOf {
    Density(1f,1f)
}

@Composable
fun WithDeviceDensity(content: @Composable () -> Unit) {
    CompositionLocalProvider(
        LocalDensity provides LocalDeviceDensity.current,
        content = content
    )
}

private val ComponentBackgroundColor = Color.Black.copy(alpha = .9f)

@Composable
internal fun AbstractBlueprintNodeStateHolder<*>.BlueprintComponent(
    manager: BlueprintManager,
    modifier: Modifier,
    shape: Shape,
    fieldSize : Float,
    content: @Composable () -> Unit,
) {
    val holder = this

    var infoVisible by remember {
        mutableStateOf(false)
    }

    if (infoVisible) {
        InfoDialog(
            manager = manager,
            onDismissRequest = {
                infoVisible = false
            },
            shape = shape,
            fieldSize = fieldSize,
            element = element,
            content = content
        )
    }

    Component(
        modifier = modifier,
        position = position,
        fieldSize = fieldSize,
        manager = manager,
        onTap = {
            manager.onComponentTap(holder)
        },
        setPosition = this@BlueprintComponent::setPosition,
        element = element,
        content = content,
        shape = shape,
        onInfo = {
            infoVisible = true
        },
        onDragStarted = {
            manager.onComponentDragStarted(this)
        },
        onDragEnded = {
            manager.onComponentDragEnded(this)
        },
    )
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun InfoDialog(
    manager: BlueprintManager,
    shape: Shape,
    fieldSize : Float,
    element : BlueprintInstance,
    onDismissRequest : () -> Unit,
    content: @Composable () -> Unit,
) {
    WithDeviceDensity {
        BasicAlertDialog(
            onDismissRequest = onDismissRequest,
            modifier = Modifier
                .padding(vertical = 36.dp)
        ) {
            Column(
                modifier = Modifier
                    .clip(shape)
                    .background(MaterialTheme.colorScheme.surface, shape)
                    .padding(18.dp)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween,
                ) {
                    Text(
                        text = element.name,
                        style = MaterialTheme.typography.titleLarge
                    )
                    IconButton(
                        modifier = Modifier.absoluteOffset(
                            y = (-12).dp,
                            x = if (LocalLayoutDirection.current == LayoutDirection.Ltr)
                                8.dp else (-8).dp
                        ),
                        onClick = onDismissRequest
                    ) {
                        Icon(
                            imageVector = Icons.Default.Close,
                            contentDescription = "Close"
                        )
                    }
                }
                Column(
                    modifier = Modifier
                        .verticalScroll(rememberScrollState()),
                    verticalArrangement = Arrangement.spacedBy(12.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        text = element.summary.highlightBold(),
                        modifier = Modifier.fillMaxWidth()
                    )

                    Component(
                        modifier = Modifier.width(IntrinsicSize.Min),
                        state = ComponentState.Preview,
                        position = rememberUpdatedState(Point.Zero),
                        fieldSize = fieldSize,
                        manager = manager,
                        shape = shape,
                        setPosition = { },
                        element = element,
                        content = content,
                        onInfo = {},
                        onTap = {},
                        onDragStarted = {},
                        onDragEnded = {}
                    )
                }
            }
        }
    }
}

@Composable
fun AnnotatedString.highlightBold(
    color : Color = MaterialTheme.colorScheme.primary
) : AnnotatedString {
    return remember(this) {
        AnnotatedString(
            text = text,
            spanStyles = spanStyles.map {
                if (it.item.fontWeight != FontWeight.Normal) {
                    it.copy(item = it.item.copy(color = color))
                } else it
            },
            paragraphStyles = paragraphStyles
        )
    }
}

@Composable
private fun Component(
    modifier: Modifier = Modifier,
    state : ComponentState = LocalComponentState.current,
    shape: Shape,
    position: State<Point>,
    fieldSize: Float,
    manager: BlueprintManager,
    setPosition: (Point) -> Unit,
    element: BlueprintInstance,
    content: @Composable () -> Unit,
    onInfo: () -> Unit,
    onTap: () -> Unit,
    onDragStarted : () -> Unit,
    onDragEnded : () -> Unit,
) {
    CompositionLocalProvider(
        LocalComponentState provides state
    ) {

        val isPreview = state == ComponentState.Preview
        val isActual = state == ComponentState.Actual

        var size by remember { mutableStateOf(IntSize.Zero) }

        Box(
            modifier = Modifier
                .onSizeChanged { size = it }
                .offset {
                    IntOffset(
                        position.value.x.coerceIn(-fieldSize, fieldSize - size.width).toInt(),
                        position.value.y.coerceIn(-fieldSize, fieldSize - size.height).toInt(),
                    )
                }.then(modifier),
        ) {
            BlueprintComponentContent(
                shape = shape,
                onTap = onTap,
                isDragEnabled = isActual,
                onAddOffset = {
                    val new = position.value + it.toPoint()

                    val newX = new.x.coerceIn(-fieldSize, fieldSize - size.width)
                    val newY = new.y.coerceIn(-fieldSize, fieldSize - size.height)

                    setPosition(Point(newX, newY))
                },
                canDelete = element !is BlueprintTrigger<*> && element !is BlueprintReturn<*>,
                onDelete = {
                    manager.deleteNode(element.id)
                },
                onInfo = onInfo,
                content = content,
                isPreview = isPreview,
                showControls = isActual,
                onDragStarted = onDragStarted,
                onDragEnded = onDragEnded
            )
            if (isPreview) {
                Box(modifier = Modifier
                    .matchParentSize()
                    .pointerInput(Unit) {

                    }
                )
            }
        }
    }
}

private val ButtonSize = 36.dp

@Composable
private fun BoxScope.BlueprintComponentContent(
    isPreview: Boolean,
    isDragEnabled : Boolean,
    showControls: Boolean,
    componentModifier: Modifier = Modifier,
    shape: Shape,
    onTap: () -> Unit,
    onAddOffset: (Offset) -> Unit,
    canDelete: Boolean,
    onDelete: () -> Unit,
    onInfo: () -> Unit,
    onDragStarted : () -> Unit,
    onDragEnded : () -> Unit,
    content: @Composable () -> Unit,
) {
    var isDragging by remember {
        mutableStateOf(false)
    }

    if (!isPreview && showControls) {
        IconButton(
            modifier = Modifier
                .align(Alignment.TopStart)
                .offset(
                    x = if (LocalLayoutDirection.current == LayoutDirection.Ltr)
                        -ButtonSize else ButtonSize,
                    y = -ButtonSize
                ),
            onClick = onInfo,
        ) {
            Icon(
                imageVector = Icons.Outlined.Info,
                tint = Color.White,
                contentDescription = "Information",
            )
        }
        if (canDelete) {
            IconButton(
                modifier = Modifier
                    .align(Alignment.TopEnd)
                    .offset(
                        x = if (LocalLayoutDirection.current == LayoutDirection.Ltr)
                            ButtonSize else -ButtonSize,
                        y = -ButtonSize
                    ),
                onClick = onDelete,
            ) {
                Icon(
                    imageVector = Icons.Outlined.Cancel,
                    tint = Color.Red,
                    contentDescription = "Delete",
                )
            }
        }
    }

    val focusManager = LocalFocusManager.current

    val updatedOnTap by rememberUpdatedState(onTap)
    val updatedOnDragStarted by rememberUpdatedState(onDragStarted)
    val updatedOnDragEnded by rememberUpdatedState(onDragEnded)
    val updatedOnAddOffset by rememberUpdatedState(onAddOffset)

    Surface(
        shape = shape,
        modifier = componentModifier
            .pointerInput(0) {
                detectTapGestures {
                    updatedOnTap()
                    focusManager.clearFocus(force = true)
                }
            }
            .pointerInput(focusManager) {
                if (isDragEnabled) {
                    detectDragGestures(
                        onDragStart = {
                            focusManager.clearFocus(force = true)
                            updatedOnTap()
                            updatedOnDragStarted()
                            isDragging = true
                        },
                        onDragEnd = {
                            updatedOnDragEnded()
                            isDragging = false
                        },
                        onDragCancel = {
                            updatedOnDragEnded()
                            isDragging = false
                        }
                    ) { _, dragAmount ->
                        updatedOnAddOffset(dragAmount)
                    }
                }
            },
        border = if (isDragging && isDragEnabled)
            BorderStroke(3.dp, Color.White)
        else null,
        color = ComponentBackgroundColor,
        content = content
    )
}