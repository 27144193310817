package org.botdesigner.blueprint.stdlib.functions.float

import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.GenericPinFactory
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.PinsMutationScope
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.generic
import org.botdesigner.blueprint.stdlib.pins.double
import org.botdesigner.blueprint.stdlib.pins.string
import org.botdesigner.blueprint.toAnnotatedString
import kotlin.math.PI


private const val Alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

@Serializable
@SerialName("BpEvaluate")
class BpEvaluate(
    override val coordinate: Point,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id){
        input {
            string("Expression", required = true)
            generic("A")
        }
        output {
            double("Result", required = true)
        }
    },
) : BpFunction() {

    override val factory: FunctionFactory = ::BpEvaluate

    override val isExtendable: Boolean get() = true

    override val summary: AnnotatedString
        get() ="""
         Calculate the result of a mathematical <b>Expression</b>.
         You can use input pin names as variables. For example: sqrt((A + 200) * 5). 
         As inputs you can pass numbers and string representations of numbers.
         Available operations are <b>+</b>, <b>-</b>, <b>*</b>, <b>/</b>, <b>( )</b> (brackets), 
         <b>sqrt(x)</b> (square root), <b>x^n</b> (exponentiation), <b>sin(x)</b>, <b>cos(x)</b>, <b>tan(x)</b>,
         <b>ln(x)</b>, <b>pi</b> (will be replaced with π value)"
        """.trimIndent().toAnnotatedString()

    override suspend fun calculate(input: List<*>): List<*> {
        var expr = input[0] as String

        val values = input.drop(1).onEach {
            require(it is Number || (it is String && it.toDoubleOrNull() != null)){
                "Only numbers and their string representations are supported for evaluation. Got \"$input\""
            }
        }
        expr = expr.replace("pi", PI.toString())

        values.forEachIndexed { index, v ->
            expr = expr.replace(Alphabet[index].toString(), v.toString())
        }

        return listOf(eval(expr))
    }

    override fun PinsMutationScope.onRemovePins(pool: BlueprintNodesPool) {
        if (pins.size > 3){
            removePin(pins.last { !it.isOut })
        }
    }
    override fun PinsMutationScope.onAddPins(pool: BlueprintNodesPool) {

        if (pins.size < Alphabet.length + 2) {
            appendPin {
                GenericPinFactory.create(
                    id = it,
                    order = pins.size.toUInt(),
                    elementId = id,
                    name = Alphabet[pins.size - 2].toString(),
                    isOut = false
                )
            }
        }
    }

}