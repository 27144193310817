package org.botdesigner.ui.common

import androidx.compose.foundation.layout.height
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.filled.ArrowBackIosNew
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import io.github.alexzhirkevich.cupertino.CupertinoIconButton
import io.github.alexzhirkevich.cupertino.CupertinoNavigateBackButton
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveWidget
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.resources.SharedRes
import org.botdesigner.ui.string

@OptIn(ExperimentalAdaptiveApi::class, ExperimentalCupertinoApi::class)
@Composable
internal fun NavigateBackButton(
    onClick : () -> Unit,
    compact : Boolean = false,
    modifier: Modifier = Modifier,
) {
    AdaptiveWidget(
        cupertino = {
            when {
                compact -> {
                    CupertinoIconButton(
                        modifier = modifier,
                        onClick = onClick,
                    ){
                        Icon(
                            imageVector = Icons.Default.ArrowBackIosNew,
                            contentDescription = string(SharedRes.strings.back),
                        )
                    }
                }
                CurrentPlatform == Platform.Ios -> {
                    CupertinoNavigateBackButton(
                        modifier = modifier,
                        onClick = onClick,
                        title = {
                            Text(string(SharedRes.strings.back))
                        }
                    )
                }

                else ->  CupertinoNavigateBackButton(
                    modifier = modifier.height(24.dp),
                    onClick = onClick,
                    title = {
                        Text(string(SharedRes.strings.back))
                    }
                )
            }
        },
        material = {
            IconButton(
                modifier = modifier,
                onClick = onClick,
            ){
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = string(SharedRes.strings.back)
                )
            }
        }
    )
}