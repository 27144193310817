package org.botdesigner.blueprint

import androidx.compose.foundation.layout.Column
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.Stable
import androidx.compose.runtime.State
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.layout.boundsInParent
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.util.fastForEach
import androidx.compose.ui.util.fastMap
import kotlinx.datetime.Clock
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.connectorsOrNull
import org.botdesigner.blueprint.components.plus
import org.botdesigner.blueprint.components.toPoint
import org.botdesigner.blueprint.ui.components.ConnectorWidget
import org.botdesigner.blueprint.ui.components.isInPreviewMode

@Stable
abstract class AbstractBlueprintNodeStateHolder<out T : BlueprintNode>(
    protected val manager: BlueprintManager,
    val element : T,
) : BlueprintNodeStateHolder<T> {

    private val _lastChange = mutableStateOf(Clock.System.now().toEpochMilliseconds())
    override val lastChange: State<Long> get() = _lastChange

    override val pins: List<Pin<*>> by derivedStateOf {
        _pins.values.map(State<Pin<*>>::value).sortedBy(Pin<*>::order)
    }

    override val connectors: List<Connector> by derivedStateOf {
        _connectors.values.map(State<Connector>::value).sortedBy(Connector::order)
    }

    private val _pins = mutableStateMapOf<Id, MutableState<Pin<*>>>()
    private val pinsPositions = mutableStateMapOf<Id, MutableState<Point>>()

    private val _connectors = mutableStateMapOf<Id, MutableState<Connector>>()
    private val connectorsPositions = mutableStateMapOf<Id, MutableState<Point>>()

    override val id: Id = element.id

    private val _position = mutableStateOf(element.coordinate)

    final override val position: State<Point> get() = _position

    open fun shouldDrawPin(pin: Pin<*>): Boolean = true

    open fun shouldDrawConnector(connector: Connector): Boolean = true

    init {
        element.pins.forEach {
            this._pins[it.id] = mutableStateOf(it)
            this.pinsPositions[it.id] = mutableStateOf(element.coordinate)
        }
        element.connectorsOrNull()?.forEach {
            this._connectors[it.id] = mutableStateOf(it)
            this.connectorsPositions[it.id] = mutableStateOf(element.coordinate)
        }
    }


    final override fun appendConnector(
        factory : (Id) -> Connector
    ) : Connector {

        var connector = factory(Id.randomId())

        while (_connectors.containsKey(connector.id)){
            connector = factory(Id.randomId())
        }

        _connectors[connector.id] = mutableStateOf(connector)
        connectorsPositions[connector.id] = mutableStateOf(Point.Zero)
        manager.onConnectorAdded(connector)
        changed()

        return connector
    }

    final override fun removeConnector(connector: Connector) {
        val removed = _connectors.remove(connector.id) != null
        connectorsPositions.remove(connector.id)
        if (removed) {
            manager.onConnectorRemoved(connector)
        }
        changed()
    }

    final override fun <T: Pin<*>> appendPin(factory: (Id) -> T) : T {

        var pin = factory(Id.randomId())

        while (_pins.containsKey(pin.id)){
            pin = factory(Id.randomId())
        }
        _pins[pin.id] = mutableStateOf(pin)
        pinsPositions[pin.id] = mutableStateOf(Point.Zero)
        manager.onPinAdded(pin)
        changed()

        return pin
    }

    final override fun removePin(pin: Pin<*>) {
        val removed = _pins.remove(pin.id) != null
        pinsPositions.remove(pin.id)
        if (removed) {
            manager.onPinRemoved(pin)
        }
        changed()
    }

    override fun focused() {
        changed()
    }

    override fun setPosition(pos: Point) {
        _position.value = pos
    }

    final override fun connectorState(id: Id): State<Connector>? {
        return _connectors[id]
    }

    final override fun pinState(id: Id): State<Pin<*>>? {
        return _pins[id]
    }

    final override fun getPinPosition(id: Id): State<Point>? {
        return pinsPositions[id]
    }

    final override fun getConnectorPosition(id: Id): State<Point>? {
        return connectorsPositions[id]
    }

    override fun updateConnector(connector: Connector, removeReference: (Connector) -> Unit) {
        if (!_connectors.containsKey(connector.id)) {
            connectorsPositions[connector.id] = mutableStateOf(Point.Zero)
            _connectors[connector.id] = mutableStateOf(connector)
        } else {
            _connectors[connector.id]?.value = connector
        }
        changed()
    }

    override fun updatePin(
        pin: Pin<*>,
    ) {
        if (!_pins.containsKey(pin.id)) {
            pinsPositions[pin.id] = mutableStateOf(Point.Zero)
            _pins[pin.id] = mutableStateOf(pin)
        } else {
            _pins[pin.id]?.value = pin
        }
        changed()
    }

    private fun changed() {
        _lastChange.value = Clock.System.now().toEpochMilliseconds()
    }

    @Composable
    fun DrawConnectors(
        parentInComponent: Offset,
        isOut: Boolean? = null,
        onConnectorClicked: (Connector) -> Unit,
        modifier: Modifier,
        drawIf: (Connector) -> Boolean
    ) {

        var columnInParent by remember {
            mutableStateOf(Offset.Zero)
        }

        val inPreviewMode = isInPreviewMode()

        Column(
            modifier = modifier.onGloballyPositioned {
                columnInParent = it.positionInParent()
            }
        ) {
            val updatedDrawIf by rememberUpdatedState(drawIf)

            val connectorValues by remember(_connectors) {
                derivedStateOf {
                    _connectors.values
                        .filter { updatedDrawIf(it.value) }
                        .fastMap(State<Connector>::value)
                        .sortedBy(Connector::order)
                }
            }
            connectorValues.fastForEach { v ->
                key(v.id.id) {
                    if (isOut == null ||
                        isOut == true && v.isOut ||
                        isOut == false && !v.isOut
                    ) {
                        ConnectorWidget(
                            isActive = !inPreviewMode && v.refId != null,
                            isOut = v.isOut,
                            name = v.name,
                            onTap = {
                                if (!inPreviewMode) {
                                    onConnectorClicked(v)
                                }
                            },
                            isSelected = !inPreviewMode && manager.selectedConnector.value?.id == v.id,
                            modifier = Modifier.onGloballyPositioned {
                                if (!inPreviewMode) {
                                    val b = it.boundsInParent().run {
                                        if (v.isOut) centerRight else centerLeft
                                    }

                                    connectorsPositions[v.id]?.value = position.value +
                                            (parentInComponent + columnInParent + b).toPoint()
                                }
                            }
                        )
                    }
                }
            }
        }
    }

    @Composable
    fun DrawPins(
        parentInComponent: Offset,
        isOut: Boolean? = null,
        pool: BlueprintNodesPool,
        onPinClicked: (Pin<*>) -> Unit,
        modifier: Modifier,
        drawIf: (Pin<*>) -> Boolean
    ) {
        var columnInParent by remember {
            mutableStateOf(Offset.Zero)
        }

        val inPreviewMode = isInPreviewMode()

        Column(
            modifier = modifier.onGloballyPositioned {
                columnInParent = it.positionInParent()
            }
        ) {
            val updatedDrawIf by rememberUpdatedState(drawIf)

            val pinValues by remember(_pins) {
                derivedStateOf {
                    _pins.values
                        .filter { updatedDrawIf(it.value) }
                        .fastMap(State<Pin<*>>::value)
                        .sortedBy(Pin<*>::order)
                }
            }

            pinValues.fastForEach { v ->
                key(v.id.id) {
                    if (isOut == null ||
                        isOut == true && v.isOut ||
                        isOut == false && !v.isOut
                    ) {
                        with(v) {
                            Draw(
                                pool = pool,
                                value = v.value?.takeIf { !inPreviewMode }?.toString(),
                                onTap = {
                                    if (!inPreviewMode) {
                                        onPinClicked(this)
                                    }
                                },
                                onInputChanged = {
                                    if (!inPreviewMode) {
                                        _pins[v.id]?.value = withValue(value = it)
                                        changed()
                                    }
                                },
                                isSelected = !inPreviewMode && manager.selectedPin.value?.id == v.id,
                                modifier = Modifier.onGloballyPositioned {
                                    if (!inPreviewMode) {
                                        val b = it.boundsInParent().run {
                                            if (v.isOut) centerRight else centerLeft
                                        }

                                        pinsPositions[v.id]?.value = position.value +
                                                (parentInComponent + columnInParent + b).toPoint()
                                    }
                                }
                            )
                        }
                    }
                }
            }
        }
    }
}