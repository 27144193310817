package org.botdesigner.shared.util.dispatchers

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.withContext
import kotlin.coroutines.CoroutineContext
import kotlinx.coroutines.Dispatchers as KDispatchers

internal class DispatchersImpl(
    private val jobManager: CoroutineJobManager
) : Dispatchers, CoroutineJobCancel by jobManager {

    override fun uiContext(): CoroutineContext =  KDispatchers.Main

    override fun uiImmediateContext(): CoroutineContext = KDispatchers.Main.immediate

    override fun ioContext(): CoroutineContext = KDispatchers.IODispatcher

    override fun compContext(): CoroutineContext = KDispatchers.Default

    override fun launchUI(
        scope: CoroutineScope,
        key: Any?,
        exceptionHandler: CoroutineExceptionHandler?,
        block: suspend CoroutineScope.() -> Unit
    ): Job =  jobManager.launch(
        scope = scope,
        context =   KDispatchers.Main.let {
            scope.coroutineContext + if (exceptionHandler != null)
                it + exceptionHandler else it
        },
        key = key,
        block = block
    )

    override fun launchUIImmediate(
        scope: CoroutineScope,
        key: Any?,
        exceptionHandler: CoroutineExceptionHandler?,
        block: suspend CoroutineScope.() -> Unit
    ): Job = jobManager.launch(
            scope = scope,
            start = CoroutineStart.UNDISPATCHED,
            context = KDispatchers.Main.immediate.let {
                scope.coroutineContext + if (exceptionHandler != null)
                    it + exceptionHandler else it
            },
            key = key,
            block = block
        )

    override fun launchComputation(
        scope: CoroutineScope,
        key: Any?,
        exceptionHandler: CoroutineExceptionHandler?,
        block: suspend CoroutineScope.() -> Unit
    ): Job = jobManager.launch(
        scope = scope,
        context = KDispatchers.Default.let {
            scope.coroutineContext + if (exceptionHandler != null)
                it + exceptionHandler else it
        },
        key = key,
        block = block
    )

    override fun launchIO(
        scope: CoroutineScope,
        key: Any?,
        exceptionHandler: CoroutineExceptionHandler?,
        block: suspend CoroutineScope.() -> Unit
    ): Job = jobManager.launch(
        scope = scope,
        context =  KDispatchers.IODispatcher.let {
            scope.coroutineContext + if (exceptionHandler != null)
                it + exceptionHandler else it
        },
        key = key,
        block = block
    )

    override suspend fun <T> runOnUI(
        exceptionHandler: CoroutineExceptionHandler?,
        block: suspend CoroutineScope.() -> T
    ) = withContext(
        context = KDispatchers.Main.let {
            if (exceptionHandler != null)
                it + exceptionHandler else it
        },
        block = block
    )

    override suspend fun <T> runOnComputational(
        exceptionHandler: CoroutineExceptionHandler?,
        block: suspend CoroutineScope.() -> T
    ) = withContext(
        context = KDispatchers.IODispatcher.let {
            if (exceptionHandler != null)
                it + exceptionHandler else it
        },
        block = block
    )

    override suspend fun <T> runOnIO(
        exceptionHandler: CoroutineExceptionHandler?,
        block: suspend CoroutineScope.() -> T
    ) = withContext(
        context = KDispatchers.IODispatcher.let {
            if (exceptionHandler != null)
                it + exceptionHandler else it
        },
        block = block
    )
}

internal expect val KDispatchers.IODispatcher: CoroutineDispatcher