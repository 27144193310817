package org.botdesigner.shared.domain.content.guides

import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import org.botdesigner.resources.SharedRes

enum class PinsGuidePage(
    override val title : StringDesc,
    override val text : StringDesc
) : GuidePage {

    Types(
        SharedRes.strings.pin_types.desc(),
        SharedRes.strings.help_pin_types.desc(),
    ),
    Arrays(
        SharedRes.strings.arrays.desc(),
        SharedRes.strings.help_arrays.desc(),
    ),
    Generics(
        SharedRes.strings.generics.desc(),
        SharedRes.strings.help_generics.desc(),
    ),
    Required(
        SharedRes.strings.null_safety.desc(),
        SharedRes.strings.help_null_safety.desc(),
    ),
    Extenders(
        SharedRes.strings.extenders.desc(),
        SharedRes.strings.help_extenders.desc(),
    )
}