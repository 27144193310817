package org.botdesigner.blueprint.builder

import kotlinx.serialization.Contextual
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.modules.SerializersModule
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintFunction
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.BlueprintProcedure
import org.botdesigner.blueprint.components.BlueprintTrigger
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.inputConnectors
import org.botdesigner.blueprint.components.inputPins
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.components.outputPins
import org.botdesigner.blueprint.components.withReference

class BlueprintBuilderScopeImpl<TR : BlueprintTrigger<*>>(
    trigger : TR,
    serializer: KSerializer<TR>,
    override val serializersModule: SerializersModule
) : BlueprintBuilderScope<TR> {

    private val json = Json {
        this.serializersModule = this@BlueprintBuilderScopeImpl.serializersModule
    }

    override val trigger: BuilderOutputNode<TR>
        get() = triggerNode

    private val triggerNode = BuilderProcedureImpl(trigger,serializer, json)

    private val builders = mutableListOf<Builder<*>>(triggerNode)

    override fun <T : BlueprintProcedure<*>> procedure(
        serializer: KSerializer<T>,
        node: T
    ): BuilderProcedureNode<T> = BuilderProcedureImpl(node, serializer,json)
        .also(builders::add)

    override fun <T : BlueprintFunction> function(
        serializer: KSerializer<T>,
        node: T
    ): BuilderFunctionNode<T> = BuilderFunctionImpl(node, serializer, json)
        .also(builders::add)


    fun build(): Blueprint = Blueprint(
        elements = builders.map(Builder<*>::build)
    )
}

private interface Builder<T : BlueprintNode> {
    fun build() : T
}

private class BuilderProcedureImpl<T : BlueprintProcedure<*>>(
    private val node : T,
     serializer: KSerializer<T>,
    json: Json
) : BuilderFunctionImpl<T>(node, serializer, json), BuilderProcedureNode<T>, Builder<T> {

    private val connectors = node.connectors
        .associateBy { it.id }.toMutableMap()

    override fun input(number: Int): BuilderInputConnector {
        return BlueprintConImpl(
            map = connectors,
            connector = requireNotNull(connectors[node.inputConnectors[number - 1].id])
        )
    }

    override fun output(number: Int): BuilderOutputConnector {
        return BlueprintConImpl(
            map = connectors,
            connector = requireNotNull(connectors[node.outputConnectors[number - 1].id])
        )
    }

    override fun build(): T {
        return buildInternal(
            pins.values.toList(),
            connectors.values.toList()
        )
    }
}

private open class BuilderFunctionImpl<T : BlueprintFunction>(
    private val node : T,
    private val serializer: KSerializer<T>,
    private val json: Json
) : BuilderFunctionNode<T>, Builder<T> {

    protected val pins = node.pins
        .associateBy { it.id }.toMutableMap()


    override fun outputPin(number: Int): BuilderOutputPin {
        return BuilderOutputPinImpl(
            map = pins,
            pin = checkNotNull(pins[node.outputPins[number - 1].id])
        )
    }

    override fun inputPin(number: Int): BuilderInputPin {
        return BuilderInputPinImpl(
            map = pins,
            pin = checkNotNull(pins[node.inputPins[number - 1].id])
        )
    }

    protected fun buildInternal(
        pins: List<Pin<Any?>>,
        connectors : List<Connector>?
    ): T {
        val element = json.encodeToJsonElement(serializer, node)
        val map = element.jsonObject.toMutableMap()

        val node = EmptyNode(
            pins = pins.sortedBy { it.order }.sortedBy { !it.isOut },
            connectors = connectors?.sortedBy { it.order }?.sortedBy { !it.isOut }.orEmpty()
        )

        map[EmptyNode::pins.name] = json
            .encodeToJsonElement(node).jsonObject[EmptyNode::pins.name]!!

        if (connectors != null) {
            map[EmptyNode::connectors.name] = json
                .encodeToJsonElement(node).jsonObject[EmptyNode::connectors.name]!!
        }
        return json.decodeFromJsonElement(serializer, JsonObject(map))
    }

    override fun build(): T {
        return buildInternal(pins.values.toList(), null)
    }
}

@Serializable
private class EmptyNode(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = emptyList(),
    override val connectors: List<Connector> = emptyList(),
) : BpProcedure<@Contextual Any?>() {

    override val factory: ProcedureFactory<in Any?>
        get() = TODO()
    override suspend fun execute(
        input: List<*>,
        context: Any?,
        pool: BlueprintNodesPool,
    ): Pair<Connector?, List<*>> {
        TODO("Not yet implemented")
    }
}

private class BlueprintConImpl(
    val map : MutableMap<Id, Connector>,
    val connector : Connector
) : BuilderInputConnector, BuilderOutputConnector {

    override fun connectWith(other: BuilderOutputConnector): BuilderInputConnector {
        return BlueprintConImpl(map, connect(other as BlueprintConImpl))
    }

    override fun connectWith(other: BuilderInputConnector): BuilderOutputConnector {
        return BlueprintConImpl(map, connect(other as BlueprintConImpl))
    }

    private fun connect(other: BlueprintConImpl): Connector {
        val newCon = connector.withReference(other.connector)
        map[newCon.id] = newCon
        val newCon2 = other.connector.withReference(connector)
        other.map[newCon2.id] = newCon2
        return newCon
    }
}

private class BuilderInputPinImpl(
    val map : MutableMap<Id, Pin<Any?>>,
    val pin : Pin<*>,
) : BuilderInputPin {

    init { require(!pin.isOut) }

    override fun connectWith(other: BuilderOutputPin): BuilderInputPin {
        other as BuilderOutputPinImpl
        val newPin = pin.withReference(other.pin)
        map[newPin.id] = newPin
        return BuilderInputPinImpl(map, newPin)
    }

    override fun withValue(value: String?): BuilderInputPin {
        val p = if (value != null)
            pin.withValue(value)
        else pin.withReference(null)
        map[p.id] = p
        return BuilderInputPinImpl(map, p)

    }

} private class BuilderOutputPinImpl(
    val map : MutableMap<Id, Pin<Any?>>,
    val pin : Pin<*>
) : BuilderOutputPin {

    init { require(pin.isOut) }

    override fun connectWith(other: BuilderInputPin): BuilderOutputPin {
        other as BuilderInputPinImpl
        val newPin = other.pin.withReference(pin)
        other.map[newPin.id] = newPin
        return BuilderOutputPinImpl(map, pin)
    }

    override fun withValue(value: String?): BuilderOutputPin {
        val p = if (value != null)
            pin.withValue(value)
        else pin.withReference(null)
        map[p.id] = p

        return BuilderOutputPinImpl(map, p)
    }
}