package org.botdesigner.blueprint.components

import kotlinx.serialization.SerialName

@kotlinx.serialization.Serializable
@SerialName("Blueprint")
data class Blueprint(
    val elements : List<BlueprintNode>
)


/**
 * Execute blueprint with single trigger and single (optional) return
 *
 * @param context launch value for [BlueprintTrigger]
 *
 * @return values returned by [BlueprintReturn]
 * */
suspend fun <T> Blueprint.execute(context : T) : List<Any?> {

    requireNotNull(findTrigger<T>()) {
        "Blueprint trigger not found"
    }.invoke(context, pool())

    return elements
        .filterIsInstance<BlueprintReturn<*>>()
        .lastOrNull()
        ?.value()
        .orEmpty()
}

/**
 * Execute specific blueprint trigger.
 * Can be used for executing blueprints with multiple triggers
 *
 * @param value launch value for [BlueprintTrigger]
 * */
suspend fun <T> Blueprint.execute(value : T, triggerId : String) {
    val trigger = findTrigger<T> { it.id.id == triggerId }
        ?: error("Blueprint trigger not found")

    trigger.invoke(value, pool())
}

fun Blueprint.initializeVariables(variables : Map<String, *>) {

    val bpVariables = elements
        .filterIsInstance<BlueprintVariable>()
        .filter { !it.isSetter }
        .groupBy { it.name }

    variables.forEach { (k, v) ->
        bpVariables[k]?.forEach {
            it.initialize(v)
        }
    }
}

@Suppress("UNCHECKED_CAST")
private fun <T> Blueprint.findTrigger(
    condition : (BlueprintTrigger<T>) -> Boolean = { true }
) : BlueprintTrigger<T>? =  elements.firstOrNull {
    it is BlueprintTrigger<*> && condition(it as BlueprintTrigger<T>)
} as? BlueprintTrigger<T>?


private fun Blueprint.pool() = BlueprintNodesPool(elements.associateBy { it.id })
