package org.botdesigner.blueprint

import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.geometry.Offset
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin

object EmptyBlueprintManager : BlueprintManager  {
    override val scale: Float = 1f
    override val translation: Offset = Offset.Zero
    override val components: Map<Id, BlueprintNodeStateHolder<*>> = emptyMap()
    override val pinLines: PinLinesMap = emptyMap()
    override val connectorLines: ConnectorLinesMap = emptyMap()
    override val selectedConnector: State<Connector?> = mutableStateOf(null)
    override val selectedPin: State<Pin<*>?> = mutableStateOf(null)
    override val isDragEnabled: Boolean
        get() = false
    override val isScaleEnabled: Boolean
        get() = false

    override fun clearSelection() {

    }
    override fun onDensityScaleChanged(densityScale: Float) {}

    override fun onDrag(delta: Offset, scaledFieldSize: Float) {}

    override fun onScale(delta: Float) {}

    override fun load(blueprint: Blueprint) {}

    override fun addNode(component: BlueprintNode): Boolean = false

    override fun deleteNode(id: Id) {}

    override fun onPinClicked(pin: Pin<*>) {}

    override fun onPinAdded(pin: Pin<*>) {}

    override fun onPinRemoved(pin: Pin<*>) {}

    override fun resetPin(pin: Pin<*>) {}

    override fun onConnectorRemoved(connector: Connector) {}

    override fun onConnectorAdded(connector: Connector) {}

    override fun onConnectorClicked(c: Connector) {}

    override fun onComponentTap(component: BlueprintNodeStateHolder<*>) {}

    override fun save(): Blueprint {
        TODO("Not available for the EmptyBlueprintManager")
    }

    override fun getElementById(id: Id): BlueprintNode {
        TODO("Not available for the EmptyBlueprintManager")
    }

    override val elements: List<BlueprintNode> = emptyList()
}