package org.botdesigner.blueprint.ui

import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection

object ConnectorShape : Shape {
    override fun createOutline(
        size: Size,
        layoutDirection: LayoutDirection,
        density: Density
    ): Outline {
        return Outline.Generic(path = Path().apply {
            lineTo(0f,0f)
            relativeLineTo(0f,size.height)
            lineTo(size.width/2,size.height)
            lineTo(size.width,size.height/2)
            lineTo(size.width/2,0f)
            lineTo(0f,0f)
        })
    }
}