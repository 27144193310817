package io.github.alexzhirkevich.onetap

import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlin.coroutines.cancellation.CancellationException

interface SignInClient<out R> {

    suspend fun launch() : SignInResult<R>

    suspend fun logout() {}
}

/**
 * Abstract [SignInClient] implementation that has access to the native view controller
 * */
abstract class AbstractSignInClient<out R> : SignInClient<R> {
    final override suspend fun launch(): SignInResult<R> {
        return try {
            val controller = withContext(Dispatchers.Main) {
                findViewController()
            }
            launch(controller)
        } catch (t: CancellationException) {
            throw t
        } catch (t: Throwable) {
            SignInResult.Failure(
                SignInException(
                    message = "Authorization failed",
                    cause = t
                )
            )
        }
    }

    final override suspend fun logout() {
        try {
            val controller = withContext(Dispatchers.Main) {
                findViewController()
            }
            logout(controller)
        } catch (t: CancellationException) {
            throw t
        } catch (t: Throwable) {
            throw SignInException(
                message = "Authorization failed",
                cause = t
            )
        }
    }

    protected abstract suspend fun launch(
        viewController: NativeViewController
    ): SignInResult<R>


    protected open suspend fun logout(
        viewController: NativeViewController
    ) {
    }
}


fun <T,R> SignInClient<T>.map(block : (T) -> R) : SignInClient<R> =
    MappedSignInClient(this, block)

internal expect fun findViewController() : NativeViewController


private class MappedSignInClient<T,R>(
    private val client : SignInClient<T>,
    private val block : (T) -> R
) : SignInClient<R> {
    override suspend fun launch(): SignInResult<R> {
        return when (val result = client.launch()) {
            is SignInResult.Cancelled -> result
            is SignInResult.Failure -> result
            is SignInResult.Success -> SignInResult.Success(block(result.result))
        }
    }

    override suspend fun logout() = client.logout()

}
