package org.botdesigner.botblueprints.telegram.pins

import androidx.compose.ui.graphics.Color
import org.botdesigner.telegram.Message
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.components.*
import org.botdesigner.botblueprints.Message


object MessagePinFactory : PinFactory<Message>{
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): Pin<Message> {
        return MessagePin(
            id = id,
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
            required = required
        )
    }

}

@kotlinx.serialization.Serializable
@SerialName("TgMessagePin")
data class MessagePin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: Message? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : Pin<Message>() {

    override val color: Color get() = BlueprintColors.Message

    override fun factory(pool: BlueprintNodesPool): PinFactory<Message> {
        return MessagePinFactory
    }

    override fun withReference(parent: Pin<*>?): Pin<Message> {
        return copy(parentId = parent?.elId, parentPinId = parent?.id, value = null)
    }

    override fun withValue(value: String): Pin<Message> {
        return copy(value = null, parentId = null, parentPinId = null)
    }
}

fun PinsDirectionalScope.message(name : String) {
    pin { order, isOut ->
        MessagePin(
            id = Id.randomId(),
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
        )
    }
}