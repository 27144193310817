@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.`object`

import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpExpression
import org.botdesigner.blueprint.components.functions.ExpressionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpObjectToString")
public class BpObjectToString(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.components.GenericPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = false
              )
          }
      }
      },
) : BpExpression() {
  override val summary: AnnotatedString
    get() = "Convert object to string".trimIndent().toAnnotatedString()

  override val name: String
    get() = "Object To String"

  override val factory: ExpressionFactory
    get() = ::BpObjectToString

  override val expression: String
    get() = "-->"

  override suspend fun calculate(input: List<Any?>): List<Any?> = listOf(ObjectToString(
  		input[0] as kotlin.Any?
  	))
}
