package org.botdesigner.blueprint.stdlib.serialization

import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.stdlib.pins.BooleanPin
import org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory
import org.botdesigner.blueprint.stdlib.pins.DateTimePin
import org.botdesigner.blueprint.stdlib.pins.DividerPin
import org.botdesigner.blueprint.stdlib.pins.DoubleArrayPin
import org.botdesigner.blueprint.stdlib.pins.DoubleArrayPinFactory
import org.botdesigner.blueprint.stdlib.pins.DoublePin
import org.botdesigner.blueprint.stdlib.pins.DoublePinFactory
import org.botdesigner.blueprint.stdlib.pins.EnumPin
import org.botdesigner.blueprint.components.GenericArrayPin
import org.botdesigner.blueprint.components.GenericArrayPinFactory
import org.botdesigner.blueprint.components.GenericPin
import org.botdesigner.blueprint.components.GenericPinFactory
import org.botdesigner.blueprint.stdlib.pins.LongArrayPin
import org.botdesigner.blueprint.stdlib.pins.LongArrayPinFactory
import org.botdesigner.blueprint.stdlib.pins.LongPin
import org.botdesigner.blueprint.stdlib.pins.LongPinFactory
import org.botdesigner.blueprint.stdlib.pins.StringArrayPin
import org.botdesigner.blueprint.stdlib.pins.StringArrayPinFactory
import org.botdesigner.blueprint.stdlib.pins.StringPin
import org.botdesigner.blueprint.stdlib.pins.StringPinFactory

internal val StdlibPinModule = SerializersModule {

    polymorphic(Pin::class){
        subclass(DividerPin::class)
        subclass(StringPin::class)
        subclass(StringArrayPin::class)
        subclass(BooleanPin::class)
        subclass(LongPin::class)
        subclass(LongArrayPin::class)
        subclass(DoublePin::class)
        subclass(DoubleArrayPin::class)
        subclass(GenericPin::class)
        subclass(GenericArrayPin::class)
        subclass(EnumPin::class)
        subclass(DateTimePin::class)
    }

    polymorphic(PinFactory::class){
        subclass(StringPinFactory::class)
        subclass(StringArrayPinFactory::class)
        subclass(BooleanPinFactory::class)
        subclass(LongPinFactory::class)
        subclass(LongArrayPinFactory::class)
        subclass(DoublePinFactory::class)
        subclass(DoubleArrayPinFactory::class)
        subclass(GenericPinFactory::class)
        subclass(GenericArrayPinFactory::class)
    }
}