package org.botdesigner.blueprint.stdlib.pins

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.KeyboardType
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.components.PinsDirectionalScope
import org.botdesigner.blueprint.components.isPinActive
import org.botdesigner.blueprint.ui.pins.TextFieldPinWidget

@kotlinx.serialization.Serializable
@SerialName("FloatPinFactory")
object DoublePinFactory: PinFactory<Double> {

    override fun array(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = DoubleArrayPinFactory.create(
        id = id,
        order = order,
        elementId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )

    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): Pin<Double> = DoublePin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}

@kotlinx.serialization.Serializable
@SerialName("FloatPin")
data class DoublePin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: Double? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : Pin<Double>() {

    override val color : Color get() = BlueprintColors.Float

    override fun withReference(parent: Pin<*>?): DoublePin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }


    override fun withValue(value: String): DoublePin {
        return copy(value = value.toDoubleOrNull(), parentId = null, parentPinId = null)
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<Double> {
        return DoublePinFactory
    }

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected : Boolean,
        pool: BlueprintNodesPool
    ) {

        TextFieldPinWidget(
            color = color,
            name = name,
            isActive = pool.isPinActive(this@DoublePin),
            isOut = isOut,
            required = required,
            value = value,
            onTap = { onTap(this@DoublePin) },
            modifier = modifier,
            filter  = {
                it.replace(',', '.').filterIndexed { index, char ->
                    char.isDigit() || index == 0 && char == '-' ||
                            char == '.' && it.count { it == '.' } == 1
                }
            },
            placeholder = "0.0",
            inputValueEnabled = true,
            isSelected = isSelected,
            keyboardOptions = KeyboardOptions(
                keyboardType = KeyboardType.Number
            ),
            onChangeInputValue = onInputChanged
        )
    }
}

fun PinsDirectionalScope.double(
    name: String = "",
    order : UInt? = null,
    default : Double? = null,
    required: Boolean = false
) {
    pin { o, i ->
        DoublePin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
            value = default,
            required = required
        )
    }
}