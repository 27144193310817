
package org.botdesigner.ui.screens.content

import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.Orientation
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.Child
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.extensions.compose.stack.animation.predictiveback.predictiveBackAnimation
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimation
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.operator.map
import io.github.alexzhirkevich.cupertino.LocalContainerColor
import io.github.alexzhirkevich.cupertino.Surface
import io.github.alexzhirkevich.cupertino.decompose.cupertinoPredictiveBackAnimation
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.debounce
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.shared.domain.content.ContentComponent
import org.botdesigner.ui.common.AdaptiveVerticalDivider
import org.botdesigner.ui.common.PlatformDialogContainer
import org.botdesigner.ui.common.SplitPane
import org.botdesigner.ui.common.SplitPaneSeparator
import org.botdesigner.ui.common.materialStackAnimator
import org.botdesigner.ui.common.rememberPlatformDialogState
import org.botdesigner.ui.common.rememberSplitPaneState
import org.botdesigner.ui.common.subscribeWithState
import org.botdesigner.ui.screens.account.AccountScreen
import org.botdesigner.ui.screens.appearance.AppearanceScreen
import org.botdesigner.ui.screens.blueprint.BlueprintScreen
import org.botdesigner.ui.screens.bot.BotScreen
import org.botdesigner.ui.screens.changepassword.ChangePasswordScreen
import org.botdesigner.ui.screens.create.CreateOrEditBotScreen
import org.botdesigner.ui.screens.guide.BlueprintGuideScreen
import org.botdesigner.ui.screens.guide.PinsGuideScreen
import org.botdesigner.ui.screens.oauth.google.GoogleOAuthScreen
import org.botdesigner.ui.screens.sessions.SessionsScreen
import org.botdesigner.ui.screens.settings.EditorSettingsScreen
import org.botdesigner.ui.screens.subscription.PremiumScreen
import org.botdesigner.ui.screens.tabs.TabsScreen

internal val SectionContextMaxWidth = 600.dp

private val PaneWidthRange = (250.dp)..(400.dp)

@OptIn(ExperimentalMaterial3Api::class, FlowPreview::class)
@Composable
internal fun ContentScreen(
    component : ContentComponent
) {
    val dialogState = rememberPlatformDialogState(
        initiallyExpanded = component.dialog.value.child != null
    )
    val dialog by component.dialog.subscribeWithState(dialogState)

    val idiom = LocalInterfaceIdiom.current

    LaunchedEffect(idiom) {
        component.onIdiomChanged(idiom)
    }

    val stack by component.stack.subscribeAsState()

    PlatformDialogContainer(
        modifier = Modifier
            .fillMaxSize(),
        onDismissRequest = component::onDialogDismissed,
        state = dialogState,
        dialog = {
            dialog.child?.instance?.let {
                Dialog(dialog = it)
            } ?: Box(Modifier.fillMaxSize())
        }
    ) {

        val paneState = rememberSplitPaneState(
            orientation = Orientation.Horizontal,
            initialPaneSize = component.appearance.paneWidth.dp.coerceIn(PaneWidthRange),
            paneSizeRange = { PaneWidthRange }
        )

        LaunchedEffect(paneState) {
            snapshotFlow {
                paneState.size
            }.debounce(200).collect {
                component.onPaneResize(it.value)
            }
        }


        val paneChild = stack.pane

        if (paneChild != null) {
            SplitPane(
                state = paneState,
                enabled = !CurrentPlatform.isMobile,
                content1 = {
                    val bgColor = MaterialTheme.colorScheme.surface

                    Surface( // cupertino surface
                        modifier = Modifier.fillMaxSize(),
                        color = bgColor
                    ) {
                        Content(paneChild.instance)
                    }
                },
                separator = {
                    SplitPaneSeparator(
                        state = paneState,
                        separator = {
                            AdaptiveVerticalDivider(modifier = it)
                        }
                    )
                },
                content2 = {
                    MainContent(component, true)
                }
            )
        } else {
            MainContent(component, false)
        }
    }
}

@OptIn(ExperimentalDecomposeApi::class)
@Composable
private fun MainContent(
    component: ContentComponent,
    isPane : Boolean
) {
    val bgColor = MaterialTheme.colorScheme.background

    Children(
        modifier = Modifier
            .fillMaxSize()
            .background(bgColor),
        stack = remember(component) {
            component.stack.map {
                ChildStack(
                    active = it.active ?: Child.Created(
                        Unit,
                        ContentComponent.Child.Empty
                    ),
                    backStack = it.backStack
                )
            }
        },
        animation = when {
            isPane  -> null

            CurrentPlatform == Platform.Ios -> cupertinoPredictiveBackAnimation(
                backHandler = component.backHandler,
                onBack = component::onBack
            )

            CurrentPlatform == Platform.Android -> predictiveBackAnimation(
                backHandler = component.backHandler,
                onBack = component::onBack,
                fallbackAnimation = stackAnimation(
                    animator = materialStackAnimator(),
                    disableInputDuringAnimation = true
                ),
            )

            else -> stackAnimation(
                animator = materialStackAnimator(),
                disableInputDuringAnimation = true
            )
        }
    ) {
        CompositionLocalProvider(
            LocalContainerColor provides bgColor
        ) {
            Content(child = it.instance)
        }
    }
}


@Composable
private fun Content(
    child: ContentComponent.Child<*>,
    modifier: Modifier = Modifier.fillMaxSize()
) {
    when (child) {
        is ContentComponent.Child.Tabs -> TabsScreen(child.component, modifier)
        is ContentComponent.Child.Bot -> BotScreen(child.component, modifier)
        is ContentComponent.Child.Blueprint -> BlueprintScreen(
            child.component,
            modifier
        )
        is ContentComponent.Child.Sessions -> SessionsScreen(child.component, modifier)
        is ContentComponent.Child.Appearance -> AppearanceScreen(child.component, modifier)
        is ContentComponent.Child.Settings -> EditorSettingsScreen(child.component, modifier)
        is ContentComponent.Child.Account -> AccountScreen(child.component, modifier)
        is ContentComponent.Child.Empty -> {
            Spacer(modifier.fillMaxSize())
        }
    }
}

@Composable
private fun Dialog(
    dialog: ContentComponent.Dialog<*>,
    modifier: Modifier = Modifier.fillMaxSize()
) {
    when (dialog) {
        is ContentComponent.Dialog.Subscription -> PremiumScreen(
            component = dialog.component,
            modifier =  Modifier
                .sizeDesktop(400.dp, Dp.Unspecified)
                .then(modifier)
        )

        is ContentComponent.Dialog.GoogleOauth -> GoogleOAuthScreen(
            component = dialog.component,
            modifier = Modifier
                .sizeDesktop(350.dp,450.dp)
                .then(modifier)
        )

        is ContentComponent.Dialog.ChangePassword -> ChangePasswordScreen(
            component = dialog.component,
            modifier = Modifier
                .sizeDesktop(370.dp, 400.dp)
                .then(modifier)
        )


        is ContentComponent.Dialog.CreateOrEditBot -> CreateOrEditBotScreen(
            component = dialog.component,
            modifier = Modifier
                .sizeDesktop(370.dp, 500.dp)
                .then(modifier)
        )

        is ContentComponent.Dialog.BlueprintGuide ->
            BlueprintGuideScreen(dialog.component)

        is ContentComponent.Dialog.PinsGuide ->
            PinsGuideScreen(dialog.component)

    }
}

private fun Modifier.sizeDesktop(
    width : Dp,
    height : Dp
) =
    if (CurrentPlatform.isMobile) this else sizeIn(
        maxWidth = width,
        maxHeight = height,
    )
