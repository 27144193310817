@file:Suppress("NOTHING_TO_INLINE", "FunctionName")

package org.botdesigner.blueprint.stdlib.functions.boolean

import org.botdesigner.blueprint.components.BlueprintExpressions
import org.botdesigner.blueprint.generator.BlueprintExpression
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.store.BlueprintNodeCategory

@BlueprintExpression(
    expression = BlueprintExpressions.And,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Boolean,
    displayName = "And",
    summary = "Check if all booleans are true (logical 'and', '&'). Null Boolean is considered false",
    serialName = "BpBooleanAnd"
)
inline fun BooleanAnd( vararg v : Boolean?) :  Boolean {
    return v.all { it == true }
}

@BlueprintExpression(
    expression = BlueprintExpressions.Or,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Boolean,
    displayName = "Or",
    summary = "Check if at least one of booleans is true (logical 'or', '|'). Null Boolean is considered false",
    serialName = "BpBooleanOr"
)
inline fun BooleanOr( vararg v : Boolean?) :  Boolean {
    return v.any { it == true }
}

@BlueprintExpression(
    expression = BlueprintExpressions.Not,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Boolean,
    displayName = "Not",
    summary = "Reverse boolean. In input is null then return value is also null",
    serialName = "BpBooleanNot"
)
inline fun BooleanNot(  v : Boolean?) :  Boolean? {
    return v?.not()
}
