package org.botdesigner.blueprint.components.functions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Shape
import kotlinx.serialization.Contextual
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.BlueprintFunction
import org.botdesigner.blueprint.components.BlueprintFunctionStateHolder
import org.botdesigner.blueprint.components.BlueprintMutablePinsOwner
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.outputPins
import org.botdesigner.blueprint.components.pinValueWithExceptionMark
import org.botdesigner.blueprint.ui.components.Function
import org.botdesigner.blueprint.ui.pins.PinsExtender


typealias FunctionFactory = (
    coordinate: Point,
    id : Id,
    pins : List<Pin<@Contextual Any?>>,
) -> BpFunction

/**
 * @see [BlueprintFunction]
 * */
@kotlinx.serialization.Serializable
abstract class BpFunction : BlueprintFunction, BlueprintMutablePinsOwner {

    /**
     * Clone factory of THIS object
     * */
    abstract val factory : FunctionFactory

    /**
     * Function calculation operation.
     *
     * @param input input pin values in correct order
     * @return output pin values in correct order
     * */
    abstract suspend fun calculate(input : List<*>) : List<*>

    @Suppress("UNCHECKED_CAST")
    final override suspend fun <T> pinValue(pin: Pin<T>, pool: BlueprintNodesPool): T? {
        val inputs = pins
            .filterNot { it.isOut || it.isUtil }
            .map { super.pinValue(it, pool) }

        return pinValueWithExceptionMark(pin) {
            val outputs = calculate(inputs)

            outputPins
                .indexOfFirst { it.id == pin.id }
                .takeIf { it in outputs.indices }
                ?.let { outputs[it] as T? }
        }
    }


    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> {
        return BpFunctionStateHolder(
            element = this,
            manager = manager
        )
    }
}

open class BpFunctionStateHolder<T : BpFunction>(
    element : T,
    manager: BlueprintManager,
) : BlueprintFunctionStateHolder<T>(
    element = element,
    manager = manager
) {

    @Composable
    open fun Component(
        manager: BlueprintManager,
        modifier: Modifier,
        fieldSize : Float,
        shape: Shape,
        also : @Composable (Float) -> Unit
    ){
        Function(
            name = element.name,
            color = element.color,
            icon = element.icon,
            modifier = modifier,
            manager = manager,
            also = also,
            fieldSize = fieldSize,
            shape = shape
        )
    }

    @Composable
    open fun Also(elementWidth : Float) {}

    @Composable
    override fun Draw(
        modifier: Modifier,
        fieldSize: Float,
        shape: Shape
    ) {
        Component(
            modifier = modifier,
            manager = manager,
            shape = shape,
            fieldSize = fieldSize
        ) {
            if (element.isExtendable) {
                PinsExtender()
            }
            Also(it)
        }
    }

    @Suppress("UNCHECKED_CAST")
    override fun save(): T {
        return element.factory(
            position.value,
            element.id,
            pins
        ) as T
    }

    @Composable
    private fun PinsExtender() {

        var addEnabled by remember {
            mutableStateOf(element.isAddEnabled(this))
        }

        var removeEnabled by remember {
            mutableStateOf(element.isRemoveEnabled(this))
        }

        PinsExtender(
            addEnabled = addEnabled,
            popEnabled = removeEnabled,
            onAddPin = {
                element.run {
                    onAddPins(manager)
                }
                addEnabled = element.isAddEnabled(this)
                removeEnabled = element.isRemoveEnabled(this)
            },
            onPopPin = {
                element.run {
                    onRemovePins(manager)
                }
                addEnabled = element.isAddEnabled(this)
                removeEnabled = element.isRemoveEnabled(this)
            }
        )
    }
}
