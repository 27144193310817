package org.botdesigner.ui.screens.bot.bpcontext

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import org.botdesigner.resources.SharedRes
import org.botdesigner.ui.common.dialogs.ConfirmationDialog
import org.botdesigner.ui.common.dialogs.ConfirmationDialogTextField
import org.botdesigner.shared.domain.content.bot.bpcontext.BlueprintContextSelectionDialogComponent
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

@Composable
internal fun BlueprintContextSelectionDialog(
    component: BlueprintContextSelectionDialogComponent,
) {
    val focus = remember { FocusRequester() }

    LaunchedEffect(focus) {
        delay(500)
        focus.requestFocus()
    }

    ConfirmationDialog(
        title = string(SharedRes.strings.new_command),
        content = {
            Column(
                Modifier.widthIn(max = 250.dp),
                verticalArrangement = Arrangement.spacedBy(Dimens.Spacing.Large)
            ) {
                Text(
                    text = string(SharedRes.strings.command_select_desc),
                    modifier = Modifier.padding(horizontal = Dimens.Spacing.Medium),
                )

                ConfirmationDialogTextField(
                    modifier = Modifier
                        .focusRequester(focus),
                    value = component.input.collectAsState().value,
                    onValueChange = component::onInputChanged,
                    placeholder = {
                        Text(component.data.type.contextName?.let {
                            '/' + string(it).lowercase()
                        }.orEmpty())
                    },
                )
            }
        },
        onOk = component::onConfirm,
        onCancel = component::onCancel,
        onDismiss = component::onCancel
    )
}