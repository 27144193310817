package org.botdesigner.blueprint.stdlib.functions.special


import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.widthIn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.util.fastForEach
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintFunctionStateHolder
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.BlueprintProcedure
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.GenericPinFactory
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.generic
import org.botdesigner.blueprint.components.inputPins
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.components.parentPin
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.blueprint.ui.components.Function
import org.botdesigner.blueprint.ui.pins.PinsExtender

@kotlinx.serialization.Serializable
@SerialName("BpSwitch")
class BpSwitch(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id){
        input {
            generic("Value")
            generic("Case")
        }
    },
    override val connectors: List<Connector> = Connectors(id){
        input()
        output("Default")
        output()
    }
) : BlueprintProcedure<@Contextual Any?> {

    override val color: Color
        get() = BlueprintColors.Util

    override val summary: AnnotatedString
        get() = "Selects next branch based on the <b>Value</b>. First branch with <b>Case</b> equal to <b>Value</b> will be triggered. <b>Default</b> will be called if no suitable cases were found"
            .toAnnotatedString()

    override suspend fun invoke(context: Any?, pool: BlueprintNodesPool) {
        val v = pinValue(inputPins.first(), pool)

        val next = inputPins
            .drop(1)
            .map { pinValue(it, pool) }
            .indexOfFirst { v == it }

        outputConnectors[next + 1].invokeNext(context, pool)
    }


    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> =
        object : BlueprintFunctionStateHolder<BpSwitch>(
            element = this,
            manager = manager
        ) {

            private val casePins
                get() = super.pins.drop(1)
            private val caseConnectors
                get() = super.connectors.filter(Connector::isOut).drop(1)



            override fun shouldDrawConnector(connector: Connector): Boolean {
                return connector.order <= 1u
            }

            override fun shouldDrawPin(pin: Pin<*>): Boolean {
                return pin.order == 0u
            }

            override fun updatePin(
                pin: Pin<*>,
            ) {
                super.updatePin(pin)

                if (pin.id != pins[0].id) {
                    return
                }

                val factory = pins.first()
                    .parentPin(manager)
                    ?.factory(manager)
                    ?: GenericPinFactory

                casePins
                    //don't reset connected pins
                    .filterNot { factory == GenericPinFactory && (it.parentPinId != null || it.value != null) }
                    .forEach {
                        if (factory::class != it.factory(manager)::class) {
                            manager.resetPin(it)
                            super.updatePin(
                                factory.create(
                                    id = it.id,
                                    order = it.order,
                                    elementId = it.elId,
                                    name = it.name,
                                    isOut = it.isOut,
                                ),
                            )
                        }
                    }
            }

            @Composable
            override fun Draw(
                modifier: Modifier,
                fieldSize: Float,
                shape: Shape
            ) {
                var w by remember {
                    mutableStateOf(0)
                }
                Function(
                    modifier = modifier.onSizeChanged {
                        w = it.width
                    },
                    color = element.color,
                    name = element.name,
                    manager = manager,
                    fieldSize = fieldSize,
                    shape = shape,
                    icon = element.icon,
                ) { width ->

                    casePins.zip(caseConnectors).fastForEach { (p, c) ->

                        var rowInParent by remember {
                            mutableStateOf(Offset.Zero)
                        }

                        var width1 by remember {
                            mutableStateOf(0)
                        }
                        var width2 by remember {
                            mutableStateOf(0)
                        }

                        Row(
                            modifier = Modifier
                                .widthIn(min = with(LocalDensity.current) { width.toDp() })
                                .onGloballyPositioned {
                                    rowInParent = it.positionInParent()
                                },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {

                            DrawPins(
                                parentInComponent = rowInParent,
                                isOut = false,
                                pool = manager,
                                onPinClicked = manager::onPinClicked,
                                modifier = Modifier.onSizeChanged {
                                    width1 = it.width
                                },
                                drawIf = { it.id == p.id }
                            )

                            DrawConnectors(
                                parentInComponent = rowInParent,
                                isOut = true,
                                modifier = Modifier.onSizeChanged {
                                    width2 = it.width
                                },
                                onConnectorClicked = manager::onConnectorClicked,
                                drawIf = { it.id == c.id }
                            )
                        }
                    }
                    PinsExtender(
                        popEnabled = casePins.size>1,
                        onAddPin = {
                            val pinFactory = pins.first().factory(manager)

                            appendPin {
                                pinFactory.create(
                                    id = it,
                                    order = pins.size.toUInt(),
                                    elementId = element.id,
                                    name = "Case",
                                    isOut = false
                                )
                            }
                            appendConnector{
                                Connector(
                                    id = it,
                                    order = connectors.size.toUInt(),
                                    elId = element.id,
                                    isOut = true
                                )
                            }
                        },
                        onPopPin = {
                            removePin(casePins.last())

                            removeConnector(caseConnectors.last())
                        }
                    )
                }
            }

            override fun save(): BpSwitch {
                return BpSwitch(
                    coordinate = position.value,
                    pins = pins,
                    connectors = connectors,
                    id = id
                )
            }
        }
}