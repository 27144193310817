package org.botdesigner.blueprint.store

import org.botdesigner.blueprint.components.BlueprintNode

/**
 * Category of the [BlueprintNode] displayed in element's selection window
 * */
data class BlueprintNodeCategory(val name : String) {

    object Stdlib {
        const val Object = "Object"
        const val Integer = "Integer"
        const val String = "String"
        const val Float = "Float"
        const val Boolean = "Boolean"
        const val DateTime = "Date & Time"
        const val Special = "Special"
        const val Array = "Array"
    }

    companion object {
        const val StdlibName = "Standard"
        const val NetworkName = "Network"
        const val HttpName = "Http"
        const val IoName = "IO"
        val Stdlib = BlueprintNodeCategory(StdlibName)
        val Network = BlueprintNodeCategory("Network")
        val Variables = BlueprintNodeCategory("Variables")
    }
}
