package org.botdesigner.ui.common

import androidx.compose.foundation.layout.RowScope
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.DeleteSweep
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import dev.icerock.moko.resources.compose.stringResource
import io.github.alexzhirkevich.cupertino.CupertinoSwipeBox
import io.github.alexzhirkevich.cupertino.CupertinoSwipeBoxItem
import io.github.alexzhirkevich.cupertino.CupertinoSwipeBoxState
import io.github.alexzhirkevich.cupertino.CupertinoSwipeBoxValue
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.SwipeBoxBehavior
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.icons.CupertinoIcons
import io.github.alexzhirkevich.cupertino.icons.outlined.Trash
import io.github.alexzhirkevich.cupertino.rememberCupertinoSwipeBoxState
import kotlinx.coroutines.flow.Flow
import org.botdesigner.resources.SharedRes

@OptIn(ExperimentalCupertinoApi::class)
@Composable
internal fun rememberSwipeToDeleteState(onDelete : () -> Unit) : CupertinoSwipeBoxState {
    return rememberCupertinoSwipeBoxState(
        confirmValueChange = { dv ->
            if (dv == CupertinoSwipeBoxValue.DismissedToStart) {
                onDelete()
            }
            true
        },
    )
}

@OptIn(ExperimentalCupertinoApi::class
)
@Composable
internal fun SwipeToDelete(
    state: CupertinoSwipeBoxState,
    onDelete: () -> Unit,
    modifier: Modifier = Modifier,
    label : Boolean = true,
    discardFlow : Flow<Any?>? = null,
    items : @Composable () -> Unit = {},
    content : @Composable RowScope.() -> Unit
) {

    discardFlow?.let {
        LaunchedEffect(state, it) {
            it.collect {
                state.reset()
            }
        }
    }


    CupertinoSwipeBox(
        modifier = modifier,
        state = state,
        startToEndBehavior = SwipeBoxBehavior.Disabled,
        items = {
            if (state.dismissDirection != CupertinoSwipeBoxValue.Collapsed) {

                if (label) {
                    CupertinoSwipeBoxItem(
                        color = MaterialTheme.colorScheme.error,
                        icon = {
                            Icon(
                                imageVector = AdaptiveIcons.vector(
                                    material = { Icons.Outlined.DeleteSweep },
                                    cupertino = { CupertinoIcons.Default.Trash }
                                ),
                                contentDescription = null,
                            )
                        },
                        label = {
                            Text(stringResource(SharedRes.strings.delete))
                        },
                        onClick = onDelete
                    )
                } else {
                    CupertinoSwipeBoxItem(
                        color = MaterialTheme.colorScheme.error,
                        onClick = onDelete
                    ){
                        Icon(
                            imageVector = AdaptiveIcons.vector(
                                material = { Icons.Outlined.DeleteSweep },
                                cupertino = { CupertinoIcons.Default.Trash }
                            ),
                            contentDescription = null,
                        )
                    }
                }
                items()
            }
        },
        content = content
    )
}