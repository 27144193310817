@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.special

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Pair
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpWhile")
public class BpWhile(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Condition",
              isOut = i,
              required = true
          )
                  }
          }
         
          
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("End")
          output("Loop")
      },
) : BpProcedure<@Contextual Any?>() {
  override val summary: AnnotatedString
    get() =
        "Repeat <b>Loop</b> action while <b>Condition</b> is true".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4287137928)

  override val factory: ProcedureFactory<@Contextual Any?>
    get() = ::BpWhile

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual Any?,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(While(
  		requireNotNull(input[0] as kotlin.Boolean?) { 
  "Condition can't be null" 
  },
  		                    {
                          results = emptyList<Any?>()
  outputConnectors[1].invokeNext(context, pool)
                      }
  	))}
}
