package org.botdesigner.shared.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import org.botdesigner.resources.SharedRes
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.contract


@Immutable
sealed interface UIState<out T> {

    @Immutable
    data object Loading : UIState<Nothing>

    @Immutable
    data class Success<out T>(val value : T) : UIState<T>

    @Immutable
    data class Empty(
        val message : StringDesc? = null,
    ) : UIState<Nothing>

    @Immutable
    data class Error(
        val title : StringDesc? = null,
        val message : StringDesc? = null,
        val error : Throwable? = null
    ) : UIState<Nothing>

    companion object {
        val DefaultError = Error(
            error = null,
            title = SharedRes.strings.error_oops.desc(),
            message = SharedRes.strings.error_something_went_wrong.desc(),
        )
    }
}

@Composable
fun UIState.Error.title() : StringDesc {
    return title ?: SharedRes.strings.error_oops.desc()
}

@Composable
fun UIState.Error.message() : StringDesc {
    return message ?: SharedRes.strings.error_something_went_wrong.desc()
}


@OptIn(ExperimentalContracts::class)
fun <T> UIState<T>.valueOrNull(): T? {
    contract {
        returnsNotNull() implies (this@valueOrNull is UIState.Success<T>)
    }
    return (this as? UIState.Success)?.value
}

fun <T> UIState<T>.valueOrElse(block : (error : Throwable?) -> T) =
    valueOrNull() ?: block((this as? Error)?.cause)

suspend fun <T> DataState(get : suspend  () -> T) : UIState<T> =
    runCatching { UIState.Success(get()) }.getOrElse {
        UIState.Error(null, null, it)
    }

@OptIn(ExperimentalContracts::class)
fun UIState<*>.isSuccess() : Boolean {
    contract {
        returns(true) implies (this@isSuccess is UIState.Success)
    }
    return this is UIState.Success
}

@OptIn(ExperimentalContracts::class)
fun UIState<*>.isLoading() : Boolean {
    contract {
        returns(true) implies (this@isLoading is UIState.Loading)
    }
    return this is UIState.Loading
}