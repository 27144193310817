package org.botdesigner.shared.util.managers

import org.botdesigner.blueprint.integrations.context.PushNotification
import org.botdesigner.blueprint.integrations.context.PushNotificationContext


interface NotificationManager {

    suspend fun token() : String

    suspend fun subscribeToTopic(topic : String)

    suspend fun unsubscribeFromTopic(topic : String)

    suspend fun deleteToken()

    suspend fun schedule(notification: PushNotification)
}

fun NotificationManager.asPushNotificationContext() = object : PushNotificationContext {
    override suspend fun sendPushNotification(notification: PushNotification) {
        schedule(notification)
    }
}