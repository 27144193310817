package org.botdesigner.ui.screens.oauth.google

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.util.fastForEach
import io.github.alexzhirkevich.cupertino.CupertinoButtonSize
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveButton
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveSwitch
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.section.CupertinoSection
import io.github.alexzhirkevich.cupertino.section.SectionItem
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.oauth.google.GoogleOAuthComponent
import org.botdesigner.shared.domain.content.oauth.google.GoogleOAuthScope
import org.botdesigner.ui.common.PlatformDialogTopBar
import org.botdesigner.ui.screens.account.OauthSignProgressDialog
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

@OptIn(ExperimentalAdaptiveApi::class, ExperimentalCupertinoApi::class,
    ExperimentalStdlibApi::class
)
@Composable
internal fun GoogleOAuthScreen(
    component: GoogleOAuthComponent,
    modifier: Modifier = Modifier
) {

    val state by component.state.collectAsState()

    if (state.isSignInProgress){
        OauthSignProgressDialog(onCancel = component::onCancel)
    }

    val sectionColor = if (CurrentPlatform == Platform.Ios)
        CupertinoTheme.colorScheme.tertiarySystemGroupedBackground
    else CupertinoTheme.colorScheme.secondarySystemGroupedBackground

    AdaptiveScaffold(
        modifier = modifier,
        containerColor = Color.Transparent,
        topBar = {
            PlatformDialogTopBar(
                title = {
                    Text(string(SharedRes.strings.link_google))
                },
                isTransparent = CurrentPlatform.isMobile,
                onDismissRequest = component::onBack
            )
        }
    ) {
        Box {
            Column(
                modifier = Modifier
                    .padding(it)
                    .fillMaxSize()
                    .verticalScroll(rememberScrollState())
            ) {

                CupertinoSection(
                    caption = {
                        Text(string(SharedRes.strings.select_google_features))
                    },
                ) {

                }

                GoogleOAuthScope.entries.fastForEach { scope ->

                    CupertinoSection(
                        caption = {
                            Text(string(scope.caption))
                        },
                        color = sectionColor,
                    ) {
                        SectionItem(
                            trailingContent = {
                                AdaptiveSwitch(
                                    checked = scope in state.scopes,
                                    onCheckedChange = {
                                        component.onScopeChanged(scope, it)
                                    }
                                )
                            }
                        ) {
                            Text(string(scope.title))
                        }
                    }
                }

                Spacer(Modifier.height(64.dp))
            }
            AdaptiveButton(
                modifier = Modifier.fillMaxWidth()
                    .align(Alignment.BottomCenter)
                    .padding(Dimens.Spacing.Large),
                onClick = component::onSignClicked,
                adaptation = {
                    cupertino {
                        size = CupertinoButtonSize.Large
                    }
                }
            ) {
                Text(string(SharedRes.strings.action_continue))
            }
        }
    }
}