
package org.botdesigner.ui

import androidx.compose.foundation.LocalIndication
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.arkivanov.essenty.lifecycle.resume
import com.arkivanov.essenty.lifecycle.stop
import io.github.alexzhirkevich.onetap.finishSignIn
import io.github.alexzhirkevich.onetap.window
import kotlinx.browser.document
import org.botdesigner.api.SharedConstants
import org.botdesigner.shared.domain.RootComponent
import org.botdesigner.shared.util.managers.AppInitializationManager
import org.botdesigner.ui.common.rememberDesktopIndication
import org.botdesigner.ui.theme.AppTheme
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.Document

@OptIn(ExperimentalComposeUiApi::class)
fun AppWeb() {

    if (window.location.href.startsWith(SharedConstants.GoogleOauthRedirectUriDefault)) {
        window.finishSignIn()
    } else {
        onWasmReady {
            AppInitializationManager().init(0)

            val lifecycle = LifecycleRegistry()

            val root = RootComponent(
                DefaultComponentContext(lifecycle = lifecycle),
            )

            lifecycle.attachToDocument()

            document.getElementById("loading")?.let {
                document.body?.removeChild(it)
            }

            CanvasBasedWindow("Bot Designer") {

                AppTheme(root.appearance.collectAsState().value) {
                    CompositionLocalProvider(LocalIndication provides rememberDesktopIndication()) {
                        Application(root)
                    }
                }
            }
        }
    }
}

private fun LifecycleRegistry.attachToDocument() {
    fun onVisibilityChanged() {
        if (document.visibilityState == "visible") {
            resume()
        } else {
            stop()
        }
    }

    onVisibilityChanged()

    document.addEventListener(type = "visibilitychange", callback = { onVisibilityChanged() })
}

private val Document.visibilityState: String
    get() = asDynamic().visibilityState.unsafeCast<String>()