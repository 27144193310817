package org.botdesigner.shared.domain.auth

import androidx.compose.runtime.Immutable
import dev.icerock.moko.resources.desc.StringDesc
import kotlinx.serialization.Serializable
import org.botdesigner.api.User
import org.botdesigner.shared.util.UIState

enum class AuthPage {
    Login, Register, VerifyEmail, RestorePasswordEmail, RestorePasswordConfirm,
}
@Immutable
data class AuthViewState(
    val isLoggedIn : Boolean,
    val user : UIState<User> = UIState.Loading,
    val name : String = "",
    val nameError : StringDesc? = null,
    val email : String = "",
    val emailError : StringDesc? = null,
    val password : String = "",
    val passwordError : StringDesc? = null,
    val code : String = "",
    val codeError : StringDesc? = null,
    val confirmPassword : String = "",
    val controlsEnabled : Boolean = true,
    val page : AuthPage = AuthPage.Login,
    val isOauthInProgress : Boolean = false
)


val AuthViewState.signButtonEnabled : Boolean
    get() = controlsEnabled && (
        email.isNotBlank() && password.isNotBlank() && page == AuthPage.Login ||
        email.isNotBlank() && password.isNotBlank() && name.isNotBlank() && page == AuthPage.Register ||
        code.isNotBlank() && page == AuthPage.VerifyEmail ||
        email.isNotBlank() && page == AuthPage.RestorePasswordEmail
    )

@Serializable
class AuthInput(
    val email : String,
    val password : String,
    val name : String,
    val code : String
)

fun AuthViewState.toAuthInput() = AuthInput(
    email = email,
    password = password,
    name = name,
    code = code
)