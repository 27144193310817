package org.botdesigner.ui.common

import androidx.compose.runtime.Composable
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.contract


@OptIn(ExperimentalContracts::class)
@Composable
internal fun NullableComposable(
    condition : Boolean,
    block : @Composable () -> Unit
) : ( @Composable () -> Unit)? {
    contract {
        returnsNotNull() implies condition
    }
    return if (condition) block else null
}

@OptIn(ExperimentalContracts::class)
@Composable
internal fun <T> NullableComposable(
    value : T?,
    block : @Composable (T) -> Unit
) : ( @Composable () -> Unit)? {
    contract {
        returnsNotNull() implies (value != null)
    }
    return if (value != null) {
        { block(value) }
    } else null
}