@file:OptIn(ExperimentalAnimationApi::class)

package org.botdesigner.ui

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.EnterExitState
import androidx.compose.animation.ExperimentalAnimationApi
import androidx.compose.animation.core.InternalAnimationApi
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.scaleIn
import androidx.compose.animation.scaleOut
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.navigationBarsPadding
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ProvideTextStyle
import androidx.compose.material3.Snackbar
import androidx.compose.material3.SnackbarDefaults
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Popup
import androidx.compose.ui.window.PopupProperties
import androidx.compose.ui.zIndex
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.extensions.compose.stack.animation.fade
import com.arkivanov.decompose.extensions.compose.stack.animation.plus
import com.arkivanov.decompose.extensions.compose.stack.animation.scale
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimation
import dev.icerock.moko.resources.compose.colorResource
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveButton
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import io.github.alexzhirkevich.cupertino.theme.systemGreen
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.filterNotNull
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.shared.domain.RootComponent
import org.botdesigner.shared.domain.SnackbarIdiom
import org.botdesigner.shared.domain.SnackbarMessage
import org.botdesigner.sharedui.MR
import org.botdesigner.ui.common.images.Images
import org.botdesigner.ui.common.images.LogoCLoud
import org.botdesigner.ui.common.images.LogoGear
import org.botdesigner.ui.screens.auth.AuthScreen
import org.botdesigner.ui.screens.content.ContentScreen
import org.botdesigner.ui.theme.Dimens
import kotlin.math.roundToInt

@Composable
fun Application(
    component: RootComponent,
) {
    val snackbar by produceState<SnackbarMessage?>(null, component) {
        component.snackbar.collectLatest {
            value = it
            delay(it.duration.duration)
            value = null
        }
    }

    val lastSnackbar by produceState(snackbar) {
        snapshotFlow { snackbar }.filterNotNull().collectLatest {
            value = it
        }
    }


    Surface {
        Children(
            modifier = Modifier.fillMaxSize(),
            stack = component.stack,
            animation = stackAnimation(fade() + scale())
        ) {
            when (val i = it.instance) {
                is RootComponent.Child.Auth -> AuthScreen(i.component)
                is RootComponent.Child.Content -> ContentScreen(i.component)
            }
        }
    }

    var actuallyVisible by remember {
        mutableStateOf(false)
    }

    var fakeVisible by remember {
        mutableStateOf(false)
    }

    LaunchedEffect(snackbar){
        if (snackbar != null){
            actuallyVisible = true
        } else {
            fakeVisible = false
        }
    }

    if (actuallyVisible) {
        Popup(
            alignment = Alignment.BottomCenter,
            properties = PopupProperties()
        ) {

            LaunchedEffect(0){
                fakeVisible = true
            }

            AnimatedVisibility(
                visible = fakeVisible,
                enter = SnackbarAnimation.first,
                exit = SnackbarAnimation.second,
                modifier = Modifier
                    .zIndex(1f)
                    .offset(y = -Dimens.Spacing.Medium)
                    .navigationBarsPadding()
                    .imePadding(),
            ) {

                DisposableEffect(0) {
                    onDispose {
                        actuallyVisible = false
                    }
                }

                AnimatedContent(
                    lastSnackbar,
                    modifier = Modifier
                        .widthIn(max = 500.dp)
                        .heightIn(min = 54.dp)
                ) {
                    it?.let {
                        Snackbar(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(horizontal = Dimens.Spacing.Medium),
                            containerColor = when (it.idiom) {
                                SnackbarIdiom.Error -> MaterialTheme.colorScheme.errorContainer
                                else -> MaterialTheme.colorScheme.surface
                            },
                            shape = if (CurrentPlatform == Platform.Ios)
                                MaterialTheme.shapes.medium
                            else SnackbarDefaults.shape,
                            contentColor = when (it.idiom) {
                                SnackbarIdiom.Error -> MaterialTheme.colorScheme.error
                                SnackbarIdiom.Success -> CupertinoColors.systemGreen
                                SnackbarIdiom.Info -> LocalContentColor.current
                            },
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.spacedBy(Dimens.Spacing.Medium)
                            ) {
                                ProvideTextStyle(
                                    if (CurrentPlatform == Platform.Ios)
                                        CupertinoTheme.typography.body
                                    else LocalTextStyle.current
                                ) {
                                    Text(
                                        text = string(it.message),
                                        modifier = Modifier
                                            .weight(1f),
                                        textAlign = TextAlign.Center,
                                    )
                                }
                                it.action?.let {
                                    AdaptiveButton(
                                        onClick = it.onClick
                                    ) {
                                        Text(string(it.name))
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    var splashVisible by rememberSaveable {
        mutableStateOf(true)
    }

    LaunchedEffect(0) {
        delay(600)
        splashVisible = false
    }

    Splash(
        visible = splashVisible
    )
}

private val SplashSize = 165.dp

//private val SplashSpec = spring<Float>(stiffness = Spring.StiffnessLow)
private val SplashSpec = tween<Float>(durationMillis = 600)

@OptIn(ExperimentalAnimationApi::class, InternalAnimationApi::class)
@Composable
private fun Splash(
    visible : Boolean
) {

    AnimatedVisibility(
        visible = visible,
        exit = fadeOut(SplashSpec),
        enter = fadeIn(SplashSpec)
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(colorResource(MR.colors.splash)),
            contentAlignment = Alignment.Center
        ) {

            transition.AnimatedVisibility(
                visible = {
                    it == EnterExitState.Visible
                },
                enter = scaleIn(initialScale = 1f, animationSpec = SplashSpec) + fadeIn(SplashSpec),
                exit = scaleOut(targetScale = 0f, animationSpec = SplashSpec) + fadeOut(SplashSpec)
            ) {
                Image(
                    imageVector = Images.LogoGear,
                    contentDescription = null,
                    modifier = Modifier
                        .size(SplashSize)
                        .graphicsLayer {
                            val progress = transition.playTimeNanos.toFloat() /
                                    transition.totalDurationNanos
                            if (!progress.isNaN()) {
                                rotationZ = 360 * progress.coerceIn(0f, 1f)
                            }
                        }
                )
                Image(
                    imageVector = Images.LogoCLoud,
                    contentDescription = null,
                    modifier = Modifier
                        .size(SplashSize)
                )
            }
        }
    }
}

private val SnackbarAnimation = if (CurrentPlatform.isMobile)
    fadeIn() to fadeOut()
else slideInVertically { (it * 1.5f).roundToInt() } to slideOutVertically { (it * 1.5f).roundToInt() }


