package org.botdesigner.shared.domain.content.appearance

import androidx.compose.runtime.Immutable
import androidx.compose.ui.graphics.Color
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.systemBlue
import io.github.alexzhirkevich.cupertino.theme.systemGreen
import io.github.alexzhirkevich.cupertino.theme.systemIndigo
import io.github.alexzhirkevich.cupertino.theme.systemOrange
import io.github.alexzhirkevich.cupertino.theme.systemPurple
import io.github.alexzhirkevich.cupertino.theme.systemRed
import io.github.alexzhirkevich.cupertino.theme.systemTeal
import org.botdesigner.shared.data.repo.Accent
import org.botdesigner.shared.data.repo.AppearanceSettings

@Immutable
data class AppearanceState(
    val isOnDarkScreen : Boolean = false,
    val autoNightMode : Boolean = true,
    val systemAppearance : Boolean = true,
    val darkMode : Boolean = false,
    val accent : AppearanceAccent = AppearanceAccent.Purple,
    val fontScale : Float = 1f,
    val paneWidth : Float = 300f
) {
    constructor(appearance: AppearanceSettings) : this(
        false,
        appearance.autoNightMode,
        appearance.systemAppearance,
        appearance.darkMode,
        AppearanceAccent.entries.first { it.accent == appearance.accent },
        appearance.fontScale,
        appearance.paneWidth
    )

    companion object {
        val FontScaleRange = .75f..1.5f
    }
}


val AppearanceState.darkModeAvailable get() = !autoNightMode
val AppearanceState.accentAvailable get() = !systemAppearance

fun AppearanceState.toAppearance() = AppearanceSettings(
    autoNightMode = autoNightMode,
    systemAppearance = systemAppearance,
    darkMode = darkMode,
    accent = accent.accent,
    fontScale = fontScale,
    paneWidth = paneWidth
)

enum class AppearanceAccent(
    val color : (dark : Boolean) -> Color,
    val accent: Accent
) {
    Red(color = CupertinoColors::systemRed, accent = Accent.Red),
    Orange(color = CupertinoColors::systemOrange, accent = Accent.Orange),
    Green(color = CupertinoColors::systemGreen, accent = Accent.Green),
    Teal(color = CupertinoColors::systemTeal, accent = Accent.Teal),
    Blue(color = CupertinoColors::systemBlue, accent = Accent.Blue),
    Indigo(color = CupertinoColors::systemIndigo, accent = Accent.Indigo),
    Purple(color = CupertinoColors::systemPurple, accent = Accent.Purple),
}