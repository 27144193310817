package org.botdesigner.shared.`data`.cache.`data`

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass
import org.botdesigner.shared.`data`.cache.CacheDatabase
import org.botdesigner.shared.`data`.cache.SqBlueprint
import org.botdesigner.shared.`data`.cache.SqBlueprintQueries
import org.botdesigner.shared.`data`.cache.SqBot
import org.botdesigner.shared.`data`.cache.SqBotQueries
import org.botdesigner.shared.`data`.cache.SqSession
import org.botdesigner.shared.`data`.cache.SqSessionQueries

internal val KClass<CacheDatabase>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = CacheDatabaseImpl.Schema

internal fun KClass<CacheDatabase>.newInstance(
  driver: SqlDriver,
  SqBlueprintAdapter: SqBlueprint.Adapter,
  SqBotAdapter: SqBot.Adapter,
  SqSessionAdapter: SqSession.Adapter,
): CacheDatabase = CacheDatabaseImpl(driver, SqBlueprintAdapter, SqBotAdapter, SqSessionAdapter)

private class CacheDatabaseImpl(
  driver: SqlDriver,
  SqBlueprintAdapter: SqBlueprint.Adapter,
  SqBotAdapter: SqBot.Adapter,
  SqSessionAdapter: SqSession.Adapter,
) : SuspendingTransacterImpl(driver), CacheDatabase {
  override val sqBlueprintQueries: SqBlueprintQueries = SqBlueprintQueries(driver,
      SqBlueprintAdapter)

  override val sqBotQueries: SqBotQueries = SqBotQueries(driver, SqBotAdapter)

  override val sqSessionQueries: SqSessionQueries = SqSessionQueries(driver, SqSessionAdapter)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 2

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS SqBlueprint (
          |    id TEXT PRIMARY KEY NOT NULL,
          |    initialId TEXT NOT NULL,
          |    botId TEXT NOT NULL,
          |    type TEXT NOT NULL,
          |    context TEXT NOT NULL,
          |    blueprint TEXT NOT NULL,
          |    createdAt INTEGER NOT NULL DEFAULT 0,
          |    editedAt INTEGER NOT NULL DEFAULT 0,
          |    deleted INTEGER NOT NULL DEFAULT 0
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS SqBot (
          |    id TEXT PRIMARY KEY NOT NULL,
          |    ownerId TEXT NOT NULL,
          |    token TEXT NOT NULL,
          |    debugToken TEXT,
          |    tokenValid INTEGER NOT NULL,
          |    debugTokenValid INTEGER NOT NULL,
          |    name TEXT NOT NULL,
          |    type TEXT NOT NULL,
          |    status TEXT NOT NULL,
          |    editedAt INTEGER NOT NULL,
          |    createdAt INTEGER NOT NULL,
          |    imageUrl TEXT,
          |    realName TEXT,
          |    username TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS SqSession (
          |    id TEXT PRIMARY KEY NOT NULL,
          |    ownerId TEXT NOT NULL,
          |    ip TEXT NOT NULL,
          |    country TEXT NOT NULL,
          |    city TEXT NOT NULL,
          |    device TEXT NOT NULL ,
          |    deviceType TEXT NOT NULL,
          |    lastActive INTEGER NOT NULL,
          |    isCurrent INTEGER NOT NULL
          |)
          """.trimMargin(), 0).await()
    }

    private fun migrateInternal(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      if (oldVersion <= 1 && newVersion > 1) {
        driver.execute(null, "DROP TABLE SqBlueprint", 0).await()
        driver.execute(null, "DROP TABLE SqBot", 0).await()
      }
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      var lastVersion = oldVersion

      callbacks.filter { it.afterVersion in oldVersion until newVersion }
      .sortedBy { it.afterVersion }
      .forEach { callback ->
        migrateInternal(driver, oldVersion = lastVersion, newVersion = callback.afterVersion +
          1).await()
        callback.block(driver)
        lastVersion = callback.afterVersion + 1
      }

      if (lastVersion < newVersion) {
        migrateInternal(driver, lastVersion, newVersion).await()
      }
    }
  }
}
