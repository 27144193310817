package org.botdesigner.blueprint.ui.pins

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.botdesigner.blueprint.ui.components.isInDemo

@Composable
fun <T> ColumnScope.TextFieldPinWidget(
    color: Color,
    name: String,
    isActive: Boolean,
    isOut: Boolean,
    value: T?,
    onChangeInputValue: (String) -> Unit = {},
    onTap: () -> Unit,
    modifier: Modifier,
    filter : (String) -> String = {it},
    placeholder: String = "",
    required : Boolean,
    inputValueEnabled: Boolean = false,
    isSelected: Boolean = false,
    maxLines: Int = 1,
    maxWidth : Dp = 100.dp,
    keyboardOptions: KeyboardOptions = KeyboardOptions(),
) {

    BasePinWidget(
        color = color,
        name = name,
        isOut = isOut,
        required = required,
        modifier = modifier,
        isSelected = isSelected,
        isActive = isActive,
        onTap = onTap,
    ) {
        if (!isInDemo() && inputValueEnabled && !isOut && !isActive) {
            val style = LocalTextStyle.current.copy(
                color = Color.White,
                fontSize = 14.sp,
            )

//            var text by remember(value) {
//                mutableStateOf(value?.toString().orEmpty())
//            }
//
//            LaunchedEffect(null) {
//                snapshotFlow { text }.drop(1).collectLatest {
//                    onChangeInputValue(it)
//                }
//            }

            BasicTextField(
                value = value?.toString().orEmpty(),
                onValueChange = { onChangeInputValue(filter(it)) },
                textStyle = style,
                keyboardOptions = keyboardOptions,
                maxLines = maxLines,
                singleLine = maxLines == 1,
                cursorBrush = SolidColor(Color.White)
            ) {
                Box(
                    Modifier
                        .width(maxWidth)
                        .padding(horizontal = 5.dp)
                        .border(
                            width = 1.dp,
                            color = Color.White,
                            shape = MaterialTheme.shapes.extraSmall
                        )
                        .padding(4.dp)
                ) {
                    it()
                    if (value?.toString()?.isBlank() == true) {
                        Text(
                            modifier = Modifier
                                .align(Alignment.CenterStart),
                            text = placeholder,
                            style = style.copy(
                                style.color.copy(alpha = .5f)
                            ),
                        )
                    }
                }
            }
        }
    }
}

val DefaultTextFieldPinWidth = 60.dp
