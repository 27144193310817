package io.github.alexzhirkevich.onetap

actual fun runCatchingMsg(block : () -> Unit) : String? = js(
    """
    {try {
        block()
        return null
    } catch(err) {
        return err.toString()
    }}
    """
)