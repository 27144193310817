package org.botdesigner.blueprint.integrations.serialization

import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.integrations.CorruptedNode
import org.botdesigner.blueprint.integrations.functions.custom.BpCustomProcedure
import org.botdesigner.blueprint.integrations.functions.custom.BpCustomProcedureReturn
import org.botdesigner.blueprint.integrations.functions.custom.BpCustomProcedureTrigger

val IntegrationsSerializationModule by lazy {
    SerializersModule {

        polymorphic(BlueprintNode::class) {
            subclass(BpCustomProcedure::class)
            subclass(BpCustomProcedureTrigger::class)
            subclass(BpCustomProcedureReturn::class)
        }

        polymorphicDefaultDeserializer(BlueprintNode::class){
            CorruptedNode.serializer()
        }
    }
}