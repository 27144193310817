package org.botdesigner.shared.data.repo.impl

import com.russhwolf.settings.set
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.botdesigner.shared.data.repo.AppearanceSettings
import org.botdesigner.shared.data.repo.EditorSettings
import org.botdesigner.shared.data.repo.SettingsRepository
import org.botdesigner.shared.util.dispatchers.Dispatchers

private val SETTINGS_KEY =   "org.botdesigner.app.settings.editor"
private val APPEARANCE_KEY = "org.botdesigner.app.settings.appearance"

internal class SettingsRepositoryImpl(
    private val settingsStore: com.russhwolf.settings.Settings,
    private val dispatchers: Dispatchers
) : SettingsRepository {

    override suspend fun saveEditor(settings: EditorSettings) {
        dispatchers.runOnIO {
            settingsStore[SETTINGS_KEY] = Json.encodeToString(settings)
        }
    }

    override suspend fun loadEditor(): EditorSettings {
        return dispatchers.runOnIO {
            runCatching {
                settingsStore.getStringOrNull(SETTINGS_KEY)?.let {
                    Json.decodeFromString<EditorSettings>(it)
                }
            }.getOrNull() ?: EditorSettings()
        }
    }

    override suspend fun saveAppearance(appearance: AppearanceSettings) {
        dispatchers.runOnIO {
            settingsStore[APPEARANCE_KEY] = Json.encodeToString(appearance)
        }
    }

    override suspend fun loadAppearance(): AppearanceSettings {
        return dispatchers.runOnIO {
            runCatching {
                settingsStore.getStringOrNull(APPEARANCE_KEY)
                    ?.let { Json.decodeFromString<AppearanceSettings>(it) }
            }.getOrNull() ?: AppearanceSettings()
        }
    }
}