package org.botdesigner.ui.common

import androidx.compose.foundation.text.ClickableText
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.unit.LayoutDirection
import io.github.alexzhirkevich.LocalContentColor
import io.github.alexzhirkevich.LocalTextStyle
import org.botdesigner.resources.SharedRes
import org.botdesigner.ui.string

@Composable
fun LearnMoreText(
    text : String,
    style: TextStyle = LocalTextStyle.current.copy(
        color = LocalContentColor.current
    ),
    onLearnMoreClick : () -> Unit
) {
    val reverseDirection = LocalLayoutDirection.current == LayoutDirection.Rtl
    val learnMore = string(SharedRes.strings.learn_more)

    ClickableText(
        text = buildAnnotatedString {
            if (reverseDirection){
                append(learnMore)
                append(" ")
                append(text)
                addStyle(
                    style = SpanStyle(color = MaterialTheme.colorScheme.primary),
                    start = 0,
                    end = learnMore.length
                )
            } else {
                append(text)
                append(" ")
                append(learnMore)
                addStyle(
                    style = SpanStyle(color = MaterialTheme.colorScheme.primary),
                    start = length - learnMore.length,
                    end = length
                )
            }
        },
        onClick = {
            if (reverseDirection && it <= learnMore.length || !reverseDirection && it >= text.length) {
                onLearnMoreClick()
            }
        },
        style = style
    )
}