package org.botdesigner.shared.`data`.cache

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import org.botdesigner.core.BotStatus
import org.botdesigner.core.BotType

public class SqBotQueries(
  driver: SqlDriver,
  private val SqBotAdapter: SqBot.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getAll(ownerId: String, mapper: (
    id: String,
    ownerId: String,
    token: String,
    debugToken: String?,
    tokenValid: Boolean,
    debugTokenValid: Boolean,
    name: String,
    type: BotType,
    status: BotStatus,
    editedAt: Long,
    createdAt: Long,
    imageUrl: String?,
    realName: String?,
    username: String?,
  ) -> T): Query<T> = GetAllQuery(ownerId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3),
      cursor.getBoolean(4)!!,
      cursor.getBoolean(5)!!,
      cursor.getString(6)!!,
      SqBotAdapter.typeAdapter.decode(cursor.getString(7)!!),
      SqBotAdapter.statusAdapter.decode(cursor.getString(8)!!),
      cursor.getLong(9)!!,
      cursor.getLong(10)!!,
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13)
    )
  }

  public fun getAll(ownerId: String): Query<SqBot> = getAll(ownerId) { id, ownerId_, token,
      debugToken, tokenValid, debugTokenValid, name, type, status, editedAt, createdAt, imageUrl,
      realName, username ->
    SqBot(
      id,
      ownerId_,
      token,
      debugToken,
      tokenValid,
      debugTokenValid,
      name,
      type,
      status,
      editedAt,
      createdAt,
      imageUrl,
      realName,
      username
    )
  }

  public fun <T : Any> `get`(id: String, mapper: (
    id: String,
    ownerId: String,
    token: String,
    debugToken: String?,
    tokenValid: Boolean,
    debugTokenValid: Boolean,
    name: String,
    type: BotType,
    status: BotStatus,
    editedAt: Long,
    createdAt: Long,
    imageUrl: String?,
    realName: String?,
    username: String?,
  ) -> T): Query<T> = GetQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3),
      cursor.getBoolean(4)!!,
      cursor.getBoolean(5)!!,
      cursor.getString(6)!!,
      SqBotAdapter.typeAdapter.decode(cursor.getString(7)!!),
      SqBotAdapter.statusAdapter.decode(cursor.getString(8)!!),
      cursor.getLong(9)!!,
      cursor.getLong(10)!!,
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13)
    )
  }

  public fun `get`(id: String): Query<SqBot> = get(id) { id_, ownerId, token, debugToken,
      tokenValid, debugTokenValid, name, type, status, editedAt, createdAt, imageUrl, realName,
      username ->
    SqBot(
      id_,
      ownerId,
      token,
      debugToken,
      tokenValid,
      debugTokenValid,
      name,
      type,
      status,
      editedAt,
      createdAt,
      imageUrl,
      realName,
      username
    )
  }

  public suspend fun create(
    id: String,
    ownerId: String,
    token: String,
    tokenValid: Boolean,
    debugTokenValid: Boolean,
    debugToken: String?,
    name: String,
    type: BotType,
    status: BotStatus,
    editedAt: Long,
    createdAt: Long,
    imageUrl: String?,
    realName: String?,
    username: String?,
  ) {
    driver.execute(242_314_618, """
        |INSERT OR REPLACE INTO SqBot(id,ownerId,token, tokenValid, debugTokenValid,debugToken, name,type,status, editedAt, createdAt, imageUrl, realName, username)
        |    VALUES (?,?,?, ?, ?,?,?,?,?,?, ?, ?, ?, ?)
        """.trimMargin(), 14) {
          bindString(0, id)
          bindString(1, ownerId)
          bindString(2, token)
          bindBoolean(3, tokenValid)
          bindBoolean(4, debugTokenValid)
          bindString(5, debugToken)
          bindString(6, name)
          bindString(7, SqBotAdapter.typeAdapter.encode(type))
          bindString(8, SqBotAdapter.statusAdapter.encode(status))
          bindLong(9, editedAt)
          bindLong(10, createdAt)
          bindString(11, imageUrl)
          bindString(12, realName)
          bindString(13, username)
        }.await()
    notifyQueries(242_314_618) { emit ->
      emit("SqBot")
    }
  }

  public suspend fun delete(id: String) {
    driver.execute(259_150_377, """DELETE FROM SqBot WHERE id = ?""", 1) {
          bindString(0, id)
        }.await()
    notifyQueries(259_150_377) { emit ->
      emit("SqBot")
    }
  }

  public suspend fun deleteAll() {
    driver.execute(-2_002_251_080, """DELETE FROM SqBot""", 0).await()
    notifyQueries(-2_002_251_080) { emit ->
      emit("SqBot")
    }
  }

  public suspend fun toggle(status: BotStatus, id: String) {
    driver.execute(726_304_722, """UPDATE SqBot SET status = ? WHERE id = ?""", 2) {
          bindString(0, SqBotAdapter.statusAdapter.encode(status))
          bindString(1, id)
        }.await()
    notifyQueries(726_304_722) { emit ->
      emit("SqBot")
    }
  }

  public suspend fun update(
    token: String,
    tokenValid: Boolean,
    debugToken: String?,
    debugTokenValid: Boolean,
    name: String,
    id: String,
  ) {
    driver.execute(755_762_503,
        """UPDATE SqBot SET token = ?, tokenValid = ?, debugToken = ?, debugTokenValid = ?, name = ? WHERE id = ?""",
        6) {
          bindString(0, token)
          bindBoolean(1, tokenValid)
          bindString(2, debugToken)
          bindBoolean(3, debugTokenValid)
          bindString(4, name)
          bindString(5, id)
        }.await()
    notifyQueries(755_762_503) { emit ->
      emit("SqBot")
    }
  }

  private inner class GetAllQuery<out T : Any>(
    public val ownerId: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("SqBot", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("SqBot", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(345_241_321, """SELECT * FROM SqBot WHERE ownerId = ?""", mapper, 1) {
      bindString(0, ownerId)
    }

    override fun toString(): String = "SqBot.sq:getAll"
  }

  private inner class GetQuery<out T : Any>(
    public val id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("SqBot", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("SqBot", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-2_134_424_680, """SELECT * FROM SqBot WHERE id = ?""", mapper, 1) {
      bindString(0, id)
    }

    override fun toString(): String = "SqBot.sq:get"
  }
}
