package org.botdesigner.blueprint.components


interface BlueprintMutablePinsOwner {

    /**
     * Flag indicates that this function can add or remove pins
     * */
    val isExtendable : Boolean
        get() = false

    /**
     * Returns addition ability flag of this element based on current element [state]
     * */
    fun isAddEnabled(
        state : BlueprintPinsOwner
    ) : Boolean = true

    /**
     * Returns removing ability flag of this element based on current element [state]
     * */
    fun isRemoveEnabled(
        state : BlueprintPinsOwner
    ) : Boolean = true

    /**
     * Add pins callback invoked when user clicks add button.
     * Used only if this function [isExtendable]
     *
     * @receiver scope to perform mutation operation
     * @return if more pins can be added
     * */
    fun PinsMutationScope.onAddPins(pool: BlueprintNodesPool) {}


    /**
     * Remove pins callback invoked when user clicks remove button.
     * Used only if this function [isExtendable]
     *
     * @receiver scope to perform mutation operation
     * @return if more pins can be removed
     * */
    fun PinsMutationScope.onRemovePins(pool: BlueprintNodesPool) {}
}