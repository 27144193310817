package org.botdesigner.shared.domain.content.bot

import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.value.Value
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.core.Bot
import org.botdesigner.shared.domain.ChildComponent
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.shared.domain.content.StackableComponent
import org.botdesigner.shared.domain.content.bot.bpcontext.BlueprintContextSelectionDialogComponent
import org.botdesigner.shared.domain.content.bot.bptype.BlueprintTypeSelectionDialogComponent
import org.botdesigner.shared.util.UIState

class BotViewState(
    val bot: UIState<Bot> = UIState.Loading,
    val blueprints: UIState<List<BlueprintData>> = UIState.Loading,
    val toggleEnabled: Boolean = true,
    val showProgress : Boolean = false,
    val isRefreshing : Boolean = false
)

interface BotComponent : StackableComponent {

    val state : StateFlow<BotViewState>

    val alertDialog : Value<ChildSlot<*, AlertDialog<*>>>

    val componentDialog : Value<ChildSlot<*, ComponentDialog<*>>>

    fun onToggle()
    fun onLogsClicked()
    fun onEditClicked()
    fun onDownloadAppClicked()

    fun onDeleteClicked()
    fun onBlueprintSelected(blueprintData: BlueprintData)
    fun onBlueprintDelete(blueprint : BlueprintData)
    fun onBlueprintEditClicked(blueprint : BlueprintData)

    fun refresh()

    fun onComponentDialogDismissed()

    fun onNewBlueprintClicked()

    sealed interface AlertDialog<T> : ChildComponent<T> {
        class ContextSelection(
            override val component : BlueprintContextSelectionDialogComponent
        ) : AlertDialog<BlueprintContextSelectionDialogComponent>

        class DeleteBlueprint(
            override val component : AlertConfirmationDialogComponent
        ) : AlertDialog<AlertConfirmationDialogComponent>

        class DeleteBot(
            override val component : AlertConfirmationDialogComponent
        ) : AlertDialog<AlertConfirmationDialogComponent>

        class BotIsRunning(
            override val component : AlertConfirmationDialogComponent
        ) : AlertDialog<AlertConfirmationDialogComponent>
    }

    sealed interface ComponentDialog<T> : ChildComponent<T> {
        class BlueprintTypeSelection(
            override val component : BlueprintTypeSelectionDialogComponent
        ) : ComponentDialog<BlueprintTypeSelectionDialogComponent>
    }
}

