@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.float

import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpExpression
import org.botdesigner.blueprint.components.functions.ExpressionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpDoubleToFloatRound")
public class BpDoubleToFloatRound(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.DoublePinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = false
              )
          }
      }
      },
) : BpExpression() {
  override val summary: AnnotatedString
    get() = "Convert float number to the closes integer number".trimIndent().toAnnotatedString()

  override val name: String
    get() = "Round To Integer"

  override val factory: ExpressionFactory
    get() = ::BpDoubleToFloatRound

  override val expression: String
    get() = "-->"

  override suspend fun calculate(input: List<Any?>): List<Any?> = listOf(DoubleToFloatRound(
  		input[0] as kotlin.Double?
  	))
}
