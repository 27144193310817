package org.botdesigner.blueprint.components

import androidx.compose.runtime.Immutable
import kotlin.jvm.JvmInline

@JvmInline
@kotlinx.serialization.Serializable
@Immutable
value class Id(val id : String) : Comparable<Id>{

    companion object {

        private const val idLength = 6
        private const val alph = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

        fun randomId(length : Int = idLength) : Id {

            return Id((0 until length).map { alph.random() }.joinToString(""))
        }

        fun uuid() : Id {
            return Id(com.benasher44.uuid.uuid4().toString())
        }
    }

    override fun compareTo(other: Id): Int {
        return id.compareTo(other.id)
    }

    override fun toString(): String {
        return id
    }
}