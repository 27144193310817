package org.botdesigner.blueprint.store

import androidx.compose.runtime.Stable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateMap
import androidx.compose.ui.util.fastAll
import androidx.compose.ui.util.fastAny
import androidx.compose.ui.util.fastMap
import androidx.compose.ui.util.fastSumBy
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.ensureActive
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.components.Point
import kotlin.coroutines.coroutineContext

typealias BpStoreRecordsMap = Map<String, Map<String, List<Pair<BlueprintNodeStoreRecord<*>,BlueprintNode>>>>

@Stable
interface BlueprintStoreManager {

    val selectedElements : State<BpStoreRecordsMap>

    val search : String

    val selectedCategory : String

    val selectedSubcategories : Map<String, Map<String, Unit>>

    fun onSearch(text : String)

    fun onSubcategoryClicked(category: String, subcategory : String)

    fun onCategorySelected(category: String)
}

class BlueprintStoreManagerImpl(
    store: BlueprintNodeStore,
    private val scope: CoroutineScope
) : BlueprintStoreManager {

    private var allElements : BpStoreRecordsMap = emptyMap()

    override var selectedCategory: String by mutableStateOf("")

    private val _elements = mutableStateOf(allElements)
    override val selectedElements: State<BpStoreRecordsMap>
        get() = _elements

    private val _search = mutableStateOf("")
    override val search by _search

    override val selectedSubcategories = SnapshotStateMap<String, SnapshotStateMap<String, Unit>>()

    init {
        scope.launch(Dispatchers.Default) {

            // hard computation can produce lag so launched in the background

            allElements = store.pool
                .filterKeys { it.name.isNotEmpty() } // empty category is utility
                .mapValues { pool ->
                    pool.value
                        .groupBy(BlueprintNodeStoreRecord<*>::subcategory)
                        .mapValues { subMap ->
                            subMap.value.map {
                                ensureActive()
                                it to it.factory(Point.Zero)
                            }
                        }
                }.mapKeys { it.key.name }
            selectedCategory = allElements.keys.firstOrNull().orEmpty()
            _elements.value = allElements

            allElements.keys.forEach {
                selectedSubcategories[it] = SnapshotStateMap()
            }
        }
    }

    private var searchJob: Job? = null

    override fun onCategorySelected(category: String) {
        selectedCategory = category
    }

    private fun splitToWords(string: String) =
        string
            .split(' ', ',', '.', ':', '!', '?')
            .filter(String::isNotBlank)

    private fun confirms(search: String, element: BlueprintNode): Boolean {

        val split = splitToWords(search)

        val hasAnyWordInName = element.name
            .split(" ")
            .fastAny { nameWord ->
                split.fastAny { splitWord ->
                    nameWord.contains(splitWord, true)
                }
            }

        val hasAllWordsInSummary = element.summary
            .split(" ")
            .fastAny { summaryWord ->
                split.fastAll { splitWord ->
                    summaryWord.contains(splitWord, true)
                }
            }

        return hasAnyWordInName || hasAllWordsInSummary
    }

    private fun matchCount(search: String, element: BlueprintNode): Int {

        val split = splitToWords(search)

        return element.name
            .split(" ")
            .fastSumBy { nameWord ->
                split.fastCount { splitWord ->
                    nameWord.contains(splitWord, true)
                }
            }
    }


    override fun onSearch(text: String) {
        _search.value = text
        searchJob?.cancel()
        searchJob = scope.launch(Dispatchers.Default) {
            applyFilter(text)
        }
    }

    override fun onSubcategoryClicked(category: String, subcategory: String) {
        selectedSubcategories[category]?.let {
            if(it.containsKey(subcategory)){
                it.remove(subcategory)
            } else {
                it[subcategory] = Unit
            }
        }
    }

    private suspend fun applyFilter(text: String) {

        val filtered =  allElements.mapValues { (_, v) ->
            coroutineContext.ensureActive()
            v.mapValues { map ->
                map.value
                    .filter { confirms(text, it.second) }
                    .fastMap { x -> x to matchCount(text, x.second) }
                    .sortedByDescending { it.second }
                    .fastMap { it.first }
            }.filterValues(Collection<*>::isNotEmpty)
        }.filterValues(Map<*, *>::isNotEmpty)

        withContext(Dispatchers.Main.immediate) {

            _elements.value = filtered
            if (!filtered.containsKey(selectedCategory)) {
                selectedCategory = filtered.keys.firstOrNull().orEmpty()
            }
        }
    }
}

private inline fun <T> List<T>.fastCount(predicate : (T) -> Boolean) : Int {
    var c = 0
    repeat(size) {
        if (predicate(get(it))) {
            c++
        }
    }
    return c
}