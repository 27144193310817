package org.botdesigner.blueprint.queue

import kotlinx.atomicfu.atomic
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.coroutines.supervisorScope
import kotlinx.coroutines.withTimeout
import kotlin.coroutines.CoroutineContext

interface IncrementalTimeout : CoroutineContext.Element {

    override val key: CoroutineContext.Key<*>
        get() = IncrementalTimeout

    /**
     * Reset timeout back to initial value
     * */
    fun reset()

    companion object : CoroutineContext.Key<IncrementalTimeout>
}

fun CoroutineContext.requireIncrementalTimeout() : IncrementalTimeout {
    return requireNotNull(get(IncrementalTimeout)){
        "Blueprint was launched in the context without IncrementalTimeout"
    }
}

/**
 * Run [block] with initial timeout as [timeMillis].
 *
 * This timeout can be reset back to [timeMillis] using [IncrementalTimeout.reset].
 * Current [IncrementalTimeout] instance can be obtained from [currentCoroutineContext] using
 * [requireIncrementalTimeout] or [IncrementalTimeout.Companion].
 *
 * Execution will be forced to time out after [totalMaxTimeout].
 * */
suspend inline fun withIncrementalTimeout(
    timeMillis: Long,
    totalMaxTimeout : Long,
    noinline block: suspend CoroutineScope.(IncrementalTimeout) -> Unit
) = coroutineScope {
    launchWithIncrementalTimeout(
        timeMillis = timeMillis,
        totalMaxTimeout = totalMaxTimeout,
        block = block
    )
}

/**
 * Launch [block] with initial timeout as [timeMillis].
 *
 * This timeout can be increased by additional [timeMillis] using [IncrementalTimeout.reset].
 * Current [IncrementalTimeout] instance can be obtained from [currentCoroutineContext] using
 * [requireIncrementalTimeout] or [IncrementalTimeout.Companion].
 *
 * Should be launched in Supervisor scope.
 *
 * Execution will be forced to time out after [totalMaxTimeout].
 * */
fun CoroutineScope.launchWithIncrementalTimeout(
    timeMillis: Long,
    totalMaxTimeout : Long,
    block: suspend CoroutineScope.(IncrementalTimeout) -> Unit
) : Job {

    val delay = IncrementalTimeoutImpl(timeMillis)

    var timeOutJob : Job? = null

    val job = launch(coroutineContext + delay) {
        withTimeout(totalMaxTimeout) {
            block(delay)
        }
    }.apply {
        invokeOnCompletion {
            timeOutJob?.cancel()
        }
    }

    timeOutJob = launch {
        withTimeout(totalMaxTimeout) {
            while (job.isActive) {
                val timeoutLeft = delay.millis.getAndSet(0)
                if (timeoutLeft <= 0) {
                    withTimeout(0) {}
                }
                delay(timeoutLeft)
            }
        }
    }.apply {
        invokeOnCompletion {
            if (job.isActive) {
                job.cancel(it as? CancellationException?)
            }
        }
    }

    return job
}

internal class IncrementalTimeoutImpl(private val timeMillis : Long) : IncrementalTimeout {

    val millis = atomic(timeMillis)

    override fun reset() {
        millis.getAndSet(timeMillis)
    }
}