@file:OptIn(ExperimentalCupertinoApi::class)

package org.botdesigner.ui.screens.sessions

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Logout
import androidx.compose.material.icons.filled.Computer
import androidx.compose.material.icons.filled.DeviceUnknown
import androidx.compose.material.icons.filled.FrontHand
import androidx.compose.material.icons.filled.Language
import androidx.compose.material.icons.filled.LaptopMac
import androidx.compose.material.icons.filled.LaptopWindows
import androidx.compose.material.icons.filled.PhoneAndroid
import androidx.compose.material.icons.filled.PhoneIphone
import androidx.compose.material.icons.filled.TabletMac
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import io.github.alexzhirkevich.cupertino.CupertinoButtonDefaults
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveIconButton
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTextButton
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTopAppBar
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.isTopBarTransparent
import io.github.alexzhirkevich.cupertino.section.CupertinoSectionDefaults
import io.github.alexzhirkevich.cupertino.section.items
import io.github.alexzhirkevich.cupertino.section.section
import io.github.alexzhirkevich.cupertino.section.sectionTitle
import org.botdesigner.api.auth.DeviceType
import org.botdesigner.api.auth.Session
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.shared.domain.content.sessions.SessionsComponent
import org.botdesigner.shared.domain.content.sessions.lastActiveAt
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.valueOrNull
import org.botdesigner.ui.common.LearnMoreText
import org.botdesigner.ui.common.NavigateBackButton
import org.botdesigner.ui.common.PlatformVerticalScrollbar
import org.botdesigner.ui.common.isLoading
import org.botdesigner.ui.screens.content.SectionContextMaxWidth
import org.botdesigner.ui.screens.list.BotsError
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable
import org.botdesigner.ui.theme.platformClickable

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal fun SessionsScreen(
    component: SessionsComponent,
    modifier: Modifier = Modifier
) {

    val listState = rememberLazyListState()

    val isTopBarTransparent = listState.isTopBarTransparent

    AdaptiveScaffold(
        modifier = modifier,
        containerColor = MaterialTheme.colorScheme.background,
        topBar = {
            AdaptiveTopAppBar(
                title = {
                    Text(string(SharedRes.strings.active_sessions))
                },
                navigationIcon = {
                    if (LocalInterfaceIdiom.current == InterfaceIdiom.Mobile) {
                        NavigateBackButton(onClick = component::onBack)
                    }
                },
                adaptation = {
                    cupertino {
                        isTranslucent = CurrentPlatform.isMobile
                        isTransparent = isTranslucent && isTopBarTransparent
                    }
                }
            )
        }
    ) {
        val sessions by component.sessions.collectAsState()

        when (val s = sessions) {
            is UIState.Success, UIState.Loading -> {
                SessionsList(
                    modifier = Modifier
                        .widthIn(max = SectionContextMaxWidth),
                    listState = listState,
                    component = component,
                    paddingValues = it
                )
            }

            is UIState.Error -> {
                BotsError(
                    modifier = Modifier.fillMaxSize(),
                    title = s.title?.let {
                        string(it)
                    }.orEmpty(),
                    text = s.message?.let {
                        string(it)
                    }.orEmpty(),
                    onRefresh = component::refresh
                )
            }

            is UIState.Empty -> {
                BotsError(
                    modifier = Modifier.fillMaxSize(),
                    title = string(SharedRes.strings.error_oops),
                    text = s.message?.let {
                        string(it)
                    }.orEmpty(),
                    onRefresh = component::refresh
                )
            }
        }
    }
}

@OptIn(ExperimentalCupertinoApi::class)
@Composable
private fun SessionsList(
    modifier: Modifier = Modifier,
    listState : LazyListState,
    component: SessionsComponent,
    paddingValues: PaddingValues
) {

    Box(Modifier.fillMaxSize()) {
        PlatformVerticalScrollbar(
            scrollableState = listState,
            modifier = Modifier
                .zIndex(1f)
                .padding(paddingValues)
                .align(Alignment.CenterEnd)
        )
        val sessions by component.sessions.collectAsState()

        val otherSessions by remember(component) {
            derivedStateOf {
                sessions.valueOrNull()?.filterNot(Session::isCurrent)
            }
        }

        val enabled by component.controlsEnabled.collectAsState()

        LazyColumn(
            modifier = modifier
                .align(Alignment.TopCenter),
            state = listState,
            contentPadding = paddingValues
        ) {
            item {
                Spacer(Modifier.height(Dimens.Spacing.ExtraLarge))
            }
            section(
                title = {
                    Text(string(SharedRes.strings.this_device).sectionTitle())
                },
                caption = {
                    Text(string(SharedRes.strings.terminate_all_sessions_desc))
                },
            ) {
                item(
                    dividerPadding = DividerPadding
                ) {
                    val thisSession by remember {
                        derivedStateOf {
                            sessions.valueOrNull()?.firstOrNull(Session::isCurrent)
                        }
                    }

                    DeviceWidget(
                        session = thisSession,
                        onStop = {},
                        enabled = enabled,
                        modifier = Modifier.padding(it)
                    )
                }
                item(
                    dividerPadding = DividerPadding
                ) {
                    val terminateAllEnabled = enabled && otherSessions?.isEmpty() == false
                    TerminateAllButton(
                        modifier = Modifier.padding(
                            start = Dimens.Spacing.Medium,
                            end = Dimens.Spacing.Medium,
                            top = it.calculateTopPadding(),
                            bottom = it.calculateBottomPadding()
                        ).pointerHoverIcon(PointerIcon.platformClickable(terminateAllEnabled)),
                        onClick = component::onStopAllClicked,
                        enabled = terminateAllEnabled
                    )
                }
            }

            section(
                title = {
                    if (otherSessions?.isEmpty() != true) {
                        Text(string(SharedRes.strings.active_sessions).sectionTitle())
                    }
                },
                caption = {
                    LearnMoreText(
                        text = string(SharedRes.strings.active_sessions_desc),
                        onLearnMoreClick = component::onLearnMoreClicked
                    )
                },
            ) {
                otherSessions?.let { list ->
                    items(
                        items = list,
                        dividerPadding = DividerPadding,
                        key = Session::id
                    ) { s, pv ->
                        DeviceWidget(
                            session = s,
                            onStop = { component.onStopClicked(s) },
                            enabled = enabled,
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(pv)
                        )
                    }
                } ?: kotlin.run {

                    items(
                        count = 2,
                        dividerPadding = DividerPadding
                    ) { _, pv ->
                        DeviceWidget(
                            session = null,
                            onStop = {},
                            enabled = enabled,
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(pv)
                        )
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
private fun TerminateAllButton(
    enabled: Boolean,
    onClick : () -> Unit,
    modifier : Modifier = Modifier,
) {
    AdaptiveTextButton(
        modifier = modifier,
        onClick = onClick,
        enabled = enabled,
        adaptation = {
            material {
                colors = ButtonDefaults.textButtonColors(
                    contentColor = MaterialTheme.colorScheme.error
                )
            }
            cupertino {
                colors = CupertinoButtonDefaults.borderlessButtonColors(
                    contentColor = MaterialTheme.colorScheme.error
                )
            }
        }
    ){
        val text = string(SharedRes.strings.terminate_all_sessions)

        Icon(
            imageVector = Icons.Default.FrontHand,
            contentDescription = text,
        )

        Spacer(Modifier.width(Dimens.Spacing.Medium))

        Text(
            text = text,
        )
    }
}

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
private fun DeviceWidget(
    session : Session?,
    onStop : () -> Unit,
    enabled : Boolean,
    modifier: Modifier = Modifier,
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement
            .spacedBy(Dimens.Spacing.Medium)
    ) {
        Icon(
            imageVector = session?.deviceType?.icon
                ?: Icons.Default.DeviceUnknown,
            contentDescription = null,
            modifier = Modifier
                .size(IconSize)
                .isLoading(session == null)
        )

        Column(
            modifier = Modifier.weight(1f),
            verticalArrangement = Arrangement.spacedBy(
                Dimens.Spacing.ExtraSmall
            )
        ) {
            Text(
                text = session?.device ?: "***************",
                maxLines = 1,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier.isLoading(session == null)
            )
            Text(
                text = session?.let {
                    "${it.country}, ${it.city} • ${string(it.lastActiveAt)}"
                } ?: "********** ***********",
                modifier = Modifier.isLoading(session == null),
                style = MaterialTheme.typography.labelMedium,
                color = MaterialTheme.colorScheme.secondary
            )
        }

        if (session?.isCurrent == false) {
            AdaptiveIconButton(
                modifier = Modifier
                    .pointerHoverIcon(PointerIcon.PlatformClickable),
                onClick = onStop,
                enabled = enabled
            ){
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.Logout,
                    contentDescription = string(SharedRes.strings.terminate),
                    tint = MaterialTheme.colorScheme.error,
                )
            }
        }
    }
}

private val DeviceType.icon : ImageVector
    get() = when (this){
        DeviceType.Android -> Icons.Default.PhoneAndroid
        DeviceType.Iphone -> Icons.Default.PhoneIphone
        DeviceType.Ipad ->  Icons.Default.TabletMac
        DeviceType.Mac -> Icons.Default.LaptopMac
        DeviceType.Windows -> Icons.Default.LaptopWindows
        DeviceType.Linux -> Icons.Default.Computer
        DeviceType.DesktopUnknown -> Icons.Default.Computer
        DeviceType.Web, DeviceType.WebMobile -> Icons.Default.Language
        DeviceType.Unknown -> Icons.Default.DeviceUnknown
    }

private val IconSize = 32.dp
private val DividerPadding = IconSize +
        Dimens.Spacing.Medium +
        CupertinoSectionDefaults.DividerPadding