package org.botdesigner.shared.domain.content.bot.bpcontext

import com.arkivanov.decompose.ComponentContext
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.botblueprints.BlueprintData

internal class DefaultBlueprintContextSelectionDialogComponent(
    context: ComponentContext,
    override val data : BlueprintData,
    override val input: StateFlow<String>,
    private val onInputChanged : (String) -> Unit,
    private val onCancel : () -> Unit,
    private val onConfirm : (BlueprintData) -> Unit
) : BlueprintContextSelectionDialogComponent, ComponentContext by context {

    init {
        onInputChanged(data.context)
    }
    override fun onInputChanged(value: String) {
        onInputChanged.invoke(value)
    }

    override fun onCancel() {
        onCancel.invoke()
    }

    override fun onConfirm() {
        onConfirm.invoke(data.copy(context = input.value))
    }
}