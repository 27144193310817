@file:OptIn(ExperimentalContracts::class)

package org.botdesigner.blueprint.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.State
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Shape
import org.botdesigner.blueprint.BlueprintManager
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract


@Stable
interface BlueprintNode : BlueprintInstance, BlueprintPinsOwner {

    /**
     * Create widget and state manager of this element
     * */
    fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*>

    /**
     * Get [pin] value in the [pool]
     * */
    @Suppress("UNCHECKED_CAST")
    suspend fun <T> pinValue(pin: Pin<T>, pool: BlueprintNodesPool) : T? {
        return pinValueWithExceptionMark(pin) {
            if (pin.parentPinId != null && pin.parentId != null) {

                val next = pool.getElementById(pin.parentId!!)
                val nextPin = pin.parentPin(pool)
                    ?: return@pinValueWithExceptionMark null
                next.pinValue(nextPin, pool) as? T?
            } else pin.value
        }
    }
}

@Stable
interface BlueprintNodeStateHolder<out T : BlueprintNode>
    : BlueprintInstanceStateHolder<T>,
    PinsMutationScope,
    ConnectorsMutationScope {

    val lastChange : State<Long>

    fun focused()

    fun connectorState(id : Id) : State<Connector>?

    fun pinState(id : Id) : State<Pin<*>>?

    fun getPinPosition(id : Id) : State<Point>?

    fun getConnectorPosition(id : Id) : State<Point>?

    @Composable
    fun Draw(
        modifier: Modifier,
        fieldSize: Float,
        shape: Shape
    )
}

@Suppress("UNCHECKED_CAST")
inline fun <A> List<*>.castNullable(block : (A?) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A?)
}

@Suppress("UNCHECKED_CAST")
inline fun <A : Any> List<*>.cast(block : (A) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A)
}

@Suppress("UNCHECKED_CAST")
inline fun <A,B> List<*>.castNullable(block : (A?, B?) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A?, this[1] as B?)
}

@Suppress("UNCHECKED_CAST")
inline fun <A, B> List<*>.cast(block : (A, B) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A, this[1] as B)
}

@Suppress("UNCHECKED_CAST")
inline fun <A,B,C> List<*>.castNullable(block : (A?, B?, C?) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A?, this[1] as B?, this[2] as C?)
}

@Suppress("UNCHECKED_CAST")
inline fun <A, B, C> List<*>.cast(block : (A, B, C) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A, this[1] as B, this[2] as C)
}

@Suppress("UNCHECKED_CAST")
inline fun <A,B,C,D> List<*>.castNullable(block : (A?, B?, C?, D?) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A?, this[1] as B?, this[2] as C?, this[3] as D?)
}

@Suppress("UNCHECKED_CAST")
inline fun <A, B, C, D> List<*>.cast(block : (A, B, C, D) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A, this[1] as B, this[2] as C, this[3] as D)

}

@Suppress("UNCHECKED_CAST")
inline fun <A,B,C,D,E> List<*>.castNullable(block : (A?, B?, C?, D?, E?) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }
    return block(this[0] as A?, this[1] as B?, this[2] as C?, this[3] as D?, this[4] as E?)
}

@Suppress("UNCHECKED_CAST")
inline fun <A, B, C, D, E> List<*>.cast(block : (A, B, C, D, E) -> List<*>) : List<*> {
    contract {
        callsInPlace(block, InvocationKind.EXACTLY_ONCE)
    }

    return block(this[0] as A, this[1] as B, this[2] as C, this[3] as D, this[4] as E)

}