package org.botdesigner.blueprint.stdlib.pins

import androidx.compose.ui.graphics.Color
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.*

@Serializable
@SerialName("LongArrayPinFactory")
object LongArrayPinFactory : PinFactory<Iterable<Long>> {
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = LongArrayPin(
        id = id,
        order = order,
        elId = elementId,
        isOut = isOut,
        name = name,
        required = required
    )
}

@Serializable
@SerialName("LongArrayPin")
data class LongArrayPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val isOut: Boolean,
    override val name: String = "",
    override val value: Iterable<Long>? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : ArrayPin<Long>() {

    override val childPinFactory: PinFactory<Long>
        get() = LongPinFactory

    override val color: Color
        get() = BlueprintColors.Long

    override fun withReference(parent: Pin<*>?): LongArrayPin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun withValue(value: String): LongArrayPin {
        return copy(value = null, parentId = null, parentPinId = null)
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<Iterable<Long>> {
        return LongArrayPinFactory
    }
}

fun PinsDirectionalScope.longArray(name: String = "", order : UInt? = null) {
    pin { o, i ->
        LongArrayPin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
        )
    }
}