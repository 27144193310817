package org.botdesigner.blueprint

import androidx.compose.runtime.Immutable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.Snapshot
import androidx.compose.runtime.snapshots.SnapshotStateList
import kotlinx.datetime.Clock
import org.botdesigner.blueprint.components.Id

enum class BlueprintLogLevel(val priority : Int) {
    Verbose(1), Warning(10), Error(20)
}

@Immutable
data class BlueprintTerminalLog(
    val id : String,
    val level : BlueprintLogLevel,
    val message : String
)

interface BlueprintLogcat {

    val enabled : State<Boolean>

    val log : List<BlueprintTerminalLog>

    var minLevel : BlueprintLogLevel

    fun log(level : BlueprintLogLevel, msg : String)

    fun clear()

    fun setEnabled(enabled : Boolean)
}

private const val MAX_LOGCAT_SIZE = 1000

class DefaultBlueprintLogcat(
    private val maxSize : Int = MAX_LOGCAT_SIZE,
    enabled: Boolean = true
) : BlueprintLogcat {

    private val _enabled = mutableStateOf(enabled)
    override val enabled: State<Boolean> get() = _enabled

    override var minLevel: BlueprintLogLevel by mutableStateOf(BlueprintLogLevel.Verbose)

    private val _log  = SnapshotStateList<BlueprintTerminalLog>()
    override val log : List<BlueprintTerminalLog> = _log

    override fun log(level : BlueprintLogLevel, msg : String) {
        if (enabled.value && level.priority >= minLevel.priority) {
            Snapshot.withoutReadObservation {
                if (_log.size >= maxSize) {
                    _log.removeLast()
                }
                _log.add(
                    BlueprintTerminalLog(
                        id = com.benasher44.uuid.uuid4().toString(),
                        level = level,
                        message = msg
                    )
                )
            }
        }
    }

    override fun clear() {
        _log.clear()
    }

    override fun setEnabled(enabled: Boolean) {
        _enabled.value = enabled
    }
}