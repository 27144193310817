@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.telegram.functions

import androidx.compose.ui.graphics.Color
import kotlin.Any
import kotlin.Pair
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext

@Serializable
@SerialName("BpTgUnpinAllMessages")
public class BpTgUnpinAllMessages(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Chat Id",
              isOut = i,
              required = true
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("")
      },
) : BpProcedure<@Contextual TelegramBotBlueprintContext>() {
  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4280696141)

  override val name: String
    get() = "Unpin All Messages"

  override val factory: ProcedureFactory<@Contextual TelegramBotBlueprintContext>
    get() = ::BpTgUnpinAllMessages

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual TelegramBotBlueprintContext,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(TgUnpinAllMessages(
  		requireNotNull(input[0] as kotlin.Long?) { 
  "Chat Id can't be null" 
  }
  	))}
}
