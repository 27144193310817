package org.botdesigner.shared.domain.content

import com.arkivanov.decompose.Child


/**
 * A state holder for `Child Stack`.
 *
 * @param active the currently active (top) child of the stack.
 * @param backStack the back stack (inactive children), can be empty.
 */
data class MultipaneChildStack<out C : Any, out T : Any>(
    val active: Child.Created<C, T>?,
    val pane : Child.Created<C, T>? = null,
    val backStack: List<Child.Created<C, T>> = emptyList(),
) {
    /**
     * Returns the full stack of component configurations, ordered from tail to head.
     */
    val items: List<Child.Created<C, T>> = buildList {
        pane?.let {
            add(it)
        }
        addAll(backStack)
        active?.let {
            add(active)
        }
    }
}
