package org.botdesigner.shared.data.source.impl

import app.cash.sqldelight.async.coroutines.awaitAsList
import org.botdesigner.api.auth.Session
import org.botdesigner.shared.data.cache.SqSession
import org.botdesigner.shared.data.cache.SqSessionQueries
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.data.source.CacheSessionsDataSource

internal class CacheSessionsDataSourceImpl(
    private val queries: SqSessionQueries,
    private val userRepository: UserRepository
) : CacheSessionsDataSource {

    override suspend fun save(session: List<Session>) {
        val uid = checkNotNull(userRepository.currentUser?.id)
        queries.deleteAll(uid)
        session.forEach {
            queries.create(
                id = it.id,
                ownerId = uid,
                ip = it.ip,
                country = it.country,
                city = it.city,
                device = it.device,
                deviceType = it.deviceType,
                lastActive = it.lastActive,
                isCurrent = it.isCurrent
            )
        }
    }

    override suspend fun closeAll() {
        queries.deleteAll(checkNotNull(userRepository.currentUser?.id))
    }

    override suspend fun close(sessionId: String) {
        queries.delete(checkNotNull(userRepository.currentUser?.id))
    }

    override suspend fun getAll(): List<Session> {
        return queries.getAll(checkNotNull(userRepository.currentUser?.id))
            .awaitAsList().map(SqSession::toSession)
    }
}

private fun SqSession.toSession() = Session(
    id = id,
    ip = ip,
    country = country,
    city = city,
    device = device,
    deviceType = deviceType,
    lastActive = lastActive,
    isCurrent = isCurrent
)