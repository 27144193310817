package io.github.alexzhirkevich.onetap

interface OAuthResult {
    val code : String?
    val accessToken : String?
    val state : String?

    companion object {

        fun parse(uri : String, expectedState: String? = null) : OAuthResult {

            val params = uri.queryParameters
            val receivedState = params["state"]

            check(expectedState == null || receivedState == expectedState){
                "Incorrect state received. Expected: '$expectedState', got: '$receivedState'"
            }

            return OAuthResult(
                code = params["code"],
                accessToken = params["access_token"],
                state = params["state"],
            )
        }
    }
}

fun OAuthResult(
    code : String? = null,
    accessToken: String? = null,
    state : String? = null
) : OAuthResult = OAuthResultImpl(
    code = code,
    accessToken = accessToken,
    state = state
)

private class OAuthResultImpl(
    override val code : String?,
    override val accessToken : String?,
    override val state: String?
) : OAuthResult {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as OAuthResultImpl

        if (code != other.code) return false
        if (accessToken != other.accessToken) return false
        if (state != other.state) return false

        return true
    }

    override fun hashCode(): Int {
        var result = code?.hashCode() ?: 0
        result = 31 * result + (accessToken?.hashCode() ?: 0)
        result = 31 * result + (state?.hashCode() ?: 0)
        return result
    }

    override fun toString(): String {
        return "OAuthResultImpl(code=$code, accessToken=$accessToken, state=$state)"
    }
}