package io.github.alexzhirkevich.onetap

/**
 * [Apple OAuth flow](https://developer.apple.com/documentation/sign_in_with_apple/request_an_authorization_to_the_sign_in_with_apple_server)
 *
 * NOTE: If you request name or email [scopes] you will get NULL VALUES.
 *
 * Apple forbids using 'query' response_mode for OAuth requests that require 'email' and 'name' scopes.
 * Only form_post method is allowed when Apple sends a POST request directly to your backend.
 * That's why the resulted token will not contain email and name - only user id.
 * So you should ask user for his name after registration if you need it.
 * If the account was already created then you can just use this anonymous token to authenticate
 * user by id.
 * You can use [AppleAS] client on iOS to get name and email.
 *
 * @param clientId The identifier (App ID or Services ID) for your app. The identifier must not include your
 * Team ID, to help prevent the possibility of exposing sensitive data to the end user.
 * @param redirectUri The destination URI associated to your app, to which the authorization redirects.
 * The URI must use the HTTPS protocool, include a domain name, can’t be an IP address or localhost,
 * and must not contain a fragment identifer (#).
 * Visit [Configure Sign in with Apple](https://developer.apple.com/help/account/configure-app-capabilities/configure-sign-in-with-apple-for-the-web) for more information.
 * @param responseType The type of response requested. Valid values are **code** and **id_token**.
 * You can request only code, or both code and id_token. Requesting only id_token is unsupported.
 * @param scopes The amount of user information requested from Apple. Valid values are **name** and **email**.
 * You can request one, both, or none.
 * @param state An arbitrary string that your app provides, representing the current state of the
 * authorization request. This value is also used to mitigate cross-site request forgery attacks,
 * by comparing against the state value contained in the authorization response.
 * */
fun OneTap.Apple(
    clientId: String,
    redirectUri: String,
    scopes : List<String> = emptyList(),
    responseType : String = "code",
    state : String? = randomState(),
) : OAuthSignInClient<OIDCResult> = OAuthSignInClient(
    uri = buildOAuthUri("https://appleid.apple.com/auth/authorize") {
        clientId(clientId)
        redirectUri(redirectUri)
        scopes(scopes)
        responseType(responseType)
        scopes(scopes)

        val responseMode =  if ("id_token" !in responseType && scopes.isEmpty()) {
            "query"
        } else {
            println("WARNING: Apple sign in requests launched by this client WILL RETURN NULL VALUES. Please read OneTap.Apple KDoc note")
            "form_post"
        }
        param("response_mode", responseMode)
        if (state != null){
            state(state)
        }
    },
    redirectUri = redirectUri,
    onRedirected = {
        OIDCResult.parse(it, state)
    }
)

/**
 * Client will use ASAuthorizationController on iOS and [Apple] client on other platforms.
 * [clientId] and [redirectUri] params can be empty for iOS.
 * */
expect fun OneTap.AppleAS(
    clientId: String,
    redirectUri: String,
    scopes : List<String> = listOf("name", "email"),
    state : String? = randomState(),
) : SignInClient<AppleSignInResult>


class AppleSignInResult(
    override val code : String?,
    override val idToken : String?,
    override val state: String?,
    val account : AppleAccount?
) : OIDCResult

class AppleAccount(
    val id : String,
    val email: String?,
    val firstName : String?,
    val lastName : String?
)