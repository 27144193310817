package org.botdesigner.blueprint.stdlib.functions.arrays

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Transient
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.ArrayPin
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintFunction
import org.botdesigner.blueprint.components.BlueprintFunctionStateHolder
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.GenericArrayPin
import org.botdesigner.blueprint.components.GenericArrayPinFactory
import org.botdesigner.blueprint.components.GenericPinFactory
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.generic
import org.botdesigner.blueprint.components.genericArray
import org.botdesigner.blueprint.components.inputPins
import org.botdesigner.blueprint.components.outputPins
import org.botdesigner.blueprint.components.parentPin
import org.botdesigner.blueprint.stdlib.pins.bool
import org.botdesigner.blueprint.toAnnotatedString

@kotlinx.serialization.Serializable
@SerialName("BpFilter")
class BpFilter(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id) {
        input {
            genericArray("Array")
            bool("Filter")
        }
        output {
            generic("Element")
            genericArray("Filtered")
        }
    },
) : BlueprintFunction {

    override val summary: AnnotatedString
        get() = "Remove elements that don't match the <b>Filter</b> condition. Condition should be made using <b>Element</b> and passed to <b>Filter</b>.  Using <b>Element</b> for purposes other than filtering can result in errors"
            .toAnnotatedString()

    override val color: Color
        get() = BlueprintColors.Util

    fun copy(
        coordinate: Point,
        pins: List<Pin<*>>,
    ): BpFilter {
        return BpFilter(coordinate, id, pins)
    }

    @Transient
    private var currentIndex = 0

    @Transient
    private var cachedArray: List<*>? = null

    private val mutex by lazy { Mutex() }

    @Suppress("UNCHECKED_CAST")
    override suspend fun <T> pinValue(pin: Pin<T>, pool: BlueprintNodesPool): T? {
        if (!pin.isOut) {
            return super.pinValue(pin, pool)
        }

        mutex.withLock {
            val input = inputPins
            val inputArray = cachedArray ?:
                (super.pinValue(input[0], pool) as Iterable<*>? ?: return null)
                    .toList().also {
                        cachedArray = it
                    }

            if (pin.id == outputPins[1].id) {
                val filtered = inputArray.filter { pinValue(input[1], pool) == true } as T?
                currentIndex = 0
                cachedArray = null
                return filtered
            }

            return (inputArray[currentIndex] as T?).also {
                currentIndex++
            }
        }
    }

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> {
        return object : BlueprintFunctionStateHolder<BpFilter>(
            element = this,
            manager = manager
        ) {

            override fun updatePin(
                pin: Pin<*>,
            ) {
                super.updatePin(pin)

                val inputArray = pins.first { !it.isOut } as GenericArrayPin

                val parent = inputArray.parentPin(manager)

                val factory = if (parent == null || parent !is ArrayPin<*>) {
                    GenericPinFactory
                } else {
                    parent.childPinFactory
                }

                val value = outputPins.first()

                manager.resetPin(value)

                val newPin = factory.create(
                    id = value.id,
                    order = value.order,
                    elementId = value.elId,
                    name = value.name,
                    isOut = value.isOut
                )

                manager.resetPin(value)
                super.updatePin(newPin)


                val mappedArray = pins.last { it.isOut }

                val newMappedArray = factory.array(
                    id = mappedArray.id,
                    order = mappedArray.order,
                    elementId = mappedArray.elId,
                    name = mappedArray.name,
                    isOut = mappedArray.isOut
                ) ?: GenericArrayPinFactory.create(
                    id = mappedArray.id,
                    order = mappedArray.order,
                    elementId = mappedArray.elId,
                    name = mappedArray.name,
                    isOut = mappedArray.isOut
                )

                manager.resetPin(mappedArray)
                super.updatePin(newMappedArray)
            }

            override fun save() = element.copy(
                coordinate = position.value,
                pins = pins,
            )
        }
    }
}