@file:OptIn(ExperimentalCupertinoApi::class)

package org.botdesigner.ui.screens.bot

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material.icons.filled.MoreHoriz
import androidx.compose.material.icons.filled.Pause
import androidx.compose.material.icons.filled.PlayArrow
import androidx.compose.material.icons.outlined.GppMaybe
import androidx.compose.material.icons.rounded.Error
import androidx.compose.material.icons.rounded.ReportProblem
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FilledTonalButton
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import com.valentinilk.shimmer.shimmer
import io.github.alexzhirkevich.cupertino.CupertinoButton
import io.github.alexzhirkevich.cupertino.CupertinoButtonDefaults.grayButtonColors
import io.github.alexzhirkevich.cupertino.CupertinoDropdownMenu
import io.github.alexzhirkevich.cupertino.CupertinoDropdownMenuDefaults
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.MenuAction
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveIconButton
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTopAppBar
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveWidget
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.adaptive.icons.Add
import io.github.alexzhirkevich.cupertino.adaptive.takeOrElse
import io.github.alexzhirkevich.cupertino.isTopBarTransparent
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import io.github.alexzhirkevich.cupertino.theme.systemGreen
import io.github.alexzhirkevich.cupertino.theme.systemYellow
import kotlinx.coroutines.flow.filter
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.core.BotStatus
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.shared.domain.asFlow
import org.botdesigner.shared.domain.content.bot.BotComponent
import org.botdesigner.shared.domain.content.bot.BotViewState
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.message
import org.botdesigner.shared.util.title
import org.botdesigner.shared.util.valueOrNull
import org.botdesigner.ui.common.NavigateBackButton
import org.botdesigner.ui.common.PlatformDialogContainer
import org.botdesigner.ui.common.dialogs.ConfirmationDialog
import org.botdesigner.ui.common.dialogs.LoadingDialog
import org.botdesigner.ui.common.isLoading
import org.botdesigner.ui.common.rememberPlatformDialogState
import org.botdesigner.ui.common.subscribeWithState
import org.botdesigner.ui.screens.bot.bpcontext.BlueprintContextSelectionDialog
import org.botdesigner.ui.screens.bot.bptype.BlueprintTypeSelectionDialog
import org.botdesigner.ui.screens.content.SectionContextMaxWidth
import org.botdesigner.ui.screens.list.BotsError
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable
import org.botdesigner.ui.theme.platformClickable

@OptIn(ExperimentalMaterial3Api::class, ExperimentalAdaptiveApi::class)
@Composable
internal fun BotScreen(
    component: BotComponent,
    modifier: Modifier = Modifier
) {
    val state by component.state.collectAsState()

    val typeSelectionDialogState = rememberPlatformDialogState()

    component.alertDialog.subscribeAsState().let {
        when (val i = it.value.child?.instance ?: return@let) {
            is BotComponent.AlertDialog.ContextSelection -> {
                BlueprintContextSelectionDialog(i.component)
            }

            is BotComponent.AlertDialog.DeleteBlueprint -> {
                ConfirmationDialog(i.component)
            }

            is BotComponent.AlertDialog.BotIsRunning -> {
                ConfirmationDialog(i.component)
            }

            is BotComponent.AlertDialog.DeleteBot -> {
                ConfirmationDialog(i.component)
            }
        }
    }

    val componentDialog by component
        .componentDialog.subscribeWithState(typeSelectionDialogState)

    val lazyListState = rememberLazyListState()

    if (state.showProgress) {
        LoadingDialog()
    }

    PlatformDialogContainer(
        modifier = modifier,
        state = typeSelectionDialogState,
        onDismissRequest = component::onComponentDialogDismissed,
        unboundHeight = true,
        dialog = {
            when (val i = componentDialog.child?.instance) {
                is BotComponent.ComponentDialog.BlueprintTypeSelection ->
                    BlueprintTypeSelectionDialog(i.component, typeSelectionDialogState)

                null -> Box(Modifier.fillMaxSize())
            }
        }
    ) {
        AdaptiveScaffold(
            modifier = Modifier
                .fillMaxSize(),
            containerColor = MaterialTheme.colorScheme.background,
            floatingActionButton = {
                if (CurrentPlatform != Platform.Ios) {
                    FloatingActionButton(
                        modifier = Modifier
                            .pointerHoverIcon(PointerIcon.PlatformClickable),
                        onClick = component::onNewBlueprintClicked
                    ) {
                        Icon(
                            imageVector = Icons.Default.Add,
                            contentDescription = string(SharedRes.strings.add_new)
                        )
                    }
                }
            },
            topBar = {
                AdaptiveTopAppBar(
                    title = {
                        Text(
                            text = state.bot.valueOrNull()?.name ?: "*******",
                            modifier = Modifier.isLoading(state.bot !is UIState.Success),
                        )
                    },
                    navigationIcon = {
                        if (LocalInterfaceIdiom.current == InterfaceIdiom.Mobile) {
                            NavigateBackButton(
                                onClick = component::onBack
                            )
                        }
                    },
                    actions = {
                        if (CurrentPlatform == Platform.Ios) {
                            AdaptiveIconButton(
                                onClick = component::onNewBlueprintClicked
                            ) {
                                Icon(
                                    imageVector = AdaptiveIcons.Outlined.Add,
                                    contentDescription = string(SharedRes.strings.new_blueprint)
                                )
                            }
                        }
                    },
                    adaptation = {
                        cupertino {
                            isTranslucent = CurrentPlatform.isMobile
                            isTransparent = isTranslucent && lazyListState.isTopBarTransparent
                        }
                    }
                )
            },
        ) {

            Box(
                modifier = Modifier
                    .fillMaxSize(),
                contentAlignment = Alignment.TopCenter
            ) {
//
//                PlatformVerticalScrollbar(
//                    scrollableState = lazyListState,
//                    modifier = Modifier
//                        .zIndex(1f)
//                        .align(Alignment.CenterEnd)
//                )

                Column(
                    modifier = Modifier
                        .padding(it)
                        .widthIn(max = SectionContextMaxWidth)
                        .fillMaxSize(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    BotActions(
                        component = component,
                        state = state,
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(top = Dimens.Spacing.ExtraLarge)
                            .padding(horizontal = Dimens.Spacing.Large)
                    )


                    val blueprints = state.blueprints

                    if (blueprints is UIState.Error) {
                        BotsError(
                            title = string(blueprints.title()),
                            text = string(blueprints.message()),
                            modifier = Modifier.fillMaxSize(),
                            onRefresh = component::refresh
                        )
                    } else {
                        if (CurrentPlatform == Platform.Web) {
                            MessageContainer(
                                visible = state.blueprints.valueOrNull()?.isNotEmpty() == true,
                            ) {
                                WarningMessage(
                                    text = string(SharedRes.strings.warning_web_performance),
                                    onClick = component::onDownloadAppClicked
                                )
                            }
                        }
                    }

                    MessageContainer(
                        visible = state.bot.valueOrNull()?.let {
                            it.isTokenValid && !it.isDebugTokenValid
                        } == true
                    ) {
                        WarningMessage(
                            text = string(SharedRes.strings.warning_invalid_bot_token_debug),
                            onClick = component::onEditClicked
                        )
                    }

                    MessageContainer(
                        visible = state.bot.valueOrNull()?.isTokenValid == false,
                    ) {
                        ErrorMessage(
                            text = string(SharedRes.strings.error_invalid_bot_token_change),
                            onClick = component::onEditClicked
                        )
                    }

                    BlueprintList(
                        modifier = Modifier
                            .weight(1f),
                        lazyListState = lazyListState,
                        blueprints = blueprints.valueOrNull(),
                        onClick = component::onBlueprintSelected,
                        onDelete = component::onBlueprintDelete,
                        discardDelete = remember(component) {
                            component.alertDialog.asFlow().filter { it.child == null }
                        },
                        onEdit = component::onBlueprintEditClicked
                    )
                }
            }
        }
    }
}

@Composable
private fun ColumnScope.MessageContainer(
    visible : Boolean,
    modifier: Modifier = Modifier,
    message : @Composable () -> Unit
) {
    AnimatedVisibility(
        visible = visible,
        modifier = modifier,
    ) {
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            message()
        }
    }
}

@Composable
private fun ErrorMessage(
    modifier: Modifier = Modifier
        .padding(
            start = Dimens.Spacing.Large,
            end = Dimens.Spacing.Large,
            top = Dimens.Spacing.ExtraLarge,
        )
        .pointerHoverIcon(PointerIcon.PlatformClickable)
        .widthIn(max = SectionContextMaxWidth),
    text : String,
    onClick: () -> Unit
) {
    Surface(
        modifier = modifier,
        shape = MaterialTheme.shapes.small,
        color = MaterialTheme.colorScheme.errorContainer,
        contentColor = MaterialTheme.colorScheme.error,
        onClick = onClick
    ) {
        Row(
            modifier = Modifier
                .padding(
                    horizontal = Dimens.Spacing.Medium,
                    vertical = Dimens.Spacing.Small
                ),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(Dimens.Spacing.Medium)
        ) {

            Icon(
                imageVector = Icons.Rounded.Error,
                contentDescription = null,
                modifier = Modifier.shimmer()
            )

            Text(
                text = text,
                style = MaterialTheme.typography.labelMedium,
            )
        }
    }
}



@Composable
private fun WarningMessage(
    modifier: Modifier = Modifier
        .padding(
            start = Dimens.Spacing.Large,
            end = Dimens.Spacing.Large,
            top = Dimens.Spacing.ExtraLarge,
        )
        .pointerHoverIcon(PointerIcon.PlatformClickable)
        .widthIn(max = SectionContextMaxWidth),
    text : String,
    onClick: () -> Unit
) {
    Surface(
        modifier = modifier,
        shape = MaterialTheme.shapes.small,
        color = MaterialTheme.colorScheme.surface,
        contentColor = CupertinoColors.systemYellow,
        onClick = onClick
    ) {
        Row(
            modifier = Modifier
                .padding(
                    horizontal = Dimens.Spacing.Medium,
                    vertical = Dimens.Spacing.Small
                ),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(Dimens.Spacing.Medium)
        ) {

            Icon(
                imageVector = Icons.Rounded.ReportProblem,
                contentDescription = null,
                modifier = Modifier.shimmer()
            )

            Text(
                text = text,
                style = MaterialTheme.typography.labelMedium,
            )
        }
    }
}

@Composable
private fun BotActions(
    component: BotComponent,
    state : BotViewState,
    modifier: Modifier = Modifier
) {


    BoxWithConstraints(modifier){

        val fullButtons = with(LocalDensity.current) {
            constraints.maxWidth > 500.dp.toPx()
        }

        Row(
            modifier = Modifier
                .fillMaxWidth()
        ) {

            val bot = state.bot.valueOrNull()

            ActionChip(
                modifier = Modifier.weight(1f),
                enabled = bot != null &&
                        state.toggleEnabled && bot.isTokenValid,
                icon = if (bot?.status == BotStatus.Stopped)
                    Icons.Filled.PlayArrow else Icons.Filled.Pause,
                tint = if (bot?.status == BotStatus.Stopped)
                    CupertinoColors.systemGreen else MaterialTheme.colorScheme.error,
                title = string(
                    if (bot?.status == BotStatus.Stopped)
                        SharedRes.strings.run else SharedRes.strings.stop
                ),
                onClick = component::onToggle,
            )

            Spacer(Modifier.weight(.1f))

            ActionChip(
                modifier = Modifier.weight(1f),
                icon = Icons.Outlined.GppMaybe,
                title = string(SharedRes.strings.logs),
                onClick = component::onLogsClicked
            )

            Spacer(Modifier.weight(.1f))

            if (fullButtons) {
                ActionChip(
                    modifier = Modifier.weight(1f),
                    icon = Icons.Filled.Edit,
                    title = string(SharedRes.strings.edit),
                    onClick = component::onEditClicked
                )
                Spacer(Modifier.weight(.1f))
                ActionChip(
                    modifier = Modifier.weight(1f),
                    icon = Icons.Filled.Delete,
                    tint = MaterialTheme.colorScheme.error,
                    title = string(SharedRes.strings.delete),
                    onClick = component::onDeleteClicked
                )
            } else {
                var menuExpanded by rememberSaveable {
                    mutableStateOf(false)
                }
                Box(Modifier.weight(1f)) {
                    ActionChip(
                        modifier = Modifier.fillMaxWidth(),
                        icon = Icons.Filled.MoreHoriz,
                        title = string(SharedRes.strings.more),
                        onClick = {
                            menuExpanded = true
                        }
                    )
                    CupertinoDropdownMenu(
                        width = if (CurrentPlatform.isMobile)
                            CupertinoDropdownMenuDefaults.DefaultWidth
                        else CupertinoDropdownMenuDefaults.SmallWidth,
                        expanded = menuExpanded,
                        onDismissRequest = {
                            menuExpanded = false
                        }
                    ) {
                        MenuAction(
                            icon = {
                                Icon(
                                    imageVector = Icons.Filled.Edit,
                                    contentDescription = null
                                )
                            },
                            onClickLabel = string(SharedRes.strings.edit),
                            onClick = {
                                menuExpanded = false
                                component.onEditClicked()
                            }
                        ) {
                            Text(string(SharedRes.strings.edit))
                        }
                        MenuAction(
                            icon = {
                                Icon(
                                    imageVector = Icons.Filled.Delete,
                                    contentDescription = null
                                )
                            },
                            onClickLabel = string(SharedRes.strings.delete),
                            onClick = {
                                menuExpanded = false
                                component.onDeleteClicked()
                            },
                            contentColor = MaterialTheme.colorScheme.error
                        ) {
                            Text(string(SharedRes.strings.delete))
                        }
                    }
                }
            }
        }
    }
}


@OptIn(ExperimentalAdaptiveApi::class, ExperimentalLayoutApi::class)
@Composable
private fun ActionChip(
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    icon: ImageVector,
    tint: Color = Color.Unspecified,
    title: String,
    onClick: () -> Unit
) {
    val isMobile = LocalInterfaceIdiom.current == InterfaceIdiom.Mobile

    @Composable
    fun content() {
        FlowRow(
            verticalArrangement = Arrangement.Center,
            horizontalArrangement = Arrangement.spacedBy(
                Dimens.Spacing.Small,
                Alignment.CenterHorizontally
            )
        ) {

            Icon(
                imageVector = icon,
                contentDescription = null,
                tint = tint.takeIf { enabled }.takeOrElse { LocalContentColor.current },
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically)
            )
            Text(
                modifier = Modifier.align(Alignment.CenterVertically),
                text = title,
                maxLines = 1,
                overflow = TextOverflow.Clip,
                color = tint.takeIf { enabled }.takeOrElse { LocalContentColor.current },
            )
        }
    }

    AdaptiveWidget(
        material = {
            FilledTonalButton(
                modifier = modifier
                    .pointerHoverIcon(PointerIcon.platformClickable(enabled)),
                onClick = onClick,
                enabled = enabled,
                shape = if (isMobile)
                    MaterialTheme.shapes.small
                else ButtonDefaults.filledTonalShape
            ) {
                content()
            }
        },
        cupertino = {
            CupertinoButton(
                modifier = modifier
                    .pointerHoverIcon(PointerIcon.platformClickable(enabled)),
                onClick = onClick,
                enabled = enabled,
                colors = grayButtonColors(
                    containerColor = CupertinoTheme.colorScheme.secondarySystemGroupedBackground,
                )
            ){
                content()
            }
        }
    )
}

