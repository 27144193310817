package org.botdesigner.ui.screens.create

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.VisualTransformation
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.filter
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.create.CreateOrEditBotComponent
import org.botdesigner.shared.domain.content.create.createEnabled
import org.botdesigner.ui.common.LearnMoreText
import org.botdesigner.ui.screens.FormScreen
import org.botdesigner.ui.string

@Composable
internal fun CreateOrEditBotScreen(
    component : CreateOrEditBotComponent,
    modifier: Modifier = Modifier,
) {

    val state by component.state.collectAsState()

    FormScreen(
        modifier = modifier,
        title = string(
            if (component.isEdit)
                SharedRes.strings.edit
            else SharedRes.strings.new_bot
        ),
        confirm = string(
            if (component.isEdit)
                SharedRes.strings.save
            else SharedRes.strings.create
        ),
        onConfirm = component::onCreateClicked,
        onBack = component::onBack,
        confirmEnabled = state.createEnabled
    ) {

        val tokenFocus = remember {
            FocusRequester()
        }

        val debugTokenFocus = remember {
            FocusRequester()
        }

        LaunchedEffect(component) {
            snapshotFlow {
                state.tokenError != null
            }.filter { it }.collectLatest {
                tokenFocus.requestFocus()
            }
            snapshotFlow {
                state.debugTokenError != null
            }.filter { it }.collectLatest {
                debugTokenFocus.requestFocus()
            }
        }

        FormTextField(
            value = state.name,
            modifier = Modifier
                .fillMaxWidth(),
            onValueChange = component::onNameChanged,
            singleLine = true,
            enabled = !state.isLoading,
            keyboardOptions = KeyboardOptions(
                imeAction = ImeAction.Next
            ),
            label = {
                Text(string(SharedRes.strings.name))
            },
            supportingText = {
                Text(string(SharedRes.strings.name_caption))
            }
        )

        FormTextField(
            value = state.token,
            modifier = Modifier
                .focusRequester(tokenFocus)
                .fillMaxWidth(),
            onValueChange = component::onTokenChanged,
            singleLine = true,
            enabled = !state.isLoading,
            isError = state.tokenError != null,
            keyboardOptions = KeyboardOptions(
                imeAction = ImeAction.Next
            ),
            label = {
                Text(string(SharedRes.strings.token))
            },
            supportingText = {
                val text =
                    string(state.tokenError ?: state.type.tokenDescription)

                if (state.tokenError == null) {
                    LearnMoreText(
                        text = text,
                        onLearnMoreClick = component::onTokenDescriptionHelpClicked
                    )
                } else {
                    Text(text)
                }

                Text(string(state.tokenError ?: state.type.tokenDescription))
            }
        )

        FormTextField(
            value = state.debugToken,
            modifier = Modifier
                .fillMaxWidth()
                .focusRequester(debugTokenFocus),
            onValueChange = component::onDebugTokenChanged,
            singleLine = true,
            enabled = !state.isLoading,
            isError = state.debugTokenError != null,
            keyboardOptions = KeyboardOptions(
                imeAction = ImeAction.Done
            ),
            keyboardActions = KeyboardActions {
                if (state.createEnabled) {
                    component.onCreateClicked()
                }
            },
            label = {
                Text(string(SharedRes.strings.debug_token_optional))
            },
            supportingText = {
                val text = string(
                    state.debugTokenError
                        ?: SharedRes.strings.debug_token_caption.desc()
                )
                Text(text)
            }
        )
    }
}

@Composable
fun FormTextField(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    readOnly: Boolean = false,
    textStyle: TextStyle = LocalTextStyle.current,
    label: @Composable (() -> Unit)? = null,
    placeholder: @Composable (() -> Unit)? = null,
    leadingIcon: @Composable (() -> Unit)? = null,
    trailingIcon: @Composable (() -> Unit)? = null,
    prefix: @Composable (() -> Unit)? = null,
    suffix: @Composable (() -> Unit)? = null,
    supportingText: @Composable (() -> Unit)? = null,
    isError: Boolean = false,
    visualTransformation: VisualTransformation = VisualTransformation.None,
    keyboardOptions: KeyboardOptions = KeyboardOptions.Default,
    keyboardActions: KeyboardActions = KeyboardActions.Default,
    singleLine: Boolean = false,
    maxLines: Int = if (singleLine) 1 else Int.MAX_VALUE,
    minLines: Int = 1,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    shape: Shape = MaterialTheme.shapes.medium,
) {
    OutlinedTextField(
        value = value,
        onValueChange = onValueChange,
        modifier = modifier,
        enabled = enabled,
        readOnly = readOnly,
        textStyle = textStyle,
        label = label,
        placeholder = placeholder,
        leadingIcon = leadingIcon,
        trailingIcon = trailingIcon,
        prefix = prefix,
        suffix = suffix,
        supportingText = supportingText,
        isError = isError,
        visualTransformation = visualTransformation,
        keyboardOptions = keyboardOptions,
        keyboardActions = keyboardActions,
        singleLine = singleLine,
        maxLines = maxLines,
        minLines = minLines,
        interactionSource = interactionSource,
        shape = shape
    )
}
