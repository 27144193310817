package org.botdesigner.shared.data.repo.impl

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.launch
import kotlinx.coroutines.supervisorScope
import kotlinx.datetime.Clock
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.shared.data.repo.BlueprintsRepository
import org.botdesigner.shared.data.source.CacheBlueprintDataSource
import org.botdesigner.shared.data.source.RemoteBlueprintDataSource
import org.botdesigner.shared.util.runCatchingIgnoringCancellation


internal class BlueprintsRepositoryImpl(
    private val cacheBlueprintDataSource: CacheBlueprintDataSource,
    private val remoteBlueprintDataSource: RemoteBlueprintDataSource
) : BlueprintsRepository {

    private val updateTrigger = MutableSharedFlow<UpdateSource>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    override fun getAll(botId: String, syncScope : CoroutineScope): Flow<List<BlueprintData>> {
        return DataFlow(
            updateTrigger = updateTrigger,
            merge = { cached, remote ->
                // delete blueprints that are deleted in cache and exists in remote
                cached.asSequence()
                    .filter(BlueprintData::isDeleted)
                    .forEach { syncScope.launch { delete(botId, it.id, true) } }

                // update blueprints that are updated in cache and don't updated in remote
                remote.map { rem ->
                    val cachedForRem = cached.find {
                        rem.id == it.id
                    } ?: return@map rem

                    if (cachedForRem.editedAt > rem.editedAt) {
                        syncScope.launch {
                            remoteBlueprintDataSource.update(botId, cachedForRem)
                        }
                        cachedForRem
                    } else rem
                }.filterNot(BlueprintData::isDeleted)
            },
            postTransform = {
                it.filterNot(BlueprintData::isDeleted)
                    .sortedByDescending(BlueprintData::editedAt)
            },
            cache = {
                cacheBlueprintDataSource.getAll(botId)
            },
            remote = {
                remoteBlueprintDataSource.getAllUpdated(
                    botId = botId,
                    local = runCatchingIgnoringCancellation {
                        cacheBlueprintDataSource.getAll(botId)
                    }.getOrElse { emptyList() }
                )
            },
            save = { list ->
                runCatchingIgnoringCancellation {
                    cacheBlueprintDataSource.deleteAll(botId)
                    list.forEach {
                        cacheBlueprintDataSource.add(botId, it)
                    }
                }
            }
        ).distinctUntilChanged().catch {
            updateTrigger.emit(UpdateSource.Remote())
            throw it
        }
    }

    override fun get(botId: String, id: String): Flow<BlueprintData> {
        return DataFlow(
            id = id,
            updateTrigger = updateTrigger.filter { id in it.keys },
            remote = {
                remoteBlueprintDataSource.get(botId, id)
            },
            cache = {
                cacheBlueprintDataSource.get(botId, id)
            },
            save = {
                runCatchingIgnoringCancellation {
                    cacheBlueprintDataSource.add(botId, it)
                }
            }
        ).distinctUntilChanged().catch {
            updateTrigger.emit(UpdateSource.Remote())
            throw it
        }
    }

    override suspend fun add(botId: String, blueprint: BlueprintData): BlueprintData {
        return Data(
            data = blueprint,
            remote = {
                remoteBlueprintDataSource.add(botId, blueprint)
            },
            save = {
                runCatchingIgnoringCancellation {
                    if (it.id != blueprint.id) {
                        cacheBlueprintDataSource.replaceId(
                            botId = botId,
                            oldId = blueprint.id,
                            newId = it.id
                        )
                        cacheBlueprintDataSource.add(botId, it.copy(initialId = blueprint.id))
                        updateTrigger.tryEmit(UpdateSource.Local(it.id, it.initialId))
                    } else {
                        cacheBlueprintDataSource.add(botId, it)
                        updateTrigger.tryEmit(UpdateSource.Local(it.id))
                    }
                }.onFailure { _ ->
                    updateTrigger.tryEmit(UpdateSource.Remote(it.id))
                }
            }
        )
    }

    override suspend fun updateLocally(botId: String, blueprint: BlueprintData): BlueprintData {

        val updatedBlueprint = blueprint.copy(
            editedAt = Clock.System.now().epochSeconds
        )
        val new = cacheBlueprintDataSource.update(botId, updatedBlueprint)
        updateTrigger.emit(UpdateSource.Local(updatedBlueprint.id))

        return new
    }

    override suspend fun update(botId: String, blueprint: BlueprintData): BlueprintData {

        val updatedBlueprint = blueprint.copy(
            editedAt = Clock.System.now().epochSeconds
        )

        return Data(
            data = updatedBlueprint,
            remote = {
                remoteBlueprintDataSource.update(botId, blueprint)
            },
            save = {
                runCatchingIgnoringCancellation {
                    cacheBlueprintDataSource.update(botId, it)
                    updateTrigger.emit(UpdateSource.Local(it.id))
                }.onFailure { _ ->
                    updateTrigger.emit(UpdateSource.Remote(it.id))
                }
            }
        )
    }

    override suspend fun delete(botId: String, id: String) {
        delete(botId, id, false)
    }

    private suspend fun delete(botId: String, id: String, remoteOnly : Boolean){
        supervisorScope {
            if (!remoteOnly) {
                launch {
                    cacheBlueprintDataSource.markDeleted(
                        botId = botId,
                        id = id,
                        deleted = true
                    )
                }
                updateTrigger.emit(UpdateSource.Local(id))
            }
            launch {
                try {
                    remoteBlueprintDataSource.delete(botId, id)
                } catch (t : Throwable){
                    cacheBlueprintDataSource.markDeleted(
                        botId = botId,
                        id = id,
                        deleted = false
                    )
                    throw t
                }
                if (!remoteOnly) {
                    cacheBlueprintDataSource.delete(botId, id)
                }
            }
        }
    }
}