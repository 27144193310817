package org.botdesigner.shared.util

import androidx.compose.ui.text.intl.Locale
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.desc.ResourceFormatted
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.provider.JsStringProvider

actual fun StringDesc.Companion.FormattedFix(
    res: StringResource,
    vararg arg : Any
) : StringDesc {
    return object : StringDesc {
        override suspend fun localized(): String {
            return StringDesc.ResourceFormatted(
                res, *arg.map {
                    when (it) {
                        is StringDesc -> it.localized()
                        is StringResource -> it.localized(
                            res.loader.getOrLoad(),
                            Locale.current.toLanguageTag()
                        )

                        else -> it
                    }
                }.toTypedArray()
            ).localized()
        }

        override fun localized(provider: JsStringProvider): String {
            return StringDesc.ResourceFormatted(
                res, *arg.map {
                    when (it) {
                        is StringDesc -> it.localized(provider)
                        is StringResource -> it.localized(provider, Locale.current.toLanguageTag())
                        else -> it
                    }
                }.toTypedArray()
            ).localized(provider)
        }
    }
}