package org.botdesigner.shared.domain.content.profile

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnStart
import dev.icerock.moko.resources.desc.ResourceFormatted
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.botdesigner.api.SharedConstants
import org.botdesigner.api.SubscriptionType
import org.botdesigner.api.subscriptionType
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.data.repo.impl.collectAsDataState
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.content.ContentComponent
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.FormattedFix
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.intents.SendEmailIntent

internal class DefaultProfileComponent(
    context: ComponentContext,
    private val onLogout: () -> Unit,
    override val topComponent: MutableStateFlow<ContentComponent.Child<*>?>,
    private val onNavigateToPremium: () -> Unit,
    private val onNavigateToDevices: () -> Unit,
    private val onNavigateToAccount: () -> Unit,
    private val onNavigateToAppearance: () -> Unit,
    private val onNavigateToSettings: () -> Unit,
    userRepository: UserRepository,
    private val sendEmailIntent: SendEmailIntent,
    private val dispatchers: Dispatchers,
    errorHandler: ErrorHandler,
) : CoroutineComponent(context, errorHandler), ProfileComponent {

    override val userInfo: StateFlow<UIState<UserInfo>> =
        userRepository
            .currentUserFlow
            .filterNotNull()
            .map {

                val sub = it.subscriptionType
                val expires = it.entitlements[sub.key]?.expiresDate.orEmpty()
                val date = kotlin.runCatching {
                    Instant.parse(expires)
                        .toLocalDateTime(TimeZone.currentSystemDefault())
                }.getOrNull()

                UserInfo(
                    user = it,
                    subscription = sub,
                    subscriptionCaption = when {
                        sub != SubscriptionType.Free && date != null ->
                            StringDesc
                                .ResourceFormatted(
                                    SharedRes.strings.expires_at,
                                    date.dayOfMonth,
                                    date.monthNumber,
                                    date.year
                                )

                        sub == SubscriptionType.Free ->
                            StringDesc.FormattedFix(
                                SharedRes.strings.invocations_left,
                                it.dailyInvocations + it.extraInvocations
                            )

                        else -> "".desc()
                    }
                )
            }
            .collectAsDataState(stateIn = componentScope)

    init {
        lifecycle.doOnStart {
            dispatchers.launchIO(componentScope) {
                userRepository.refreshUser()
            }
        }
    }

    override fun onLogout() {
        onLogout.invoke()
    }

    override fun onAccountClicked() {
        onNavigateToAccount.invoke()
    }

    override fun onPremiumClicked() {
        onNavigateToPremium.invoke()
    }

    override fun onDevicesClicked() {
        onNavigateToDevices.invoke()
    }

    override fun onSettingsClicked() {
        onNavigateToSettings.invoke()
    }

    override fun onAskQuestionClicked() {
        sendEmailIntent.invoke(SharedConstants.SupportEmail, "Bot Designer")
    }

    override fun onAppearanceClicked() {
        onNavigateToAppearance.invoke()
    }
}