package org.botdesigner.blueprint.components

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.ui.pins.ArrayPinWidget

@kotlinx.serialization.Serializable
abstract class ArrayPin<out T> : Pin<Iterable<T>>() {

    abstract val childPinFactory : PinFactory<T>

    override val color: Color get() = BlueprintColors.Array

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected: Boolean,
        pool: BlueprintNodesPool
    ) {

        val parentColor = parentPin(pool)?.color ?: color

        ArrayPinWidget(
            modifier = modifier,
            color = parentColor,
            borderColor = BlueprintColors.Array,
            name = name,
            isActive = pool.isPinActive(this@ArrayPin),
            isOut = isOut,
            isSelected = isSelected,
            onTap = { onTap(this@ArrayPin) },
        )
    }
}