package org.botdesigner.shared.domain.content.sessions

import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.StateFlow
import kotlinx.datetime.Clock
import org.botdesigner.api.auth.Session
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.StackableComponent
import org.botdesigner.shared.util.FormattedFix
import org.botdesigner.shared.util.UIState
import kotlin.time.Duration.Companion.seconds

interface SessionsComponent : StackableComponent {

    val sessions : StateFlow<UIState<List<Session>>>

    val controlsEnabled : StateFlow<Boolean>

    fun refresh()

    fun onStopClicked(session: Session)

    fun onStopAllClicked()

    fun onLearnMoreClicked()

}

private const val SecondsInMinute = 60
private const val SecondsInHour = SecondsInMinute * 60
private const val SecondsInDay = SecondsInHour * 24

val Session.lastActiveAt : StringDesc
    get() {

        if (isCurrent) {
            return SharedRes.strings.online.desc()
        }

        val now = Clock.System.now().epochSeconds

        val diff = now - lastActive

        return when {
            diff < 5 * SecondsInMinute -> StringDesc.FormattedFix(SharedRes.strings.online)
            diff < SecondsInHour -> StringDesc.FormattedFix(
                SharedRes.strings.minutes_ago, diff.seconds.inWholeMinutes
            )

            diff < SecondsInDay -> StringDesc.FormattedFix(
                SharedRes.strings.hours_ago, diff.seconds.inWholeHours
            )

            else -> StringDesc.FormattedFix(
                SharedRes.strings.days_ago, diff.seconds.inWholeDays
            )
        }
    }