package org.botdesigner.shared.data.source.impl

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import kotlinx.coroutines.delay
import org.botdesigner.api.auth.Session
import org.botdesigner.shared.data.source.SessionsDataSource

internal class RemoteSessionsDataSourceImpl(
    private val httpClient: HttpClient
) : SessionsDataSource {

    override suspend fun closeAll() {
        httpClient.post("/v1/account/sessions/stopAll")
    }

    override suspend fun close(sessionId: String) {
        httpClient.post("/v1/account/sessions/$sessionId/stop")
    }

    override suspend fun getAll(): List<Session> {
        return httpClient
            .get("/v1/account/sessions")
            .body<List<Session>>()
            .sortedByDescending(Session::lastActive)
    }
}