package org.botdesigner.ui.screens

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.WindowInsetsSides
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.only
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.BottomAppBar
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ScaffoldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.unit.dp
import io.github.alexzhirkevich.cupertino.CupertinoButtonDefaults
import io.github.alexzhirkevich.cupertino.CupertinoButtonSize
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveButton
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveHorizontalDivider
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.cupertinoTranslucentBottomBarColor
import io.github.alexzhirkevich.cupertino.isNavigationBarTransparent
import io.github.alexzhirkevich.cupertino.isTopBarTransparent
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.ui.common.ClearFocusBox
import org.botdesigner.ui.common.PlatformDialogTopBar
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.platformClickable

@OptIn(ExperimentalAdaptiveApi::class, ExperimentalCupertinoApi::class)
@Composable
fun FormScreen(
    title : String,
    confirm : String,
    confirmEnabled : Boolean,
    onConfirm : () -> Unit,
    onBack : () -> Unit,
    modifier: Modifier = Modifier,
    content : @Composable ColumnScope.() -> Unit
) {

    val scrollState = rememberScrollState()

    AdaptiveScaffold(
        modifier = modifier
            .imePadding(),
        topBar = {
            PlatformDialogTopBar(
                title = {
                    Text(title)
                },
                isTransparent = CurrentPlatform.isMobile && scrollState.isTopBarTransparent,
                onDismissRequest = onBack
            )
        },
        bottomBar = {
            Column {
                if (!scrollState.isNavigationBarTransparent){
                    AdaptiveHorizontalDivider()
                }
                BottomAppBar(
                    containerColor = cupertinoTranslucentBottomBarColor(
                        color = MaterialTheme.colorScheme.surface,
                        isTranslucent = true,
                        isTransparent = scrollState.isNavigationBarTransparent
                    )
                ) {
                    AdaptiveButton(
                        enabled = confirmEnabled,
                        onClick = onConfirm,
                        modifier = Modifier
                            .padding(horizontal = Dimens.Spacing.ExtraLarge)
                            .fillMaxWidth()
                            .pointerHoverIcon(PointerIcon.platformClickable(confirmEnabled)),
                        adaptation = {
                            cupertino {
                                size = if (CurrentPlatform.isMobile)
                                    CupertinoButtonSize.Large
                                else CupertinoButtonSize.Regular
                                colors = CupertinoButtonDefaults.borderedProminentButtonColors(
                                    contentColor = MaterialTheme.colorScheme.onPrimary
                                )
                            }
                            material {
                                contentPadding = PaddingValues(
                                    horizontal = Dimens.Spacing.ExtraLarge,
                                    vertical = Dimens.Spacing.Medium
                                )
                            }
                        }
                    ) {
                        Text(
                            text = confirm,
                            modifier = Modifier.padding(
                                horizontal = 64.dp,
                                vertical = Dimens.Spacing.ExtraSmall
                            )
                        )
                    }
                }
            }
        },
        containerColor = Color.Transparent,
        contentWindowInsets = ScaffoldDefaults.contentWindowInsets.only(
            WindowInsetsSides.Bottom + WindowInsetsSides.Start + WindowInsetsSides.End
        )
    ) { pv ->

        ClearFocusBox {
            Column(
                modifier = Modifier
                    .verticalScroll(scrollState)
                    .padding(pv)
                    .padding(
                        vertical = Dimens.Spacing.Large,
                        horizontal = Dimens.Spacing.ExtraLarge
                    ),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement
                    .spacedBy(Dimens.Spacing.Large),
                content = content
            )
        }
    }
}