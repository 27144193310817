package io.github.alexzhirkevich.onetap

import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.contract

sealed interface SignInResult<out R> {

    data object Cancelled : SignInResult<Nothing>

    class Failure constructor(val exception: Throwable? = null) : SignInResult<Nothing>

    data class Success<R> (val result : R) : SignInResult<R>
}

@OptIn(ExperimentalContracts::class)
fun <R> SignInResult<R>.resultOrNull() : R? {
    contract {
        returnsNotNull() implies (this@resultOrNull is SignInResult.Success<R>)
    }

    return (this as? SignInResult.Success<R>)?.result
}

@OptIn(ExperimentalContracts::class)
@Throws(SignInException::class)
fun <R> SignInResult<R>.resultOrThrow() : R {
    contract {
        returns() implies (this@resultOrThrow is SignInResult.Success<R>)
    }
    when (this) {
        is SignInResult.Success -> return result
        SignInResult.Cancelled -> throw SignInCancellationException()
        is SignInResult.Failure -> throw SignInException(null, exception)
    }
}


