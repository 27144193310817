package org.botdesigner.blueprint.stdlib.functions

import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectEquals
import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectToArray
import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectToFloat
import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectToFloatArray
import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectToJson
import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectToLong
import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectToLongArray
import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectToString
import org.botdesigner.blueprint.stdlib.functions.`object`.BpObjectToStringArray
import org.botdesigner.blueprint.stdlib.functions.`object`.BpStringToObject
import org.botdesigner.blueprint.stdlib.functions.arrays.BpAppend
import org.botdesigner.blueprint.stdlib.functions.arrays.BpArraySize
import org.botdesigner.blueprint.stdlib.functions.arrays.BpElementAtIndex
import org.botdesigner.blueprint.stdlib.functions.arrays.BpFirstOrNull
import org.botdesigner.blueprint.stdlib.functions.arrays.BpForEach
import org.botdesigner.blueprint.stdlib.functions.arrays.BpLastOrNull
import org.botdesigner.blueprint.stdlib.functions.arrays.BpRemove
import org.botdesigner.blueprint.stdlib.functions.boolean.BpBooleanAnd
import org.botdesigner.blueprint.stdlib.functions.boolean.BpBooleanNot
import org.botdesigner.blueprint.stdlib.functions.boolean.BpBooleanOr
import org.botdesigner.blueprint.stdlib.functions.datetime.BpCurrentTimestamp
import org.botdesigner.blueprint.stdlib.functions.datetime.BpCurrentTimestampAdd
import org.botdesigner.blueprint.stdlib.functions.datetime.BpCurrentTimestampSeconds
import org.botdesigner.blueprint.stdlib.functions.datetime.BpDecomposeTimestamp
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleDiv
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleEquals
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleGreater
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleGreaterOrEquals
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleMod
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleMul
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleRandom
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleSub
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleSum
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleToFloat
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleToFloatRound
import org.botdesigner.blueprint.stdlib.functions.float.BpDoubleToString
import org.botdesigner.blueprint.stdlib.functions.long.BpLongDiv
import org.botdesigner.blueprint.stdlib.functions.long.BpLongEquals
import org.botdesigner.blueprint.stdlib.functions.long.BpLongGreater
import org.botdesigner.blueprint.stdlib.functions.long.BpLongGreaterOrEquals
import org.botdesigner.blueprint.stdlib.functions.long.BpLongMod
import org.botdesigner.blueprint.stdlib.functions.long.BpLongMul
import org.botdesigner.blueprint.stdlib.functions.long.BpLongRandom
import org.botdesigner.blueprint.stdlib.functions.long.BpLongRange
import org.botdesigner.blueprint.stdlib.functions.long.BpLongSub
import org.botdesigner.blueprint.stdlib.functions.long.BpLongSum
import org.botdesigner.blueprint.stdlib.functions.long.BpLongToFloat
import org.botdesigner.blueprint.stdlib.functions.long.BpLongToString
import org.botdesigner.blueprint.stdlib.functions.special.BpBranch
import org.botdesigner.blueprint.stdlib.functions.special.BpDelay
import org.botdesigner.blueprint.stdlib.functions.special.BpFor
import org.botdesigner.blueprint.stdlib.functions.special.BpIfNotNull
import org.botdesigner.blueprint.stdlib.functions.special.BpIsNull
import org.botdesigner.blueprint.stdlib.functions.special.BpPrint
import org.botdesigner.blueprint.stdlib.functions.special.BpRepeat
import org.botdesigner.blueprint.stdlib.functions.special.BpReplaceNull
import org.botdesigner.blueprint.stdlib.functions.special.BpWhile
import org.botdesigner.blueprint.stdlib.functions.string.BpJoinToString
import org.botdesigner.blueprint.stdlib.functions.string.BpMatches
import org.botdesigner.blueprint.stdlib.functions.string.BpStringCharAt
import org.botdesigner.blueprint.stdlib.functions.string.BpStringConcat
import org.botdesigner.blueprint.stdlib.functions.string.BpStringEndsWith
import org.botdesigner.blueprint.stdlib.functions.string.BpStringEquals
import org.botdesigner.blueprint.stdlib.functions.string.BpStringGreater
import org.botdesigner.blueprint.stdlib.functions.string.BpStringGreaterOrEquals
import org.botdesigner.blueprint.stdlib.functions.string.BpStringLength
import org.botdesigner.blueprint.stdlib.functions.string.BpStringLowercase
import org.botdesigner.blueprint.stdlib.functions.string.BpStringReverse
import org.botdesigner.blueprint.stdlib.functions.string.BpStringSplit
import org.botdesigner.blueprint.stdlib.functions.string.BpStringStartsWith
import org.botdesigner.blueprint.stdlib.functions.string.BpStringSubstring
import org.botdesigner.blueprint.stdlib.functions.string.BpStringSubstringAfter
import org.botdesigner.blueprint.stdlib.functions.string.BpStringSubstringAfterLast
import org.botdesigner.blueprint.stdlib.functions.string.BpStringSubstringBefore
import org.botdesigner.blueprint.stdlib.functions.string.BpStringSubstringBeforeLast
import org.botdesigner.blueprint.stdlib.functions.string.BpStringToBoolean
import org.botdesigner.blueprint.stdlib.functions.string.BpStringToFloat
import org.botdesigner.blueprint.stdlib.functions.string.BpStringToLong
import org.botdesigner.blueprint.stdlib.functions.string.BpStringTrim
import org.botdesigner.blueprint.stdlib.functions.string.BpStringUppercase
import org.botdesigner.blueprint.store.BlueprintNodeCategory
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.blueprint.store.BlueprintNodeStoreRecord

public fun BlueprintNodeStoreOwner.register_org_botdesigner_blueprint_stdlib_functions() {
  BlueprintNodeStoreOwner.register(
  	BlueprintNodeCategory("Standard"),
  	BlueprintNodeStoreRecord("Array", ::BpForEach),
  	BlueprintNodeStoreRecord("Array", ::BpAppend),
  	BlueprintNodeStoreRecord("Array", ::BpRemove),
  	BlueprintNodeStoreRecord("Special", ::BpBranch),
  	BlueprintNodeStoreRecord("Special", ::BpWhile),
  	BlueprintNodeStoreRecord("Special", ::BpRepeat),
  	BlueprintNodeStoreRecord("Special", ::BpFor),
  	BlueprintNodeStoreRecord("Special", ::BpPrint),
  	BlueprintNodeStoreRecord("Special", ::BpIfNotNull),
  	BlueprintNodeStoreRecord("Array", ::BpArraySize),
  	BlueprintNodeStoreRecord("Array", ::BpElementAtIndex),
  	BlueprintNodeStoreRecord("Array", ::BpFirstOrNull),
  	BlueprintNodeStoreRecord("Array", ::BpLastOrNull),
  	BlueprintNodeStoreRecord("Date & Time", ::BpCurrentTimestamp),
  	BlueprintNodeStoreRecord("Date & Time", ::BpCurrentTimestampSeconds),
  	BlueprintNodeStoreRecord("Date & Time", ::BpDecomposeTimestamp),
  	BlueprintNodeStoreRecord("Date & Time", ::BpCurrentTimestampAdd),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleRandom),
  	BlueprintNodeStoreRecord("Integer", ::BpLongRandom),
  	BlueprintNodeStoreRecord("Integer", ::BpLongRange),
  	BlueprintNodeStoreRecord("Object", ::BpStringToObject),
  	BlueprintNodeStoreRecord("Object", ::BpObjectToJson),
  	BlueprintNodeStoreRecord("Special", ::BpDelay),
  	BlueprintNodeStoreRecord("String", ::BpStringLength),
  	BlueprintNodeStoreRecord("String", ::BpStringCharAt),
  	BlueprintNodeStoreRecord("String", ::BpStringTrim),
  	BlueprintNodeStoreRecord("String", ::BpStringUppercase),
  	BlueprintNodeStoreRecord("String", ::BpStringLowercase),
  	BlueprintNodeStoreRecord("String", ::BpStringReverse),
  	BlueprintNodeStoreRecord("String", ::BpStringSplit),
  	BlueprintNodeStoreRecord("String", ::BpStringSubstring),
  	BlueprintNodeStoreRecord("String", ::BpStringSubstringAfter),
  	BlueprintNodeStoreRecord("String", ::BpStringSubstringBefore),
  	BlueprintNodeStoreRecord("String", ::BpStringSubstringAfterLast),
  	BlueprintNodeStoreRecord("String", ::BpStringSubstringBeforeLast),
  	BlueprintNodeStoreRecord("String", ::BpStringStartsWith),
  	BlueprintNodeStoreRecord("String", ::BpStringEndsWith),
  	BlueprintNodeStoreRecord("String", ::BpJoinToString),
  	BlueprintNodeStoreRecord("String", ::BpMatches),
  	BlueprintNodeStoreRecord("Boolean", ::BpBooleanAnd),
  	BlueprintNodeStoreRecord("Boolean", ::BpBooleanOr),
  	BlueprintNodeStoreRecord("Boolean", ::BpBooleanNot),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleEquals),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleSum),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleSub),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleMul),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleDiv),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleMod),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleGreater),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleGreaterOrEquals),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleToString),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleToFloat),
  	BlueprintNodeStoreRecord("Float", ::BpDoubleToFloatRound),
  	BlueprintNodeStoreRecord("Integer", ::BpLongEquals),
  	BlueprintNodeStoreRecord("Integer", ::BpLongSum),
  	BlueprintNodeStoreRecord("Integer", ::BpLongSub),
  	BlueprintNodeStoreRecord("Integer", ::BpLongMul),
  	BlueprintNodeStoreRecord("Integer", ::BpLongDiv),
  	BlueprintNodeStoreRecord("Integer", ::BpLongMod),
  	BlueprintNodeStoreRecord("Integer", ::BpLongGreater),
  	BlueprintNodeStoreRecord("Integer", ::BpLongGreaterOrEquals),
  	BlueprintNodeStoreRecord("Integer", ::BpLongToString),
  	BlueprintNodeStoreRecord("Integer", ::BpLongToFloat),
  	BlueprintNodeStoreRecord("Object", ::BpObjectEquals),
  	BlueprintNodeStoreRecord("Object", ::BpObjectToString),
  	BlueprintNodeStoreRecord("Object", ::BpObjectToFloat),
  	BlueprintNodeStoreRecord("Object", ::BpObjectToLong),
  	BlueprintNodeStoreRecord("Object", ::BpObjectToArray),
  	BlueprintNodeStoreRecord("Object", ::BpObjectToStringArray),
  	BlueprintNodeStoreRecord("Object", ::BpObjectToLongArray),
  	BlueprintNodeStoreRecord("Object", ::BpObjectToFloatArray),
  	BlueprintNodeStoreRecord("Special", ::BpIsNull),
  	BlueprintNodeStoreRecord("Special", ::BpReplaceNull),
  	BlueprintNodeStoreRecord("String", ::BpStringEquals),
  	BlueprintNodeStoreRecord("String", ::BpStringConcat),
  	BlueprintNodeStoreRecord("String", ::BpStringGreater),
  	BlueprintNodeStoreRecord("String", ::BpStringGreaterOrEquals),
  	BlueprintNodeStoreRecord("String", ::BpStringToFloat),
  	BlueprintNodeStoreRecord("String", ::BpStringToLong),
  	BlueprintNodeStoreRecord("String", ::BpStringToBoolean)
  )
}
