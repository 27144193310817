package org.botdesigner.shared.data

import kotlinx.browser.window
import org.botdesigner.api.auth.DeviceType
import org.w3c.dom.Navigator

actual object DeviceConfig {
    actual val name: String
    actual val os: String
    actual val type: DeviceType

    init {
        val platformDetails = window.navigator.userAgentData
        os = platformDetails?.platform.orEmpty()
        name = platformDetails?.brands?.firstOrNull()?.let {
            "${it.brand} ${it.version}".trim()
        } ?: "Browser"

        type = if (platformDetails?.mobile == true)
            DeviceType.WebMobile
        else DeviceType.Web
    }
}

internal external interface Brand {
    val brand : String
    val version : String
}

internal external interface NavigatorUAData {

    val brands : Array<Brand>

    val mobile : Boolean

    val platform : String
}

@Suppress("unchecked_cast_to_external_interface")
internal val Navigator.userAgentData : NavigatorUAData?
    get() = (asDynamic().userAgentData as? Any).let {
        if (it == undefined) null else it as NavigatorUAData
    }
