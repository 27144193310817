package org.botdesigner.blueprint.ui

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection

object PinShape : Shape {
    override fun createOutline(
        size: Size,
        layoutDirection: LayoutDirection,
        density: Density
    ): Outline {

        val triangleWidth = size.width / 5
        val triangleHeight = size.height / 2

        return Outline.Generic(Path().apply {
            addOval(
                Rect(
                    center = Offset(
                        x = (size.width - triangleWidth) / 2f,
                        y = size.height / 2
                    ),
                    radius = (size.width - triangleWidth)/2.5f
                )
            )
            moveTo(
                x = size.width - triangleWidth,
                y = (size.height - triangleHeight)/2
            )
            lineTo(x = size.width, y = size.height / 2)
            lineTo(
                x = size.width - triangleWidth,
                y = (size.height + triangleHeight)/2
            )
            lineTo(
                x = size.width - triangleWidth,
                y = (size.height - triangleHeight)/2
            )

        })
    }
}

