package org.botdesigner.shared.`data`.cache

import app.cash.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import org.botdesigner.api.auth.DeviceType

public data class SqSession(
  public val id: String,
  public val ownerId: String,
  public val ip: String,
  public val country: String,
  public val city: String,
  public val device: String,
  public val deviceType: DeviceType,
  public val lastActive: Long,
  public val isCurrent: Boolean,
) {
  public class Adapter(
    public val deviceTypeAdapter: ColumnAdapter<DeviceType, String>,
  )
}
