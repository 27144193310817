package org.botdesigner.blueprint.stdlib.functions.special

import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.util.fastFilter
import kotlinx.coroutines.launch
import kotlinx.coroutines.supervisorScope
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.*
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.BpProcedureStateHolder
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.blueprint.ui.components.Function
import org.botdesigner.blueprint.ui.pins.PinsExtender

@kotlinx.serialization.Serializable()
@SerialName("BpChain")
class BpChain(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = emptyList(),
    override val connectors: List<Connector> = IOConnectors(id)
) : BpProcedure<@Contextual Any?>() {

    override val color: Color
        get() = BlueprintColors.Util

    override val summary: AnnotatedString
        get() = "Helps organize long branched chains"
            .toAnnotatedString()

    override val factory: ProcedureFactory<Any?>
        get() = ::BpChain


    override suspend fun execute(
        input: List<*>,
        context: Any?,
        pool: BlueprintNodesPool
    ): Pair<Connector?, List<*>> {
        outputConnectors.forEach {
            it.invokeNext(context, pool)
        }
        return null to emptyList<Any?>()
    }

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> =
        BpChainStateHolder(this, manager)
}

@kotlinx.serialization.Serializable()
@SerialName("BpChainAsync")
class BpChainAsync(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = emptyList(),
    override val connectors: List<Connector> = Connectors(id) {
        input()
        output()
    },
) : BpProcedure<@Contextual Any?>() {

    override val color: Color
        get() = BlueprintColors.Util

    override val summary: AnnotatedString
        get() = "Execute next branches asynchronously".toAnnotatedString()
    override val factory: ProcedureFactory<Any?>
        get() = ::BpChainAsync

    override suspend fun execute(
        input: List<*>,
        context: Any?,
        pool: BlueprintNodesPool
    ): Pair<Connector?, List<*>> {
        supervisorScope {
            outputConnectors.forEach {
                launch {
                    it.invokeNext(context, pool)
                }
            }
        }
        return null to emptyList<Any?>()
    }

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> =
        BpChainStateHolder(this, manager)
}

// Keep: used multiple times
private class BpChainStateHolder(
    element: BpProcedure<Any?>,
    manager: BlueprintManager
) : BpProcedureStateHolder<Any?>(
    element = element,
    manager = manager
) {
    private val caseConnectors get() =
        super.connectors.fastFilter(Connector::isOut)

    @Composable
    override fun Draw(
        modifier: Modifier,
        fieldSize: Float,
        shape: Shape
    ) {
        var w by remember {
            mutableStateOf(0)
        }
        Function(
            modifier = modifier.onSizeChanged {
                w = it.width
            },
            color = element.color,
            name = element.name,
            manager = manager,
            icon = element.icon,
            fieldSize = fieldSize,
            shape = shape
        ) { width ->
            PinsExtender(
                addEnabled = caseConnectors.size < 15,
                popEnabled = caseConnectors.size > 1,
                onAddPin = {
                    appendConnector {
                        Connector(
                            id = it,
                            order = connectors.size.toUInt(),
                            elId = element.id,
                            isOut = true
                        )
                    }
                },
                onPopPin = {
                    removeConnector(caseConnectors.last())
                }
            )
        }
    }

    override fun save(): BpChainAsync {
        return BpChainAsync(
            coordinate = position.value,
            pins = pins,
            connectors = connectors,
            id = id
        )
    }
}