package org.botdesigner.shared.di

import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.driver.worker.WebWorkerDriver
import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import kotlinx.browser.window
import org.botdesigner.shared.util.SecureSettings
import org.botdesigner.shared.util.intents.SendEmailIntent
import org.botdesigner.shared.util.intents.ViewUrlIntent
import org.botdesigner.shared.util.managers.AdManager
import org.botdesigner.shared.util.managers.JsNotificationManager
import org.botdesigner.shared.util.managers.NotificationManager
import org.botdesigner.shared.util.managers.SubscriptionManager
import org.koin.core.definition.KoinDefinition
import org.koin.core.module.Module
import org.koin.core.module.dsl.singleOf
import org.w3c.dom.Worker

internal actual fun Module.viewUrlIntent(): KoinDefinition<ViewUrlIntent> = single {
    ViewUrlIntent { url -> window.open(url) }
}

internal actual fun Module.sendEmailIntent(): KoinDefinition<SendEmailIntent> = single {
    SendEmailIntent { email, subject ->
        //TODO: url encode
        window.open("mailto:$email?subject=$subject")
    }
}


internal actual fun Module.subscriptionManager(): KoinDefinition<SubscriptionManager> = single {
    SubscriptionManager.Empty
}
internal actual fun Module.adManager(): KoinDefinition<AdManager> = single {
    AdManager.Empty
}

internal actual fun Module.notificationManager(): KoinDefinition<NotificationManager> =
    singleOf(::JsNotificationManager)

internal actual fun Module.settings(): KoinDefinition<Settings> = single {
    StorageSettings()
}
internal actual fun Module.secureSettings(): KoinDefinition<SecureSettings> = single {
    object : SecureSettings, Settings by get() {}
}
internal actual fun Module.sqlDriver(): KoinDefinition<SqlDriver> = single {

    WebWorkerDriver(
        Worker(
            js("""new URL("@cashapp/sqldelight-sqljs-worker/sqljs.worker.js", import.meta.url)""")
        )
    )
//    object :SqlDriver {
//        override fun addListener(vararg queryKeys: String, listener: Query.Listener) {
//
//        }
//
//        override fun close() {
//        }
//
//        override fun currentTransaction(): Transacter.Transaction?  = null
//
//        override fun execute(
//            identifier: Int?,
//            sql: String,
//            parameters: Int,
//            binders: (SqlPreparedStatement.() -> Unit)?
//        ): QueryResult<Long> {
//            return QueryResult.Value(0)
//        }
//
//        override fun <R> executeQuery(
//            identifier: Int?,
//            sql: String,
//            mapper: (SqlCursor) -> QueryResult<R>,
//            parameters: Int,
//            binders: (SqlPreparedStatement.() -> Unit)?
//        ): QueryResult<R> {
//            TODO("Not yet implemented")
//        }
//
//        override fun newTransaction(): QueryResult<Transacter.Transaction> {
//            TODO("Not yet implemented")
//        }
//
//        override fun notifyListeners(vararg queryKeys: String) {
//        }
//
//        override fun removeListener(vararg queryKeys: String, listener: Query.Listener) {
//        }
//    }
}
