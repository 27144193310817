package org.botdesigner.blueprint.integrations

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("CorruptedNode")
class CorruptedNode(
    override val coordinate: Point,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = emptyList(),
    override val connectors: List<Connector> = emptyList(),
) : BpProcedure<@Contextual Any?>() {

    override val factory: ProcedureFactory<in Any?>
        get() = ::CorruptedNode

    override val color: Color
        get() = Color.Magenta

    override val summary: AnnotatedString
        get() = "This node was added after blueprint restoration as a replacement for a corrupted node. Replace it with correct one".toAnnotatedString()

    override suspend fun execute(
        input: List<*>,
        context: Any?,
        pool: BlueprintNodesPool,
    ): Pair<Connector?, List<*>> {
        error(summary.text)
    }
}

val Blueprint.hasCorruptedNodes : Boolean
    get() = elements.any { it is CorruptedNode }