package org.botdesigner.ui.screens.bot

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.Delete
import androidx.compose.material.icons.outlined.Edit
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import dev.icerock.moko.resources.compose.stringResource
import io.github.alexzhirkevich.cupertino.CupertinoSwipeBoxItem
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.LocalContainerColor
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.section.SectionStyle
import io.github.alexzhirkevich.cupertino.section.items
import io.github.alexzhirkevich.cupertino.section.section
import io.github.alexzhirkevich.cupertino.section.sectionTitle
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.systemOrange
import kotlinx.coroutines.flow.Flow
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.resources.SharedRes
import org.botdesigner.ui.common.ContextMenuArea
import org.botdesigner.ui.common.ContextMenuItem
import org.botdesigner.ui.common.Settings
import org.botdesigner.ui.common.SwipeToDelete
import org.botdesigner.ui.common.rememberSwipeToDeleteState
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable


@OptIn(ExperimentalMaterial3Api::class,
    ExperimentalCupertinoApi::class, ExperimentalFoundationApi::class
)
@Composable
internal fun BlueprintList(
    modifier: Modifier,
    lazyListState: LazyListState,
    discardDelete : Flow<Any?>,
    blueprints: List<BlueprintData>?,
    onClick : (BlueprintData) -> Unit,
    onDelete : (BlueprintData) -> Unit,
    onEdit : (BlueprintData) -> Unit,
) {

    LazyColumn(
        modifier = modifier,
        state = lazyListState,
        contentPadding = PaddingValues(
            top = Dimens.Spacing.ExtraLarge
        )
    ) {


        section(
            title = {
                Text(string(SharedRes.strings.blueprints).sectionTitle())
            },
            style = SectionStyle.Sidebar
        ) {

            if (blueprints != null) {
                items(
                    items = blueprints,
                    key = { it.id },
                    dividerPadding = BlueprintItemIconSize + Dimens.Spacing.Medium * 2
                ) { blueprint, _ ->

                    val updatedDelete by rememberUpdatedState { onDelete(blueprint) }

                    val state = rememberSwipeToDeleteState {
                        updatedDelete()
                    }

                    val delete = stringResource(SharedRes.strings.delete)
                    val edit = stringResource(SharedRes.strings.edit)

                    val deleteColor = MaterialTheme.colorScheme.error

                    SwipeToDelete(
                        state = state,
                        discardFlow = discardDelete,
                        label = false,
                        items = {
                            if (blueprint.context.isNotEmpty()) {
                                CupertinoSwipeBoxItem(
                                    color = CupertinoColors.systemOrange,
                                    onClick = { onEdit(blueprint) },
                                ) {
                                    Icon(
                                        imageVector = AdaptiveIcons.Filled.Settings,
                                        contentDescription = null
                                    )
                                }
                            }
                        },
                        onDelete = { onDelete(blueprint) }
                    ) {

                        val delete = string(SharedRes.strings.delete)
                        val edit = string(SharedRes.strings.edit)

                        ContextMenuArea(
                            items = {
                                buildList {
                                    add(
                                        ContextMenuItem(
                                            icon = Icons.Outlined.Delete,
                                            title = delete,
                                            contentColor = deleteColor,
                                            clickAction = {
                                                onDelete(blueprint)
                                            }
                                        )
                                    )
                                    if (blueprint.context.isNotEmpty()) {
                                        add(
                                            ContextMenuItem(
                                                icon = Icons.Outlined.Edit,
                                                title = edit,
                                                clickAction = {
                                                    onEdit(blueprint)
                                                }
                                            )
                                        )
                                    }
                                }
                            }
                        ) {
                            BlueprintItem(
                                blueprint = blueprint,
                                modifier = Modifier
                                    .background(LocalContainerColor.current)
                                    .fillMaxWidth()
                                    .pointerHoverIcon(PointerIcon.PlatformClickable)
                                    .clickable { onClick(blueprint) }
                                    .padding(Dimens.Spacing.Small)
                            )
                        }
                    }
                }
            } else {
                items(5) { idx, pv ->
                    BlueprintItem(
                        blueprint = null,
                        modifier = Modifier
                            .background(LocalContainerColor.current)
                            .fillMaxWidth()
                            .padding(Dimens.Spacing.Small)
                    )
                }
            }
        }
    }
}