@file:Suppress( "FunctionName")
package org.botdesigner.blueprint.stdlib.functions.string

import org.botdesigner.blueprint.components.BlueprintExpressions
import org.botdesigner.blueprint.generator.BlueprintExpression
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.store.BlueprintNodeCategory

@BlueprintExpression(
    expression = BlueprintExpressions.Equals,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Equals",
    summary = "Check if 2 strings are equal",
    serialName = "BpStringE"
)
inline fun StringEquals(a : String?, b : String?) :  Boolean {
    return a == b
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Length",
    summary = "Number of characters",
    serialName = "BpStringLength"
)
inline fun StringLength( a : String) :  Long {
    return a.length.toLong()
}

@BlueprintExpression(
    expression = BlueprintExpressions.Sum,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Concat",
    summary = "Concatenate a bunch of strings to the single string",
    serialName = "BpStringConcat"
)
inline fun StringConcat(vararg values : String) :  String {
    return values.joinToString("")
}

@BlueprintExpression(
    expression = BlueprintExpressions.Greater,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Greater",
    summary = "Compare two strings alphabetically",
    serialName = "BpStringG"
)
inline fun StringGreater(a : String, b : String) :  Boolean {
    return a > b
}


@BlueprintExpression(
    expression = BlueprintExpressions.GreaterOrEquals,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Greater Or Equals",
    summary = "Compare two strings alphabetically",
    serialName = "BpStringGE"
)
inline fun StringGreaterOrEquals(a : String, b : String) :  Boolean {
    return a >= b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "To Float",
    summary = "Convert string to float number. If conversion was failed or input is null - returns null",
    serialName = "BpStringToFloat"
)
inline fun StringToFloat( a : String?) :  Double? {
    return a?.toDouble()
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "To Integer",
    summary = "Convert string to integer number. If conversion was failed or input is null - returns null",
    serialName = "BpStringToLong"
)
inline fun StringToLong( a : String?) :  Long? {
    return a?.toLong()
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "To Boolean",
    summary = "Convert string to boolean. ('true' = true, ignoring case, other string are considered false). Null string considered false",
    serialName = "BpStringToBoolean"
)
inline fun StringToBoolean( a : String?) :  Boolean {
    return a?.toBoolean() == true
}


@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Char At",
    summary = "String character at index",
    serialName = "BpStringCharAt"
)
inline fun StringCharAt(String : String, Index : Long) :  String {
    return String[Index.toInt()].toString()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Trim",
    summary = "Trim string - remove leading and trailing whitespaces",
    serialName = "BpStringTrim"
)
inline fun StringTrim( String : String) : String {
    return String.lowercase()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Uppercase",
    summary = "Convert string characters to upper case",
    serialName = "BpStringUppercase"
)
inline fun StringUppercase( String : String) :  String {
    return String.uppercase()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Lowercase",
    summary = "Convert string characters to the lower case",
    serialName = "BpStringLowercase"
)
inline fun StringLowercase( String : String) : String {
    return String.lowercase()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Reverse",
    summary = "Reverse string",
    serialName = "BpStringReverse"
)
inline fun StringReverse( String : String?) :  String? {
    return String?.reversed()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Split",
    summary = "Trim string - remove leading and trailing whitespaces",
    serialName = "BpStringSplit"
)
inline fun StringSplit(String : String, Delimiters : String) : List<String> {
    return String.split(*Delimiters.toCharArray())
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Substring",
    summary = "Get substring from <b>From Index</b> until <b>Until Index</b>. If <b>Until Index</b> is 0 or not specified then string length is used",
    serialName = "BpStringSubstring"
)
inline fun StringSubstring(
    String : String, 
    `From Index`: Long, 
    `Until Index`: Long?
) :  String {
    return if (`Until Index`.takeIf { it != 0L } != null)
        String.substring(`From Index`.toInt(), `Until Index`!!.toInt())
    else String.substring(`From Index`.toInt())
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Substring After",
    summary = "Returns a <b>String</b> part after the first occurrence of <b>After</b>. If <b>String</b> does not contain <b>After</b> then whole <b>String</b> is returned",
    serialName = "BpStringSubstringAfter"
)
inline fun StringSubstringAfter(String : String, After : String) : String {
    return String.substringAfter(After)
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Substring Before",
    summary = "Returns a <b>String</b> part before the first occurrence of <b>Before</b>. If <b>String</b> does not contain <b>Before</b> then whole <b>String</b> is returned",
    serialName = "BpStringSubstringBefore"
)
inline fun StringSubstringBefore(String : String, Before : String) : String {
    return String.substringBefore(Before)
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Substring After Last",
    summary = "Returns a <b>String</b> part after the first occurrence of <b>After</b>. If <b>String</b> does not contain <b>After</b> then whole <b>String</b> is returned",
    serialName = "BpStringSubstringAfterLast"
)
inline fun StringSubstringAfterLast(String : String, After : String) :  String {
    return String.substringAfterLast(After)
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Substring Before Last",
    summary = "Returns a <b>String</b> part before the first occurrence of <b>Before</b>. If <b>String</b> does not contain <b>Before</b> then whole <b>String</b> is returned",
    serialName = "BpStringSubstringBeforeLast"
)
inline fun StringSubstringBeforeLast(String : String, Before : String) :  String {
    return String.substringBeforeLast(Before)
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Starts With",
    summary = "Returns true if <b>String</b> start with <b>Prefix</b>",
    serialName = "BpStringStartsWith"
)
inline fun StringStartsWith(String : String, Prefix : String) : Boolean {
    return String.startsWith(Prefix)
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    displayName = "Ends With",
    summary = "Returns true if <b>String</b> ends with <b>Suffix</b>",
    serialName = "BpStringEndsWith"
)
inline fun StringEndsWith(String : String, Suffix : String) : Boolean {
    return String.endsWith(Suffix)
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    summary = "Join array to string",
    serialName = "BpJoinToString"
)
inline fun JoinToString(
    Array : Iterable<Any>,
    Separator : String?,
    Prefix : String?,
    Postfix : String?
) : String {
    return Array.joinToString(
        separator = Separator.orEmpty(),
        prefix = Prefix.orEmpty(),
        postfix = Postfix.orEmpty()
    )
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.String,
    summary = "Compare a <b>String</b> using the <b>Regex</b> pattern",
    serialName = "BpStringMatches"
)
inline fun Matches(
    String : String,
    Regex : String,
) : Boolean {
    return String.matches(Regex(Regex))
}