package org.botdesigner.blueprint.components

import androidx.compose.runtime.Immutable
import androidx.compose.ui.geometry.Offset
import kotlinx.serialization.SerialName

@kotlinx.serialization.Serializable
@SerialName("Point")
@Immutable
data class Point(val x : Float, val y : Float) {
    companion object {
        val Zero = Point(0f,0f)
    }
}

operator fun Point.plus(other : Point) = Point(this.x + other.x, this.y + other.y)
operator fun Point.minus(other : Point) = Point(this.x - other.x, this.y - other.y)

fun Point.toOffset() = Offset(x,y)

fun Offset.toPoint() = Point(x,y)

operator fun Point.div(value : Float) = Point(x/value, y/value)

operator fun Point.times(value : Float) = Point(x*value, y*value)