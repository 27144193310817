package org.botdesigner.shared.domain.content

import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.type
import com.arkivanov.decompose.Child
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.router.slot.SlotNavigation
import com.arkivanov.decompose.router.slot.activate
import com.arkivanov.decompose.router.slot.childSlot
import com.arkivanov.decompose.router.slot.dismiss
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.bringToFront
import com.arkivanov.decompose.router.stack.navigate
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.value.Value
import com.arkivanov.decompose.value.operator.map
import com.arkivanov.decompose.value.subscribe
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.update
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.core.Bot
import org.botdesigner.shared.data.repo.AuthRepository
import org.botdesigner.shared.data.repo.BlueprintsRepository
import org.botdesigner.shared.data.repo.BotsRepository
import org.botdesigner.shared.data.repo.CustomFunctionsRepository
import org.botdesigner.shared.data.repo.EditorSettings
import org.botdesigner.shared.data.repo.SessionRepository
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.RootComponent
import org.botdesigner.shared.domain.RootKeyEvent
import org.botdesigner.shared.domain.SnackbarMessage
import org.botdesigner.shared.domain.content.account.AccountComponent
import org.botdesigner.shared.domain.content.account.DefaultAccountComponent
import org.botdesigner.shared.domain.content.appearance.AppearanceComponent
import org.botdesigner.shared.domain.content.appearance.AppearanceState
import org.botdesigner.shared.domain.content.appearance.DefaultAppearanceComponent
import org.botdesigner.shared.domain.content.blueprint.BlueprintComponent
import org.botdesigner.shared.domain.content.blueprint.DefaultBlueprintComponent
import org.botdesigner.shared.domain.content.bot.BotComponent
import org.botdesigner.shared.domain.content.bot.DefaultBotComponent
import org.botdesigner.shared.domain.content.changepassword.ChangePasswordComponent
import org.botdesigner.shared.domain.content.changepassword.DefaultChangePasswordComponent
import org.botdesigner.shared.domain.content.create.CreateOrEditBotComponent
import org.botdesigner.shared.domain.content.create.DefaultCreateBotComponent
import org.botdesigner.shared.domain.content.guides.BlueprintGuidePage
import org.botdesigner.shared.domain.content.guides.DefaultGuideComponent
import org.botdesigner.shared.domain.content.guides.PinsGuidePage
import org.botdesigner.shared.domain.content.oauth.google.DefaultGoogleOAuthComponent
import org.botdesigner.shared.domain.content.oauth.google.GoogleOAuthComponent
import org.botdesigner.shared.domain.content.sessions.DefaultSessionsComponent
import org.botdesigner.shared.domain.content.sessions.SessionsComponent
import org.botdesigner.shared.domain.content.settings.DefaultEditorSettingsComponent
import org.botdesigner.shared.domain.content.settings.EditorSettingsComponent
import org.botdesigner.shared.domain.content.subscription.DefaultSubscriptionComponent
import org.botdesigner.shared.domain.content.subscription.SubscriptionComponent
import org.botdesigner.shared.domain.content.tabs.DefaultTabsComponent
import org.botdesigner.shared.domain.content.tabs.TabsComponent
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.domain.setupWebHistoryController
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.intents.SendEmailIntent
import org.botdesigner.shared.util.intents.ViewUrlIntent
import org.botdesigner.shared.util.managers.NotificationManager
import org.botdesigner.shared.util.managers.SubscriptionManager
import org.botdesigner.shared.util.managers.asPushNotificationContext


@OptIn(ExperimentalStdlibApi::class)
internal class DefaultContentComponent(
    context : ComponentContext,
    private val onLogout : () -> Unit,
    private val deepLinkFlow : Flow<RootComponent.Deeplink>,
    keyEventFlow : Flow<RootKeyEvent>,
    private val appearanceState : MutableStateFlow<AppearanceState>,
    private val settings : MutableStateFlow<EditorSettings>,
    private val notificationManager: NotificationManager,
    private val blueprintsRepository: BlueprintsRepository,
    private val botsRepository: BotsRepository,
    private val authRepository: AuthRepository,
    private val sessionsRepository: SessionRepository,
    private val customFunctionsRepository: CustomFunctionsRepository,
    private val subscriptionManager: SubscriptionManager,
    private val viewUrlIntent: ViewUrlIntent,
    private val sendEmailIntent: SendEmailIntent,
    private val dispatchersFactory: () -> Dispatchers,
    private val errorHandler: ErrorHandler,
    private val onMessage : (SnackbarMessage) -> Unit,
    private val rootCoroutineScope : CoroutineScope
) : CoroutineComponent(context, errorHandler), ContentComponent {

    override val appearance: AppearanceState
        get() = appearanceState.value

    private val navigation = StackNavigation<ChildConfig>()

    private val dialogNavigation = SlotNavigation<DialogConfig>()

    private val selectedBot = MutableStateFlow<Bot?>(null)

    private val topComponent : MutableStateFlow<ContentComponent.Child<*>?> =
        MutableStateFlow(null)


    override val stack: Value<MultipaneChildStack<*, ContentComponent.Child<*>>> = multipaneChildStack(
        isMultipane = { active -> !isMobile && active !is ChildConfig.Blueprint },
        source = navigation,
        serializer = ChildConfig.serializer(),
        initialConfiguration = ChildConfig.Tabs,
        handleBackButton = topComponent.value !is ContentComponent.Child.Blueprint,
        childFactory = ::childFactory,
        key = keyFor(::stack)
    )

    override val dialog: Value<ChildSlot<*, ContentComponent.Dialog<*>>> = childSlot(
        source = dialogNavigation,
        serializer = DialogConfig.serializer(),
        handleBackButton = true,
        childFactory = ::dialogFactory,
        key = keyFor(::dialog)
    )

    private val dispatchers = dispatchersFactory()

    private var isMobile = CurrentPlatform.isMobile

    private val unconsumedKeyEvents = keyEventFlow.filter {
        if (it.event.type == KeyEventType.KeyDown &&
            it.event.key == Key.Escape &&
            stack.value.pane != null &&
            stack.value.active != null
        ) {
            !onBack()
        } else {
            true
        }
    }

    init {
        setupWebHistoryController(
            navigator = navigation,
            stack = stack.map {
                ChildStack(
                    active = it.active ?: Child.Created(
                        ChildConfig.Empty,
                        ContentComponent.Child.Empty
                    ),
                    backStack = it.backStack
                ) as ChildStack<ChildConfig, *>
            },
            serializer = ChildConfig.serializer(),
            getPath = {
                when (it){
                    ChildConfig.Account -> "account"
                    ChildConfig.Appearance -> "appearance"
                    is ChildConfig.Blueprint -> "${it.blueprint.id}"
                    is ChildConfig.Bot -> "bot${it.bot.id}"
                    is ChildConfig.BotLogs -> TODO()
                    ChildConfig.Empty -> ""
                    ChildConfig.Sessions -> "devices"
                    ChildConfig.Editor -> "editor"
                    ChildConfig.Tabs -> "tabs"
                }
            },
            getConfiguration = {
                TODO()
            }
        )

        stack.subscribe(lifecycle) { st ->
            val bot = st.items.map { it.configuration }
                .filterIsInstance<ChildConfig.Bot>()
                .firstOrNull()

            topComponent.tryEmit(st.active?.instance)

            selectedBot.tryEmit(bot?.bot)

            // desktop blueprint screen is always in dark theme to maintain status bar color
            appearanceState.update {
                it.copy(
                    isOnDarkScreen = !CurrentPlatform.isMobile &&
                            st.active?.configuration is ChildConfig.Blueprint
                )
            }
        }

        dialog.subscribe(lifecycle) {

            // blueprint guide was displayed, don't show it again automatically
            if (it.child?.configuration is DialogConfig.BlueprintGuide) {
                settings.update {
                    it.copy(isBlueprintGuideSeen = true)
                }
            }
        }

        dispatchers.launchIO(componentScope) {
            unconsumedKeyEvents.collect()
        }
    }

    override fun onDialogDismissed() = dismissDialog()


    override fun onIdiomChanged(idiom: InterfaceIdiom) {
        this.isMobile = idiom == InterfaceIdiom.Mobile
        dispatchers.launchUI(componentScope) {
            navigation.navigate { it }
        }
    }

    override fun onPaneResize(sizeDp: Float) {
        appearanceState.update {
            it.copy(paneWidth = sizeDp)
        }
    }

    override fun onBack() : Boolean {

        val canNavigate = stack.value.backStack.size > 1 ||
                !isMobile && stack.value.active?.configuration != null

        dispatchers.launchUI(componentScope) {
            navigation.pop()
        }

        return canNavigate
    }

    private fun childFactory(config: ChildConfig, context: ComponentContext): ContentComponent.Child<*> =
        when (config) {
            ChildConfig.Tabs -> ContentComponent.Child.Tabs(
                component = tabsComponent(context)
            )

            is ChildConfig.Bot -> ContentComponent.Child.Bot(
                component = botComponent(context, config)
            )

            is ChildConfig.Blueprint -> ContentComponent.Child.Blueprint(
                component = blueprintComponent(context, config)
            )

            is ChildConfig.BotLogs -> TODO()

            ChildConfig.Sessions -> ContentComponent.Child.Sessions(
                component = sessionsComponent(context)
            )

            ChildConfig.Appearance -> ContentComponent.Child.Appearance(
                component = appearanceComponent(context)
            )

            ChildConfig.Account -> ContentComponent.Child.Account(
                component = accountComponent(context)
            )

            ChildConfig.Editor -> ContentComponent.Child.Settings(
                component = settingsComponent(context)
            )

            ChildConfig.Empty -> ContentComponent.Child.Empty
        }

    private fun dialogFactory(config: DialogConfig, context: ComponentContext): ContentComponent.Dialog<*> =
        when (config) {
            DialogConfig.Subscription -> ContentComponent.Dialog.Subscription(
                component = subscriptionComponent(context)
            )

            DialogConfig.CreateBot -> ContentComponent.Dialog.CreateOrEditBot(
                component = createBotComponent(context)
            )

            DialogConfig.GoogleOauth -> ContentComponent.Dialog.GoogleOauth(
                component = googleOAuthComponent(context)
            )

            is DialogConfig.EditBot -> ContentComponent.Dialog.CreateOrEditBot(
                component = createBotComponent(context, edit = config.bot)
            )

            DialogConfig.BlueprintGuide -> ContentComponent.Dialog.BlueprintGuide(
                component = DefaultGuideComponent(
                    pages = BlueprintGuidePage.entries,
                    onClose = ::onDialogDismissed
                )
            )

            DialogConfig.PinsGuide -> ContentComponent.Dialog.PinsGuide(
                component = DefaultGuideComponent(
                    pages = PinsGuidePage.entries,
                    onClose = ::onDialogDismissed
                )
            )

            is DialogConfig.ChangePassword -> ContentComponent.Dialog.ChangePassword(
                component = changePasswordComponent(context)
            )
        }


    private fun showDialog(config : DialogConfig) {
        dispatchers.launchUI(componentScope) {
            dialogNavigation.activate(config)
        }
    }

    private fun dismissDialog() {
        dispatchers.launchUI(componentScope) {
            dialogNavigation.dismiss()
        }
    }


    private fun navigate(config : ChildConfig, push : Boolean = false) {
        dispatchers.launchUI(componentScope) {
            if (push){
                navigation.push(config)
                return@launchUI
            }
            if (isMobile) {
                navigation.bringToFront(config)
            } else {
                navigation.navigate {
                    it.take(1) + config
                }
            }
        }
    }

    private inline fun <reified T : StackableComponent> removeNavigationComponent(){
        navigation.navigate {
            it.filterNot { it is T }
        }
    }

    private fun onBotDeleted(bot: Bot){
        removeNavigationComponent<BotComponent>()
    }

    private fun appearanceComponent(
        context: ComponentContext
    ) : AppearanceComponent = DefaultAppearanceComponent(
        state = appearanceState,
        onBack = ::onBack
    )

    private fun settingsComponent(
        context: ComponentContext
    ) : EditorSettingsComponent = DefaultEditorSettingsComponent(
        state = settings,
        onBack = ::onBack,
        context = context,
        errorHandler = errorHandler,
        onShowBlueprintGuide = {
            showDialog(DialogConfig.BlueprintGuide)
        },
        onShowPinGuide = {
            showDialog(DialogConfig.PinsGuide)
        }
    )

    private fun accountComponent(
        context: ComponentContext
    ) : AccountComponent = DefaultAccountComponent(
        context = context,
        userRepository = authRepository,
        onNavigateToEditPassword = {
            showDialog(DialogConfig.ChangePassword)
        },
        onLinkGoogleClicked = {
            showDialog(DialogConfig.GoogleOauth)
        },
        onBack = ::onBack,
        dispatchers = dispatchersFactory(),
        errorHandler = errorHandler,
        onMessage = onMessage
    )

    private fun tabsComponent(context: ComponentContext) : TabsComponent =
        DefaultTabsComponent(
            context = context,
            topComponent = topComponent,
            onNavigateToBot = {
                navigate(ChildConfig.Bot(it))
            },
            onNavigateToEditBot = {
                showDialog(DialogConfig.EditBot(it))
            },
            onNavigateToPremium = {
                showDialog(DialogConfig.Subscription)
            },
            onNavigateToCreateBot = {
                showDialog(DialogConfig.CreateBot)
            },
            onNavigateToDevices = {
                navigate(ChildConfig.Sessions)
            },
            onNavigateToAccount = {
                navigate(ChildConfig.Account)
            },
            onNavigateToAppearance = {
                navigate(ChildConfig.Appearance)
            },
            onNavigateToSettings = {
                navigate(ChildConfig.Editor)
            },
            onBotDeleted = ::onBotDeleted,
            onLogout = onLogout,
            errorHandler = errorHandler,
            dispatchers = dispatchersFactory(),
            botsRepository = botsRepository,
            userRepository = authRepository,
            sendEmailIntent = sendEmailIntent
        )

    private fun subscriptionComponent(context: ComponentContext) : SubscriptionComponent =
        DefaultSubscriptionComponent(
            context = context,
            dispatchers = dispatchersFactory(),
            errorHandler = errorHandler,
            userRepository = authRepository,
            subscriptionManager = subscriptionManager,
            onBack = ::dismissDialog
        )

    private fun botComponent(context: ComponentContext, config: ChildConfig.Bot): BotComponent =
        DefaultBotComponent(
            context = context,
            initialBot = config.bot,
            onNavigateToBlueprint = {
                navigate(ChildConfig.Blueprint(config.bot, it), push = true)
            },
            onNavigateToEdit = {
                showDialog(DialogConfig.EditBot(it))
            },
            onNavigateToLogs = {
                navigate(ChildConfig.BotLogs(it))
            },
            onBack = ::onBack,
            blueprintsRepository = blueprintsRepository,
            botsRepository = botsRepository,
            dispatchers = dispatchersFactory(),
            errorHandler = errorHandler,
            onMessage = onMessage,
            viewUrlIntent = viewUrlIntent,
            onBotDeleted = ::onBotDeleted
        )

    private fun blueprintComponent(context: ComponentContext, config: ChildConfig.Blueprint): BlueprintComponent =
        DefaultBlueprintComponent(
            context = context,
            bot = config.bot,
            keyEventFlow = unconsumedKeyEvents,
            initialBlueprintData = config.blueprint,
            onFinished = navigation::pop,
            onShowBlueprintGuide = {
                showDialog(DialogConfig.BlueprintGuide)
            },
            onShowSubscriptionDialog = {
               showDialog(DialogConfig.Subscription)
            },
            onConsolePositionChanged = { pos ->
                settings.update {
                    it.copy(consolePosition = pos)
                }
            },
            onMessage = onMessage,
            settings = settings,
            rootCoroutineScope = rootCoroutineScope,
            blueprintsRepository = blueprintsRepository,
            customFunctionsRepository = customFunctionsRepository,
            userRepository = authRepository,
            errorHandler = errorHandler,
            dispatchers = dispatchersFactory(),
            notificationContext = notificationManager.asPushNotificationContext()
        )

   private fun createBotComponent(context: ComponentContext, edit : Bot? = null): CreateOrEditBotComponent =
       DefaultCreateBotComponent(
           context = context,
           onNavigateToBot = {
               navigate(ChildConfig.Bot(it))
               dismissDialog()
           },
           edit = edit,
           onBack = ::dismissDialog,
           botsRepository = botsRepository,
           errorHandler = errorHandler,
           viewUrlIntent = viewUrlIntent
       )

    private fun googleOAuthComponent(context: ComponentContext): GoogleOAuthComponent =
        DefaultGoogleOAuthComponent(
            context = context,
            onBack = ::dismissDialog,
            errorHandler = errorHandler,
            dispatchers = dispatchersFactory(),
            authRepository = authRepository,
            onMessage = onMessage
        )

    private fun changePasswordComponent(context: ComponentContext): ChangePasswordComponent =
        DefaultChangePasswordComponent(
            context = context,
            onBack = ::dismissDialog,
            onMessage = onMessage,
            dispatchers = dispatchersFactory(),
            errorHandler = errorHandler,
            userRepository = authRepository
        )

    private fun sessionsComponent(context: ComponentContext) : SessionsComponent =
        DefaultSessionsComponent(
            context = context,
            onBack = ::onBack,
            sessionRepository = sessionsRepository,
            errorHandler = errorHandler,
            dispatchers = dispatchersFactory(),
            viewUrlIntent = viewUrlIntent
        )


    @Serializable
    private sealed interface ChildConfig {

        @Serializable
        object Empty : ChildConfig

        @Serializable
        object Sessions : ChildConfig

        @Serializable
        object Appearance : ChildConfig

        @Serializable
        object Editor : ChildConfig

        @Serializable
        object Account : ChildConfig

        @Serializable
        class BotLogs(val bot: org.botdesigner.core.Bot) : ChildConfig

        @Serializable
        object Tabs : ChildConfig

        @Serializable
        data class Bot(val bot: org.botdesigner.core.Bot) : ChildConfig

        @Serializable
        data class Blueprint(
            val bot : org.botdesigner.core.Bot,
            val blueprint: BlueprintData
        ) : ChildConfig
    }

    @Serializable
    private sealed interface DialogConfig {
        @Serializable
        object Subscription : DialogConfig

        @Serializable
        class EditBot(val bot: Bot) : DialogConfig

        @Serializable
        object CreateBot: DialogConfig

        @Serializable
        object GoogleOauth : DialogConfig

        @Serializable
        object ChangePassword : DialogConfig

        @Serializable
        object BlueprintGuide : DialogConfig

        @Serializable
        object PinsGuide : DialogConfig
    }
}

