package org.botdesigner.ui.common

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.selection.selectable
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.unit.dp
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveNavigationBarItem
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.ui.theme.PlatformClickable

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal fun RowScope.AdaptiveNavigationMenuItem(
    icon : @Composable () -> Unit,
    selected : Boolean,
    onClick : () -> Unit,
    modifier: Modifier = Modifier,
    label : (@Composable () -> Unit)? = null,
) {
    if (LocalInterfaceIdiom.current == InterfaceIdiom.Mobile) {
        AdaptiveNavigationBarItem(
            selected = selected,
            onClick = onClick,
            icon = icon,
            modifier = modifier,
            label = label,
        )
    } else {
        Box(
            modifier = Modifier
                .pointerHoverIcon(PointerIcon.PlatformClickable)
                .selectable(
                    selected = selected,
                    onClick = onClick,
                    role = Role.Tab,
                    interactionSource = remember { MutableInteractionSource() },
                    indication = null,
                )
                .weight(1f)
                .fillMaxHeight()
                .padding(bottom = 4.dp),
            contentAlignment = Alignment.Center
        ) {
            CompositionLocalProvider(
                LocalContentColor provides if (selected)
                    MaterialTheme.colorScheme.primary
                else MaterialTheme.colorScheme.secondary
            ) {
                icon()
            }
        }
    }
}