package org.botdesigner.botblueprints

import kotlinx.datetime.Clock
import org.botdesigner.api.ApiBlueprintData
import org.botdesigner.api.ApiBlueprintType
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.core.BotType

@kotlinx.serialization.Serializable
data class BlueprintData(
    val id : String,
    val initialId : String = id,
    val type : BlueprintType = BlueprintType.MessageReceived,
    val context : String = "",
    val blueprint : String,
    val variables : List<BlueprintVariableData>? = null,
    val createdAt : Long = Clock.System.now().epochSeconds,
    val editedAt : Long = Clock.System.now().epochSeconds,
    val isDeleted : Boolean = false
)

interface BlueprintFactory {
    fun blueprint(id : String, context: String, type: BotType) : Blueprint
}


fun ApiBlueprintData.toCore() = BlueprintData(
    id = id,
    type = type.toCore(),
    context = context,
    blueprint = blueprint,
    createdAt = createdAt,
    editedAt = editedAt,
)

fun ApiBlueprintType.toCore() = BlueprintType.valueOf(name)
fun BlueprintType.toApi() = ApiBlueprintType.valueOf(name)

