
package org.botdesigner.ui.screens.blueprint

import androidx.compose.animation.animateColorAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.Orientation
import androidx.compose.foundation.interaction.collectIsPressedAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.statusBarsPadding
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.CornerBasedShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Undo
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Done
import androidx.compose.material.icons.filled.Terminal
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.IconButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.addOutline
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import com.materialkolor.ktx.darken
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.filter
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.blueprint.ui.BlueprintWidget
import org.botdesigner.blueprint.ui.windowSize
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.ConsolePosition
import org.botdesigner.shared.domain.content.blueprint.BlueprintComponent
import org.botdesigner.shared.domain.content.blueprint.BlueprintError
import org.botdesigner.ui.common.HorizontalMobileSplitPaneSeparator
import org.botdesigner.ui.common.NavigateBackIcon
import org.botdesigner.ui.common.PlatformDialogContainer
import org.botdesigner.ui.common.SplitPane
import org.botdesigner.ui.common.SplitPaneSeparator
import org.botdesigner.ui.common.VerticalMobileSplitPaneSeparator
import org.botdesigner.ui.common.containerBackground
import org.botdesigner.ui.common.dialogs.ConfirmationDialog
import org.botdesigner.ui.common.dialogs.LoadingDialog
import org.botdesigner.ui.common.rememberPlatformDialogState
import org.botdesigner.ui.common.rememberSplitPaneState
import org.botdesigner.ui.common.subscribeWithState
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal fun BlueprintScreen(
    component: BlueprintComponent,
    modifier: Modifier = Modifier
) {

    val dialogState = rememberPlatformDialogState()

    val dialog = component.componentDialog
        .subscribeWithState(dialogState)

    val state by component.state.collectAsState()

    component.alertDialog.subscribeAsState().let {
        when (val i = it.value.child?.instance ?: return@let) {
            is BlueprintComponent.AlertDialog.BlueprintUnavailable -> {
                ConfirmationDialog(i.component)
            }
        }
    }

    if (state.isLoading) {
        LoadingDialog()
    }

    val windowSize = windowSize()
    val density = LocalDensity.current

    PlatformDialogContainer(
        modifier = modifier,
        state = dialogState,
        unboundHeight = dialog.value.child?.instance is BlueprintComponent.ComponentDialog.Store,
        onDismissRequest = component::onContentDialogDismiss,
        dialog = {
            when (val c = dialog.value.child?.instance) {
                is BlueprintComponent.ComponentDialog.Store -> {
                    BlueprintStoreScreen(c.component)
                }
                null -> {}
            }
        }
    ) {

        val settings by component.settings.collectAsState()

        val verticalPaneRange by rememberUpdatedState(remember(density, windowSize) {
            density.run {
                150.dp..windowSize.height.toDp() * .75f
            }
        })

        val horizontalPaneRange by rememberUpdatedState(remember(density, windowSize) {
            density.run {
                150.dp..windowSize.width.toDp() * .75f
            }
        })

        val paneState = rememberSplitPaneState(
            orientation = settings.consolePosition.toOrientation(),
            initialPaneSize = 250.dp,
            paneSizeRange = {
                if (it == Orientation.Vertical) {
                    verticalPaneRange
                } else {
                    horizontalPaneRange
                }
            },
            isStartPane = false
        )

        LaunchedEffect(settings.consolePosition) {
            paneState.orientation = settings.consolePosition.toOrientation()
        }


        Scaffold(
            modifier = Modifier
                .fillMaxSize(),
            topBar = {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier
                        .fillMaxWidth()
                        .statusBarsPadding()
                        .padding(Dimens.Spacing.Small)
                        .zIndex(1f)
                ) {
                    IconButton(
                        onClick = component::onBack,
                        modifier = Modifier
                            .padding(Dimens.Spacing.ExtraSmall),
                        colors = IconButtonDefaults.iconButtonColors(
                            contentColor = Color.White
                        )
                    ) {
                        Icon(
                            imageVector = NavigateBackIcon,
                            contentDescription = string(SharedRes.strings.back),
                        )
                    }
                    Row {

                        IconButton(
                            enabled = state.controlsEnabled && state.canUndo,
                            onClick = component::onUndoClicked,
                            modifier = Modifier
                                .padding(Dimens.Spacing.ExtraSmall),
                            colors = IconButtonDefaults.iconButtonColors(
                                contentColor = Color.White
                            )
                        ) {
                            Icon(
                                imageVector = Icons.AutoMirrored.Filled.Undo,
                                contentDescription = string(SharedRes.strings.undo),
                            )
                        }

                        IconButton(
                            enabled = state.controlsEnabled,
                            onClick = component::onLogsClicked,
                            modifier = Modifier
                                .padding(Dimens.Spacing.ExtraSmall)
                                .errorIndication(
                                    errors = component.blueprintErrors,
                                    shape = CircleShape,
                                ),
                            colors = IconButtonDefaults.iconButtonColors(
                                contentColor = Color.White
                            )
                        ) {
                            Icon(
                                imageVector = Icons.Default.Terminal,
                                contentDescription = string(SharedRes.strings.logs),
                            )
                        }

                        IconButton(
                            enabled = state.controlsEnabled,
                            onClick = component::onSaveClicked,
                            modifier = Modifier
                                .padding(Dimens.Spacing.ExtraSmall),
                            colors = IconButtonDefaults.iconButtonColors(
                                contentColor = Color.White
                            )
                        ) {
                            Icon(
                                imageVector = Icons.Default.Done,
                                contentDescription = string(SharedRes.strings.back),
                            )
                        }
                    }
                }
            },
            floatingActionButton = {
                FloatingActionButton(
                    onClick = component::onAddNodeClicked
                ) {
                    Icon(
                        imageVector = Icons.Default.Add,
                        contentDescription = string(SharedRes.strings.add_new)
                    )
                }
            }
        ) {

            val pane by component.console.subscribeAsState()

            val paneChild = pane.child

            if (paneChild != null) {
                SplitPane(
                    state = paneState,
                    content1 = {
                        Blueprint(
                            component = component,
                        )
                    },
                    content2 = {
                        BlueprintLogcat(
                            component = paneChild.instance,
                            modifier = Modifier
                                .fillMaxSize()
                                .background(containerBackground()),
                            isPane = true,
                            orientation = settings.consolePosition,
                        )
                    },
                    separator = {
                        SplitPaneSeparator(paneState) {
                            if (CurrentPlatform.isMobile) {
                                val isPressed by paneState.separatorInteractionSource
                                    .collectIsPressedAsState()

                                val color by animateColorAsState(
                                    MaterialTheme.colorScheme.secondary.darken(
                                        if (paneState.isDragInProgress || isPressed) 4f else 2f
                                    )
                                )


                                if (paneState.orientation == Orientation.Vertical) {
                                    HorizontalMobileSplitPaneSeparator(
                                        modifier = it, color = color
                                    )
                                } else {
                                    VerticalMobileSplitPaneSeparator(
                                        modifier = it, color = color
                                    )
                                }
                            }


                            if (paneState.orientation == Orientation.Vertical) {
                                HorizontalDivider(it)
                            } else {
                                VerticalDivider(it)
                            }
                        }
                    }
                )
            } else {
                Blueprint(
                    component = component,
                )
            }
        }
    }
}


private fun ConsolePosition.toOrientation() =
    if (this == ConsolePosition.Bottom) Orientation.Vertical else Orientation.Horizontal

private fun Modifier.errorIndication(
    errors : Flow<BlueprintError>,
    shape: CornerBasedShape,
    id : String? = null
) = composed {
    var error by remember {
        mutableStateOf(false)
    }

    val animatedForeground by animateColorAsState(
        if (error)
            MaterialTheme.colorScheme.error.copy(alpha = .5f)
        else Color.Transparent,
    ) {
        if (it != Color.Transparent) {
            error = false
        }
    }

    LaunchedEffect(0) {
        errors
            .filter { id == null || it.nodeId == id }
            .collectLatest {
                error = true
                delay(500)
                error = false
            }
    }


    drawWithCache {
        val path = Path().apply {
            addOutline(
                shape.createOutline(
                    size = size,
                    topStart = shape.topStart.toPx(size, this@drawWithCache),
                    topEnd = shape.topEnd.toPx(size, this@drawWithCache),
                    bottomEnd = shape.bottomStart.toPx(size, this@drawWithCache),
                    bottomStart = shape.bottomEnd.toPx(size, this@drawWithCache),
                    layoutDirection = layoutDirection
                )
            )
        }
        onDrawWithContent {

            drawContent()
            drawPath(
                path = path,
                color = animatedForeground
            )
        }
    }
}

@Composable
private fun Blueprint(
    component: BlueprintComponent,
    modifier: Modifier = Modifier
) {

    val shape = MaterialTheme.shapes.medium

    BlueprintWidget(
        manager = component.blueprintManager,
        modifier = modifier,
        nodeModifier = { holder ->
            Modifier.errorIndication(
                errors = component.blueprintErrors,
                shape = shape,
                id = holder.id.id
            )
        },
        gridVisible = component.settings.collectAsState().value.isGridEnabled,
    )
}