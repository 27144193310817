package org.botdesigner.shared.domain.content.subscription

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import io.github.alexzhirkevich.onetap.NativeViewController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.stateIn
import org.botdesigner.api.SubscriptionType
import org.botdesigner.api.subscriptionType
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.catchWith
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.managers.Subscription
import org.botdesigner.shared.util.managers.SubscriptionManager
internal class DefaultSubscriptionComponent(
    context : ComponentContext,
    private val onBack : () -> Unit,
    private val userRepository: UserRepository,
    private val subscriptionManager: SubscriptionManager,
    private val dispatchers: Dispatchers,
    initialSubType : SubscriptionType? = null,
    errorHandler: ErrorHandler,
) : CoroutineComponent(context, errorHandler),
    SubscriptionComponent {

    private val isHelpVisible: MutableStateFlow<Boolean> = instanceKeeper
        .getOrCreateSimple(keyFor(::isHelpVisible)) {
            MutableStateFlow(false)
        }

    private val isLoading: MutableStateFlow<Boolean> = instanceKeeper
        .getOrCreateSimple(keyFor(::isLoading)) {
            MutableStateFlow(false)
        }

    private val selectedOffering: MutableStateFlow<Subscription?> = instanceKeeper
        .getOrCreateSimple(keyFor(::selectedOffering)) {
            MutableStateFlow(null)
        }

    override val state: StateFlow<SubscriptionViewState> = combine(
        userRepository.currentUserFlow,
        selectedOffering,
        isHelpVisible,
        isLoading,
        subscriptionManager.offerings().catchWith(errorHandler)
    ) { user, offering, isHelpVisible, loading, offerings ->

        val selectedOffering = offering.takeIf { it in offerings }
            ?: offerings.firstOrNull { it.type == offering?.type }
            ?: offerings.firstOrNull { it.type == initialSubType }
            ?: offerings.firstOrNull { it.type == user?.subscriptionType }
            ?: offerings.firstOrNull()

        SubscriptionViewState(
            availableOfferings = offerings,
            isLoading = loading,
            isHelpVisible = isHelpVisible,
            currentSubscriptionType = user?.subscriptionType,
            selectedOffering = selectedOffering
        )
    }.stateIn(
        scope = componentScope,
        started = SharingStarted.Eagerly,
        initialValue = SubscriptionViewState()
    )

    override fun onOfferingSelected(offering: Subscription) {
        selectedOffering.value = offering
    }

    override fun onSubscribeClicked(nativeViewController: NativeViewController) {
        if (state.value.isAlreadyPurchased){
            // TODO: Manage subscription
        } else {
            dispatchers.launchIO(componentScope) {
                isLoading.value = true
                selectedOffering.value?.payload?.let {
                    subscriptionManager.purchase(nativeViewController, it)
                }
                userRepository.refreshUser()
            }.invokeOnCompletion {
                isLoading.value = false
            }
        }
    }

    override fun onHelpClicked() {
        isHelpVisible.value = !isHelpVisible.value
    }


    override fun onCloseClicked() {
        if (isHelpVisible.value) {
            onHelpClicked()
        } else {
            onBack.invoke()
        }
    }
}