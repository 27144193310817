package org.botdesigner.shared.`data`.cache

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlin.Unit
import org.botdesigner.shared.`data`.cache.`data`.newInstance
import org.botdesigner.shared.`data`.cache.`data`.schema

public interface CacheDatabase : SuspendingTransacter {
  public val sqBlueprintQueries: SqBlueprintQueries

  public val sqBotQueries: SqBotQueries

  public val sqSessionQueries: SqSessionQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = CacheDatabase::class.schema

    public operator fun invoke(
      driver: SqlDriver,
      SqBlueprintAdapter: SqBlueprint.Adapter,
      SqBotAdapter: SqBot.Adapter,
      SqSessionAdapter: SqSession.Adapter,
    ): CacheDatabase = CacheDatabase::class.newInstance(driver, SqBlueprintAdapter, SqBotAdapter,
        SqSessionAdapter)
  }
}
