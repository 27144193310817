package org.botdesigner.ui.common

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.rounded.ContactSupport
import androidx.compose.material.icons.automirrored.rounded.MenuBook
import androidx.compose.material.icons.filled.GppMaybe
import androidx.compose.material.icons.rounded.Add
import androidx.compose.material.icons.rounded.DarkMode
import androidx.compose.material.icons.rounded.Person
import androidx.compose.material.icons.rounded.Settings
import androidx.compose.material.icons.rounded.Star
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.vector.ImageVector
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.icons.CupertinoIcons
import io.github.alexzhirkevich.cupertino.icons.filled.Book
import io.github.alexzhirkevich.cupertino.icons.filled.CircleLefthalfed
import io.github.alexzhirkevich.cupertino.icons.filled.Gearshape
import io.github.alexzhirkevich.cupertino.icons.filled.Person
import io.github.alexzhirkevich.cupertino.icons.filled.QuestionmarkCircle
import io.github.alexzhirkevich.cupertino.icons.filled.Star
import io.github.alexzhirkevich.cupertino.icons.outlined.CheckmarkShield
import io.github.alexzhirkevich.cupertino.icons.outlined.PlusCircle

//val AdaptiveIcons.Outlined.Star : ImageVector
//    @Composable
//    get() = AdaptiveIcons.vector(
//        material = Icons.Rounded.Star,
//        cupertino = CupertinoIcons.Outlined.Star
//    )
internal val AdaptiveIcons.Filled.Star : ImageVector
    @Composable
    get() = AdaptiveIcons.vector(
        material = { Icons.Rounded.Star },
        cupertino = { CupertinoIcons.Filled.Star }
    )

internal val AdaptiveIcons.Filled.Person : ImageVector
    @Composable
    get() = AdaptiveIcons.vector(
        material = { Icons.Rounded.Person },
        cupertino = { CupertinoIcons.Filled.Person }
    )

internal val AdaptiveIcons.Filled.DayNight : ImageVector
    @Composable
    get() = AdaptiveIcons.vector(
        material = { Icons.Rounded.DarkMode },
        cupertino = { CupertinoIcons.Filled.CircleLefthalfed }
    )


internal val AdaptiveIcons.Filled.Shield : ImageVector
    @Composable
    get() = AdaptiveIcons.vector(
        material = { Icons.Default.GppMaybe },
        cupertino = { CupertinoIcons.Outlined.CheckmarkShield }
    )

internal val AdaptiveIcons.Filled.Settings : ImageVector
    @Composable
    get() = AdaptiveIcons.vector(
        material = { Icons.Rounded.Settings },
        cupertino = { CupertinoIcons.Filled.Gearshape }
    )


internal val AdaptiveIcons.Filled.Support
    @Composable
    get() = AdaptiveIcons.vector(
        material = { Icons.AutoMirrored.Rounded.ContactSupport},
        cupertino = { CupertinoIcons.Filled.QuestionmarkCircle}
    )

internal val AdaptiveIcons.Filled.Book
    @Composable
    get() = AdaptiveIcons.vector(
        material = { Icons.AutoMirrored.Rounded.MenuBook },
        cupertino = { CupertinoIcons.Filled.Book }
    )

internal val AdaptiveIcons.Outlined.AddNew
    @Composable
    get() = AdaptiveIcons.vector(
        material = { Icons.Rounded.Add },
        cupertino = { CupertinoIcons.Outlined.PlusCircle }
    )


