@file:Suppress("NOTHING_TO_INLINE","FUNCTIONNAME","LOCALVARIABLENAME")

package org.botdesigner.botblueprints.telegram.functions

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.BlueprintProcedure
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.generator.Tuple
import org.botdesigner.blueprint.generator.Tuple3
import org.botdesigner.blueprint.generator.Tuple5
import org.botdesigner.botblueprints.BpCategories
import org.botdesigner.botblueprints.BpSubcategories
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext
import org.botdesigner.telegram.Chat
import org.botdesigner.telegram.ChatMemberAdministrator
import org.botdesigner.telegram.ChatMemberBanned
import org.botdesigner.telegram.ChatMemberLeft
import org.botdesigner.telegram.ChatMemberMember
import org.botdesigner.telegram.ChatMemberOwner
import org.botdesigner.telegram.ChatMemberRestricted
import org.botdesigner.telegram.User
import org.botdesigner.telegram.approveChatJoinRequest
import org.botdesigner.telegram.banChatMember
import org.botdesigner.telegram.createChatInviteLink
import org.botdesigner.telegram.declineChatJoinRequest
import org.botdesigner.telegram.deleteChatStickerSet
import org.botdesigner.telegram.getChat
import org.botdesigner.telegram.getChatMember
import org.botdesigner.telegram.getChatMemberCount
import org.botdesigner.telegram.leaveChat
import org.botdesigner.telegram.promoteChatMember
import org.botdesigner.telegram.sendChatAction
import org.botdesigner.telegram.setChatDescription
import org.botdesigner.telegram.setChatStickerSet
import org.botdesigner.telegram.setChatTitle
import org.botdesigner.telegram.unbanChatMember
import kotlin.time.Duration.Companion.minutes

private const val DSCR_DECOMPOSE_CHAT = "Get <b>Chat</b> properties"
private const val DSCR_BOT_MUST_BE_ADMIN = "Bot must be admin of the channel"
private const val DSCR_JOIN_CHAT_RESPOND = "Approve or decline chat join request"
private const val DSCR_BAN_MEMBER = "Ban user from the chat. User can be banned for N <b>Minutes</b> or <b>Until Date</b>. Ban for more than year or for 0 minutes considered permanent."
private const val DSCR_KICK_MEMBER = "Kick user from the chat"
private const val DSCR_SEND_CHAT_ACTION ="Display progress of the bots action"

internal enum class TgChatAction(val actionName : String) {
    Typing("typing"),
    UploadPhoto("upload_photo"),
    RecordVideo("record_video"),
    UploadVideo("upload_video"),
    RecordVoice("record_voice"),
    UploadVoice("upload_voice"),
    UploadDocument("upload_document"),
    ChooseSticker("choose_sticker"),
    FindLocation("find_location"),
    RecordVideoVoice("record_video_note"),
    UploadVideoVoice("upload_video_note")
}


@BlueprintFunction(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    summary = DSCR_DECOMPOSE_CHAT,
    displayName = "Decompose Chat"
)
internal inline fun TgDecomposeChat(
    Chat : Chat
) : Tuple3<
    @Pin("Id") Long,
    @Pin("Name") String,
    @Pin("Image Id") String?
> = Tuple(
    Chat.id,
    Chat.title ?: "${Chat.first_name.orEmpty()}${Chat.last_name?.let { " $it" }.orEmpty()}",
    Chat.photo?.big_file_id
)


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Get Chat Member"
)
internal suspend inline fun TelegramBotBlueprintContext.TgGetChatMember(
    `User Id`: Long,
    `Chat Id`: Long,
) : Tuple5<
    @Pin("User") User?,
    @Pin("Is Owner") Boolean,
    @Pin("Is Admin") Boolean,
    @Pin("Is Left") Boolean,
    @Pin("Is Banned") Boolean,
> {

    val member = client.getChatMember(
        user_id = `User Id`,
        chat_id = `Chat Id`.toString(),
    ).result

    var admin = false
    var owner = false
    var left = false
    var banned = false

    val user = when(member){
        is ChatMemberAdministrator -> member.user.also {
            admin = true
        }
        is ChatMemberBanned -> member.user.also {
            left = true
            banned = true
        }
        is ChatMemberLeft -> member.user.also {
            left = true
        }
        is ChatMemberMember -> member.user
        is ChatMemberOwner -> member.user.also {
            owner = true
            admin = true
        }
        is ChatMemberRestricted -> member.user
        else -> null
    }

    return Tuple(user, owner, admin, left, banned)
}

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Kick Chat Member",
    summary = "$DSCR_BOT_MUST_BE_ADMIN\n$DSCR_KICK_MEMBER"
)
internal suspend inline fun TelegramBotBlueprintContext.TgKickChatMember(
    `User Id`: Long,
    `Chat Id`: Long,
) : Boolean {

    return client.unbanChatMember(
        user_id = `User Id`,
        chat_id = `Chat Id`.toString(),
    ).result
}

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Ban Chat Member",
    summary = "$DSCR_BOT_MUST_BE_ADMIN\n$DSCR_BAN_MEMBER"
)
internal suspend inline fun TelegramBotBlueprintContext.TgBanChatMember(
    `User Id`: Long,
    `Chat Id`: Long,
    Minutes: Long?,
    `Until Date` : Instant?,
    `Revoke Messages` : Boolean?
) : Boolean {

    assertAtLeastOneSpecified(
        `Until Date` to "Until Date",
        Minutes to "Minutes"
    )
    return client.banChatMember(
        user_id = `User Id`,
        chat_id = `Chat Id`.toString(),
        revoke_messages = `Revoke Messages`,
        until_date = (`Until Date` ?: Clock.System.now().plus(Minutes!!.minutes)).epochSeconds
    ).result
}

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Unban Chat Member",
    summary = DSCR_BOT_MUST_BE_ADMIN
)
internal suspend inline fun TelegramBotBlueprintContext.TgUnbanChatMember(
    `User Id`: Long,
    `Chat Id`: Long,
) : Boolean = client.unbanChatMember(
    user_id = `User Id`,
    chat_id = `Chat Id`.toString(),
    only_if_banned = true
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Promote Chat Member",
    summary = DSCR_BOT_MUST_BE_ADMIN
)
internal suspend inline fun TelegramBotBlueprintContext.TgPromoteChatMember(
    `Chat Id`: Long,
    `User Id`: Long,
    `Is Anonymous`: Boolean,
    `Can Manage Chat` : Boolean,
    `Can Post Message` : Boolean,
    `Can Edit Message` : Boolean,
    `Can Delete Message` : Boolean,
    `Can Manage Video Chats` : Boolean,
    `Can Restrict Members` : Boolean,
    `Can Promote Members` : Boolean,
    `Can Change Info` : Boolean,
    `Can Invite User` : Boolean,
    `Can Pin Messages` : Boolean,
) : Boolean = client.promoteChatMember(
    user_id = `User Id`,
    chat_id = `Chat Id`.toString(),
    is_anonymous = `Is Anonymous`,
    can_manage_chat = `Can Manage Chat`,
    can_post_messages = `Can Post Message`,
    can_edit_messages = `Can Edit Message`,
    can_delete_messages = `Can Delete Message`,
    can_manage_video_chats = `Can Manage Video Chats`,
    can_restrict_members = `Can Restrict Members`,
    can_promote_members = `Can Promote Members`,
    can_change_info = `Can Change Info`,
    can_invite_users = `Can Invite User`,
    can_pin_messages = `Can Pin Messages`
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Create Invite Link",
    summary = DSCR_BOT_MUST_BE_ADMIN
)
internal suspend inline fun TelegramBotBlueprintContext.TgCreateInviteLink(
    `Chat Id`: Long,
    `Usage Count`: Long?,
    Name : String?,
    `Expire Min` : Long?,
    `Expire Date` : Instant?
) : String {

    assertAtLeastOneSpecified(
        `Expire Min` to "Expire Min",
        `Expire Date` to "Expire Date"
    )

    return client.createChatInviteLink(
        chat_id = `Chat Id`.toString(),
        name = Name,
        member_limit = `Usage Count`,
        expire_date = (`Expire Date` ?: Clock.System.now().plus(`Expire Min`!!.minutes)).epochSeconds
    ).result.invite_link
}


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Join Chat Respond",
    summary = "$DSCR_BOT_MUST_BE_ADMIN\n$DSCR_JOIN_CHAT_RESPOND"
)
internal suspend inline fun TelegramBotBlueprintContext.TgJoinChatRespond(
    `Chat Id`: Long,
    `User Id`: Long,
    Approve: Boolean
) : Boolean {
    return if (Approve) {
        client.approveChatJoinRequest(
            chat_id = `Chat Id`.toString(),
            user_id = `User Id`
        ).result
    } else {
        client.declineChatJoinRequest(
            chat_id = `Chat Id`.toString(),
            user_id = `User Id`
        ).result
    }
}

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Get Chat Info",
)
internal suspend inline fun TelegramBotBlueprintContext.TgGetChat(
    `Chat Id`: Long,
) : Chat = client.getChat(
    chat_id = `Chat Id`.toString(),
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Get Chat Member Count",
)
internal suspend inline fun TelegramBotBlueprintContext.TgGetChatMembersCount(
    `Chat Id`: Long,
) : Long = client.getChatMemberCount(
    chat_id = `Chat Id`.toString(),
).result.toLong()

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Leave Chat",
    summary = DSCR_BOT_MUST_BE_ADMIN
)
internal suspend inline fun TelegramBotBlueprintContext.TgLeaveChat(
    `Chat Id`: Long,
) : Boolean = client.leaveChat(
    chat_id = `Chat Id`.toString(),
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Send Chat Action",
    summary = DSCR_SEND_CHAT_ACTION
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendChatAction(
    Action : TgChatAction,
    `Chat Id`: Long,
) : Boolean = client.sendChatAction(
    action = Action.actionName,
    chat_id = `Chat Id`.toString(),
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Set Chat Title",
    summary = DSCR_BOT_MUST_BE_ADMIN
)
internal suspend inline fun TelegramBotBlueprintContext.TgSetChatTitle(
    Title : String,
    `Chat Id`: Long,
) : Boolean = client.setChatTitle(
    title = Title,
    chat_id = `Chat Id`.toString(),
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Set Chat Description",
    summary = DSCR_BOT_MUST_BE_ADMIN
)
internal suspend inline fun TelegramBotBlueprintContext.TgSetChatDescription(
    Description : String,
    `Chat Id`: Long,
) : Boolean = client.setChatDescription(
    description = Description,
    chat_id = `Chat Id`.toString(),
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Set Chat Sticker Set",
)
internal suspend inline fun TelegramBotBlueprintContext.TgSetChatStickerSet(
    `Chat Id` : Long,
    `Set Name` : String
) : Boolean = client.setChatStickerSet(
    chat_id = `Chat Id`.toString(),
    sticker_set_name = `Set Name`
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Chat,
    displayName = "Delete Chat Sticker Set",
)
internal suspend inline fun TelegramBotBlueprintContext.TgDeleteChatStickerSet(
    `Chat Id` : Long,
) : Boolean = client.deleteChatStickerSet(
    chat_id = `Chat Id`.toString(),
).result


private fun assertAtLeastOneSpecified(
    vararg args : Pair<Any?, String>
){
    if (args.all { it.first == null }){
        throw IllegalStateException("At least of ${args.map { it.second }} arguments must be specified")
    }
}