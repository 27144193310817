package org.botdesigner.shared.domain.content.account

import androidx.compose.runtime.Immutable
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.value.Value
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.api.AuthProvider
import org.botdesigner.shared.domain.ChildComponent
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.shared.domain.content.StackableComponent

@Immutable
class AccountState(
    val linkedProviders: Set<AuthProvider> = emptySet(),
    val isLinkageInProgress : Boolean = false
)

val AccountState.hasPassword : Boolean
    get() = AuthProvider.Password in linkedProviders

interface AccountComponent : StackableComponent {

    val state : StateFlow<AccountState>

    val dialog : Value<ChildSlot<*, Dialog<*>>>

    fun onChangePasswordClicked()

    fun onLinkGoogleClicked()

    fun onLinkAppleClicked()

    fun onDeleteAccountClicked()

    fun onCancelLinking()

    sealed interface Dialog<T> : ChildComponent<T> {
        class DeleteAccount(
            override val component : AlertConfirmationDialogComponent
        ) : Dialog<AlertConfirmationDialogComponent>
    }
}