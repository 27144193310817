package org.botdesigner.shared.domain.content.oauth.google

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.flow.update
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.AuthRepository
import org.botdesigner.shared.data.repo.LinkResult
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.SnackbarIdiom
import org.botdesigner.shared.domain.SnackbarMessage
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.dispatchers.Dispatchers
import kotlin.coroutines.cancellation.CancellationException


internal class DefaultGoogleOAuthComponent(
    context: ComponentContext,
    private val onBack : () -> Unit,
    private val authRepository: AuthRepository,
    private val dispatchers: Dispatchers,
    private val onMessage : (SnackbarMessage ) -> Unit,
    errorHandler: ErrorHandler,
) : CoroutineComponent(context, errorHandler), GoogleOAuthComponent {

    private val scopes : MutableStateFlow<List<GoogleOAuthScope>> =
        instanceKeeper.getOrCreateSimple(keyFor(::scopes)) {
            MutableStateFlow(emptyList())
        }

    private val isSignInProgress : MutableStateFlow<Boolean> =
        instanceKeeper.getOrCreateSimple(keyFor(::isSignInProgress)) {
            MutableStateFlow(false)
        }

    override val state: StateFlow<GoogleOAuthViewState> =
        combine(scopes, isSignInProgress) { sc, inProgress ->
            GoogleOAuthViewState(sc, inProgress)
        }.stateIn(componentScope, SharingStarted.Lazily, GoogleOAuthViewState())

    override fun onScopeChanged(scope: GoogleOAuthScope, enabled: Boolean) {
        scopes.update {
            if (scope in it)
                it - scope
            else it + scope
        }
    }

    override fun onSignClicked() {
        val scopes = scopes.value.map(GoogleOAuthScope::scope)
        dispatchers.launchIO(
            scope = componentScope,
            key = keyFor(::onSignClicked)
        ) {
            isSignInProgress.emit(true)
            val (idiom, msg) = when (authRepository.linkGoogle(scopes)) {
                LinkResult.Success ->
                    SnackbarIdiom.Success to SharedRes.strings.link_google_success

                LinkResult.InvalidCredentials ->
                    SnackbarIdiom.Error to SharedRes.strings.error_something_went_wrong

                LinkResult.CredentialsInUse ->
                    SnackbarIdiom.Error to SharedRes.strings.error_link_cred_in_use
            }
            onMessage(
                SnackbarMessage(
                    idiom = idiom,
                    message = msg.desc()
                )
            )
        }.invokeOnCompletion {
            if (it != null && it !is CancellationException) {
                onMessage(
                    SnackbarMessage(
                        idiom = SnackbarIdiom.Error,
                        message = SharedRes.strings.error_something_went_wrong.desc()
                    )
                )
            }
            isSignInProgress.tryEmit(false)
        }
    }

    override fun onCancel() {
        dispatchers.cancel(keyFor(::onSignClicked))
    }

    override fun onBack() {
        onBack.invoke()
    }
}