@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.arrays

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Boolean
import kotlin.Pair
import kotlin.Suppress
import kotlin.collections.List
import kotlin.collections.Map
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpArrayElementAtIndex")
public class BpElementAtIndex(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.components.GenericArrayPinFactory.create(
              id = Id("0"),
              elementId = elementId,
              order = o,
              name = "Array",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Index",
              isOut = i,
              required = true
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.components.GenericPinFactory.create(
                  id = Id("r"),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = false
              )
          }
      }
      },
) : BpFunction() {
  override val summary: AnnotatedString
    get() =
        "Returns <b>Array</b> element at specific <b>Index</b> or NULL if index is greater than array length".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4287137928)

  override val pinTypeDerivation: Map<Id, Pair<Id, Boolean>>
    get() = mapOf(
    Pair(Id("r"), Pair(Id("0"), true))
    )

  override val factory: FunctionFactory
    get() = ::BpElementAtIndex

  override suspend fun calculate(input: List<Any?>): List<Any?> = listOf(ElementAtIndex(
  		requireNotNull(input[0] as kotlin.collections.Iterable<kotlin.Any?>?) { 
  "Array can't be null" 
  },
  		requireNotNull(input[1] as kotlin.Long?) { 
  "Index can't be null" 
  }
  	))
}
