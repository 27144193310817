package org.botdesigner.shared.di

import org.botdesigner.shared.util.DefaultLogger
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.dispatchers.CoroutineJobManagerImpl
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.dispatchers.DispatchersImpl
import org.koin.dsl.module

internal val AppModule = module {

    factory<Dispatchers> {
        DispatchersImpl(CoroutineJobManagerImpl())
    }

    single<ErrorHandler> {
        ErrorHandler.Log(DefaultLogger)
//        ErrorHandler.ReThrow
    }
}