package org.botdesigner.botblueprints.telegram.blueprints

import org.botdesigner.blueprint.builder.buildBlueprint
import org.botdesigner.blueprint.builder.function
import org.botdesigner.blueprint.builder.procedure
import org.botdesigner.botblueprints.telegram.TelegramSerializersModule
import org.botdesigner.botblueprints.telegram.functions.BpTgDecomposeMessage
import org.botdesigner.botblueprints.telegram.functions.BpTgMessageReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgSendMessage

fun MessageReceivedBlueprint() = buildBlueprint<BpTgMessageReceived>(TelegramSerializersModule) {
    val decomposeMessage = function<BpTgDecomposeMessage>(300f, 200f)
    val sendMessage = procedure<BpTgSendMessage>(800f, 0f)

    trigger.output(1) connectWith sendMessage.input(1)
    trigger.outputPin(1) connectWith decomposeMessage.inputPin(1)
    decomposeMessage.outputPin(3) connectWith sendMessage.inputPin(2)
    sendMessage.inputPin(1) withValue "Hello!"
}

