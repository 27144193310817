package org.botdesigner.blueprint.ui.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.width
import androidx.compose.runtime.Composable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.toSize
import org.botdesigner.blueprint.AbstractBlueprintNodeStateHolder
import org.botdesigner.blueprint.BlueprintManager

@Composable
fun AbstractBlueprintNodeStateHolder<*>.NamedPinComponent(
    color : Color,
    icon : ImageVector,
    name : String,
    manager: BlueprintManager,
    modifier : Modifier = Modifier,
    fieldSize : Float,
    shape: Shape,
    beforePins : @Composable (ColumnScope.(width : Float) -> Unit)? = null,
    afterPins : @Composable (ColumnScope.(width : Float) -> Unit)? = null,
) {

    BlueprintComponent(
        manager = manager,
        modifier = modifier,
        fieldSize = fieldSize,
        shape = shape
    ) {
        var nameSize by remember {
            mutableStateOf(Size.Zero)
        }
        var containerSize by remember {
            mutableStateOf(Size.Zero)
        }

        var columnInComponent by remember {
            mutableStateOf(Offset.Zero)
        }

        Column(modifier = Modifier
            .onSizeChanged {
                containerSize = it.toSize()
            }
            .onGloballyPositioned {
                columnInComponent = it.positionInParent()
            }
            .drawWithCache {
                val brush = Brush.horizontalGradient(
                    0f to color,
                    .5f to color,
                    1f to Color.Black.copy(alpha = .1f)
                )
                val s = size.copy(height = nameSize.height)
                onDrawBehind {
                    drawRect(
                        brush = brush,
                        size = s
                    )
                }
            }) {

            ComponentName(
                name = name,
                icon = icon,
                modifier = Modifier.onSizeChanged {
                    nameSize = it.toSize()
                }
            )

            beforePins?.invoke(this, containerSize.width)

            var inPinsSize by remember {
                mutableStateOf(Size.Zero)
            }

            var outPinsSize by remember {
                mutableStateOf(Size.Zero)
            }

            var rowInColumn by remember {
                mutableStateOf(Offset.Zero)
            }

            Row(
                modifier = Modifier.onGloballyPositioned {
                    rowInColumn = it.positionInParent()
                }
            ) {
                DrawPins(
                    isOut = false,
                    pool = manager,
                    onPinClicked = manager::onPinClicked,
                    modifier = Modifier.onSizeChanged {
                        inPinsSize = it.toSize()
                    },
                    parentInComponent = columnInComponent + rowInColumn,
                    drawIf = { shouldDrawPin(it) }
                )

                val density = LocalDensity.current
                val spacerSize by remember{
                    derivedStateOf {
                        density.run {
                            (containerSize.width - outPinsSize.width - inPinsSize.width).toDp()
                        }
                    }
                }

                Spacer(
                    modifier = Modifier
                        .width(spacerSize)
                )

                DrawPins(
                    isOut = true,
                    pool = manager,
                    onPinClicked = manager::onPinClicked,
                    modifier = Modifier.onSizeChanged {
                        outPinsSize = it.toSize()
                    },
                    parentInComponent = columnInComponent + rowInColumn,
                    drawIf = { shouldDrawPin(it) }
                )
            }

            afterPins?.invoke(this, containerSize.width)
        }
    }
}