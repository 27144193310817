package org.botdesigner.core

import androidx.compose.ui.graphics.vector.ImageVector
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.serialization.Serializable
import org.botdesigner.api.ApiBot
import org.botdesigner.api.ApiBotStatus
import org.botdesigner.api.ApiBotType
import org.botdesigner.api.SharedConstants
import org.botdesigner.core.resources.images.Logos
import org.botdesigner.core.resources.images.Telegram


@Serializable
data class Bot(
    val id : String = com.benasher44.uuid.uuid4().toString(),
    val ownerId : String = "",
    val token : String,
    val debugToken : String? = null,
    val name : String,
    val type : BotType,
    val status : BotStatus = BotStatus.Stopped,
    val editedAt : Long,
    val createdAt : Long,
    val isTokenValid : Boolean = true,
    val isDebugTokenValid : Boolean = true,
    val imageUri : String? = null,
    val realName : String? = null,
    val username : String? = null
)

val Bot.isRealtimeUpdatesEnabled : Boolean
    get() = (debugToken != null && isDebugTokenValid) || status == BotStatus.Stopped

val Bot.tokenForDebug : String?
    get() = when {
        debugToken != null && isDebugTokenValid -> debugToken
        isTokenValid -> token
        else -> null
    }

enum class BotStatus(val status : StringResource, ) {
    Running(MR.strings.running),
    Stopped(MR.strings.stopped)
}

enum class BotType(
    val img : ImageVector,
    val type : String,
    val creationGuide : String,
    val tokenDescription : StringDesc
){
    Telegram(
        img = Logos.Telegram,
        type = "Telegram",
        creationGuide = SharedConstants.TGCreationGuide,
        tokenDescription = MR.strings.tg_token_desc.desc()
    )
}

fun ApiBotType.toCore() = org.botdesigner.core.BotType.valueOf(name)
fun ApiBotStatus.toCore() = org.botdesigner.core.BotStatus.valueOf(name)
fun BotType.toApi() = ApiBotType.valueOf(name)
fun BotStatus.toApi() = ApiBotStatus.valueOf(name)

fun ApiBot.toCore() = Bot(
    id = id,
    ownerId = ownerId,
    token = token,
    debugToken = debugToken,
    name = name,
    type = type.toCore(),
    status = status.toCore(),
    editedAt = editedAt,
    createdAt = createdAt,
    imageUri = null,
    realName = null
)
