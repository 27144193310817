package org.botdesigner.ui.screens.account

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.section.CupertinoLinkIcon
import io.github.alexzhirkevich.cupertino.section.CupertinoSection
import io.github.alexzhirkevich.cupertino.section.SectionLink
import io.github.alexzhirkevich.cupertino.section.sectionTitle
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import io.github.alexzhirkevich.cupertino.theme.systemGreen
import org.botdesigner.api.AuthProvider
import org.botdesigner.core.resources.images.Apple
import org.botdesigner.core.resources.images.Google
import org.botdesigner.core.resources.images.Logos
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.account.AccountComponent
import org.botdesigner.shared.domain.content.account.hasPassword
import org.botdesigner.ui.common.dialogs.ConfirmationDialog
import org.botdesigner.ui.localized
import org.botdesigner.ui.screens.PreferencesScreen
import org.botdesigner.ui.string

@OptIn(ExperimentalCupertinoApi::class)
@Composable
internal fun AccountScreen(
    component: AccountComponent,
    modifier: Modifier
) {

    val state by component.state.collectAsState()

    component.dialog.subscribeAsState().value.child?.instance?.let {
        when (it) {
            is AccountComponent.Dialog.DeleteAccount -> {
                ConfirmationDialog(it.component)
            }
        }
    }

    if (state.isLinkageInProgress){
        OauthSignProgressDialog(
            onCancel = component::onCancelLinking
        )
    }

    PreferencesScreen(
        modifier = modifier,
        title = string(SharedRes.strings.account),
        onBack = component::onBack
    ) {
        if (state.hasPassword) {
            CupertinoSection(
            ) {
                SectionLink(
                    onClick = component::onChangePasswordClicked,
                ) {
                    Text(SharedRes.strings.change_password.localized())
                }
            }
        }

        CupertinoSection(
            title = {
                Text(string(SharedRes.strings.link_provider).sectionTitle())
            },
            caption = {
                Text(string(SharedRes.strings.link_provider_desc))
            }
        ) {
            SectionLink(
                onClick = component::onLinkGoogleClicked,
                icon = {
                    CupertinoLinkIcon(
                        containerColor = CupertinoColors.systemGreen,
                        imageVector = Logos.Google
                    )
                }
            ) {
                Text(
                    if (AuthProvider.Google !in state.linkedProviders)
                        SharedRes.strings.link_google.localized()
                    else SharedRes.strings.manage_google.localized()
                )
            }

            SectionLink(
                onClick = component::onLinkAppleClicked,
                icon = {
                    CupertinoLinkIcon(
                        imageVector = Logos.Apple,
                        tint = MaterialTheme.colorScheme.background,
                        containerColor = CupertinoTheme.colorScheme.label
                    )
                }
            ) {
                Text(
                    if (AuthProvider.Apple !in state.linkedProviders)
                        SharedRes.strings.link_apple.localized()
                    else SharedRes.strings.unlink_apple.localized()
                )
            }
        }

        CupertinoSection(
            caption = {
                Text(string(SharedRes.strings.delete_account_desc))
            }
        ) {
            SectionLink(
                onClick = component::onDeleteAccountClicked,
                chevron = {}
            ){
                Text(
                    text = string(SharedRes.strings.delete_account),
                    color = MaterialTheme.colorScheme.error,
                    modifier = Modifier.fillMaxWidth(),
                    textAlign = TextAlign.Center
                )
            }
        }
    }
}