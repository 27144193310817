package org.botdesigner.blueprint.io.network

import org.botdesigner.blueprint.store.BlueprintNodeCategory
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.blueprint.store.BlueprintNodeStoreRecord

public fun BlueprintNodeStoreOwner.register_org_botdesigner_blueprint_io_network() {
  BlueprintNodeStoreOwner.register(
  	BlueprintNodeCategory("Network"),
  	BlueprintNodeStoreRecord("Http", ::BpSendHttpRequest),
  	BlueprintNodeStoreRecord("Http", ::BpSendHttpRequestDownload),
  	BlueprintNodeStoreRecord("Http", ::BpSendHttpRequestUpload)
  )
}
