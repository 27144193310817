package org.botdesigner.blueprint.integrations.functions.custom

import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.execute
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.integrations.context.CustomProcedureContext
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
data class ProcedureInfo(
    val id : String,
    val verId : String,
    val verTag : String,
    val name : String,
    val summary : String
)

@Serializable
@SerialName("BpCustomProcedure")
class BpCustomProcedure(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>>,
    override val connectors: List<Connector>,
    val info : ProcedureInfo,
) : BpProcedure<CustomProcedureContext>() {

    override val summary: AnnotatedString
        get() = info.summary.toAnnotatedString()
    override val name: String
        get() = "${info.name} [${info.verTag}]"

    override suspend fun execute(
        input: List<*>,
        context: CustomProcedureContext,
        pool: BlueprintNodesPool
    ): Pair<Connector?, List<*>> {
        return outputConnectors[0] to context
            .blueprint(info.id, info.verId)
            .execute(context)
    }


    override val factory: ProcedureFactory<CustomProcedureContext>
        get() = { p, i, pi, co ->
            BpCustomProcedure(
                coordinate = p,
                id = i,
                pins = pi,
                connectors = co,
                info = info
            )
        }
}

/**
 * Create an initial blueprint for custom procedure.
 * */
fun CustomProcedureBlueprint(
    name : String,
    inputs: List<Pair<String, PinFactory<*>>>,
    outputs : List<Pair<String, PinFactory<*>>>
) : Blueprint {
    return Blueprint(
        elements = buildList {

            val triggerId = Id.randomId()
            val returnId = Id.randomId()

            val input = inputs.mapIndexed { index, it ->
                it.second.create(
                    id = Id.randomId(),
                    order = index.toUInt(),
                    elementId = triggerId,
                    name = it.first,
                    isOut = true
                )
            }
            val output = outputs.mapIndexed { index, it ->
                it.second.create(
                    id = Id.randomId(),
                    order = (inputs.size + index).toUInt(),
                    elementId = returnId,
                    name = it.first,
                    isOut = false
                )
            }

            if (input.isNotEmpty()) {
                add(
                    BpCustomProcedureTrigger(
                    coordinate = Point.Zero,
                    id = triggerId,
                    pins = input,
                    name = name
                )
                )
            }
            if (output.isNotEmpty()){
                add(
                    BpCustomProcedureReturn(
                    coordinate = Point(x = 400f, y = 0f),
                    id = returnId,
                    pins = output
                )
                )
            }
        }
    )
}