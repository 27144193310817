package org.botdesigner.api.auth

import kotlinx.serialization.Serializable
import org.botdesigner.api.User

@Serializable
class OAuthSignInRequest(
    val idToken : String? = null,
    val code : String? = null,
    override val device : String,
    override val deviceType : DeviceType,
    override val os: String,
    override val notificationToken: String
) : SignInRequest

@Serializable
class OAuthSignInResponse(
    override val userInfo : User,
    override val accessToken: String?,
    override val refreshToken: String?
) : AuthResponse