package org.botdesigner.blueprint.integrations.context

import kotlinx.serialization.Serializable

@Serializable
data class PushNotification(
    val title : String,
    val body : String
)

interface PushNotificationContext {
    suspend fun sendPushNotification(
        notification: PushNotification
    )
}