package org.botdesigner.api.auth

import kotlinx.serialization.Serializable

@Serializable
class Session(
    val id : String,
    val ip : String,
    val country : String,
    val city : String,
    val device: String,
    val deviceType : DeviceType,
    val lastActive : Long,
    val isCurrent : Boolean
)