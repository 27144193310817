
package org.botdesigner.ui.screens.list

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListScope
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Circle
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material.icons.outlined.Delete
import androidx.compose.material.icons.outlined.Edit
import androidx.compose.material3.Icon
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import coil3.compose.AsyncImage
import io.github.alexzhirkevich.cupertino.CupertinoSwipeBoxItem
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.LocalContainerColor
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveHorizontalDivider
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.systemGreen
import io.github.alexzhirkevich.cupertino.theme.systemOrange
import kotlinx.coroutines.flow.Flow
import org.botdesigner.core.Bot
import org.botdesigner.core.BotStatus
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.ui.common.ContextMenuArea
import org.botdesigner.ui.common.ContextMenuItem
import org.botdesigner.ui.common.PlatformVerticalScrollbar
import org.botdesigner.ui.common.SwipeToDelete
import org.botdesigner.ui.common.darken
import org.botdesigner.ui.common.isLoading
import org.botdesigner.ui.common.rememberSwipeToDeleteState
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable

private const val SkeletonsCount = 4

@OptIn(ExperimentalAdaptiveApi::class, ExperimentalCupertinoApi::class)
@Composable
internal fun BotList(
    lazyListState: LazyListState,
    bots: List<Bot>?,
    selectedId: String?,
    discardDelete : Flow<Any?>,
    paddingValues: PaddingValues,
    modifier: Modifier = Modifier,
    onClicked: (Bot) -> Unit,
    onDelete: (Bot) -> Unit,
    onEdit: (Bot) -> Unit,
    actions: LazyListScope.() -> Unit
) {

    Box {
        PlatformVerticalScrollbar(
            scrollableState = lazyListState,
            modifier = Modifier
                .zIndex(1f)
                .align(Alignment.CenterEnd)
        )
        LazyColumn(
            contentPadding = paddingValues,
            state = lazyListState,
            modifier = modifier
        ) {

            actions()

            if (bots != null) {
                itemsIndexed(bots, key = { _, item -> item.id }) { idx, bot ->
                    Column {
                        val highlighted = selectedId == bot.id && LocalInterfaceIdiom.current == InterfaceIdiom.Desktop

                        CompositionLocalProvider(
                            LocalContentColor provides
                                    if (highlighted) MaterialTheme.colorScheme.onPrimary
                                    else LocalContentColor.current
                        ) {
                            val state = rememberSwipeToDeleteState(
                                onDelete = { onDelete(bot) }
                            )

                            val delete = string(SharedRes.strings.delete)
                            val edit = string(SharedRes.strings.edit)


                            SwipeToDelete(
                                state = state,
                                discardFlow = discardDelete,
                                onDelete = { onDelete(bot) },
                                items = {
                                    CupertinoSwipeBoxItem(
                                        color = CupertinoColors.systemOrange,
                                        onClick = { onEdit(bot) },
                                        label = {
                                            Text(edit)
                                        },
                                        onClickLabel = edit,
                                        icon = {
                                            Icon(
                                                imageVector = Icons.Filled.Edit,
                                                contentDescription = null
                                            )
                                        }
                                    )
                                }
                            ) {
                                ContextMenuArea(
                                    items = {
                                        listOf(
                                            ContextMenuItem(
                                                icon = Icons.Outlined.Delete,
                                                title = delete,
                                                clickAction = { onDelete(bot) }
                                            ),
                                            ContextMenuItem(
                                                icon = Icons.Outlined.Edit,
                                                title = edit,
                                                clickAction = { onEdit(bot) }
                                            )
                                        )
                                    }
                                ) {
                                    BotItem(
                                        imageSize = 48.dp,
                                        bot = bot,
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .background(
                                                if (highlighted)
                                                    MaterialTheme.colorScheme.primary.darken()
                                                else LocalContainerColor.current
                                            )
                                            .pointerHoverIcon(PointerIcon.PlatformClickable)
                                            .clickable(enabled = !highlighted) {
                                                onClicked(bot)
                                            }
                                            .padding(Dimens.Spacing.Medium)
                                    )
                                }
                            }
                        }

                        AdaptiveHorizontalDivider(
                            modifier = Modifier.padding(
                                start = if (idx == bots.lastIndex)
                                    0.dp else Dimens.Spacing.Medium * 2 + 48.dp
                            )
                        )
                    }
                }
            } else {
                items(SkeletonsCount){
                    Column {
                        BotItem(
                            imageSize = 48.dp,
                            bot = null,
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(Dimens.Spacing.Medium)
                        )

                        AdaptiveHorizontalDivider(
                            modifier = Modifier.padding(
                                start = if (it == SkeletonsCount - 1)
                                    0.dp else Dimens.Spacing.Medium * 2 + 48.dp
                            )
                        )
                    }
                }
            }
        }
    }
}

@Composable
private fun BotItem(
    bot : Bot?,
    modifier : Modifier = Modifier,
    imageSize : Dp = 48.dp,
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(Dimens.Spacing.Medium)
    ) {
        val defaultPainter = rememberVectorPainter(bot?.type?.img ?: Icons.Filled.Circle)

        val runningColor = CupertinoColors.systemGreen
        val errorColor = MaterialTheme.colorScheme.error

        val contentColor = LocalContentColor.current

        Box {
            AsyncImage(
                model = bot?.imageUri,
                contentDescription = null,
                placeholder = defaultPainter,
                fallback = defaultPainter,
                error = defaultPainter,
                modifier = Modifier
                    .size(imageSize)
                    .isLoading(bot == null)
                    .drawWithContent {
                        drawContent()

                        val isRunning = bot?.status == BotStatus.Running
                        val isInvalidToken = bot?.isTokenValid == false

                        if (isRunning) {
                            val radius = Dimens.Spacing.Medium.toPx() / 2
                            val center = Offset(size.width - radius, size.height - radius)
                            drawCircle(
                                color = when {
                                    isInvalidToken -> errorColor
                                    isRunning -> runningColor
                                    else -> Color.Transparent // should never happen
                                },
                                radius = radius,
                                center = center
                            )
                            drawCircle(
                                color = contentColor,
                                radius = radius,
                                center = center,
                                style = Stroke(.5.dp.toPx())
                            )
                        }
                    }
                    .clip(CircleShape)

            )
        }
        Column {

            val name = when {
                bot == null -> "**************"
                bot.realName != null -> "${bot.name} (${bot.realName})"
                else -> bot.name
            }

            val type = when {
                bot == null ->"***********"
                bot.username != null -> "${bot.type.name} • ${bot.username}"
                else -> bot.type.name
            }

            Text(
                text = name,
                fontWeight = FontWeight.Bold,
                style = MaterialTheme.typography.bodyLarge,
                maxLines = 1,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier
                    .isLoading(bot == null)
            )
            Spacer(Modifier.height(1.dp))

            Text(
                text = type,
                style = MaterialTheme.typography.bodyMedium,
                maxLines = 1,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier
                    .isLoading(bot == null)
                    .alpha(.5f)
            )
        }
    }
}