package org.botdesigner.botblueprints.telegram.pins

import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.components.*
import org.botdesigner.botblueprints.KeyboardButton
import org.botdesigner.telegram.KeyboardButton
import kotlinx.serialization.SerialName


object KeyboardButtonPinFactory : PinFactory<KeyboardButton> {

    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean,
    ) = KeyboardButtonPin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )


    override fun array(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = KeyboardButtonArrayPinFactory.create(
        id = id,
        order = order,
        elementId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}

@kotlinx.serialization.Serializable
@SerialName("TgKeyboardButtonPin")
data class KeyboardButtonPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: KeyboardButton? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : Pin<KeyboardButton>() {

    override val color: Color get() = BlueprintColors.KeyboardButton

    override fun factory(pool: BlueprintNodesPool): PinFactory<KeyboardButton> {
        return KeyboardButtonPinFactory
    }

    override fun withReference(parent: Pin<*>?): Pin<KeyboardButton> {
        return copy(parentId = parent?.elId, parentPinId = parent?.id, value = null)
    }

    override fun withValue(value: String): Pin<KeyboardButton> {
        return copy(value = null, parentId = null, parentPinId = null)
    }
}

fun PinsDirectionalScope.keyboardButton(name : String) {
    pin { order, isOut ->
        KeyboardButtonPin(
            id = Id.randomId(),
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
        )
    }
}