package org.botdesigner.sharedui

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.AssetResource
import dev.icerock.moko.resources.ColorResource
import dev.icerock.moko.resources.FileResource
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.PluralsResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.StringResource
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "67fddfb078ce0b86138082d2c5dfdc82"

  public actual object strings : ResourceContainer<StringResource> {
    override fun values(): List<StringResource> = listOf()
  }

  public actual object plurals : ResourceContainer<PluralsResource> {
    override fun values(): List<PluralsResource> = listOf()
  }

  public actual object images : ResourceContainer<ImageResource> {
    override fun values(): List<ImageResource> = listOf()
  }

  public actual object fonts : ResourceContainer<FontResource> {
    override fun values(): List<FontResource> = listOf(Montserrat.black, Montserrat.blackItalic,
        Montserrat.bold, Montserrat.boldItalic, Montserrat.extraBold, Montserrat.extraBoldItalic,
        Montserrat.extraLight, Montserrat.extraLightItalic, Montserrat.italic, Montserrat.light,
        Montserrat.lightItalic, Montserrat.medium, Montserrat.mediumItalic, Montserrat.regular,
        Montserrat.semiBold, Montserrat.semiBoldItalic, Montserrat.thin, Montserrat.thinItalic)

    public fun addFontsToPage() {
      js("require(\"fonts/orgbotdesignersharedui-generated-declarations.css\")")
    }

    public actual object Montserrat {
      public actual val black: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-Black.ttf\")") as String, fontFamily = "Montserrat-Black")

      public actual val blackItalic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-BlackItalic.ttf\")") as String, fontFamily =
          "Montserrat-BlackItalic")

      public actual val bold: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-Bold.ttf\")") as String, fontFamily = "Montserrat-Bold")

      public actual val boldItalic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-BoldItalic.ttf\")") as String, fontFamily =
          "Montserrat-BoldItalic")

      public actual val extraBold: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-ExtraBold.ttf\")") as String, fontFamily =
          "Montserrat-ExtraBold")

      public actual val extraBoldItalic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-ExtraBoldItalic.ttf\")") as String, fontFamily =
          "Montserrat-ExtraBoldItalic")

      public actual val extraLight: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-ExtraLight.ttf\")") as String, fontFamily =
          "Montserrat-ExtraLight")

      public actual val extraLightItalic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-ExtraLightItalic.ttf\")") as String, fontFamily =
          "Montserrat-ExtraLightItalic")

      public actual val italic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-Italic.ttf\")") as String, fontFamily =
          "Montserrat-Italic")

      public actual val light: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-Light.ttf\")") as String, fontFamily = "Montserrat-Light")

      public actual val lightItalic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-LightItalic.ttf\")") as String, fontFamily =
          "Montserrat-LightItalic")

      public actual val medium: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-Medium.ttf\")") as String, fontFamily =
          "Montserrat-Medium")

      public actual val mediumItalic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-MediumItalic.ttf\")") as String, fontFamily =
          "Montserrat-MediumItalic")

      public actual val regular: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-Regular.ttf\")") as String, fontFamily =
          "Montserrat-Regular")

      public actual val semiBold: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-SemiBold.ttf\")") as String, fontFamily =
          "Montserrat-SemiBold")

      public actual val semiBoldItalic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-SemiBoldItalic.ttf\")") as String, fontFamily =
          "Montserrat-SemiBoldItalic")

      public actual val thin: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-Thin.ttf\")") as String, fontFamily = "Montserrat-Thin")

      public actual val thinItalic: FontResource = FontResource(fileUrl =
          js("require(\"fonts/Montserrat-ThinItalic.ttf\")") as String, fontFamily =
          "Montserrat-ThinItalic")
    }
  }

  public actual object files : ResourceContainer<FileResource> {
    override fun values(): List<FileResource> = listOf()
  }

  public actual object colors : ResourceContainer<ColorResource> {
    public actual val splash: ColorResource = ColorResource(lightColor = Color(0xffffffFF),
        darkColor = Color(0x1c1c1cFF))

    override fun values(): List<ColorResource> = listOf(splash)
  }

  public actual object assets : ResourceContainer<AssetResource> {
    public actual val done: AssetResource = AssetResource(originalPath =
        js("require(\"files/done.json\")") as String, rawPath = "done.json")

    public actual val robot_error: AssetResource = AssetResource(originalPath =
        js("require(\"files/robot_error.json\")") as String, rawPath = "robot_error.json")

    public actual val robot_hello: AssetResource = AssetResource(originalPath =
        js("require(\"files/robot_hello.json\")") as String, rawPath = "robot_hello.json")

    public actual val robot_play: AssetResource = AssetResource(originalPath =
        js("require(\"files/robot_play.json\")") as String, rawPath = "robot_play.json")

    override fun values(): List<AssetResource> = listOf(done, robot_error, robot_hello, robot_play)
  }
}
