package org.botdesigner.shared.domain.content.create

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import org.botdesigner.core.Bot
import org.botdesigner.core.BotType
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.BotsRepository
import org.botdesigner.shared.data.repo.VerifyBotTokenResult
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.intents.ViewUrlIntent

internal class DefaultCreateBotComponent(
    context: ComponentContext,
    private val onNavigateToBot : (Bot) -> Unit,
    private val onBack : () -> Unit,
    private val edit : Bot?,
    private val viewUrlIntent: ViewUrlIntent,
    private val botsRepository: BotsRepository,
    errorHandler: ErrorHandler
) : CoroutineComponent(context, errorHandler),
    CreateOrEditBotComponent {

    override val isEdit: Boolean get() = edit != null

    override val state: MutableStateFlow<CreateOrEditBotViewState> = instanceKeeper
        .getOrCreateSimple(keyFor(::state)) {
            MutableStateFlow(
                CreateOrEditBotViewState(
                    name = edit?.name.orEmpty(),
                    token = edit?.token.orEmpty(),
                    debugToken = edit?.debugToken.orEmpty(),
                    type = edit?.type ?: BotType.Telegram,
                    tokenError = if (edit?.isTokenValid == false)
                        SharedRes.strings.error_invalid_bot_token.desc()
                    else null,
                    debugTokenError = if (edit?.isTokenValid != false && edit?.isDebugTokenValid == false)
                        SharedRes.strings.error_invalid_bot_token.desc()
                    else null
                )
            )
        }


    override fun onCreateClicked() {
        state.update {
            it.copy(
                isLoading = true
            )
        }

        val st = state.value

        componentScope.launch {
            if (validateToken(st.token, st.type, false)) {
                val debugToken = st.debugToken.takeIf(String::isNotBlank)
                if (debugToken == null || validateToken(debugToken, st.type, true)) {
                    save(
                        name = st.name,
                        token = st.token,
                        debugToken = st.debugToken.takeIf(String::isNotBlank),
                        type = st.type
                    )
                }
            }
        }.invokeOnCompletion {
            state.update {
                it.copy(isLoading = false)
            }
        }
    }

    private suspend fun validateToken(token: String, type: BotType, debug: Boolean): Boolean {

        if (debug && state.value.token == token) {
            state.update {
                it.copy(
                    debugTokenError = SharedRes.strings.error_same_debug_token.desc()
                )
            }
            return false
        }

        return when (botsRepository.verifyToken(token, type)) {
            VerifyBotTokenResult.Success -> true

            VerifyBotTokenResult.Error -> false

            VerifyBotTokenResult.InvalidToken, VerifyBotTokenResult.Exists -> {
                val err = SharedRes.strings.error_invalid_bot_token.desc()

                state.update {
                    it.copy(
                        tokenError = if (debug) it.tokenError else err,
                        debugTokenError = if (debug) err else it.debugTokenError,
                    )
                }
                false
            }
        }
    }

    override fun onTokenDescriptionHelpClicked() {
        viewUrlIntent.invoke(state.value.type.creationGuide)
    }

    override fun onNameChanged(value: String) {
        state.update {
            it.copy(
                name = value
            )
        }
    }

    override fun onTokenChanged(value: String) {
        state.update {
            it.copy(
                token = value,
                tokenError = null
            )
        }
    }

    override fun onDebugTokenChanged(value: String) {
        state.update {
            it.copy(
                debugToken = value,
                debugTokenError = null
            )
        }
    }

    override fun onBack() {
        onBack.invoke()
    }


    private suspend fun save(name: String, token: String, debugToken: String?, type: BotType) {

        if (edit == null) {
            val bot = botsRepository.create(
                name = name,
                token = token,
                debugToken = debugToken,
                type = type
            )
            onNavigateToBot(bot)
        } else {
            botsRepository.update(
                edit.copy(
                    name = name,
                    token = token,
                    debugToken = debugToken,
                    isTokenValid = true,
                    isDebugTokenValid = true
                )
            )
            onBack()
        }
    }
}