package org.botdesigner.shared.domain.content

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.value.Value
import org.botdesigner.shared.domain.ChildComponent
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.content.account.AccountComponent
import org.botdesigner.shared.domain.content.appearance.AppearanceComponent
import org.botdesigner.shared.domain.content.appearance.AppearanceState
import org.botdesigner.shared.domain.content.blueprint.BlueprintComponent
import org.botdesigner.shared.domain.content.bot.BotComponent
import org.botdesigner.shared.domain.content.changepassword.ChangePasswordComponent
import org.botdesigner.shared.domain.content.create.CreateOrEditBotComponent
import org.botdesigner.shared.domain.content.guides.BlueprintGuidePage
import org.botdesigner.shared.domain.content.guides.GuideComponent
import org.botdesigner.shared.domain.content.guides.PinsGuidePage
import org.botdesigner.shared.domain.content.oauth.google.GoogleOAuthComponent
import org.botdesigner.shared.domain.content.sessions.SessionsComponent
import org.botdesigner.shared.domain.content.settings.EditorSettingsComponent
import org.botdesigner.shared.domain.content.subscription.SubscriptionComponent
import org.botdesigner.shared.domain.content.tabs.TabsComponent


interface ContentComponent : ComponentContext {


    val stack : Value<MultipaneChildStack<*, Child<*>>>

    val dialog : Value<ChildSlot<*, Dialog<*>>>

    val appearance : AppearanceState

    fun onBack(): Boolean

    fun onDialogDismissed()

    fun onIdiomChanged(idiom: InterfaceIdiom)

    fun onPaneResize(sizeDp : Float)

    sealed interface Child<T> : ChildComponent<T> {

        object Empty : Child<Unit> {
            override val component: Unit
                get() = Unit
        }

        class Tabs(
            override val component : TabsComponent
        ) : Child<TabsComponent>

        class Bot(
            override val component : BotComponent
        ) : Child<BotComponent>

        class Blueprint(
            override val component : BlueprintComponent
        ) : Child<BlueprintComponent>

        class Sessions(
            override val component: SessionsComponent
        ) : Child<SessionsComponent>

        class Appearance(
            override val component: AppearanceComponent
        ) : Child<AppearanceComponent>

        class Settings(
            override val component: EditorSettingsComponent
        ) : Child<EditorSettingsComponent>

        class Account(
            override val component: AccountComponent
        ) : Child<AccountComponent>
    }

    sealed interface Dialog<T> : ChildComponent<T> {
        class Subscription(
            override val component : SubscriptionComponent
        ) : Dialog<SubscriptionComponent>

        class CreateOrEditBot(
            override val component : CreateOrEditBotComponent
        ) : Dialog<CreateOrEditBotComponent>

        class GoogleOauth(
            override val component : GoogleOAuthComponent
        ) : Dialog<GoogleOAuthComponent>

        class ChangePassword(
            override val component : ChangePasswordComponent
        ) : Dialog<ChangePasswordComponent>

        class BlueprintGuide(
            override val component : GuideComponent<BlueprintGuidePage>
        ) : Dialog<GuideComponent<BlueprintGuidePage>>

        class PinsGuide(
            override val component : GuideComponent<PinsGuidePage>
        ) : Dialog<GuideComponent<PinsGuidePage>>
    }
}

