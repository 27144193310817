package io.github.alexzhirkevich.onetap

import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlin.coroutines.resume

internal const val OneTapBroadcastChannel = "io.github.alexzhirkevich.onetap"

/**
 * Completes popup OAuth sign in flow by posting [Location.href] message to the parent window
 * with the same [Location.origin].
 * This will be closed by parent window.
 * */
fun Window.finishSignIn() {
    BroadcastChannel(OneTapBroadcastChannel).apply {
        postMessage(window.href)
        close()
    }
    closeWindow()
}

actual class OAuthSignInClient<R> actual constructor(
    private val uri : String,
    private val redirectUri : String,
    private val onRedirected : (String) -> R
) : AbstractSignInClient<R>() {

    var windowOptions = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100, popup=true"
    var windowTitle = "Sign In"

    override suspend fun launch(viewController: NativeViewController): SignInResult<R> {

        return coroutineScope {
            suspendCancellableCoroutine { cont ->

                val popupWindow = viewController.window.dialog(uri, windowTitle, windowOptions)

                if (popupWindow == null) {
                    cont.resume(SignInResult.Failure(SignInException("Failed to open OAuth popup window")))
                    return@suspendCancellableCoroutine
                }

                var finished = false

                val channel =  BroadcastChannel(OneTapBroadcastChannel)

                var interval : Int? = null

                fun resume(value : SignInResult<R>?) {
                    if (!finished) {
                        finished = true
                        value?.let(cont::resume)
                        interval?.let(viewController.window::clearInterval)
                        popupWindow.closeWindow()
                        channel.close()
                    }
                }

                var msg = runCatchingMsg { popupWindow.href }

                interval = viewController.window.setInterval(
                    handler = {
                        val newMsg = runCatchingMsg {

                            // window.closed is always true for other domains.
                            // this line will throw exception if href is not the same domain
                            popupWindow.href

                            if (popupWindow.isClosed) {
                                resume(SignInResult.Cancelled)
                            }

                        }
                        if (msg == null) {
                            msg = newMsg
                        } else {
                            // popupWindow.location.href throw different exception when window is closed
                            if (newMsg!= msg) {
                                resume(SignInResult.Cancelled)
                            }
                        }
                    },
                    interval = 500
                )

                channel.onMessage { data ->

                    if (!data.startsWith(redirectUri, true)) {

                        resume(
                            SignInResult.Failure(
                                SignInException(
                                    "Posted from popup message must be a window.location.href that starts with '$redirectUri'. But got '$data'"
                                )
                            )
                        )
                    } else {

                        try {
                            resume(SignInResult.Success(onRedirected(data.toString())))
                        } catch (t: Throwable) {
                            resume(SignInResult.Failure(t))
                        }
                    }
                }

                cont.invokeOnCancellation {
                    resume(null)
                }
            }
        }
    }
}