package org.botdesigner.shared.data.repo

import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.integrations.context.CustomProcedureContext
import org.botdesigner.blueprint.integrations.functions.custom.BpCustomProcedure
import org.botdesigner.core.CustomProcedureCombinedData
import org.botdesigner.core.CustomProcedureData
import org.botdesigner.core.CustomProcedureVersionData

interface CustomFunctionsRepository {

    fun getAll(): Flow<List<CustomProcedureCombinedData>>

    suspend fun get(id: String): CustomProcedureData

    suspend fun versions(id: String): List<CustomProcedureVersionData>

    suspend fun blueprint(id: String, versionId: String): Blueprint

//    suspend fun variables(id : String): List<BlueprintVariableData>
}

fun CustomFunctionsRepository.asCustomProcedureContext(
    blueprint: Blueprint
) : CustomProcedureContext = object :
    CustomProcedureContext {

    private val mutex = Mutex()

    private var blueprints: Map<Pair<String, String>, Blueprint>? = null

    override suspend fun blueprint(procedureId: String, versionId: String): Blueprint {
        return requireNotNull(blueprints()[procedureId to versionId]) {
            "Procedure $procedureId is not registered in this blueprint"
        }
    }

    override suspend fun versions(
        procedureId: String
    ): List<Pair<String, String>> {
        return this@asCustomProcedureContext.versions(procedureId)
            .map { it.id to it.tag }
    }

    private suspend fun blueprints(): Map<Pair<String, String>, Blueprint> {
        mutex.withLock {
            if (blueprints != null)
                return blueprints!!

            val procedures = blueprint.elements
                .filterIsInstance<BpCustomProcedure>()
                .map { it.info }
                .distinct()

            blueprints = coroutineScope {
                procedures.map {
                    async {
                        it.id to it.verId to
                                this@asCustomProcedureContext.blueprint(it.id, it.verId)
                    }
                }.awaitAll().toMap()
            }

            return blueprints!!
        }
    }
}