package org.botdesigner.shared.domain.content.blueprint.terminal

import androidx.compose.runtime.Stable
import com.arkivanov.decompose.ComponentContext
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.blueprint.BlueprintLogLevel
import org.botdesigner.blueprint.BlueprintLogcat
import org.botdesigner.blueprint.BlueprintTerminalLog
import org.botdesigner.shared.data.repo.ConsolePosition
import org.botdesigner.shared.data.repo.EditorSettings
import org.botdesigner.shared.domain.CoroutineComponent


@Stable
internal class DefaultBlueprintLogsComponent(
    context: ComponentContext,
    override val fontScale: Float,
    private val settings : StateFlow<EditorSettings>,
    private val onConsolePositionChanged : (ConsolePosition) -> Unit,
    private val logcat: BlueprintLogcat,
    private val onClose : () -> Unit
) : CoroutineComponent(context), BlueprintLogsComponent {

    override val logs: List<BlueprintTerminalLog>
        get() = logcat.log

    override val level: BlueprintLogLevel
        get() = logcat.minLevel

    override fun onCloseClicked() = onClose()

    override fun onClearClicked() {
        logcat.clear()
    }

    override fun onLogLevelChange() {
        logcat.minLevel = when (logcat.minLevel) {
            BlueprintLogLevel.Verbose -> BlueprintLogLevel.Warning
            BlueprintLogLevel.Warning -> BlueprintLogLevel.Error
            BlueprintLogLevel.Error -> BlueprintLogLevel.Verbose
        }
    }

    override fun onChangeOrientationClicked() {
        onConsolePositionChanged(
            if (settings.value.consolePosition == ConsolePosition.Bottom)
                ConsolePosition.End else ConsolePosition.Bottom
        )
    }
}