package org.botdesigner.blueprint.components.functions

import androidx.compose.runtime.State
import kotlinx.serialization.Contextual
import kotlinx.serialization.Transient
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.*

typealias ProcedureFactory<T> = (
    coordinate: Point,
    id : Id,
    pins : List<Pin<@Contextual Any?>>,
    connectors : List<Connector>
) -> BpProcedure<T>

/**
 * Implementation of blueprint procedure with encapsulated I/O mechanism and state holder.
 *
 * @see BlueprintProcedure
 * */
@kotlinx.serialization.Serializable
abstract class BpProcedure<in T> : BlueprintProcedure<T> {

    /**
     * SHOULD NOT BE USED DIRECTLY.
     *
     * Protected for procedure lambda codegen
     * */
    @Transient
    protected var results: List<Any?> = emptyList()

    abstract val factory: ProcedureFactory<in T>

    /**
     * Execute procedure.
     *
     * Guaranteed that [input] list is consistent with input probably null [pins] values.
     * So this list can be safely casted to the corresponding input types.
     *
     * @param input [pins] values in initial order
     * @param context current blueprint execution context
     *
     * @return pair of next execution target and output pin values
     * */
    abstract suspend fun execute(
        input: List<*>,
        context: T,
        pool: BlueprintNodesPool
    ): Pair<Connector?, List<*>>

    final override suspend fun invoke(context: T, pool: BlueprintNodesPool) {

        val inputs = inputPins
            .map { super.pinValue(it, pool) }

        runWithExceptionMark {
            val (con, res) = execute(inputs, context, pool)

            results = res

            con?.invokeNext(context, pool)
        }
    }

    @Suppress("UNCHECKED_CAST")
    final override suspend fun <T> pinValue(pin: Pin<T>, pool: BlueprintNodesPool): T? {
        if (!pin.isOut) {
            return super.pinValue(pin, pool)
        }
        return pinValueWithExceptionMark(pin) {
            outputPins
                .indexOfFirst { it.id == pin.id }
                .takeIf { it >= 0 }
                ?.let { results[it] as T? }
        }
    }

    override fun toString(): String {
        return "${this::class.simpleName}(${BlueprintNode::id.name}=$id)"
    }

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> {
        return BpProcedureStateHolder(
            element = this,
            manager = manager
        )
    }
}

open class BpProcedureStateHolder<T>(
    element: BpProcedure<T>,
    manager: BlueprintManager
) : BlueprintFunctionStateHolder<BpProcedure<T>>(
    element = element,
    manager = manager
) {

    override fun save(): BpProcedure<T> {
        return element.factory(
            position.value,
            element.id,
            pins,
            connectors
        )
    }
}