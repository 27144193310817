package org.botdesigner.api.auth

import kotlinx.serialization.Serializable
import org.botdesigner.api.User

@Serializable
class ResetPasswordRequest(
    val email : String
)

@Serializable
class ConfirmResetPasswordRequest(
    val email: String,
    val code : String,
    val newPassword : String,
    override val device : String,
    override val deviceType : DeviceType,
    override val os: String,
    override val notificationToken: String
) : SignInRequest

@Serializable
class ConfirmResetPasswordResponse(
    override val accessToken: String? = null,
    override val refreshToken: String? = null,
    override val userInfo: User? = null,
    val errorCode: ResetPasswordErrorCode = ResetPasswordErrorCode.Success
) : AuthResponse