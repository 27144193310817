package org.botdesigner.shared.domain.content.oauth.google

import androidx.compose.runtime.Immutable
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.StackableComponent

enum class GoogleOAuthScope(
    val scope : String,
    val title : StringDesc,
    val caption : StringDesc,
) {
    Drive(
        scope = "https://www.googleapis.com/auth/drive.file",
        title = SharedRes.strings.google_scope_drive.desc(),
        caption = SharedRes.strings.google_scope_drive_desc.desc()
    ),
    Sheets(
        scope = "https://www.googleapis.com/auth/spreadsheets",
        title = SharedRes.strings.google_scope_sheets.desc(),
        caption = SharedRes.strings.google_scope_sheets_desc.desc()
    ),
}

@Immutable
class GoogleOAuthViewState(
    val scopes : List<GoogleOAuthScope> = emptyList(),
    val isSignInProgress : Boolean = false
)

interface GoogleOAuthComponent : StackableComponent {

    val state: StateFlow<GoogleOAuthViewState>

    fun onScopeChanged(scope: GoogleOAuthScope, enabled: Boolean)

    fun onSignClicked()

    fun onCancel()

}
