package org.botdesigner.botblueprints.telegram.pins

import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.components.*
import org.botdesigner.botblueprints.User
import org.botdesigner.telegram.User
import kotlinx.serialization.SerialName


object UserArrayPinFactory : PinFactory<Iterable<User>>{
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): UserArrayPin = UserArrayPin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}

@kotlinx.serialization.Serializable
@SerialName("TgUserArrayPin")
data class UserArrayPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: Iterable<User>? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : ArrayPin<User>() {

    override val childPinFactory: PinFactory<User>
        get() = UserPinFactory

    override val color: Color get() = BlueprintColors.User

    override fun factory(pool: BlueprintNodesPool): PinFactory<Iterable<User>> {
        return UserArrayPinFactory
    }

    override fun withReference(parent: Pin<*>?): Pin<Iterable<User>> {
        return copy(parentId = parent?.elId, parentPinId = parent?.id, value = null)
    }

    override fun withValue(value: String): Pin<Iterable<User>> {
        return copy(value = null, parentId = null, parentPinId = null)
    }
}

fun PinsDirectionalScope.userArray(name : String) {
    pin { order, isOut ->
        UserArrayPin(
            id = Id.randomId(),
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
        )
    }
}