package org.botdesigner.api

import kotlinx.serialization.Serializable


@Serializable
data class ApiBlueprintData(
    val id : String,
    val type : ApiBlueprintType,
    val context : String = "",
    val blueprint : String,
    val createdAt: Long = 0,
    val editedAt: Long = 0
)
@Serializable
data class ApiBot(
    val id : String,
    val ownerId : String = "",
    val token : String,
    val debugToken : String? = null,
    val name : String,
    val type : ApiBotType,
    val status : ApiBotStatus = ApiBotStatus.Stopped,
    val createdAt : Long,
    val editedAt : Long,
)

enum class ApiBotStatus {
    Stopped,
    Running,
}
enum class ApiBotType{
    Telegram
}


enum class ApiBlueprintType {
    Scheduled,
    MessageReceived,
    CommandReceived,
    ImageReceived,
    VideoReceived,
    DocumentReceived,
    StickerReceived,
    VoiceReceived,
    VideoNoteReceived,
    MessageEdited,
    ChatJoinRequest,
    ChannelPosted,
    ChannelPostEdited
}


@Serializable
class GetBotsResponse(
    val bots : List<ApiBot>
)

@Serializable
class GetBotResponse(
    val bot: ApiBot
)


@Serializable
class CreateBotRequest(
    val token : String,
    val debugToken : String?,
    val name : String,
    val type : ApiBotType,
)

@Serializable
class CreateBotResponse(
    val bot : ApiBot
)

@Serializable
class UpdateBotRequest(
    val token : String,
    val debugToken : String?,
    val name : String,
)

@Serializable
class ToggleBotRequest(
    val status: ApiBotStatus
)

@Serializable
class UpdateBotResponse(
    val bot : ApiBot
)


/**
 * @property update Map of local saved [ApiBlueprintData.id] to [ApiBlueprintData.editedAt]
 * */
@Serializable
class GetUpdatedBlueprintsRequest(
    val update : Map<String, Long>
)

@Serializable
class GetBlueprintsResponse(
    val blueprints : List<ApiBlueprintData>
)

@Serializable
class GetBlueprintResponse(
    val blueprint : ApiBlueprintData
)

@Serializable
class CreateBlueprintRequest(
    val type : ApiBlueprintType,
    val context : String,
    val blueprint : String,
)

@Serializable
class CreateBlueprintResponse(
    val botId : String,
    val blueprintData: ApiBlueprintData
)

@Serializable
class UpdateBlueprintRequest(
    val context : String?,
    val blueprint : String?
)

@Serializable
class UpdateBlueprintResponse(
    val botId : String,
    val blueprintData: ApiBlueprintData
)