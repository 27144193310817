package org.botdesigner.shared.di

import io.ktor.client.HttpClient
import org.botdesigner.shared.data.cache.CacheDatabase
import org.botdesigner.shared.data.cache.SqBlueprintQueries
import org.botdesigner.shared.data.cache.SqBotQueries
import org.botdesigner.shared.data.cache.SqSessionQueries
import org.botdesigner.shared.data.repo.AuthRepository
import org.botdesigner.shared.data.repo.BlueprintsRepository
import org.botdesigner.shared.data.repo.BotsRepository
import org.botdesigner.shared.data.repo.CustomFunctionsRepository
import org.botdesigner.shared.data.repo.SessionRepository
import org.botdesigner.shared.data.repo.SettingsRepository
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.data.repo.impl.AuthRepositoryImpl
import org.botdesigner.shared.data.repo.impl.BlueprintsRepositoryImpl
import org.botdesigner.shared.data.repo.impl.BotsRepositoryImpl
import org.botdesigner.shared.data.repo.impl.DefaultHttpClient
import org.botdesigner.shared.data.repo.impl.SessionRepositoryImpl
import org.botdesigner.shared.data.repo.impl.SettingsRepositoryImpl
import org.botdesigner.shared.data.repo.test.CustomFunctionsRepositoryTest
import org.botdesigner.shared.data.source.BotDataSource
import org.botdesigner.shared.data.source.CacheBlueprintDataSource
import org.botdesigner.shared.data.source.CacheBotDataSource
import org.botdesigner.shared.data.source.CacheSessionsDataSource
import org.botdesigner.shared.data.source.DatabaseFactory
import org.botdesigner.shared.data.source.RemoteBlueprintDataSource
import org.botdesigner.shared.data.source.SessionsDataSource
import org.botdesigner.shared.data.source.impl.CacheBlueprintDataSourceImpl
import org.botdesigner.shared.data.source.impl.CacheBotDataSourceImpl
import org.botdesigner.shared.data.source.impl.CacheSessionsDataSourceImpl
import org.botdesigner.shared.data.source.impl.DatabaseFactoryImpl
import org.botdesigner.shared.data.source.impl.RemoteBlueprintDataSourceImpl
import org.botdesigner.shared.data.source.impl.RemoteBotDataSource
import org.botdesigner.shared.data.source.impl.RemoteSessionsDataSourceImpl
import org.botdesigner.shared.util.managers.RefreshTokenManager
import org.botdesigner.shared.util.managers.RefreshTokenManagerImpl
import org.koin.core.module.dsl.new
import org.koin.core.qualifier.named
import org.koin.dsl.module

private val SqlDelightModule by lazy {
    module {
        single<DatabaseFactory> {
            new(::DatabaseFactoryImpl)
        }
        single<CacheDatabase> {
            get<DatabaseFactory>().create()
        }
    }
}

private val HttpDataModule by lazy {
    module {
        single<HttpClient> {
            DefaultHttpClient(get(), get())
        }
        single<HttpClient>(qualifier = named("SSE")) {
            DefaultHttpClient(get(), get(), sse = true)
        }
        single<RefreshTokenManager> {
            new(::RefreshTokenManagerImpl)
        }
    }
}

private val BotDataModule by lazy {
    module {
        single<SqBotQueries> {
            get<CacheDatabase>().sqBotQueries
        }
        single<CacheBotDataSource> {
            new(::CacheBotDataSourceImpl)
        }
        single<BotDataSource> {
            new(::RemoteBotDataSource)
        }
        single<BotsRepository> {
            new(::BotsRepositoryImpl)
        }
    }
}

private val BlueprintsDataModule by lazy {
    module {
        single<SqBlueprintQueries> {
            get<CacheDatabase>().sqBlueprintQueries
        }
        single<CacheBlueprintDataSource> {
            new(::CacheBlueprintDataSourceImpl)
        }
        single<RemoteBlueprintDataSource> {
            new(::RemoteBlueprintDataSourceImpl)
        }
        single<BlueprintsRepository> {
            new(::BlueprintsRepositoryImpl)
        }

        single<CustomFunctionsRepository> {
            new(::CustomFunctionsRepositoryTest)
//        new(::CustomFunctionsRepositoryImpl)
        }
    }
}

private val SessionsDataModule by lazy {
    module {
        single<SqSessionQueries> {
            get<CacheDatabase>().sqSessionQueries
        }
        single<CacheSessionsDataSource> {
            new(::CacheSessionsDataSourceImpl)
        }
        single<SessionsDataSource> {
            new(::RemoteSessionsDataSourceImpl)
        }
        single<SessionRepository> {
            new(::SessionRepositoryImpl)
        }
    }
}

private val AuthDataModule by lazy {
    module {
        single<UserRepository> {
            get<AuthRepository>()
        }

        single<AuthRepository> {
            new(::AuthRepositoryImpl)
        }
    }
}


private val SettingsDataModule by lazy {
    module {
        single<SettingsRepository> {
            new(::SettingsRepositoryImpl)
        }
    }
}



val DataModule by lazy {
    module {
        includes(
            SqlDelightModule,
            HttpDataModule,
            BotDataModule,
            BlueprintsDataModule,
            SessionsDataModule,
            AuthDataModule,
            SettingsDataModule
        )
    }
}
