@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.telegram.functions

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Pair
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext

@Serializable
@SerialName("BpTgSendPoll")
public class BpTgSendPoll(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Question",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringArrayPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Options",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Chat Id",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Is Anonymous",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Is Quiz",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Allow Multi Answers",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Timeout Sec",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.DateTimePinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "End Date",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Disable Notification",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Protect Content",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.KeyboardPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Reply Markup (Keyboard)",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.botblueprints.telegram.pins.MessagePinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("")
      },
) : BpProcedure<@Contextual TelegramBotBlueprintContext>() {
  override val summary: AnnotatedString
    get() = """
    |Send poll to the chat.
    |Up to 10 <b>Options</b>. Up to 100 characters for each option.
    |Poll can optionally <b>time out</b> after <b>5-600</b> seconds or it can be closed after <b>End Date</b>. This 2 attributes can not be used together.
    """.trimMargin().trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4280696141)

  override val name: String
    get() = "Send Poll"

  override val factory: ProcedureFactory<@Contextual TelegramBotBlueprintContext>
    get() = ::BpTgSendPoll

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual TelegramBotBlueprintContext,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(TgSendPoll(
  		requireNotNull(input[0] as kotlin.String?) { 
  "Question can't be null" 
  },
  		requireNotNull(input[1] as kotlin.collections.Iterable<kotlin.String>?) { 
  "Options can't be null" 
  },
  		requireNotNull(input[2] as kotlin.Long?) { 
  "Chat Id can't be null" 
  },
  		input[3] as kotlin.Boolean?,
  		input[4] as kotlin.Boolean?,
  		input[5] as kotlin.Boolean?,
  		input[6] as kotlin.Long?,
  		input[7] as kotlinx.datetime.Instant?,
  		input[8] as kotlin.Boolean?,
  		input[9] as kotlin.Boolean?,
  		input[10] as org.botdesigner.telegram.KeyboardOption?
  	))}
}
