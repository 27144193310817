package io.github.alexzhirkevich.onetap


/**
 * [Google OAuth flow](https://developers.google.com/identity/protocols/oauth2/web-server)
 *
 * @param clientId The client ID for your application. You can find this value in the
 * API Console [Credentials page](https://console.cloud.google.com/apis/credentials).
 * @param redirectUri Determines where the API server redirects the user after the user completes
 * the authorization flow. The value must exactly match one of the authorized redirect URIs for the
 * OAuth 2.0 client, which you configured in your client's API Console
 * [Credentials page](https://console.cloud.google.com/apis/credentials). If this value doesn't
 * match an authorized redirect URI for the provided client_id you will get a redirect_uri_mismatch error.
 * Note that the http or https scheme, case, and trailing slash ('/') must all match.
 * @param responseType Determines whether the Google OAuth 2.0 endpoint returns an authorization code.
 * Set this to **code** to get server authorization code or **token** to get access token
 * @param scopes Scopes enable your application to only request access to the resources that it
 * needs while also enabling users to control the amount of access that they grant to your application.
 * Thus, there is an inverse relationship between the number of scopes requested and the likelihood
 * of obtaining user consent. [Google OAuth scopes](https://developers.google.com/identity/protocols/oauth2/scopes)
 * @param accessType Indicates whether your application can refresh access tokens when the user is
 * not present at the app. Valid parameter values are **online**, which is the default value,
 * and **offline**. Set the value to offline if your application needs to refresh access tokens when
 * the user is not present at the browser. This value instructs the Google authorization server to
 * return a refresh token and an access token the first time that your application exchanges an
 * authorization code for tokens.
 * @param includeGrantedScopes Enables applications to use incremental authorization to request
 * access to additional scopes in context. If you set this parameter's value to true and the
 * authorization request is granted, then the new access token will also cover any scopes to which
 * the user previously granted the application access.
 * See the [incremental authorization](https://developers.google.com/identity/protocols/oauth2/web-server#incrementalAuth) section for examples.
 * @param state Specifies any string value that your application uses to maintain state between your
 * authorization request and the authorization server's response. The server returns the exact value
 * that you send as a name=value pair in the URL query component (?) of the redirect_uri after the
 * user consents to or denies your application's access request.
 * You can use this parameter for several purposes, such as directing the user to the correct resource
 * in your application, sending nonces, and mitigating cross-site request forgery. Since your redirect_uri
 * can be guessed, using a state value can increase your assurance that an incoming connection is the result
 * of an authentication request. If you generate a random string or encode the hash of a cookie or another
 * value that captures the client's state, you can validate the response to additionally ensure that the
 * request and response originated in the same browser, providing protection against attacks such as cross-site
 * request forgery. See the [OpenID Connect](https://developers.google.com/identity/protocols/oauth2/openid-connect#createxsrftoken)
 * documentation for an example of how to create and confirm a state token.
 * @param prompt A space-delimited, case-sensitive list of prompts to present the user. If you
 * don't specify this parameter, the user will be prompted only the first time your project requests
 * access. See [Prompting re-consent](https://developers.google.com/identity/protocols/oauth2/openid-connect#re-consent) for more information.
 * */
inline fun OneTap.Google(
    clientId: String,
    redirectUri: String,
    responseType : String = "code",
    scopes : List<String> = listOf("profile", "email"),
    accessType : String? = null,
    includeGrantedScopes : Boolean = true,
    state : String? = randomState(),
    prompt : String? = null,
    loginHint : String? = null,
    enabledGranularConsent : Boolean? = null
) : OAuthSignInClient<OAuthResult> = OAuthSignInClient(
    uri = buildOAuthUri("https://accounts.google.com/o/oauth2/v2/auth") {
        clientId(clientId)
        redirectUri(redirectUri)
        scopes(scopes)
        responseType(responseType)
        param("include_granted_scopes", includeGrantedScopes)
        if (accessType != null) {
            param("access_type", accessType)
        }

        state?.let(::state)

        prompt?.let {
            param("prompt", it)
        }
        loginHint?.let {
            param("login_hint",it)
        }
        enabledGranularConsent?.let {
            param("enable_granular_consent", it)
        }
    },
    redirectUri = redirectUri,
    onRedirected = {
        OAuthResult.parse(it, state)
    }
)


/**
 * Client will use AndroidX Credentials Manager on Android and [Google]
 * for other platforms. [GoogleSignInResult.idToken] and [GoogleSignInResult.googleAccount]
 * will be returned only on android.
 *
 * @param redirectUri required only for non-Android targets
 * */
expect fun OneTap.GoogleCM(
    serverClientId: String,
    redirectUri : String
) : SignInClient<GoogleSignInResult>


class GoogleSignInResult(
    val idToken : String? = null,
    val serverAuthCode : String? = null,
    val googleAccount: GoogleAccount? = null
) : Credential

class GoogleAccount(
    val displayName : String?,
    val firstName : String?,
    val lastName : String?,
    val email : String?,
    val picture : String?,
    val id : String?,
)

