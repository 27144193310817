package org.botdesigner.ui.screens.guide

import androidx.compose.animation.core.MutableTransitionState
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.updateTransition
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.AnnotatedString
import io.github.alexzhirkevich.cupertino.cupertinoTween
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.PinsScope
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpExpression
import org.botdesigner.blueprint.components.functions.ExpressionFactory
import org.botdesigner.blueprint.components.generic
import org.botdesigner.blueprint.components.genericArray
import org.botdesigner.blueprint.stdlib.functions.long.BpLongSum
import org.botdesigner.blueprint.stdlib.pins.bool
import org.botdesigner.blueprint.stdlib.pins.double
import org.botdesigner.blueprint.stdlib.pins.doubleArray
import org.botdesigner.blueprint.stdlib.pins.long
import org.botdesigner.blueprint.stdlib.pins.longArray
import org.botdesigner.blueprint.stdlib.pins.string
import org.botdesigner.blueprint.stdlib.pins.stringArray
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.blueprint.ui.components.BlueprintPreview
import org.botdesigner.shared.domain.content.guides.GuideComponent
import org.botdesigner.shared.domain.content.guides.PinsGuidePage
import org.botdesigner.ui.common.PlatformDialogAnimationDuration

@Composable
fun PinsGuideScreen(
    component : GuideComponent<PinsGuidePage>,
    modifier: Modifier = Modifier
) {

    val pinTypesTransitionState = remember {
        MutableTransitionState(false).apply {
            targetState = true
        }
    }

    GuideScreen(
        component = component,
        modifier = modifier,
        illustration = {
            when (it){
                PinsGuidePage.Types -> PinTypesIllustration(pinTypesTransitionState)
                PinsGuidePage.Arrays -> ArraysIllustration()
                PinsGuidePage.Generics -> GenericsIllustration()
                PinsGuidePage.Required -> RequiredIllustration()
                PinsGuidePage.Extenders -> ExtenderIllustration()
            }
        }
    )
}

private fun ExampleExpression(
    coordinate: Point = Point.Zero,
    id: Id = Id.uuid(),
    pins: List<Pin<Any?>> = Pins(id) {
        output {
            string("Out String")
        }
    },
) : BpExpression = object : BpExpression(){
    override val coordinate: Point = coordinate
    override val id: Id = id
    override val pins: List<Pin<Any?>> = pins
    override val expression: String get() = ""
    override val factory: ExpressionFactory = ::ExampleExpression
    override val name: String
        get() = "Expression"

    override val summary: AnnotatedString
        get() = "This is node description. Here you can read what this node do, which arguments it takes and other useful information".toAnnotatedString()

    override suspend fun calculate(input: List<*>): List<*> {
        return emptyList<Any?>()
    }
}

private fun <T> pinTypesTransition() = cupertinoTween<T>(
    durationMillis = 1000,
    delayMillis = PlatformDialogAnimationDuration /2
)

private val DefaultScale = 1f

@Composable
private fun BoxScope.PinsIllustration(
    scale : Float = DefaultScale,
    nodeModifier : Modifier = Modifier
        .align(Alignment.Center)
        .scale(scale),
    pins: PinsScope.() -> Unit,
){
    BlueprintPreview {
        val inputExpression = remember {
            ExampleExpression(
                id = Id("0"),
                pins = Pins(Id("0"), pins)
            ).createHolder(it)
        }

        inputExpression.Draw(
            modifier = nodeModifier,
            fieldSize = Float.MAX_VALUE,
            shape = MaterialTheme.shapes.medium
        )
    }
}

@Composable
private fun BoxScope.PinTypesIllustration(
    transitionState: MutableTransitionState<Boolean>
) {
    val settledTransition = updateTransition(transitionState)

    val scale by settledTransition.animateFloat(
        transitionSpec = { pinTypesTransition() }
    ) { if (it) DefaultScale else .4f }

    PinsIllustration(
        nodeModifier =  Modifier
            .align(Alignment.Center)
            .graphicsLayer {
                scaleX = scale
                scaleY = scale
            },
        scale = .9f
    ){
        input {
            string("String (text)", default = "abc")
            long("Integer number (64 bit)", default = 123)
            double("Floating point number", default = -10.5)
            bool("Boolean (true/false)", default = true)
        }
    }
}

@Composable
private fun BoxScope.ArraysIllustration() {

    PinsIllustration {
        input {
            stringArray("Array of strings")
            longArray("Array of integers")
            doubleArray("Array of floats")
        }
    }
}

@Composable
private fun BoxScope.GenericsIllustration() {

    PinsIllustration {
        input {
            generic("Generic")
            genericArray("Generic array")
        }
    }
}

@Composable
private fun BoxScope.RequiredIllustration() {

    PinsIllustration {
        input {
            string("Required", required = true)
        }

        output {
            string("Guaranteed", required = true)
        }
    }
}

@Composable
private fun BoxScope.ExtenderIllustration() {

    BlueprintPreview {
        val inputExpression = remember {
            BpLongSum(
                id = Id("0"),
                pins = Pins(Id("0")){
                    input {
                        long("A")
                        long("B")
                        long("C")
                    }
                    output {
                        long("Sum")
                    }
                }
            ).createHolder(it)
        }

        inputExpression.Draw(
            modifier = Modifier.align(Alignment.Center),
            fieldSize = Float.MAX_VALUE,
            shape = MaterialTheme.shapes.medium
        )
    }
}