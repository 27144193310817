@file:Suppress("NOTHING_TO_INLINE","FUNCTIONNAME","LOCALVARIABLENAME")


package org.botdesigner.botblueprints.telegram.functions

import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.BlueprintProcedure
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.generator.Tuple
import org.botdesigner.blueprint.generator.Tuple6
import org.botdesigner.botblueprints.BpCategories
import org.botdesigner.botblueprints.BpSubcategories
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext
import org.botdesigner.telegram.User
import org.botdesigner.telegram.getUserProfilePhotos

private const val DSCR_DECOMPOSE_USER = "Ge user properties: name, username, id, ..."

@BlueprintFunction(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.User,
    displayName = "Decompose User",
    summary = DSCR_DECOMPOSE_USER
)
internal inline fun TgDecomposeUser(
    User : User
) : Tuple6<
    @Pin("Id") Long,
    @Pin("First Name") String,
    @Pin("LastName") String?,
    @Pin("Username") String?,
    @Pin("Is Premium") Boolean,
    @Pin("Is Bot") Boolean
> = Tuple(
    User.id,
    User.first_name,
    User.last_name,
    User.username,
    User.is_premium == true,
    User.is_bot
)

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.User,
    displayName = "Get User Profile Photos",
    summary = DSCR_DECOMPOSE_USER
)
internal suspend inline fun TelegramBotBlueprintContext.TgGetUserProfilePhotos(
    `User Id` : Long
) : List<String> = client.getUserProfilePhotos(
    user_id = `User Id`
).result.photos.flatten().map {it.file_id }