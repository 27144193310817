@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.special

import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpExpression
import org.botdesigner.blueprint.components.functions.ExpressionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpIsNull")
public class BpIsNull(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.components.GenericPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Object",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
) : BpExpression() {
  override val summary: AnnotatedString
    get() = "Check if object is uninitialized (is NULL)".trimIndent().toAnnotatedString()

  override val factory: ExpressionFactory
    get() = ::BpIsNull

  override val expression: String
    get() = "IS NULL"

  override suspend fun calculate(input: List<Any?>): List<Any?> = listOf(IsNull(
  		input[0] as kotlin.Any?
  	))
}
