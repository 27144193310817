package org.botdesigner.ui

import androidx.compose.runtime.Composable
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.compose.stringResource
import dev.icerock.moko.resources.desc.StringDesc
import io.github.alexzhirkevich.onetap.NativeViewController

@Composable
internal expect fun LocalViewController() : NativeViewController

@Composable
internal expect fun string(desk : StringDesc) : String

@Composable
internal fun string(res : StringResource) : String{
    return stringResource(res).normalizeStringResource()
}

@Composable
internal fun StringResource.localized() : String {
    return string(this)
}

@Composable
internal fun StringResource.localized(vararg args : Any) : String {
    return string(this, *args)
}

@Composable
internal fun StringDesc.localized() : String {
    return string(this)
}


@Composable
internal fun string(res : StringResource, vararg args : Any) : String{
    return stringResource(res, *args).normalizeStringResource()
}

internal fun String.normalizeStringResource() =
    replace("\\n", "\n")
    .replace("\\t", "\t")
    .replace("\\","")

internal expect fun StringDesc.localized(viewController: NativeViewController) : String


