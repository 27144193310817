package org.botdesigner.ui.screens.tabs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.WindowInsetsSides
import androidx.compose.foundation.layout.only
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Icon
import androidx.compose.material3.ScaffoldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import io.github.alexzhirkevich.cupertino.LocalContainerColor
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveNavigationBar
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.adaptive.icons.List
import io.github.alexzhirkevich.cupertino.icons.CupertinoIcons
import io.github.alexzhirkevich.cupertino.icons.filled.Gearshape
import org.botdesigner.shared.domain.content.tabs.TabsComponent
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.ui.common.AdaptiveNavigationMenuItem
import org.botdesigner.ui.common.NullableComposable
import org.botdesigner.ui.screens.list.BotListScreen
import org.botdesigner.ui.screens.profile.ProfileScreen
import org.botdesigner.ui.string

@OptIn(
    ExperimentalDecomposeApi::class,
    ExperimentalAdaptiveApi::class,
)
@Composable
fun TabsScreen(
    component: TabsComponent,
    modifier: Modifier = Modifier
) {

    val tabs by component.tabs.subscribeAsState()

    AdaptiveScaffold(
        modifier = modifier,
        containerColor = LocalContainerColor.current,
        contentWindowInsets = ScaffoldDefaults.contentWindowInsets
            .only(WindowInsetsSides.Bottom),
        bottomBar = {
            val isMobileIdiom = LocalInterfaceIdiom.current == InterfaceIdiom.Mobile

            AdaptiveNavigationBar(
                adaptation = {
                    cupertino {
                        isTranslucent = isMobileIdiom
                    }
                }
            ) {
                tabs.items.forEachIndexed { idx, tab ->

                    val t = tab.instance ?: return@forEachIndexed

                    if (tab.instance != null) {
                        AdaptiveNavigationMenuItem(
                            selected = tabs.selectedIndex == idx,
                            onClick = {
                                component.onTabSelected(idx)
                            },
                            icon = {
                                Icon(
                                    imageVector = when(t) {
                                        is TabsComponent.Tab.BotList -> AdaptiveIcons.Outlined.List
                                        is TabsComponent.Tab.Settings -> CupertinoIcons.Filled.Gearshape
                                    },
                                    contentDescription = null
                                )
                            },
                            label = NullableComposable(isMobileIdiom) {
                                Text(string(t.title))
                            }
                        )
                    }
                }
            }
        }
    ) {
        Box(Modifier.padding(it)) {
            val tab = tabs.items[tabs.selectedIndex]

            when (val t = tab.instance) {
                is TabsComponent.Tab.BotList ->
                    BotListScreen(t.component)

                is TabsComponent.Tab.Settings ->
                    ProfileScreen(t.component)

                else -> {}
            }
        }
    }
}