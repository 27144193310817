package org.botdesigner.api.auth

enum class DeviceType {
    Android,
    Iphone,
    Ipad,
    Mac,
    Windows,
    Linux,
    DesktopUnknown,
    Web,
    WebMobile,
    Unknown
}

val DeviceType.isDesktop
    get() = this == DeviceType.Mac || this == DeviceType.Windows || this == DeviceType.Linux ||
            this == DeviceType.DesktopUnknown