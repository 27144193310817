@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.telegram.functions

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Pair
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext

@Serializable
@SerialName("BpTgSendSticker")
public class BpTgSendSticker(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Sticker",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Chat Id",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Reply To Message",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Disable Notification",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Protect Content",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.KeyboardPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Reply Markup (Keyboard)",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.botblueprints.telegram.pins.MessagePinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("")
      },
) : BpProcedure<@Contextual TelegramBotBlueprintContext>() {
  override val summary: AnnotatedString
    get() =
        "Send sticker using it's URL from the Internet (in .webp format) or file_id of existing on Telegram servers sticker".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4280696141)

  override val name: String
    get() = "Send Sticker"

  override val factory: ProcedureFactory<@Contextual TelegramBotBlueprintContext>
    get() = ::BpTgSendSticker

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual TelegramBotBlueprintContext,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(TgSendSticker(
  		requireNotNull(input[0] as kotlin.String?) { 
  "Sticker can't be null" 
  },
  		requireNotNull(input[1] as kotlin.Long?) { 
  "Chat Id can't be null" 
  },
  		input[2] as kotlin.Long?,
  		input[3] as kotlin.Boolean?,
  		input[4] as kotlin.Boolean?,
  		input[5] as org.botdesigner.telegram.KeyboardOption?
  	))}
}
