package org.botdesigner.shared.data.source.impl

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOne
import org.botdesigner.core.Bot
import org.botdesigner.core.BotStatus
import org.botdesigner.shared.data.cache.SqBot
import org.botdesigner.shared.data.cache.SqBotQueries
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.data.source.CacheBotDataSource
import org.botdesigner.shared.util.dispatchers.Dispatchers

internal class CacheBotDataSourceImpl(
    private val queries : SqBotQueries,
    private val userRepository: UserRepository,
    private val dispatchers: Dispatchers
) : CacheBotDataSource {

    private val uid get() = requireNotNull(userRepository.currentUser?.id)

    override suspend fun getAll(): List<Bot> {
        return dispatchers.runOnIO {
            queries.getAll(uid).awaitAsList().map { it.toBot() }
        }
    }

    override suspend fun create(bot: Bot): Bot {
        dispatchers.runOnIO {
            queries.create(
                id = bot.id,
                ownerId = bot.ownerId,
                name = bot.name,
                token = bot.token,
                debugToken = bot.debugToken,
                tokenValid = bot.isTokenValid,
                debugTokenValid = bot.isDebugTokenValid,
                type = bot.type,
                status = bot.status,
                createdAt = bot.createdAt,
                editedAt = bot.editedAt,
                imageUrl = bot.imageUri,
                realName = bot.realName,
                username = bot.username
            )
        }

        return bot
    }

    override suspend fun get(id: String): Bot {
        return dispatchers.runOnIO {
            queries.get(id).awaitAsOne().toBot()
        }
    }

    override suspend fun update(bot: Bot): Bot {
        dispatchers.runOnIO {
            queries.update(
                token = bot.token,
                tokenValid = bot.isTokenValid,
                debugToken = bot.debugToken,
                debugTokenValid = bot.isDebugTokenValid,
                name = bot.name,
                id = bot.id
            )
        }
        return bot
    }

    override suspend fun toggle(id: String, status: BotStatus) {
        dispatchers.runOnIO {
            queries.toggle(
                id = id,
                status = status
            )
        }
    }

    override suspend fun delete(id: String) {
        dispatchers.runOnIO {
            queries.delete(id)
        }
    }

    override suspend fun deleteAll() {
        dispatchers.runOnIO {
            queries.deleteAll()
        }
    }
}

private fun SqBot.toBot() = Bot(
    id = id,
    ownerId = ownerId,
    type = type,
    status = status,
    token = token,
    isTokenValid = tokenValid,
    debugToken = debugToken,
    isDebugTokenValid = debugTokenValid,
    name = name,
    editedAt = editedAt,
    createdAt = createdAt,
    imageUri = imageUrl,
    realName = realName,
    username = username
)