package org.botdesigner.blueprint.components

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import org.botdesigner.blueprint.components.functions.BpTrigger

/**
 * Callable blueprint element, that starts blueprint execution.
 *
 * - Should not have input pins.
 * - Execution should only initialize output pins and call next [BlueprintInvocable]
 * - Should not be inherited directly. Use [BpTrigger] instead.
 * */

interface BlueprintTrigger<in T> : BlueprintProcedure<T> {

    override val icon: ImageVector
        get() = BlueprintIcons.Trigger

    override val color: Color
        get() = BlueprintColors.Trigger

}