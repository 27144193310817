package org.botdesigner.blueprint.stdlib.functions.special

import kotlinx.coroutines.delay
import kotlinx.coroutines.ensureActive
import kotlin.coroutines.coroutineContext

internal inline fun <R> withFraudProtection(
    block : FraudProtection.() -> R
) : R = FraudProtection().block()


internal class FraudProtection(
    private val maxIterationCount : Int = 1000
) {

    private var count = 0

    suspend fun cycle() {
        count++
        coroutineContext.ensureActive()
        if (count > maxIterationCount) {
            delay((count - maxIterationCount) * 10L)
        }
    }
}