package org.botdesigner.shared.data.repo

import kotlinx.coroutines.flow.Flow
import org.botdesigner.api.ChangePasswordResult
import org.botdesigner.api.User

enum class LinkResult {
    Success, InvalidCredentials, CredentialsInUse
}

interface UserRepository {

    val currentUser : User?

    val currentUserFlow : Flow<User?>

    suspend fun linkGoogle(extraScopes : List<String>) : LinkResult
    suspend fun linkApple() : LinkResult
    suspend fun refreshUser()

    suspend fun updateAccount(name : String? = null)

    suspend fun changePassword(oldPassword: String, newPassword : String) : ChangePasswordResult

    suspend fun deleteAccount()
}