package org.botdesigner.blueprint.store

import androidx.compose.runtime.Stable
import androidx.compose.runtime.snapshots.SnapshotStateMap
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import org.botdesigner.blueprint.components.BlueprintNode



@Stable
interface BlueprintNodeStore {
    val pool : Map<BlueprintNodeCategory, MutableList<BlueprintNodeStoreRecord<*>>>
}

interface BlueprintNodeStoreOwner {

    val store : BlueprintNodeStore

    /**
     * Register [BlueprintNode] to be displayed in element selection window.
     * */
    fun register(
        category: BlueprintNodeCategory,
        vararg records: BlueprintNodeStoreRecord<*>
    )

    fun unregister(
        category: BlueprintNodeCategory
    )

    companion object : BlueprintNodeStoreOwner {

        private val pool = SnapshotStateMap<BlueprintNodeCategory, MutableList<BlueprintNodeStoreRecord<*>>>()

        override val store: BlueprintNodeStore = object : BlueprintNodeStore {
            override val pool
                get() = this@Companion.pool
        }

        override fun register(
            category: BlueprintNodeCategory,
            vararg records: BlueprintNodeStoreRecord<*>
        ) {
            if (pool.containsKey(category)) {
                pool[category]?.addAll(records)
            } else pool[category] = records.toMutableList()
        }

        override fun unregister(category: BlueprintNodeCategory) {
            pool.remove(category)
        }
    }
}

/**
 * Create serializers module that includes all nodes that was previously registered to [store]
 * */

fun BlueprintNodeStore.serializersModule() = SerializersModule {
    polymorphic(BlueprintNode::class) {
        pool.values.forEach {
            it.forEach { it.serializer?.invoke(this) }
        }
    }
}