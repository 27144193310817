package org.botdesigner.botblueprints

import org.botdesigner.blueprint.components.PinFactory
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable

//enum class BlueprintVariableVisibility(val msg : String) {
//    Public("Value of this variable will be visible to anyone and mutable"),
//    Private("""
//        Value of this variable will not be visible or mutable.
//        It can be retrieved only during the cloud execution! For local execution it will be replaced with NULL value.
//        Suitable for secret API keys and passwords
//    """.trimIndent()),
//    Protected("""
//        Value of this variable will not be visible directly or mutable.
//        People will not see the value from the app but can potentially hack it.
//        Suitable for not so secret keys
//    """.trimIndent());
//}

@Serializable
class BlueprintVariableData(
    val name : String,
    val mutable : Boolean,
    val factory : PinFactory<@Contextual Any?>,
    val value : String?
)
