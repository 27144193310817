package org.botdesigner.ui.common

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.spring
import androidx.compose.animation.fadeIn
import androidx.compose.animation.scaleIn
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import androidx.compose.ui.window.DialogProperties
import io.github.alexzhirkevich.cupertino.Surface
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveIconButton
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.icons.CupertinoIcons
import io.github.alexzhirkevich.cupertino.icons.outlined.XmarkCircle
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import org.botdesigner.resources.SharedRes
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable


actual class PlatformDialogState(
    initiallyVisible : Boolean = false
) {

     var visible by mutableStateOf(initiallyVisible)
    actual val isVisible : Boolean get() = visible

    actual suspend fun show() {
        visible = true
    }

    actual suspend fun hide() {
        visible = false
    }

    companion object {
        fun Saver() = androidx.compose.runtime.saveable.Saver<PlatformDialogState, Boolean>(
            save = { it.visible },
            restore = {
                PlatformDialogState(it)
            }
        )
    }
}

@Composable
internal actual fun rememberPlatformDialogState(
    initiallyExpanded : Boolean
) : PlatformDialogState {
    return rememberSaveable(saver = PlatformDialogState.Saver()) {
        PlatformDialogState(initiallyExpanded)
    }
}

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal actual fun PlatformDialogCloseButton(
    onClick : () -> Unit
){
    AdaptiveIconButton(
        modifier = Modifier
            .pointerHoverIcon(PointerIcon.PlatformClickable),
        onClick = onClick
    ){
        Icon(
            imageVector = CupertinoIcons.Default.XmarkCircle,
            contentDescription = string(SharedRes.strings.close)
        )
    }
}

private val EnterAnimation =  scaleIn(
    initialScale = .9f,
    animationSpec = spring(
        stiffness = Spring.StiffnessMediumLow,
        dampingRatio = Spring.DampingRatioMediumBouncy
    )
) + fadeIn(
    initialAlpha = .5f,
    animationSpec = spring(stiffness = Spring.StiffnessMediumLow)
)

@Composable
actual fun containerBackground() : Color = MaterialTheme.colorScheme.background

actual val PlatformDialogAnimationDuration : Int get() = 100

@Composable
internal actual fun PlatformDialogContainer(
    modifier: Modifier,
    state: PlatformDialogState,
    unboundHeight : Boolean,
    unboundWidth : Boolean,
    onDismissRequest : () -> Unit,
    dialog : @Composable () -> Unit,
    content : @Composable () -> Unit
) {
    Box(modifier) {
        content()

        if (state.visible) {
            Dialog(
                properties = DialogProperties(
                    usePlatformDefaultWidth = !unboundWidth,
                    dismissOnClickOutside = false,
                ),
                onDismissRequest = {
                    state.visible = false
                    onDismissRequest()
                },
                content = {
                    var visible by rememberSaveable {
                        mutableStateOf(false)
                    }
                    LaunchedEffect(0) {
                        visible = true
                    }

                    AnimatedVisibility(
                        visible = visible,
                        enter = EnterAnimation,
                    ) {
                        Surface(
                            modifier = Modifier
                                .heightIn(max = if (unboundHeight) Dp.Infinity else 650.dp)
                                .padding(Dimens.Spacing.ExtraLarge),
                            shape = CupertinoTheme.shapes.large,
                            shadowElevation = Dimens.TonalElevation.Level3,
                            color = containerBackground(),
                        ) {
                            dialog()
                        }
                    }
                }
            )
        }
    }
}