package org.botdesigner.blueprint.stdlib.pins

import androidx.compose.ui.graphics.Color
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.components.ArrayPin
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.components.PinsDirectionalScope

@kotlinx.serialization.Serializable
@SerialName("FloatArrayPinFactory")
object DoubleArrayPinFactory : PinFactory<Iterable<Double>> {
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = DoubleArrayPin(
        id = id,
        order = order,
        elId = elementId,
        isOut = isOut,
        name = name,
        required = required
    )
}

@kotlinx.serialization.Serializable
@SerialName("FloatArrayPin")
data class DoubleArrayPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val isOut: Boolean,
    override val name: String = "",
    override val value: Iterable<Double>? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : ArrayPin<Double>() {

    override val childPinFactory: PinFactory<Double>
        get() = DoublePinFactory

    override val color: Color
        get() = BlueprintColors.Float

    override fun withReference(parent: Pin<*>?): DoubleArrayPin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun withValue(value: String): DoubleArrayPin {
        return copy(value = null, parentId = null, parentPinId = null)
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<Iterable<Double>> {
        return DoubleArrayPinFactory
    }
}

fun PinsDirectionalScope.doubleArray(name: String = "", order : UInt? = null) {
    pin { o, i ->
        DoubleArrayPin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
        )
    }
}