@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.telegram.functions

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Pair
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext

@Serializable
@SerialName("BpTgAwaitPhoto")
public class BpTgAwaitPhoto(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "User Id",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Timeout",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.botblueprints.telegram.pins.MessagePinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("")
          output("Timed Out")
      },
) : BpProcedure<@Contextual TelegramBotBlueprintContext>() {
  override val summary: AnnotatedString
    get() = """
    |
    |        Wait for next photo from user. For private chats and groups only.
    |        In group chats set <b>User Id</b> pin to the id of user you want to wait photo from. In private chats this pin can be ignored.
    |        If <b>Timeout</b> > 0 and no photo was received after <b>Timeout</b> seconds then <b>Timed Out</b> block is called and waiting stops.
    |        
    """.trimMargin().trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4280696141)

  override val name: String
    get() = "Await Next Photo"

  override val factory: ProcedureFactory<@Contextual TelegramBotBlueprintContext>
    get() = ::BpTgAwaitPhoto

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual TelegramBotBlueprintContext,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(TgAwaitPhoto(
  		input[0] as kotlin.Long?,
  		input[1] as kotlin.Long?,
  		{
      return outputConnectors[1] to listOf(it)
  }
  	))}
}
