package org.botdesigner.ui.common.images

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush.Companion.linearGradient
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

internal val Images.LogoGear: ImageVector
    get() {
        if (_logoLightPurple != null) {
            return _logoLightPurple!!
        }
        _logoLightPurple = Builder(name = "LogoGear", defaultWidth = 717.0.dp, defaultHeight
                = 703.0.dp, viewportWidth = 717.0f, viewportHeight = 703.0f).apply {
            path(fill = linearGradient(0.0f to Color(0xFFC66DD7), 1.0f to Color(0xFF3224AF), start =
                    Offset(357.835f,13.53f), end = Offset(357.835f,675.78f)), stroke = null,
                    strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = NonZero) {
                moveTo(338.5f, 674.4f)
                curveTo(323.03f, 672.84f, 306.51f, 670.72f, 303.84f, 669.95f)
                curveTo(298.63f, 668.46f, 293.27f, 664.32f, 290.66f, 659.77f)
                curveTo(289.31f, 657.42f, 282.44f, 642.07f, 275.38f, 625.66f)
                curveTo(271.302f, 615.79f, 264.66f, 597.682f, 263.146f, 596.05f)
                curveTo(261.511f, 594.122f, 250.056f, 588.768f, 247.754f, 588.749f)
                curveTo(245.676f, 588.735f, 183.43f, 614.74f, 180.64f, 615.41f)
                curveTo(174.84f, 616.79f, 167.48f, 614.23f, 158.74f, 607.77f)
                curveTo(139.37f, 593.48f, 116.14f, 570.87f, 99.88f, 550.5f)
                curveTo(89.07f, 536.95f, 88.45f, 535.95f, 87.09f, 529.91f)
                curveTo(85.64f, 523.46f, 86.76f, 519.75f, 100.4f, 485.52f)
                curveTo(104.513f, 475.207f, 112.717f, 456.916f, 112.74f, 454.58f)
                curveTo(112.717f, 452.031f, 108.044f, 441.073f, 106.47f, 439.6f)
                curveTo(104.847f, 438.041f, 84.817f, 430.227f, 73.99f, 425.54f)
                curveTo(38.35f, 410.12f, 35.7f, 408.44f, 32.58f, 399.28f)
                curveTo(26.18f, 380.51f, 26.91f, 299.97f, 33.59f, 286.75f)
                curveTo(35.78f, 282.43f, 38.84f, 279.15f, 42.5f, 277.24f)
                curveTo(44.15f, 276.38f, 59.23f, 269.7f, 76.0f, 262.39f)
                curveTo(86.167f, 257.967f, 104.913f, 250.317f, 106.5f, 249.12f)
                curveTo(107.896f, 247.854f, 113.015f, 236.0f, 113.01f, 234.14f)
                curveTo(112.991f, 232.09f, 104.563f, 212.927f, 100.34f, 202.32f)
                curveTo(86.0f, 166.3f, 84.98f, 161.94f, 89.01f, 153.92f)
                curveTo(94.83f, 142.37f, 122.36f, 111.72f, 141.89f, 95.05f)
                curveTo(163.78f, 76.37f, 168.52f, 73.63f, 177.9f, 74.18f)
                curveTo(183.49f, 74.51f, 189.28f, 76.47f, 215.79f, 86.96f)
                curveTo(226.217f, 91.087f, 244.476f, 99.339f, 247.07f, 99.34f)
                curveTo(249.649f, 99.37f, 260.93f, 94.078f, 262.17f, 93.11f)
                curveTo(263.551f, 91.931f, 273.284f, 68.433f, 278.841f, 56.094f)
                curveTo(285.578f, 41.239f, 287.272f, 36.526f, 291.55f, 28.3f)
                curveTo(294.3f, 24.08f, 298.53f, 20.94f, 303.43f, 19.48f)
                curveTo(317.78f, 15.19f, 366.95f, 13.53f, 393.28f, 16.46f)
                curveTo(412.78f, 18.62f, 415.58f, 19.48f, 420.97f, 24.92f)
                curveTo(424.85f, 28.85f, 426.91f, 32.78f, 435.4f, 52.5f)
                curveTo(441.571f, 66.309f, 452.11f, 92.147f, 453.914f, 93.926f)
                curveTo(455.735f, 95.687f, 466.413f, 99.242f, 468.66f, 99.0f)
                curveTo(471.192f, 98.764f, 490.327f, 90.76f, 501.16f, 86.64f)
                curveTo(518.4f, 79.84f, 533.79f, 73.97f, 535.36f, 73.59f)
                curveTo(540.62f, 72.33f, 548.51f, 74.79f, 555.24f, 79.79f)
                curveTo(582.23f, 99.84f, 616.9f, 135.63f, 626.25f, 153.09f)
                curveTo(631.08f, 162.12f, 630.7f, 163.9f, 616.0f, 201.19f)
                curveTo(611.667f, 212.18f, 603.021f, 232.027f, 603.0f, 234.16f)
                curveTo(603.049f, 236.054f, 607.916f, 247.717f, 609.5f, 249.16f)
                curveTo(611.104f, 250.526f, 630.833f, 258.353f, 641.5f, 262.95f)
                curveTo(677.73f, 278.56f, 680.64f, 280.45f, 683.43f, 290.18f)
                curveTo(687.05f, 302.82f, 689.49f, 348.06f, 687.63f, 368.0f)
                curveTo(685.17f, 394.25f, 684.08f, 399.57f, 679.86f, 405.7f)
                curveTo(676.43f, 410.68f, 674.01f, 411.92f, 635.1f, 428.59f)
                curveTo(626.658f, 432.07f, 611.038f, 437.967f, 609.774f, 439.031f)
                curveTo(608.508f, 440.056f, 603.333f, 452.836f, 603.304f, 454.691f)
                curveTo(603.307f, 456.659f, 612.121f, 476.89f, 616.53f, 487.99f)
                curveTo(630.81f, 523.97f, 631.39f, 526.51f, 627.21f, 535.0f)
                curveTo(620.7f, 548.22f, 587.47f, 583.7f, 564.5f, 601.95f)
                curveTo(542.48f, 619.44f, 543.86f, 619.4f, 504.0f, 603.51f)
                curveTo(491.942f, 598.969f, 470.073f, 589.901f, 467.826f, 589.886f)
                curveTo(465.754f, 589.888f, 451.199f, 596.129f, 450.239f, 597.28f)
                curveTo(449.309f, 598.559f, 443.08f, 617.56f, 439.5f, 627.7f)
                curveTo(431.8f, 645.27f, 424.45f, 661.09f, 423.17f, 662.85f)
                curveTo(420.69f, 666.23f, 415.48f, 669.34f, 410.5f, 670.39f)
                curveTo(396.71f, 673.29f, 352.13f, 675.78f, 338.5f, 674.4f)
                close()
            }
        }
        .build()
        return _logoLightPurple!!
    }

private var _logoLightPurple: ImageVector? = null
