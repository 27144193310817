package org.botdesigner.shared.domain.content.blueprint.store

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.blueprint.store.BlueprintNodeStoreRecord
import org.botdesigner.blueprint.store.BlueprintStoreManager
import org.botdesigner.blueprint.store.BlueprintStoreManagerImpl
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler


internal class DefaultBlueprintStoreComponent(
    context : ComponentContext,
    errorHandler: ErrorHandler,
    private val onClose : () -> Unit,
    private val onAdd : (BlueprintNodeStoreRecord<*>) -> Unit
) : CoroutineComponent(context, errorHandler), BlueprintStoreComponent {

    override val manager: BlueprintStoreManager = instanceKeeper
        .getOrCreateSimple(keyFor(::manager)) {
            BlueprintStoreManagerImpl(BlueprintNodeStoreOwner.store, componentScope)
        }

    override fun onCloseClicked() = onClose()

    override fun onNodeSelected(node : BlueprintNodeStoreRecord<*>) = onAdd(node)
}