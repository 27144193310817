package org.botdesigner.core.resources.images

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush.Companion.linearGradient
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Logos.Telegram: ImageVector
    get() {
        if (telegram != null) {
            return telegram!!
        }
        telegram = Builder(name = "Telegram", defaultWidth = 64.0.dp, defaultHeight =
                64.0.dp, viewportWidth = 512.0f, viewportHeight = 512.0f).apply {
            path(fill = linearGradient(0.0f to Color(0xFF41BCE7), 1.0f to Color(0xFF22A6DC), start =
                    Offset(256.0f,0.0f), end = Offset(256.0f,510.13f)), stroke = null,
                    strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = NonZero) {
                moveTo(256.0f, 256.0f)
                moveToRelative(-256.0f, 0.0f)
                arcToRelative(256.0f, 256.0f, 0.0f, true, true, 512.0f, 0.0f)
                arcToRelative(256.0f, 256.0f, 0.0f, true, true, -512.0f, 0.0f)
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(380.6f, 147.3f)
                lineToRelative(-45.7f, 230.5f)
                curveToRelative(0.0f, 0.0f, -6.4f, 16.0f, -24.0f, 8.3f)
                lineToRelative(-105.5f, -80.9f)
                lineTo(167.0f, 286.7f)
                lineToRelative(-64.6f, -21.7f)
                curveToRelative(0.0f, 0.0f, -9.9f, -3.5f, -10.9f, -11.2f)
                curveToRelative(-1.0f, -7.7f, 11.2f, -11.8f, 11.2f, -11.8f)
                lineToRelative(256.8f, -100.7f)
                curveTo(359.5f, 141.2f, 380.6f, 131.9f, 380.6f, 147.3f)
                close()
            }
            path(fill = SolidColor(Color(0xFFD2E4F0)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(197.2f, 375.2f)
                curveToRelative(0.0f, 0.0f, -3.1f, -0.3f, -6.9f, -12.4f)
                curveToRelative(-3.8f, -12.1f, -23.3f, -76.1f, -23.3f, -76.1f)
                lineToRelative(155.1f, -98.5f)
                curveToRelative(0.0f, 0.0f, 9.0f, -5.4f, 8.6f, 0.0f)
                curveToRelative(0.0f, 0.0f, 1.6f, 1.0f, -3.2f, 5.4f)
                curveToRelative(-4.8f, 4.5f, -121.8f, 109.7f, -121.8f, 109.7f)
            }
            path(fill = SolidColor(Color(0xFFB5CFE4)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(245.8f, 336.2f)
                lineToRelative(-41.7f, 38.1f)
                curveToRelative(0.0f, 0.0f, -3.3f, 2.5f, -6.8f, 0.9f)
                lineToRelative(8.0f, -70.7f)
            }
        }
        .build()
        return telegram!!
    }

private var telegram: ImageVector? = null
