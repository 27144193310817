package org.botdesigner.botblueprints

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ForwardToInbox
import androidx.compose.material.icons.filled.Update
import androidx.compose.material.icons.outlined.Campaign
import androidx.compose.material.icons.outlined.Edit
import androidx.compose.material.icons.outlined.Face
import androidx.compose.material.icons.outlined.GroupAdd
import androidx.compose.material.icons.outlined.Image
import androidx.compose.material.icons.outlined.MarkEmailUnread
import androidx.compose.material.icons.outlined.Mic
import androidx.compose.material.icons.outlined.PersonAdd
import androidx.compose.material.icons.outlined.Terminal
import androidx.compose.material.icons.outlined.UploadFile
import androidx.compose.material.icons.outlined.Videocam
import androidx.compose.ui.graphics.vector.ImageVector
import dev.icerock.moko.resources.StringResource
import org.botdesigner.api.SubscriptionType
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.Id
import org.botdesigner.botblueprints.scheduler.BpScheduled
import org.botdesigner.botblueprints.telegram.blueprints.ChannelPostedBlueprint
import org.botdesigner.botblueprints.telegram.blueprints.ChatJoinRequestBlueprint
import org.botdesigner.botblueprints.telegram.blueprints.MessageReceivedBlueprint
import org.botdesigner.botblueprints.telegram.functions.BpTgChannelPostEdited
import org.botdesigner.botblueprints.telegram.functions.BpTgCommandReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgDocumentReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgImagesReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgMessageEdited
import org.botdesigner.botblueprints.telegram.functions.BpTgNewChatMembers
import org.botdesigner.botblueprints.telegram.functions.BpTgStickerReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgVideoNoteReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgVideoReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgVoiceReceived
import org.botdesigner.core.BotType
import org.botdesigner.core.MR

//private object BlueprintTypeColors {
//    object Light {
////        val Message = Color(0xFFE6DFF1)
////        val Chat = Color(0xFFC0DEDC)
////        val Edit = Color(0xFFF1EEE9)
//        val Message = Color(0xFFE6DFF1)
//        val Chat = Color(0xFFC0DEDC)
//        val Edit = Color(0xFFF1EEE9)
//    }
//
//    object Dark {
////        val Message = Color(0xFFE6DFF1)
////        val Chat = Color(0xFFC0DEDC)
////        val Edit = Color(0xFFF1EEE9)
//        val Message = Color(0xFFE6DFF1).compositeOver(Color.Black)
//        val Chat = Color(0xFFC0DEDC).compositeOver(Color.Black)
//        val Edit = Color(0xFFF1EEE9).compositeOver(Color.Black)
//    }
//}

enum class BlueprintType(
    val title: StringResource,
    val description : StringResource,
    val icon: ImageVector,
    val bots: List<BotType>,
    val multiple : Boolean = false,
    val contextName : StringResource? = null,
    val minSubLevel : SubscriptionType = SubscriptionType.Free,
) : BlueprintFactory {

    Scheduled(
        title = MR.strings.scheduled,
        description = MR.strings.scheduled_desc,
        icon = Icons.Default.Update,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        minSubLevel = SubscriptionType.Pro,
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpScheduled())
            )
        }
    },


    MessageReceived(
        title = MR.strings.message_received,
        description = MR.strings.message_received_desc,
        icon = Icons.Default.ForwardToInbox,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return MessageReceivedBlueprint()
        }
    },

    CommandReceived(
        title = MR.strings.command_received,
        description = MR.strings.command_received_desc,
        icon = Icons.Outlined.Terminal,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        multiple = true,
        contextName = MR.strings.command
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgCommandReceived())
            )
        }
    },

    ImageReceived(
        title = MR.strings.image_received,
        description = MR.strings.image_received_desc,
        icon = Icons.Outlined.Image,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgImagesReceived())
            )
        }
    },

    VideoReceived(
        title = MR.strings.video_received,
        description = MR.strings.video_received_desc,
        icon = Icons.Outlined.UploadFile,
//        lightColor = BlueprintTypeColors.Light.Message,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgVideoReceived())
            )
        }
    },

    DocumentReceived(
        title = MR.strings.document_received,
        description = MR.strings.document_received_desc,
        icon = Icons.Outlined.Face,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgDocumentReceived())
            )
        }
    },

    StickerReceived(
        title = MR.strings.sticker_received,
        description = MR.strings.sticker_received_desc,
        icon = Icons.Outlined.Face,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgStickerReceived())
            )
        }
    },


    VoiceReceived(
        title = MR.strings.voice_received,
        description = MR.strings.voice_received_desc,
        icon = Icons.Outlined.Mic,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgVoiceReceived())
            )
        }
    },

    VideoNoteReceived(
        title = MR.strings.videonote_received,
        description = MR.strings.videonote_received_desc,
        icon = Icons.Outlined.Videocam,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgVideoNoteReceived())
            )
        }
    },

    MessageEdited(
        title = MR.strings.message_edited,
        description = MR.strings.message_edited_desc,
        icon = Icons.Outlined.MarkEmailUnread,
//        lightColor = BlueprintTypeColors.Light.Edit,
//        darkColor = BlueprintTypeColors.Dark.Edit,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgMessageEdited())
            )
        }
    },

    ChatJoinRequest(
        title = MR.strings.chat_join_requested,
        description = MR.strings.chat_join_requested_desc,
        icon = Icons.Outlined.GroupAdd,
//        lightColor = BlueprintTypeColors.Light.Chat,
//        darkColor = BlueprintTypeColors.Dark.Chat,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return ChatJoinRequestBlueprint(Id(id))
        }
    },

    ChannelPosted(
        title = MR.strings.channel_posted,
        description = MR.strings.channel_posted_desc,
        icon = Icons.Outlined.Campaign,
//        lightColor = BlueprintTypeColors.Light.Message,
//        darkColor = BlueprintTypeColors.Dark.Message,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return ChannelPostedBlueprint(Id(id))
        }
    },

    ChannelPostEdited(
        title = MR.strings.channel_post_edited,
        description = MR.strings.channel_post_edited_desc,
        icon = Icons.Outlined.Edit,
//        lightColor = BlueprintTypeColors.Light.Edit,
//        darkColor = BlueprintTypeColors.Dark.Edit,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgChannelPostEdited())
            )
        }
    },
    NewChatMembers(
        title = MR.strings.new_chat_members,
        description = MR.strings.new_chat_members_desc,
        icon = Icons.Outlined.PersonAdd,
//        lightColor = BlueprintTypeColors.Light.Chat,
//        darkColor = BlueprintTypeColors.Dark.Edit,
        bots = listOf(BotType.Telegram),
        multiple = false
    ) {
        override fun blueprint(id: String, context: String, type: BotType): Blueprint {
            return Blueprint(
                elements = listOf(BpTgNewChatMembers())
            )
        }
    },
}
