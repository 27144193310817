package org.botdesigner.blueprint

import androidx.compose.runtime.Stable
import androidx.compose.runtime.State
import androidx.compose.ui.geometry.Offset
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point

typealias PinLinesMap = Map<Pair<Pin<*>, Pin<*>>, Pair<State<Point>, State<Point>>>
typealias ConnectorLinesMap = Map<Pair<Connector, Connector>, Pair<State<Point>, State<Point>>>

/**
 * Manage blueprint dynamic state.
 * */
@Stable
interface BlueprintManager : BlueprintNodesPool {

    val scale : Float

    val translation : Offset

    /**
     * Displayed blueprint elements
     * */
    val components : Map<Id, BlueprintNodeStateHolder<*>>

    val pinLines : PinLinesMap

    val connectorLines : ConnectorLinesMap

    val selectedConnector : State<Connector?>

    val selectedPin : State<Pin<*>?>

    val isDragEnabled : Boolean

    val isScaleEnabled : Boolean

    fun onDensityScaleChanged(densityScale: Float)

    fun onDrag(delta : Offset, scaledFieldSize: Float)

    fun onScale(delta : Float)

    /**
     * Must be called from the main thread!
     * */
    fun load(blueprint: Blueprint)

    /**
     * Add new component to the blueprint.
     *
     * @return true if component was added successfully, false if component with such id
     * is already exists
     * */
    fun addNode(component : BlueprintNode) : Boolean

    fun deleteNode(id : Id)

    fun onPinClicked(pin: Pin<*>)

    fun onPinAdded(pin: Pin<*>)

    fun onPinRemoved(pin: Pin<*>)

    fun resetPin(pin: Pin<*>)

    fun onConnectorRemoved(connector: Connector)

    fun onConnectorAdded(connector: Connector)

    fun onConnectorClicked(c : Connector)

    fun onComponentTap(component: BlueprintNodeStateHolder<*>)
    
    fun onComponentDragStarted(component: BlueprintNodeStateHolder<*>) {}

    fun onComponentDragEnded(component: BlueprintNodeStateHolder<*>) {}

    fun clearSelection()

    /**
     * Save current blueprint state.
     *
     * Must be called from UI thread
     * */
    fun save() : Blueprint
}

