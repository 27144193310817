package org.botdesigner.shared.domain.content.bot.bptype

import com.arkivanov.decompose.ComponentContext
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.botblueprints.BlueprintType
import org.botdesigner.botblueprints.telegram.TelegramSerializersModule
import org.botdesigner.core.BotType

class DefaultBlueprintTypeSelectionDialogComponent(
    context : ComponentContext,
    override val blueprintTypes: List<Pair<BlueprintType, Boolean>>,
    private val botType : BotType,
    private val onRequestContext : (BlueprintData) -> Unit,
    private val onCreateBlueprint : (BlueprintData) -> Unit,
    private val onCancel : () -> Unit
) : BlueprintTypeSelectionDialogComponent, ComponentContext by context {

    private val json = Json {
        serializersModule = TelegramSerializersModule
    }

    override fun onSelected(blueprint: BlueprintData) {
        val bp = blueprint.copy(
            blueprint = json.encodeToString(
                blueprint.type.blueprint(blueprint.id, "", botType)
            ),
        )

        if (blueprint.type.contextName != null) {
            onRequestContext(bp)
        } else {
            onCreateBlueprint(bp)
        }
    }

    override fun onCancel() {
        onCancel.invoke()
    }
}