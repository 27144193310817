package org.botdesigner.ui.theme

import androidx.compose.material3.ColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import com.materialkolor.dynamicColorScheme
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.systemPurple

@Composable
internal actual fun systemMaterialTheme(dark : Boolean) : ColorScheme {
    return dynamicColorScheme(
        seedColor = systemAccent(dark),
        isDark = dark
    )
}

@Composable
internal actual fun systemAccent(dark : Boolean) : Color {
    return CupertinoColors.systemPurple(dark)
}

@Composable

internal actual fun setWindowBackground(color: Color) {}

