@file: Suppress("INVISIBLE_MEMBER", "INVISIBLE_REFERENCE")

package org.botdesigner.ui.common

import androidx.compose.foundation.gestures.Orientation
import androidx.compose.ui.input.pointer.BrowserCursor
import androidx.compose.ui.input.pointer.PointerIcon

internal actual fun splitPaneSeparatorHoverIcon(
    orientation: Orientation
) : PointerIcon = BrowserCursor(
    if (orientation == Orientation.Horizontal)
        "ew-resize" else "ns-resize"
)

