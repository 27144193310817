
package org.botdesigner.ui.screens.blueprint

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.navigationBarsPadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ExpandLess
import androidx.compose.material.icons.filled.ExpandMore
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ScrollableTabRow
import androidx.compose.material3.Tab
import androidx.compose.material3.TabRowDefaults
import androidx.compose.material3.TabRowDefaults.tabIndicatorOffset
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import io.github.alexzhirkevich.cupertino.CupertinoSearchTextField
import io.github.alexzhirkevich.cupertino.CupertinoSearchTextFieldDefaults
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveHorizontalDivider
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.rememberCupertinoSearchTextFieldState
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filter
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.store.BlueprintNodeStoreRecord
import org.botdesigner.blueprint.store.BlueprintStoreManager
import org.botdesigner.blueprint.ui.components.highlightBold
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.blueprint.store.BlueprintStoreComponent
import org.botdesigner.ui.common.PlatformDialogTopBar
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

private val HorizontalPadding = 12.dp
private val VerticalPadding = 8.dp

private enum class ContentTypes {
    Subtitle, Item
}

@OptIn(
    ExperimentalMaterial3Api::class,
    ExperimentalCupertinoApi::class,
    ExperimentalAdaptiveApi::class
)
@Composable
fun BlueprintStoreScreen(
    component: BlueprintStoreComponent,
    modifier: Modifier = Modifier,
) {

    val searchTextFieldState = rememberCupertinoSearchTextFieldState()

    val lazyListState = rememberLazyListState()

    val focus = LocalFocusManager.current


    LaunchedEffect(lazyListState, searchTextFieldState) {
        snapshotFlow {
            lazyListState.isScrollInProgress && searchTextFieldState.isFocused
        }.filter { it }.distinctUntilChanged().collect {
            focus.clearFocus(force = true)
        }
    }
    AdaptiveScaffold(
        modifier = modifier,
        containerColor = Color.Transparent,
        topBar = {
            PlatformDialogTopBar(
                title = {
                    Text(string(SharedRes.strings.add_node))
                },
                onDismissRequest = component::onCloseClicked,
                isTransparent = true
            )
        }
    ) {
        BlueprintStoreWidget(
            modifier = Modifier
                .padding(it)
                .padding(top = Dimens.Spacing.Medium),
            lazyListState = lazyListState,
            manager = component.manager,
            searchField = {
                val interactionSource = remember { MutableInteractionSource() }
                CupertinoSearchTextField(
                    state = searchTextFieldState,
                    interactionSource = interactionSource,
                    value = component.manager.search,
                    onValueChange = component.manager::onSearch,
                    cancelButton = {
                        CupertinoSearchTextFieldDefaults.cancelButton(
                            onValueChange = component.manager::onSearch,
                            interactionSource = interactionSource
                        )
                    }
                )
            },
            onSelected = component::onNodeSelected
        )
    }
}

@OptIn(ExperimentalFoundationApi::class, ExperimentalAdaptiveApi::class)
@Composable
fun BlueprintStoreWidget(
    modifier: Modifier = Modifier,
    manager: BlueprintStoreManager,
    lazyListState: LazyListState = rememberLazyListState(),
    searchField : @Composable () -> Unit = {},
    onSelected: (record: BlueprintNodeStoreRecord<BlueprintNode>) -> Unit,
) {

    Column(modifier) {

        searchField()

        Spacer(Modifier.height(12.dp))
        HorizontalDivider()

        val selectedItemsList by manager.selectedElements

        val selectedTabIndex by remember(manager) {
            derivedStateOf {
                selectedItemsList.keys
                    .indexOf(manager.selectedCategory)
                    .takeIf { it >= 0 }
                    ?: 0
            }
        }


        if (selectedItemsList.isNotEmpty()) {
            Box {
                ScrollableTabRow(
                    selectedTabIndex = selectedTabIndex,
                    edgePadding = 0.dp,
                    modifier = Modifier.fillMaxWidth(),
                    indicator = {
                        TabRowDefaults.SecondaryIndicator(
                            Modifier.tabIndicatorOffset(
                                // required as search is performed in background thread and
                                // tab index is not synced with displayed tabs count
                                it[selectedTabIndex.coerceIn(it.indices)]
                            )
                        )
                    },
                    divider = {
                        AdaptiveHorizontalDivider()
                    }
                ) {
                    selectedItemsList.forEach {
                        Tab(
                            selected = manager.selectedCategory == it.key,
                            onClick = {
                                manager.onCategorySelected(it.key)
                            }
                        ) {
                            Text(
                                text = it.key,
                                modifier = Modifier.padding(
                                    horizontal = HorizontalPadding,
                                    vertical = VerticalPadding * 2
                                )
                            )
                        }
                    }
                }
                AdaptiveHorizontalDivider(Modifier.align(Alignment.BottomCenter))
            }

            LazyColumn(
                modifier = Modifier.weight(1f),
                state = lazyListState
            ) {

                val category = manager.selectedCategory

                selectedItemsList[category]?.forEach { (subcategory, recs) ->
                    val isExpanded = manager.selectedSubcategories[category]
                        ?.containsKey(subcategory) == true

                    stickyHeader(
                        contentType = ContentTypes.Subtitle
                    ) {

                        Column(
                            modifier = Modifier.background(MaterialTheme.colorScheme.surface)
                        ) {
                            Row(
                                modifier = Modifier
                                    .clickable {
                                        manager.onSubcategoryClicked(category, subcategory)
                                    }
                                    .fillMaxWidth()
                                    .padding(
                                        vertical = VerticalPadding,
                                        horizontal = HorizontalPadding
                                    ),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.spacedBy(VerticalPadding)
                            ) {
                                Text(
                                    text = subcategory,
                                    modifier = Modifier
                                )
                                Spacer(Modifier.weight(1f))
                                if (manager.search.isBlank()) {
                                    Icon(
                                        imageVector = if (isExpanded)
                                            Icons.Default.ExpandLess
                                        else Icons.Default.ExpandMore,
                                        contentDescription = null
                                    )
                                }
                            }
                        }
                        AdaptiveHorizontalDivider()
                    }

                    if (manager.search.isNotBlank() || isExpanded)

                        items(
                            items = recs,
                            contentType = { ContentTypes.Item },
                            key = { it.second.id }
                        ) {
                            Column {
                                Record(
                                    instance = it.second
                                ) {
                                    onSelected(it.first)
                                }
                                AdaptiveHorizontalDivider(Modifier.padding(start = HorizontalPadding))
                            }
                        }
                }
                item {
                    Spacer(
                        modifier = Modifier
                            .navigationBarsPadding()
                            .imePadding()
                    )
                }
            }
        } else {
            Box(
                modifier = Modifier.fillMaxSize(),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "No elements matching query",
                    textAlign = TextAlign.Center
                )
            }
        }
    }
}



@Composable
private fun Record(
    instance: BlueprintNode,
    modifier: Modifier = Modifier,
    onClick: () -> Unit,
) {
    Row(
        modifier = modifier
            .clickable(onClick = onClick)
            .fillMaxWidth()
            .padding(vertical = 6.dp, horizontal = HorizontalPadding),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Column(
            modifier = Modifier
                .weight(1f)
                .padding(vertical = 6.dp),
            verticalArrangement = Arrangement.spacedBy(6.dp)
        ) {
            Text(
                text = instance.name,
                fontWeight = FontWeight.Bold,
                style = MaterialTheme.typography.bodyLarge
            )
            if (instance.summary.isNotBlank()) {

                Text(
                    text = instance.summary.highlightBold(),
                    style = MaterialTheme.typography.labelMedium,
                    modifier = Modifier.alpha(.5f)
                )
            }
        }
    }
}
