package org.botdesigner.shared.domain.content.tabs

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.pages.ChildPages
import com.arkivanov.decompose.router.pages.Pages
import com.arkivanov.decompose.router.pages.PagesNavigation
import com.arkivanov.decompose.router.pages.childPages
import com.arkivanov.decompose.router.pages.select
import com.arkivanov.decompose.value.Value
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn
import kotlinx.serialization.Serializable
import org.botdesigner.core.Bot
import org.botdesigner.shared.data.repo.BotsRepository
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.content.ContentComponent
import org.botdesigner.shared.domain.content.list.DefaultBotListComponent
import org.botdesigner.shared.domain.content.profile.DefaultProfileComponent
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.intents.SendEmailIntent
import org.botdesigner.shared.util.valueOrNull

@OptIn(ExperimentalDecomposeApi::class)
internal class DefaultTabsComponent(
    context: ComponentContext,
    private val topComponent: MutableStateFlow<ContentComponent.Child<*>?>,
    private val onBotDeleted: (Bot) -> Unit,
    private val onNavigateToBot: (Bot) -> Unit,
    private val onNavigateToEditBot: (Bot) -> Unit,
    private val onNavigateToPremium: () -> Unit,
    private val onNavigateToCreateBot: () -> Unit,
    private val onNavigateToDevices: () -> Unit,
    private val onNavigateToAppearance: () -> Unit,
    private val onNavigateToSettings: () -> Unit,
    private val onNavigateToAccount: () -> Unit,
    private val onLogout: () -> Unit,
    private val errorHandler: ErrorHandler,
    private val dispatchers: Dispatchers,
    private val botsRepository: BotsRepository,
    private val userRepository: UserRepository,
    private val sendEmailIntent: SendEmailIntent
) : CoroutineComponent(context,errorHandler), TabsComponent {

    @OptIn(ExperimentalDecomposeApi::class)
    private val navigation = PagesNavigation<TabConfig>()

    override val tabs: Value<ChildPages<*, TabsComponent.Tab<*>>> = childPages(
        source = navigation,
        serializer = TabConfig.serializer(),
        initialPages = {
            Pages(
                items = listOf(
                    TabConfig.BotList,
                    TabConfig.Profile,
                ),
                selectedIndex = 0
            )
        },
        childFactory = ::childFactory,
    )

    override fun onTabSelected(index : Int) {
        navigation.select(index)
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    private fun childFactory(config: TabConfig, context: ComponentContext) : TabsComponent.Tab<*> {
        return when (config) {
            is TabConfig.BotList -> TabsComponent.Tab.BotList(
                DefaultBotListComponent(
                    context = context,
                    onNavigateToBot = onNavigateToBot,
                    onNavigateToEdit = onNavigateToEditBot,
                    onNavigateToCreate = onNavigateToCreateBot,
                    onBotDeleted = onBotDeleted,
                    errorHandler = errorHandler,
                    dispatchers = dispatchers,
                    selectedBot = topComponent.flatMapLatest {
                        if (it is ContentComponent.Child.Bot) {
                            it.component.state.map { it.bot.valueOrNull() }
                        } else {
                            flowOf(null)
                        }
                    }.stateIn(componentScope, SharingStarted.Eagerly, null),
                    botsRepository = botsRepository
                )
            )

            is TabConfig.Profile -> TabsComponent.Tab.Settings(
                DefaultProfileComponent(
                    context = context,
                    onLogout = onLogout,
                    onNavigateToPremium = onNavigateToPremium,
                    onNavigateToDevices = onNavigateToDevices,
                    onNavigateToAccount = onNavigateToAccount,
                    onNavigateToAppearance = onNavigateToAppearance,
                    userRepository = userRepository,
                    onNavigateToSettings = onNavigateToSettings,
                    topComponent = topComponent,
                    sendEmailIntent = sendEmailIntent,
                    dispatchers = dispatchers,
                    errorHandler = errorHandler
                )
            )

        }
    }

    @Serializable
    private sealed interface TabConfig {

        @Serializable
        data object BotList : TabConfig

        @Serializable
        data object Profile : TabConfig
    }
}