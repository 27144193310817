
package org.botdesigner.ui.screens.subscription

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.scaleIn
import androidx.compose.animation.scaleOut
import androidx.compose.animation.slideInHorizontally
import androidx.compose.animation.slideOutHorizontally
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.navigationBarsPadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.selection.selectable
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.text.ClickableText
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.HelpOutline
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material.icons.filled.Done
import androidx.compose.material.icons.outlined.Circle
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.lerp
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.util.fastForEach
import androidx.compose.ui.util.fastForEachIndexed
import androidx.compose.ui.zIndex
import io.github.alexzhirkevich.cupertino.CupertinoButtonSize
import io.github.alexzhirkevich.cupertino.LocalContainerColor
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveButton
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveIconButton
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.cupertinoTween
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import org.botdesigner.api.SharedConstants
import org.botdesigner.api.SubscriptionType
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.subscription.SubscriptionComponent
import org.botdesigner.shared.domain.content.subscription.SubscriptionViewState
import org.botdesigner.shared.domain.content.subscription.isAlreadyPurchased
import org.botdesigner.shared.util.managers.Subscription
import org.botdesigner.ui.LocalViewController
import org.botdesigner.ui.common.PlatformDialogTopBar
import org.botdesigner.ui.common.PlatformVerticalScrollbar
import org.botdesigner.ui.common.darken
import org.botdesigner.ui.common.dialogs.LoadingDialog
import org.botdesigner.ui.common.isLoading
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens


@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal fun PremiumScreen(
    component: SubscriptionComponent,
    modifier : Modifier = Modifier
) {

    val state by component.state.collectAsState()

    if (state.isLoading) {
        LoadingDialog()
    }

    AdaptiveScaffold(
        modifier = modifier,
        containerColor = LocalContainerColor.current,
        topBar = {
            PlatformDialogTopBar(
                title = {
                    Text(string(SharedRes.strings.subscription),)
                },
                onDismissRequest = component::onCloseClicked,
                actions = {
                    AnimatedVisibility(
                        visible = !state.isHelpVisible,
                        enter = scaleIn() + fadeIn(),
                        exit = scaleOut() + fadeOut()
                    ) {
                        AdaptiveIconButton(
                            onClick = component::onHelpClicked
                        ) {
                            Icon(
                                imageVector = Icons.AutoMirrored.Filled.HelpOutline,
                                contentDescription = string(SharedRes.strings.help_info)
                            )
                        }
                    }
                }
            )
        }
    ) { padding ->
        Column(
            modifier = Modifier
                .padding(padding)
                .fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            val controller = LocalViewController()

            AnimatedContent(
                targetState = state.isHelpVisible,
                modifier = Modifier.fillMaxSize()
            ) { help ->
                if (help) {
                    HelpInfo(
                        modifier =  Modifier
                            .padding(horizontal = Dimens.Spacing.Large)
                    )
                } else {
                    SubscriptionPicker(
                        modifier =  Modifier
                            .padding(bottom = Dimens.Spacing.Large)
                            .navigationBarsPadding(),
                        state = state,
                        onSelected = component::onOfferingSelected,
                        onSubscribeClicked = {
                            component.onSubscribeClicked(controller)
                        }
                    )
                }
            }
        }
    }
}



@OptIn(ExperimentalFoundationApi::class)
@Composable
private fun HelpInfo(modifier: Modifier) {

    val helpBlocks = listOf(
        SharedRes.strings.invocations to string(
            SharedRes.strings.invocations_help,
            string(SharedRes.strings.app_name),
            SharedConstants.FreeInvocationsPerDay
        ),
        SharedRes.strings.execution_time to string(SharedRes.strings.execution_time_help),
        SharedRes.strings.max_running_bots to string(SharedRes.strings.max_running_bots_help),
        SharedRes.strings.blueprint_logs to string(SharedRes.strings.blueprint_logs_help),
    )

    val listState = rememberLazyListState()

    Box {
        PlatformVerticalScrollbar(
            scrollableState = listState,
            modifier = Modifier
                .zIndex(1f)
                .align(Alignment.CenterEnd)
        )

        LazyColumn(
            modifier = modifier,
            state = listState,
            contentPadding = PaddingValues(bottom = Dimens.Spacing.Medium)
        ) {
            helpBlocks.fastForEach {
                item { Spacer(Modifier.height(Dimens.Spacing.Medium)) }
                stickyHeader {
                    Surface(
                        modifier = Modifier.fillMaxWidth(),
                        color = LocalContainerColor.current,
                        contentColor = MaterialTheme.colorScheme.onBackground
                    ) {
                        Text(
                            text = string(it.first),
                            style = MaterialTheme.typography.titleMedium,
                            modifier = Modifier.padding(vertical = Dimens.Spacing.Small),
                            fontWeight = FontWeight.Bold
                        )
                    }
                }
                item {
                    Text(
                        text = it.second,
                        style = MaterialTheme.typography.labelMedium,
                        color = MaterialTheme.colorScheme.secondary
                    )
                }
            }
        }
    }
}

private val CheckmarContents : List<@Composable (SubscriptionType?) -> String?> = listOf(
    {
        it?.maxActiveBots?.let {
            string(SharedRes.strings.up_to_n_active_bots, it)
        }
    },
    {
        it?.maxNodes?.let {
            string(
                SharedRes.strings.up_to_n_nodes,
                it
            )
        }
    },
    {
        it?.totalMaxTimeout?.let {
            string(
                SharedRes.strings.up_to_n_execution_time,
                it.inWholeMinutes
            )
        }
    },
    {
        when (it) {
            SubscriptionType.Free -> ""
            SubscriptionType.Premium -> string(SharedRes.strings.blueprint_logs)
            SubscriptionType.Pro -> string(SharedRes.strings.logs_statistics_nodes)
            null -> null
        }
    }
)

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
private fun SubscriptionPicker(
    modifier: Modifier = Modifier,
    state : SubscriptionViewState,
    onSelected : (Subscription) -> Unit,
    onSubscribeClicked : () -> Unit
) {
    val horizontalPadding = Modifier.padding(horizontal = Dimens.Spacing.Large)

    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {

        Spacer(Modifier.weight(1f))

        Text(
            modifier = Modifier.fillMaxWidth().then(horizontalPadding),
            text = string(SharedRes.strings.unlock_hosting),
            style = MaterialTheme.typography.headlineMedium,
            fontWeight = FontWeight.Bold,
            textAlign = TextAlign.Center
        )
        Spacer(Modifier.weight(1f))

        CheckmarContents.fastForEachIndexed { i, text ->
            AnimatedCheckmarkItem(
                sub = state.selectedOffering?.type,
                modifier = Modifier.padding(
                    horizontal = Dimens.Spacing.Large + Dimens.Spacing.Medium,
                    vertical = Dimens.Spacing.Medium / 2
                ),
                text = text,
                delay = 50 * i
            )
        }

        Spacer(Modifier.weight(1f))


        state.availableOfferings.fastForEach {
            OfferingWidget(
                modifier = Modifier
                    .padding(vertical = Dimens.Spacing.Medium / 2)
                    .then(horizontalPadding),
                name = it.type.name,
                price  = it.price?.let { price ->
                    if (it.freeTrialDays != null) {
                        string(
                            SharedRes.strings.days_free_then,
                            it.freeTrialDays ?: 0,
                            string(price)
                        )
                    } else {
                        string(price)
                    }
                },
                selected = state.selectedOffering === it,
                current = state.currentSubscriptionType == it.type,
                enabled = state.selectedOffering !== it,
                onClick = {
                    onSelected(it)
                }
            )
        }

        val (buttonText, enabled) = when {

            state.isAlreadyPurchased ->
                string(SharedRes.strings.manage_subscription) to !state.isLoading

            state.selectedOffering?.price != null -> {
                val price = state.selectedOffering?.price
                    ?.let { string(it) } ?: ""

                string(SharedRes.strings.subscribe_for, price) to !state.isLoading
            }

            else -> null to false
        }

        Spacer(Modifier.weight(.33f))

        AdaptiveButton(
            modifier = horizontalPadding,
            onClick = onSubscribeClicked,
            enabled = enabled,
            adaptation = {
                cupertino {
                    size = CupertinoButtonSize.ExtraLarge
                    shape = CupertinoButtonSize.ExtraLarge.shape.invoke(CupertinoTheme.shapes)
                    contentPadding = CupertinoButtonSize.ExtraLarge.contentPadding
                }
            }
        ) {
            AnimatedContent(
                modifier = Modifier
                    .fillMaxWidth(),
                targetState = buttonText,
                contentAlignment = Alignment.Center,
            ) {
                Text(
                    text = it ?: string(SharedRes.strings.loading),
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center
                )
            }
        }

        Spacer(Modifier.weight(.33f))

        ClickableText(
            modifier = horizontalPadding,
            text = AnnotatedString(string(SharedRes.strings.terms_of_service)),
            onClick = {},
            style = MaterialTheme.typography.labelSmall.copy(
                color = MaterialTheme.colorScheme.secondary,
            ),
        )
    }
}

@Composable
fun AnimatedCheckmarkItem(
    modifier: Modifier,
    sub : SubscriptionType?,
    text: @Composable (SubscriptionType?) -> String?,
    delay : Int,
) {
    AnimatedContent(
        targetState = sub,
        transitionSpec = {
            slideInHorizontally(
                animationSpec = cupertinoTween(delayMillis = delay),
                initialOffsetX = {
                    val t = targetState ?: SubscriptionType.Premium
                    val c = initialState ?: SubscriptionType.Pro
                    if (t.ordinal > c.ordinal) it else -it
                }
            ) togetherWith slideOutHorizontally(
                animationSpec = cupertinoTween(delayMillis = delay),
                targetOffsetX = {
                    val t = targetState ?: SubscriptionType.Premium
                    val c = initialState ?: SubscriptionType.Pro
                    if (t.ordinal > c.ordinal) -it else it
                }
            )
        },
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        CheckmarkItem(
            text = text(it),
            modifier = modifier
        )
    }
}

@Composable
private fun CheckmarkItem(
    text : String?,
    modifier: Modifier = Modifier
) {
    if (text != null && text.isEmpty())
        return

    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(Dimens.Spacing.Medium)
    ) {
        Icon(
            imageVector = Icons.Default.Done,
            contentDescription = null,
            tint = MaterialTheme.colorScheme.primary
        )
        Text(
            text = text?: "*************** ****** *****",
            modifier = Modifier.isLoading(text == null),
            color = MaterialTheme.colorScheme.secondary
        )
    }
}

@Composable
private fun OfferingWidget(
    modifier : Modifier = Modifier,
    name : String,
    selected : Boolean,
    enabled : Boolean,
    price : String?,
    current : Boolean,
    onClick : () -> Unit
) {

    val primaryColor = MaterialTheme.colorScheme.primary
    val secondaryColor = MaterialTheme.colorScheme.secondary.copy(alpha = .25f)

    val lerp by animateFloatAsState(if (selected) 1f else 0f)

    val borderColor = lerp(secondaryColor, primaryColor, lerp)
    Surface(
        modifier = modifier,
        color = lerp(MaterialTheme.colorScheme.surface, primaryColor, lerp/10),
        contentColor = MaterialTheme.colorScheme.onBackground,
        shape = MaterialTheme.shapes.medium,
        border = BorderStroke(2.dp, borderColor)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .selectable(
                    enabled = enabled,
                    selected = selected,
                    onClick = onClick,
                )
                .padding(Dimens.Spacing.Medium),
            verticalArrangement = Arrangement.spacedBy(Dimens.Spacing.ExtraSmall)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.spacedBy(Dimens.Spacing.Small)
            ) {
                Icon(
                    imageVector = if (selected)
                        Icons.Default.CheckCircle
                    else Icons.Outlined.Circle,
                    tint = borderColor,
                    contentDescription = null
                )
                Text(
                    text = name,
                    fontWeight = FontWeight.SemiBold
                )
                if (current) {
                    Spacer(Modifier.weight(1f))
                    Text(
                        text = string(SharedRes.strings.current),
                        modifier = Modifier
                            .clip(CircleShape)
                            .background(MaterialTheme.colorScheme.primary.darken())
                            .padding(
                                vertical = Dimens.Spacing.ExtraSmall / 2,
                                horizontal = Dimens.Spacing.Small,
                            ),
                        color = MaterialTheme.colorScheme.onPrimary,
                        style = MaterialTheme.typography.labelMedium
                    )
                }
            }
            Text(
                text = price ?: "********",
                style = MaterialTheme.typography.labelMedium,
                modifier = Modifier.isLoading(price == null)
            )
        }
    }
}

