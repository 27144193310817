package org.botdesigner.ui.common

import androidx.compose.animation.core.FiniteAnimationSpec
import androidx.compose.animation.core.tween
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.layout.layout
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimator
import kotlin.math.abs

fun materialStackAnimator(
    animationSpec : FiniteAnimationSpec<Float> = tween(
        durationMillis = 400,
    ),
) = stackAnimator(animationSpec = animationSpec) { factor, _, content ->
    content(
        Modifier
        .graphicsLayer {
            alpha =  (1F - abs(factor)).coerceIn(0f, 1f)
        }.offsetXFactor(factor/10)
    )
}


private fun Modifier.offsetXFactor(factor: Float): Modifier =
    layout { measurable, constraints ->
        val placeable = measurable.measure(constraints)

        layout(placeable.width, placeable.height) {
            placeable.placeRelative(x = (placeable.width.toFloat() * factor).toInt(), y = 0)
        }
    }