@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.scheduler

import androidx.compose.ui.graphics.Color
import kotlin.Any
import kotlin.Pair
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpTrigger
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpScheduled")
public class BpScheduled(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              
          }
         
          
      },
  override val connectors: List<Connector> = Connectors(id){ output() },
) : BpTrigger<@Contextual Any?>() {
  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4294901760)

  override val factory: ProcedureFactory<@Contextual Any?>
    get() = ::BpScheduled

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual Any?,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(Scheduled())}
}
