package org.botdesigner.ui.screens.account

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.window.DialogProperties
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveAlertDialog
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveCircularProgressIndicator
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.cancel
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.resources.SharedRes
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal fun OauthSignProgressDialog(
    onCancel : () -> Unit
) {
    if (CurrentPlatform == Platform.Desktop) {
        AdaptiveAlertDialog(
            properties = DialogProperties(
                dismissOnBackPress = false,
                dismissOnClickOutside = false
            ),
            title = {
            },
            message = {
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.spacedBy(Dimens.Spacing.Medium)
                ) {
                    AdaptiveCircularProgressIndicator()
                    Text(string(SharedRes.strings.browser_sign_in_desc))
                }
            },
            onDismissRequest = onCancel
        ) {
            cancel(onClick = onCancel) {
                Text(
                    text = string(SharedRes.strings.cancel),
                    color = MaterialTheme.colorScheme.primary
                )
            }
        }
    }
}