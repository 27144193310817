package org.botdesigner.api.auth

import kotlinx.serialization.Serializable

@Serializable
class RefreshTokenRequest(
    val token : String,
    val notificationToken: String
)

@Serializable
class RefreshTokenResponse(
    override val accessToken: String,
    override val refreshToken: String
) : TokenResponse