package org.botdesigner.ui.common

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material.icons.filled.ArrowBackIosNew
import androidx.compose.ui.graphics.vector.ImageVector
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform

internal val NavigateBackIcon : ImageVector
    get() = if (CurrentPlatform == Platform.Ios)
        Icons.Default.ArrowBackIosNew else Icons.Default.ArrowBack