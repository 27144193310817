package org.botdesigner.shared.data.repo

import dev.icerock.moko.resources.desc.Resource
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import org.botdesigner.api.User
import org.botdesigner.resources.SharedRes


expect class AuthContext

sealed interface AuthResult {

    val errorMsg : StringDesc?

    class Success(val user: User) : AuthResult {
        override val errorMsg: StringDesc?
            get() = null
    }

    object Error : AuthResult {
        override val errorMsg: StringDesc
            get() = SharedRes.strings.error_something_went_wrong.desc()

    }
    object WeakPassword : AuthResult {
        override val errorMsg: StringDesc
            get() = SharedRes.strings.error_password_constraints.desc()
    }
    object Timeout : AuthResult {
        override val errorMsg: StringDesc
            get() =  SharedRes.strings.error_auth_timeout.desc()

    }

    object AlreadyExists : AuthResult {
        override val errorMsg: StringDesc
            get() = SharedRes.strings.error_auth_exists.desc()
    }

    object InvalidCredentials : AuthResult {
        override val errorMsg: StringDesc
            get() = SharedRes.strings.error_auth_invalid_credentials.desc()
    }

    object InvalidCode : AuthResult {
        override val errorMsg: StringDesc
            get() = SharedRes.strings.error_auth_invalid_code.desc()
    }

    object Cancelled : AuthResult {
        override val errorMsg: StringDesc?
            get() = null

    }
}



interface AuthRepository : UserRepository {

    suspend fun register(name : String, email : String, password : String) : AuthResult

    suspend fun signIn(email : String, password: String) : AuthResult

    suspend fun signInWithGoogle() : AuthResult
    suspend fun signInWithApple() : AuthResult

    suspend fun sendEmailVerification()

    suspend fun verifyEmail(code : String) : Boolean

    suspend fun requestRestorePassword(email : String) : Boolean

    suspend fun changePassword(email : String, code : String, newPassword : String) : AuthResult


    suspend fun signOut()
}