package org.botdesigner.shared.domain.content.list

import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.value.Value
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.core.Bot
import org.botdesigner.shared.domain.ChildComponent
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.shared.domain.content.ContentComponent
import org.botdesigner.shared.util.UIState

interface BotsListComponent : BotFilterComponent {

    val isTwoPane : Boolean

    val bots : StateFlow<UIState<List<Bot>>>

    val selectedBot : StateFlow<Bot?>

    val dialog: Value<ChildSlot<*, Dialog<*>>>

    fun onBotSelected(bot: Bot)

    fun onBotEdit(bot: Bot)

    fun onBotDelete(bot: Bot)

    fun onCreateClicked()


    sealed interface Dialog<T> : ChildComponent<T> {
        class Delete(
            override val component : AlertConfirmationDialogComponent
        ) : Dialog<AlertConfirmationDialogComponent>
    }

}
