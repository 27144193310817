package org.botdesigner.blueprint.stdlib.pins


import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.*
import org.botdesigner.blueprint.ui.pins.DefaultTextFieldPinWidth
import org.botdesigner.blueprint.ui.pins.TextFieldPinWidget

@Serializable
@SerialName("StringPinFactory")
object StringPinFactory: PinFactory<String> {

    override fun array(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = StringArrayPinFactory.create(
        id = id,
        order = order,
        elementId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )

    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): Pin<String> = StringPin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}
@Serializable
@SerialName("StringPin")
data class StringPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: String? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
    val maxLines : Int = 1
) : Pin<String>() {

    override val color: Color get()  = BlueprintColors.String

    override fun withValue(value: String): StringPin {
        return copy(parentPinId = null, parentId = null, value = value)
    }

    override fun withReference(parent: Pin<*>?): StringPin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<String> {
        return StringPinFactory
    }

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected : Boolean,
        pool: BlueprintNodesPool
    ) {
        TextFieldPinWidget(
            color = color,
            name = name,
            isActive = pool.isPinActive(this@StringPin),
            isOut = isOut,
            required = required,
            value = value,
            onTap = { onTap(this@StringPin) },
            modifier = modifier,
            inputValueEnabled = true,
            isSelected = isSelected,
            maxLines = maxLines,
            maxWidth = if (maxLines == 1) DefaultTextFieldPinWidth else 300.dp,
            onChangeInputValue = { onInputChanged(it) }
        )
    }
}

fun PinsDirectionalScope.string(
    name: String = "",
    order : UInt? = null,
    default : String? = null,
    maxLines: Int = 1,
    required: Boolean = false
) {
    pin { o, i ->
        StringPin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
            value = default,
            maxLines = maxLines,
            required = required
        )
    }
}