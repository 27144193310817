@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.special

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Boolean
import kotlin.Pair
import kotlin.Suppress
import kotlin.collections.List
import kotlin.collections.Map
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpIfNotNull")
public class BpIfNotNull(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.components.GenericPinFactory.create(
              id = Id("0"),
              elementId = elementId,
              order = o,
              name = "Value",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.components.GenericPinFactory.create(
                  id = Id("r"),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = false
              )
          }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("Not Null")
          output("Is Null")
      },
) : BpProcedure<@Contextual Any?>() {
  override val summary: AnnotatedString
    get() =
        "Perform <b>Not Null</b> action if <b>Value</b> is not null and <b>Is Null</b> otherwise. Returns the same <b>Value</b> that was passed".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4287137928)

  override val pinTypeDerivation: Map<Id, Pair<Id, Boolean>>
    get() = mapOf(
    Pair(Id("r"), Pair(Id("0"), false))
    )

  override val factory: ProcedureFactory<@Contextual Any?>
    get() = ::BpIfNotNull

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual Any?,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(IfNotNull(
  		input[0] as kotlin.Any?,
  		{
      return outputConnectors[1] to listOf(it)
  }
  	))}
}
