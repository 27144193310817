package org.botdesigner.shared.data.repo

import kotlinx.coroutines.flow.Flow
import org.botdesigner.core.Bot
import org.botdesigner.core.BotStatus
import org.botdesigner.core.BotType
import org.botdesigner.core.models.BlueprintLog

enum class VerifyBotTokenResult {
    Success, InvalidToken, Exists, Error
}

interface BotsRepository {

    fun getAll() : Flow<List<Bot>>

    suspend fun create(
        token : String,
        debugToken : String?,
        name : String,
        type : BotType,
    ) : Bot

    fun get(id : String) : Flow<Bot>

    suspend fun update(bot: Bot)

    suspend fun toggle(botId : String, status: BotStatus)

    suspend fun delete(id : String)

    suspend fun logs(botId : String, page : Int) : Flow<List<BlueprintLog>>

    suspend fun verifyToken(
        token : String,
        botType: BotType,
        verifyExistence : Boolean = true
    ) : VerifyBotTokenResult

}