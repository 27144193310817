@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.io.network

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Pair
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.io.context.NetworkContext
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpSendHttpRequestUpload")
public class BpSendHttpRequestUpload(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "URL",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.EnumPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Method",
              isOut = i,
              required = true
          ).copy(availableValues =
          org.botdesigner.blueprint.io.network.BpHttpMethod.values().map(Enum<*>::name))
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringArrayPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Headers",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Multipart Key",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "File Name",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.io.pins.ByteStreamPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Body",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.components.GenericPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Extra Form Data",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Response Code",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringArrayPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Response Headers",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Response Body",
              isOut = i,
              required = true
          )
      }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("")
      },
) : BpProcedure<@Contextual NetworkContext>() {
  override val summary: AnnotatedString
    get() =
        "Multipart form data upload request. You can provide optional <b>Multipart Key</b> for body (by default 'data' will be used). <b>Response Body</b> is decoded to UTF-8 string and is limited to 1MB".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4280696141)

  override val name: String
    get() = "Multipart HTTP Upload"

  override val factory: ProcedureFactory<@Contextual NetworkContext>
    get() = ::BpSendHttpRequestUpload

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual NetworkContext,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { SendHttpRequestUpload(
  		requireNotNull(input[0] as kotlin.String?) { 
  "URL can't be null" 
  },
  		org.botdesigner.blueprint.io.network.BpHttpMethod.valueOf(input[1] as kotlin.String),
  		input[2] as kotlin.collections.Iterable<kotlin.String>?,
  		input[3] as kotlin.String?,
  		input[4] as kotlin.String?,
  		input[5] as io.ktor.utils.io.ByteReadChannel?,
  		input[6] as kotlin.Any?
  	).values }
}
