package org.botdesigner.shared.data.source.impl

import app.cash.sqldelight.ColumnAdapter
import app.cash.sqldelight.async.coroutines.awaitCreate
import app.cash.sqldelight.async.coroutines.awaitMigrate
import app.cash.sqldelight.db.SqlDriver
import com.russhwolf.settings.Settings
import com.russhwolf.settings.long
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.botdesigner.api.auth.DeviceType
import org.botdesigner.botblueprints.BlueprintType
import org.botdesigner.core.BotStatus
import org.botdesigner.core.BotType
import org.botdesigner.shared.data.cache.CacheDatabase
import org.botdesigner.shared.data.cache.SqBlueprint
import org.botdesigner.shared.data.cache.SqBot
import org.botdesigner.shared.data.cache.SqSession
import org.botdesigner.shared.data.source.DatabaseFactory

class DatabaseFactoryImpl(
    private val driver: SqlDriver,
    settings: Settings
) : DatabaseFactory {

    private val scope = CoroutineScope(Dispatchers.Default)


    private var lastVersion by settings.long(
        key = "org.botdesigner.database_version",
        defaultValue = CacheDatabase.Schema.version
    )

    override fun create(): CacheDatabase {

        scope.launch {
            CacheDatabase.Schema.awaitCreate(driver)
        }

        val vCurrent = CacheDatabase.Schema.version
        val vLast = lastVersion

        if (vLast < vCurrent) {
            scope.launch {
                CacheDatabase.Schema.migrate(
                    driver = driver,
                    oldVersion = vLast,
                    newVersion = vCurrent
                )
            }
        }

        if (vLast > vCurrent) {
            scope.launch {
                destroy()
                CacheDatabase.Schema.awaitCreate(driver)
            }
        }

        lastVersion = vCurrent

        return CacheDatabase(
            driver = driver,
            SqBlueprintAdapter = SqBlueprint.Adapter(
                typeAdapter = object : ColumnAdapter<BlueprintType, String> {
                    override fun decode(databaseValue: String): BlueprintType =
                        BlueprintType.valueOf(databaseValue)

                    override fun encode(value: BlueprintType): String = value.name
                },
            ),
            SqSessionAdapter = SqSession.Adapter(
                deviceTypeAdapter = object : ColumnAdapter<DeviceType, String> {
                    override fun decode(databaseValue: String): DeviceType {
                        return runCatching {
                            DeviceType.valueOf(databaseValue)
                        }.getOrDefault(DeviceType.Unknown)
                    }

                    override fun encode(value: DeviceType): String {
                        return value.name
                    }
                }
            ),
            SqBotAdapter = SqBot.Adapter(
                typeAdapter = object : ColumnAdapter<BotType, String> {
                    override fun decode(databaseValue: String): BotType =
                        BotType.valueOf(databaseValue)

                    override fun encode(value: BotType): String = value.name
                },
                statusAdapter = object : ColumnAdapter<BotStatus, String> {
                    override fun decode(databaseValue: String): BotStatus =
                        BotStatus.valueOf(databaseValue)

                    override fun encode(value: BotStatus): String = value.name
                },
            ),
        )
    }

    override suspend fun destroy() {
        CacheDatabase.Schema.awaitMigrate(driver, 1, 2)
    }
}