package io.github.alexzhirkevich.onetap

fun OneTap.buildOAuthUri(endpoint : String, block : OAuthUriBuildingScope.() -> Unit) : String {
    return OAuthUriBuildingScopeImpl(endpoint).apply(block).toString()
}

interface OAuthUriBuildingScope {
    fun param(key: String, value: Any)
}

inline fun OAuthUriBuildingScope.clientId(value : String) = param("client_id", value)
inline fun OAuthUriBuildingScope.redirectUri(value : String) = param("redirect_uri", value)
inline fun OAuthUriBuildingScope.responseType(value : String) = param("response_type", value)
inline fun OAuthUriBuildingScope.scopes(value : List<String>) {
    if (value.isNotEmpty()) {
        param("scope", value.joinToString(separator = " "))
    }
}

inline fun OAuthUriBuildingScope.state(value : String) =
    param("state", value)

private class OAuthUriBuildingScopeImpl(
    endpoint: String
) : OAuthUriBuildingScope {

    private val builder = StringBuilder(endpoint)

    private var count: Int = 0
    private val delim get() = if (count == 0) '?' else "&"
    override fun param(key: String, value: Any) {
        builder.append("$delim$key=$value")
        count++
    }

    override fun toString(): String {
        return builder.toString()
    }
}


