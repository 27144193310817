package org.botdesigner.ui.screens.changepassword

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.changepassword.ChangePasswordComponent
import org.botdesigner.shared.domain.content.changepassword.isChangeEnabled
import org.botdesigner.ui.common.NullableComposable
import org.botdesigner.ui.localized
import org.botdesigner.ui.screens.FormScreen
import org.botdesigner.ui.screens.auth.EyeButton
import org.botdesigner.ui.screens.create.FormTextField
import org.botdesigner.ui.string

@Composable
fun ChangePasswordScreen(
    component : ChangePasswordComponent,
    modifier: Modifier = Modifier
) {

    val state by component.state.collectAsState()

    var visible by remember {
        mutableStateOf(false)
    }

    val visualTransformation = remember { PasswordVisualTransformation() }
        .takeUnless { visible } ?: VisualTransformation.None

    FormScreen(
        modifier = modifier,
        title = SharedRes.strings.change_password.localized(),
        confirm = SharedRes.strings.change.localized(),
        confirmEnabled = state.isChangeEnabled,
        onConfirm = component::onChangeClicked,
        onBack = component::onBack
    ) {
        FormTextField(
            value = state.oldPassword,
            onValueChange = component::onOldPasswordChanged,
            enabled = !state.isChangingInProgress,
            modifier = Modifier.fillMaxWidth(),
            keyboardOptions = KeyboardOptions(
                keyboardType = KeyboardType.Password,
                autoCorrect = false,
                imeAction = ImeAction.Next
            ),
            visualTransformation = visualTransformation,
            singleLine = true,
            isError = state.oldPasswordError != null,
            label = {
                Text(SharedRes.strings.old_password.localized())
            },
            trailingIcon = {
                EyeButton(
                    visible = visible,
                    onClick = {
                        visible = !visible
                    }
                )
            },
            supportingText = NullableComposable(state.oldPasswordError) {
                Text(string(it))
            }
        )
        FormTextField(
            value = state.newPassword,
            onValueChange = component::onNewPasswordChanged,
            enabled = !state.isChangingInProgress,
            modifier = Modifier.fillMaxWidth(),
            keyboardOptions = KeyboardOptions(
                keyboardType = KeyboardType.Password,
                autoCorrect = false,
                imeAction = ImeAction.Done
            ),
            keyboardActions = KeyboardActions {
                component.onChangeClicked()
            },
            visualTransformation = visualTransformation,
            singleLine = true,
            isError = state.newPasswordError != null,
            label = {
                Text(string(SharedRes.strings.new_password))
            },
            supportingText = NullableComposable(state.newPasswordError) {
                Text(string(it))
            }
        )
    }
}