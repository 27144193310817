package org.botdesigner.blueprint.stdlib.functions.arrays

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Transient
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.ArrayPin
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintFunction
import org.botdesigner.blueprint.components.BlueprintFunctionStateHolder
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.GenericArrayPin
import org.botdesigner.blueprint.components.GenericArrayPinFactory
import org.botdesigner.blueprint.components.GenericPin
import org.botdesigner.blueprint.components.GenericPinFactory
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.generic
import org.botdesigner.blueprint.components.genericArray
import org.botdesigner.blueprint.components.inputPins
import org.botdesigner.blueprint.components.outputPins
import org.botdesigner.blueprint.components.parentPin
import org.botdesigner.blueprint.toAnnotatedString

@kotlinx.serialization.Serializable
@SerialName("BpMap")
class BpMap(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id) {
        input {
            genericArray("Array")
            generic("Map")
        }
        output {
            generic("Element")
            genericArray("Mapped")
        }
    },
) : BlueprintFunction {

    override val summary: AnnotatedString
        get() = "Transform <b>Array</b> to another array. <b>Element</b> should be transformed and passed to <b>Map</b>. Using <b>Element</b> for purposes other than mapping can result in errors"
            .toAnnotatedString()

    override val color: Color
        get() = BlueprintColors.Util

    fun copy(
        coordinate: Point,
        pins: List<Pin<*>>,
    ): BpMap {
        return BpMap(coordinate, id, pins)
    }

    @Transient
    private var currentIndex = 0

    @Transient
    private var cachedArray : List<*>? = null

    @Suppress("UNCHECKED_CAST")
    override suspend fun <T> pinValue(pin: Pin<T>, pool: BlueprintNodesPool): T? {
        if (!pin.isOut) {
            return super.pinValue(pin, pool)
        }

        val inputArray = cachedArray
            ?: (super.pinValue(pins.first { !it.isOut }, pool) as Iterable<*>? ?: return null)
                .toList().also {
                    cachedArray = it
                }

        if (pin.id == pins.last { it.isOut }.id) {
            val mapped = inputArray.map {
                pinValue(pins.last { !it.isOut }, pool)
            } as T?
            currentIndex = 0
            cachedArray = null
            return mapped
        }

        return (inputArray[currentIndex] as T?).also {
            currentIndex++
        }
    }

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> {
        return object : BlueprintFunctionStateHolder<BpMap>(
            element = this,
            manager = manager
        ) {

            override fun updatePin(
                pin: Pin<*>
            ) {
                super.updatePin(pin)

                val input = inputPins

                if (pin.id == input[0].id && pin is GenericArrayPin) {

                    val parent = pin.parentPin(manager)

                    val factory = if (parent == null || parent !is ArrayPin<*>) {
                        GenericPinFactory
                    } else {
                        parent.childPinFactory
                    }

                    val value = outputPins.first()

                    manager.resetPin(value)

                    val newPin = factory.create(
                        id = value.id,
                        order = value.order,
                        elementId = value.elId,
                        name = value.name,
                        isOut = value.isOut
                    )

                    super.updatePin(newPin)
                }

                if (pin.id == input[1].id && pin is GenericPin) {

                    val mappedArray = outputPins.last()

                    val newArray = pin.factory(manager).array(
                        id = mappedArray.id,
                        order = mappedArray.order,
                        elementId = mappedArray.elId,
                        name = mappedArray.name,
                        isOut = mappedArray.isOut
                    ) ?: GenericArrayPinFactory.create(
                        id = mappedArray.id,
                        order = mappedArray.order,
                        elementId = mappedArray.elId,
                        name = mappedArray.name,
                        isOut = mappedArray.isOut
                    )

                    println("${newArray::class} != ${mappedArray::class}")

                    if (newArray::class != mappedArray::class) {
                        manager.resetPin(mappedArray)
                        super.updatePin(newArray)
                    }
                }
            }

            override fun save() = element.copy(
                coordinate = position.value,
                pins = pins,
            )
        }
    }
}
