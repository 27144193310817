package org.botdesigner.blueprint.builder

import kotlinx.serialization.KSerializer
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.serializer
import org.botdesigner.blueprint.components.BlueprintFunction
import org.botdesigner.blueprint.components.BlueprintProcedure
import org.botdesigner.blueprint.components.BlueprintTrigger

sealed interface BlueprintBuilderScope<TR : BlueprintTrigger<*>> {

    val serializersModule : SerializersModule

    val trigger : BuilderOutputNode<TR>

    fun <T : BlueprintProcedure<*>> procedure(
        serializer : KSerializer<T>,
        node : T,
    ) : BuilderProcedureNode<T>

    fun <T : BlueprintFunction> function(
        serializer : KSerializer<T>,
        node : T,
    ) : BuilderFunctionNode<T>
}

inline fun <reified T : BlueprintProcedure<*>> BlueprintBuilderScope<*>.procedure(
    x : Float = 0f,
    y : Float = 0f
) : BuilderProcedureNode<T> = procedure(serializersModule.serializer(), node(x,y))

inline fun <reified T : BlueprintFunction> BlueprintBuilderScope<*>.function(
    x : Float = 0f,
    y : Float = 0f
) : BuilderFunctionNode<T> = function(serializersModule.serializer(), node(x,y))

sealed interface BuilderFunctionNode<T : BlueprintFunction> {
    fun outputPin(number: Int): BuilderOutputPin
    fun inputPin(number: Int): BuilderInputPin
}

sealed interface BuilderOutputNode<T : BlueprintProcedure<*>> : BuilderFunctionNode<T> {
    fun output(number: Int): BuilderOutputConnector
}

sealed interface BuilderProcedureNode<T : BlueprintProcedure<*>> : BuilderOutputNode<T> {
    fun input(number: Int): BuilderInputConnector
}


sealed interface BuilderInputConnector  {
    infix fun connectWith(other: BuilderOutputConnector): BuilderInputConnector
}
sealed interface BuilderOutputConnector {
    infix fun connectWith(other: BuilderInputConnector): BuilderOutputConnector
}

sealed interface BuilderInputPin {
    infix fun connectWith(other : BuilderOutputPin) : BuilderInputPin
    infix fun withValue(value: String?): BuilderInputPin
}

sealed interface BuilderOutputPin {
    infix fun connectWith(other : BuilderInputPin) : BuilderOutputPin
    infix fun withValue(value: String?): BuilderOutputPin
}