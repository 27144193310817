package org.botdesigner.blueprint.ui.pins

//import androidx.compose.material.IconButton
import androidx.compose.foundation.layout.Row
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Remove
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.IconButtonDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color

@Composable
fun PinsExtender(
    addEnabled : Boolean = true,
    popEnabled : Boolean = true,
    onAddPin : () -> Unit,
    onPopPin : () -> Unit,
    modifier: Modifier = Modifier
) {
    Row(modifier) {
        IconButton(
            onClick = onAddPin,
            enabled = addEnabled,
            colors = IconButtonDefaults.iconButtonColors(
                contentColor = Color.White,
                disabledContentColor = Color.Gray
            )
        ) {
            Icon(
                imageVector = Icons.Default.Add,
//                tint = Color.White,
                contentDescription = "Add pin"
            )
        }
        IconButton(
            onClick = onPopPin,
            enabled = popEnabled,
            colors = IconButtonDefaults.iconButtonColors(
                contentColor = Color.White,
                disabledContentColor = Color.Gray
            )
        ) {
            Icon(
                imageVector = Icons.Default.Remove,
//                tint = Color.White,
                contentDescription = "Pop pin"
            )
        }
    }
}