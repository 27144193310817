package org.botdesigner.blueprint.ui.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.toSize
import org.botdesigner.blueprint.AbstractBlueprintNodeStateHolder
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintIcons

@Composable
fun AbstractBlueprintNodeStateHolder<*>.Function(
    name : String,
    color : Color = BlueprintColors.Function,
    icon : ImageVector = BlueprintIcons.Function,
    modifier : Modifier = Modifier,
    manager: BlueprintManager,
    fieldSize : Float,
    shape: Shape,
    also : @Composable ((elementWidth : Float) -> Unit)?=null
) {
    NamedPinComponent(
        modifier = modifier
            .width(IntrinsicSize.Max),
        color = color,
        icon = icon,
        name = name,
        manager = manager,
        shape = shape,
        fieldSize = fieldSize,
        beforePins = { width ->
            var rowPosition by remember {
                mutableStateOf(Offset.Zero)
            }

            Connectors(
                modifier = Modifier
                    .fillMaxWidth()
                    .onGloballyPositioned {
                        rowPosition = it.positionInParent()
                    },
                width = width,
                inputConnector = { pos ->
                    DrawConnectors(
                        parentInComponent = rowPosition,
                        isOut = false,
                        onConnectorClicked = manager::onConnectorClicked,
                        modifier = Modifier.onSizeChanged(pos),
                        drawIf = { shouldDrawConnector(it) }
                    )
                },
                outputConnectors = { pos ->
                    DrawConnectors(
                        parentInComponent = rowPosition,
                        isOut = true,
                        onConnectorClicked = manager::onConnectorClicked,
                        modifier = Modifier.onSizeChanged(pos),
                        drawIf = { shouldDrawConnector(it) }
                    )
                }
            )
        },
        afterPins = if (also != null) {
            { also.invoke(it) }
        } else null
    )
}

@Composable
private fun Connectors(
    modifier: Modifier,
    width: Float,
    inputConnector: @Composable (onSizeChanged : (IntSize) -> Unit) -> Unit,
    outputConnectors: @Composable (onSizeChanged : (IntSize) -> Unit) -> Unit,
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        var inSize by remember {
            mutableStateOf(Size.Zero)
        }

        var outSize by remember {
            mutableStateOf(Size.Zero)
        }

        inputConnector {
            inSize = it.toSize()
        }

        with(LocalDensity.current) {
            Spacer(
                modifier = Modifier
                    .widthIn(max = (width - inSize.width - outSize.width).toDp())
            )
        }

        outputConnectors {
            outSize = it.toSize()
        }
    }
}