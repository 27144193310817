package org.botdesigner.shared.domain

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.replaceAll
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filterNot
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.botdesigner.api.SharedConstants
import org.botdesigner.shared.data.repo.AuthRepository
import org.botdesigner.shared.data.repo.EditorSettings
import org.botdesigner.shared.data.repo.SettingsRepository
import org.botdesigner.shared.domain.auth.AuthComponent
import org.botdesigner.shared.domain.auth.DefaultAuthComponent
import org.botdesigner.shared.domain.content.ContentComponent
import org.botdesigner.shared.domain.content.DefaultContentComponent
import org.botdesigner.shared.domain.content.appearance.AppearanceState
import org.botdesigner.shared.domain.content.appearance.toAppearance
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.toCoroutineExceptionHandler
import org.koin.core.component.KoinComponent
import org.koin.core.component.get
import org.koin.core.component.inject

@OptIn(FlowPreview::class)
internal class DefaultRootComponent(
    context : ComponentContext,
) : CoroutineComponent(context), KoinComponent, RootComponent {

    private val authRepository: AuthRepository by inject()

    private val settingsRepository: SettingsRepository by inject()

    override val appearance : MutableStateFlow<AppearanceState> = instanceKeeper
        .getOrCreateSimple(keyFor(::appearance)) {
            MutableStateFlow(AppearanceState())
        }
    override val settings : MutableStateFlow<EditorSettings> = instanceKeeper
        .getOrCreateSimple(keyFor(::settings)) {
            MutableStateFlow(EditorSettings())
        }

    override val snackbar: MutableSharedFlow<SnackbarMessage> =
        instanceKeeper.getOrCreateSimple(keyFor(::snackbar)) {
            MutableSharedFlow(
                replay = 0,
                extraBufferCapacity = 3,
                onBufferOverflow = BufferOverflow.DROP_OLDEST
            )
        }

    private val deepLinkFlow = MutableSharedFlow<RootComponent.Deeplink>(
        replay = 1,
        extraBufferCapacity = 1
    )

    private val navigation = StackNavigation<Config>()

    private val errorHandler: ErrorHandler by inject()

    private val dispatchers: org.botdesigner.shared.util.dispatchers.Dispatchers by inject()

    private val keyEventFlow : MutableSharedFlow<RootKeyEvent> = instanceKeeper
        .getOrCreateSimple(keyFor(::keyEventFlow)) {
            MutableSharedFlow(extraBufferCapacity = 1, onBufferOverflow = BufferOverflow.DROP_OLDEST)
        }

    override val stack: Value<ChildStack<*, RootComponent.Child>> = childStack(
        source = navigation,
        serializer = Config.serializer(),
        initialConfiguration = if (authRepository.currentUser?.isEmailVerified == true)
            Config.Content else Config.Auth,
        handleBackButton = true,
        childFactory = { configuration, context ->
            when (configuration) {
                Config.Auth -> RootComponent.Child.Auth(
                    authComponent(context)
                )

                Config.Content -> RootComponent.Child.Content(
                    contentComponent(context)
                )
            }
        }
    )


    private fun authComponent(context: ComponentContext): AuthComponent {
        return DefaultAuthComponent(
            context = context,
            authRepository = authRepository,
            errorHandler = get(),
            dispatchers = get(),
            onLogout = ::logout,
            onMessage = snackbar::tryEmit
        )
    }

    private fun contentComponent(context: ComponentContext): ContentComponent =
        DefaultContentComponent(
            context = context,
            onLogout = ::logout,
            blueprintsRepository = get(),
            botsRepository = get(),
            authRepository = get(),
            sessionsRepository = get(),
            customFunctionsRepository = get(),
            dispatchersFactory = { get() },
            errorHandler = get(),
            viewUrlIntent = get(),
            sendEmailIntent = get(),
            subscriptionManager = get(),
            deepLinkFlow = deepLinkFlow.filterNot { it is RootComponent.Deeplink.Authentication },
            keyEventFlow = keyEventFlow,
            appearanceState = appearance,
            settings = settings,
            onMessage = snackbar::tryEmit,
            rootCoroutineScope = componentScope,
            notificationManager = get()
        )

    init {
        dispatchers.launchUI(
            scope = componentScope,
            exceptionHandler = errorHandler.toCoroutineExceptionHandler()
        ) {
            authRepository.currentUserFlow
                .map { it?.isEmailVerified == true }
                .distinctUntilChanged()
                .collectLatest { authorized ->
                    when {
                        stack.value.active.instance is RootComponent.Child.Auth && authorized ->
                            navigation.replaceAll(Config.Content)

                        stack.value.active.instance is RootComponent.Child.Content && !authorized ->
                            navigation.replaceAll(Config.Auth)
                    }
                }
        }
        dispatchers.launchIO(
            scope = componentScope,
        ) {
            launch {
                appearance
                    .onStart { appearance.emit(AppearanceState(settingsRepository.loadAppearance())) }
                    .debounce(500)
                    .collectLatest {
                        runCatching {
                            settingsRepository.saveAppearance(it.toAppearance())
                        }
                    }
            }
            launch {
                settings
                    .onStart { settings.emit(settingsRepository.loadEditor()) }
                    .collectLatest {
                        runCatching {
                            settingsRepository.saveEditor(it)
                        }
                    }
            }
        }
    }

    private fun logout() {
        dispatchers.launchIO(
            scope = componentScope,
            key = keyFor(::logout),
            exceptionHandler = errorHandler.toCoroutineExceptionHandler()
        ) {
            authRepository.signOut()
        }
    }

    override fun onKeyEvent(event: RootKeyEvent) : Boolean {
        return keyEventFlow.tryEmit(event)
    }


    override fun handleDeepLink(value: String) {
        val cleanDL = cleanupDeeplink(value)

        val rootDeeplink: RootComponent.Deeplink = when {
            cleanDL.startsWith("oauth2callback") -> RootComponent.Deeplink.Authentication(
                code = cleanDL.takeIf { "code=" in it }?.substringAfter("code=")
                    ?.substringBefore("&"),
                idToken = cleanDL.takeIf { "token=" in it }?.substringAfter("token=")
                    ?.substringBefore("&")
            )

            else -> return
        }

        componentScope.launch {
            deepLinkFlow.emit(rootDeeplink)
        }
    }


    @Serializable
    private sealed interface Config {
        @Serializable
        object Auth : Config

        @Serializable
        object Content : Config
    }
}

private fun cleanupDeeplink(value: String) = if (value.startsWith(SharedConstants.AppUrlScheme))
    value.substringAfter(SharedConstants.AppUrlScheme).substringAfter("://")
else value.substringAfter(SharedConstants.Domain + "/")