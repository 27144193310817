package org.botdesigner.shared.`data`.cache

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import org.botdesigner.api.auth.DeviceType

public class SqSessionQueries(
  driver: SqlDriver,
  private val SqSessionAdapter: SqSession.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getAll(ownerId: String, mapper: (
    id: String,
    ownerId: String,
    ip: String,
    country: String,
    city: String,
    device: String,
    deviceType: DeviceType,
    lastActive: Long,
    isCurrent: Boolean,
  ) -> T): Query<T> = GetAllQuery(ownerId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      SqSessionAdapter.deviceTypeAdapter.decode(cursor.getString(6)!!),
      cursor.getLong(7)!!,
      cursor.getBoolean(8)!!
    )
  }

  public fun getAll(ownerId: String): Query<SqSession> = getAll(ownerId) { id, ownerId_, ip,
      country, city, device, deviceType, lastActive, isCurrent ->
    SqSession(
      id,
      ownerId_,
      ip,
      country,
      city,
      device,
      deviceType,
      lastActive,
      isCurrent
    )
  }

  public suspend fun create(
    id: String,
    ownerId: String,
    ip: String,
    country: String,
    city: String,
    device: String,
    deviceType: DeviceType,
    lastActive: Long,
    isCurrent: Boolean,
  ) {
    driver.execute(428_683_017, """
        |INSERT OR REPLACE INTO SqSession(id,ownerId,ip,country,city,device,deviceType,lastActive,isCurrent)
        |    VALUES (?,?,?,?,?,?,?, ?,?)
        """.trimMargin(), 9) {
          bindString(0, id)
          bindString(1, ownerId)
          bindString(2, ip)
          bindString(3, country)
          bindString(4, city)
          bindString(5, device)
          bindString(6, SqSessionAdapter.deviceTypeAdapter.encode(deviceType))
          bindLong(7, lastActive)
          bindBoolean(8, isCurrent)
        }.await()
    notifyQueries(428_683_017) { emit ->
      emit("SqSession")
    }
  }

  public suspend fun delete(id: String) {
    driver.execute(445_518_776, """DELETE FROM SqSession WHERE id=?""", 1) {
          bindString(0, id)
        }.await()
    notifyQueries(445_518_776) { emit ->
      emit("SqSession")
    }
  }

  public suspend fun deleteAll(ownerId: String) {
    driver.execute(1_000_977_097, """DELETE FROM SqSession WHERE ownerId=?""", 1) {
          bindString(0, ownerId)
        }.await()
    notifyQueries(1_000_977_097) { emit ->
      emit("SqSession")
    }
  }

  private inner class GetAllQuery<out T : Any>(
    public val ownerId: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("SqSession", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("SqSession", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(531_609_720, """SELECT * FROM SqSession WHERE ownerId=?""", mapper, 1) {
      bindString(0, ownerId)
    }

    override fun toString(): String = "SqSession.sq:getAll"
  }
}
