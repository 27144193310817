package org.botdesigner.shared.util.managers

import org.botdesigner.blueprint.integrations.registerIntegrations
import org.botdesigner.blueprint.io.registerIo
import org.botdesigner.blueprint.stdlib.registerStdlib
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.botblueprints.registerBotComponents
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.di.AppModule
import org.botdesigner.shared.di.DataModule
import org.botdesigner.shared.di.DomainModule
import org.botdesigner.shared.di.PlatformModule
import org.koin.core.KoinApplication
import org.koin.core.context.startKoin

expect class NativeApplication

interface AppInitializer {
    fun init(app: NativeApplication)
}

abstract class AppInitializationManagerBase : AppInitializer {
    override fun init(app: NativeApplication) {

        BlueprintNodeStoreOwner.registerStdlib()
        BlueprintNodeStoreOwner.registerIo()
        BlueprintNodeStoreOwner.registerIntegrations()
        BlueprintNodeStoreOwner.registerBotComponents()

//        if (CurrentPlatform == Platform.Android){
//            Firebase.initialize(app)
//        } else {
//            Firebase.initialize(app, options = FirebaseOptions(
//                apiKey="AIzaSyAg7-jBNxmNnX25AJ4E-2XvQ8CdYWkCl6k",
//                authDomain= "b0tcreator.firebaseapp.com",
//                projectId= "b0tcreator",
//                storageBucket= "b0tcreator.appspot.com",
//                gcmSenderId  = "102223468176",
//                applicationId = "1:102223468176:web:40ebe19a481213a32e6a15",
//                gaTrackingId = "G-RRSX8JNBW6"
//            ))
//        }

        val koin = startKoin {
            initKoin(app, this)
        }

        val subscriptionManager = koin.koin.get<SubscriptionManager>()
        val id = koin.koin.get<UserRepository>().currentUser?.id
        subscriptionManager.configure(app, id)
    }

    open fun initKoin(app : NativeApplication, koin: KoinApplication) {
        with(koin) {
            modules(DomainModule, DataModule, AppModule, PlatformModule)
        }
    }
}

expect class AppInitializationManager() : AppInitializationManagerBase