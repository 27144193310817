package org.botdesigner.ui.screens.bot

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Circle
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.ui.common.isLoading
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

val BlueprintItemIconSize = 38.dp

@Composable
internal fun BlueprintItem(
    blueprint: BlueprintData?,
    isNew : Boolean = false,
    modifier : Modifier = Modifier
) {
    Column(modifier) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(Dimens.Spacing.Medium),
            modifier = Modifier.padding(horizontal = Dimens.Spacing.Small)
        ) {
            Icon(
                imageVector = blueprint?.type?.icon ?: Icons.Filled.Circle,
                contentDescription = null,
                tint = MaterialTheme.colorScheme.primary,
                modifier = Modifier
                    .size(BlueprintItemIconSize)
                    .clip(CircleShape)
                    .background(MaterialTheme.colorScheme.surface)
                    .padding(Dimens.Spacing.Small)
                    .isLoading(blueprint == null),
            )

            Column(
                verticalArrangement = Arrangement.spacedBy(Dimens.Spacing.ExtraSmall)
            ) {
                Text(
                    text = blueprint?.type?.title?.let { string(it)}
                        ?: "********** **********",
                    style = MaterialTheme.typography.bodyLarge,
                    modifier = Modifier.isLoading(blueprint == null)
                )
                if (blueprint?.context?.isNotBlank() == true) {
                    Text(
                        text = blueprint.context,
                        style = MaterialTheme.typography.labelMedium,
                        color = MaterialTheme.colorScheme.secondary,
                    )
                }
                if (isNew) {
                    Text(
                        text = blueprint?.type?.description?.let { string(it)}
                            ?: "******* *********** ********* ****",
                        style = MaterialTheme.typography.labelMedium,
                        fontWeight = FontWeight.Normal,
                        color = MaterialTheme.colorScheme.secondary,
                    )
                }
            }
        }
    }
}