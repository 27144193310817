package org.botdesigner.ui.common

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.Indication
import androidx.compose.foundation.IndicationInstance
import androidx.compose.foundation.interaction.InteractionSource
import androidx.compose.foundation.interaction.collectIsFocusedAsState
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.interaction.collectIsPressedAsState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.ContentDrawScope
import io.github.alexzhirkevich.LocalContentColor
import org.botdesigner.api.auth.DeviceType
import org.botdesigner.shared.data.DeviceConfig

/**
 * Cupertino click effect
 * */
@Composable
fun rememberDesktopIndication(
    color: @Composable () -> Color = { DesktopIndication.DefaultColor }
) : Indication {

    val updatedColor by rememberUpdatedState(color)

    return remember { DesktopIndication(color = { updatedColor() }) }
}

internal class DesktopIndication(
    val color: @Composable () -> Color,
) : Indication {

    companion object {
        val DefaultColor : Color
            @Composable
            @ReadOnlyComposable
            get() = LocalContentColor.current.copy(alpha = DefaultAlpha)

        val DefaultAlpha = .1f
    }

    @Composable
    override fun rememberUpdatedInstance(interactionSource: InteractionSource): IndicationInstance {

        val pressed by interactionSource.collectIsPressedAsState()
        val hovered by interactionSource.collectIsHoveredAsState()
        val focused by interactionSource.collectIsFocusedAsState()

        val animatedAlpha by animateFloatAsState(
            when {
                pressed -> 1f
                focused -> .5f
                else -> 0f
            }
        )

        val color by rememberUpdatedState(color())


        return remember() {
            object : IndicationInstance {


                override fun ContentDrawScope.drawIndication() {

                    when {
                        pressed -> drawRect(
                            color = color,
                        )
                        focused -> drawRect(
                            color = color,
                            alpha = .5f
                        )
                        (DeviceConfig.type == DeviceType.Web || DeviceConfig.type == DeviceType.Windows) &&
                                hovered && animatedAlpha < .5f -> drawRect(
                            color = color,
                            alpha = .5f,
                        )
                        else -> drawRect(
                            color = color,
                            alpha = animatedAlpha,
                        )
                    }
                    drawContent()
                }
            }
        }
    }
}

