package org.botdesigner.shared.data.repo.impl

//import io.ktor.client.plugins.sse.sse
import com.russhwolf.settings.nullableString
import io.github.alexzhirkevich.onetap.AppleAS
import io.github.alexzhirkevich.onetap.Google
import io.github.alexzhirkevich.onetap.GoogleCM
import io.github.alexzhirkevich.onetap.OIDCResult
import io.github.alexzhirkevich.onetap.OneTap
import io.github.alexzhirkevich.onetap.SignInClient
import io.github.alexzhirkevich.onetap.SignInResult
import io.github.alexzhirkevich.onetap.map
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.request.get
import io.ktor.client.request.patch
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.HttpStatusCode
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.updateAndGet
import kotlinx.coroutines.launch
import kotlinx.coroutines.supervisorScope
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.botdesigner.api.ApiError
import org.botdesigner.api.ChangePasswordRequest
import org.botdesigner.api.ChangePasswordResult
import org.botdesigner.api.SharedConstants
import org.botdesigner.api.UpdateAccountRequest
import org.botdesigner.api.User
import org.botdesigner.api.VerifyEmailRequest
import org.botdesigner.api.auth.ConfirmResetPasswordRequest
import org.botdesigner.api.auth.ConfirmResetPasswordResponse
import org.botdesigner.api.auth.LoginErrorCode
import org.botdesigner.api.auth.LoginWithEmailAndPasswordRequest
import org.botdesigner.api.auth.LoginWithEmailAndPasswordResponse
import org.botdesigner.api.auth.LogoutRequest
import org.botdesigner.api.auth.OAuthLinkRequest
import org.botdesigner.api.auth.OAuthSignInRequest
import org.botdesigner.api.auth.OAuthSignInResponse
import org.botdesigner.api.auth.RegisterErrorCode
import org.botdesigner.api.auth.RegisterWithEmailAndPasswordRequest
import org.botdesigner.api.auth.RegisterWithEmailAndPasswordResponse
import org.botdesigner.api.auth.ResetPasswordErrorCode
import org.botdesigner.api.auth.ResetPasswordRequest
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.shared.data.DeviceConfig
import org.botdesigner.shared.data.repo.AuthRepository
import org.botdesigner.shared.data.repo.AuthResult
import org.botdesigner.shared.data.repo.LinkResult
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.SecureSettings
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.managers.NotificationManager
import org.botdesigner.shared.util.managers.RefreshTokenManager
import org.botdesigner.shared.util.managers.SubscriptionManager
import org.botdesigner.shared.util.toCoroutineExceptionHandler
import kotlin.coroutines.CoroutineContext


private const val USER_SAVE_KEY = "org.botdesigner.user"

internal class AuthRepositoryImpl(
    private val httpClient: HttpClient,
    private val sseHttpClient: HttpClient,
    settings: SecureSettings,
    private val refreshTokenManager: RefreshTokenManager,
    private val subscriptionManager: SubscriptionManager,
    private val notificationManager: NotificationManager,
    errorHandler: ErrorHandler,
    dispatchers: Dispatchers,
) : AuthRepository, CoroutineScope {

    override val coroutineContext: CoroutineContext = dispatchers.ioContext() + SupervisorJob() +
            errorHandler.toCoroutineExceptionHandler()

    private var user by settings
        .nullableString(USER_SAVE_KEY)

    private val _currentUser = MutableStateFlow<User?>(
        user?.takeIf { refreshTokenManager.refreshToken != null }?.let {
            runCatching {
                Json.decodeFromString<User>(it)
            }.onFailure {
                // backwards compatibility failed
                user = null
            }.getOrNull()
        }
    )

    override val currentUser: User?
        get() = user?.let {
            kotlin.runCatching {
                Json.decodeFromString<User>(it)
            }.getOrNull()
        }


    private var accountListeningJob: Job? = null

    override val currentUserFlow: Flow<User?> =
        _currentUser.asStateFlow().onEach {
            if (it == null) {
                accountListeningJob?.cancel()
                accountListeningJob = null
            }
            if (it != null && accountListeningJob?.isActive != true) {
                accountListeningJob?.cancel()
                accountListeningJob = launchAccountListening()
            }
        }


    init {
        refreshTokenManager.addOnExpiredRefreshTokenListener {
            onNewUser(null)
        }
    }

    override suspend fun refreshUser() {
        if (currentUser != null) {
            onNewUser(
                httpClient
                    .get("/v1/account")
                    .body<User>()
            )
        }
    }


    override suspend fun register(name: String, email: String, password: String): AuthResult {
        val resp: RegisterWithEmailAndPasswordResponse = try {
            httpClient.post("/v1/auth/register") {
                setBody(
                    RegisterWithEmailAndPasswordRequest(
                        email = email,
                        name = name,
                        password = password,
                        device = DeviceConfig.name,
                        deviceType = DeviceConfig.type,
                        os = DeviceConfig.os,
                        notificationToken = notificationManager.token()
                    )
                )
            }.body()
        } catch (t: ClientRequestException) {
            if (t.response.status != HttpStatusCode.BadRequest)
                throw t
            t.response.body()
        }

        return when (resp.errorCode) {
            RegisterErrorCode.Success -> {
                val u = requireNotNull(resp.userInfo)
                refreshTokenManager.saveTokens(
                    accessToken = requireNotNull(resp.accessToken),
                    refreshToken = requireNotNull(resp.refreshToken)
                )
                onNewUser(u)

                AuthResult.Success(u)
            }

            RegisterErrorCode.AlreadyExists -> AuthResult.AlreadyExists
            RegisterErrorCode.WeakPassword -> AuthResult.WeakPassword
            RegisterErrorCode.UnknownError -> AuthResult.Error
        }
    }

    override suspend fun signIn(email: String, password: String): AuthResult {
        val resp: LoginWithEmailAndPasswordResponse = try {

            httpClient.post("/v1/auth/login") {
                setBody(
                    LoginWithEmailAndPasswordRequest(
                        email = email,
                        password = password,
                        device = DeviceConfig.name,
                        deviceType = DeviceConfig.type,
                        os = DeviceConfig.os,
                        notificationToken = notificationManager.token()
                    )
                )
            }.body()
        } catch (t: ClientRequestException) {
            if (t.response.status != HttpStatusCode.BadRequest)
                throw t
            t.response.body()
        }

        return when (resp.errorCode) {
            LoginErrorCode.Success -> {
                val u = requireNotNull(resp.userInfo)
                refreshTokenManager.saveTokens(
                    accessToken = requireNotNull(resp.accessToken),
                    refreshToken = requireNotNull(resp.refreshToken)
                )
                onNewUser(u)

                AuthResult.Success(u)
            }

            LoginErrorCode.InvalidCredentials,
            LoginErrorCode.InvalidToken,
            LoginErrorCode.CredentialsInUse
            -> AuthResult.InvalidCredentials

            LoginErrorCode.UnknownError -> AuthResult.Error
        }
    }

    private val defaultGoogleScopes get() = listOf("openid", "email", "profile")

    override suspend fun signInWithGoogle(): AuthResult {
        return oAuthSignIn(
            signInClient = googleOauthClient(
                scopes = defaultGoogleScopes,
                consent = false
            ),
            route = "/v1/auth/login/google"
        )
    }

    override suspend fun signInWithApple(): AuthResult {
        return oAuthSignIn(
            signInClient = appleOAuthClient(),
            route = "/v1/auth/login/apple"
        )
    }

    override suspend fun linkGoogle(extraScopes: List<String>): LinkResult {
        return oAuthLink(
            googleOauthClient(
                scopes = (extraScopes + defaultGoogleScopes).toSet().toList(),
                consent = true
            ), "/v1/account/link/google"
        )
    }

    override suspend fun linkApple(): LinkResult {
        return oAuthLink(appleOAuthClient(), "/v1/account/link/apple")
    }

    override suspend fun sendEmailVerification() {
        httpClient.post("/v1/account/email/resendCode")
    }

    override suspend fun requestRestorePassword(email: String): Boolean {
        return try {
            httpClient.post("/v1/auth/resetPassword") {
                setBody(
                    ResetPasswordRequest(
                        email = email
                    )
                )
            }
            true
        } catch (t: ClientRequestException) {
            if (t.response.status != HttpStatusCode.BadRequest)
                throw t
            false
        }
    }

    override suspend fun changePassword(
        email: String,
        code: String,
        newPassword: String
    ): AuthResult {
        val res: ConfirmResetPasswordResponse = try {
            httpClient.post("/v1/auth/confirmResetPassword") {
                setBody(
                    ConfirmResetPasswordRequest(
                        email = email,
                        code = code,
                        newPassword = newPassword,
                        os = DeviceConfig.os,
                        device = DeviceConfig.name,
                        deviceType = DeviceConfig.type,
                        notificationToken = notificationManager.token()
                    )
                )
            }.body()
        } catch (t: ClientRequestException) {
            if (t.response.status != HttpStatusCode.BadRequest)
                throw t
            t.response.body()
        }

        return when (res.errorCode) {
            ResetPasswordErrorCode.Success -> AuthResult.Success(
                checkNotNull(res.userInfo).also {
                    refreshTokenManager.saveTokens(
                        accessToken = checkNotNull(res.accessToken),
                        refreshToken = checkNotNull(res.refreshToken)
                    )
                    onNewUser(it)
                }
            )

            ResetPasswordErrorCode.InvalidEmail -> AuthResult.InvalidCredentials
            ResetPasswordErrorCode.InvalidCode -> AuthResult.InvalidCode
            ResetPasswordErrorCode.CodeExpired -> AuthResult.Timeout
            ResetPasswordErrorCode.WeakPassword -> AuthResult.WeakPassword
            ResetPasswordErrorCode.Unknown -> AuthResult.Error
        }
    }

    override suspend fun changePassword(oldPassword: String, newPassword: String): ChangePasswordResult {
        return try {
            httpClient.post("/v1/account/changePassword") {
                setBody(
                    ChangePasswordRequest(
                        oldPassword, newPassword
                    )
                )
            }
            ChangePasswordResult.Success
        } catch (t: ClientRequestException) {

            if (t.response.status != HttpStatusCode.BadRequest)
                throw t

            runCatching {
                ChangePasswordResult.valueOf(t.response.body<ApiError>().code)
            }.getOrDefault(ChangePasswordResult.UnknownError)
        }
    }

    override suspend fun updateAccount(name: String?) {
        httpClient.patch("/v1/account") {
            setBody(UpdateAccountRequest(name = name))
        }
        val user = _currentUser.updateAndGet { it?.copy(name = name ?: it.name) }

        onNewUser(user)
    }

    override suspend fun deleteAccount() {
//        httpClient.delete("/v1/account")
//        onNewUser(null)
//        clearAuthData()
    }

    override suspend fun verifyEmail(code: String): Boolean {
        return try {
            httpClient.post("/v1/account/email/verify") {
                setBody(VerifyEmailRequest(code = code))
            }
            onNewUser(_currentUser.value?.copy(isEmailVerified = true))
            true
        } catch (e: ClientRequestException) {
            if (e.response.status == HttpStatusCode.BadRequest)
                return false
            else throw e
        }
    }

    override suspend fun signOut() {

        val token = refreshTokenManager.refreshToken

        onNewUser(null)

        token?.let {
            httpClient.post("/v1/auth/logout") {
                setBody(LogoutRequest(token = it))
            }
        }
    }


    private fun appleOAuthClient() : SignInClient<OIDCResult> {
        return OneTap.AppleAS(
            clientId = SharedConstants.AppleOAuthClientIdWeb,
            redirectUri = if (CurrentPlatform == Platform.Desktop)
                SharedConstants.AppleOAuthRedirectUriCustomScheme
            else SharedConstants.AppleOAuthRedirectUriDefault,
            scopes = if (CurrentPlatform == Platform.Ios)
                listOf("email", "name") else emptyList()
        ).map { OIDCResult(code = it.code, idToken = it.idToken) }
    }

    private fun googleOauthClient(
        scopes: List<String>,
        consent : Boolean
    ) : SignInClient<OIDCResult> {
        val (clientId, redirectUri) = with(SharedConstants) {
            when (CurrentPlatform) {
                Platform.Ios -> GoogleOauthClientIdIOS to GoogleOauthRedirectUriIOS
                Platform.Desktop -> GoogleOauthClientIdDefault to GoogleOauthRedirectUriDesktop
                else -> GoogleOauthClientIdDefault to GoogleOauthRedirectUriDefault
            }
        }
        return if (scopes.isEmpty() && CurrentPlatform == Platform.Android) {
            OneTap.GoogleCM(
                serverClientId = clientId,
                redirectUri = redirectUri
            ).map { OIDCResult(it.serverAuthCode, it.idToken) }
        } else {
            OneTap.Google(
                clientId = clientId,
                redirectUri = redirectUri,
                scopes = scopes,
                accessType = "offline",
                prompt = if (consent) "consent" else null
            ).map { OIDCResult(it.code) }
        }
    }

    private suspend fun oAuthLink(
        signInClient: SignInClient<OIDCResult>,
        route: String,
    ) : LinkResult {
        return when (val res = signInClient.launch()) {
            SignInResult.Cancelled -> throw CancellationException("Linking was cancelled")
            is SignInResult.Failure -> throw res.exception
                ?: Exception("Failed to link sign in method")

            is SignInResult.Success -> {
                try {
                    httpClient.post(route) {
                        setBody(
                            OAuthLinkRequest(
                                idToken = res.result.idToken,
                                code = res.result.code,
                                deviceType = DeviceConfig.type
                            )
                        )
                    }
                    LinkResult.Success
                } catch (c: ClientRequestException) {
                    return when(c.response.body<ApiError>().code){
                        LoginErrorCode.CredentialsInUse.name -> LinkResult.CredentialsInUse
                        else -> LinkResult.InvalidCredentials
                    }
                }
            }
        }
    }


    private suspend fun oAuthSignIn(signInClient: SignInClient<OIDCResult>, route : String) : AuthResult {
        return when (val res = signInClient.launch()) {
            SignInResult.Cancelled -> AuthResult.Cancelled
            is SignInResult.Failure -> AuthResult.Error
            is SignInResult.Success -> {
                val resp = httpClient.post(route) {
                    setBody(
                        OAuthSignInRequest(
                            idToken = res.result.idToken,
                            code = res.result.code,
                            device = DeviceConfig.name,
                            deviceType = DeviceConfig.type,
                            os = DeviceConfig.os,
                            notificationToken = notificationManager.token()
                        )
                    )
                }.body<OAuthSignInResponse>()


                refreshTokenManager.saveTokens(
                    accessToken = checkNotNull(resp.accessToken),
                    refreshToken = checkNotNull(resp.refreshToken)
                )

                onNewUser(resp.userInfo)

                AuthResult.Success(resp.userInfo)
            }
        }
    }

    private suspend fun clearAuthData(){
        supervisorScope {
            launch {
                refreshTokenManager.clearTokens(httpClient)
            }
            launch {
                notificationManager.deleteToken()
            }
            launch {
                subscriptionManager.logout()
            }
        }

    }

    private suspend fun onNewUser(user: User?) {
        _currentUser.tryEmit(user)
        this.user = if (user == null) null else Json.encodeToString(user)
        if (user != null) {
            subscriptionManager.login(user.id)
        } else {
            clearAuthData()
        }
    }

    private fun launchAccountListening(): Job {
        return launch {
            account()
                .catch { }
                .collectLatest {
                    onNewUser(it)
                }
        }
    }

    private fun account(): Flow<User> {
        return flow {
//            sseHttpClient.sse("/v1/account/listen") {
//                incoming.collectLatest {
//
//                    it.data?.let { user ->
//                        val decoded = kotlin.runCatching {
//                            Json.decodeFromString<User>(user)
//                        }.getOrElse { return@collectLatest }
//
//                        emit(decoded)
//                    }
//                }
//            }
        }
    }
}