@file: Suppress("INVISIBLE_MEMBER", "INVISIBLE_REFERENCE")

package org.botdesigner.shared.domain.content

import com.arkivanov.decompose.Child
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.children.ChildNavState
import com.arkivanov.decompose.router.children.NavState
import com.arkivanov.decompose.router.children.NavigationSource
import com.arkivanov.decompose.router.children.SimpleChildNavState
import com.arkivanov.decompose.router.children.children
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.essenty.statekeeper.SerializableContainer
import com.arkivanov.essenty.statekeeper.consumeRequired
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.ListSerializer


fun <C : Any, T : Any> ComponentContext.multipaneChildStack(
    source: NavigationSource<StackNavigation.Event<C>>,
    serializer: KSerializer<C>?,
    initialConfiguration: C,
    isMultipane : (active : C?) -> Boolean,
    key: String = "DefaultChildStack",
    handleBackButton: Boolean = false,
    childFactory: (configuration: C, ComponentContext) -> T
) = children(
        source = source,
        key = key,
        initialState = {
            StackNavState(
                configurations = listOf(initialConfiguration),
                isMultipane = isMultipane(null)
            )
        },
        saveState = { stack ->
            if (serializer != null) {
                SerializableContainer(stack.configurations, ListSerializer(serializer))
            } else {
                null
            }
        },
        restoreState = { container ->
            val configurations = if (serializer != null) {
                container.consumeRequired(strategy = ListSerializer(serializer))
            } else {
                listOf(initialConfiguration)
            }
            StackNavState(
                configurations = configurations,
                isMultipane = isMultipane(configurations.lastOrNull())
            )
        },
        navTransformer = { state, event ->
            val configurations = event.transformer(state.configurations)
            StackNavState(
                configurations = configurations,
                isMultipane = isMultipane(configurations.lastOrNull())
            )
        },
        stateMapper = { state, children ->

            @Suppress("UNCHECKED_CAST")
            val createdChildren = children as List<Child.Created<C, T>>

            MultipaneChildStack(
                active = if (state.isMultipane)
                    createdChildren.drop(1).lastOrNull()
                else createdChildren.lastOrNull(),
                pane = if (state.isMultipane)
                    createdChildren.firstOrNull()
                else null,
                backStack = if (state.isMultipane)
                    createdChildren.drop(1).dropLast(1)
                else createdChildren.dropLast(1),
            )
        },
        onEventComplete = { event, newState, oldState ->
            event.onComplete(newState.configurations, oldState.configurations)
        },
        backTransformer = { state ->
            if (handleBackButton && (state.configurations.size > 1)) {
                {
                    StackNavState(
                        isMultipane = state.isMultipane,
                        configurations = state.configurations.dropLast(1)
                    )
                }
            } else {
                null
            }
        },
        childFactory = childFactory,
    )

private data class StackNavState<out C : Any>(
    val configurations: List<C>,
    val isMultipane : Boolean,
) : NavState<C> {

    init {
        require(configurations.isNotEmpty()) { "Configuration stack must not be empty" }
    }

    override val children: List<SimpleChildNavState<C>> =
        configurations.mapIndexed { index, configuration ->

            val status = if (index == configurations.lastIndex || index == 0 && isMultipane)
                ChildNavState.Status.RESUMED
            else ChildNavState.Status.CREATED

            SimpleChildNavState(
                configuration = configuration,
                status = status,
            )
        }
}