package org.botdesigner.shared.domain.content.blueprint

//import org.botdesigner.blueprint.components.functions.BpVariableGetter
//import org.botdesigner.blueprint.components.functions.BpVariableSetter
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.isCtrlPressed
import androidx.compose.ui.input.key.isMetaPressed
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.type
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.router.slot.SlotNavigation
import com.arkivanov.decompose.router.slot.activate
import com.arkivanov.decompose.router.slot.childSlot
import com.arkivanov.decompose.router.slot.dismiss
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import com.arkivanov.essenty.lifecycle.doOnStop
import dev.icerock.moko.resources.desc.ResourceFormatted
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.withContext
import kotlinx.serialization.Serializable
import org.botdesigner.api.SubscriptionType
import org.botdesigner.api.subscriptionType
import org.botdesigner.blueprint.BlueprintLogLevel
import org.botdesigner.blueprint.BlueprintLogcat
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.BlueprintManagerImpl
import org.botdesigner.blueprint.DefaultBlueprintLogcat
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintException
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.last
import org.botdesigner.blueprint.integrations.context.PushNotificationContext
import org.botdesigner.blueprint.integrations.hasCorruptedNodes
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.core.Bot
import org.botdesigner.core.isRealtimeUpdatesEnabled
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.TelegramDebugBotManager
import org.botdesigner.shared.data.repo.BlueprintsRepository
import org.botdesigner.shared.data.repo.ConsolePosition
import org.botdesigner.shared.data.repo.CustomFunctionsRepository
import org.botdesigner.shared.data.repo.EditorSettings
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.RootKeyEvent
import org.botdesigner.shared.domain.SnackbarAction
import org.botdesigner.shared.domain.SnackbarDuration
import org.botdesigner.shared.domain.SnackbarIdiom
import org.botdesigner.shared.domain.SnackbarMessage
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.shared.domain.content.blueprint.store.BlueprintStoreComponent
import org.botdesigner.shared.domain.content.blueprint.store.DefaultBlueprintStoreComponent
import org.botdesigner.shared.domain.content.blueprint.terminal.BlueprintLogsComponent
import org.botdesigner.shared.domain.content.blueprint.terminal.DefaultBlueprintLogsComponent
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.FormattedFix
import org.botdesigner.shared.util.dispatchers.Dispatchers

private const val AUTOSAVE_DEBOUNCE = 1000L

private val BACKSTACK_SIZE = 50

@OptIn(FlowPreview::class)
internal class DefaultBlueprintComponent(
    context : ComponentContext,
    override val settings : StateFlow<EditorSettings>,
    keyEventFlow : Flow<RootKeyEvent>,
    initialBlueprintData : BlueprintData,
    private val bot : Bot,
    private val onFinished : () -> Unit,
    private val onShowBlueprintGuide : () -> Unit,
    private val onShowSubscriptionDialog : () -> Unit,
    private val onConsolePositionChanged : (ConsolePosition) -> Unit,
    private val onMessage : (SnackbarMessage) -> Unit,
    private val rootCoroutineScope : CoroutineScope,
    private val notificationContext: PushNotificationContext,
    private val blueprintsRepository: BlueprintsRepository,
    private val customFunctionsRepository: CustomFunctionsRepository,
    private val userRepository: UserRepository,
    private val errorHandler: ErrorHandler,
    private val dispatchers: Dispatchers,
) : CoroutineComponent(context),
    BlueprintComponent {

    private val blueprintKey: String
        get() = BlueprintData::class.simpleName!! + bot.id

    private val blueprintData : BlueprintData = instanceKeeper
        .getOrCreateSimple(keyFor(::blueprintData)) {
            stateKeeper.consume(
                key = blueprintKey,
                strategy = BlueprintData.serializer()
            ) ?: initialBlueprintData
        }

    private val blueprintBackstack : MutableList<Blueprint> = instanceKeeper
        .getOrCreateSimple(keyFor(::blueprintBackstack)) {
            mutableStateListOf()
        }


    override val blueprintManager: BlueprintManager = instanceKeeper
        .getOrCreateSimple(keyFor(::blueprintManager)) {
            BlueprintManagerImpl(
                onChanged = {
                    if (blueprintBackstack.size >= BACKSTACK_SIZE) {
                        blueprintBackstack.removeFirstOrNull()
                    }
                    blueprintBackstack.add(it.save())
                    state.update {
                        it.copy(canUndo = blueprintBackstack.size > 1)
                    }
                }
            )
        }


    override val state : MutableStateFlow<BlueprintViewState> = instanceKeeper
        .getOrCreateSimple(keyFor(::state)) {
            MutableStateFlow(BlueprintViewState())
        }

    override val blueprintErrors = MutableSharedFlow<BlueprintError>()

    private val componentDialogNavigation = SlotNavigation<ComponentDialogConfig>()

    private val consoleNavigation = SlotNavigation<PaneConfig>()

    private val alertNavigation  = SlotNavigation<AlertDialogConfig>()

    override val transformation: MutableStateFlow<BlueprintTransformation> = instanceKeeper
        .getOrCreateSimple(keyFor(::transformation)) {
            MutableStateFlow(
                BlueprintTransformation(
                    offset = Offset.Zero,
                    scale = 1f,
                )
            )
        }

    override val componentDialog: Value<ChildSlot<*, BlueprintComponent.ComponentDialog<*>>> =
        childSlot(
            source = componentDialogNavigation,
            serializer = ComponentDialogConfig.serializer(),
            handleBackButton = true,
            key = keyFor(::componentDialog),
            childFactory = ::componentDialogFactory,
        )

    override val alertDialog: Value<ChildSlot<*, BlueprintComponent.AlertDialog<*>>> =
        childSlot(
            source = alertNavigation,
            serializer  = AlertDialogConfig.serializer(),
            handleBackButton = true,
            key = keyFor(::alertDialog),
            childFactory = ::alertDialogFactory,
        )

    override val console: Value<ChildSlot<*, BlueprintLogsComponent>> =
        childSlot(
            source = consoleNavigation,
            serializer = PaneConfig.serializer(),
            handleBackButton = true,
            key = keyFor(::console),
            childFactory = { config, context ->
                logcatComponent(context)
            },
        )

    private val blueprintSaveController: BlueprintSaveController =
        instanceKeeper.getOrCreateSimple(keyFor(::blueprintSaveController)) {
            BlueprintSaveController(
                isAutoSaveEnabled = settings.value::isAutoSaveEnabled,
                bot = bot,
                blueprintData = ::blueprintData,
                onSaveBlueprint = blueprintManager::save,
                repository = blueprintsRepository
            )
        }

    private val logcat : BlueprintLogcat = instanceKeeper
        .getOrCreateSimple(keyFor(::logcat)) {
            DefaultBlueprintLogcat()
        }

    init {
        // save blueprint state on process death
        stateKeeper.register(blueprintKey, BlueprintData.serializer()) {
            blueprintData.copy(
                blueprint = blueprintSaveController.encodeToJson(blueprintManager.save())
            )
        }

        // autosave with debounce
        dispatchers.launchIO(componentScope) {
            snapshotFlow {
                blueprintManager.components.values
                    .maxOfOrNull { it.lastChange.value }
            }.debounce(AUTOSAVE_DEBOUNCE).collect {
                blueprintSaveController.tryAutoSave()
            }
        }
        // save remotely on exit
        lifecycle.doOnStop {
            dispatchers.launchIO(rootCoroutineScope) {
                blueprintSaveController.tryAutoSave(
                    forceRemote = true
                )
            }
        }

        // hardware keyboard hotkeys
        dispatchers.launchUIImmediate(componentScope) {
            keyEventFlow
                .filter { it.event.type == KeyEventType.KeyDown }
                .collect {
                    when {
                        it.event.key == Key.Escape -> {
                            blueprintManager.clearSelection()
                        }

                        // on Spacebar when blueprint is loaded
                        !it.isInputInProgress &&
                                it.event.key == Key.Spacebar &&
                                blueprintManager.components.isNotEmpty() -> {
                            onAddNodeClicked()
                        }

                        // on system undo action
                        !it.isInputInProgress && it.event.key == Key.Z &&
                                (it.event.isMetaPressed || it.event.isCtrlPressed) -> {
                            onUndoClicked()
                        }
                    }
                }
        }

        // load blueprint and launch bot
        loadAndLaunch(allowRestore = false)

        // show blueprint guide if it wasn't seen before
        if (!settings.value.isBlueprintGuideSeen) {
            dispatchers.launchUIImmediate(componentScope) {
                delay(500)
                onShowBlueprintGuide()
            }
        }
    }

    override fun onAddNodeClicked() {
        componentDialogNavigation.activate(ComponentDialogConfig.Store)
    }

    override fun onContentDialogDismiss() {
        componentDialogNavigation.dismiss()
    }

    override fun onSaveClicked() {
        dispatchers.launchIO(componentScope) {
            state.update {
                it.copy(
                    controlsEnabled = false,
                    isLoading = true
                )
            }
            try {
                val msg = when(blueprintSaveController.save()){
                    SaveResult.Success -> {
                        SnackbarMessage(
                            idiom = SnackbarIdiom.Success,
                            message = SharedRes.strings.blueprint_save_success.desc()
                        )
                    }
                    SaveResult.UnresolvedCorruptedNodes -> {
                        SnackbarMessage(
                            idiom = SnackbarIdiom.Error,
                            message = SharedRes.strings.blueprint_save_corrupted.desc()
                        )
                    }
                }

                onMessage(msg)
            } finally {
                state.update {
                    it.copy(
                        controlsEnabled = true,
                        isLoading = false
                    )
                }
            }
        }
    }

    override fun onUndoClicked() {
        if (state.value.canUndo) {
            blueprintBackstack.removeLastOrNull()
            blueprintBackstack.lastOrNull()?.let {
                blueprintManager.load(it)
            }
            state.update {
                it.copy(canUndo = blueprintBackstack.size > 1)
            }
        }
    }

    override fun onLogsClicked() {
        if (console.value.child?.configuration != null){
            consoleNavigation.dismiss()
            return
        } else {
            consoleNavigation.activate(PaneConfig)
        }
    }

    override fun onBack() {
        onFinished.invoke()
    }

    private fun loadAndLaunch(
        allowRestore : Boolean
    ) {
        dispatchers.launchIO(
            scope = componentScope,
            key = keyFor(::loadAndLaunch)
        ) {
            val blueprint = kotlin.runCatching {
                blueprintSaveController.decodeFromJson(blueprintData.blueprint)
            }.getOrElse {
                dispatchers.runOnUI {
                    alertNavigation.activate(AlertDialogConfig.BlueprintUnavailable)
                }
                return@launchIO
            }

            if (blueprint.hasCorruptedNodes && !allowRestore) {
                dispatchers.runOnUI {
                    alertNavigation.activate(AlertDialogConfig.BlueprintUnavailable)
                }
                return@launchIO
            }

            dispatchers.runOnUI {
                blueprintManager.load(blueprint)
            }

            blueprintBackstack.add(blueprint)

            if (bot.isRealtimeUpdatesEnabled) {
                withContext(
                    // почему так? да хуй его знает.
                    // подругому конченные эксепшены из блюпринтов не ловятся
                    currentCoroutineContext() + CoroutineExceptionHandler { _, throwable ->
                        if (throwable is BlueprintException) {
                            logBlueprintError(throwable)
                        } else {
                            errorHandler.handle(throwable)
                        }
                    }
                ) {
                    TelegramDebugBotManager(
                        bot = bot,
                        customFunctionsRepository = customFunctionsRepository,
                        subscriptionType = {
                            userRepository.currentUser?.subscriptionType
                                ?: SubscriptionType.Free
                        },
                        logcat = logcat,
                        blueprintManager = blueprintManager,
                        dispatchers = dispatchers,
                        notificationContext = notificationContext
                    ).launch()
                }
            }
        }
    }

    private fun logBlueprintError(error : BlueprintException) {
        dispatchers.launchIO(componentScope) {
            val last = error.trace.last()
            logcat.log(
                level = BlueprintLogLevel.Error,
                msg = "[${last.component}]: ${last.error}"
            )
            blueprintErrors.emit(BlueprintError(last.nodeId))

            val isConsoleOpen = console.value.child?.configuration != null

            if (!isConsoleOpen) {
                onMessage(
                    SnackbarMessage(
                        idiom = SnackbarIdiom.Error,
                        duration = SnackbarDuration.Short,
                        message = StringDesc.FormattedFix(
                            SharedRes.strings.error_blueprint_node,
                            last.component
                        )
                    )
                )
            }
        }
    }

    private fun componentDialogFactory(
        config: ComponentDialogConfig,
        context: ComponentContext
    ) : BlueprintComponent.ComponentDialog<*> = when (config) {

        ComponentDialogConfig.Store -> BlueprintComponent.ComponentDialog
            .Store(storeComponent(context))
    }

    private fun alertDialogFactory(
        config: AlertDialogConfig,
        context: ComponentContext
    ) : BlueprintComponent.AlertDialog<*> = when (config) {
        AlertDialogConfig.BlueprintUnavailable -> BlueprintComponent.AlertDialog
            .BlueprintUnavailable(
                AlertConfirmationDialogComponent.Default(
                    title = SharedRes.strings.blueprint_unavailable.desc(),
                    message = SharedRes.strings.blueprint_unavailable_desc.desc(),
                    onCancel = ::onBack,
                    confirm = SharedRes.strings.restore.desc(),
                    onConfirm = {
                        alertNavigation.dismiss()
                        loadAndLaunch(allowRestore = true)
                    }
                )
            )
    }



    private fun storeComponent(context : ComponentContext) : BlueprintStoreComponent =
        DefaultBlueprintStoreComponent(
            context = context,
            errorHandler = errorHandler,
            onClose = componentDialogNavigation::dismiss,
            onAdd = { record ->
                val sub = userRepository.currentUser?.subscriptionType
                    ?: return@DefaultBlueprintStoreComponent

                componentDialogNavigation.dismiss()

                if (blueprintManager.components.size < sub.maxNodes){
                    blueprintManager.addNode(
                        record.factory(
                            Point(
                                -blueprintManager.translation.x,
                                -blueprintManager.translation.y
                            )
                        )
                    )
                } else {

                    val showUpgrade = sub < SubscriptionType.entries.last()
                    onMessage(
                        SnackbarMessage(
                            idiom = SnackbarIdiom.Error,
                            duration = SnackbarDuration.Long,
                            message = StringDesc.ResourceFormatted(
                                if (showUpgrade)
                                    SharedRes.strings.blueprint_max_nodes_reached_upgrade
                                else SharedRes.strings.blueprint_max_nodes_reached,
                                sub.maxNodes
                            ),
                            action = if (showUpgrade) {
                                SnackbarAction(
                                    name = SharedRes.strings.upgrade.desc(),
                                    onClick = onShowSubscriptionDialog
                                )
                            } else null
                        )
                    )
                }
            }
        )
    private fun logcatComponent(
        context: ComponentContext
    ) : BlueprintLogsComponent =  DefaultBlueprintLogsComponent(
        context = context,
        fontScale = settings.value.consoleFontScale,
        logcat = logcat,
        settings = settings,
        onConsolePositionChanged = onConsolePositionChanged,
        onClose = consoleNavigation::dismiss
    )

    @Serializable
    private sealed interface ComponentDialogConfig {
        @Serializable
        object Store : ComponentDialogConfig
    }

    @Serializable
    private sealed interface AlertDialogConfig {
        @Serializable
        object BlueprintUnavailable : AlertDialogConfig
    }

    @Serializable
    private object PaneConfig
}