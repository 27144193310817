package org.botdesigner.shared.domain.content.changepassword

import androidx.compose.runtime.Immutable
import dev.icerock.moko.resources.desc.StringDesc
import kotlinx.coroutines.flow.StateFlow
import kotlinx.serialization.Serializable
import org.botdesigner.shared.domain.content.StackableComponent

@Immutable
@Serializable
data class ChangePasswordViewState(
    val oldPassword : String = "",
    val newPassword : String = "",
    val oldPasswordError : StringDesc? = null,
    val newPasswordError : StringDesc? = null,
    val isChangingInProgress : Boolean = false
)

val ChangePasswordViewState.isChangeEnabled : Boolean
    get() = oldPassword.isNotEmpty() && newPassword.isNotEmpty() && !isChangingInProgress

interface ChangePasswordComponent : StackableComponent {

    val state: StateFlow<ChangePasswordViewState>

    fun onOldPasswordChanged(value: String)

    fun onNewPasswordChanged(value: String)

    fun onChangeClicked()
}