package org.botdesigner.shared.domain.content.tabs

import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.pages.ChildPages
import com.arkivanov.decompose.value.Value
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.ChildComponent
import org.botdesigner.shared.domain.content.list.BotsListComponent
import org.botdesigner.shared.domain.content.profile.ProfileComponent

@OptIn(ExperimentalDecomposeApi::class)
interface TabsComponent {

    val tabs : Value<ChildPages<*, Tab<*>>>

    fun onTabSelected(index : Int)


    sealed interface Tab<T> : ChildComponent<T> {

        val title : StringDesc

        class BotList(
            override val component: BotsListComponent
        ) : Tab<BotsListComponent> {

            override val title: StringDesc
                get() = SharedRes.strings.bots.desc()
        }

        class Settings(
            override val component : ProfileComponent
        ) : Tab<ProfileComponent> {

            override val title: StringDesc
                get() = SharedRes.strings.settings.desc()
        }
    }
}