package org.botdesigner.blueprint.components.functions

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Shape
import kotlinx.serialization.Contextual
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.BlueprintPinsOwner
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.components.PinsMutationScope
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.ui.components.Expression

typealias ExpressionNFactory<T> = (
    coordinate: Point,
    id : Id,
    pins : List<Pin<@Contextual Any?>>,
) -> BpExpressionN<T>

@kotlinx.serialization.Serializable
abstract class BpExpressionN<T> : BpExpression() {

    abstract val pinFactory: PinFactory<T>

    final override val isExtendable: Boolean
        get() = true

    abstract override val factory: ExpressionNFactory<T>

    abstract suspend fun calculateCasted(value: List<T>): List<*>


    @Suppress("UNCHECKED_CAST")
    final override suspend fun calculate(input: List<*>): List<*> {
        return calculateCasted(input as List<T>)
    }

    override fun isAddEnabled(state: BlueprintPinsOwner): Boolean {
        return state.pins.size < 8
    }

    override fun isRemoveEnabled(state: BlueprintPinsOwner): Boolean {
        return state.pins.size > 3
    }

    override fun PinsMutationScope.onAddPins(pool: BlueprintNodesPool) {
        if (this@onAddPins.pins.size < 8) {
            appendPin {
                pinFactory.create(
                    id = it,
                    order = pins.size.toUInt(),
                    elementId = id,
                    name = "",
                    isOut = false,
                    required = pins
                        .filter { !it.isOut }
                        .getOrNull(0)
                        ?.required == true
                )
            }
        }
    }

    override fun PinsMutationScope.onRemovePins(pool: BlueprintNodesPool) {
        if (this@onRemovePins.pins.size > 3) {
            val pin = this@onRemovePins.pins.last { !it.isOut } // DON'T USE inputPins here. pins from the scope must be used!!

            removePin(pin)
        }
    }

    final override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> {
        return object : BpFunctionStateHolder<BpExpressionN<T>>(
            element = this,
            manager = manager
        ) {
            @Composable
            override fun Component(
                manager: BlueprintManager,
                modifier: Modifier,
                fieldSize: Float,
                shape: Shape,
                also: @Composable (Float) -> Unit
            ) = Expression(
                modifier = modifier,
                expression = expression,
                manager = manager,
                fieldSize = fieldSize,
                shape = shape,
                also = { also(0f) }
            )
        }
    }
}