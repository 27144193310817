package org.botdesigner.shared.util

class TaggedException(
    tag : String,
    cause: Throwable?
) : Exception(tag,cause)

interface Logger {

    enum class LogLevel {
        Warning,
        Error,
    }

    fun log(
        msg : String,
        tag : String,
        logLevel: LogLevel = LogLevel.Warning,
        cause: Throwable?=null,
    )
}