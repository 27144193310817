package org.botdesigner.ui.screens.list

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import dev.icerock.moko.resources.AssetResource
import dev.icerock.moko.resources.compose.readTextAsState
import io.github.alexzhirkevich.compottie.LottieAnimation
import io.github.alexzhirkevich.compottie.LottieCompositionSpec
import io.github.alexzhirkevich.compottie.LottieConstants
import io.github.alexzhirkevich.compottie.rememberLottieComposition
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTextButton
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import org.botdesigner.resources.SharedRes
import org.botdesigner.sharedui.MR
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

@Composable
@NonRestartableComposable
internal fun BotsError(
    text : String,
    title: String?,
    onRefresh : (() -> Unit)? = null,
    modifier: Modifier = Modifier,
) = BotsMessage(
    modifier = modifier,
    lottie = MR.assets.robot_error,
    text = text,
    title = title,
    onRefresh = onRefresh,
)


@Composable
@NonRestartableComposable
internal fun BotsGreeting(
    text : String,
    modifier: Modifier = Modifier,
) = BotsMessage(
    modifier = modifier,
    size = 200.dp,
    lottie = MR.assets.robot_hello,
    title = null,
    text = text
)

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
private fun BotsMessage(
    modifier: Modifier,
    size : Dp = 150.dp,
    lottie : AssetResource,
    title: String?,
    text : String,
    onRefresh : (() -> Unit)? = null,
) {

    val lottieJson by lottie.readTextAsState()

    Column(
        modifier = modifier
            .padding(horizontal = Dimens.Spacing.Medium),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Box(Modifier.size(size)) {
            lottieJson?.let {
                val composition by rememberLottieComposition(
                    LottieCompositionSpec.JsonString(it)
                )

                LottieAnimation(
                    modifier = Modifier.fillMaxSize(),
                    composition = composition,
                    iterations = LottieConstants.IterateForever
                )
            }
        }
        Spacer(Modifier.height(Dimens.Spacing.Medium))
        if (title != null) {
            Text(
                text = title,
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.titleLarge,
                fontWeight = FontWeight.Bold,
            )
            Spacer(Modifier.height(Dimens.Spacing.Medium))
        }
        Text(
            text = text,
            textAlign = TextAlign.Center,
            maxLines = 3,
            overflow = TextOverflow.Ellipsis
        )

        onRefresh?.let {
            Spacer(Modifier.height(Dimens.Spacing.Medium))
            AdaptiveTextButton(
                onClick = it
            ){
                Text(string(SharedRes.strings.refresh))
            }
        }
    }
}


