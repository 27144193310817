package org.botdesigner.shared.domain.content.create

import androidx.compose.runtime.Immutable
import dev.icerock.moko.resources.desc.StringDesc
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.core.BotType
import org.botdesigner.shared.domain.content.StackableComponent

@Immutable
data class CreateOrEditBotViewState(
    val name : String = "",
    val token : String = "",
    val debugToken : String = "",
    val isLoading : Boolean = false,
    val type : BotType = BotType.Telegram,
    val tokenError : StringDesc? = null,
    val debugTokenError : StringDesc? = null,
)
val CreateOrEditBotViewState.createEnabled : Boolean
    get() = name.isNotBlank() &&
        token.isNotBlank() &&
        tokenError == null &&
        debugTokenError == null

interface CreateOrEditBotComponent : StackableComponent {

    val isEdit : Boolean

    val state : StateFlow<CreateOrEditBotViewState>

    fun onNameChanged(value : String)

    fun onTokenChanged(value : String)

    fun onDebugTokenChanged(value : String)

    fun onTokenDescriptionHelpClicked()

    fun onCreateClicked()

}

