package org.botdesigner.shared.data.repo.impl

import dev.icerock.moko.resources.desc.Resource
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.channelFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.emptyFlow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEmpty
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.flow.retryWhen
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import kotlinx.coroutines.supervisorScope
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.util.UIState

fun <T> Flow<T>.collectAsDataState(
    map : suspend (value : T) -> UIState<T> = { UIState.Success(it) },
    autoRetry : Boolean = true,
    retryTrigger : Flow<Any?> = emptyFlow(),
    onError : (t : Throwable) -> UIState.Error? = {
        UIState.Error(
            error = it,
            title = SharedRes.strings.error_oops.desc(),
            message = SharedRes.strings.error_something_went_wrong.desc(),
        )
    },
) : Flow<UIState<T>> {
    val dataFlow = map(map)
        .onEmpty { emit(UIState.Empty()) }
        .onStart { emit(UIState.Loading) }
        .retryWhen { _, _ ->
//            onError(cause)?.let { e -> emit(e) }
            autoRetry
        }
        .catch {
            onError(it)?.let { e -> emit(e) }
        }

    return channelFlow {
        coroutineScope {
            var job = launch {
                dataFlow.collect(::send)
            }
            retryTrigger.collect {
                job.cancel()
                job = launch {
                    dataFlow.collect(::send)
                }
            }
        }
    }
}

fun <T> Flow<T>.collectAsDataState(
    stateIn : CoroutineScope,
    initial : UIState<T> = UIState.Loading,
    map : suspend (value : T) -> UIState<T> = { UIState.Success(it) },
    autoRetry : Boolean = true,
    retryTrigger : Flow<*> = emptyFlow<Any?>(),
    onError : (t : Throwable) -> UIState.Error? = {
        UIState.Error(
            error = it,
            title = SharedRes.strings.error_oops.desc(),
            message = SharedRes.strings.error_something_went_wrong.desc(),
        )
    },
) : StateFlow<UIState<T>> {
    return collectAsDataState(
        map = map,
        retryTrigger = retryTrigger,
        autoRetry = autoRetry,
        onError = onError
    ).stateIn(
        scope = stateIn,
        started = SharingStarted.Eagerly,
        initialValue = initial
    )
}


internal suspend fun <T> Data(
    data : T ? = null,
    remote: suspend () -> T,
    save: suspend (T) -> Unit
) : T  = supervisorScope {
    val firstSaveJob = if (data != null) {
        launch {
            save(data)
        }
    } else null

    remote().also {
        firstSaveJob?.join()
        save(it)
    }
}

internal sealed class UpdateSource(vararg val keys : Any?) {
    class Local(vararg keys : Any?) : UpdateSource(*keys)
    class Remote(vararg keys : Any?) : UpdateSource(*keys)
    class Both(vararg keys : Any?) : UpdateSource(*keys)
}

internal fun <T> DataFlow(
    id : String? = null,
    updateTrigger : Flow<UpdateSource>? = null,
    cache : suspend () -> T,
    remote : suspend () -> T,
    merge : suspend (cache : T, remote : T) -> T = { _, r -> r },
    preTransform : suspend (T) -> T = { it },
    postTransform : suspend (T) -> T = { it },
    save : suspend (T) -> Unit
) = channelFlow {
    val emitAction : suspend (UpdateSource) -> Unit = { source ->
        supervisorScope {
            val remoteDef = if (source !is UpdateSource.Local) async {
                preTransform(remote())
            } else null

            val cacheDef = if (source !is UpdateSource.Remote) async {

                val c = cache()

                if (c is Iterable<*> && !c.any())
                    return@async null

                preTransform(cache()).also {
                    if (remoteDef?.isCompleted != true) {
                        send(postTransform(it))
                    }
                }
            } else null

            val cached = kotlin.runCatching {
                cacheDef?.await()
            }.getOrNull()


            val rem = remoteDef?.await()

            val merged = if (rem != null && cached != null) {
                merge(cached, rem)
            } else rem ?: cached ?: return@supervisorScope

            val post = postTransform(merged)

            send(post)

            runCatching {
                save(post)
            }
        }
    }

    emitAction(UpdateSource.Both(id))

    updateTrigger?.collectLatest {
        emitAction(it)
    }
}
