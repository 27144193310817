package org.botdesigner.ui.common.images

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

internal val Images.LogoCLoud: ImageVector
    get() {
        if (_logoLightPurple != null) {
            return _logoLightPurple!!
        }
        _logoLightPurple = Builder(name = "LogoCloud", defaultWidth = 717.0.dp, defaultHeight
                = 703.0.dp, viewportWidth = 717.0f, viewportHeight = 703.0f).apply {
            path(fill = SolidColor(Color(0xFFffffff)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(391.87f, 588.31f)
                curveTo(439.89f, 554.3f, 480.64f, 508.61f, 504.38f, 462.14f)
                curveTo(534.14f, 403.88f, 541.54f, 339.51f, 524.37f, 288.18f)
                curveTo(504.48f, 228.75f, 455.14f, 185.39f, 392.0f, 171.86f)
                curveTo(377.58f, 168.77f, 341.88f, 168.5f, 326.5f, 171.36f)
                curveTo(303.41f, 175.66f, 274.44f, 187.74f, 256.0f, 200.77f)
                curveTo(244.85f, 208.65f, 224.7f, 228.33f, 216.85f, 239.0f)
                curveTo(200.35f, 261.43f, 189.39f, 286.92f, 184.4f, 314.5f)
                curveTo(181.65f, 329.66f, 181.88f, 359.51f, 184.86f, 375.5f)
                curveTo(191.53f, 411.32f, 207.21f, 441.32f, 232.44f, 466.57f)
                curveTo(247.07f, 481.22f, 255.65f, 487.93f, 271.0f, 496.76f)
                curveTo(295.55f, 510.89f, 319.54f, 517.8f, 351.36f, 519.92f)
                curveTo(362.84f, 520.68f, 366.01f, 521.25f, 369.85f, 523.23f)
                curveTo(375.14f, 525.95f, 380.44f, 532.32f, 381.95f, 537.78f)
                curveTo(382.53f, 539.84f, 383.0f, 551.94f, 383.01f, 565.0f)
                curveTo(383.03f, 586.69f, 383.42f, 593.0f, 384.74f, 593.0f)
                curveTo(385.02f, 593.0f, 388.23f, 590.89f, 391.87f, 588.31f)
                close()
                close()
            }
            path(fill = SolidColor(Color(0xFFE7E6E2)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(386.771f, 590.588f)
                curveTo(395.446f, 582.311f, 396.513f, 581.163f, 407.704f, 570.514f)
                curveTo(417.173f, 561.28f, 418.45f, 560.05f, 427.3f, 550.7f)
                curveTo(452.95f, 523.75f, 470.95f, 498.6f, 485.9f, 468.7f)
                curveTo(497.45f, 445.6f, 506.5f, 418.15f, 510.0f, 395.5f)
                curveTo(512.8f, 377.25f, 513.25f, 371.75f, 513.25f, 355.5f)
                curveTo(513.2f, 341.75f, 513.0f, 338.75f, 511.45f, 327.75f)
                curveTo(510.2f, 318.55f, 507.25f, 306.55f, 503.9f, 296.75f)
                curveTo(495.15f, 271.2f, 480.25f, 247.6f, 460.7f, 228.5f)
                curveTo(444.25f, 212.4f, 428.55f, 201.75f, 407.05f, 192.1f)
                curveTo(401.8f, 189.7f, 383.7f, 183.8f, 377.25f, 182.3f)
                curveTo(360.35f, 178.5f, 339.65f, 177.05f, 322.35f, 178.5f)
                curveTo(310.9f, 179.45f, 294.6f, 182.7f, 291.534f, 183.541f)
                curveTo(287.491f, 184.47f, 286.351f, 184.749f, 282.225f, 185.812f)
                curveTo(290.832f, 181.714f, 294.864f, 179.916f, 301.952f, 177.171f)
                curveTo(315.676f, 173.096f, 319.394f, 172.27f, 329.346f, 170.904f)
                curveTo(349.049f, 168.88f, 356.398f, 168.372f, 372.982f, 169.272f)
                curveTo(393.239f, 171.158f, 410.569f, 175.268f, 427.527f, 182.296f)
                curveTo(443.933f, 188.979f, 451.169f, 192.636f, 464.653f, 203.144f)
                curveTo(475.927f, 212.132f, 482.4f, 217.549f, 494.424f, 231.234f)
                curveTo(513.967f, 258.729f, 516.854f, 262.494f, 526.101f, 290.446f)
                curveTo(532.642f, 316.196f, 534.148f, 328.24f, 534.25f, 354.423f)
                curveTo(532.494f, 382.525f, 530.345f, 398.8f, 524.641f, 417.573f)
                curveTo(517.589f, 438.945f, 515.047f, 444.573f, 501.213f, 469.632f)
                curveTo(479.586f, 503.53f, 473.393f, 512.753f, 447.9f, 540.8f)
                curveTo(431.097f, 557.878f, 425.276f, 562.907f, 413.208f, 573.272f)
                curveTo(407.772f, 578.128f, 400.426f, 583.527f, 393.25f, 588.3f)
                curveTo(387.75f, 592.15f, 386.6f, 592.5f, 385.232f, 593.06f)
                curveTo(384.788f, 593.109f, 384.506f, 593.041f, 384.317f, 592.899f)
                close()
            }
            path(fill = SolidColor(Color(0xFF44448C)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(231.5f, 344.5f)
                arcToRelative(30.0f, 30.0f, 0.0f, true, false, 60.0f, 0.0f)
                arcToRelative(30.0f, 30.0f, 0.0f, true, false, -60.0f, 0.0f)
                close()
            }
            path(fill = SolidColor(Color(0xFF44448C)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(328.0f, 344.5f)
                arcToRelative(30.0f, 30.0f, 0.0f, true, false, 60.0f, 0.0f)
                arcToRelative(30.0f, 30.0f, 0.0f, true, false, -60.0f, 0.0f)
                close()
            }
            path(fill = SolidColor(Color(0xFF44448C)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(424.5f, 344.5f)
                arcToRelative(30.0f, 30.0f, 0.0f, true, false, 60.0f, 0.0f)
                arcToRelative(30.0f, 30.0f, 0.0f, true, false, -60.0f, 0.0f)
                close()
            }
            path(fill = SolidColor(Color(0xFF44448C)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(424.5f, 344.5f)
                arcToRelative(30.0f, 30.0f, 0.0f, true, false, 60.0f, 0.0f)
                arcToRelative(30.0f, 30.0f, 0.0f, true, false, -60.0f, 0.0f)
                close()
            }
        }
        .build()
        return _logoLightPurple!!
    }

private var _logoLightPurple: ImageVector? = null
