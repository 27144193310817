package org.botdesigner.ui.screens.bot.bptype

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsetsSides
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.navigationBarsPadding
import androidx.compose.foundation.layout.only
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ScaffoldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.zIndex
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveHorizontalDivider
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import kotlinx.coroutines.launch
import org.botdesigner.blueprint.components.Id
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.botblueprints.BlueprintType
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.bot.bptype.BlueprintTypeSelectionDialogComponent
import org.botdesigner.ui.common.PlatformDialogState
import org.botdesigner.ui.common.PlatformDialogTopBar
import org.botdesigner.ui.common.PlatformVerticalScrollbar
import org.botdesigner.ui.screens.bot.BlueprintItem
import org.botdesigner.ui.screens.bot.BlueprintItemIconSize
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable

@Composable
@OptIn(ExperimentalMaterial3Api::class)
internal fun BlueprintTypeSelectionDialog(
    component: BlueprintTypeSelectionDialogComponent,
    state : PlatformDialogState
) {

    val scope = rememberCoroutineScope()

    NewBlueprintSelectionDialog(
        availableTypes = component.blueprintTypes,
        enabled = true,
        onClose = {
            scope.launch {
                state.hide()
            }.invokeOnCompletion {
                component.onCancel()
            }
        }
    ) {
        scope.launch {
            state.hide()
        }.invokeOnCompletion {
            component.onCancel()
        }
        component.onSelected(it)
    }
}

private val BlueprintDividerPadding = BlueprintItemIconSize +
        Dimens.Spacing.Small  +
        Dimens.Spacing.Large

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
private fun BlueprintDivider() {
    AdaptiveHorizontalDivider(
        modifier = Modifier.padding(
            start = BlueprintDividerPadding
        )
    )
}

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
private fun NewBlueprintSelectionDialog(
    modifier: Modifier = Modifier,
    availableTypes: List<Pair<BlueprintType, Boolean>>?,
    enabled: Boolean,
    onClose: () -> Unit,
    onSelected: (BlueprintData) -> Unit
) {

    val types = remember(availableTypes) {
        availableTypes.orEmpty().map {
            BlueprintData(
                id = Id.uuid().id,
                type = it.first,
                blueprint = ""
            ) to it.second
        }
    }

    AdaptiveScaffold(
        modifier = modifier,
        contentWindowInsets = ScaffoldDefaults.contentWindowInsets.only(
            WindowInsetsSides.Start + WindowInsetsSides.End
        ),
        containerColor = Color.Transparent,
        topBar = {
            PlatformDialogTopBar(
                title = {
                    Text(string(SharedRes.strings.new_blueprint))
                },
                onDismissRequest = onClose
            )
        }
    ) {
        val lazyListState = rememberLazyListState()

        Box(Modifier.padding(it)) {
            PlatformVerticalScrollbar(
                scrollableState = lazyListState,
                modifier=  modifier
                    .zIndex(1f)
                    .align(Alignment.CenterEnd)
            )
            LazyColumn(
                state = lazyListState,
                modifier = Modifier
                    .fillMaxWidth(),
            ) {
                if (availableTypes != null) {
                    items(items = types, key = { it.first.id }) {
                        Column {

                            Box(
                                Modifier
                                    .clickable(
                                        enabled = enabled && it.second,
                                    ) {
                                        onSelected(it.first.copy())
                                    }.padding(
                                        horizontal = Dimens.Spacing.Large,
                                        vertical = Dimens.Spacing.Medium
                                    ).alpha(if (it.second) 1f else .5f)
                            ) {
                                BlueprintItem(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .pointerHoverIcon(PointerIcon.PlatformClickable),
                                    blueprint = it.first,
                                    isNew = true
                                )
                            }
                            BlueprintDivider()
                        }
                    }
                } else {
                    repeat(5) {
                        item {
                            BlueprintItem(
                                null,
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .padding(
                                        horizontal = Dimens.Spacing.Large,
                                        vertical = Dimens.Spacing.Medium
                                    )
                            )
                        }
                    }
                }
                item {
                    Spacer(Modifier.navigationBarsPadding().imePadding())
                }
            }
        }
    }
}
