@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.float

import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Double
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpExpressionN
import org.botdesigner.blueprint.components.functions.ExpressionNFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpDoubleSum")
public class BpDoubleSum(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.DoublePinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "",
              isOut = i,
              required = true
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.DoublePinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
) : BpExpressionN<Double>() {
  override val summary: AnnotatedString
    get() = "Sum a bunch of float numbers".trimIndent().toAnnotatedString()

  override val name: String
    get() = "Sum"

  override val factory: ExpressionNFactory<Double>
    get() = ::BpDoubleSum

  override val expression: String
    get() = "+"

  override val pinFactory: PinFactory<Double>
    get() = org.botdesigner.blueprint.stdlib.pins.DoublePinFactory

  override suspend fun calculateCasted(input: List<Double>): List<Any?> =
      listOf(DoubleSum(*input.toDoubleArray()))
}
