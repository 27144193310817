package org.botdesigner.blueprint.io.network

import io.ktor.client.HttpClient
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.request.forms.ChannelProvider
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.header
import io.ktor.client.request.request
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.bodyAsChannel
import io.ktor.http.HttpHeaders
import io.ktor.http.HttpMethod
import io.ktor.http.Url
import io.ktor.util.cio.toByteArray
import io.ktor.util.toMap
import io.ktor.utils.io.ByteReadChannel
import org.botdesigner.blueprint.generator.BlueprintProcedure
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.generator.Tuple
import org.botdesigner.blueprint.generator.Tuple3
import org.botdesigner.blueprint.io.context.NetworkContext
import org.botdesigner.blueprint.store.BlueprintNodeCategory

enum class BpHttpMethod(val method: HttpMethod) {
    GET(HttpMethod.Get),
    POST(HttpMethod.Post),
    DELETE(HttpMethod.Delete),
    PUT(HttpMethod.Put),
    PATCH(HttpMethod.Patch),
    HEAD(HttpMethod.Head),
    OPTIONS(HttpMethod.Options),
}

@BlueprintProcedure(
    serialName = "BpSendHttpRequest",
    displayName = "Send HTTP Request",
    summary = "Perform HTTP <b>Method</b> Request on <b>URL</b> with a list of <b>Headers</b> and a request <b>Body</b>. <b>Response Body</b> is decoded to UTF-8 string and is limited to 1MB",
    category = BlueprintNodeCategory.NetworkName,
    subCategory = BlueprintNodeCategory.HttpName
)
internal suspend inline fun NetworkContext.SendHttpRequest(
    URL : String,
    Method : BpHttpMethod,
    Headers : Iterable<String>?,
    Body : String?
) : Tuple3<
    @Pin("Response Code") Long,
    @Pin("Response Headers") Iterable<String>,
    @Pin("Response Body") String
> {

    if (!validateUrl(URL)) {
        throw IllegalArgumentException("Request for '$URL' is forbidden")
    }

    val resp = httpClient.performRequest(
        url = URL,
        method = Method,
        headers = Headers,
        body = Body
    )
    return Tuple(
        resp.status.value.toLong(),
        resp.headers.toMap().flatMap { (k, v) -> v.map { "$k:$it" } },
        resp.bodyAsChannel()
            .toByteArray(limit = REQUEST_LIMIT_BYTES)
            .decodeToString()
    )
}


@BlueprintProcedure(
    serialName = "BpSendHttpRequestDownload",
    displayName = "Stream HTTP Request",
    summary = "Stream HTTP request. You can use it to download images, files. <b>Response Body</b> is a byte stream. NOTE: you can read this byte stream ONLY 1 TIME",
    category = BlueprintNodeCategory.NetworkName,
    subCategory = BlueprintNodeCategory.HttpName
)
internal suspend inline fun NetworkContext.SendHttpRequestDownload(
    URL : String,
    Method : BpHttpMethod,
    Headers : Iterable<String>?,
    Body : String?
) : Tuple3<
    @Pin("Response Code") Long,
    @Pin("Response Headers") Iterable<String>,
    @Pin("Response Body") ByteReadChannel
> {

    if (!validateUrl(URL)) {
        throw IllegalArgumentException("Request for '$URL' is forbidden")
    }

    val resp = httpClient.performRequest(
        url = URL,
        method = Method,
        headers = Headers,
        body = Body
    )

    return Tuple(
        resp.status.value.toLong(),
        resp.headers.toMap().flatMap { (k, v) -> v.map { "$k:$it" } },
        resp.bodyAsChannel()
    )
}

@BlueprintProcedure(
    serialName = "BpSendHttpRequestUpload",
    displayName = "Multipart HTTP Upload",
    summary = "Multipart form data upload request. You can provide optional <b>Multipart Key</b> for body (by default 'data' will be used). <b>Response Body</b> is decoded to UTF-8 string and is limited to 1MB",
    category = BlueprintNodeCategory.NetworkName,
    subCategory = BlueprintNodeCategory.HttpName
)
internal suspend inline fun NetworkContext.SendHttpRequestUpload(
    URL : String,
    Method : BpHttpMethod,
    Headers : Iterable<String>?,
    @Pin("Multipart Key") multipartKey : String?,
    @Pin("File Name")fileName : String?,
    Body : ByteReadChannel?,
    @Pin("Extra Form Data") extraBody : Any?,
) : Tuple3<
    @Pin("Response Code") Long,
    @Pin("Response Headers") Iterable<String>,
    @Pin("Response Body") String
> {

    if (!validateUrl(URL)) {
        throw IllegalArgumentException("Request for '$URL' is forbidden")
    }

    val resp = httpClient.performRequest(
        url = URL,
        method = Method,
        headers = Headers,
        body = Body?.let {
            MultiPartFormDataContent(
                formData {
                    append(
                        key = multipartKey.takeIf { !it.isNullOrBlank() } ?: "",
                        value = ChannelProvider { it },
                        headers = io.ktor.http.Headers.build {
                            if (fileName != null){
                                append(HttpHeaders.ContentDisposition, "filename=\"$fileName\"")
                            }
                        }
                    )
                }
            )
        }
    )

    return Tuple(
        resp.status.value.toLong(),
        resp.headers.toMap().flatMap { (k, v) -> v.map { "$k:$it" } },
        resp.bodyAsChannel()
            .toByteArray(limit = REQUEST_LIMIT_BYTES)
            .decodeToString()
    )
}


internal suspend inline fun <reified T> HttpClient.performRequest(
    url : String,
    method : BpHttpMethod,
    headers : Iterable<String>?,
    body : T?
): HttpResponse {
    return try {
        request {
            url(url)
            headers
                ?.map { it.substringBefore(":").trim() to it.substringAfter(":").trim() }
                ?.filter { it.first.isNotBlank() && it.second.isNotBlank() }
                ?.forEach {
                    header(it.first, it.second)
                }
            this.method = method.method
            if (body != null) {
                setBody<T>(body)
            }
        }
    } catch (e: ClientRequestException) {
        e.response
    }
}

internal fun validateUrl(url: String): Boolean {
    if (!url.startsWith("https://") && !url.startsWith("http://"))
        return false

    val host = Url(url).host

    if (host.startsWith("localhost", true)){
        return false
    }
    if (!host.matches(ipRegex)){
        return true
    }

    return !forbiddenIps.any(host::startsWith)
}

internal const val REQUEST_LIMIT_BYTES = 1024 * 1024

private val forbiddenIps by lazy {
    listOf("0.", "10.", "127.", "192.168.")
}

private val ipRegex = Regex("^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}\$")
