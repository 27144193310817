package org.botdesigner.ui.common.dialogs

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.ProvideTextStyle
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.text.style.TextAlign
import io.github.alexzhirkevich.LocalContentColor
import io.github.alexzhirkevich.cupertino.AlertActionStyle
import io.github.alexzhirkevich.cupertino.CupertinoBorderedTextField
import io.github.alexzhirkevich.cupertino.CupertinoBorderedTextFieldDefaults
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveAlertDialog
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveWidget
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.cancel
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.ui.common.NullableComposable
import org.botdesigner.ui.string

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal fun ConfirmationDialog(
    title : String,
    text : String? = null,
    content : (@Composable () -> Unit)? = null,
    ok : String = string(SharedRes.strings.ok),
    cancel : String = string(SharedRes.strings.cancel),
    onCancel : (() -> Unit) ?= null,
    onOk : (() -> Unit) ?= null,
    isDestructive  : Boolean = false,
    onDismiss : () -> Unit,
) {
    AdaptiveAlertDialog(
        onDismissRequest = onDismiss,
        message = NullableComposable(text != null || content != null) {
            CompositionLocalProvider(
                LocalContentColor provides MaterialTheme.colorScheme.onSurface
            ) {
                Column {
                    if (text != null) {
                        Text(
                            text = text,
                            color = MaterialTheme.colorScheme.onSurface
                        )
                    }
                    content?.invoke()
                }
            }
        },
        title = {
            Text(
                text = title,
                color = MaterialTheme.colorScheme.onSurface
            )
        },
        adaptation = {

            if (isDestructive) {
                material {
                    confirmButton = { _, _, onClick, content ->
                        Button(
                            colors = ButtonDefaults.buttonColors(
                                containerColor = MaterialTheme.colorScheme.error,
                                contentColor = MaterialTheme.colorScheme.onError,
                            ),
                            onClick = onClick
                        ) {
                            content()
                        }
                    }
                    dismissButton = { _, _, onClick, content ->
                        TextButton(
                            onClick = onClick
                        ) {
                            content()
                        }
                    }
                }
            }
        }
    ) {
        if (onCancel != null) {
            cancel(onClick = onCancel) {
                Text(
                    text = cancel,
                    color = if (CurrentPlatform != Platform.Android)
                        MaterialTheme.colorScheme.primary
                    else Color.Unspecified
                )
            }
        }
        if (onOk != null) {
            action(
                style = if (isDestructive)
                    AlertActionStyle.Destructive
                else AlertActionStyle.Default,
                onClick = onOk
            ) {
                Text(
                    text = ok,
                    color = if (!isDestructive && CurrentPlatform != Platform.Android)
                        MaterialTheme.colorScheme.primary
                    else Color.Unspecified
                )
            }
        }
    }
}

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
fun ConfirmationDialogTextField(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    readOnly: Boolean = false,
    placeholder: @Composable (() -> Unit)? = null,
    leadingIcon: @Composable (() -> Unit)? = null,
    trailingIcon: @Composable (() -> Unit)? = null,
    isError: Boolean = false,
    visualTransformation: VisualTransformation = VisualTransformation.None,
    keyboardOptions: KeyboardOptions = KeyboardOptions.Default,
    keyboardActions: KeyboardActions = KeyboardActions.Default,
    singleLine: Boolean = true,
    maxLines: Int = if (singleLine) 1 else Int.MAX_VALUE,
    minLines: Int = 1,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
) {
    AdaptiveWidget(
        material = {
            OutlinedTextField(
                value = value,
                onValueChange = onValueChange,
                modifier = modifier,
                enabled = enabled,
                readOnly = readOnly,
                placeholder = placeholder,
                leadingIcon = leadingIcon,
                trailingIcon = trailingIcon,
                visualTransformation = visualTransformation,
                keyboardOptions = keyboardOptions,
                keyboardActions = keyboardActions,
                maxLines = maxLines,
                minLines = minLines,
                interactionSource = interactionSource,
                shape = MaterialTheme.shapes.medium,
            )
        },
        cupertino = {
            ProvideTextStyle(
                CupertinoTheme.typography.body
                    .copy(textAlign = TextAlign.Start)
            ) {
                CupertinoBorderedTextField(
                    value = value,
                    onValueChange = onValueChange,
                    colors= CupertinoBorderedTextFieldDefaults.colors(
                        focusedContainerColor =  CupertinoTheme.colorScheme
                            .systemBackground
                    ),
                    modifier = modifier,
                    enabled = enabled,
                    readOnly = readOnly,
                    placeholder = placeholder,
                    leadingIcon = leadingIcon,
                    trailingIcon = trailingIcon,
                    isError = isError,
                    visualTransformation = visualTransformation,
                    keyboardOptions = keyboardOptions,
                    keyboardActions = keyboardActions,
                    singleLine = singleLine,
                    maxLines = maxLines,
                    minLines = minLines,
                    interactionSource = interactionSource
                )
            }
        }
    )
}

@Composable
internal fun ConfirmationDialog(
    component: AlertConfirmationDialogComponent,
) {
    val cancel = string(SharedRes.strings.cancel)

    ConfirmationDialog(
        title = string(component.title),
        text = component.message?.let { string(it) },
        onCancel = component::onCancel,
        onOk = component::onConfirm,
        cancel = cancel,
        ok = string(component.confirm),
        onDismiss = component::onCancel,
        isDestructive = component.isDestructive
    )
}