package org.botdesigner.blueprint.components

import kotlinx.coroutines.TimeoutCancellationException
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.coroutines.cancellation.CancellationException

@Serializable
data class BlueprintExceptionTrace(
    val error : String?,
    val component : String,
    val nodeId : String,
    val trace : BlueprintExceptionTrace?
)

enum class DefaultBlueprintErrors(val message : String) {
    Timeout("Blueprint was timed out: invocation time was exceeded")
}

open class BlueprintException(
    val blueprintId : String,
    val trace : BlueprintExceptionTrace,
) : Exception(Json.encodeToString(trace))

fun BlueprintExceptionTrace.last() : BlueprintExceptionTrace = trace?.last() ?: this
fun BlueprintExceptionTrace.traceToString() : String {
    return "[$component] ${error ?: "-> ${trace?.traceToString()}"}"
}




/**
 * Called in [BlueprintNode.pinValue].
 *
 * Do not include super.pinValue in this block.
 * */
inline fun <T> BlueprintNode.pinValueWithExceptionMark(
    pin: Pin<*>,
    block : () -> T
) : T = runWithExceptionMark(
    map = {
        buildString {
            this.append("Failed to get value of '${pin.name}'")
            if (it !is BlueprintException){
                append(": ${it.message}")
            }
        }
    },
    block = block
)

inline fun <T> BlueprintNode.runWithExceptionMark(
    map : (Throwable) -> String? = {
       if (it is BlueprintException){
           null
       } else {
           it.message
       }
    },
    block : () -> T
) : T = runCatching(block)
    .getOrElse {

        if (it is CancellationException /*&& it !is TimeoutCancellationException*/)
            throw it

        throw markException(it) { t ->
            if (t is TimeoutCancellationException) {
                DefaultBlueprintErrors.Timeout.message
            } else {
                map(t)
            }
        }
    }

inline fun BlueprintNode.markException(
    t : Throwable,
    map : (Throwable) -> String?
) : BlueprintException {
    return BlueprintException(
        blueprintId = id.id,
        trace = BlueprintExceptionTrace(
            error = map(t),
            component = name,
            nodeId = id.id,
            trace = if (t is BlueprintException) t.trace else null
        )
    )
}
