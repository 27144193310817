package org.botdesigner.shared.data.source.impl

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.patch
import io.ktor.client.request.post
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.http.HttpStatusCode
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import org.botdesigner.api.CreateBotRequest
import org.botdesigner.api.CreateBotResponse
import org.botdesigner.api.GetBotResponse
import org.botdesigner.api.GetBotsResponse
import org.botdesigner.api.ToggleBotRequest
import org.botdesigner.api.UpdateBotRequest
import org.botdesigner.core.Bot
import org.botdesigner.core.BotStatus
import org.botdesigner.core.BotType
import org.botdesigner.core.toApi
import org.botdesigner.core.toCore
import org.botdesigner.shared.data.source.BotDataSource
import org.botdesigner.shared.util.runCatchingIgnoringCancellation
import org.botdesigner.telegram.TelegramClient
import org.botdesigner.telegram.getFile
import org.botdesigner.telegram.getMe
import org.botdesigner.telegram.getUserProfilePhotos


internal class RemoteBotDataSource(
    private val httpClient: HttpClient
) : BotDataSource {
    override suspend fun getAll(): List<Bot> {
        return coroutineScope {
            httpClient
                .get("/v1/bots")
                .body<GetBotsResponse>()
                .bots
                .map {
                    async {
                        it.toCore().extended()
                    }
                }.awaitAll()
        }
    }

    override suspend fun create(bot: Bot): Bot {
        val resp = httpClient
            .put("/v1/bots") {
                setBody(
                    CreateBotRequest(
                        token = bot.token,
                        name = bot.name,
                        debugToken = bot.debugToken,
                        type = bot.type.toApi()
                    )
                )
            }.body<CreateBotResponse>()

        return resp.bot.toCore().extended()
    }

    override suspend fun get(id: String): Bot {
        val resp = httpClient
            .get("/v1/bots/$id")
            .body<GetBotResponse>()

        return resp.bot.toCore().extended()
    }

    override suspend fun toggle(id: String, status: BotStatus) {
        httpClient
            .post("/v1/bots/$id/toggle") {
                setBody(
                    ToggleBotRequest(
                        status = status.toApi()
                    )
                )
            }
    }

    override suspend fun update(bot: Bot): Bot {
        return httpClient
            .patch("/v1/bots/${bot.id}") {
                setBody(
                    UpdateBotRequest(
                        name = bot.name,
                        token = bot.token,
                        debugToken = bot.debugToken,
                    )
                )
            }
            .body<CreateBotResponse>()
            .bot
            .toCore()
            .extended()
    }

    override suspend fun delete(id: String) {
        httpClient.delete("/v1/bots/$id")
    }

    private suspend fun Bot.extended(): Bot {
        return when (type) {
            BotType.Telegram -> extendedTelegram()
        }
    }

    private suspend fun Bot.extendedTelegram(): Bot {
        return coroutineScope {

            val debugTokenValid = async {
                val dt = debugToken ?: return@async true
                runCatchingIgnoringCancellation {
                    TelegramClient(dt, httpClient).getMe()
                }.exceptionOrNull() !is ClientRequestException
            }

            val client = TelegramClient(token, httpClient)

            runCatchingIgnoringCancellation {

                val me = client.getMe().result

                val url = client.getUserProfilePhotos(me.id)
                    .result.photos.firstOrNull()?.firstOrNull()
                    ?.let {
                        client.getFile(it.file_id)
                    }?.let {
                        "${client.filePath}/${it.result.file_path}"
                    }

                copy(
                    imageUri = url,
                    realName = me.first_name,
                    username = "@" + me.username,
                )
            }.getOrElse {
                if (it is ClientRequestException && it.response.status == HttpStatusCode.Unauthorized) {
                    copy(isTokenValid = false)
                } else {
                    this@extendedTelegram
                }
            }.copy(
                isDebugTokenValid = debugTokenValid.await()
            )
        }
    }
}