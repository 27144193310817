package org.botdesigner.blueprint.builder

import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.serializer
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.components.BlueprintTrigger
import org.botdesigner.blueprint.components.Point

inline fun <reified T : BlueprintTrigger<*>> buildBlueprint(
    module : SerializersModule,
    builder : BlueprintBuilderScope<T>.() -> Unit
) : Blueprint = BlueprintBuilderScopeImpl(
    trigger = node<T>(0f, 0f),
    serializer = module.serializer<T>(),
    serializersModule = module
).apply(builder).build()


inline fun <reified T : BlueprintNode> node(x : Float, y : Float) : T {
    return Json.decodeFromString<T>(
        Json.encodeToString(
            mapOf(BlueprintNode::coordinate.name to Point(x, y))
        )
    )
}
