package org.botdesigner.shared.util.managers

import org.botdesigner.blueprint.integrations.context.PushNotification

internal class JsNotificationManager : NotificationManager {
    override suspend fun token(): String {
        return ""
    }

    override suspend fun subscribeToTopic(topic: String) {

    }

    override suspend fun unsubscribeFromTopic(topic: String) {

    }

    override suspend fun deleteToken() {

    }

    override suspend fun schedule(notification: PushNotification) {

    }
}