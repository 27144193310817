package org.botdesigner.api.auth

import org.botdesigner.api.ErrorCode

enum class RegisterErrorCode(
    override val isSuccess : Boolean = false
) : ErrorCode {
    Success(true),
    AlreadyExists,
    UnknownError,
    WeakPassword,
}