package org.botdesigner.shared.util

internal object DefaultLogger : Logger {

    override fun log(msg: String, tag: String, logLevel: Logger.LogLevel, cause: Throwable?) {
        when (logLevel) {
            Logger.LogLevel.Warning -> if (cause != null)
                print("Warning", msg, tag, logLevel, cause)
            Logger.LogLevel.Error ->
                print("Error", msg, tag, logLevel, cause)
        }
    }

    private fun print(
        header: String,
        msg: String,
        tag: String,
        logLevel: Logger.LogLevel,
        cause: Throwable?
    ) {
        if (cause != null)
            println("$header | $tag: $msg \n ${cause.stackTraceToString()}") else
            println("$header | $tag: $msg")
    }
}