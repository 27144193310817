@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.telegram.functions

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpTgDecomposeMessage")
public class BpTgDecomposeMessage(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.MessagePinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Message",
              isOut = i,
              required = true
          )
                  }
          }
         
          output {
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Id",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.UserPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "User",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Chat Id",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Text",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Photos Id",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Video Id",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Sticker Id",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Voice Id",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Video Voice Id",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Document Id",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Dice Value",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.UserArrayPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "New Chat Members",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.UserPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Left Chat Member",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "New Chat Photo",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Chat Photo Deleted",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "New Chat Title",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.MessagePinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Message Pinned",
              isOut = i,
              required = false
          )
      }
      }
      },
) : BpFunction() {
  override val summary: AnnotatedString
    get() = "Get message properties: Id, Text, Sender, ...".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4284132730)

  override val name: String
    get() = "Decompose Message"

  override val factory: FunctionFactory
    get() = ::BpTgDecomposeMessage

  override suspend fun calculate(input: List<Any?>): List<Any?> = TgDecomposeMessage(
  		requireNotNull(input[0] as org.botdesigner.telegram.Message?) { 
  "Message can't be null" 
  }
  	).values
}
