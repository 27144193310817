package org.botdesigner.shared.data.repo.test

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.core.CustomProcedureCombinedData
import org.botdesigner.core.CustomProcedureData
import org.botdesigner.core.CustomProcedureReadOnlyData
import org.botdesigner.core.CustomProcedureVersionData
import org.botdesigner.shared.data.repo.CustomFunctionsRepository

class CustomFunctionsRepositoryTest : CustomFunctionsRepository {
    override fun getAll(): Flow<List<CustomProcedureCombinedData>> {
        return flowOf(
            listOf(
                CustomProcedureCombinedData(
                    public = CustomProcedureData(
                        id = "123",
                        ownerId = "testOwner",
                        name = "Test Procedure",
                        description = "Description of hte test procedure",
                        tags = listOf("test", "test3", "test2"),
                        isPublic = true
                    ),
                    readonly = CustomProcedureReadOnlyData(
                        id = "123",
                        ownerId = "testOwner",
                        likes = 123
                    )
                )
            )
        )
    }

    override suspend fun get(id: String): CustomProcedureData {
        TODO("Not yet implemented")
    }

    override suspend fun versions(id: String): List<CustomProcedureVersionData> {
        TODO("Not yet implemented")
    }

    override suspend fun blueprint(id: String, versionId: String): Blueprint {
        TODO("Not yet implemented")
    }
}