@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.integrations.google

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Pair
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.io.context.NetworkContext
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpGoogleDrivePublicUpload")
public class BpGoogleDrivePublicUpload(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Folder URL",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "File Name",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.io.pins.ByteStreamPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "File",
              isOut = i,
              required = true
          )
                  }
          }
         
          
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("")
      },
) : BpProcedure<@Contextual NetworkContext>() {
  override val summary: AnnotatedString
    get() = "Upload file to the public Google Drive folder".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4280696141)

  override val factory: ProcedureFactory<@Contextual NetworkContext>
    get() = ::BpGoogleDrivePublicUpload

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual NetworkContext,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run {
      listOf(GoogleDrivePublicUpload(
  		requireNotNull(input[0] as kotlin.String?) { 
  "Folder URL can't be null" 
  },
  		input[1] as kotlin.String?,
  		requireNotNull(input[2] as io.ktor.utils.io.ByteReadChannel?) { 
  "File can't be null" 
  }
  	))}
}
