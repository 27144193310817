@file:Suppress( "FunctionName")

package org.botdesigner.blueprint.stdlib.functions.`object`

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.contentOrNull
import kotlinx.serialization.json.encodeToJsonElement
import org.botdesigner.blueprint.components.BlueprintExpressions
import org.botdesigner.blueprint.generator.BlueprintExpression
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.store.BlueprintNodeCategory

@BlueprintExpression(
    expression = BlueprintExpressions.Equals,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Equals",
    summary = "Check if 2 objects are equal. Type-safe comparison is used",
    serialName = "BpObjectEquals"
)
inline fun ObjectEquals(
    @Pin("") a : Any?,
    @Pin("") b : Any?
) : Boolean {
    return a == b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Object To String",
    summary = "Convert object to string",
    serialName = "BpObjectToString"
)
inline fun ObjectToString(@Pin("") v :Any?) :  String? {
    return when (v) {
        is JsonPrimitive -> v.contentOrNull
        else -> v.toString()
    }
}



@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Object To Float",
    summary = "Cast object to float number. Automatically converts strings and integer numbers to float",
    serialName = "BpObjectToFloat"
)
inline fun ObjectToFloat(@Pin("") v :Any?) :  Double? {
    return when (v) {
        is Number -> v.toDouble()
        is JsonPrimitive -> v.contentOrNull?.toDoubleOrNull()
        else -> v?.toString()?.toDoubleOrNull()
    }
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Object To Integer",
    summary = "Cast object to integer number. Automatically converts strings and integer numbers to integer",
    serialName = "BpObjectToLong"
)
inline fun ObjectToLong(@Pin("") v :Any?) :  Long? {
    return when (v) {
        is Number -> v.toLong()
        is JsonPrimitive -> v.contentOrNull?.toLongOrNull()
        else -> v?.toString()?.toLongOrNull()
    }
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Object To Array",
    summary = "Cast object to array",
    serialName = "BpObjectToArray"
)
inline fun ObjectToArray(@Pin("") v :Any?) : Iterable<*>? {
    return when (v) {
        null -> null
        is Iterable<*> -> v
        else -> error("Object cant be casted to array: $v")
    }
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Object To String Array",
    summary = "Cast object to string array. Automatically decodes JSON encoded string array",
    serialName = "BpObjectToStringArray"
)
inline fun ObjectToStringArray(@Pin("") v :Any?) : Iterable<String>? {
    if (v == null)
        return null

    return when (v) {
        is Iterable<*> -> v.map { it.toString() }
        is String -> runCatching {
            Json.decodeFromString<List<String>>(v)
        }.getOrNull()

        else -> null
    } ?: error("Object cant be casted to string array: $v")
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Object To Integer Array",
    summary = "Cast object to array of integer numbers. Automatically decodes JSON encoded number array. Converts any single number (or string representation of number) to integer array",
    serialName = "BpObjectToLongArray"
)
inline fun ObjectToLongArray(@Pin("") v :Any?) : Iterable<Long>? {

    if (v == null)
        return null

    return when (v) {
        is Iterable<*> -> v.mapNotNull {
            when (it) {
                is Number -> it.toLong()
                is JsonPrimitive -> it.contentOrNull?.toLongOrNull()
                else -> it?.toString()?.toLongOrNull() ?: it?.toString()?.toDoubleOrNull()
                    ?.toLong()
            }
        }

        is String -> runCatching {
            Json.decodeFromString<List<Long>>(v)
        }.getOrElse { v.toLongOrNull()?.let { listOf(it) } }

        is Number -> listOf(v.toLong())
        else -> null
    } ?: error("Object cant be casted to integer array: $v")
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Object To Float Array",
    summary = "Cast object to array of integer numbers. Automatically decodes JSON encoded number array. Converts any single number (or string representation of number) to float array",
    serialName = "BpObjectToFloatArray"
)
inline fun ObjectToFloatArray(@Pin("") v :Any?) : Iterable<Double>? {

    if (v == null)
        return null

    return when (v) {
        is Iterable<*> -> v.mapNotNull {
            when (it) {
                is Number -> it.toDouble()
                is JsonPrimitive -> it.contentOrNull?.toDoubleOrNull()
                else -> it?.toString()?.toDoubleOrNull() ?: it?.toString()?.toDoubleOrNull()
            }
        }

        is String -> runCatching {
            Json.decodeFromString<List<Double>>(v)
        }.getOrElse { v.toDoubleOrNull()?.let { listOf(it) } }

        is Number -> listOf(v.toDouble())

        else -> null
    } ?: error("Object cant be casted to float array: $v")
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "JSON To Object",
    summary = "Decode JSON string to an object",
    serialName = "BpStringToObject"
)
inline fun StringToObject(@Pin("") v :String?) :  Any? {
    return Json.parseToJsonElement(v ?: return null)
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Object,
    displayName = "Object To Json",
    summary = "Encodes object to JSON string",
    serialName = "BpObjectToJson"
)
fun ObjectToJson( v : Any?) :  String {
    return v.toJsonElement().toString()
}


private fun Any?.toJsonElement(): JsonElement {
    return when (this) {
        is Number -> JsonPrimitive(this)
        is Boolean -> JsonPrimitive(this)
        is String -> JsonPrimitive(this)
        is Array<*> -> toList().toJsonArray()
        is List<*> -> toJsonArray()
        is Map<*, *> -> toJsonObject()
        is JsonElement -> this

        else -> runCatching {
            Json.encodeToJsonElement(this)
        }.getOrNull() ?: JsonNull
    }
}

private fun List<*>.toJsonArray(): JsonArray {
    return JsonArray(map(Any?::toJsonElement))
}

private fun Map<*, *>.toJsonObject(): JsonObject {
    val map = mapValues { it.value.toJsonElement() }
        .mapKeys { it.key.toString() }

    return JsonObject(map)
}