package org.botdesigner.ui.common

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector

actual class ContextMenuItem actual constructor(
    actual val icon : ImageVector,
    actual val title : String,
    actual val contentColor : Color,
    actual val clickAction : () -> Unit
)

@Composable
actual fun ContextMenuArea(
    items : () -> List<ContextMenuItem>,
    enabled : Boolean,
    content : @Composable () -> Unit
) {
    content()
}