package org.botdesigner.shared.domain.content.profile

import dev.icerock.moko.resources.desc.StringDesc
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.api.SubscriptionType
import org.botdesigner.api.User
import org.botdesigner.shared.domain.content.ContentComponent
import org.botdesigner.shared.util.UIState

class UserInfo(
    val user: User,
    val subscription : SubscriptionType,
    val subscriptionCaption : StringDesc
)

interface ProfileComponent {

    val userInfo : StateFlow<UIState<UserInfo>>

    val topComponent : StateFlow<ContentComponent.Child<*>?>

    fun onLogout()

    fun onAppearanceClicked()

    fun onSettingsClicked()

    fun onAskQuestionClicked()

    fun onDevicesClicked()

    fun onAccountClicked()

    fun onPremiumClicked()
}
