package org.botdesigner.botblueprints.telegram

import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.integrations.serialization.IntegrationsSerializationModule
import org.botdesigner.blueprint.io.serialization.IOSerializationModule
import org.botdesigner.blueprint.stdlib.serialization.StdlibSerializationModule
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.blueprint.store.serializersModule
import org.botdesigner.botblueprints.telegram.pins.KeyboardButtonArrayPin
import org.botdesigner.botblueprints.telegram.pins.KeyboardButtonPin
import org.botdesigner.botblueprints.telegram.pins.KeyboardPin
import org.botdesigner.botblueprints.telegram.pins.MessagePin
import org.botdesigner.botblueprints.telegram.pins.UserArrayPin
import org.botdesigner.botblueprints.telegram.pins.UserPin



val TelegramSerializersModule by lazy {
    SerializersModule {
        include(IOSerializationModule)
        include(StdlibSerializationModule)
        include(IntegrationsSerializationModule)
        include(BlueprintNodeStoreOwner.store.serializersModule())

        polymorphic(Pin::class) {
            subclass(UserPin::class)
            subclass(UserArrayPin::class)
            subclass(MessagePin::class)
            subclass(KeyboardPin::class)
            subclass(KeyboardButtonPin::class)
            subclass(KeyboardButtonArrayPin::class)
        }
    }
}