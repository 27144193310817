@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.long

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpLongRandom")
public class BpLongRandom(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "From",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Until",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
) : BpFunction() {
  override val summary: AnnotatedString
    get() =
        "Generate random integer number in given range. Random is NOT cryptographically strong. <b>Until</b> must be greater than <b>From</b>. <b>From</b> defaults to 0, <b>Until</b> defaults to 9223372036854775807".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4284132730)

  override val name: String
    get() = "Random"

  override val factory: FunctionFactory
    get() = ::BpLongRandom

  override suspend fun calculate(input: List<Any?>): List<Any?> = listOf(LongRandom(
  		input[0] as kotlin.Long?,
  		input[1] as kotlin.Long?
  	))
}
