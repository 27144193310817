package org.botdesigner.shared.domain.content.list

import dev.icerock.moko.resources.StringResource
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.core.BotStatus
import org.botdesigner.resources.SharedRes

enum class BotFilter(
    val label : StringResource,
    val status : BotStatus?,
) {
    All(SharedRes.strings.all, null),
    Active(BotStatus.Running.status, BotStatus.Running),
    Idle(BotStatus.Stopped.status, BotStatus.Stopped),
}


interface BotFilterComponent  {

    val botsFilter : StateFlow<BotFilter>

    val botsSearchQuery : StateFlow<String>

    fun onFilterChanged(filter: BotFilter)

    fun onQueryChanged(query : String)

    fun onSearchFocusChanged(focused : Boolean)
}