package org.botdesigner.blueprint.stdlib.functions.`object`

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.*
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.stdlib.pins.DividerPin
import org.botdesigner.blueprint.components.generic
import org.botdesigner.blueprint.stdlib.pins.string
import org.botdesigner.blueprint.toAnnotatedString

@kotlinx.serialization.Serializable
@SerialName("BpCreateObject")
class BpCreateObject(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id){
        input {
            string("Key")
            generic("Value")
        }
        output {
            generic("Object", order = UInt.MAX_VALUE)
        }
    }
) : BpFunction() {

    override val color: Color
        get() = BlueprintColors.Util

    override val icon: ImageVector
        get() = BlueprintIcons.Object

    override val summary: AnnotatedString
        get() = "Create a key-value object (JSON)".toAnnotatedString()

    override val factory: FunctionFactory
        get() = ::BpCreateObject

    override val isExtendable: Boolean
        get() = true

    override fun isRemoveEnabled(state: BlueprintPinsOwner): Boolean {
        return state.pins.size > 3
    }

    override fun PinsMutationScope.onAddPins(
        pool: BlueprintNodesPool
    ) {

        appendPin {
            DividerPin(
                id = it,
                order = pins.size.toUInt(),
                elId = id
            )
        }
        appendPin {
            pins[0].factory(pool).create(
                id = it,
                order = pins.size.toUInt() + 1u,
                elementId = id,
                name = pins[0].name,
                isOut = false
            )
        }
        appendPin {
            pins[1].factory(pool).create(
                id = it,
                order = pins.size.toUInt() + 2u,
                elementId = id,
                name = pins[1].name,
                isOut = false
            )
        }
    }

    override fun PinsMutationScope.onRemovePins(pool: BlueprintNodesPool) {
        val p = pins

        repeat(3){
            removePin(p[p.lastIndex - it - 1])
        }
    }

    override suspend fun calculate(input: List<*>): List<*> {
        return listOf(input.chunked(2).associate {
            requireNotNull(it[0]){
                "Object key must not be null"
            }.toString() to it[1]
        })
    }
}