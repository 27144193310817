package org.botdesigner.blueprint.stdlib.pins


import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.components.PinsDirectionalScope
import org.botdesigner.blueprint.components.isPinActive
import org.botdesigner.blueprint.ui.pins.BasePinWidget

@kotlinx.serialization.Serializable
@SerialName("BooleanPinFactory")
object BooleanPinFactory: PinFactory<Boolean> {
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): Pin<Boolean> = BooleanPin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}

@kotlinx.serialization.Serializable
@SerialName("BooleanPin")
data class BooleanPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: Boolean? = false,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : Pin<Boolean>() {

    override val color: Color get()  = BlueprintColors.Boolean

    override fun withValue(value: String): BooleanPin {
        return copy(value = value.toBoolean())
    }

    override fun withReference(parent: Pin<*>?): BooleanPin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<Boolean> {
        return BooleanPinFactory
    }

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected: Boolean,
        pool: BlueprintNodesPool
    ) {

        val isActive = pool.isPinActive(this@BooleanPin)

        BasePinWidget(
            color = color,
            name = name,
            isOut = isOut,
            required = required,
            modifier = modifier,
            isActive = isActive,
            isSelected = isSelected,
            onTap = { onTap(this@BooleanPin) }
        ) {
            if (isActive.not()) {
                Checkbox(
                    colors = CheckboxDefaults.colors(
                        checkmarkColor = Color.Black,
                        checkedColor = BlueprintColors.Boolean,
                        uncheckedColor = Color.White
                    ),
                    checked = value == "true",
                    onCheckedChange = { onInputChanged(if (it) "true" else "false") }
                )
            } else {
                Spacer(modifier = Modifier.width(5.dp))
            }
        }
    }
}

fun PinsDirectionalScope.bool(name : String = "", order : UInt?=null, default: Boolean = false) {
    pin { o, i ->
        BooleanPin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
            value = default
        )
    }
}