package org.botdesigner.api

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes

private const val MaxTimeoutMultiplier = 5
enum class SubscriptionType(
    val key : String,
    val timeout : Duration,
    val totalMaxTimeout : Duration,
    val maxActiveBots : Int,
    val canLogErrors : Boolean,
    val maxNodes : Int,
) {
    Free(
        key = "free",
        timeout = 1.minutes,
        totalMaxTimeout = 1.minutes * MaxTimeoutMultiplier,
        maxActiveBots = 1,
        maxNodes = 100,
        canLogErrors = false
    ),
    Premium(
        key = "premium",
        timeout = 3.minutes,
        totalMaxTimeout = 3.minutes * MaxTimeoutMultiplier,
        maxActiveBots = 5,
        maxNodes = 300,
        canLogErrors = true
    ),
    Pro(
        key = "pro",
        timeout = 6.minutes,
        totalMaxTimeout = 6.minutes * MaxTimeoutMultiplier,
        maxActiveBots = 15,
        maxNodes = 1000,
        canLogErrors = true
    ),
}

@Serializable
data class Entitlement(
    @SerialName("purchase_date")
    val purchaseDate : String,
    @SerialName("expires_date")
    val expiresDate : String,
    @SerialName("product_identifier")
    val productIdentifier : String
)

enum class AuthProvider(val id : Int) {
    Password(0), Google(1), Apple(2)
}

@Serializable
data class User(
    val id : String,
    val isEmailVerified : Boolean = false,
//    val email : String = "",
    val name : String = "",
    val photo : String? = null,
    val entitlements : Map<String, Entitlement> = emptyMap(),
    val dailyInvocations : Int = SharedConstants.FreeInvocationsPerDay,
    val extraInvocations : Long = 0,
    val authProviders : Set<AuthProvider> = emptySet()
)

val User.subscriptionType : SubscriptionType
    get() = SubscriptionTypeFromEntitlements(entitlements)

fun SubscriptionTypeFromEntitlements(entitlements: Map<String, Entitlement>): SubscriptionType {
    return listOf(
        SubscriptionType.Pro,
        SubscriptionType.Premium,
    ).firstOrNull {
        entitlements.containsKey(it.key)
    } ?: SubscriptionType.Free
}

@Serializable
class VerifyEmailRequest(
    val code : String
)

@Serializable
class ChangePasswordRequest(
    val oldPassword : String,
    val newPassword : String
)

@Serializable
class UpdateAccountRequest(
    val name : String? = null
)

enum class ChangePasswordResult {
    Success,
    ConstrainViolation,
    InvalidOldPassword,
    UnknownError
}