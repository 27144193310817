package io.github.alexzhirkevich.onetap

actual typealias Window = org.w3c.dom.Window

actual val window : Window = kotlinx.browser.window

actual val Window.href : String get() = location.href

actual fun Window.closeWindow() = close()

actual fun Window.setInterval(handler : () -> Unit, interval : Int) : Int {
    return setInterval(handler = handler, timeout = interval)
}

actual fun Window.clearInterval(interval : Int) {
    clearInterval(handle = interval)
}



actual fun Window.dialog(url : String, name : String, options : String) : Window? =
    open(url, name,options)

actual val Window.isClosed : Boolean
    get() = closed

