@file:Suppress("NOTHING_TO_INLINE","FUNCTIONNAME","LOCALVARIABLENAME")


package org.botdesigner.botblueprints.telegram.functions

import org.botdesigner.telegram.Message
import org.botdesigner.telegram.MessageEntity
import org.botdesigner.telegram.User
import org.botdesigner.blueprint.generator.BlueprintTrigger
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.generator.Tuple
import org.botdesigner.blueprint.generator.Tuple3
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext

@BlueprintTrigger(displayName = "Message Received")
internal inline fun TelegramBotBlueprintContext.TgNewChatMembers() : @Pin("Message") List<User>  =
    requireNotNull(update.message?.new_chat_members){ "New message not found" }

@BlueprintTrigger(displayName = "Message Received")
internal inline fun TelegramBotBlueprintContext.TgMessageReceived() : @Pin("Message") Message =
    requireNotNull(update.message){ "New message not found" }

@BlueprintTrigger(displayName = "Message Edited")
internal inline fun TelegramBotBlueprintContext.TgMessageEdited() : @Pin("Message") Message =
    requireNotNull(update.edited_message){ "Edited message not found" }

@BlueprintTrigger(displayName = "Channel Posted")
internal inline fun TelegramBotBlueprintContext.TgChannelPosted() : @Pin("Post") Message =
    requireNotNull(update.channel_post){ "New post not found" }

@BlueprintTrigger(displayName = "Channel Post Edited")
internal inline fun TelegramBotBlueprintContext.TgChannelPostEdited() : @Pin("Post") Message =
    requireNotNull(update.edited_channel_post){ "Edited post not found" }

@BlueprintTrigger(displayName = "Chat Join Requested")
internal inline fun TelegramBotBlueprintContext.TgChatJoinRequested()
: Tuple3<
    @Pin("User") User,
    @Pin("Chat Id") Long,
    @Pin("Invite Link") String?
> {
    val r = requireNotNull(update.chat_join_request){ "New post not found" }
    return Tuple(r.from, r.chat.id, r.invite_link?.invite_link)
}

fun TgCommandFromMessage(msg : Message, crop : Boolean = false) : String? {

    val text = msg.text


    if (text == null || msg.entities == null)
        return null

    val commandRange = msg.entities
        ?.firstOrNull { it.type == MessageEntity.Type.BotCommand }
        ?.let { it.offset.toInt()until it.offset.toInt() + it.length.toInt() }
        ?: return null

    return if (crop)
        text.substring(commandRange)
    else
        text.substring(startIndex = commandRange.first)
}

@BlueprintTrigger(displayName = "Command Received")
internal inline fun TelegramBotBlueprintContext.TgCommandReceived()
: Tuple3<
    @Pin("Command") String,
    @Pin("User") User?,
    @Pin("Chat Id") Long,
> = tupleFromReceived { msg ->
    requireNotNull(TgCommandFromMessage(msg)) {
        "Message does not contain commands"
    }
}



@BlueprintTrigger(displayName = "Document Received")
internal inline fun TelegramBotBlueprintContext.TgDocumentReceived()
: Tuple3<
    @Pin("Document Id") String,
    @Pin("User") User?,
    @Pin("Chat Id") Long,
> = tupleFromReceived { requireNotNull(it.document?.file_id) }

@BlueprintTrigger(displayName = "Image Received")
internal inline fun TelegramBotBlueprintContext.TgImagesReceived()
: Tuple3<
    @Pin("Image Id") String,
    @Pin("User") User?,
    @Pin("Chat Id") Long,
> {
    val msg = requireNotNull(update.message)
    val r = requireNotNull(update.message?.photo?.lastOrNull()?.file_id)
    return Tuple(r, msg.from, msg.chat.id)
}

@BlueprintTrigger(displayName = "Voice Received")
internal inline fun TelegramBotBlueprintContext.TgVoiceReceived()
: Tuple3<
    @Pin("Voice Id") String,
    @Pin("User") User?,
    @Pin("Chat Id") Long,
> = tupleFromReceived { requireNotNull(it.voice?.file_id) }

@BlueprintTrigger(displayName = "Video Note Received")
internal inline fun TelegramBotBlueprintContext.TgVideoNoteReceived()
: Tuple3<
    @Pin("Video Note Id") String,
    @Pin("User") User?,
    @Pin("Chat Id") Long,
> = tupleFromReceived { requireNotNull(it.video_note?.file_id) }

@BlueprintTrigger(displayName = "Video Received")
internal inline fun TelegramBotBlueprintContext.TgVideoReceived()
: Tuple3<
    @Pin("Video Voice Id") String,
    @Pin("User") User?,
    @Pin("Chat Id") Long,
> = tupleFromReceived { requireNotNull(it.video?.file_id) }

@BlueprintTrigger(displayName = "Sticker Received")
internal inline fun TelegramBotBlueprintContext.TgStickerReceived()
: Tuple3<
    @Pin("Sticker Id") String,
    @Pin("User") User?,
    @Pin("Chat Id") Long,
> = tupleFromReceived { requireNotNull(it.sticker?.file_id) }


private inline fun TelegramBotBlueprintContext.tupleFromReceived(
    id : (Message) -> String
) : Tuple3<String, User?, Long> {
    val msg = requireNotNull(update.message)
    return Tuple(id(msg), msg.from, msg.chat.id)
}