package org.botdesigner.botblueprints.telegram.pins

import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.components.*
import org.botdesigner.botblueprints.KeyboardButton
import org.botdesigner.telegram.KeyboardButton
import kotlinx.serialization.SerialName


object KeyboardButtonArrayPinFactory : PinFactory<Iterable<KeyboardButton>> {

    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = KeyboardButtonArrayPin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}

@kotlinx.serialization.Serializable
@SerialName("TgKeyboardButtonArrayPin")
data class KeyboardButtonArrayPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: Iterable<KeyboardButton>? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : ArrayPin<KeyboardButton>() {

    override val childPinFactory: PinFactory<KeyboardButton>
        get() = KeyboardButtonPinFactory

    override val color: Color get() = BlueprintColors.KeyboardButton

    override fun factory(pool: BlueprintNodesPool): PinFactory<Iterable<KeyboardButton>> {
        return KeyboardButtonArrayPinFactory
    }

    override fun withReference(parent: Pin<*>?): ArrayPin<KeyboardButton> {
        return copy(parentId = parent?.elId, parentPinId = parent?.id, value = null)
    }

    override fun withValue(value: String): ArrayPin<KeyboardButton> {
        return copy(value = null, parentId = null, parentPinId = null)
    }
}

fun PinsDirectionalScope.keyboardButtonArray(name : String) {
    pin { order, isOut ->
        KeyboardButtonArrayPin(
            id = Id.randomId(),
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
        )
    }
}