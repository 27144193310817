package org.botdesigner.shared.util

import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.catch
import kotlin.coroutines.cancellation.CancellationException

interface ErrorHandler {

    fun handle(error: Throwable)

    object Ignore : ErrorHandler {
        override fun handle(error: Throwable) = Unit
    }

    object ReThrow : ErrorHandler {
        override fun handle(error: Throwable) = throw error
    }

    class Log(private val logger: Logger) : ErrorHandler {
        override fun handle(error: Throwable) {
            val (tag, cause) = if (error is TaggedException)
                error.message.orEmpty() to error.cause
            else "" to error

            logger.log(
                msg = "Unhandled error occurred",
                tag = tag,
                cause = cause,
                logLevel = Logger.LogLevel.Error
            )
        }
    }
}

inline fun <R> runCatchingIgnoringCancellation(block : () -> R) : Result<R> {
    return runCatching(block).onFailure { it.rethrowCancellation() }
}

fun Throwable.rethrowCancellation() {
    if (this is CancellationException)
        throw this
}

fun <T> Flow<T>.catchWith(errorHandler: ErrorHandler) = catch {
    errorHandler.handle(it)
}

fun ErrorHandler.toCoroutineExceptionHandler() : CoroutineExceptionHandler =
    CoroutineExceptionHandler { _, throwable ->
        handle(throwable)
    }