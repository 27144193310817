@file:Suppress("NOTHING_TO_INLINE","FUNCTIONNAME","LOCALVARIABLENAME")

package org.botdesigner.botblueprints.telegram.functions

import org.botdesigner.telegram.ForceReply
import org.botdesigner.telegram.KeyboardButton
import org.botdesigner.telegram.KeyboardOption
import org.botdesigner.telegram.ReplyKeyboardMarkup
import org.botdesigner.telegram.ReplyKeyboardRemove
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.botblueprints.BpCategories
import org.botdesigner.botblueprints.BpSubcategories
import kotlin.math.roundToInt

private const val DSCR_KEYBOARD_CREATE =
"""Create keyboard option that can be attached to a Telegram message.
<b>Buttons</b> will be send as keyboard grid. Rows count can be from 1 to 3.
<b>Force Reply</b> option will automatically force user to reply to this message. You can set a placeholder for force replied input
<b>Remove</b> will clear any previously sent keyboard.
<b>Exactly 1 option should be selected</b>"""
private const val DSCR_KEYBOARD_BUTTON_CREATE = "Create a button for a Telegram message keyboard"

@BlueprintFunction(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Keyboard,
    displayName = "Create Keyboard",
    summary = DSCR_KEYBOARD_CREATE
)
internal inline fun TgKeyboardCreate(
    Buttons: Iterable<KeyboardButton>,
    `Button Rows`: Long,
    `Force Reply`: Boolean?,
    `Force Reply Placeholder`: String?,
    Remove: Boolean?,
) : KeyboardOption {


    if (Buttons.toList().isNotEmpty()) {
        val buttonslist = Buttons.toList()
        val rows = `Button Rows`.coerceIn(1, minOf(3, buttonslist.size.toLong()))
        val chunkSize = (buttonslist.size / rows.toFloat() + 0.01).roundToInt().coerceAtLeast(1)
        return ReplyKeyboardMarkup(
            keyboard = buttonslist.chunked(chunkSize),
            resize_keyboard = true
        )
    }

    if (`Force Reply` == true) {
        return ForceReply(
            force_reply = true,
            input_field_placeholder = `Force Reply Placeholder`
        )
    }

    if (Remove == true) {
        return ReplyKeyboardRemove(remove_keyboard = true)
    }

    throw IllegalStateException("Exactly one keyboard options should be selected")
}

@BlueprintFunction(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Keyboard,
    displayName = "Create Keyboard Button",
    summary = DSCR_KEYBOARD_BUTTON_CREATE
)
internal inline fun TgKeyboardButtonCreate(
    Text : String,
    `Request Contact`: Boolean?,
) : KeyboardButton = KeyboardButton(
    text = Text,
    request_contact = `Request Contact`
)