@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.telegram.functions

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpTgDecomposeChat")
public class BpTgDecomposeChat(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.ChatPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Chat",
              isOut = i,
              required = true
          )
                  }
          }
         
          output {
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Id",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Name",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Image Id",
              isOut = i,
              required = false
          )
      }
      }
      },
) : BpFunction() {
  override val summary: AnnotatedString
    get() = "Get <b>Chat</b> properties".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4284132730)

  override val name: String
    get() = "Decompose Chat"

  override val factory: FunctionFactory
    get() = ::BpTgDecomposeChat

  override suspend fun calculate(input: List<Any?>): List<Any?> = TgDecomposeChat(
  		requireNotNull(input[0] as org.botdesigner.telegram.Chat?) { 
  "Chat can't be null" 
  }
  	).values
}
