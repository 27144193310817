package org.botdesigner.shared.domain

import com.arkivanov.decompose.value.Value
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.channelFlow
import kotlinx.coroutines.launch

fun <T : Any> Value<T>.asFlow() = channelFlow<T> {
    val observer = subscribe { v ->
        launch {
            send(v)
        }
    }
    awaitClose {
        observer.cancel()
    }
}