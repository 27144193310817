package org.botdesigner.ui.screens.guide

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.PagerState
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ProvideTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.BiasAlignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.lerp
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.times
import androidx.compose.ui.util.lerp
import androidx.compose.ui.zIndex
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTextButton
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.adaptive.icons.KeyboardArrowLeft
import io.github.alexzhirkevich.cupertino.adaptive.icons.KeyboardArrowRight
import kotlinx.coroutines.launch
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.guides.GuideComponent
import org.botdesigner.shared.domain.content.guides.GuidePage
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable
import org.botdesigner.ui.theme.platformClickable
import kotlin.math.absoluteValue

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun <T : GuidePage> GuideScreen(
    component: GuideComponent<T>,
    illustration : @Composable BoxScope.(T) -> Unit,
    title : @Composable BoxScope.(T) -> Unit = {
        Text(string(it.title))
    },
    text : @Composable BoxScope.(T) -> Unit = {
        Text(string(it.text))
    },
    canMoveForward : Boolean = true,
    canMoveBackward : Boolean = true,
    modifier: Modifier = Modifier
) {

    val coroutineScope = rememberCoroutineScope()

    val forwardEnabled = component.pagerState.currentPage > 0 && canMoveBackward
    val backwardEnabled = component.pagerState.let { it.currentPage < it.pageCount -1 } && canMoveForward

    AdaptiveScaffold(
        modifier = modifier,
        topBar = {
            Box(
                modifier = Modifier.fillMaxWidth()
                    .padding(Dimens.Spacing.Small),
                contentAlignment = Alignment.CenterEnd
            ) {
                AdaptiveTextButton(
                    modifier = Modifier.pointerHoverIcon(PointerIcon.PlatformClickable),
                    onClick = component::onCloseClicked,
                ) {
                    Text(string(SharedRes.strings.skip))
                }
            }
        }
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(it)
        ) {
            HorizontalPager(
                modifier = Modifier
                    .weight(1f)
                    .fillMaxWidth(),
                key = component.pages::get,
                state = component.pagerState,
                userScrollEnabled = CurrentPlatform.isMobile && canMoveForward && canMoveBackward,
            ) {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Box(
                        Modifier
                            .weight(.5f)
                            .fillMaxWidth()
                            .zIndex(1f)
                    ) {
                        illustration(component.pages[it])
                    }
                    Column(
                        modifier = Modifier
                            .weight(.5f)
                            .fillMaxWidth()
                            .padding(horizontal = Dimens.Spacing.Large),
                    ) {
                        Box(
                            Modifier
                                .fillMaxWidth()
                                .padding(top = Dimens.Spacing.Large),
                            contentAlignment = Alignment.Center
                        ){
                            ProvideTextStyle(
                                MaterialTheme.typography.titleLarge
                                    .copy(
                                        textAlign = TextAlign.Center,
                                        fontWeight = FontWeight.Bold
                                    )
                            ) {
                                title(component.pages[it])
                            }
                        }
                        Box(
                            modifier= Modifier
                                .fillMaxWidth()
                                .weight(1f),
                            contentAlignment = BiasAlignment(0f, -0.5f)
                        ) {
                            ProvideTextStyle(
                                MaterialTheme.typography.bodyMedium.copy(
                                    textAlign = TextAlign.Center,
                                    lineHeight = MaterialTheme.typography.bodyMedium.lineHeight * 1.25f,
                                )
                            ) {
                                text(component.pages[it])
                            }
                        }
                    }
                }
            }

            val density = LocalDensity.current


            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceEvenly,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(bottom = Dimens.Spacing.Large)
            ) {
                if (!CurrentPlatform.isMobile) {
                    AdaptiveTextButton(
                        modifier = Modifier
                            .weight(1f)
                            .padding(horizontal = Dimens.Spacing.Medium)
                            .pointerHoverIcon(PointerIcon.platformClickable(enabled = forwardEnabled)),
                        enabled = forwardEnabled,
                        onClick = {
                            coroutineScope.launch {
                                component.onPrevClicked()
                            }
                        }
                    ) {
                        Row(
                            modifier = Modifier.weight(1f),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement
                                .spacedBy(Dimens.Spacing.Medium, Alignment.CenterHorizontally)
                        ) {
                            Icon(
                                imageVector = AdaptiveIcons.Outlined.KeyboardArrowLeft,
                                contentDescription = null
                            )
                            BoxWithConstraints {
                                if (constraints.maxWidth > 70 * density.density) {
                                    Text(string(SharedRes.strings.back))
                                }
                            }
                        }
                    }
                }

                PageIndicator(component.pagerState)
                if (!CurrentPlatform.isMobile) {
                    AdaptiveTextButton(
                        modifier = Modifier
                            .weight(1f)
                            .padding(horizontal = Dimens.Spacing.Medium)
                            .pointerHoverIcon(PointerIcon.platformClickable(enabled = backwardEnabled)),
                        enabled = backwardEnabled,
                        onClick = {
                            coroutineScope.launch {
                                component.onNextClicked()
                            }
                        }
                    ) {
                        Row(
                            modifier = Modifier.weight(1f),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement
                                .spacedBy(Dimens.Spacing.Medium, Alignment.CenterHorizontally)
                        ) {
                            BoxWithConstraints {
                                if (constraints.maxWidth > 70 * density.density) {
                                    Text(string(SharedRes.strings.next))
                                }
                            }
                            Icon(
                                imageVector = AdaptiveIcons.Outlined.KeyboardArrowRight,
                                contentDescription = null
                            )
                        }

                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
private fun PageIndicator(
    pagerState: PagerState,
    modifier: Modifier = Modifier,
    selectedColor: Color = MaterialTheme.colorScheme.primary,
    defaultColor: Color = MaterialTheme.colorScheme.secondary,
    defaultRadius: Dp = Dimens.Spacing.Small,
    selectedLength: Dp = defaultRadius * 3,
    space: Dp = defaultRadius
) {
    Canvas(
        modifier
            .width((pagerState.pageCount-1) * (defaultRadius + space) + selectedLength - space)
            .height(defaultRadius)
    ) {

        val defaultRadiusPx = defaultRadius.toPx()
        val selectedLengthPx = selectedLength.toPx()

        val fraction = when {
            !pagerState.isScrollInProgress -> 1f
            pagerState.currentPage == pagerState.settledPage ->
                (pagerState.currentPageOffsetFraction.absoluteValue - .5f).coerceIn(0f,1f)
            else -> (1f - pagerState.currentPageOffsetFraction.absoluteValue).coerceIn(0f,1f)
        }
        val lengthLerp = lerp(defaultRadiusPx, selectedLengthPx, fraction)
        val invertedLengthLerp = lerp(selectedLengthPx, defaultRadiusPx, fraction)

        val colorLerp = lerp(defaultColor, selectedColor, fraction)
        val invertedColorLerp = lerp(selectedColor, defaultColor, fraction)
        val spacePx = space.toPx()

        var x = 0f

        val corner = CornerRadius(defaultRadiusPx/2, defaultRadiusPx/2)
        repeat(pagerState.pageCount) { page ->
            val (width, color) = when (page) {
                pagerState.targetPage -> lengthLerp to colorLerp
                pagerState.settledPage -> invertedLengthLerp to invertedColorLerp
                else -> defaultRadiusPx to defaultColor
            }

            drawRoundRect(
                cornerRadius = corner,
                color = color,
                topLeft = Offset(x = x, y= 0f),
                size = Size(width, defaultRadiusPx)
            )
            x += (width + spacePx)
        }
    }
}