package org.botdesigner.shared.domain.content.appearance

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update

class DefaultAppearanceComponent(
    override val state : MutableStateFlow<AppearanceState>,
    private val onBack : () -> Unit
) : AppearanceComponent {

    override fun onAutoNightModeChanged(value: Boolean) {
        state.update {
            it.copy(autoNightMode = value)
        }
    }

    override fun onSystemAppearanceChanged(value: Boolean) {
        state.update {
            it.copy(systemAppearance = value)
        }
    }

    override fun onDarkModeChanged(value: Boolean) {
        state.update {
            it.copy(darkMode = value)
        }
    }

    override fun onAccentChanged(value: AppearanceAccent) {
        state.update {
            it.copy(accent = value)
        }
    }

    override fun onFontScaleChanged(value: Float) {
        state.update {
            it.copy(fontScale = value)
        }
    }

    override fun onBack() {
        onBack.invoke()
    }
}