package org.botdesigner.shared.util.managers

import kotlinx.browser.window
import org.botdesigner.api.SharedConstants

actual typealias NativeApplication = Any

private val redirect  : String? get() {
    val md = js("new MobileDetect(window.navigator.userAgent)")

    if (md.mobile() == null && md.phone() == null && md.tablet() == null){
        return null
    }

    return if (md.`is`("iPhone") as Boolean || md.`is`("iPad") as Boolean) {
        SharedConstants.AppStoreUrl
    } else {
        SharedConstants.GooglePlayUrl
    }
}


actual class AppInitializationManager : AppInitializationManagerBase() {

    override fun init(app: NativeApplication) {

        redirect?.let {
            window.location.href = it
            window.open(it)
            return
        }

        super.init(app)
    }
}
