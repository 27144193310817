package org.botdesigner.ui.common

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.surfaceColorAtElevation
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.Stable
import androidx.compose.runtime.State
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.value.Value
import io.github.alexzhirkevich.cupertino.CupertinoTopAppBarDefaults
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTopAppBar
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.distinctUntilChanged
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.shared.domain.asFlow
import org.botdesigner.ui.theme.Dimens


@Composable
@ExperimentalMaterial3Api
internal expect fun rememberPlatformDialogState(
    initiallyExpanded : Boolean = false
) : PlatformDialogState

@Stable
@ExperimentalMaterial3Api
expect class PlatformDialogState {

    val isVisible : Boolean

    suspend fun show()
    suspend fun hide()
}



@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal fun <T: Any> Value<ChildSlot<*, T>>.subscribeWithState(
    dialogState: PlatformDialogState
) : State<ChildSlot<*,T>> {

    val v = subscribeAsState()

    ObserveDialogState(
        state = dialogState,
        childSlot = remember(this) { asFlow() }
    )

    return produceState(v.value, v, dialogState){
        snapshotFlow {
            v.value to dialogState.isVisible
        }.collectLatest { (cValue, visible) ->
            value = if (visible) {
                cValue.takeIf { it.child != null } ?: value
            } else {
                cValue
            }
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal fun ObserveDialogState(
    state: PlatformDialogState,
    childSlot: Flow<ChildSlot<*, *>>
) {

    LaunchedEffect(state, childSlot){
        childSlot.distinctUntilChanged().collectLatest {
            if (it.child != null){
                state.show()
            } else {
                state.hide()
            }
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal fun ObserveDialogState(
    state: PlatformDialogState,
    visible: () -> Boolean
) {
    LaunchedEffect(0){

        snapshotFlow {
            visible()
        }.distinctUntilChanged().collectLatest {
            if (it){
                state.show()
            } else {
                state.hide()
            }
        }
    }
}

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal fun PlatformDialogTopBar(
    title : @Composable () -> Unit,
    modifier: Modifier = Modifier,
    actions : @Composable RowScope.() -> Unit = {},
    isTransparent : Boolean = false,
    onDismissRequest: () -> Unit
) {
    AdaptiveTopAppBar(
        modifier = modifier,
        windowInsets = WindowInsets(
            top = 5.dp,
            bottom = 5.dp
        ),
        title = title,
        actions = {
            if (CurrentPlatform != Platform.Ios) {
                PlatformDialogCloseButton(onDismissRequest)
            } else {
                actions()
            }
        },
        navigationIcon = {
            if (CurrentPlatform == Platform.Ios) {
                PlatformDialogCloseButton(onDismissRequest)
            } else {
                Row(Modifier.padding(start = Dimens.Spacing.Small)) {
                    actions()
                }
            }
        },
        adaptation = {
            cupertino {
                this.isTransparent = isTransparent
                this.isTranslucent = CurrentPlatform.isMobile
                if (!isTranslucent){
                    colors = CupertinoTopAppBarDefaults.topAppBarColors(
                        containerColor = MaterialTheme.colorScheme.surfaceColorAtElevation(
                            Dimens.TonalElevation.Level2
                        )
                    )
                }
            }
        }
    )
}

@Composable
internal expect fun PlatformDialogCloseButton(
    onClick : () -> Unit
)


@Composable
expect fun containerBackground() : Color

expect val PlatformDialogAnimationDuration : Int

@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal expect fun PlatformDialogContainer(
    modifier: Modifier = Modifier,
    state: PlatformDialogState,
    unboundHeight : Boolean = false,
    unboundWidth : Boolean = false,
    onDismissRequest : () -> Unit,
    dialog : @Composable () -> Unit,
    content : @Composable () -> Unit
)