package org.botdesigner.resources

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.AssetResource
import dev.icerock.moko.resources.ColorResource
import dev.icerock.moko.resources.FileResource
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.PluralsResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.collections.List

public actual object SharedRes {
  private val contentHash: String = "f0b6b48ea28ef1b102b6a49d66f3bc8f"

  public val stringsLoader: RemoteJsStringLoader = strings.stringsLoader

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    private val stringsFallbackFileUrl: String =
        js("require(\"localization/orgbotdesignerresources_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        ))

    override val stringsLoader: RemoteJsStringLoader = RemoteJsStringLoader.Impl(supportedLocales =
        supportedLocales, fallbackFileUri = stringsFallbackFileUrl)

    public actual val promo_premium: StringResource = StringResource(key = "promo_premium", loader =
        stringsLoader)

    public actual val renews_at: StringResource = StringResource(key = "renews_at", loader =
        stringsLoader)

    public actual val expires_at: StringResource = StringResource(key = "expires_at", loader =
        stringsLoader)

    public actual val invocations_left: StringResource = StringResource(key = "invocations_left",
        loader = stringsLoader)

    public actual val all: StringResource = StringResource(key = "all", loader = stringsLoader)

    public actual val error: StringResource = StringResource(key = "error", loader = stringsLoader)

    public actual val error_oops: StringResource = StringResource(key = "error_oops", loader =
        stringsLoader)

    public actual val error_empty: StringResource = StringResource(key = "error_empty", loader =
        stringsLoader)

    public actual val error_no_bots_type: StringResource = StringResource(key =
        "error_no_bots_type", loader = stringsLoader)

    public actual val error_no_bots_matching: StringResource = StringResource(key =
        "error_no_bots_matching", loader = stringsLoader)

    public actual val error_something_went_wrong: StringResource = StringResource(key =
        "error_something_went_wrong", loader = stringsLoader)

    public actual val error_sign_in: StringResource = StringResource(key = "error_sign_in", loader =
        stringsLoader)

    public actual val error_auth_exists: StringResource = StringResource(key = "error_auth_exists",
        loader = stringsLoader)

    public actual val error_auth_invalid_credentials: StringResource = StringResource(key =
        "error_auth_invalid_credentials", loader = stringsLoader)

    public actual val error_auth_invalid_code: StringResource = StringResource(key =
        "error_auth_invalid_code", loader = stringsLoader)

    public actual val error_auth_timeout: StringResource = StringResource(key =
        "error_auth_timeout", loader = stringsLoader)

    public actual val error_link_cred_in_use: StringResource = StringResource(key =
        "error_link_cred_in_use", loader = stringsLoader)

    public actual val error_email_constraints: StringResource = StringResource(key =
        "error_email_constraints", loader = stringsLoader)

    public actual val error_email_notfound: StringResource = StringResource(key =
        "error_email_notfound", loader = stringsLoader)

    public actual val error_password_constraints: StringResource = StringResource(key =
        "error_password_constraints", loader = stringsLoader)

    public actual val error_password_invalid_old: StringResource = StringResource(key =
        "error_password_invalid_old", loader = stringsLoader)

    public actual val error_name_empty: StringResource = StringResource(key = "error_name_empty",
        loader = stringsLoader)

    public actual val error_bots_load: StringResource = StringResource(key = "error_bots_load",
        loader = stringsLoader)

    public actual val error_send_email_verification: StringResource = StringResource(key =
        "error_send_email_verification", loader = stringsLoader)

    public actual val error_invalid_verification_code: StringResource = StringResource(key =
        "error_invalid_verification_code", loader = stringsLoader)

    public actual val error_verify_email: StringResource = StringResource(key =
        "error_verify_email", loader = stringsLoader)

    public actual val error_load_logs: StringResource = StringResource(key = "error_load_logs",
        loader = stringsLoader)

    public actual val error_load_blueprints: StringResource = StringResource(key =
        "error_load_blueprints", loader = stringsLoader)

    public actual val error_blueprint_node: StringResource = StringResource(key =
        "error_blueprint_node", loader = stringsLoader)

    public actual val error_invalid_bot_token: StringResource = StringResource(key =
        "error_invalid_bot_token", loader = stringsLoader)

    public actual val error_invalid_bot_token_change: StringResource = StringResource(key =
        "error_invalid_bot_token_change", loader = stringsLoader)

    public actual val error_same_debug_token: StringResource = StringResource(key =
        "error_same_debug_token", loader = stringsLoader)

    public actual val error_network_try_again: StringResource = StringResource(key =
        "error_network_try_again", loader = stringsLoader)

    public actual val warning_web_performance: StringResource = StringResource(key =
        "warning_web_performance", loader = stringsLoader)

    public actual val warning_invalid_bot_token_debug: StringResource = StringResource(key =
        "warning_invalid_bot_token_debug", loader = stringsLoader)

    public actual val confirm_delete_blueprint: StringResource = StringResource(key =
        "confirm_delete_blueprint", loader = stringsLoader)

    public actual val confirm_delete_account: StringResource = StringResource(key =
        "confirm_delete_account", loader = stringsLoader)

    public actual val confirm_delete_bot: StringResource = StringResource(key =
        "confirm_delete_bot", loader = stringsLoader)

    public actual val delete_blueprint_success: StringResource = StringResource(key =
        "delete_blueprint_success", loader = stringsLoader)

    public actual val bots_empty: StringResource = StringResource(key = "bots_empty", loader =
        stringsLoader)

    public actual val blueprint_save_success: StringResource = StringResource(key =
        "blueprint_save_success", loader = stringsLoader)

    public actual val blueprint_max_nodes_reached: StringResource = StringResource(key =
        "blueprint_max_nodes_reached", loader = stringsLoader)

    public actual val blueprint_max_nodes_reached_upgrade: StringResource = StringResource(key =
        "blueprint_max_nodes_reached_upgrade", loader = stringsLoader)

    public actual val link_apple_success: StringResource = StringResource(key =
        "link_apple_success", loader = stringsLoader)

    public actual val link_apple_fail: StringResource = StringResource(key = "link_apple_fail",
        loader = stringsLoader)

    public actual val confirm: StringResource = StringResource(key = "confirm", loader =
        stringsLoader)

    public actual val more: StringResource = StringResource(key = "more", loader = stringsLoader)

    public actual val google_scope_drive: StringResource = StringResource(key =
        "google_scope_drive", loader = stringsLoader)

    public actual val google_scope_drive_desc: StringResource = StringResource(key =
        "google_scope_drive_desc", loader = stringsLoader)

    public actual val google_scope_sheets: StringResource = StringResource(key =
        "google_scope_sheets", loader = stringsLoader)

    public actual val google_scope_sheets_desc: StringResource = StringResource(key =
        "google_scope_sheets_desc", loader = stringsLoader)

    public actual val minutes_ago: StringResource = StringResource(key = "minutes_ago", loader =
        stringsLoader)

    public actual val hours_ago: StringResource = StringResource(key = "hours_ago", loader =
        stringsLoader)

    public actual val days_ago: StringResource = StringResource(key = "days_ago", loader =
        stringsLoader)

    public actual val online: StringResource = StringResource(key = "online", loader =
        stringsLoader)

    public actual val app_name: StringResource = StringResource(key = "app_name", loader =
        stringsLoader)

    public actual val back: StringResource = StringResource(key = "back", loader = stringsLoader)

    public actual val back_to_sign_in: StringResource = StringResource(key = "back_to_sign_in",
        loader = stringsLoader)

    public actual val welcome: StringResource = StringResource(key = "welcome", loader =
        stringsLoader)

    public actual val new_bot: StringResource = StringResource(key = "new_bot", loader =
        stringsLoader)

    public actual val add_new: StringResource = StringResource(key = "add_new", loader =
        stringsLoader)

    public actual val new_blueprint: StringResource = StringResource(key = "new_blueprint", loader =
        stringsLoader)

    public actual val blueprints: StringResource = StringResource(key = "blueprints", loader =
        stringsLoader)

    public actual val ok: StringResource = StringResource(key = "ok", loader = stringsLoader)

    public actual val cancel: StringResource = StringResource(key = "cancel", loader =
        stringsLoader)

    public actual val name: StringResource = StringResource(key = "name", loader = stringsLoader)

    public actual val token: StringResource = StringResource(key = "token", loader = stringsLoader)

    public actual val debug_token_optional: StringResource = StringResource(key =
        "debug_token_optional", loader = stringsLoader)

    public actual val name_caption: StringResource = StringResource(key = "name_caption", loader =
        stringsLoader)

    public actual val debug_token_caption: StringResource = StringResource(key =
        "debug_token_caption", loader = stringsLoader)

    public actual val edit: StringResource = StringResource(key = "edit", loader = stringsLoader)

    public actual val save: StringResource = StringResource(key = "save", loader = stringsLoader)

    public actual val restore: StringResource = StringResource(key = "restore", loader =
        stringsLoader)

    public actual val close: StringResource = StringResource(key = "close", loader = stringsLoader)

    public actual val skip: StringResource = StringResource(key = "skip", loader = stringsLoader)

    public actual val create: StringResource = StringResource(key = "create", loader =
        stringsLoader)

    public actual val delete: StringResource = StringResource(key = "delete", loader =
        stringsLoader)

    public actual val undo: StringResource = StringResource(key = "undo", loader = stringsLoader)

    public actual val clear: StringResource = StringResource(key = "clear", loader = stringsLoader)

    public actual val run: StringResource = StringResource(key = "run", loader = stringsLoader)

    public actual val stop: StringResource = StringResource(key = "stop", loader = stringsLoader)

    public actual val logs: StringResource = StringResource(key = "logs", loader = stringsLoader)

    public actual val new_command: StringResource = StringResource(key = "new_command", loader =
        stringsLoader)

    public actual val command_select: StringResource = StringResource(key = "command_select", loader
        = stringsLoader)

    public actual val command_select_desc: StringResource = StringResource(key =
        "command_select_desc", loader = stringsLoader)

    public actual val bots_select_or_create: StringResource = StringResource(key =
        "bots_select_or_create", loader = stringsLoader)

    public actual val bots: StringResource = StringResource(key = "bots", loader = stringsLoader)

    public actual val settings: StringResource = StringResource(key = "settings", loader =
        stringsLoader)

    public actual val editor_prefs: StringResource = StringResource(key = "editor_prefs", loader =
        stringsLoader)

    public actual val ask_question: StringResource = StringResource(key = "ask_question", loader =
        stringsLoader)

    public actual val faq: StringResource = StringResource(key = "faq", loader = stringsLoader)

    public actual val or_lowercase: StringResource = StringResource(key = "or_lowercase", loader =
        stringsLoader)

    public actual val add_node: StringResource = StringResource(key = "add_node", loader =
        stringsLoader)

    public actual val console: StringResource = StringResource(key = "console", loader =
        stringsLoader)

    public actual val next: StringResource = StringResource(key = "next", loader = stringsLoader)

    public actual val nodes: StringResource = StringResource(key = "nodes", loader = stringsLoader)

    public actual val help_nodes: StringResource = StringResource(key = "help_nodes", loader =
        stringsLoader)

    public actual val connect: StringResource = StringResource(key = "connect", loader =
        stringsLoader)

    public actual val help_connect: StringResource = StringResource(key = "help_connect", loader =
        stringsLoader)

    public actual val pins: StringResource = StringResource(key = "pins", loader = stringsLoader)

    public actual val help_pins: StringResource = StringResource(key = "help_pins", loader =
        stringsLoader)

    public actual val store: StringResource = StringResource(key = "store", loader = stringsLoader)

    public actual val help_store: StringResource = StringResource(key = "help_store", loader =
        stringsLoader)

    public actual val lets_try: StringResource = StringResource(key = "lets_try", loader =
        stringsLoader)

    public actual val help_lets_try: StringResource = StringResource(key = "help_lets_try", loader =
        stringsLoader)

    public actual val help_pin_types: StringResource = StringResource(key = "help_pin_types", loader
        = stringsLoader)

    public actual val arrays: StringResource = StringResource(key = "arrays", loader =
        stringsLoader)

    public actual val help_arrays: StringResource = StringResource(key = "help_arrays", loader =
        stringsLoader)

    public actual val generics: StringResource = StringResource(key = "generics", loader =
        stringsLoader)

    public actual val help_generics: StringResource = StringResource(key = "help_generics", loader =
        stringsLoader)

    public actual val null_safety: StringResource = StringResource(key = "null_safety", loader =
        stringsLoader)

    public actual val help_null_safety: StringResource = StringResource(key = "help_null_safety",
        loader = stringsLoader)

    public actual val extenders: StringResource = StringResource(key = "extenders", loader =
        stringsLoader)

    public actual val help_extenders: StringResource = StringResource(key = "help_extenders", loader
        = stringsLoader)

    public actual val appearance: StringResource = StringResource(key = "appearance", loader =
        stringsLoader)

    public actual val automatic: StringResource = StringResource(key = "automatic", loader =
        stringsLoader)

    public actual val manual: StringResource = StringResource(key = "manual", loader =
        stringsLoader)

    public actual val auto_night_mode: StringResource = StringResource(key = "auto_night_mode",
        loader = stringsLoader)

    public actual val system_appearance: StringResource = StringResource(key = "system_appearance",
        loader = stringsLoader)

    public actual val dark_mode: StringResource = StringResource(key = "dark_mode", loader =
        stringsLoader)

    public actual val accent: StringResource = StringResource(key = "accent", loader =
        stringsLoader)

    public actual val font_scale: StringResource = StringResource(key = "font_scale", loader =
        stringsLoader)

    public actual val account: StringResource = StringResource(key = "account", loader =
        stringsLoader)

    public actual val delete_account: StringResource = StringResource(key = "delete_account", loader
        = stringsLoader)

    public actual val delete_account_desc: StringResource = StringResource(key =
        "delete_account_desc", loader = stringsLoader)

    public actual val link_provider: StringResource = StringResource(key = "link_provider", loader =
        stringsLoader)

    public actual val link_provider_desc: StringResource = StringResource(key =
        "link_provider_desc", loader = stringsLoader)

    public actual val link_google: StringResource = StringResource(key = "link_google", loader =
        stringsLoader)

    public actual val manage_google: StringResource = StringResource(key = "manage_google", loader =
        stringsLoader)

    public actual val link_google_success: StringResource = StringResource(key =
        "link_google_success", loader = stringsLoader)

    public actual val link_google_fail: StringResource = StringResource(key = "link_google_fail",
        loader = stringsLoader)

    public actual val link_apple: StringResource = StringResource(key = "link_apple", loader =
        stringsLoader)

    public actual val unlink_apple: StringResource = StringResource(key = "unlink_apple", loader =
        stringsLoader)

    public actual val browser_sign_in_desc: StringResource = StringResource(key =
        "browser_sign_in_desc", loader = stringsLoader)

    public actual val select_google_features: StringResource = StringResource(key =
        "select_google_features", loader = stringsLoader)

    public actual val devices: StringResource = StringResource(key = "devices", loader =
        stringsLoader)

    public actual val this_device: StringResource = StringResource(key = "this_device", loader =
        stringsLoader)

    public actual val terminate: StringResource = StringResource(key = "terminate", loader =
        stringsLoader)

    public actual val terminate_all_sessions: StringResource = StringResource(key =
        "terminate_all_sessions", loader = stringsLoader)

    public actual val terminate_all_sessions_desc: StringResource = StringResource(key =
        "terminate_all_sessions_desc", loader = stringsLoader)

    public actual val active_sessions: StringResource = StringResource(key = "active_sessions",
        loader = stringsLoader)

    public actual val active_sessions_desc: StringResource = StringResource(key =
        "active_sessions_desc", loader = stringsLoader)

    public actual val learn_more: StringResource = StringResource(key = "learn_more", loader =
        stringsLoader)

    public actual val auto_save: StringResource = StringResource(key = "auto_save", loader =
        stringsLoader)

    public actual val auto_save_desc: StringResource = StringResource(key = "auto_save_desc", loader
        = stringsLoader)

    public actual val grid: StringResource = StringResource(key = "grid", loader = stringsLoader)

    public actual val grid_desc: StringResource = StringResource(key = "grid_desc", loader =
        stringsLoader)

    public actual val guides: StringResource = StringResource(key = "guides", loader =
        stringsLoader)

    public actual val guides_desk: StringResource = StringResource(key = "guides_desk", loader =
        stringsLoader)

    public actual val pin_types: StringResource = StringResource(key = "pin_types", loader =
        stringsLoader)

    public actual val vibration: StringResource = StringResource(key = "vibration", loader =
        stringsLoader)

    public actual val vibrate_on_connect: StringResource = StringResource(key =
        "vibrate_on_connect", loader = stringsLoader)

    public actual val vibrate_on_move: StringResource = StringResource(key = "vibrate_on_move",
        loader = stringsLoader)

    public actual val dock_bottom: StringResource = StringResource(key = "dock_bottom", loader =
        stringsLoader)

    public actual val dock_left: StringResource = StringResource(key = "dock_left", loader =
        stringsLoader)

    public actual val dock_right: StringResource = StringResource(key = "dock_right", loader =
        stringsLoader)

    public actual val min_log_level: StringResource = StringResource(key = "min_log_level", loader =
        stringsLoader)

    public actual val subscription: StringResource = StringResource(key = "subscription", loader =
        stringsLoader)

    public actual val upgrade: StringResource = StringResource(key = "upgrade", loader =
        stringsLoader)

    public actual val unlock_hosting: StringResource = StringResource(key = "unlock_hosting", loader
        = stringsLoader)

    public actual val manage_subscription: StringResource = StringResource(key =
        "manage_subscription", loader = stringsLoader)

    public actual val subscribe: StringResource = StringResource(key = "subscribe", loader =
        stringsLoader)

    public actual val watch_video: StringResource = StringResource(key = "watch_video", loader =
        stringsLoader)

    public actual val current: StringResource = StringResource(key = "current", loader =
        stringsLoader)

    public actual val free: StringResource = StringResource(key = "free", loader = stringsLoader)

    public actual val per_month: StringResource = StringResource(key = "per_month", loader =
        stringsLoader)

    public actual val days_free_then: StringResource = StringResource(key = "days_free_then", loader
        = stringsLoader)

    public actual val subscribe_for: StringResource = StringResource(key = "subscribe_for", loader =
        stringsLoader)

    public actual val up_to_n_active_bots: StringResource = StringResource(key =
        "up_to_n_active_bots", loader = stringsLoader)

    public actual val up_to_n_execution_time: StringResource = StringResource(key =
        "up_to_n_execution_time", loader = stringsLoader)

    public actual val up_to_n_nodes: StringResource = StringResource(key = "up_to_n_nodes", loader =
        stringsLoader)

    public actual val blueprint_logs: StringResource = StringResource(key = "blueprint_logs", loader
        = stringsLoader)

    public actual val logs_statistics_nodes: StringResource = StringResource(key =
        "logs_statistics_nodes", loader = stringsLoader)

    public actual val help_info: StringResource = StringResource(key = "help_info", loader =
        stringsLoader)

    public actual val on_mobile_only: StringResource = StringResource(key = "on_mobile_only", loader
        = stringsLoader)

    public actual val invocations: StringResource = StringResource(key = "invocations", loader =
        stringsLoader)

    public actual val invocations_help: StringResource = StringResource(key = "invocations_help",
        loader = stringsLoader)

    public actual val execution_time: StringResource = StringResource(key = "execution_time", loader
        = stringsLoader)

    public actual val execution_time_help: StringResource = StringResource(key =
        "execution_time_help", loader = stringsLoader)

    public actual val max_running_bots: StringResource = StringResource(key = "max_running_bots",
        loader = stringsLoader)

    public actual val max_running_bots_help: StringResource = StringResource(key =
        "max_running_bots_help", loader = stringsLoader)

    public actual val blueprint_logs_help: StringResource = StringResource(key =
        "blueprint_logs_help", loader = stringsLoader)

    public actual val dont_have_an_account: StringResource = StringResource(key =
        "dont_have_an_account", loader = stringsLoader)

    public actual val sign_up: StringResource = StringResource(key = "sign_up", loader =
        stringsLoader)

    public actual val sign_in: StringResource = StringResource(key = "sign_in", loader =
        stringsLoader)

    public actual val create_account: StringResource = StringResource(key = "create_account", loader
        = stringsLoader)

    public actual val action_continue: StringResource = StringResource(key = "action_continue",
        loader = stringsLoader)

    public actual val restore_password: StringResource = StringResource(key = "restore_password",
        loader = stringsLoader)

    public actual val change_password: StringResource = StringResource(key = "change_password",
        loader = stringsLoader)

    public actual val change_password_success: StringResource = StringResource(key =
        "change_password_success", loader = stringsLoader)

    public actual val old_password: StringResource = StringResource(key = "old_password", loader =
        stringsLoader)

    public actual val new_password: StringResource = StringResource(key = "new_password", loader =
        stringsLoader)

    public actual val change: StringResource = StringResource(key = "change", loader =
        stringsLoader)

    public actual val forgot_password: StringResource = StringResource(key = "forgot_password",
        loader = stringsLoader)

    public actual val sign_out: StringResource = StringResource(key = "sign_out", loader =
        stringsLoader)

    public actual val email: StringResource = StringResource(key = "email", loader = stringsLoader)

    public actual val password: StringResource = StringResource(key = "password", loader =
        stringsLoader)

    public actual val sign_in_with_google: StringResource = StringResource(key =
        "sign_in_with_google", loader = stringsLoader)

    public actual val sign_in_with_apple: StringResource = StringResource(key =
        "sign_in_with_apple", loader = stringsLoader)

    public actual val sign_in_social_without_account: StringResource = StringResource(key =
        "sign_in_social_without_account", loader = stringsLoader)

    public actual val sign_up_terms_and_privacy: StringResource = StringResource(key =
        "sign_up_terms_and_privacy", loader = stringsLoader)

    public actual val terms_of_service: StringResource = StringResource(key = "terms_of_service",
        loader = stringsLoader)

    public actual val privacy_policy: StringResource = StringResource(key = "privacy_policy", loader
        = stringsLoader)

    public actual val send_again: StringResource = StringResource(key = "send_again", loader =
        stringsLoader)

    public actual val verify: StringResource = StringResource(key = "verify", loader =
        stringsLoader)

    public actual val verify_email: StringResource = StringResource(key = "verify_email", loader =
        stringsLoader)

    public actual val email_sent_desc: StringResource = StringResource(key = "email_sent_desc",
        loader = stringsLoader)

    public actual val confirmation_email_was_sent_to: StringResource = StringResource(key =
        "confirmation_email_was_sent_to", loader = stringsLoader)

    public actual val blueprint_unavailable: StringResource = StringResource(key =
        "blueprint_unavailable", loader = stringsLoader)

    public actual val blueprint_unavailable_desc: StringResource = StringResource(key =
        "blueprint_unavailable_desc", loader = stringsLoader)

    public actual val blueprint_save_corrupted: StringResource = StringResource(key =
        "blueprint_save_corrupted", loader = stringsLoader)

    public actual val loading: StringResource = StringResource(key = "loading", loader =
        stringsLoader)

    public actual val warning_bot_is_active: StringResource = StringResource(key =
        "warning_bot_is_active", loader = stringsLoader)

    public actual val warning_bot_is_active_desc: StringResource = StringResource(key =
        "warning_bot_is_active_desc", loader = stringsLoader)

    public actual val warning_no_bot_token: StringResource = StringResource(key =
        "warning_no_bot_token", loader = stringsLoader)

    public actual val warning_no_bot_token_desc: StringResource = StringResource(key =
        "warning_no_bot_token_desc", loader = stringsLoader)

    public actual val refresh: StringResource = StringResource(key = "refresh", loader =
        stringsLoader)

    override fun values(): List<StringResource> = listOf(promo_premium, renews_at, expires_at,
        invocations_left, all, error, error_oops, error_empty, error_no_bots_type,
        error_no_bots_matching, error_something_went_wrong, error_sign_in, error_auth_exists,
        error_auth_invalid_credentials, error_auth_invalid_code, error_auth_timeout,
        error_link_cred_in_use, error_email_constraints, error_email_notfound,
        error_password_constraints, error_password_invalid_old, error_name_empty, error_bots_load,
        error_send_email_verification, error_invalid_verification_code, error_verify_email,
        error_load_logs, error_load_blueprints, error_blueprint_node, error_invalid_bot_token,
        error_invalid_bot_token_change, error_same_debug_token, error_network_try_again,
        warning_web_performance, warning_invalid_bot_token_debug, confirm_delete_blueprint,
        confirm_delete_account, confirm_delete_bot, delete_blueprint_success, bots_empty,
        blueprint_save_success, blueprint_max_nodes_reached, blueprint_max_nodes_reached_upgrade,
        link_apple_success, link_apple_fail, confirm, more, google_scope_drive,
        google_scope_drive_desc, google_scope_sheets, google_scope_sheets_desc, minutes_ago,
        hours_ago, days_ago, online, app_name, back, back_to_sign_in, welcome, new_bot, add_new,
        new_blueprint, blueprints, ok, cancel, name, token, debug_token_optional, name_caption,
        debug_token_caption, edit, save, restore, close, skip, create, delete, undo, clear, run,
        stop, logs, new_command, command_select, command_select_desc, bots_select_or_create, bots,
        settings, editor_prefs, ask_question, faq, or_lowercase, add_node, console, next, nodes,
        help_nodes, connect, help_connect, pins, help_pins, store, help_store, lets_try,
        help_lets_try, help_pin_types, arrays, help_arrays, generics, help_generics, null_safety,
        help_null_safety, extenders, help_extenders, appearance, automatic, manual, auto_night_mode,
        system_appearance, dark_mode, accent, font_scale, account, delete_account,
        delete_account_desc, link_provider, link_provider_desc, link_google, manage_google,
        link_google_success, link_google_fail, link_apple, unlink_apple, browser_sign_in_desc,
        select_google_features, devices, this_device, terminate, terminate_all_sessions,
        terminate_all_sessions_desc, active_sessions, active_sessions_desc, learn_more, auto_save,
        auto_save_desc, grid, grid_desc, guides, guides_desk, pin_types, vibration,
        vibrate_on_connect, vibrate_on_move, dock_bottom, dock_left, dock_right, min_log_level,
        subscription, upgrade, unlock_hosting, manage_subscription, subscribe, watch_video, current,
        free, per_month, days_free_then, subscribe_for, up_to_n_active_bots, up_to_n_execution_time,
        up_to_n_nodes, blueprint_logs, logs_statistics_nodes, help_info, on_mobile_only,
        invocations, invocations_help, execution_time, execution_time_help, max_running_bots,
        max_running_bots_help, blueprint_logs_help, dont_have_an_account, sign_up, sign_in,
        create_account, action_continue, restore_password, change_password, change_password_success,
        old_password, new_password, change, forgot_password, sign_out, email, password,
        sign_in_with_google, sign_in_with_apple, sign_in_social_without_account,
        sign_up_terms_and_privacy, terms_of_service, privacy_policy, send_again, verify,
        verify_email, email_sent_desc, confirmation_email_was_sent_to, blueprint_unavailable,
        blueprint_unavailable_desc, blueprint_save_corrupted, loading, warning_bot_is_active,
        warning_bot_is_active_desc, warning_no_bot_token, warning_no_bot_token_desc, refresh)
  }

  public actual object plurals : ResourceContainer<PluralsResource> {
    override fun values(): List<PluralsResource> = listOf()
  }

  public actual object images : ResourceContainer<ImageResource> {
    override fun values(): List<ImageResource> = listOf()
  }

  public actual object fonts : ResourceContainer<FontResource> {
    override fun values(): List<FontResource> = listOf()
  }

  public actual object files : ResourceContainer<FileResource> {
    override fun values(): List<FileResource> = listOf()
  }

  public actual object colors : ResourceContainer<ColorResource> {
    override fun values(): List<ColorResource> = listOf()
  }

  public actual object assets : ResourceContainer<AssetResource> {
    override fun values(): List<AssetResource> = listOf()
  }
}
