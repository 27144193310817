package org.botdesigner.blueprint.components

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.Flag
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector

/**
 * Callable blueprint node, that finishes blueprint execution.
 * */
interface BlueprintReturn<in T> : BlueprintProcedure<T> {

    override val icon: ImageVector
        get() = Icons.Outlined.Flag

    override val color: Color
        get() = BlueprintColors.Return

    fun value() : List<Any?>
}