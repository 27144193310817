package io.github.alexzhirkevich.onetap

import org.w3c.dom.BroadcastChannel


actual class BroadcastChannel actual constructor(key : String) {

    private val channel = BroadcastChannel(key)

    actual fun postMessage(msg: String) {
        channel.postMessage(msg)
    }

    actual fun onMessage(handler: (String) -> Unit) {
        channel.onmessage = {
            handler(it.data.toString())
        }
    }

    actual fun close() {
        channel.close()
    }
}