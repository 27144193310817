package org.botdesigner.blueprint.stdlib.pins

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.components.PinsDirectionalScope

@Serializable
@SerialName("DividerPin")
class DividerPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
) : Pin<Nothing>() {
    override val required: Boolean = false
    override val isOut: Boolean get() = false

    override val isUtil: Boolean
        get() = true

    override val name: String
        get() = ""

    override val color: Color
        get() = BlueprintColors.Util

    override val value: Nothing?
        get() = null
    override val parentId: Id?
        get() = null
    override val parentPinId: Id?
        get() = null


    override fun factory(pool: BlueprintNodesPool): PinFactory<Nothing> {
        TODO("Not yet implemented")
    }

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected: Boolean,
        pool: BlueprintNodesPool
    ) {
        Box(modifier.drawWithContent {
            drawContent()
            drawLine(
                color = color,
                start = Offset.Zero,
                end = Offset(1000f, 0f),
                strokeWidth = 1.dp.toPx()
            )
        })

    }

    override fun withReference(parent: Pin<*>?): Pin<Nothing> {
        TODO("Not yet implemented")
    }

    override fun withValue(value: String): Pin<Nothing> {
        TODO("Not yet implemented")
    }
}

fun PinsDirectionalScope.divider() {
    pin { order, isOut ->
        DividerPin(
            id = Id.randomId(),
            order = order,
            elId = elementId
        )
    }
}