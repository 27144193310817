package org.botdesigner.shared.domain

import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigator
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.decompose.value.Value
import kotlinx.serialization.KSerializer

@OptIn(ExperimentalDecomposeApi::class)
actual fun <C : Any> setupWebHistoryController(
    navigator: StackNavigator<C>,
    stack: Value<ChildStack<C, *>>,
    serializer: KSerializer<C>,
    getPath: (configuration: C) -> String,
    getConfiguration: (path: String) -> C,
){
    DefaultWebHistoryController().attach(
        navigator, stack, serializer, getPath, getConfiguration
    )
}