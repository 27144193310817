package org.botdesigner.botblueprints.telegram.pins

import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.components.*
import org.botdesigner.botblueprints.User
import org.botdesigner.telegram.User
import kotlinx.serialization.SerialName


object UserPinFactory : PinFactory<User>{
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): Pin<User> {
        return UserPin(
            id = id,
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
            required = required
        )
    }

    override fun array(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): ArrayPin<User> = UserArrayPinFactory
        .create(
            id = id,
            order = order,
            elementId = elementId,
            name = name,
            isOut = isOut,
            required = required
        )
}

@kotlinx.serialization.Serializable
@SerialName("TgUserPin")
data class UserPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: User? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : Pin<User>() {

    override val color: Color get() = BlueprintColors.User

    override fun factory(pool: BlueprintNodesPool): PinFactory<User> {
        return UserPinFactory
    }

    override fun withReference(parent: Pin<*>?): Pin<User> {
        return copy(parentId = parent?.elId, parentPinId = parent?.id, value = null)
    }

    override fun withValue(value: String): Pin<User> {
        return copy(value = null, parentId = null, parentPinId = null)
    }
}

fun PinsDirectionalScope.user(name : String) {
    pin { order, isOut ->
        UserPin(
            id = Id.randomId(),
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
        )
    }
}