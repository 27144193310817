@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.botblueprints.telegram.functions

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpTgDecomposeUser")
public class BpTgDecomposeUser(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.botblueprints.telegram.pins.UserPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "User",
              isOut = i,
              required = true
          )
                  }
          }
         
          output {
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Id",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "First Name",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "LastName",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Username",
              isOut = i,
              required = false
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Is Premium",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Is Bot",
              isOut = i,
              required = true
          )
      }
      }
      },
) : BpFunction() {
  override val summary: AnnotatedString
    get() = "Ge user properties: name, username, id, ...".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4284132730)

  override val name: String
    get() = "Decompose User"

  override val factory: FunctionFactory
    get() = ::BpTgDecomposeUser

  override suspend fun calculate(input: List<Any?>): List<Any?> = TgDecomposeUser(
  		requireNotNull(input[0] as org.botdesigner.telegram.User?) { 
  "User can't be null" 
  }
  	).values
}
