package org.botdesigner.shared.data.source.impl

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.patch
import io.ktor.client.request.post
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import org.botdesigner.api.CreateBlueprintRequest
import org.botdesigner.api.CreateBlueprintResponse
import org.botdesigner.api.GetBlueprintResponse
import org.botdesigner.api.GetBlueprintsResponse
import org.botdesigner.api.GetUpdatedBlueprintsRequest
import org.botdesigner.api.UpdateBlueprintRequest
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.botblueprints.toApi
import org.botdesigner.botblueprints.toCore
import org.botdesigner.shared.data.source.RemoteBlueprintDataSource


internal class RemoteBlueprintDataSourceImpl(
    private val httpClient: HttpClient
) : RemoteBlueprintDataSource {
    override suspend fun getAll(botId: String): List<BlueprintData> {
        return httpClient
            .get("/v1/bots/$botId/blueprints")
            .body<GetBlueprintsResponse>()
            .blueprints
            .map { it.toCore() }
    }

    override suspend fun getAllUpdated(
        botId: String,
        local : List<BlueprintData>
    ): List<BlueprintData> {
        val blueprintsMap = local.associateBy { it.id }
        val state = blueprintsMap.mapValues { it.value.editedAt }

        return httpClient
            .post("/v1/bots/$botId/blueprints/getUpdated") {
                setBody(GetUpdatedBlueprintsRequest(state))
            }
            .body<GetBlueprintsResponse>()
            .blueprints
            .map {
                if (it.blueprint.isEmpty()) {
                    it.copy(blueprint = blueprintsMap[it.id]?.blueprint.orEmpty())
                } else {
                    it
                }.toCore()
            }
    }

    override suspend fun get(botId: String, id: String): BlueprintData {
        return httpClient
            .get("/v1/bots/$botId/blueprints/$id")
            .body<GetBlueprintResponse>()
            .blueprint
            .toCore()
    }

    override suspend fun add(botId: String, blueprint: BlueprintData): BlueprintData {
        return httpClient
            .put("/v1/bots/$botId/blueprints") {
                setBody(
                    CreateBlueprintRequest(
                        type = blueprint.type.toApi(),
                        context = blueprint.context,
                        blueprint = blueprint.blueprint
                    )
                )
            }.body<CreateBlueprintResponse>().blueprintData.toCore()
    }

    override suspend fun delete(botId: String, id: String) {
        httpClient
            .delete("/v1/bots/$botId/blueprints/$id")
    }

    override suspend fun update(botId: String, blueprint: BlueprintData): BlueprintData {
        return httpClient
            .patch("/v1/bots/$botId/blueprints/${blueprint.id}") {
                setBody(
                    UpdateBlueprintRequest(
                        context = blueprint.context,
                        blueprint = blueprint.blueprint
                    )
                )
            }.body<CreateBlueprintResponse>().blueprintData.toCore()
    }

}