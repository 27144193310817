package org.botdesigner.shared.domain.content.blueprint

import androidx.compose.runtime.Immutable
import androidx.compose.ui.geometry.Offset
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.value.Value
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.shared.data.repo.EditorSettings
import org.botdesigner.shared.domain.ChildComponent
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.shared.domain.content.StackableComponent
import org.botdesigner.shared.domain.content.blueprint.store.BlueprintStoreComponent
import org.botdesigner.shared.domain.content.blueprint.terminal.BlueprintLogsComponent


@Immutable
data class BlueprintViewState(
    val isLoading : Boolean = false,
    val controlsEnabled : Boolean = true,
    val canUndo : Boolean = false
)

@Immutable
data class BlueprintTransformation(
    val scale : Float,
    val offset : Offset
)

data class BlueprintError(
    val nodeId : String
)

interface BlueprintComponent : StackableComponent {

    val settings : StateFlow<EditorSettings>

    val state: StateFlow<BlueprintViewState>

    val transformation : StateFlow<BlueprintTransformation>

    val blueprintErrors : Flow<BlueprintError>

    val blueprintManager : BlueprintManager

    val componentDialog : Value<ChildSlot<*, ComponentDialog<*>>>

    val alertDialog : Value<ChildSlot<*, AlertDialog<*>>>

    val console : Value<ChildSlot<*, BlueprintLogsComponent>>

    fun onContentDialogDismiss()

    fun onAddNodeClicked()

    fun onSaveClicked()

    fun onLogsClicked()

    fun onUndoClicked()

    sealed interface AlertDialog<T> : ChildComponent<T> {
        class BlueprintUnavailable(
            override val component: AlertConfirmationDialogComponent
        ) : AlertDialog<AlertConfirmationDialogComponent>
    }

    sealed interface ComponentDialog<T> : ChildComponent<T> {
        class Store(
            override val component : BlueprintStoreComponent
        ) : ComponentDialog<BlueprintStoreComponent>

    }
}
