package org.botdesigner.ui.common

import androidx.compose.foundation.LocalScrollbarStyle
import androidx.compose.foundation.VerticalScrollbar
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.rememberScrollbarAdapter
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import io.github.alexzhirkevich.LocalContentColor
import org.botdesigner.ui.theme.Dimens

@Composable
internal actual fun PlatformVerticalScrollbar(
    scrollableState: LazyListState,
    modifier: Modifier
){
    VerticalScrollbar(
        modifier = modifier
            .padding(end = Dimens.Spacing.ExtraSmall),
        style = LocalScrollbarStyle.current.copy(
            unhoverColor = LocalContentColor.current.copy(alpha = .2f),
            hoverColor = LocalContentColor.current.copy(alpha = .5f),
        ),
        adapter = rememberScrollbarAdapter(scrollableState)
    )
}