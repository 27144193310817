package org.botdesigner.blueprint.stdlib.functions.special

import androidx.compose.runtime.State
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Transient
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintFunctionStateHolder
import org.botdesigner.blueprint.components.BlueprintIcons
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.BlueprintProcedure
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.invokeIfPossible
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.next
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.stdlib.pins.string
import org.botdesigner.blueprint.toAnnotatedString
import kotlin.coroutines.cancellation.CancellationException

@kotlinx.serialization.Serializable
@SerialName("BpRunWithErrorHandling")
class BpRunWithErrorHandling(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id){
        output {
            string("Error")
        }
    },
    override val connectors: List<Connector> = Connectors(id){
        input()
        output("Run")
        output("On Error")
        output("Finally")
    }
) : BlueprintProcedure<@Contextual Any?> {

    @Transient
    private var error: String? = null

    override val summary: AnnotatedString
        get() = "Run block with error handling (A.k.a. try catch block). If error occurred in <b>Run</b> block then <b>Run</b> stops <b>On error</b> block is called. <b>Error</b> message can be empty. <b>Finally</b> bock is called at the end of <b>Run</b> or <b>On Error</b>"
            .toAnnotatedString()

    override val color: Color
        get() = BlueprintColors.Util

    override val icon: ImageVector
        get() = BlueprintIcons.Cycle


    @Suppress("UNCHECKED_CAST")
    override suspend fun <T> pinValue(pin: Pin<T>, pool: BlueprintNodesPool): T? {
        return error as? T?
    }

    override suspend fun invoke(context: Any?, pool: BlueprintNodesPool) {
        error = null
        try {
            outputConnectors[0].invokeNext(context,pool)
        } catch (t: CancellationException) {
            throw t
        } catch (t: Throwable) {
            outputConnectors[1].next(pool)
                ?.invokeIfPossible(context, pool)
        } finally {
            try {
                outputConnectors[2].next(pool)
                    ?.invokeIfPossible(context, pool)
            } finally {
                error = null
            }
        }
    }

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> =
        BlueprintFunctionStateHolder(manager) { coordinate, pins, connectors ->
            BpRunWithErrorHandling(coordinate, id, pins, connectors)
        }
}