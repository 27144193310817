package org.botdesigner.blueprint.generator

/**
 * Used to return multiple values from blueprint functions.
 *
 * Such function should declare its return type as
 * ToupleN<@Pin("value1") TypeA, @Pin("value2") TypeB>.
 *
 * Existing ToupleN declarations can be used or custom
 * type that inherit this interface can be created if default arguments count limit is not enough.
 * */
interface Tuple {
    val values : List<Any?>
}

/**
 * @see Tuple
 * */
interface Tuple2<T1, T2> : Tuple

/**
 * @see Tuple
 * */
interface Tuple3<T1, T2, T3> : Tuple2<T1,T2>

/**
 * @see Tuple
 * */
interface Tuple4<T1, T2, T3, T4> : Tuple3<T1,T2,T3>

/**
 * @see Tuple
 * */
interface Tuple5<T1, T2, T3, T4, T5> : Tuple4<T1,T2,T3,T4>

/**
 * @see Tuple
 * */
interface Tuple6<T1, T2, T3, T4, T5, T6> : Tuple5<T1,T2,T3,T4,T5>

/**
 * @see Tuple
 * */
interface Tuple7<T1, T2, T3, T4, T5, T6, T7> : Tuple6<T1,T2,T3,T4,T5, T6>

/**
 * @see Tuple
 * */
interface Tuple8<T1, T2, T3, T4, T5, T6, T7, T8> : Tuple7<T1,T2,T3,T4,T5, T6, T7>

/**
 * @see Tuple
 * */
interface Tuple17<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, T13, T14, T15, T16, T17> : Tuple

/**
 * @see Tuple
 * */
fun<T1, T2> Tuple(value1: T1, value2: T2)  =
    object : Tuple2<T1, T2> {
        override val values: List<Any?> = listOf(value1, value2)
    }

/**
 * @see Tuple
 * */
fun <T1, T2, T3> Tuple(value1 : T1, value2: T2, value3: T3)  =
    object : Tuple3<T1, T2, T3> {
        override val values: List<Any?> = listOf(value1, value2, value3)
    }

/**
 * @see Tuple
 * */
fun <T1, T2, T3, T4> Tuple(value1 : T1, value2: T2, value3: T3, value4 : T4) =
    object : Tuple4<T1, T2, T3, T4> {
        override val values: List<Any?> = listOf(value1, value2, value3, value4)
    }

/**
 * @see Tuple
 * */
fun <T1, T2, T3, T4, T5> Tuple(value1 : T1, value2: T2, value3: T3, value4 : T4, value5: T5) =
    object : Tuple5<T1, T2, T3, T4, T5> {
        override val values: List<Any?> = listOf(value1, value2, value3, value4, value5)
    }

/**
 * @see Tuple
 * */
fun <T1, T2, T3, T4, T5, T6> Tuple(
    value1 : T1,
    value2: T2,
    value3: T3,
    value4 : T4,
    value5: T5,
    value6: T6
) =
    object : Tuple6<T1, T2, T3, T4, T5, T6> {
        override val values: List<Any?> = listOf(value1, value2, value3, value4, value5, value6)
    }

/**
 * @see Tuple
 * */
fun <T1, T2, T3, T4, T5, T6, T7> Tuple(
    value1 : T1,
    value2: T2,
    value3: T3,
    value4 : T4,
    value5: T5,
    value6: T6,
    value7: T7,
) =
    object : Tuple7<T1, T2, T3, T4, T5, T6, T7> {
        override val values: List<Any?> = listOf(value1, value2, value3, value4, value5, value6, value7)
    }


/**
 * @see Tuple
 * */
fun <T1, T2, T3, T4, T5, T6, T7, T8> Tuple(
    value1 : T1,
    value2: T2,
    value3: T3,
    value4 : T4,
    value5: T5,
    value6: T6,
    value7: T7,
    value8: T8,
) =
    object : Tuple8<T1, T2, T3, T4, T5, T6, T7, T8> {
        override val values: List<Any?> = listOf(value1, value2, value3, value4, value5, value6, value7, value8)
    }

/**
 * @see Tuple
 * */
fun <T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, T13, T14, T15, T16, T17> Tuple(
    value1 : T1,
    value2: T2,
    value3: T3,
    value4 : T4,
    value5: T5,
    value6: T6,
    value7: T7,
    value8: T8,
    value9: T9,
    value10: T10,
    value11: T11,
    value12: T12,
    value13: T13,
    value14: T14,
    value15: T15,
    value16: T16,
    value17: T17,
) =
    object : Tuple17<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, T13, T14, T15, T16, T17> {
        override val values: List<Any?> = listOf(
            value1, value2, value3, value4, value5, value6,
            value7, value8, value9, value10, value11, value12,
            value13, value14, value15, value16, value17
        )
    }