package org.botdesigner.shared.`data`.cache

import app.cash.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import org.botdesigner.core.BotStatus
import org.botdesigner.core.BotType

public data class SqBot(
  public val id: String,
  public val ownerId: String,
  public val token: String,
  public val debugToken: String?,
  public val tokenValid: Boolean,
  public val debugTokenValid: Boolean,
  public val name: String,
  public val type: BotType,
  public val status: BotStatus,
  public val editedAt: Long,
  public val createdAt: Long,
  public val imageUrl: String?,
  public val realName: String?,
  public val username: String?,
) {
  public class Adapter(
    public val typeAdapter: ColumnAdapter<BotType, String>,
    public val statusAdapter: ColumnAdapter<BotStatus, String>,
  )
}
