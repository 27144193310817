package org.botdesigner.ui.common

import androidx.compose.foundation.MutatePriority
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.DragScope
import androidx.compose.foundation.gestures.DraggableState
import androidx.compose.foundation.gestures.Orientation
import androidx.compose.foundation.gestures.awaitEachGesture
import androidx.compose.foundation.gestures.awaitFirstDown
import androidx.compose.foundation.gestures.draggable
import androidx.compose.foundation.gestures.waitForUpOrCancellation
import androidx.compose.foundation.hoverable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.PressInteraction
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.Saver
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.Layout
import androidx.compose.ui.unit.Constraints
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import org.botdesigner.ui.theme.Dimens

@Composable
fun rememberSplitPaneState(
    orientation: Orientation,
    initialPaneSize: Dp,
    isStartPane: Boolean = true,
    paneSizeRange : (Orientation) -> ClosedRange<Dp> = { (0.dp)..(Dp.Infinity) }
) = rememberSaveable(
    saver = SplitPaneState.Saver(
        percentageRange = paneSizeRange,
        isStartPane = isStartPane
    )
) {
    SplitPaneState(
        orientation = orientation,
        initialPaneSize = initialPaneSize,
        paneSizeRange = paneSizeRange,
        isStartPane = isStartPane
    )
}

@Composable
fun SplitPane(
    state : SplitPaneState,
    modifier: Modifier = Modifier,
    enabled : Boolean = true,
    content1 : @Composable BoxScope.() -> Unit,
    content2 : @Composable BoxScope.() -> Unit,
    separator : @Composable () -> Unit  = {
        SplitPaneSeparator(state)
    }
) {
    val isSeparatorHovered by state.separatorInteractionSource.collectIsHoveredAsState()

    val paneModifier = if (enabled && (isSeparatorHovered || state.isDragInProgress))
        modifier.pointerHoverIcon(splitPaneSeparatorHoverIcon(state.orientation))
    else modifier

    SideEffect {
        state.enabled = enabled
    }

    SplitPaneImpl(
        state = state,
        modifier = paneModifier,
        content1 = content1,
        content2 = content2,
        separator = separator
    )
}

class SplitPaneState(
    orientation: Orientation,
    private val initialPaneSize: Dp,
    internal val isStartPane : Boolean = true,
    paneSizeRange : (Orientation) -> ClosedRange<Dp> = { (0.dp)..Dp.Infinity }
) : DraggableState {

    var _orientation by mutableStateOf(orientation)
    var orientation
        get() = _orientation
        set(value) {
            _orientation = value
            _paneSize = initialPaneSize
        }


    internal var enabled by mutableStateOf(true)

    private var _isDragInProgress by mutableStateOf(false)
    val isDragInProgress get() = _isDragInProgress

    val separatorInteractionSource = MutableInteractionSource()


    private var _paneSize by mutableStateOf(initialPaneSize)
    val size: Dp get() = _paneSize

    internal var maxSize: Int = 1
    internal var density: Float = 1f

    private val draggableState = DraggableState {
        val mul = if (isStartPane) 1 else -1
        _paneSize = (_paneSize + Dp(it / density * mul)).coerceIn(paneSizeRange(_orientation))
    }

    override fun dispatchRawDelta(delta: Float) {
        draggableState.dispatchRawDelta(delta)
    }

    override suspend fun drag(dragPriority: MutatePriority, block: suspend DragScope.() -> Unit) {
        draggableState.drag(dragPriority) {
            try {
                _isDragInProgress = true
                block()
            } finally {
                _isDragInProgress = false
            }
        }
    }

    companion object {
        fun Saver(
            percentageRange: (Orientation) -> ClosedRange<Dp>,
            isStartPane: Boolean
        ): Saver<SplitPaneState, *> = Saver(
            save = { listOf(it._paneSize, it.orientation) },
            restore = {
                SplitPaneState(
                    initialPaneSize = it[0] as Dp,
                    orientation = it[1] as Orientation,
                    paneSizeRange = percentageRange,
                    isStartPane = isStartPane
                )
            }
        )
    }
}


internal expect fun splitPaneSeparatorHoverIcon(orientation: Orientation) : PointerIcon

@Composable
private fun SplitPaneImpl(
    state : SplitPaneState,
    modifier: Modifier = Modifier,
    content1 : @Composable BoxScope.() -> Unit,
    content2 : @Composable BoxScope.() -> Unit,
    separator : @Composable () -> Unit  = {
        SplitPaneSeparator(state)
    },
) {
    Layout(
        modifier = modifier,
        content = {
            Box(
                content = content1
            )
            Box {
                separator()
            }
            Box(
                content = content2
            )
        }
    ) { measurables, constraints ->

        val maxSize = if (state.orientation == Orientation.Horizontal)
            constraints.maxWidth else constraints.maxHeight

        val withMaxSize: (Int) -> Constraints = if (state.orientation == Orientation.Horizontal) {
            { constraints.copy(minWidth = it, maxWidth = it) }
        } else {
            { constraints.copy(minHeight = it, maxHeight = it) }
        }

        state.maxSize = maxSize
        state.density = density

        val separatorMeasurable = measurables[1].measure(constraints)

        val paneSize = (state.size.toPx()).toInt().coerceIn(0, maxSize)

        val (content1Size, content2Size) = if (state.isStartPane) {
            paneSize to maxSize - paneSize
        } else {
            maxSize - paneSize to paneSize
        }

        val content1Measurable = measurables[0].measure(withMaxSize(content1Size))

        val content2Measurable = measurables[2].measure(withMaxSize(content2Size))

        layout(constraints.maxWidth, constraints.maxHeight) {
            content1Measurable.place(0, 0)
            if (state.orientation == Orientation.Horizontal) {
                content2Measurable.place(content1Size, 0)
                separatorMeasurable.place(
                    x = content1Size - separatorMeasurable.width / 2,
                    y = 0,
                    zIndex = 1f
                )
            } else {
                content2Measurable.place(0, content1Size)
                separatorMeasurable.place(
                    x = 0,
                    y = content1Size - separatorMeasurable.height / 2,
                    zIndex = 1f
                )
            }
        }
    }
}

@Composable
fun HorizontalMobileSplitPaneSeparator(
    modifier: Modifier,
    color: Color = MaterialTheme.colorScheme.secondary
) {
    Box(
        modifier
            .fillMaxWidth()
            .clip(CircleShape)
            .background(color)
    ) {
        Spacer(
            Modifier
                .fillMaxWidth()
                .height(Dimens.Spacing.Medium)
                .padding(
                    vertical = Dimens.Spacing.ExtraSmall,
                    horizontal = Dimens.Spacing.Small
                )
                .clip(CircleShape)
                .background(MaterialTheme.colorScheme.onSecondary)

        )
    }
}

@Composable
fun VerticalMobileSplitPaneSeparator(
    modifier: Modifier,
    color: Color = MaterialTheme.colorScheme.secondary
) {
    Box(
        modifier
            .fillMaxHeight()
            .clip(CircleShape)
            .background(color)
    ) {
        Spacer(
            Modifier
                .fillMaxHeight()
                .width(Dimens.Spacing.Medium)
                .padding(
                    vertical = Dimens.Spacing.Small,
                    horizontal = Dimens.Spacing.ExtraSmall
                )
                .clip(CircleShape)
                .background(MaterialTheme.colorScheme.onSecondary)

        )
    }
}

@Composable
fun SplitPaneSeparator(
    state: SplitPaneState,
    hoverPadding : Dp = 6.dp,
    separator: @Composable (Modifier) -> Unit = {
        if (state.orientation == Orientation.Horizontal) {
            VerticalDivider(it)
        } else {
            HorizontalDivider(it)
        }
    }
) {
    val modifier = Modifier
        .hoverable(state.separatorInteractionSource, state.enabled)
        .pressable(state.separatorInteractionSource, state.enabled)
        .draggable(state, state.orientation, state.enabled)
        .padding(
            horizontal = if (state.orientation == Orientation.Horizontal)
                hoverPadding else 0.dp,
            vertical = if (state.orientation == Orientation.Vertical)
                hoverPadding else 0.dp,
        ).excludeFromSystemGestures()

    separator(modifier)
}

private fun Modifier.pressable(
    interactionSource: MutableInteractionSource,
    enabled: Boolean
) = pointerInput(interactionSource, enabled) {
    if (enabled) {
        awaitEachGesture {
            val down = awaitFirstDown()
            val press = PressInteraction.Press(down.position)
            interactionSource.tryEmit(press)

            val up = waitForUpOrCancellation()

            interactionSource.tryEmit(
                if (up != null)
                    PressInteraction.Release(press)
                else
                    PressInteraction.Cancel(press)
            )
        }
    }
}