package org.botdesigner.botblueprints


import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.store.BlueprintNodeCategory
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.blueprint.store.BlueprintNodeStoreRecord

val BlueprintColors.Message : Color
    get() = Color(0xfff77ce5)

val BlueprintColors.Chat : Color
    get() = Color(0xff37bad4)

val BlueprintColors.User : Color
    get() = Color(0xff6488ea)

val BlueprintColors.Keyboard : Color
    get() = Color(0xff03fcb1)

val BlueprintColors.KeyboardButton : Color
    get() = Color(0xffD95030)



fun BlueprintNodeStoreOwner.registerBotComponents(){

    register_org_botdesigner_botblueprints()

}

