@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.integrations.google

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Pair
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.io.context.NetworkContext
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpGoogleSheetValue")
public class BpGoogleSheetValue(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Sharing URL",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Key",
              isOut = i,
              required = true
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.StringPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = false
              )
          }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("")
      },
) : BpProcedure<@Contextual NetworkContext>() {
  override val summary: AnnotatedString
    get() =
        "Get value from Google Sheet. Keys are stored in the 1st column and values in the 2nd. Sheet must be public for reading".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4280696141)

  override val factory: ProcedureFactory<@Contextual NetworkContext>
    get() = ::BpGoogleSheetValue

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual NetworkContext,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(GoogleSheetValue(
  		requireNotNull(input[0] as kotlin.String?) { 
  "Sharing URL can't be null" 
  },
  		requireNotNull(input[1] as kotlin.String?) { 
  "Key can't be null" 
  }
  	))}
}
