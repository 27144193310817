package org.botdesigner.ui.common

import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusManager
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onKeyEvent
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalFocusManager

@Composable
internal fun ClearFocusBox(
    modifier: Modifier = Modifier,
    enabled : Boolean = true,
    contentAlignment: Alignment = Alignment.TopStart,
    onEscape : () -> Unit = {},
    content : @Composable BoxScope.(FocusManager) -> Unit
) {
    val focusManager = LocalFocusManager.current

    var hasFocus by remember {
        mutableStateOf(false)
    }
    val focusModifier = if (enabled && hasFocus)
        Modifier.pointerInput(0) {
            detectTapGestures {
                focusManager.clearFocus(force = true)
            }
        }.onKeyEvent {
            if (it.key == Key.Escape) {
                focusManager.clearFocus(force = true)
                onEscape()
                true
            } else {
                false
            }
        }
    else Modifier

    Box(
        modifier = modifier.onFocusChanged {
            hasFocus = it.hasFocus
        } then focusModifier,
        contentAlignment = contentAlignment,
        content = {
            content(focusManager)
        }
    )
}