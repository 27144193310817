package org.botdesigner.ui.screens.settings

import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveSwitch
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.section.CupertinoLinkIcon
import io.github.alexzhirkevich.cupertino.section.CupertinoSection
import io.github.alexzhirkevich.cupertino.section.SectionItem
import io.github.alexzhirkevich.cupertino.section.SectionLink
import io.github.alexzhirkevich.cupertino.section.sectionTitle
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.systemOrange
import io.github.alexzhirkevich.cupertino.theme.systemYellow
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.content.settings.EditorSettingsComponent
import org.botdesigner.ui.common.Support
import org.botdesigner.ui.screens.PreferencesScreen
import org.botdesigner.ui.string

@OptIn(ExperimentalCupertinoApi::class, ExperimentalAdaptiveApi::class)
@Composable
internal fun EditorSettingsScreen(
    component: EditorSettingsComponent,
    modifier: Modifier = Modifier,
) {

    val state by component.state.collectAsState()

    PreferencesScreen(
        modifier = modifier,
        onBack = component::onBack,
        title = string(SharedRes.strings.editor_prefs)
    ){
        CupertinoSection(
            caption = {
                Text(string(SharedRes.strings.auto_save_desc))
            }
        ) {
            SectionItem(
                trailingContent = {
                    AdaptiveSwitch(
                        checked = state.isAutoSaveEnabled,
                        onCheckedChange = component::onAutoSaveChanged,
                    )
                }
            ){
                Text(string(SharedRes.strings.auto_save))
            }
        }

        CupertinoSection(
        ) {
            SectionItem(
                trailingContent = {
                    AdaptiveSwitch(
                        checked = state.isGridEnabled,
                        onCheckedChange = component::onDrawGridChanged,
                    )
                }
            ){
                Text(string(SharedRes.strings.grid))
            }
        }

        if (CurrentPlatform.isMobile) {
            CupertinoSection(
                title = {
                    Text(string(SharedRes.strings.vibration).sectionTitle())
                }
            ) {
                SectionItem(
                    trailingContent = {
                        AdaptiveSwitch(
                            checked = state.vibrateOnMove,
                            enabled = CurrentPlatform.isMobile,
                            onCheckedChange = component::onVibrateOnMoveChanged,
                        )
                    }
                ){
                    Text(string(SharedRes.strings.vibrate_on_move))
                }

                SectionItem(
                    trailingContent = {
                        AdaptiveSwitch(
                            checked = state.vibrateOnConnect,
                            enabled = CurrentPlatform.isMobile,
                            onCheckedChange = component::onVibrateOnConnectChanged,
                        )
                    }
                ){
                    Text(string(SharedRes.strings.vibrate_on_connect))
                }
            }
        }

        CupertinoSection(
            title = {
                Text(string(SharedRes.strings.guides).sectionTitle())
            },
            caption = {
                Text(string(SharedRes.strings.guides_desk))
            },
        ) {
            SectionLink(
                onClick = component::onBlueprintGuideClicked,
                icon = {
                    CupertinoLinkIcon(
                        imageVector = AdaptiveIcons.Filled.Support,
                        containerColor = CupertinoColors.systemYellow
                    )
                }
            ){
                Text(string(SharedRes.strings.blueprints))
            }
            SectionLink(
                onClick = component::onPinsGuideClicked,
                icon = {
                    CupertinoLinkIcon(
                        imageVector = AdaptiveIcons.Filled.Support,
                        containerColor = CupertinoColors.systemOrange
                    )
                }
            ){
                Text(string(SharedRes.strings.pin_types))
            }
        }
    }
}