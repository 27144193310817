package org.botdesigner.blueprint.components

import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.components.functions.BpProcedure

/**
 * Callable blueprint element.
 *
 * Should not be inherited directly. Inherit [BpProcedure] instead
 * */
interface BlueprintProcedure<in T> :
    BlueprintFunction, BlueprintConnectorsOwner, BlueprintInvocable<T> {

    override val color: Color
        get() = BlueprintColors.Function

    /**
     * Perform procedure action.
     *
     * After performing element must call super or [invokeNext]
     * */
    override suspend operator fun invoke(context: T, pool: BlueprintNodesPool)
}

val BlueprintProcedure<*>.outputConnectors : List<Connector>
    get() = connectors.filter { it.isOut }.sortedBy { it.order }

val BlueprintProcedure<*>.inputConnectors : List<Connector>
    get() = connectors.filter { !it.isOut }.sortedBy { it.order }