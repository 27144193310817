
package org.botdesigner.ui.screens.profile

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.basicMarquee
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Devices
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ProvideTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.layout.layout
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import io.github.alexzhirkevich.cupertino.CupertinoSurface
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTopAppBar
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.isTopBarTransparent
import io.github.alexzhirkevich.cupertino.section.CupertinoLinkIcon
import io.github.alexzhirkevich.cupertino.section.CupertinoSection
import io.github.alexzhirkevich.cupertino.section.CupertinoSectionDefaults
import io.github.alexzhirkevich.cupertino.section.LocalSectionStyle
import io.github.alexzhirkevich.cupertino.section.SectionItem
import io.github.alexzhirkevich.cupertino.section.SectionLink
import io.github.alexzhirkevich.cupertino.section.SectionScope
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import io.github.alexzhirkevich.cupertino.theme.systemBlue
import io.github.alexzhirkevich.cupertino.theme.systemGray
import io.github.alexzhirkevich.cupertino.theme.systemIndigo
import io.github.alexzhirkevich.cupertino.theme.systemOrange
import io.github.alexzhirkevich.cupertino.theme.systemPurple
import io.github.alexzhirkevich.cupertino.theme.systemTeal
import io.github.alexzhirkevich.cupertino.theme.systemYellow
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.shared.domain.content.ContentComponent
import org.botdesigner.shared.domain.content.profile.ProfileComponent
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.valueOrNull
import org.botdesigner.ui.common.Book
import org.botdesigner.ui.common.DayNight
import org.botdesigner.ui.common.Person
import org.botdesigner.ui.common.Settings
import org.botdesigner.ui.common.Star
import org.botdesigner.ui.common.Support
import org.botdesigner.ui.common.darken
import org.botdesigner.ui.common.isLoading
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.platformClickable

private val DesktopSectionPadding = PaddingValues(horizontal = 12.dp, vertical = 6.dp)

@OptIn(
    ExperimentalCupertinoApi::class,
    ExperimentalAdaptiveApi::class,
    ExperimentalMaterial3Api::class,
    ExperimentalFoundationApi::class
)
@Composable
internal fun ProfileScreen(
    component: ProfileComponent
) {

    val user by component.userInfo.collectAsState()

    val scrollState = rememberScrollState()

    AdaptiveScaffold(
        containerColor = Color.Transparent,
        topBar = {
            AdaptiveTopAppBar(
                title = {
                    Text(
                        text = user.valueOrNull()?.user?.name ?: "******",
                        modifier = Modifier
                            .basicMarquee()
                            .isLoading(user.valueOrNull() == null)
                    )
                },
                adaptation = {
                    cupertino {
                        isTransparent = scrollState.isTopBarTransparent
                    }
                }
            )
        },
    ) {

        val sectionPadding = if (CurrentPlatform.isMobile) {
            CupertinoSectionDefaults.paddingValues(
                style = LocalSectionStyle.current,
                includePaddingBetweenSections = true
            )
        }
        else {
            DesktopSectionPadding
        }

        val idiom = LocalInterfaceIdiom.current
        val topComponent by component.topComponent.collectAsState()

        val dividerPadding = if (LocalInterfaceIdiom.current == InterfaceIdiom.Desktop)
            PaddingValues(start = 10000.dp)
        else PaddingValues(start = CupertinoSectionDefaults.DividerPadding)

        Column(
            Modifier
                .verticalScroll(scrollState)
                .padding(it)
        ) {
            CupertinoSection(
                modifier = Modifier
                    .padding(top = Dimens.Spacing.ExtraLarge),
                contentPadding = sectionPadding,
                dividerPadding = dividerPadding
            ) {

                AdaptiveLink(
                    idiom = idiom,
                    onClick = component::onPremiumClicked,
                    icon = {
                        CupertinoLinkIcon(
                            imageVector = AdaptiveIcons.Filled.Star,
                            tint = MaterialTheme.colorScheme.onPrimary,
                            containerColor = CupertinoColors.systemPurple
                        )
                    },
                    caption = {
                        Text(
                            text = user.valueOrNull()?.subscription?.name.orEmpty(),
                            maxLines = 1,
                            overflow = TextOverflow.Clip,
                            modifier = Modifier.isLoading(
                                loading = user is UIState.Loading
                            ).layout { measurable, constraints ->
                                if (constraints.maxWidth > 50.dp.toPx()) {
                                    val p = measurable.measure(constraints)
                                    layout(p.width, p.height) {
                                        p.place(0, 0)
                                    }
                                } else {
                                    layout(0, 0) {

                                    }
                                }
                            }
                        )
                    }
                ) {
                    Column {
                        Text(
                            text = string(SharedRes.strings.subscription)
                        )
                        user.valueOrNull()?.subscriptionCaption
                            ?.let {
                                Text(
                                    text = string(it),
                                    style = MaterialTheme.typography.labelMedium,
                                    color = MaterialTheme.colorScheme.secondary
                                )
                            }
                    }
                }
            }
            CupertinoSection(
                contentPadding = sectionPadding,
                dividerPadding = dividerPadding
            ) {
                AdaptiveLink(
                    idiom = idiom,
                    selected = topComponent is ContentComponent.Child.Sessions,
                    onClick = component::onDevicesClicked,
                    icon = {
                        CupertinoLinkIcon(
                            imageVector = Icons.Default.Devices,
                            tint = MaterialTheme.colorScheme.onPrimary,
                            containerColor = CupertinoColors.systemIndigo
                        )
                    }
                ) {
                    Text(string(SharedRes.strings.devices))
                }
                AdaptiveLink(
                    idiom = idiom,
                    onClick = component::onAccountClicked,
                    selected = topComponent is ContentComponent.Child.Account,
                    icon = {
                        CupertinoLinkIcon(
                            imageVector = AdaptiveIcons.Filled.Person,
                            tint = MaterialTheme.colorScheme.onPrimary,
                            containerColor = CupertinoColors.systemBlue
                        )
                    }
                ) {
                    Text(string(SharedRes.strings.account))
                }
                AdaptiveLink(
                    idiom = idiom,
                    selected = topComponent is ContentComponent.Child.Appearance,
                    onClick = component::onAppearanceClicked,
                    icon = {
                        CupertinoLinkIcon(
                            imageVector = AdaptiveIcons.Filled.DayNight,
                            tint = MaterialTheme.colorScheme.onPrimary,
                            containerColor = CupertinoColors.systemTeal
                        )
                    }
                ) {
                    Text(string(SharedRes.strings.appearance))
                }
                EditorLink(
                    idiom = idiom,
                    selected = topComponent is ContentComponent.Child.Settings,
                    onClick = component::onSettingsClicked,
                )
            }
            CupertinoSection(
                contentPadding = sectionPadding,
                dividerPadding = dividerPadding
            ) {
                AdaptiveLink(
                    idiom = idiom,
                    onClick = component::onAskQuestionClicked,
                    icon = {
                        CupertinoLinkIcon(
                            imageVector = AdaptiveIcons.Filled.Support,
                            tint = MaterialTheme.colorScheme.onPrimary,
                            containerColor = CupertinoColors.systemYellow
                        )
                    }
                ) {
                    Text(string(SharedRes.strings.ask_question))
                }
                AdaptiveLink(
                    idiom = idiom,
                    onClick = component::onAskQuestionClicked,
                    icon = {
                        CupertinoLinkIcon(
                            imageVector = AdaptiveIcons.Filled.Book,
                            tint = MaterialTheme.colorScheme.onPrimary,
                            containerColor = CupertinoColors.systemOrange
                        )
                    }
                ) {
                    Text(string(SharedRes.strings.faq))
                }
            }

            CupertinoSection(
                contentPadding = sectionPadding
            ) {
                AdaptiveLink(
                    idiom = idiom,
                    onClick = component::onLogout,
                    trailingIcon = {}
                ) {
                    Text(
                        color = MaterialTheme.colorScheme.error,
                        text = string(SharedRes.strings.sign_out)
                    )
                }
            }
        }
    }
}

@Composable
fun SectionScope.EditorLink(
    idiom : InterfaceIdiom,
    selected: Boolean,
    onClick: () -> Unit,
)=  AdaptiveLink(
    idiom = idiom,
    selected = selected,
    onClick = onClick,
    icon = {
        CupertinoLinkIcon(
            imageVector = AdaptiveIcons.Filled.Settings,
            tint = MaterialTheme.colorScheme.onPrimary,
            containerColor = CupertinoColors.systemGray
        )
    }
) {
    Text(string(SharedRes.strings.editor_prefs))
}

@OptIn(ExperimentalCupertinoApi::class)
@Composable
fun SectionScope.AdaptiveLink(
    onClick : () -> Unit,
    idiom: InterfaceIdiom,
    onClickLabel : String? = null,
    selected : Boolean = false,
    trailingIcon : @Composable () -> Unit = {
        CupertinoSectionDefaults.LabelChevron()
    },
    caption : @Composable () -> Unit = {},
    icon : (@Composable () -> Unit)?=null,
    title : @Composable () -> Unit,
) {
    if (idiom == InterfaceIdiom.Mobile) {
        SectionLink(
            onClickLabel = onClickLabel,
            onClick = onClick,
            icon = {
                icon?.invoke()
            },
            caption = caption,
            title = title,
            chevron = trailingIcon
        )
    } else {
        SectionItem(
            paddingValues = PaddingValues(0.dp)
        ) {
            CupertinoSurface(
                modifier = Modifier
                    .fillMaxWidth()
                    .pointerHoverIcon(PointerIcon.platformClickable(!selected)),
                onClick = onClick,
                enabled = !selected,
                color = if (selected)
                    MaterialTheme.colorScheme.primary.darken()
                else Color.Transparent,
                shape = MaterialTheme.shapes.medium
            ) {
                Row(
                    modifier = Modifier
                        .padding(DesktopSectionPadding)
                        .heightIn(32.dp),
                    horizontalArrangement = Arrangement
                        .spacedBy(Dimens.Spacing.Medium),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    icon?.invoke()
                    CompositionLocalProvider(
                        LocalContentColor provides
                                if (selected) MaterialTheme.colorScheme.onPrimary
                                else LocalContentColor.current
                    ) {
                        title()
                    }
                    if (!selected) {
                        Spacer(Modifier.weight(1f))
                        CompositionLocalProvider(
                            LocalContentColor provides CupertinoTheme.colorScheme.secondaryLabel
                        ) {
                            ProvideTextStyle(
                                CupertinoTheme.typography.subhead,
                                content = caption
                            )
                        }
                        Box(Modifier.scale(.75f)) {
                            trailingIcon()
                        }
                    }
                }
            }
        }
    }
}

