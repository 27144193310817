package org.botdesigner.shared.data.repo

import androidx.compose.runtime.Immutable
import kotlinx.serialization.Serializable

enum class ConsolePosition {
    Bottom, End
}

@Serializable
@Immutable
data class EditorSettings(
    val isAutoSaveEnabled : Boolean = false,
    val isGridEnabled : Boolean = true,
    val vibrateOnMove : Boolean = false,
    val vibrateOnConnect : Boolean = false,
    val consoleFontScale : Float = 1f,
    val consolePosition : ConsolePosition = ConsolePosition.Bottom,
    val isBlueprintGuideSeen : Boolean = false
)

@Serializable
class AppearanceSettings(
    val autoNightMode : Boolean = true,
    val systemAppearance : Boolean = true,
    val darkMode : Boolean = false,
    val accent : Accent = Accent.Purple,
    val fontScale : Float = 1f,
    val paneWidth : Float = 300f
)

enum class Accent {
    Red,
    Orange,
    Green,
    Teal,
    Blue,
    Indigo,
    Purple,
}


interface SettingsRepository {

    suspend fun saveEditor(settings: EditorSettings)

    suspend fun loadEditor() : EditorSettings

    suspend fun saveAppearance(appearance: AppearanceSettings)

    suspend fun loadAppearance() : AppearanceSettings
}
