
package org.botdesigner.blueprint.components

import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.contract

interface BlueprintConnectorsOwner {
    val connectors: List<Connector>
}

@OptIn(ExperimentalContracts::class)
fun BlueprintNode.connectorsOrNull() : List<Connector>? {
    contract {
        returnsNotNull() implies (this@connectorsOrNull is BlueprintConnectorsOwner)
    }

    return (this as? BlueprintConnectorsOwner)?.connectors
}