package org.botdesigner.api

object SharedConstants {
    const val FreeInvocationsPerDay = 100
    const val FreeInvocationsPerRewardedAd = 100

    const val GooglePlayUrl = "https://play.google.com/"
    const val AppStoreUrl = "https://apps.apple.com/"
    const val SupportEmail = "support@botdesigner.org"

    const val GoogleApiKey = "AIzaSyBcJytSHs44AXdp6v10zAfsS1G9wv3PaJY"
    const val AppleRCPublicKey = "appl_pQoRkPeStafmMWDTVbXTwnZkNFI"
    const val GPlayRCPublicKey = "goog_sChfVMnxXehHTUGebSdiHXQGlMH"
    const val GPlayManagementUrl = "https://play.google.com/store/account/subscriptions"

    val telegramAllowedUpdated = listOf(
        "message","edited_message","channel_post",
        "edited_channel_post","inline_query",
        "chosen_inline_result","callback_query",
        "shipping_query","pre_checkout_query",
        "poll","poll_answer","my_chat_member","chat_member",
        "chat_join_request"
    )


    const val AppNameEng = "Bot Designer"
    const val AppUrlScheme = "botdesigner"
    const val AppId = "org.botdesigner.app"
    const val Domain = "botdesigner.org"

    const val DownloadAppUrl = "https://$Domain"
    const val WebAppUrl = "https://app.$Domain"

    const val IOReadingLimitBytes = 1024 * 1024


    private const val OAuthRedirectForCustomUri = "https://api.botdesigner.org/v1/auth/oauth2callback"

    const val AppleOAuthClientIdWeb = "org.botdesigner.account"
    const val AppleOauthClientIdIOS = AppId
    const val AppleOAuthRedirectUriDefault = "https://botdesigner.org"
    const val AppleOAuthRedirectUriCustomScheme = OAuthRedirectForCustomUri
    const val GoogleOauthClientIdIOS = "102223468176-05c2rurfa3llaa216750e6rbo548drfn.apps.googleusercontent.com"
    const val GoogleOauthClientIdDefault = "102223468176-7g6j4ubksj6vt5e7lcs3vv9d2pmra6r4.apps.googleusercontent.com"
    const val GoogleOauthRedirectUriIOS = "com.googleusercontent.apps.102223468176-05c2rurfa3llaa216750e6rbo548drfn:/oauth2callback"
    const val GoogleOauthRedirectUriDesktop = OAuthRedirectForCustomUri
    const val GoogleOauthRedirectUriDefault = "https://app.botdesigner.org/oauth2callback"
//    const val GoogleOauthRedirectUriDefault = "http://localhost:8080/oauth2callback"


    const val TGCreationGuide = "https://core.telegram.org/bots/features#botfather"
}