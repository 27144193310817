package org.botdesigner.shared.data.repo.impl

import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.flow
import org.botdesigner.api.auth.Session
import org.botdesigner.shared.data.repo.SessionRepository
import org.botdesigner.shared.data.source.CacheSessionsDataSource
import org.botdesigner.shared.data.source.SessionsDataSource
import org.botdesigner.shared.util.rethrowCancellation
import org.botdesigner.shared.util.runCatchingIgnoringCancellation

class SessionRepositoryImpl(
    private val remoteDataSource: SessionsDataSource,
    private val cacheDataSource: CacheSessionsDataSource
) : SessionRepository {

    private val updateTrigger = MutableSharedFlow<UpdateSource>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    override fun sessions(): Flow<List<Session>> = DataFlow(
        updateTrigger = updateTrigger,
        remote = remoteDataSource::getAll,
        cache = cacheDataSource::getAll,
        save = cacheDataSource::save,
        postTransform = { it.sortedByDescending(Session::lastActive) }
    )

    override suspend fun stop(sessionId: String) {
        remoteDataSource.close(sessionId)
        runCatchingIgnoringCancellation {
            cacheDataSource.close(sessionId)
        }
        updateTrigger.emit(UpdateSource.Both())
    }

    override suspend fun stopAll() {
        remoteDataSource.closeAll()
        runCatchingIgnoringCancellation {
            cacheDataSource.closeAll()
        }
        updateTrigger.emit(UpdateSource.Both())
    }
}