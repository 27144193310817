package org.botdesigner.ui.screens.list

import androidx.compose.animation.animateColorAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.SuggestionChip
import androidx.compose.material3.SuggestionChipDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import io.github.alexzhirkevich.cupertino.CupertinoButton
import io.github.alexzhirkevich.cupertino.CupertinoButtonDefaults.filledButtonColors
import io.github.alexzhirkevich.cupertino.CupertinoButtonDefaults.grayButtonColors
import io.github.alexzhirkevich.cupertino.CupertinoButtonSize
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveWidget
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import org.botdesigner.shared.domain.content.list.BotFilter
import org.botdesigner.shared.domain.content.list.BotFilterComponent
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable

@OptIn(ExperimentalStdlibApi::class)
@Composable
internal fun BotFilterChip(
    component: BotFilterComponent,
    modifier: Modifier = Modifier
) {

    val activeFilter by component.botsFilter.collectAsState()

    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement
            .spacedBy(Dimens.Spacing.Medium),
        verticalAlignment = Alignment.CenterVertically,
    ) {

        BotFilter.entries.forEach {

            SelectorChip(
                selected = activeFilter == it,
                onSelected = {
                    component.onFilterChanged(it)
                },
                label = {
                    Text(string(it.label))
                }
            )
        }
    }
}

@OptIn(ExperimentalAdaptiveApi::class, ExperimentalCupertinoApi::class)
@Composable
fun SelectorChip(
    selected : Boolean,
    onSelected : () -> Unit,
    label : @Composable () -> Unit
) {
    val containerColor by animateColorAsState(
        targetValue = if (selected)
            MaterialTheme.colorScheme.secondary
        else MaterialTheme.colorScheme.onSurface.copy(alpha = .1f)
    )

    val labelColor by animateColorAsState(
        targetValue = if (selected)
            MaterialTheme.colorScheme.onSecondary
        else LocalContentColor.current
    )

    AdaptiveWidget(
        material = {
            SuggestionChip(
                modifier = Modifier
                    .pointerHoverIcon(PointerIcon.PlatformClickable),
                onClick = onSelected,
                colors = SuggestionChipDefaults.suggestionChipColors(
                    containerColor = containerColor,
                    labelColor = labelColor
                ),
                label = label,
                border = null,
                shape = CircleShape
            )
        },
        cupertino = {
            CupertinoButton(
                onClick = onSelected,
                size = CupertinoButtonSize.Small,
                colors = if (selected)
                    filledButtonColors(
                        indicationColor = Color.Transparent
                    )
                else grayButtonColors(),
                modifier = Modifier
                    .padding(bottom = Dimens.Spacing.Small)
                    .pointerHoverIcon(PointerIcon.PlatformClickable)

            ){
                label()
            }
        }
    )

}