package org.botdesigner.blueprint.io.serialization

import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.io.pins.ByteStreamPin
import org.botdesigner.blueprint.io.pins.ByteStreamPinFactory

internal val BpIOPinModule = SerializersModule {

    polymorphic(Pin::class){
        subclass(ByteStreamPin::class)
    }

    polymorphic(PinFactory::class){
        subclass(ByteStreamPinFactory::class)
    }
}