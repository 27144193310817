package org.botdesigner.botblueprints.telegram.blueprints

import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext
import org.botdesigner.botblueprints.telegram.functions.BpTgChatJoinRequested
import org.botdesigner.botblueprints.telegram.functions.BpTgDecomposeUser
import org.botdesigner.botblueprints.telegram.functions.BpTgJoinChatRespond

fun ChatJoinRequestBlueprint(id : Id) : Blueprint {
    val messageReceived = BpTgChatJoinRequested()

    var decomposeUser : BpFunction = BpTgDecomposeUser(Point(600f,600f))
    var respond :BpProcedure<TelegramBotBlueprintContext> = BpTgJoinChatRespond(Point(1400f,0f))


    decomposeUser = decomposeUser.factory(
        decomposeUser.coordinate,
        decomposeUser.id,
        listOf(decomposeUser.pins.first().withReference(messageReceived.pins.first())) +
                decomposeUser.pins.drop(1)
    )

    respond = respond.factory(
        respond.coordinate,
        respond.id,
        listOf(respond.pins.first().withReference(decomposeUser.pins[1])) +
                respond.pins[1].withReference(messageReceived.pins[1]) +
                respond.pins.drop(2),
        respond.connectors
    )

    return Blueprint(
        elements = listOf(messageReceived, decomposeUser, respond)
    )
}