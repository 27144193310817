package io.github.alexzhirkevich.onetap


expect class OAuthSignInClient<R>(
    uri : String,
    redirectUri : String,
    onRedirected : (String) -> R
) : AbstractSignInClient<R>


private val stateAlphabet = "abcdefghijklmnopqrstuvwxyz"

fun randomState() = buildString {
    repeat(12) {
        append(stateAlphabet.random())
    }
}

internal val String.queryParameters : Map<String,String>
    get() = substringAfter("?")
        .split("&")
        .associate { p ->
            p.split("=").let { it[0] to it.getOrNull(1).orEmpty() }
        }


