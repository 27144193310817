package org.botdesigner.core

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.AssetResource
import dev.icerock.moko.resources.ColorResource
import dev.icerock.moko.resources.FileResource
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.PluralsResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "c137cb87f316b3c408245ea4d6017a64"

  public val stringsLoader: RemoteJsStringLoader = strings.stringsLoader

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    private val stringsFallbackFileUrl: String =
        js("require(\"localization/orgbotdesignercore_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        ))

    override val stringsLoader: RemoteJsStringLoader = RemoteJsStringLoader.Impl(supportedLocales =
        supportedLocales, fallbackFileUri = stringsFallbackFileUrl)

    public actual val command: StringResource = StringResource(key = "command", loader =
        stringsLoader)

    public actual val stopped: StringResource = StringResource(key = "stopped", loader =
        stringsLoader)

    public actual val running: StringResource = StringResource(key = "running", loader =
        stringsLoader)

    public actual val tg_token_desc: StringResource = StringResource(key = "tg_token_desc", loader =
        stringsLoader)

    public actual val scheduled: StringResource = StringResource(key = "scheduled", loader =
        stringsLoader)

    public actual val scheduled_desc: StringResource = StringResource(key = "scheduled_desc", loader
        = stringsLoader)

    public actual val message_received: StringResource = StringResource(key = "message_received",
        loader = stringsLoader)

    public actual val message_received_desc: StringResource = StringResource(key =
        "message_received_desc", loader = stringsLoader)

    public actual val command_received: StringResource = StringResource(key = "command_received",
        loader = stringsLoader)

    public actual val command_received_desc: StringResource = StringResource(key =
        "command_received_desc", loader = stringsLoader)

    public actual val document_received: StringResource = StringResource(key = "document_received",
        loader = stringsLoader)

    public actual val document_received_desc: StringResource = StringResource(key =
        "document_received_desc", loader = stringsLoader)

    public actual val sticker_received: StringResource = StringResource(key = "sticker_received",
        loader = stringsLoader)

    public actual val sticker_received_desc: StringResource = StringResource(key =
        "sticker_received_desc", loader = stringsLoader)

    public actual val image_received: StringResource = StringResource(key = "image_received", loader
        = stringsLoader)

    public actual val image_received_desc: StringResource = StringResource(key =
        "image_received_desc", loader = stringsLoader)

    public actual val video_received: StringResource = StringResource(key = "video_received", loader
        = stringsLoader)

    public actual val video_received_desc: StringResource = StringResource(key =
        "video_received_desc", loader = stringsLoader)

    public actual val voice_received: StringResource = StringResource(key = "voice_received", loader
        = stringsLoader)

    public actual val voice_received_desc: StringResource = StringResource(key =
        "voice_received_desc", loader = stringsLoader)

    public actual val videonote_received: StringResource = StringResource(key =
        "videonote_received", loader = stringsLoader)

    public actual val videonote_received_desc: StringResource = StringResource(key =
        "videonote_received_desc", loader = stringsLoader)

    public actual val message_edited: StringResource = StringResource(key = "message_edited", loader
        = stringsLoader)

    public actual val message_edited_desc: StringResource = StringResource(key =
        "message_edited_desc", loader = stringsLoader)

    public actual val chat_join_requested: StringResource = StringResource(key =
        "chat_join_requested", loader = stringsLoader)

    public actual val chat_join_requested_desc: StringResource = StringResource(key =
        "chat_join_requested_desc", loader = stringsLoader)

    public actual val channel_posted: StringResource = StringResource(key = "channel_posted", loader
        = stringsLoader)

    public actual val channel_posted_desc: StringResource = StringResource(key =
        "channel_posted_desc", loader = stringsLoader)

    public actual val channel_post_edited: StringResource = StringResource(key =
        "channel_post_edited", loader = stringsLoader)

    public actual val channel_post_edited_desc: StringResource = StringResource(key =
        "channel_post_edited_desc", loader = stringsLoader)

    public actual val new_chat_members: StringResource = StringResource(key = "new_chat_members",
        loader = stringsLoader)

    public actual val new_chat_members_desc: StringResource = StringResource(key =
        "new_chat_members_desc", loader = stringsLoader)

    override fun values(): List<StringResource> = listOf(command, stopped, running, tg_token_desc,
        scheduled, scheduled_desc, message_received, message_received_desc, command_received,
        command_received_desc, document_received, document_received_desc, sticker_received,
        sticker_received_desc, image_received, image_received_desc, video_received,
        video_received_desc, voice_received, voice_received_desc, videonote_received,
        videonote_received_desc, message_edited, message_edited_desc, chat_join_requested,
        chat_join_requested_desc, channel_posted, channel_posted_desc, channel_post_edited,
        channel_post_edited_desc, new_chat_members, new_chat_members_desc)
  }

  public actual object plurals : ResourceContainer<PluralsResource> {
    override fun values(): List<PluralsResource> = listOf()
  }

  public actual object images : ResourceContainer<ImageResource> {
    override fun values(): List<ImageResource> = listOf()
  }

  public actual object fonts : ResourceContainer<FontResource> {
    override fun values(): List<FontResource> = listOf()
  }

  public actual object files : ResourceContainer<FileResource> {
    override fun values(): List<FileResource> = listOf()
  }

  public actual object colors : ResourceContainer<ColorResource> {
    override fun values(): List<ColorResource> = listOf()
  }

  public actual object assets : ResourceContainer<AssetResource> {
    override fun values(): List<AssetResource> = listOf()
  }
}
