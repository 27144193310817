package io.github.alexzhirkevich.onetap

object OneTap {

    private val listeners = mutableListOf<(String) -> Boolean>()

    fun handleUri(url: String) : Boolean {
        return listeners.any { it(url) }
    }

    internal fun addOnNewUriListener(listener: (String) -> Boolean): (String) -> Boolean {
        return listener.also(listeners::add)
    }

    internal fun removeOnNewUriListener(listener: (String) -> Boolean) {
        listeners.remove(listener)
    }
}