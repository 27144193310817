package org.botdesigner.ui.common

import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.ime
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp

@Composable
internal actual fun WindowInsets.Companion.isImeVisible() : Boolean {
    val density = LocalDensity.current

    val offset = remember(density) {
        density.run {
            30.dp.toPx()
        }
    }
    return WindowInsets.ime.getBottom(density) > offset
}