package org.botdesigner.blueprint.integrations.functions.custom

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.OutputConnector
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.BpTrigger
import org.botdesigner.blueprint.components.outputConnectors

@kotlinx.serialization.Serializable
@SerialName("BpCustomProcedureTrigger")
class BpCustomProcedureTrigger(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = emptyList(),
    override val connectors: List<Connector> = listOf(OutputConnector(id)),
    override val name: String,
) : BpTrigger<List<Any?>>() {

    override val factory: (Point, Id, List<Pin<Any?>>, List<Connector>) -> BpProcedure<List<Any?>>
        get() = { p, i, pi, co ->
            BpCustomProcedureTrigger(
                coordinate = p,
                id = i,
                pins = pi,
                connectors = co,
                name = name
            )
        }

    override suspend fun execute(
        input: List<*>,
        context: List<Any?>,
        pool: BlueprintNodesPool
    ): Pair<Connector?, List<*>> {
        return outputConnectors[0] to input
    }

}