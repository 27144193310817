package org.botdesigner.shared.domain.content.changepassword

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import org.botdesigner.api.ChangePasswordResult
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.SnackbarIdiom
import org.botdesigner.shared.domain.SnackbarMessage
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.dispatchers.Dispatchers
import kotlin.coroutines.cancellation.CancellationException

internal class DefaultChangePasswordComponent(
    context: ComponentContext,
    errorHandler: ErrorHandler,
    private val onBack : () -> Unit,
    private val onMessage : (SnackbarMessage) -> Unit,
    private val dispatchers: Dispatchers,
    private val userRepository: UserRepository
) : CoroutineComponent(context, errorHandler), ChangePasswordComponent {

    override val state: MutableStateFlow<ChangePasswordViewState> =
        instanceKeeper.getOrCreateSimple(keyFor(::state)) {
            MutableStateFlow(
                stateKeeper.consume(
                    key = keyFor(::state),
                    strategy = ChangePasswordViewState.serializer()
                ) ?: ChangePasswordViewState()
            )
        }

    init {
        stateKeeper.register(
            key = keyFor(::state),
            strategy = ChangePasswordViewState.serializer(),
            supplier = state::value
        )
    }

    override fun onOldPasswordChanged(value: String) {
        state.update {
            it.copy(
                oldPassword = value,
                oldPasswordError = null
            )
        }
    }

    override fun onNewPasswordChanged(value: String) {
        state.update {
            it.copy(
                newPassword = value,
                newPasswordError = null
            )
        }
    }

    override fun onChangeClicked() {

        val st = state.value

        if (!st.isChangeEnabled)
            return

        dispatchers.launchIO(
            scope = componentScope,
            key = keyFor(::onChangeClicked)
        ) {
            state.update {
                it.copy(isChangingInProgress = true)
            }
            when (userRepository.changePassword(st.oldPassword, st.newPassword)) {
                ChangePasswordResult.Success -> onMessage(
                    SnackbarMessage(
                        idiom = SnackbarIdiom.Success,
                        message = SharedRes.strings
                            .change_password_success.desc()
                    )
                )

                ChangePasswordResult.ConstrainViolation -> {
                    state.update {
                        it.copy(
                            newPasswordError = SharedRes.strings
                                .error_password_constraints.desc()
                        )
                    }
                }

                ChangePasswordResult.InvalidOldPassword -> {
                    state.update {
                        it.copy(
                            oldPasswordError = SharedRes.strings
                                .error_password_invalid_old.desc()
                        )
                    }
                }

                ChangePasswordResult.UnknownError -> {
                    onMessage(SnackbarMessage.NetworkError)
                }
            }
        }.invokeOnCompletion {
            state.update {
                it.copy(isChangingInProgress = false)
            }

            if (it != null && it !is CancellationException) {
                onMessage(SnackbarMessage.NetworkError)
            }
        }
    }

    override fun onBack() {
        onBack.invoke()
    }
}