package org.botdesigner.shared.domain

import androidx.compose.ui.input.key.KeyEvent
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.EditorSettings
import org.botdesigner.shared.domain.auth.AuthComponent
import org.botdesigner.shared.domain.content.ContentComponent
import org.botdesigner.shared.domain.content.appearance.AppearanceState
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

enum class SnackbarIdiom {
    Error, Success, Info
}

enum class SnackbarDuration(val duration : Duration) {
    Short(3.seconds),
    Long(7.seconds)
}

class SnackbarAction(
    val name : StringDesc,
    val onClick : () -> Unit
)

class SnackbarMessage(
    val idiom : SnackbarIdiom,
    val duration: SnackbarDuration = SnackbarDuration.Short,
    val action: SnackbarAction? = null,
    val message : StringDesc,
) {
    companion object {
        val NetworkError =  SnackbarMessage(
            idiom = SnackbarIdiom.Error,
            
            message = SharedRes.strings.error_network_try_again.desc()
        )
    }
}

class RootKeyEvent(
    val event: KeyEvent,
    val isInputInProgress: Boolean
)

interface RootComponent {

    val stack : Value<ChildStack<*, Child>>

    val appearance : StateFlow<AppearanceState>

    val settings : StateFlow<EditorSettings>

    val snackbar : Flow<SnackbarMessage>

    fun onKeyEvent(event: RootKeyEvent) : Boolean

    fun handleDeepLink(value : String)

    sealed interface Child {
        class Auth(val component: AuthComponent) : Child
        class Content(val component: ContentComponent) : Child
    }

    sealed interface Deeplink {
        class Authentication(
            val code: String?,
            val idToken : String?
        ) : Deeplink
    }
}

fun RootComponent(context: ComponentContext) : RootComponent = DefaultRootComponent(context)
