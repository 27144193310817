package org.botdesigner.blueprint.components

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Transient
import org.botdesigner.blueprint.ui.pins.BasePinWidget

@kotlinx.serialization.Serializable
@SerialName("GenericPinFactory")
object GenericPinFactory : PinFactory<Any?> {
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = GenericPin(
            id = id,
            order = order,
            elId = elementId,
            isOut = isOut,
            name = name,
            required = required
        )


    override fun array(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = GenericArrayPinFactory.create(
        id = id,
        order = order,
        elementId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}

@kotlinx.serialization.Serializable
@SerialName("GenericPin")
data class GenericPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val isOut: Boolean,
    override val name: String = "",
    @Transient override val value: Any? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false
) : Pin<@Contextual Any?>() {

    override val color: Color
        get() = BlueprintColors.Util

    override fun fits(other: Pin<*>): Boolean {
        return true
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<Any?> {
        return if (parentId != null && parentPinId != null){
            pool.getElementById(parentId).pins
                .find { it.id == parentPinId }
                ?.factory(pool)
                ?: GenericPinFactory
        } else GenericPinFactory
    }

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected: Boolean,
        pool: BlueprintNodesPool
    ) {

        val parent = remember(pool, parentId, parentPinId) {
            parentId
                ?.let(pool::getElementById)
                ?.pins
                ?.firstOrNull { it.id == parentPinId }
        }

        if (parent != null && parent !is GenericPin) {
            val instance = remember(parent, pool) {
                parent.factory(pool).create(
                    id = id,
                    order = order,
                    elementId = elId,
                    name = name,
                    isOut = isOut
                ).withReference(parent)
            }

            instance.run {
                Draw(
                    modifier = modifier,
                    onInputChanged = onInputChanged,
                    onTap = onTap,
                    value = value,
                    isSelected = isSelected,
                    pool = pool
                )
            }
        }
        else {
            BasePinWidget(
                modifier = modifier,
                color = color,
                name = name,
                isOut = isOut,
                required = required,
                isSelected = isSelected,
                isActive = pool.isPinActive(this@GenericPin),
                onTap = { onTap(this@GenericPin) },
            ) {}
        }
    }

    override fun withReference(parent: Pin<*>?): Pin<Any?> {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun withValue(value: String): Pin<Any?> {
        return copy(value = null, parentId =  null, parentPinId = null)
    }
}

fun PinsDirectionalScope.generic(name: String = "", order : UInt? = null) {
    pin { o, i ->
        GenericPin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
        )
    }
}