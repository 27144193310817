@file:Suppress("NOTHING_TO_INLINE", "FunctionName")

package org.botdesigner.blueprint.stdlib.functions.long

import org.botdesigner.blueprint.components.BlueprintExpressions
import org.botdesigner.blueprint.generator.BlueprintExpression
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.store.BlueprintNodeCategory
import kotlin.random.Random

@BlueprintExpression(
    expression = BlueprintExpressions.Equals,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Equals",
    summary = "Check if 2 integer numbers are equal",
    serialName = "BpLongEquals"
)
inline fun LongEquals(
    @Pin(name ="") a : Long?,
    @Pin(name = "") b : Long?
) :  Boolean {
    return a == b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Sum,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Sum",
    summary = "Sum a bunch of integer numbers",
    serialName = "BpLongSum"
)
inline fun LongSum(  vararg values : Long) :  Long {
    return values.sum()
}

@BlueprintExpression(
    expression = BlueprintExpressions.Sub,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Subtract",
    summary = "Subtract second integer number from the first",
    serialName = "BpLongSub"
)
inline fun LongSub(
    @Pin(name ="") a : Long,
    @Pin(name = "") b : Long
) : Long {
    return a - b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Mul,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Multiply",
    summary = "Multiply first integer number by second",
    serialName = "BpLongMul"
)
inline fun LongMul(
    @Pin(name ="") a : Long,
    @Pin(name = "") b : Long
) :  Long {
    return a * b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Div,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Divide",
    summary = "Divide first integer number by second. Result is rounded downwards",
    serialName = "BpLongDiv"
)
inline fun LongDiv(
    @Pin(name ="") a : Long,
    @Pin(name = "") b : Long
) :  Long {
    return a / b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Mod,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Mod",
    summary = "Remainder of the division",
    serialName = "BpLongMod"
)
inline fun LongMod(
    @Pin(name ="") a : Long,
    @Pin(name = "") b : Long
) :  Long {
    return a % b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Greater,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Greater",
    summary = "Check if first 1st number is greater than 2nd. Flip pins to revert expression (make Less)",
    serialName = "BpLongGreater"
)
inline fun LongGreater(
    @Pin(name ="") a : Long,
    @Pin(name = "") b : Long
) :  Boolean {
    return a > b
}


@BlueprintExpression(
    expression = BlueprintExpressions.GreaterOrEquals,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Greater Or Equals",
    summary = "Check if first 1st number is greater than 2nd or equal to. Flip pins to revert expression (make Less Or Equals)",
    serialName = "BpLongGreaterOrEquals"
)
inline fun LongGreaterOrEquals(
    @Pin(name ="") a : Long,
    @Pin(name = "") b : Long
) :  Boolean {
    return a >= b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "To String",
    summary = "Get string representation of the integer number",
    serialName = "BpLongToString"
)
inline fun LongToString(@Pin(name = "") a : Long?) :  String {
    return a.toString()
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "To Float",
    summary = "Convert integer number to float number",
    serialName = "BpLongToFloat"
)
inline fun LongToFloat(@Pin(name = "") a : Long?) :  Double? {
    return a?.toDouble()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Random",
    summary = "Generate random integer number in given range. Random is NOT cryptographically strong. <b>Until</b> must be greater than <b>From</b>. <b>From</b> defaults to 0, <b>Until</b> defaults to ${Long.MAX_VALUE}",
    serialName = "BpLongRandom"
)
inline fun LongRandom(From : Long?, Until : Long?) :  Long {
    return Random.nextLong(From ?: 0, Until ?: Long.MAX_VALUE)
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Integer,
    displayName = "Make Range",
    summary = "Create range (sequence) of integer numbers starting from 'From' and ending by 'Until'-1",
    serialName = "BpLongRange"
)
inline fun LongRange(From : Long, Until : Long) :  Iterable<Long> {
    return From until Until
}