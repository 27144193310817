package org.botdesigner.blueprint.stdlib.context

import org.botdesigner.blueprint.BlueprintLogLevel
import org.botdesigner.blueprint.BlueprintLogcat

interface LogcatContext {
    fun log(
        level : BlueprintLogLevel,
        message : String
    )
}

fun BlueprintLogcat.asLogcatContext() = object : LogcatContext {
    override fun log(level: BlueprintLogLevel, message: String) {
        this@asLogcatContext.log(BlueprintLogLevel.Verbose, message)
    }
}
