package org.botdesigner.blueprint.components

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.DataObject
import androidx.compose.material.icons.filled.Flag
import androidx.compose.material.icons.filled.Functions
import androidx.compose.material.icons.filled.RestartAlt
import androidx.compose.ui.graphics.vector.ImageVector

object BlueprintIcons {
    val Trigger: ImageVector get() = Icons.Default.Flag
    val Function: ImageVector get() = Icons.Default.Functions
    val Cycle: ImageVector get() = Icons.Default.RestartAlt
    val Object: ImageVector get() = Icons.Default.DataObject
}