package org.botdesigner.blueprint

import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.Snapshot
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.util.fastForEach
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.connectorsOrNull


class BlueprintManagerImpl(
    initialScale : Float = .5f,
    initialTranslation : Offset = Offset.Zero,
//    initialBlueprint : Blueprint = Blprint(elements = emptyList()),
    override val isScaleEnabled : Boolean = true,
    override val isDragEnabled : Boolean = true,
    private val onChanged : (self : BlueprintManager) -> Unit = {}
) : BlueprintManager {

    private var _scale: Float by mutableStateOf(initialScale)
    override val scale: Float get() = _scale

    private var densityScale = 0f

    private var _translation: Offset by mutableStateOf(initialTranslation)
    override val translation: Offset get() = _translation

//    private var blueprint: Blueprint = initialBlueprint

    private val _components = mutableStateMapOf<Id, BlueprintNodeStateHolder<*>>()
    override val components get() = _components

    private val _connectorLines =
        mutableStateMapOf<Pair<Connector, Connector>, Pair<State<Point>, State<Point>>>()
    override val connectorLines
        get() = _connectorLines

    private val _pinLines =
        mutableStateMapOf<Pair<Pin<*>, Pin<*>>, Pair<State<Point>, State<Point>>>()

    override val pinLines: PinLinesMap
        get() = _pinLines

    private var _selectedConnector = mutableStateOf<Connector?>(null)
    override val selectedConnector: State<Connector?> get() = _selectedConnector

    private var _selectedPin = mutableStateOf<Pin<*>?>(null)
    override val selectedPin: State<Pin<*>?> get() = _selectedPin

    override val elements: List<BlueprintNode>
        get() = _components.map { it.value.save() }


    override fun onDrag(delta: Offset, scaledFieldSize: Float) {
        if (!isDragEnabled)
            return

        val offsetRange = -scaledFieldSize/2..scaledFieldSize/2

        _translation = (_translation + delta).let {
            Offset(
                x = it.x.coerceIn(offsetRange),
                y = it.y.coerceIn(offsetRange),
            )
        }
    }

    override fun onScale(delta: Float) {
        if (!isScaleEnabled)
            return
        val scaleRange = (0.033f * densityScale) .. densityScale
        _scale = (_scale * delta).coerceIn(scaleRange)
    }

    override fun onDensityScaleChanged(densityScale: Float) {
        if (this.densityScale != densityScale) {

            _scale = if (isScaleEnabled)
                densityScale * .75f
            else densityScale

            this.densityScale = densityScale
        }
    }

    override fun clearSelection() {
        _selectedPin.value = null
        _selectedConnector.value = null
    }

    override fun load(blueprint: Blueprint) {
        Snapshot.withMutableSnapshot {
            runCatching {

                _selectedConnector.value = null
                _selectedPin.value = null
                _components.clear()
                _pinLines.clear()
                _connectorLines.clear()

                blueprint.elements.fastForEach {
                    _components[it.id] = it.createHolder(this)
                }

                blueprint.elements.fastForEach {
                    createConnectorsAndPins(it)
                }
            }
        }
    }

    override fun addNode(component: BlueprintNode) : Boolean {
        return runCatching {
            Snapshot.withMutableSnapshot {

                if (_components[component.id] != null)
                    return@withMutableSnapshot false

                _components[component.id] = component
                    .createHolder(this)

                createConnectorsAndPins(component)
                onChanged(this)
                true
            }
        }.getOrDefault(false)
    }


    override fun deleteNode(id: Id) {
        runCatching {
            Snapshot.withMutableSnapshot {

                _components[id]?.let { holder ->
                    holder.connectors.fastForEach {
                        tryUnmergeConnectors(it, true)
                    }
                    holder.pins.fastForEach {
                        unmergePins(it, true)
                    }
                }
                _components.remove(id)
                onChanged(this)
            }
        }
    }

    override fun onPinRemoved(pin: Pin<*>) {
        Snapshot.withMutableSnapshot {
            unmergePins(pin, true)
            onChanged(this)
        }
    }

    override fun resetPin(pin: Pin<*>) {
        Snapshot.withMutableSnapshot {
            unmergePins(pin, true)
        }
    }

    override fun onConnectorRemoved(connector: Connector) {
        Snapshot.withMutableSnapshot {
            if (connector.refId != null && connector.refConId != null) {
                tryUnmergeConnectors(connector, true)

                onChanged(this)
            }
        }
    }

    override fun onConnectorAdded(connector: Connector) {
        onChanged(this)
    }

    override fun onPinClicked(pin: Pin<*>) {
        Snapshot.withMutableSnapshot {
            _components[pin.elId]?.focused()

            val cur = _selectedPin.value

            _selectedConnector.value = null

            when {

                pin.id == cur?.id -> {
                    _selectedPin.value = null
                }

                !pin.isOut && pin.parentId != null  -> {

                    unmergePins(pin, true)

                    _selectedPin.value = pin
                    onChanged(this)
                }

                cur != null
                        && cur.elId != pin.elId
                        && cur.isOut != pin.isOut
                        && (if (cur.isOut) pin.fits(cur) else cur.fits(pin)) -> {

                    if (cur.isOut) {
                        tryMergePins(cur, pin, true)
                    } else {
                        tryMergePins(pin, cur, true)
                    }

                    _selectedPin.value = null
                    onChanged(this)
                }

                else -> {
                    _selectedPin.value = pin
                }
            }
        }
    }

    override fun onPinAdded(pin: Pin<*>) {
        onChanged(this)
    }

    override fun onConnectorClicked(c: Connector) {
        Snapshot.withMutableSnapshot {

            val cur = _selectedConnector.value
            _components[c.elId]?.focused()

            _selectedPin.value = null

            when {

                // клик на выбранный коннектор
                c.id == cur?.id -> {
                    _selectedConnector.value = null
                }

                // клик на соединенный коннектор
                c.refId != null -> {

                    tryUnmergeConnectors(c, true)

                    if (cur != null && cur.isOut != c.isOut) {
                        tryMergeConnectors(cur, c, true)
                        _selectedConnector.value = null
                    } else
                        _selectedConnector.value = c
                    onChanged(this)

                }

                cur != null &&
                        cur.elId != c.elId &&
                        cur.isOut != c.isOut -> {

                    tryMergeConnectors(cur, c, true)

                    _selectedConnector.value = null
                    onChanged(this)

                }

                else -> {
                    _selectedConnector.value = c
                }
            }
        }
    }

    override fun onComponentDragEnded(component: BlueprintNodeStateHolder<*>) {
        onChanged(this)
    }


    override fun onComponentTap(component: BlueprintNodeStateHolder<*>) {
        component.focused()
    }

    override fun save(): Blueprint {
        return Snapshot.withoutReadObservation {
            Blueprint(_components.values.map(BlueprintNodeStateHolder<*>::save))
        }
    }

    private fun createConnectorsAndPins(component: BlueprintNode) {
        component.pins.fastForEach {
            tryMergePins(null, it, false)
        }

        component.connectorsOrNull()?.let { connectors ->
            connectors.fastForEach {
                tryMergeConnectors(null, it, false)
            }
        }
    }

    private fun releaseConnector(connector: Connector) {
        _components[connector.elId]?.updateConnector(
            connector.copy(refId = null)
        ) {
            tryUnmergeConnectors(it, true)
        }
    }

    override fun getElementById(id: Id): BlueprintNode {
        return Snapshot.withoutReadObservation {
            _components[id]!!.save()
        }
    }


    private fun unmergePins(pin: Pin<*>, updateComponents: Boolean) {
        _pinLines.keys.filter {
            it.first.id == pin.id || it.second.id == pin.id
        }.fastForEach { key ->
            _pinLines.remove(key)

            val parent = key.firstIn()

            if (updateComponents) {
                _components[parent.elId]?.updatePin(
                    parent.withReference(null)
                )
            }
        }
    }


    private fun tryMergePins(parent: Pin<*>? = null, child: Pin<*>, updateChild: Boolean) {

        val pos1 = _components[child.elId]
            ?.getPinPosition(child.id) ?: return

        if (parent == null && (child.parentId == null || child.parentPinId == null)) {
            return
        }

        val nParent = parent
            ?: _components[child.parentId]?.pinState(child.parentPinId!!)?.value
            ?: return

        val pos2 = _components[nParent.elId]
            ?.getPinPosition(nParent.id) ?: return

        _pinLines[nParent to child] = pos1 to pos2

        if (updateChild) {
            _components[child.elId]?.updatePin(
                child.withReference(nParent)
            )
        }
    }

    private fun tryUnmergeConnectors(merged: Connector, update: Boolean) {
        val key = _connectorLines.keys.find {
            it.first.id == merged.id || it.second.id == merged.id
        }

        if (key != null) {
            _connectorLines.remove(key)

            if (update) {
                releaseConnector(key.first)
                releaseConnector(key.second)
            }
        }  else {
            if (update) {
                releaseConnector(merged)
            }
        }
    }

    private fun tryMergeConnectors(first: Connector? = null, second: Connector, update: Boolean) {

        if (first == null && (second.refId == null || second.refConId == null)) {
            return
        }

        val firstConnector = first ?: _components[second.refId]
            ?.connectorState(second.refConId!!)?.value ?: return

        if (firstConnector.isOut == second.isOut)
            return

        if (connectorLines.keys.any {
                it.first.id == firstConnector.id || it.first.id == second.id ||
                        it.second.id == second.id || it.second.id == second.id
            }) return

        val el1 = _components[firstConnector.elId] ?: return
        val el2 = _components[second.elId] ?: return

        val pos1 = el1.getConnectorPosition(firstConnector.id) ?: return
        val pos2 = el2.getConnectorPosition(second.id) ?: return

        if (update) {
            el1.updateConnector(
                firstConnector.copy(refId = second.elId, refConId = second.id)
            ) {
                tryUnmergeConnectors(it, true)
            }
            el2.updateConnector(
                second.copy(refId = firstConnector.elId, refConId = second.id)
            ) {
                tryUnmergeConnectors(it, true)
            }
        }


        _connectorLines[firstConnector to second] = pos1 to pos2
    }
}

private fun Pair<Pin<*>,Pin<*>>.firstOut() : Pin<*> {
    return first.takeIf { it.isOut } ?: second
}

private fun Pair<Pin<*>,Pin<*>>.firstIn() : Pin<*> {
    return first.takeIf { !it.isOut } ?: second
}