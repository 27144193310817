package org.botdesigner.shared.data.repo.impl

import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpRequestRetry
import io.ktor.client.plugins.HttpTimeout
import io.ktor.client.plugins.auth.Auth
import io.ktor.client.plugins.auth.providers.BearerTokens
import io.ktor.client.plugins.auth.providers.bearer
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.request.header
import io.ktor.http.ContentType
import io.ktor.http.HttpHeaders
import io.ktor.http.HttpStatusCode
import io.ktor.http.isSuccess
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import org.botdesigner.shared.util.managers.NotificationManager
import org.botdesigner.shared.util.managers.RefreshTokenManager

private const val API_URL = "https://api.botdesigner.org"

private val NoRetryStauses = listOf(
    HttpStatusCode.Unauthorized,
    HttpStatusCode.BadRequest,
    HttpStatusCode.NotFound,
)

//expect fun engine() : HttpClientEngine

internal fun DefaultHttpClient(
    refreshTokenManager: RefreshTokenManager,
    notificationManager: NotificationManager,
    sse : Boolean = false
) = HttpClient() {

    expectSuccess = true

    install(ContentNegotiation) {
        json(Json {
            ignoreUnknownKeys = true
            isLenient = true
        })
    }

    install(HttpRequestRetry) {
        maxRetries = 2

        retryIf { httpRequest, httpResponse ->
            httpResponse.status.let {
                !it.isSuccess() && it !in NoRetryStauses
            }
        }
        retryOnExceptionIf { httpRequestBuilder, throwable -> true }


        exponentialDelay()
    }

    install(HttpTimeout) {
        connectTimeoutMillis = 10_000
        requestTimeoutMillis = 15_000
    }

    Logging {
        level = LogLevel.BODY
        logger = object : Logger {
            override fun log(message: String) {
                println(message)
            }
        }
    }

    defaultRequest {
        url(API_URL)
        header(HttpHeaders.Accept, ContentType.Application.Json)
        header(HttpHeaders.ContentType, ContentType.Application.Json)
    }

    Auth {

        bearer {
            sendWithoutRequest {
                it.url.buildString().startsWith(API_URL)
            }
            loadTokens {
                val access = refreshTokenManager.accessToken ?: return@loadTokens null
                val refresh = refreshTokenManager.refreshToken ?: return@loadTokens null

                BearerTokens(access, refresh)
            }

            refreshTokens {

                refreshTokenManager.refresh(
                    notificationToken = notificationManager.token(),
                    client = client
                )

                val access = refreshTokenManager.accessToken ?: return@refreshTokens null
                val refresh = refreshTokenManager.refreshToken ?: return@refreshTokens null

                BearerTokens(access, refresh)
            }
        }
    }
}