package org.botdesigner.blueprint.ui.components

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.botdesigner.blueprint.AbstractBlueprintNodeStateHolder
import org.botdesigner.blueprint.BlueprintManager


@Composable
fun AbstractBlueprintNodeStateHolder<*>.Expression(
    expression : String,
    manager: BlueprintManager,
    modifier : Modifier = Modifier,
    fieldSize : Float,
    shape : Shape,
    also : @Composable () -> Unit = {},
) {
    BlueprintComponent(
        manager = manager,
        fieldSize = fieldSize,
        shape = shape,
        modifier = modifier,
    ) {

        var rowInComponent by remember {
            mutableStateOf(Offset.Zero)
        }
        Row(
            modifier = Modifier.onGloballyPositioned {
                rowInComponent = it.positionInParent()
            },
            verticalAlignment = Alignment.CenterVertically,
        ) {
            var columnInRow by remember {
                mutableStateOf(Offset.Zero)
            }
            Column(
                modifier = Modifier
                    .animateContentSize()
                    .onGloballyPositioned {
                        columnInRow = it.positionInParent()
                    }
            ) {
                DrawPins(
                    isOut = false,
                    pool = manager,
                    onPinClicked = manager::onPinClicked,
                    modifier = Modifier,
                    parentInComponent = rowInComponent + columnInRow,
                    drawIf = {true}
                )
                also.invoke()
            }

            Spacer(modifier = Modifier.width(10.dp))
            Text(
                text = expression,
                color = Color.Gray,
                fontWeight = FontWeight.Bold,
                fontSize = 32.sp,
                maxLines = 1,
                textAlign = TextAlign.Center,
                lineHeight = 32.sp
            )
            Spacer(modifier = Modifier.width(10.dp))

            DrawPins(
                isOut = true,
                pool = manager,
                onPinClicked = manager::onPinClicked,
                modifier = Modifier,
                parentInComponent = rowInComponent,
                drawIf = { shouldDrawPin(it) }
            )
        }
    }
}