package org.botdesigner.blueprint.integrations.google

import io.ktor.client.request.forms.ChannelProvider
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.get
import io.ktor.client.request.parameter
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsChannel
import io.ktor.http.ContentType
import io.ktor.http.Headers
import io.ktor.http.HttpHeaders
import io.ktor.util.cio.toByteArray
import io.ktor.utils.io.ByteReadChannel
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import org.botdesigner.api.SharedConstants
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.generator.BlueprintProcedure
import org.botdesigner.blueprint.io.context.NetworkContext
import org.botdesigner.blueprint.store.BlueprintNodeCategory

private const val GoogleCategoryName = "Google"

@BlueprintProcedure(
    summary = "Get value from Google Sheet. Keys are stored in the 1st column and values in the 2nd. Sheet must be public for reading",
    category = BlueprintNodeCategory.NetworkName,
    subCategory = GoogleCategoryName,
)
internal suspend inline fun NetworkContext.GoogleSheetValue(
    `Sharing URL` : String,
    Key : String
) : String? {

    val sheetId = `Sharing URL`.substringAfter("/d/").substringBefore('/')

    val text = httpClient
        .get("https://sheets.googleapis.com/v4/spreadsheets/$sheetId/values/R1C1:R1000C2?key=${SharedConstants.GoogleApiKey}")
        .bodyAsChannel()
        .toByteArray(limit = SharedConstants.IOReadingLimitBytes)
        .decodeToString()

    val resp = Json
        .parseToJsonElement(text)
        .jsonObject["values"].let { requireNotNull(it) { "Sheet Url is incorrect or document is not shared for reading" } }
        .jsonArray
        .find {
            kotlin.runCatching { it.jsonArray[0].jsonPrimitive.content == Key }
                .getOrDefault(false)
        }?.jsonArray?.get(1)?.jsonPrimitive?.content


    return resp
}

//enum class MediaContentType {
//    ""
//}



@BlueprintProcedure(
    summary = "Upload file to the public Google Drive folder",
    category = BlueprintNodeCategory.NetworkName,
    subCategory = GoogleCategoryName,
)
internal suspend inline fun NetworkContext.GoogleDrivePublicUpload(
    `Folder URL` : String,
    `File Name` : String?,
    File: ByteReadChannel
) {


    require("/folders/" in `Folder URL`) {
        "Invalid Folder URL"
    }

    val folderId = `Folder URL`
        .substringAfter("/folders/")
        .substringBefore("&")
        .substringBefore("/")

    val fileName = `File Name` ?: "File_${Clock.System.now().toEpochMilliseconds()}"

    val boundary = Id.randomId(24).id
    httpClient.post("https://www.googleapis.com/upload/drive/v3/files") {
        parameter("uploadType", "multipart")
        parameter("key", SharedConstants.GoogleApiKey)
        setBody(
            MultiPartFormDataContent(
                formData {
                    append(
                        key = "metadata",
                        value = """{"parents":["$folderId"],"name":"$fileName"}""",
                        headers = Headers.build {
                            append(
                                HttpHeaders.ContentType,
                                "application/json"
                            )
                        }
                    )

                    append(
                        key = "media",
                        value = ChannelProvider { File },
                        headers = Headers.build {
                            append(HttpHeaders.ContentType, "image/jpeg")
                        }
                    )
                },
                boundary = boundary,
                contentType = ContentType.MultiPart.Related
                    .withParameter("boundary", boundary)
            )
        )

    }
}