package org.botdesigner.botblueprints.telegram.pins

import androidx.compose.ui.graphics.Color
import org.botdesigner.blueprint.components.*
import org.botdesigner.botblueprints.Keyboard
import org.botdesigner.telegram.KeyboardOption
import kotlinx.serialization.SerialName


object KeyboardPinFactory : PinFactory<KeyboardOption> {

    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): Pin<KeyboardOption> {
        return KeyboardPin(
            id = id,
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
            required = required
        )
    }
}

@kotlinx.serialization.Serializable
@SerialName("TgKeyboardPin")
data class KeyboardPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: KeyboardOption? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : Pin<KeyboardOption>() {

    override val color: Color get() = BlueprintColors.Keyboard

    override fun factory(pool: BlueprintNodesPool): PinFactory<KeyboardOption> {
        return KeyboardPinFactory
    }

    override fun withReference(parent: Pin<*>?): Pin<KeyboardOption> {
        return copy(parentId = parent?.elId, parentPinId = parent?.id, value = null)
    }

    override fun withValue(value: String): Pin<KeyboardOption> {
        return copy(value = null, parentId = null, parentPinId = null)
    }
}

fun PinsDirectionalScope.keyboard(name : String) {
    pin { order, isOut ->
        KeyboardPin(
            id = Id.randomId(),
            order = order,
            elId = elementId,
            name = name,
            isOut = isOut,
        )
    }
}