package org.botdesigner.blueprint.io.pins


import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import io.ktor.utils.io.ByteReadChannel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.*
import org.botdesigner.blueprint.ui.pins.BasePinWidget



@Serializable
@SerialName("ChannelPinFactory")
object ByteStreamPinFactory: PinFactory<ByteReadChannel> {

    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): Pin<ByteReadChannel> = ByteStreamPin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}
@Serializable
@SerialName("ChannelPin")
data class ByteStreamPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: ByteReadChannel? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
    val maxLines : Int = 1
) : Pin<ByteReadChannel>() {

    override val color: Color get() = Color(0xFFFF007F)

    override fun withValue(value: String): ByteStreamPin {
        return copy(parentPinId = null, parentId = null, value = null)
    }

    override fun withReference(parent: Pin<*>?): ByteStreamPin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<ByteReadChannel> {
        return ByteStreamPinFactory
    }

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected : Boolean,
        pool: BlueprintNodesPool
    ) {
        BasePinWidget(
            color = color,
            name = name,
            isActive = pool.isPinActive(this@ByteStreamPin),
            isOut = isOut,
            required = required,
            onTap = { onTap(this@ByteStreamPin) },
            modifier = modifier,
            isSelected = isSelected,
            value = {}
        )
    }
}

fun PinsDirectionalScope.channel(
    name: String = "",
    order : UInt? = null
) {
    pin { o, i ->
        ByteStreamPin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
        )
    }
}