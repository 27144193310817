package io.github.alexzhirkevich.onetap

actual fun OneTap.AppleAS(
    clientId: String,
    redirectUri: String,
    scopes : List<String>,
    state : String?,
) : SignInClient<AppleSignInResult> = Apple(
    clientId = clientId,
    redirectUri = redirectUri,
    scopes = scopes,
    state = state
).map {
    AppleSignInResult(
        code = it.code,
        idToken = it.idToken,
        state = it.state,
        account = null
    )
}