package org.botdesigner.blueprint.stdlib.pins

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.width
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ExposedDropdownMenuBox
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.*
import org.botdesigner.blueprint.ui.components.WithDeviceDensity
import org.botdesigner.blueprint.ui.pins.BasePinWidget

@Serializable
@SerialName("EnumPinFactory")
object EnumPinFactory : PinFactory<String> {
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): EnumPin {
        return EnumPin(
            id = id,
            order = order,
            elId = elementId,
            isOut = isOut,
            name = name,
            required = required
        )
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Serializable
@SerialName("EnumPin")
data class EnumPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val isOut: Boolean,
    override val name: String = "",
    val availableValues : List<String> = emptyList(),
    override val value: String? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : Pin<@Contextual String>() {

    override val color: Color
        get() = BlueprintColors.String

    override fun withReference(parent: Pin<*>?): EnumPin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun withValue(value: String): EnumPin {
        return copy(value = value, parentId = null, parentPinId = null)
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<String> {
        return EnumPinFactory
    }

    override fun fits(other: Pin<*>): Boolean {
        return super.fits(other) && other is EnumPin && other.availableValues == availableValues
    }

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected: Boolean,
        pool: BlueprintNodesPool
    ) {

        var expanded by rememberSaveable {
            mutableStateOf(false)
        }

        BasePinWidget(
            color = color,
            name = name,
            isOut = isOut,
            required = required,
            isSelected = isSelected,
            isActive = pool.isPinActive(this@EnumPin),
            onTap = { onTap(this@EnumPin) },
            modifier = modifier
        ) {
            ExposedDropdownMenuBox(
                expanded = expanded,
                onExpandedChange = {
                    expanded = it
                }
            ) {
                Row(Modifier.menuAnchor()) {
                    Spacer(Modifier.width(5.dp))
                    TextButton(
                        border = BorderStroke(1.dp, Color.White),
                        colors = ButtonDefaults.textButtonColors(
                            contentColor = color,
                            disabledContentColor = color,
                        ),
                        enabled = false,
                        onClick = { expanded = !expanded },
                    ) {
                        Text(text = value?.takeIf { it.isNotBlank() } ?: "Select")
                    }
                }

                ExposedDropdownMenu(
                    expanded = expanded,
                    onDismissRequest = {
                        expanded = false
                    }
                ) {
                    WithDeviceDensity {
                        availableValues.forEach {
                            DropdownMenuItem(
                                onClick = {
                                    onInputChanged(it)
                                    expanded = false
                                },
                                text = {
                                    Text(it)
                                }
                            )
                        }
                    }
                }
            }
        }
    }
}

fun PinsDirectionalScope.enum(values : List<String>, name : String  = "") {
    pin { o, i ->
        EnumPin(
            id = Id.randomId(),
            order =  o,
            elId = elementId,
            name = name,
            isOut = i,
            availableValues = values
        )
    }
}