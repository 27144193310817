package org.botdesigner.shared.domain.auth

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import dev.icerock.moko.resources.desc.Resource
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.update
import org.botdesigner.api.User
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.AuthRepository
import org.botdesigner.shared.data.repo.AuthResult
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.SnackbarIdiom
import org.botdesigner.shared.domain.SnackbarMessage
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.FormattedFix
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.rethrowCancellation
import org.botdesigner.shared.util.valueOrNull

private val AuthJobKey = DefaultAuthComponent::class.simpleName + "_auth"

private val emailRegex = Regex(
    "[a-zA-Z0-9+._%\\-]{1,256}" +
            "@" +
            "[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}" +
            "(" +
            "\\." +
            "[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}" +
            ")+"
)


internal class DefaultAuthComponent(
    context : ComponentContext,
    private val onLogout : () -> Unit,
    private val authRepository: AuthRepository,
    errorHandler: ErrorHandler,
    private val dispatchers: Dispatchers,
    private val onMessage : (SnackbarMessage) -> Unit
) : CoroutineComponent(context, errorHandler),
    AuthComponent {

    override val verificationCodeSize: Int = 6

    override val state: MutableStateFlow<AuthViewState> = instanceKeeper
        .getOrCreateSimple(keyFor(::state)) {

            val logged = authRepository.currentUser != null

            val state = stateKeeper.consume(
                key = AuthInput::class.simpleName!!,
                strategy = AuthInput.serializer()
            )

            MutableStateFlow(
                AuthViewState(
                    isLoggedIn = logged,
                    page = if (logged)
                        AuthPage.VerifyEmail
                    else AuthPage.Login,
                    email = state?.email.orEmpty(),
                    password = state?.password.orEmpty(),
                    name = state?.name.orEmpty(),
                    code = state?.code.orEmpty()
                )
            )
        }

    init {

        stateKeeper.register(
            key = AuthInput::class.simpleName!!,
            strategy = AuthInput.serializer()
        ) {
            state.value.toAuthInput()
        }


        dispatchers.launchIO(componentScope) {
            authRepository.currentUserFlow.collectLatest { user ->
                state.update {
                    it.copy(
                        user = if (user != null)
                            UIState.Success(user)
                        else UIState.Error(),
                        isLoggedIn = user != null && user.isEmailVerified,
                        page = if (user?.isEmailVerified == false)
                            AuthPage.VerifyEmail else AuthPage.Login,
                        code = "",
                        codeError = null,
                    )
                }
            }
        }
    }


    override fun onForgotPasswordClicked() {
        state.update {
            it.copy(
                page = AuthPage.RestorePasswordEmail,
                password = "",
                code = "",
            )
        }
    }

    override fun onNameChanged(value: String) {
        state.update { it.copy(name = value.take(50)) }
    }

    override fun onEmailChanged(value: String) {
        state.update { it.copy(email = value.take(100), emailError = null) }
    }

    override fun onPasswordChanged(value: String) {
        state.update { it.copy(password = value.take(256), passwordError = null) }
    }

    override fun onCodeChanged(value: String) {
        state.update {

            val text = value.filter(Char::isDigit)
                .take(verificationCodeSize)

            if (text.length == verificationCodeSize && it.page == AuthPage.VerifyEmail){
                onSignClicked()
            }

            it.copy(
                code = value,
                codeError = null
            )
        }
    }


    override fun onSignClicked() {

        dispatchers.launchIO(
            scope = componentScope,
            key = AuthJobKey
        ) {

            val st = state.value

            if (!validateInputs(st)) {
                return@launchIO
            }

            state.update { it.copy(controlsEnabled = false) }

            try {

                val authResult = when (st.page) {
                    AuthPage.Register -> {
                        authRepository.register(
                            st.name.trim(),
                            st.email.trim(),
                            st.password.trim()
                        )
                    }

                    AuthPage.Login -> authRepository.signIn(
                        email = st.email.trim(),
                        password = st.password.trim()
                    )
                    AuthPage.VerifyEmail -> {
                        if (!authRepository.verifyEmail(st.code)) {
                            state.update {
                                it.copy(
                                    codeError = SharedRes.strings
                                        .error_invalid_verification_code.desc()
                                )
                            }
                        }
                        return@launchIO
                    }

                    AuthPage.RestorePasswordEmail -> {
                        val success = authRepository.requestRestorePassword(st.email)
                        state.update {
                            it.copy(
                                emailError = if (success) {
                                    null
                                }
                                else {
                                    SharedRes.strings.error_email_notfound.desc()
                                },
                                page = if (success) {
                                    AuthPage.RestorePasswordConfirm
                                }
                                else {
                                    it.page
                                }
                            )
                        }

                        return@launchIO
                    }

                    AuthPage.RestorePasswordConfirm -> TODO()
                }


                val user = processAuthResult(authResult)

                state.update {
                    it.copy(
                        user = user,
                        page = if (user.valueOrNull()?.isEmailVerified == false)
                            AuthPage.VerifyEmail
                        else it.page
                    )
                }
            } catch (t : Throwable) {

                t.rethrowCancellation()

                onMessage(SnackbarMessage.NetworkError)

                state.update {
                    it.copy(
                        user = UIState.Error(
                            message = SharedRes.strings.error_something_went_wrong.desc()
                        )
                    )
                }
                throw t
            } finally {
                state.update {
                    it.copy(
                        controlsEnabled = true,
                    )
                }
            }
        }
    }

    private fun processAuthResult(authResult: AuthResult) : UIState<User> {
        authResult.errorMsg?.let {
            onMessage(
                SnackbarMessage(
                    idiom = SnackbarIdiom.Error,
                    message = it
                )
            )
        }

        return when(authResult) {
            is AuthResult.Success -> UIState.Success(authResult.user)
            AuthResult.Cancelled -> state.value.user
            AuthResult.Error -> UIState.DefaultError
            else -> UIState.Error(
                message = authResult.errorMsg
            )
        }
    }

    override fun onChangePageClicked() {
        if (state.value.page == AuthPage.VerifyEmail) {
            onLogout()
        } else {
            state.update {
                it.copy(
                    page = if (it.page == AuthPage.Login)
                        AuthPage.Register else AuthPage.Login,
                    nameError = null,
                    emailError = null,
                    passwordError = null,
                    code = ""
                )
            }
        }
    }

    private fun validateInputs(authState: AuthViewState): Boolean {

        val nameError = if (authState.page == AuthPage.Register && authState.name.isBlank()) {
            SharedRes.strings.error_name_empty.desc()
        } else null

        val emailError =
            if (authState.page == AuthPage.Register && emailRegex.matches(authState.email.trim())
                    .not()
            ) {
                SharedRes.strings.error_email_constraints.desc()
            } else null

        val pwdError = if (authState.page == AuthPage.Register && authState.password.length < 6) {
            StringDesc.FormattedFix(SharedRes.strings.error_password_constraints, 6)
        } else null

        state.update {
            it.copy(
                nameError = nameError,
                emailError = emailError,
                passwordError = pwdError
            )
        }

        return nameError == null && emailError == null && pwdError == null
    }

    override fun onSignInWithAppleClicked() {
        oauthSignIn {
            authRepository.signInWithApple()
        }
    }

    override fun onSignInWithGoogleClicked() {
        oauthSignIn {
            authRepository.signInWithGoogle()
        }
    }

    override fun cancelAuth() {
        dispatchers.cancel(AuthJobKey)
    }

    private fun oauthSignIn(sign : suspend () -> AuthResult){
        dispatchers.launchIO(
            scope = componentScope,
            key = AuthJobKey
        ) {
            state.update {
                it.copy(
                    controlsEnabled = false,
                    isOauthInProgress = true
                )
            }
            val user = processAuthResult(sign())
            state.update {
                it.copy(
                    user = user
                )
            }
        }.invokeOnCompletion {
            state.update {
                it.copy(
                    controlsEnabled = true,
                    isOauthInProgress = false
                )
            }
        }
    }

    override fun onResendEmailClicked() {
        dispatchers.launchIO(componentScope) {
            authRepository.sendEmailVerification()
        }
    }

    override fun onLogout() {
        onLogout.invoke()
    }
}