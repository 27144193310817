@file:Suppress("NOTHING_TO_INLINE","FUNCTIONNAME","LOCALVARIABLENAME")

package org.botdesigner.botblueprints.telegram.functions

import io.ktor.utils.io.ByteReadChannel
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.BlueprintProcedure
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.generator.Tuple
import org.botdesigner.blueprint.generator.Tuple17
import org.botdesigner.botblueprints.BpCategories
import org.botdesigner.botblueprints.BpSubcategories
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext
import org.botdesigner.telegram.KeyboardOption
import org.botdesigner.telegram.Message
import org.botdesigner.telegram.ParseMode
import org.botdesigner.telegram.User
import org.botdesigner.telegram.deleteMessage
import org.botdesigner.telegram.editMessageText
import org.botdesigner.telegram.forwardMessage
import org.botdesigner.telegram.pinChatMessage
import org.botdesigner.telegram.sendAudio
import org.botdesigner.telegram.sendContact
import org.botdesigner.telegram.sendDocument
import org.botdesigner.telegram.sendMessage
import org.botdesigner.telegram.sendPhoto
import org.botdesigner.telegram.sendPhotoFile
import org.botdesigner.telegram.sendPoll
import org.botdesigner.telegram.sendSticker
import org.botdesigner.telegram.sendVideo
import org.botdesigner.telegram.sendVideoNote
import org.botdesigner.telegram.sendVoice
import org.botdesigner.telegram.unpinAllChatMessages
import org.botdesigner.telegram.unpinChatMessage


private const val DSCR_DECOMPOSE_MESSAGE= "Get message properties: Id, Text, Sender, ..."
private const val DSCR_SEND_PHOTO       = "Send photo using it's URL from the Internet or file_id of existing on Telegram servers photo"
private const val DSCR_SEND_PHOTO_FILE  = "Send photo using its data. Max photo size is 10 MB. NOTE: if the same file is sent frequentrly is it always better to use <b>Send Photo</b> with File Id instead"
private const val DSCR_SEND_STICKER     = "Send sticker using it's URL from the Internet (in .webp format) or file_id of existing on Telegram servers sticker"
private const val DSCR_SEND_VIDEO       = "Send video using it's URL from the Internet or file_id of existing on Telegram servers video"
private const val DSCR_SEND_VIDEO_VOICE = "Send video voice using file_id of existing on Telegram servers video"
private const val DSCR_SEND_VOICE       = "Send audio voice using it's URL from the Internet or file_id of existing on Telegram servers video"
private const val DSCR_SEND_AUDIO       = "Send audio file using it's URL from the Internet or file_id of existing on Telegram servers audio"
private const val DSCR_SEND_DOCUMENT    = "Send document using it's URL from the Internet or file_id of existing on Telegram servers document"
private const val DSCR_SEND_CONTACT     = "Send phone contact. It must have a <b>First Name</b>"
private const val DSCR_SEND_POLL        = "Send poll to the chat.\nUp to 10 <b>Options</b>. Up to 100 characters for each option.\nPoll can optionally <b>time out</b> after <b>5-600</b> seconds or it can be closed after <b>End Date</b>. This 2 attributes can not be used together."
private const val DSCR_FORWARD_MESSAGE  = "Forward message to another chat"
private const val DSCR_PIN_MESSAGE      = "Pin chat message to the top"


@BlueprintFunction(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Decompose Message",
    summary = DSCR_DECOMPOSE_MESSAGE
)
internal inline fun TgDecomposeMessage(
    Message : Message
) : Tuple17<
    @Pin ("Id") Long,
    @Pin ("User") User?,
    @Pin ("Chat Id") Long,
    @Pin ("Text") String?,
    @Pin ("Photos Id") String?,
    @Pin ("Video Id") String?,
    @Pin ("Sticker Id") String?,
    @Pin ("Voice Id") String?,
    @Pin ("Video Voice Id") String?,
    @Pin ("Document Id") String?,
    @Pin ("Dice Value") Long?,
    @Pin ("New Chat Members") Iterable<User>?,
    @Pin ("Left Chat Member") User?,
    @Pin ("New Chat Photo") String?,
    @Pin ("Chat Photo Deleted") Boolean?,
    @Pin ("New Chat Title") String?,
    @Pin ("Message Pinned") Message?,
> = Tuple(
    Message.message_id,
    Message.from,
    Message.chat.id,
    Message.text,
    Message.photo?.lastOrNull()?.file_id,
    Message.video?.file_id,
    Message.sticker?.file_id,
    Message.voice?.file_id,
    Message.video_note?.file_id,
    Message.document?.file_id,
    Message.dice?.value,
    Message.new_chat_members,
    Message.left_chat_member,
    Message.new_chat_photo?.lastOrNull()?.file_id,
    Message.delete_chat_photo == true,
    Message.new_chat_title,
    Message.pinned_message
)

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Message",
    summary = "Send <b>Text</b> message"
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendMessage(
    Text : String,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    `Disable Web Preview` : Boolean?,
    `Parse Mode` : ParseMode?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendMessage(
    text = Text,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    disable_web_page_preview = `Disable Web Preview`,
    parse_mode = `Parse Mode`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Photo",
    summary = DSCR_SEND_PHOTO
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendPhoto(
    Photo : String,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendPhoto(
    photo = Photo,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Photo File",
    summary = DSCR_SEND_PHOTO_FILE
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendPhotoFile(
    Photo : ByteReadChannel,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?
) : Message = client.sendPhotoFile(
    photo = Photo,
    name = "Image_${Clock.System.now().toEpochMilliseconds()}",
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Sticker",
    summary = DSCR_SEND_STICKER
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendSticker(
    Sticker : String,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendSticker(
    sticker = Sticker,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Video",
    summary = DSCR_SEND_VIDEO
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendVideo(
    Video : String,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendVideo(
    video = Video,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Video Voice",
    summary =  DSCR_SEND_VIDEO_VOICE
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendVideoVoice(
    `Video Voice` : String,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendVideoNote(
    video_note = `Video Voice`,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Voice",
    summary = DSCR_SEND_VOICE
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendVoice(
    Voice : String,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendVoice(
    voice = Voice,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Audio",
    summary = DSCR_SEND_AUDIO
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendAudio(
    Audio : String,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendAudio(
    audio = Audio,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Document",
    summary = DSCR_SEND_DOCUMENT
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendDocument(
    Document : String,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendDocument(
    document = Document,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Contact",
    summary = DSCR_SEND_CONTACT
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendContact(
    `Phone Number` : String,
    `First Name` : String,
    `Last Name` : String?,
    `Chat Id`: Long,
    `Reply To Message` : Long?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendContact(
    phone_number = `Phone Number`,
    first_name = `First Name`,
    last_name = `Last Name`,
    chat_id = `Chat Id`.toString(),
    reply_to_message_id = `Reply To Message`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Send Poll",
    summary = DSCR_SEND_POLL
)
internal suspend inline fun TelegramBotBlueprintContext.TgSendPoll(
    Question : String,
    Options : Iterable<String>,
    `Chat Id`: Long,
    `Is Anonymous`: Boolean?,
    `Is Quiz`: Boolean?,
    `Allow Multi Answers`: Boolean?,
    `Timeout Sec`: Long?,
    `End Date`: Instant?,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
    @Pin("Reply Markup (Keyboard)") keyboard : KeyboardOption?,
) : Message = client.sendPoll(
    question = Question,
    options = Options.toList(),
    chat_id = `Chat Id`.toString(),
    is_anonymous = `Is Anonymous`,
    type = if (`Is Quiz` == true) "quiz" else null,
    allows_multiple_answers = `Allow Multi Answers`,
    open_period = `Timeout Sec`,
    close_date = `End Date`?.epochSeconds,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`,
    reply_markup = keyboard
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Delete Message",
)
internal suspend inline fun TelegramBotBlueprintContext.TgDеleteMessage(
    `Chat Id` : Long,
    `Message Id` : Long,
) : Boolean = client.deleteMessage(
    chat_id = `Chat Id`.toString(),
    message_id = `Message Id`,
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Edit Message Text",
)
internal suspend inline fun TelegramBotBlueprintContext.TgEditMessageText(
    `New Text` : String,
    `Chat Id` : Long,
    `Message Id` : Long,
) : Message = client.editMessageText(
    text = `New Text`,
    chat_id = `Chat Id`.toString(),
    message_id = `Message Id`,
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Forward Message",
    summary = DSCR_FORWARD_MESSAGE
)
internal suspend inline fun TelegramBotBlueprintContext.TgForwardMessage(
    `From Chat Id` : Long,
    `To Chat Id` : Long,
    `Message Id` : Long,
    `Disable Notification` : Boolean?,
    `Protect Content` : Boolean?,
) : Message = client.forwardMessage(
    from_chat_id = `From Chat Id`.toString(),
    chat_id = `To Chat Id`.toString(),
    message_id = `Message Id`,
    disable_notification = `Disable Notification`,
    protect_content = `Protect Content`
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Pin Message",
    summary = DSCR_PIN_MESSAGE
)
internal suspend inline fun TelegramBotBlueprintContext.TgPinMessage(
    `Chat Id` : Long,
    `Message Id` : Long,
    `Disable Notification` : Boolean?,
) : Boolean = client.pinChatMessage(
    chat_id = `Chat Id`.toString(),
    message_id = `Message Id`,
    disable_notification = `Disable Notification`
).result


@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Unpin Message",
)
internal suspend inline fun TelegramBotBlueprintContext.TgUnpinMessage(
    `Chat Id` : Long,
    `Message Id` : Long,
) : Boolean = client.unpinChatMessage(
    chat_id = `Chat Id`.toString(),
    message_id = `Message Id`,
).result

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Message,
    displayName = "Unpin All Messages",
)
internal suspend inline fun TelegramBotBlueprintContext.TgUnpinAllMessages(
    `Chat Id` : Long,
) : Boolean = client.unpinAllChatMessages(
    chat_id = `Chat Id`.toString(),
).result

