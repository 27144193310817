package org.botdesigner.ui.screens.list

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.nestedscroll.nestedScroll
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import io.github.alexzhirkevich.cupertino.CupertinoSearchTextField
import io.github.alexzhirkevich.cupertino.CupertinoSearchTextFieldDefaults
import io.github.alexzhirkevich.cupertino.CupertinoSearchTextFieldState
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.LocalContainerColor
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveHorizontalDivider
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveIconButton
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveScaffold
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTopAppBar
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.rememberCupertinoSearchTextFieldState
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filter
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.core.Bot
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.shared.domain.asFlow
import org.botdesigner.shared.domain.content.list.BotsListComponent
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.valueOrNull
import org.botdesigner.ui.common.AddNew
import org.botdesigner.ui.common.dialogs.ConfirmationDialog
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable


@OptIn(ExperimentalAdaptiveApi::class,)
@Composable
internal fun BotListScreen(
    component: BotsListComponent,
    modifier: Modifier = Modifier.fillMaxSize()
) {
    val bots by component.bots.collectAsState()

    val lazyListState = rememberLazyListState()
    val searchState = rememberCupertinoSearchTextFieldState(
        scrollableState = lazyListState,
        collapse = {
            lazyListState.canScrollBackward
        }
    )

    LaunchedEffect(searchState) {
        snapshotFlow {
            searchState.isFocused
        }.distinctUntilChanged().collectLatest {
            component.onSearchFocusChanged(it)
        }
    }

    component.dialog.subscribeAsState().value.child?.let {
        when (val c = it.instance) {
            is BotsListComponent.Dialog.Delete -> ConfirmationDialog(c.component)
        }
    }

    AdaptiveScaffold(
        modifier = modifier,
        containerColor = LocalContainerColor.current,
        floatingActionButton = {
            if (CurrentPlatform == Platform.Android) {
                FloatingActionButton(
                    onClick = component::onCreateClicked
                ) {
                    Icon(
                        imageVector = Icons.Default.Add,
                        contentDescription = string(SharedRes.strings.add_new)
                    )
                }
            }
        },
        topBar = {
             BotListTopBar(
                 searchState = searchState,
                 component = component
             )
        },
    ) {
        when (val b = bots) {
            is UIState.Success<List<Bot>>, is UIState.Loading -> BotList(
                lazyListState = lazyListState,
                bots = b.valueOrNull(),
                selectedId = component.selectedBot.collectAsState().value?.id,
                discardDelete = remember(component) {
                    component.dialog.asFlow().filter { it.child == null }
                },
                modifier = Modifier
                    .fillMaxSize()
                    .nestedScroll(searchState.nestedScrollConnection),
                paddingValues = it,
                onClicked = component::onBotSelected,
                onDelete = component::onBotDelete,
                onEdit = component::onBotEdit
            ) {
            }

            is UIState.Error -> BotsError(
                modifier = Modifier
                    .fillMaxSize()
                    .imePadding()
                    .padding(top = it.calculateTopPadding()),
                title = b.title?.let {
                    string(it)
                },
                text = b.message?.let {
                    string(it)
                }.orEmpty()
            )

            is UIState.Empty -> BotsGreeting(
                modifier = Modifier
                    .fillMaxSize()
                    .imePadding()
                    .padding(top = it.calculateTopPadding()),
                text = b.message?.let {
                    string(it)
                }.orEmpty()
            )
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class, ExperimentalAdaptiveApi::class,
    ExperimentalCupertinoApi::class
)
@Composable
private fun BotListTopBar(
    searchState : CupertinoSearchTextFieldState,
    component : BotsListComponent
) {

    val isDesktop = LocalInterfaceIdiom.current == InterfaceIdiom.Desktop ||
            !CurrentPlatform.isMobile

    Column(
        modifier = Modifier
            .background(if (isDesktop) MaterialTheme.colorScheme.surface else Color.Transparent)
            .windowInsetsPadding(TopAppBarDefaults.windowInsets)
    ) {
        AnimatedVisibility(
            visible = !searchState.isFocused
        ) {
            AdaptiveTopAppBar(
                windowInsets = WindowInsets(0.dp),
                title = {
                    Text(
                        text = string(SharedRes.strings.bots),
                    )
                },
                actions = {
                    if (CurrentPlatform != Platform.Android) {
                        AdaptiveIconButton(
                            modifier = Modifier
                                .pointerHoverIcon(PointerIcon.PlatformClickable),
                            onClick = component::onCreateClicked
                        ) {
                            Icon(
                                imageVector = AdaptiveIcons.Outlined.AddNew,
                                contentDescription = null
                            )
                        }
                    }
                },
                adaptation = {
                    cupertino {
                        isTranslucent = !isDesktop
                        divider = {}
                    }
                }
            )
        }

        val interactionSource = remember { MutableInteractionSource() }

        val focus = LocalFocusManager.current
        CupertinoSearchTextField(
            modifier = Modifier
                .onPreviewKeyEvent {
                    if (it.key == Key.Escape && searchState.isFocused) {
                        focus.clearFocus()
                        component.onQueryChanged("")
                        true
                    } else {
                        false
                    }
                },
            state = searchState,
            interactionSource = interactionSource,
            paddingValues = PaddingValues(
                horizontal = Dimens.Spacing.Medium,
                vertical = Dimens.Spacing.Small
            ),
            value = component.botsSearchQuery.collectAsState().value,
            onValueChange = component::onQueryChanged,
            cancelButton = {
                CupertinoSearchTextFieldDefaults
                    .cancelButton(
                        onValueChange = component::onQueryChanged,
                        interactionSource = interactionSource
                    ) {
                        Text(string(SharedRes.strings.cancel))
                    }
            }
        )

        AnimatedVisibility(
            visible = !searchState.isFocused,
            modifier = Modifier.fillMaxWidth(),

        ) {
            BotFilterChip(
                component = component,
                modifier = Modifier
                    .padding(
                        horizontal = Dimens.Spacing.Medium,
                        vertical = Dimens.Spacing.Small
                    )

            )
        }
        AdaptiveHorizontalDivider()
    }
}


