package org.botdesigner.botblueprints.telegram.blueprints

import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.botblueprints.telegram.functions.BpTgChannelPosted
import org.botdesigner.botblueprints.telegram.functions.BpTgDecomposeMessage

internal fun ChannelPostedBlueprint(id : Id) : Blueprint {
    val messageReceived = BpTgChannelPosted()

    var decomposeMessage : BpFunction = BpTgDecomposeMessage(Point(700f,200f))


    decomposeMessage = decomposeMessage.factory(
        decomposeMessage.coordinate,
        decomposeMessage.id,
        listOf(decomposeMessage.pins.first().withReference(messageReceived.pins.first())) +
                decomposeMessage.pins.drop(1)
    )


    return Blueprint(
        elements = listOf(messageReceived, decomposeMessage)
    )
}