package org.botdesigner.shared.domain.content.guides

import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import org.botdesigner.resources.SharedRes

enum class BlueprintGuidePage(
    override val title : StringDesc,
    override val text : StringDesc,
) : GuidePage {

    Node(
        SharedRes.strings.nodes.desc(),
        SharedRes.strings.help_nodes.desc(),
    ),
    Connectors(
        SharedRes.strings.connect.desc(),
        SharedRes.strings.help_connect.desc(),
    ),
    Pins(
        SharedRes.strings.pins.desc(),
        SharedRes.strings.help_pins.desc(),
    ),
    Store(
        SharedRes.strings.store.desc(),
        SharedRes.strings.help_store.desc(),
    ),
    Finish(
        SharedRes.strings.lets_try.desc(),
        SharedRes.strings.help_lets_try.desc(),
    )
}