package org.botdesigner.shared.data.source.impl

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOne
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.shared.data.cache.SqBlueprint
import org.botdesigner.shared.data.cache.SqBlueprintQueries
import org.botdesigner.shared.data.source.CacheBlueprintDataSource
import org.botdesigner.shared.util.dispatchers.Dispatchers

class CacheBlueprintDataSourceImpl(
    private val queries : SqBlueprintQueries,
    private val dispatchers: Dispatchers
) : CacheBlueprintDataSource {

    override suspend fun getAll(botId: String): List<BlueprintData> {
        return dispatchers.runOnIO {
            queries.getAll(botId)
                .awaitAsList()
                .map { it.toBlueprint()}
        }
    }

    override suspend fun get(botId: String, id: String): BlueprintData {
        return dispatchers.runOnIO {
            queries.get(
                botId = botId,
                id = id,
                initialId = id
            ).awaitAsOne().toBlueprint()
        }
    }

    override suspend fun add(botId: String, blueprint: BlueprintData): BlueprintData {
        dispatchers.runOnIO {
            queries.transaction {
                val existing = queries.get(
                    botId = botId,
                    id = blueprint.id,
                    initialId = blueprint.initialId
                ).awaitAsOneOrNull()

                queries.create(
                    botId = botId,
                    id = blueprint.id,
                    initialId = existing?.initialId ?: blueprint.initialId,
                    type = blueprint.type,
                    context = blueprint.context,
                    blueprint = blueprint.blueprint,
                    createdAt = blueprint.createdAt,
                    editedAt = blueprint.editedAt
                )
            }
        }
        return blueprint
    }

    override suspend fun update(botId: String, blueprint: BlueprintData): BlueprintData {
        dispatchers.runOnIO {
            queries.update(
                id = blueprint.id,
                context = blueprint.context,
                blueprint = blueprint.blueprint,
                editedAt = blueprint.editedAt
            )
        }
        return blueprint
    }
    override suspend fun delete(botId: String, id: String) {
        dispatchers.runOnIO {
            queries.delete(botId, id)
        }
    }

    override suspend fun markDeleted(botId: String, id: String, deleted : Boolean) {
        dispatchers.runOnIO {
            queries.markDeleted(botId = botId, id =  id, deleted =  deleted)
        }
    }

    override suspend fun replaceId(botId: String, oldId: String, newId: String) {
        dispatchers.runOnIO {
            queries.replaceId(
                botId = botId,
                oldId = oldId,
                newId = newId
            )
        }
    }

    override suspend fun deleteAll(botId: String) {
        dispatchers.runOnIO {
            queries.deleteAll(botId = botId)
        }
    }
}

private fun SqBlueprint.toBlueprint() = BlueprintData(
    id = id,
    initialId = initialId,
    type = type,
    context = context,
    blueprint = blueprint,
    editedAt = editedAt,
    createdAt = createdAt,
    isDeleted = deleted
)