package org.botdesigner.shared.domain.content.settings

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import org.botdesigner.shared.data.repo.EditorSettings
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.util.ErrorHandler

internal class DefaultEditorSettingsComponent(
    private val onBack : () -> Unit,
    private val onShowBlueprintGuide : () -> Unit,
    private val onShowPinGuide : () -> Unit,
    override val state: MutableStateFlow<EditorSettings>,
    context: ComponentContext,
    errorHandler: ErrorHandler,
) : CoroutineComponent(context, errorHandler), EditorSettingsComponent {

    override fun onAutoSaveChanged(value: Boolean) {
        state.update {
            it.copy(isAutoSaveEnabled = value)
        }
    }

    override fun onDrawGridChanged(value: Boolean) {
        state.update {
            it.copy(
                isGridEnabled = value
            )
        }
    }

    override fun onVibrateOnMoveChanged(value: Boolean) {
        state.update {
            it.copy(vibrateOnMove = value)
        }
    }

    override fun onVibrateOnConnectChanged(value: Boolean) {
        state.update {
            it.copy(vibrateOnConnect = value)
        }
    }

    override fun onBlueprintGuideClicked() {
        onShowBlueprintGuide()
    }

    override fun onPinsGuideClicked() {
        onShowPinGuide()
    }

    override fun onBack() {
        onBack.invoke()
    }


}