package org.botdesigner.shared.domain

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.instancekeeper.InstanceKeeper
import com.arkivanov.essenty.instancekeeper.getOrCreate
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.toCoroutineExceptionHandler
import org.koin.ext.getFullName
import kotlin.coroutines.CoroutineContext
import kotlin.reflect.KCallable


private class ComponentCoroutineScope(errorHandler: ErrorHandler) : CoroutineScope, InstanceKeeper.Instance {
    override val coroutineContext: CoroutineContext =
        Dispatchers.Main.immediate +
                SupervisorJob() +
                errorHandler.toCoroutineExceptionHandler()

    override fun onDestroy() {
        cancel()
    }

}

internal abstract class CoroutineComponent(
    context: ComponentContext,
    errorHandler: ErrorHandler = ErrorHandler.Ignore
) : ComponentContext by context {

    val componentScope: CoroutineScope by lazy {
        instanceKeeper.getOrCreate("componentScope") {
            ComponentCoroutineScope(errorHandler)
        }
    }
}

inline fun <reified T>T.keyFor(field : KCallable<*>) : String {
    return T::class.getFullName() + "_" + field.name
}