package org.botdesigner.blueprint

enum class Platform {
    Android, Ios, Web, Desktop
}

val Platform.isMobile : Boolean
    get() = this == Platform.Android || this == Platform.Ios


expect val CurrentPlatform : Platform