package org.botdesigner.shared.data.repo.impl

import io.ktor.client.plugins.ClientRequestException
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.emptyFlow
import kotlinx.coroutines.flow.filter
import kotlinx.datetime.Clock
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.MissingFieldException
import org.botdesigner.core.Bot
import org.botdesigner.core.BotStatus
import org.botdesigner.core.BotType
import org.botdesigner.core.models.BlueprintLog
import org.botdesigner.shared.data.repo.BotsRepository
import org.botdesigner.shared.data.repo.VerifyBotTokenResult
import org.botdesigner.shared.data.source.BotDataSource
import org.botdesigner.shared.data.source.CacheBotDataSource
import org.botdesigner.shared.util.runCatchingIgnoringCancellation
import org.botdesigner.telegram.TelegramClient
import org.botdesigner.telegram.getWebhookInfo

internal class BotsRepositoryImpl(
    private val cacheBotDataSource: CacheBotDataSource,
    private val remoteBotDataSource: BotDataSource,
) : BotsRepository {

    private val updateTrigger = MutableSharedFlow<UpdateSource>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    override fun getAll(): Flow<List<Bot>> {
        return DataFlow(
            updateTrigger = updateTrigger,
            cache = {
                cacheBotDataSource.getAll()
            },
            remote = {
                remoteBotDataSource.getAll()
            },
            preTransform = {
                // running bots go first
                it.sortedBy { bot -> bot.status != BotStatus.Running }
            },
            save = { list ->
                runCatchingIgnoringCancellation {
                    cacheBotDataSource.deleteAll()
                    list.forEach {
                        cacheBotDataSource.create(it)
                    }
                }.onFailure {
                    it.printStackTrace()
                }
            }
        ).distinctUntilChanged().catch {
            updateTrigger.emit(UpdateSource.Remote())
            throw it
        }
    }

    override suspend fun create(token: String, debugToken : String?, name: String, type: BotType): Bot {
        return Data(
            remote = {
                val now = Clock.System.now().epochSeconds

                remoteBotDataSource.create(
                    Bot(
                        id = "",
                        ownerId = "",
                        token = token,
                        name = name,
                        type = type,
                        debugToken = debugToken,
                        status = BotStatus.Stopped,
                        createdAt = now,
                        editedAt = now,
                    )
                )
            },
            save = {
                runCatchingIgnoringCancellation {
                    cacheBotDataSource.create(it)
                }
            }
        ).also {
            updateTrigger.emit(UpdateSource.Local(it.id))
        }
    }

    override fun get(id: String): Flow<Bot> {
        return DataFlow(
            updateTrigger = updateTrigger.filter { id in it.keys },
            cache = {
                cacheBotDataSource.get(id)
            },
            remote = {
                remoteBotDataSource.get(id)
            },
            save = {
                runCatchingIgnoringCancellation {
                    cacheBotDataSource.create(it)
                }
            }
        ).distinctUntilChanged().catch {
            updateTrigger.emit(UpdateSource.Remote(id))
            throw it
        }
    }

    override suspend fun update(bot: Bot) {
        remoteBotDataSource.update(bot)
        runCatchingIgnoringCancellation {
            cacheBotDataSource.update(bot)
            updateTrigger.emit(UpdateSource.Local(bot.id))
        }.onFailure {
            updateTrigger.emit(UpdateSource.Remote(bot.id))
        }
    }

    override suspend fun toggle(botId : String, status: BotStatus) {
        remoteBotDataSource.toggle(botId, status)
        runCatchingIgnoringCancellation {
            cacheBotDataSource.toggle(botId, status)
            updateTrigger.emit(UpdateSource.Local(botId))
        }.onFailure {
            updateTrigger.emit(UpdateSource.Remote(botId))
        }
    }

    override suspend fun delete(id: String) {
        remoteBotDataSource.delete(id)
        runCatchingIgnoringCancellation {
            cacheBotDataSource.delete(id)
            updateTrigger.tryEmit(UpdateSource.Local(id))
        }.onFailure {
            updateTrigger.emit(UpdateSource.Remote(id))
        }
    }


    override suspend fun logs(botId: String, page: Int): Flow<List<BlueprintLog>> {
        return emptyFlow() //TODO
    }

    override suspend fun verifyToken(
        token: String,
        botType: BotType,
        verifyExistence: Boolean,
    ) : VerifyBotTokenResult {

        return when(botType){
            BotType.Telegram -> verifyTelegram(token)
        }

    }

    @OptIn(ExperimentalSerializationApi::class)
    private suspend fun verifyTelegram(token: String) : VerifyBotTokenResult{
        return try {
            val ok = TelegramClient(token).getWebhookInfo().ok
            if (ok) VerifyBotTokenResult.Success else VerifyBotTokenResult.InvalidToken
        } catch (t : MissingFieldException) {
            VerifyBotTokenResult.InvalidToken
        }
        catch (t : ClientRequestException) {
            VerifyBotTokenResult.InvalidToken
        }
        catch (t : Throwable) {
            VerifyBotTokenResult.Error
        }
    }
}