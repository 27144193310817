package org.botdesigner.blueprint.stdlib.functions.`object`

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.widthIn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonObject
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintIcons
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.BlueprintPinsOwner
import org.botdesigner.blueprint.components.GenericPinFactory
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.PinsMutationScope
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.BpFunctionStateHolder
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.generic
import org.botdesigner.blueprint.stdlib.pins.StringPinFactory
import org.botdesigner.blueprint.stdlib.pins.string
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.blueprint.ui.components.Function

@kotlinx.serialization.Serializable
@SerialName("BpParseObject")
class BpParseObject(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id) {
        input {
            generic("Object", order = 0u)
            string("Key", order = 1u)
        }
        output {
            generic("Value", order = 2u)
        }
    }
) : BpFunction() {

    override val color: Color
        get() = BlueprintColors.Util

    override val icon: ImageVector
        get() = BlueprintIcons.Object

    override val summary: AnnotatedString
        get() = "Get value of an object field. Accepts JSON encoded string or any key-value object"
            .toAnnotatedString()

    override val isExtendable: Boolean
        get() = true

    override val factory: FunctionFactory
        get() = ::BpParseObject

    override fun isRemoveEnabled(state: BlueprintPinsOwner): Boolean {
        return state.pins.size > 3
    }

    override fun PinsMutationScope.onAddPins(pool: BlueprintNodesPool) {

        appendPin {
            StringPinFactory.create(
                id = it,
                order = pins.size.toUInt(),
                elementId = id,
                name = "Key",
                isOut = false
            )
        }
        appendPin {
            GenericPinFactory.create(
                id = it,
                elementId = id,
                order = pins.size.toUInt(),
                name = "Value",
                isOut = true
            )
        }
    }

    override fun PinsMutationScope.onRemovePins(pool: BlueprintNodesPool) {
        removePin(pins.last())
        removePin(pins.last())
    }

    override suspend fun calculate(input: List<*>): List<*> {
        val obj = input.first()

        val jsonElement = if (obj is String) {
            Json.parseToJsonElement(obj).jsonObject
        } else (obj as Map<*, *>)

        return input.drop(1)
            .map { jsonElement[it] }
    }

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> = object : BpFunctionStateHolder<BpParseObject>(
        element = this,
        manager = manager
    ) {
        private val casePins get() = super.pins.drop(1)


        override fun shouldDrawPin(pin: Pin<*>): Boolean {
            return pin.order == 0u
        }

        @Composable
        override fun Component(
            manager: BlueprintManager,
            modifier: Modifier,
            fieldSize: Float,
            shape: Shape,
            also: @Composable (Float) -> Unit
        ) {
            Function(
                modifier = modifier,
                color = element.color,
                name = element.name,
                manager = manager,
                fieldSize = fieldSize,
                shape = shape,
                icon = element.icon,
            ) { width ->
                casePins.groupBy { it.isOut }.let {
                    it[false].orEmpty().zip(it[true].orEmpty()) { i, o ->
                        var rowInParent by remember {
                            mutableStateOf(Offset.Zero)
                        }

                        var width1 by remember {
                            mutableStateOf(0)
                        }
                        var width2 by remember {
                            mutableStateOf(0)
                        }

                        Row(
                            modifier = Modifier
                                .widthIn(min = with(LocalDensity.current) { width.toDp() })
                                .onGloballyPositioned {
                                    rowInParent = it.positionInParent()
                                },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {

                            DrawPins(
                                parentInComponent = rowInParent,
                                isOut = false,
                                pool = manager,
                                onPinClicked = { p -> manager.onPinClicked(p) },
                                modifier = Modifier.onSizeChanged { size ->
                                    width1 = size.width
                                },
                                drawIf = { p -> p.id == i.id }
                            )
                            DrawPins(
                                parentInComponent = rowInParent,
                                isOut = true,
                                pool = manager,
                                onPinClicked = { manager.onPinClicked(it) },
                                modifier = Modifier.onSizeChanged {
                                    width2 = it.width
                                },
                                drawIf = { it.id == o.id }
                            )
                        }
                    }
                }
                also(width)
            }
        }
    }
}