package org.botdesigner.blueprint.components

import androidx.compose.runtime.Stable
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.util.fastFilter
import androidx.compose.ui.util.fastJoinToString
import androidx.compose.ui.util.fastMapIndexed

@Stable
interface BlueprintInstance : Identifiable {

    val name: String
        get() = this::class.simpleName.toString().let {
            val uppers = it.filter(Char::isUpperCase)
            val splits = it.split(delimiters = uppers.toCharArray())
                .fastFilter(String::isNotBlank)
            if (uppers.length != splits.size){
                return@let it
            }
            val words = splits.fastMapIndexed { index, s -> uppers[index] + s }

            return (if (words[0] == "Bp")
                words.drop(1)
            else words).fastJoinToString(separator = " ")
        }

    val coordinate : Point

    val summary : AnnotatedString get() = AnnotatedString("")
}

interface BlueprintInstanceStateHolder<out T : BlueprintInstance> : BlueprintPositionable, Identifiable {

    fun save(): T

    fun setPosition(pos: Point)
}