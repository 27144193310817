package org.botdesigner.shared.domain.content.account

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.router.slot.SlotNavigation
import com.arkivanov.decompose.router.slot.activate
import com.arkivanov.decompose.router.slot.childSlot
import com.arkivanov.decompose.router.slot.dismiss
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.stateIn
import kotlinx.serialization.Serializable
import org.botdesigner.api.AuthProvider
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.LinkResult
import org.botdesigner.shared.data.repo.UserRepository
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.SnackbarIdiom
import org.botdesigner.shared.domain.SnackbarMessage
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.dispatchers.Dispatchers

private const val LinkJobKey = "DefaultAccountComponent_OauthLinking"

internal class DefaultAccountComponent(
    context : ComponentContext,
    private val userRepository: UserRepository,
    private val onNavigateToEditPassword : () -> Unit,
    private val onLinkGoogleClicked : () -> Unit,
    private val onBack : () -> Unit,
    errorHandler: ErrorHandler,
    private val dispatchers: Dispatchers,
    private val onMessage : (SnackbarMessage) -> Unit
) : CoroutineComponent(context, errorHandler), AccountComponent {

    private val isLinkageInProgress : MutableStateFlow<Boolean> = instanceKeeper
        .getOrCreateSimple(keyFor(::isLinkageInProgress)) {
            MutableStateFlow(false)
        }

    override val state = combine(
        userRepository.currentUserFlow
            .filterNotNull(),
        isLinkageInProgress
    ) { user, inProgress ->
        AccountState(
            linkedProviders = user.authProviders,
            isLinkageInProgress = inProgress
        )
    }.stateIn(
        scope = componentScope,
        started = SharingStarted.Eagerly,
        initialValue = AccountState()
    )


    private val dialogNavigation = SlotNavigation<DialogConfig>()

    override val dialog: Value<ChildSlot<*, AccountComponent.Dialog<*>>> = childSlot(
        source = dialogNavigation,
        serializer = DialogConfig.serializer(),
        handleBackButton = true,
        childFactory = ::dialogFactory,
        key = "dialog"
    )

    override fun onChangePasswordClicked() {
        if (state.value.hasPassword) {
            onNavigateToEditPassword.invoke()
        }
    }

    override fun onLinkGoogleClicked() {
        onLinkGoogleClicked.invoke()
    }

    override fun onLinkAppleClicked() {
        if (AuthProvider.Apple !in state.value.linkedProviders) {
            dispatchers.launchIO(componentScope, key = LinkJobKey) {
                isLinkageInProgress.emit(true)
                val (idiom, msg) = when (userRepository.linkApple()) {
                    LinkResult.Success ->
                        SnackbarIdiom.Success to SharedRes.strings.link_apple_success

                    LinkResult.InvalidCredentials ->
                        SnackbarIdiom.Error to SharedRes.strings.error_something_went_wrong

                    LinkResult.CredentialsInUse ->
                        SnackbarIdiom.Error to SharedRes.strings.error_link_cred_in_use
                }
                onMessage(
                    SnackbarMessage(
                        idiom = idiom,
                        message = msg.desc()
                    )
                )
            }.invokeOnCompletion {
                isLinkageInProgress.tryEmit(false)
                if (it != null && it !is CancellationException){
                    onMessage(
                        SnackbarMessage(
                            idiom = SnackbarIdiom.Error,
                            
                            message = SharedRes.strings.link_apple_fail.desc()
                        )
                    )
                }
            }
        } else {
            //TODO : unlink apple
        }
    }

    override fun onDeleteAccountClicked() {
        dialogNavigation.activate(DialogConfig.DeleteAccount)
    }

    override fun onCancelLinking() {
        dispatchers.cancel(LinkJobKey)
    }

    override fun onBack() {
        onBack.invoke()
    }
    
    private fun dialogFactory(
        config: DialogConfig,
        context: ComponentContext
    ) : AccountComponent.Dialog<*> {
        return when (config) {
            DialogConfig.DeleteAccount -> AccountComponent.Dialog.DeleteAccount(
                component = AlertConfirmationDialogComponent.Default(
                    onConfirm = {
                        dispatchers.launchIO(componentScope) {
                            userRepository.deleteAccount()
                        }
                    },
                    isDestructive = true,
                    onCancel = dialogNavigation::dismiss,
                    title =  SharedRes.strings.confirm.desc(),
                    message = SharedRes.strings.confirm_delete_account.desc()
                )
            )
        }
    }

    @Serializable
    private sealed interface DialogConfig {

        @Serializable
        object DeleteAccount : DialogConfig
    }
}