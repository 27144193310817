package org.botdesigner.shared.domain.content.sessions

import com.arkivanov.decompose.ComponentContext
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.api.SharedConstants
import org.botdesigner.api.auth.Session
import org.botdesigner.shared.data.repo.SessionRepository
import org.botdesigner.shared.data.repo.impl.collectAsDataState
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.intents.ViewUrlIntent

internal class DefaultSessionsComponent(
    private val onBack : () -> Unit,
    context: ComponentContext,
    private val sessionRepository: SessionRepository,
    errorHandler: ErrorHandler,
    private val dispatchers: Dispatchers,
    private val viewUrlIntent : ViewUrlIntent
) : CoroutineComponent(context, errorHandler), SessionsComponent {

    private val retryFlow = MutableSharedFlow<Unit>()

    override val sessions: StateFlow<UIState<List<Session>>> = sessionRepository
        .sessions()
        .collectAsDataState(
            stateIn = componentScope,
            retryTrigger = retryFlow
        )

    override val controlsEnabled: MutableStateFlow<Boolean> =  MutableStateFlow(true)

    override fun refresh() {
        dispatchers.launchIO(componentScope) {
            retryFlow.emit(Unit)
        }
    }

    override fun onStopClicked(session: Session) {
        controlsEnabled.value = false
        dispatchers.launchIO(componentScope) {
            sessionRepository.stop(session.id)
            retryFlow.emit(Unit)
        }.invokeOnCompletion {
            controlsEnabled.value = true
        }
    }

    override fun onStopAllClicked() {
        controlsEnabled.value = false
        dispatchers.launchIO(componentScope) {
            sessionRepository.stopAll()
            retryFlow.emit(Unit)
        }.invokeOnCompletion {
            controlsEnabled.value = true
        }
    }

    override fun onLearnMoreClicked() {
        viewUrlIntent.invoke(SharedConstants.DownloadAppUrl)
    }

    override fun onBack() {
        onBack.invoke()
    }
}