package org.botdesigner.blueprint.ui.pins

import androidx.compose.animation.core.InfiniteRepeatableSpec
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.addOutline
import androidx.compose.ui.graphics.drawscope.Fill
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.drawscope.translate
import androidx.compose.ui.unit.dp
import org.botdesigner.blueprint.ui.PinShape
import kotlin.math.abs
import kotlin.math.cos
import kotlin.math.min
import kotlin.math.sin

private val BasePinSize = 32.dp

private val BasePinAnimation by lazy {
    InfiniteRepeatableSpec<Float>(
        tween(durationMillis = 2000, easing = LinearEasing)
    )
}

@Composable
fun ColumnScope.BasePinWidget(
    color: Color,
    name: String,
    isOut: Boolean,
    isSelected: Boolean,
    isActive : Boolean,
    required : Boolean,
    onTap: () -> Unit,
    modifier: Modifier,
    value: @Composable () -> Unit
) {
    Row(
        modifier = modifier
            .align(if (isOut) Alignment.End else Alignment.Start),
        horizontalArrangement = if (isOut)
            Arrangement.End else Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {

        if (isOut) {
            Name(name, isOut)
            Icon(
                required = required,
                isActive = isActive,
                isSelected = isSelected,
                color = color,
                onTap = onTap
            )
        } else {
            Icon(
                required = required,
                isActive = isActive,
                isSelected = isSelected,
                color = color,
                onTap = onTap
            )
            Name(name,isOut)
            value()
        }
    }
}

@Composable
private fun Name(name: String, isOut: Boolean) {
    Text(
        text = name,
        color = Color.White,
        maxLines = 1,
        modifier = Modifier.padding(
            start = if (isOut) 15.dp else 3.dp,
            end = if (isOut) 3.dp else 15.dp
        )
    )
}

private const val TWO_PI = 2 * kotlin.math.PI
private fun RoundedStarShape(
    sides: Int,
    size : Size,
    curve: Double = 0.5,
    iterations: Int = 360,
) : Path {
   return Path().apply {


        val r = min(size.height, size.width) * 0.4 * mapRange(1.0, 0.0, 0.5, 1.0, curve)

        val xCenter = size.width * .5f
        val yCenter = size.height * .5f

        moveTo(xCenter, yCenter)

        var t = 0.0
        val steps = (TWO_PI) / min(iterations, 360)


        while (t <= TWO_PI) {
            val x = r * (cos(t) * (1 + curve * cos(sides * t)))
            val y = r * (sin(t) * (1 + curve * cos(sides * t)))
            lineTo((x + xCenter).toFloat(), (y + yCenter).toFloat())

            t += steps
        }

        val x = r * (cos(t) * (1 + curve * cos(sides * t)))
        val y = r * (sin(t) * (1 + curve * cos(sides * t)))
        lineTo((x + xCenter).toFloat(), (y + yCenter).toFloat())

    }
}

private fun mapRange(a: Double, b: Double, c: Double, d: Double, x: Double): Double {
    return (x - a) / (b - a) * (d - c) + c
}

@Composable
private fun Icon(
    required: Boolean,
    isActive : Boolean,
    isSelected: Boolean,
    color: Color,
    onTap: () -> Unit,
) {
    val animation = if (isSelected) {
        rememberInfiniteTransition(
            label = "Active pin animation"
        ).animateFloat(
            initialValue = 0f,
            targetValue = 2f,
            animationSpec = BasePinAnimation
        ).value
    } else 0f

    IconButton(
        onClick = onTap,
        modifier = Modifier
            .drawWithCache {
                val center = Offset(size.width * 4/6, 0f)

                val shape = RoundedStarShape(8, size / 4f)
                onDrawWithContent {
                    drawContent()
                    if (required) {
                        translate(center.x, center.y) {
                            drawPath(shape, Color.Red)
                        }
                    }
                }
            }
    ) {
        Spacer(
            modifier = Modifier
                .padding(5.dp)
                .size(BasePinSize)
                .drawWithCache {

                    val path = Path().apply {
                        addOutline(
                            PinShape.createOutline(size, layoutDirection, this@drawWithCache)
                        )
                    }

                    val solidColorBrush = SolidColor(color)

                    val stroke = Stroke(width = density)
                    val stroke2 = Stroke(width = 3 * density)

                    val offsetCenter = Offset(BasePinSize.toPx() / 2, BasePinSize.toPx() / 2)

                    onDrawBehind {

                        val brush = if (isSelected) {
                            Brush.selectionGradient(animation, color, offsetCenter)
                        } else {
                            solidColorBrush
                        }

                        drawPath(
                            path = path,
                            brush = brush,
                            style = when {
                                isActive -> Fill
                                isSelected -> stroke2
                                else -> stroke
                            }
                        )
                    }
                }
        )
    }
}

internal fun Brush.Companion.selectionGradient(
    animation : Float,
    color : Color,
    center : Offset,
) =  sweepGradient(
    center = center,
    colorStops = if (animation <= 1f) {
        arrayOf(
            animation to Color.Transparent,
            abs(1 - animation) to color
        )
    } else {
        arrayOf(
            animation - 1 to color,
            abs(1 - animation) to Color.Transparent
        )
    }
)