@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.arrays

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Boolean
import kotlin.Pair
import kotlin.Suppress
import kotlin.collections.List
import kotlin.collections.Map
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpArrayAppend")
public class BpAppend(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.components.GenericArrayPinFactory.create(
              id = Id("0"),
              elementId = elementId,
              order = o,
              name = "Array",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.components.GenericPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Element",
              isOut = i,
              required = false
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Index",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.components.GenericArrayPinFactory.create(
                  id = Id("r"),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("")
      },
) : BpProcedure<@Contextual Any?>() {
  override val summary: AnnotatedString
    get() =
        "Returns copy of the <b>Array</b> with appended <b>Element</b> at the last position. Doesn't support appending to ranges. Total array memory size can't be greater then 1 MB".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4287137928)

  override val pinTypeDerivation: Map<Id, Pair<Id, Boolean>>
    get() = mapOf(
    Pair(Id("r"), Pair(Id("0"), true))
    )

  override val factory: ProcedureFactory<@Contextual Any?>
    get() = ::BpAppend

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual Any?,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(Append(
  		requireNotNull(input[0] as kotlin.collections.Iterable<kotlin.Any?>?) { 
  "Array can't be null" 
  },
  		input[1] as kotlin.Any?,
  		input[2] as kotlin.Long?
  	))}
}
