package org.botdesigner.botblueprints.telegram.functions

import org.botdesigner.blueprint.generator.BlueprintProcedure
import org.botdesigner.botblueprints.BpCategories
import org.botdesigner.botblueprints.BpSubcategories
import org.botdesigner.botblueprints.telegram.TelegramBotBlueprintContext
import org.botdesigner.telegram.Message

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Wait,
    displayName = "Await Next Message",
    summary = """
        Wait for next message from user. For private chats and groups only.
        In group chats set <b>User Id</b> pin to the id of user you want to wait message from. In private chats this pin can be ignored.
        If <b>Timeout</b> > 0 and no message was received after <b>Timeout</b> seconds then <b>Timed Out</b> block is called and waiting stops.
        """,
    serialName = "BpTgAwaitMessage"
)
@Suppress("NonNullType")
internal suspend inline fun TelegramBotBlueprintContext.TgAwaitMessage(
    `User Id` : Long?,
    Timeout: Long?,
    `Timed Out` : (Message?) -> Nothing,
) : Message {
    return awaitMessage(userId = `User Id`, timeout = Timeout) { true }
        ?: `Timed Out`(null)
}

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Wait,
    displayName = "Await Next Photo",
    summary = """
        Wait for next photo from user. For private chats and groups only.
        In group chats set <b>User Id</b> pin to the id of user you want to wait photo from. In private chats this pin can be ignored.
        If <b>Timeout</b> > 0 and no photo was received after <b>Timeout</b> seconds then <b>Timed Out</b> block is called and waiting stops.
        """,
    serialName = "BpTgAwaitPhoto"
)
internal suspend inline fun TelegramBotBlueprintContext.TgAwaitPhoto(
    `User Id` : Long?,
    Timeout: Long?,
    `Timed Out` : (Message?) -> Nothing,
) : Message {
    return awaitMessage(userId = `User Id`, timeout = Timeout) {
        it.photo != null
    } ?: `Timed Out`(null)
}

@BlueprintProcedure(
    category = BpCategories.Telegram,
    subCategory = BpSubcategories.Wait,
    displayName = "Await Next Document",
    summary = """
        Wait for next document from user. For private chats and groups only.
        In group chats set <b>User Id</b> pin to the id of user you want to wait document from. In private chats this pin can be ignored.
        If <b>Timeout</b> > 0 and no document was received after <b>Timeout</b> seconds then <b>Timed Out</b> block is called and waiting stops.
        """,
    serialName = "BpTgAwaitDocument"
)
internal suspend inline fun TelegramBotBlueprintContext.TgAwaitDocument(
    `User Id` : Long?,
    Timeout: Long?,
    `Timed Out` : (Message?) -> Nothing,
) : Message {
    return awaitMessage(userId = `User Id`, timeout = Timeout) {
        it.document != null
    } ?: `Timed Out`(null)
}

private suspend fun TelegramBotBlueprintContext.awaitMessage(
    userId : Long?,
    timeout : Long?,
    predicate : (Message) -> Boolean
) : Message? {
    return await(
        timeout = timeout?.takeIf { it > 0 }?.times(1000) ?: Long.MAX_VALUE
    ) {
        it.update.message?.let {
            predicate(it) && (userId == null || it.from?.id == userId)
        } == true
    }?.update?.message
}