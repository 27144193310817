package org.botdesigner.botblueprints

import org.botdesigner.blueprint.store.BlueprintNodeCategory
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.blueprint.store.BlueprintNodeStoreRecord
import org.botdesigner.botblueprints.scheduler.BpScheduled
import org.botdesigner.botblueprints.telegram.functions.BpTgAwaitDocument
import org.botdesigner.botblueprints.telegram.functions.BpTgAwaitMessage
import org.botdesigner.botblueprints.telegram.functions.BpTgAwaitPhoto
import org.botdesigner.botblueprints.telegram.functions.BpTgBanChatMember
import org.botdesigner.botblueprints.telegram.functions.BpTgChannelPostEdited
import org.botdesigner.botblueprints.telegram.functions.BpTgChannelPosted
import org.botdesigner.botblueprints.telegram.functions.BpTgChatJoinRequested
import org.botdesigner.botblueprints.telegram.functions.BpTgCommandReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgCreateInviteLink
import org.botdesigner.botblueprints.telegram.functions.BpTgDecomposeChat
import org.botdesigner.botblueprints.telegram.functions.BpTgDecomposeMessage
import org.botdesigner.botblueprints.telegram.functions.BpTgDecomposeUser
import org.botdesigner.botblueprints.telegram.functions.BpTgDeleteChatStickerSet
import org.botdesigner.botblueprints.telegram.functions.BpTgDocumentReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgDownloadFile
import org.botdesigner.botblueprints.telegram.functions.BpTgDеleteMessage
import org.botdesigner.botblueprints.telegram.functions.BpTgEditMessageText
import org.botdesigner.botblueprints.telegram.functions.BpTgForwardMessage
import org.botdesigner.botblueprints.telegram.functions.BpTgGetChat
import org.botdesigner.botblueprints.telegram.functions.BpTgGetChatMember
import org.botdesigner.botblueprints.telegram.functions.BpTgGetChatMembersCount
import org.botdesigner.botblueprints.telegram.functions.BpTgGetUserProfilePhotos
import org.botdesigner.botblueprints.telegram.functions.BpTgImagesReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgJoinChatRespond
import org.botdesigner.botblueprints.telegram.functions.BpTgKeyboardButtonCreate
import org.botdesigner.botblueprints.telegram.functions.BpTgKeyboardCreate
import org.botdesigner.botblueprints.telegram.functions.BpTgKickChatMember
import org.botdesigner.botblueprints.telegram.functions.BpTgLeaveChat
import org.botdesigner.botblueprints.telegram.functions.BpTgMessageEdited
import org.botdesigner.botblueprints.telegram.functions.BpTgMessageReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgNewChatMembers
import org.botdesigner.botblueprints.telegram.functions.BpTgPinMessage
import org.botdesigner.botblueprints.telegram.functions.BpTgPromoteChatMember
import org.botdesigner.botblueprints.telegram.functions.BpTgSendAudio
import org.botdesigner.botblueprints.telegram.functions.BpTgSendChatAction
import org.botdesigner.botblueprints.telegram.functions.BpTgSendContact
import org.botdesigner.botblueprints.telegram.functions.BpTgSendDocument
import org.botdesigner.botblueprints.telegram.functions.BpTgSendInvoice
import org.botdesigner.botblueprints.telegram.functions.BpTgSendMessage
import org.botdesigner.botblueprints.telegram.functions.BpTgSendPhoto
import org.botdesigner.botblueprints.telegram.functions.BpTgSendPhotoFile
import org.botdesigner.botblueprints.telegram.functions.BpTgSendPoll
import org.botdesigner.botblueprints.telegram.functions.BpTgSendSticker
import org.botdesigner.botblueprints.telegram.functions.BpTgSendVideo
import org.botdesigner.botblueprints.telegram.functions.BpTgSendVideoVoice
import org.botdesigner.botblueprints.telegram.functions.BpTgSendVoice
import org.botdesigner.botblueprints.telegram.functions.BpTgSetChatDescription
import org.botdesigner.botblueprints.telegram.functions.BpTgSetChatStickerSet
import org.botdesigner.botblueprints.telegram.functions.BpTgSetChatTitle
import org.botdesigner.botblueprints.telegram.functions.BpTgStickerReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgUnbanChatMember
import org.botdesigner.botblueprints.telegram.functions.BpTgUnpinAllMessages
import org.botdesigner.botblueprints.telegram.functions.BpTgUnpinMessage
import org.botdesigner.botblueprints.telegram.functions.BpTgVideoNoteReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgVideoReceived
import org.botdesigner.botblueprints.telegram.functions.BpTgVoiceReceived

public fun BlueprintNodeStoreOwner.register_org_botdesigner_botblueprints() {
  BlueprintNodeStoreOwner.register(
  	BlueprintNodeCategory("Telegram"),
  	BlueprintNodeStoreRecord("Chat", ::BpTgGetChatMember),
  	BlueprintNodeStoreRecord("Chat", ::BpTgKickChatMember),
  	BlueprintNodeStoreRecord("Chat", ::BpTgBanChatMember),
  	BlueprintNodeStoreRecord("Chat", ::BpTgUnbanChatMember),
  	BlueprintNodeStoreRecord("Chat", ::BpTgPromoteChatMember),
  	BlueprintNodeStoreRecord("Chat", ::BpTgCreateInviteLink),
  	BlueprintNodeStoreRecord("Chat", ::BpTgJoinChatRespond),
  	BlueprintNodeStoreRecord("Chat", ::BpTgGetChat),
  	BlueprintNodeStoreRecord("Chat", ::BpTgGetChatMembersCount),
  	BlueprintNodeStoreRecord("Chat", ::BpTgLeaveChat),
  	BlueprintNodeStoreRecord("Chat", ::BpTgSendChatAction),
  	BlueprintNodeStoreRecord("Chat", ::BpTgSetChatTitle),
  	BlueprintNodeStoreRecord("Chat", ::BpTgSetChatDescription),
  	BlueprintNodeStoreRecord("Chat", ::BpTgSetChatStickerSet),
  	BlueprintNodeStoreRecord("Chat", ::BpTgDeleteChatStickerSet),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendMessage),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendPhoto),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendPhotoFile),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendSticker),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendVideo),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendVideoVoice),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendVoice),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendAudio),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendDocument),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendContact),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendPoll),
  	BlueprintNodeStoreRecord("Message", ::BpTgDеleteMessage),
  	BlueprintNodeStoreRecord("Message", ::BpTgEditMessageText),
  	BlueprintNodeStoreRecord("Message", ::BpTgForwardMessage),
  	BlueprintNodeStoreRecord("Message", ::BpTgPinMessage),
  	BlueprintNodeStoreRecord("Message", ::BpTgUnpinMessage),
  	BlueprintNodeStoreRecord("Message", ::BpTgUnpinAllMessages),
  	BlueprintNodeStoreRecord("Message", ::BpTgSendInvoice),
  	BlueprintNodeStoreRecord("User", ::BpTgGetUserProfilePhotos),
  	BlueprintNodeStoreRecord("Util", ::BpTgDownloadFile),
  	BlueprintNodeStoreRecord("Wait", ::BpTgAwaitMessage),
  	BlueprintNodeStoreRecord("Wait", ::BpTgAwaitPhoto),
  	BlueprintNodeStoreRecord("Wait", ::BpTgAwaitDocument),
  	BlueprintNodeStoreRecord("Chat", ::BpTgDecomposeChat),
  	BlueprintNodeStoreRecord("Keyboard", ::BpTgKeyboardCreate),
  	BlueprintNodeStoreRecord("Keyboard", ::BpTgKeyboardButtonCreate),
  	BlueprintNodeStoreRecord("Message", ::BpTgDecomposeMessage),
  	BlueprintNodeStoreRecord("User", ::BpTgDecomposeUser)
  )
  BlueprintNodeStoreOwner.register(
  	BlueprintNodeCategory(""),
  	BlueprintNodeStoreRecord("", ::BpScheduled),
  	BlueprintNodeStoreRecord("", ::BpTgNewChatMembers),
  	BlueprintNodeStoreRecord("", ::BpTgMessageReceived),
  	BlueprintNodeStoreRecord("", ::BpTgMessageEdited),
  	BlueprintNodeStoreRecord("", ::BpTgChannelPosted),
  	BlueprintNodeStoreRecord("", ::BpTgChannelPostEdited),
  	BlueprintNodeStoreRecord("", ::BpTgChatJoinRequested),
  	BlueprintNodeStoreRecord("", ::BpTgCommandReceived),
  	BlueprintNodeStoreRecord("", ::BpTgDocumentReceived),
  	BlueprintNodeStoreRecord("", ::BpTgImagesReceived),
  	BlueprintNodeStoreRecord("", ::BpTgVoiceReceived),
  	BlueprintNodeStoreRecord("", ::BpTgVideoNoteReceived),
  	BlueprintNodeStoreRecord("", ::BpTgVideoReceived),
  	BlueprintNodeStoreRecord("", ::BpTgStickerReceived)
  )
}
