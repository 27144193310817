package org.botdesigner.ui.theme

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Typography
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.layout.layout
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.materialkolor.dynamicColorScheme
import dev.icerock.moko.resources.compose.asFont
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveTheme
import io.github.alexzhirkevich.cupertino.adaptive.CupertinoThemeSpec
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.adaptive.MaterialThemeSpec
import io.github.alexzhirkevich.cupertino.adaptive.Theme
import io.github.alexzhirkevich.cupertino.section.LocalSectionStyle
import io.github.alexzhirkevich.cupertino.section.SectionStyle
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.darkColorScheme
import io.github.alexzhirkevich.cupertino.theme.lightColorScheme
import io.github.alexzhirkevich.cupertino.theme.systemRed
import org.botdesigner.api.auth.DeviceType
import org.botdesigner.api.auth.isDesktop
import org.botdesigner.blueprint.CurrentPlatform
import org.botdesigner.blueprint.Platform
import org.botdesigner.blueprint.isMobile
import org.botdesigner.blueprint.ui.windowSize
import org.botdesigner.shared.data.DeviceConfig
import org.botdesigner.shared.domain.InterfaceIdiom
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.shared.domain.content.appearance.AppearanceState
import org.botdesigner.sharedui.MR
import androidx.compose.material3.ColorScheme as MaterialColorScheme
import io.github.alexzhirkevich.cupertino.theme.ColorScheme as CupertinoColorScheme

@Composable
fun Montserrat() : FontFamily {
    val fontsList = listOfNotNull(
        MR.fonts.Montserrat.black.asFont(FontWeight.Black),
        MR.fonts.Montserrat.blackItalic.asFont(FontWeight.Black, FontStyle.Italic),
        MR.fonts.Montserrat.extraBold.asFont(FontWeight.ExtraBold),
        MR.fonts.Montserrat.extraBoldItalic.asFont(FontWeight.ExtraBold, FontStyle.Italic),
        MR.fonts.Montserrat.bold.asFont(FontWeight.Bold),
        MR.fonts.Montserrat.boldItalic.asFont(FontWeight.Bold, FontStyle.Italic),
        MR.fonts.Montserrat.semiBold.asFont(FontWeight.SemiBold),
        MR.fonts.Montserrat.semiBoldItalic.asFont(FontWeight.SemiBold, FontStyle.Italic),
        MR.fonts.Montserrat.medium.asFont(FontWeight.Medium),
        MR.fonts.Montserrat.mediumItalic.asFont(FontWeight.Medium, FontStyle.Italic),
        MR.fonts.Montserrat.thin.asFont(FontWeight.Thin),
        MR.fonts.Montserrat.thinItalic.asFont(FontWeight.Thin, FontStyle.Italic),
        MR.fonts.Montserrat.light.asFont(FontWeight.Light),
        MR.fonts.Montserrat.lightItalic.asFont(FontWeight.Light, FontStyle.Italic),
        MR.fonts.Montserrat.extraLight.asFont(FontWeight.ExtraLight),
        MR.fonts.Montserrat.extraLightItalic.asFont(FontWeight.ExtraLight, FontStyle.Italic),
    ).takeIf { it.isNotEmpty() }

    return fontsList?.let { FontFamily(it) } ?: FontFamily.Default
}

private val DarkBackground = Color(28, 28, 28)
private val LightBackground = Color(245, 245, 250)

val PointerIcon.Companion.PlatformClickable
    get() = if (DeviceConfig.type == DeviceType.Windows || CurrentPlatform == Platform.Web)
        Hand else Default

fun PointerIcon.Companion.platformClickable(enabled : Boolean = true) = if (enabled)
    PlatformClickable else Default


@Composable
private fun DesktopThemes(
    dark: Boolean,
    accent: Color
) : Pair<MaterialColorScheme, CupertinoColorScheme> {

    var materialColors = dynamicColorScheme(
        seedColor = accent,
        isDark  = dark
    )

    val surface = if (dark)
        Color(40, 40, 40)
    else
        Color.White


    materialColors = materialColors.copy(
        primary = accent,
        onPrimary = Color.White,
        background = if (dark) DarkBackground else LightBackground,
        surface = surface,
        onSurface = if (dark) Color.White else DarkBackground,
        error = CupertinoColors.systemRed(dark),
        errorContainer = surface
    )


    val cupertinoColors = if (dark) {
        darkColorScheme(
            accent = accent,
            systemBackground = materialColors.background,
            tertiarySystemBackground = materialColors.surface,
            secondarySystemGroupedBackground = materialColors.surface
        )
    } else {
        lightColorScheme(
            accent = accent,
            systemBackground = materialColors.background,
            tertiarySystemBackground = materialColors.surface,
            secondarySystemGroupedBackground = materialColors.surface
        )
    }

    return materialColors to cupertinoColors
}

@Composable
private fun IosThemes(
    dark: Boolean,
    accent : Color
) : Pair<MaterialColorScheme, CupertinoColorScheme> {

    var materialColors = dynamicColorScheme(
        seedColor = accent,
        isDark = dark
    )

    val cupertinoColors = if (dark)
        darkColorScheme(
            accent = accent,
            systemBackground = DarkBackground,
        )
    else lightColorScheme(
        accent = accent,
        systemBackground = LightBackground,
    )

    materialColors = materialColors.copy(
        primary = accent,
        onPrimary = Color.White,
        background = cupertinoColors.systemGroupedBackground,//if (dark) darkBg else lightBg,
        surface = cupertinoColors.tertiarySystemBackground,
        error = CupertinoColors.systemRed(dark),
        errorContainer = cupertinoColors.secondarySystemGroupedBackground
    )


    return materialColors to cupertinoColors
}

@Composable
fun AndroidTheme(
    dark: Boolean,
    accent: Color,
    system : Boolean
) : Pair<MaterialColorScheme, CupertinoColorScheme> {

    val material = when {
        system -> systemMaterialTheme(dark)
        else -> dynamicColorScheme(seedColor = accent, isDark = dark)
    }

    val cupertino = if (dark) {
        darkColorScheme(
            separator = Color.Transparent,
            opaqueSeparator = Color.Transparent,
            secondarySystemGroupedBackground = material.background
        )
    } else {
        lightColorScheme(
            separator = Color.Transparent,
            opaqueSeparator = Color.Transparent,
            secondarySystemGroupedBackground = material.background
        )
    }

    return material to cupertino
}


@OptIn(ExperimentalAdaptiveApi::class)
@Composable
internal fun AppTheme(
    appearance: AppearanceState,
    content: @Composable() () -> Unit
) {
    val scaledDensity = with(LocalDensity.current) {
        if (CurrentPlatform == Platform.Ios && DeviceConfig.type == DeviceType.Mac) {
            // scale for mac catalyst
            Density(
                density * 1.33f,
                fontScale
            )
        } else this
    }



    val windowSize by rememberUpdatedState(windowSize())

    val idiom by remember(scaledDensity) {
        derivedStateOf {
            scaledDensity.run {
                if (windowSize.width.toDp() > 600.dp && windowSize.height.toDp() > 500.dp)
                    InterfaceIdiom.Desktop
                else InterfaceIdiom.Mobile
            }
        }
    }

    Box(
        modifier = Modifier.fillMaxSize().layout { measurable, constraints ->
            val p = measurable.measure(constraints)
            layout(p.width, p.height) {
                p.place(0, 0)
            }
        }
    ) {



        val useDarkTheme: Boolean = appearance.isActuallyDark()

        val accent = if (appearance.systemAppearance)
            systemAccent(useDarkTheme)
        else appearance.accent.color(useDarkTheme)


        val (materialColors, cupertinoColors) =
            if (DeviceConfig.type.isDesktop ||
                DeviceConfig.type == DeviceType.Web ||
                idiom == InterfaceIdiom.Desktop
            ) {
                DesktopThemes(useDarkTheme, accent)
            } else {
                when (CurrentPlatform) {
                    Platform.Desktop, Platform.Web -> DesktopThemes(useDarkTheme, accent)
                    Platform.Ios -> IosThemes(useDarkTheme, accent)
                    Platform.Android -> AndroidTheme(useDarkTheme, accent, appearance.systemAppearance)
                }
            }

        setWindowBackground(materialColors.background)

        val fonts = if (CurrentPlatform == Platform.Ios)
            LocalTextStyle.current.fontFamily
        else Montserrat()

        var cupertinoTypography = io.github.alexzhirkevich.cupertino.theme.Typography()

        if (!CurrentPlatform.isMobile) {

            cupertinoTypography = io.github.alexzhirkevich.cupertino.theme.Typography(
                body = cupertinoTypography.body.copy(
                    fontSize = 15.sp,
                    lineHeight = 20.sp
                ),
                headline = cupertinoTypography.headline.copy(
                    fontWeight = FontWeight.Normal,
                    fontSize = 15.sp,
                    lineHeight = 20.sp
                )
            )
        }

        AdaptiveTheme(
            target = if (CurrentPlatform == Platform.Android)
                Theme.Material3 else Theme.Cupertino,
            material = MaterialThemeSpec.Default(
                typography = MaterialTheme.typography.withFontFamily(fonts),
                colorScheme = materialColors,
            ),
            cupertino = CupertinoThemeSpec.Default(
                typography = cupertinoTypography.withFontFamily(fonts),
                colorScheme = cupertinoColors,
            ),
            content = {

                val animatedFontScale by animateFloatAsState(appearance.fontScale)

                CompositionLocalProvider(
                    LocalSectionStyle provides when (CurrentPlatform) {
                        Platform.Android -> SectionStyle.Grouped
                        else -> SectionStyle.InsetGrouped
                    },
                    LocalDensity.provides(
                        Density(
                            density = scaledDensity.density,
                            fontScale = scaledDensity.fontScale * animatedFontScale
                        )
                    ),
                    LocalInterfaceIdiom provides idiom,
                    content = content
                )
            }
        )
    }
}

@Composable
fun AppearanceState.isActuallyDark() = isOnDarkScreen ||
        !autoNightMode && darkMode || autoNightMode && isSystemInDarkTheme()

private fun io.github.alexzhirkevich.cupertino.theme.Typography.withFontFamily(
    fonts: FontFamily?
) = io.github.alexzhirkevich.cupertino.theme.Typography(
        largeTitle = largeTitle.copy(fontFamily = fonts),
        title1 = title1.copy(fontFamily = fonts),
        title2 = title2.copy(fontFamily = fonts),
        title3 = title3.copy(fontFamily = fonts),
        headline = headline.copy(fontFamily = fonts),
        body = body.copy(fontFamily = fonts),
        callout = callout.copy(fontFamily = fonts),
        subhead = subhead.copy(fontFamily = fonts),
        footnote = footnote.copy(fontFamily = fonts),
        caption1 = caption1.copy(fontFamily = fonts),
        caption2 = caption2.copy(fontFamily = fonts)
    )

private fun Typography.withFontFamily(fonts: FontFamily?) = Typography(
    displayLarge = displayLarge.copy(fontFamily = fonts),
    displayMedium = displayMedium.copy(fontFamily = fonts),
    displaySmall = displaySmall.copy(fontFamily = fonts),
    headlineLarge = headlineLarge.copy(fontFamily = fonts),
    headlineMedium = headlineMedium.copy(fontFamily = fonts),
    headlineSmall = headlineSmall.copy(fontFamily = fonts),
    titleLarge = titleLarge.copy(fontFamily = fonts),
    titleMedium = titleMedium.copy(fontFamily = fonts),
    titleSmall = titleSmall.copy(fontFamily = fonts),
    bodyLarge = bodyLarge.copy(fontFamily = fonts),
    bodyMedium = bodyMedium.copy(fontFamily = fonts),
    bodySmall = bodySmall.copy(fontFamily = fonts),
    labelLarge = labelLarge.copy(fontFamily = fonts),
    labelMedium = labelMedium.copy(fontFamily = fonts),
    labelSmall = labelSmall.copy(fontFamily = fonts),
)

@Composable
internal expect fun systemAccent(dark : Boolean) : Color

@Composable
internal expect fun systemMaterialTheme(dark : Boolean) : MaterialColorScheme

@Composable
internal expect fun setWindowBackground(color: Color)
