package org.botdesigner.blueprint.stdlib

import org.botdesigner.blueprint.stdlib.functions.arrays.BpFilter
import org.botdesigner.blueprint.stdlib.functions.arrays.BpMakeArray
import org.botdesigner.blueprint.stdlib.functions.arrays.BpMap
import org.botdesigner.blueprint.stdlib.functions.float.BpEvaluate
import org.botdesigner.blueprint.stdlib.functions.`object`.BpCreateObject
import org.botdesigner.blueprint.stdlib.functions.`object`.BpParseObject
import org.botdesigner.blueprint.stdlib.functions.register_org_botdesigner_blueprint_stdlib_functions
import org.botdesigner.blueprint.stdlib.functions.special.BpChain
import org.botdesigner.blueprint.stdlib.functions.special.BpChainAsync
import org.botdesigner.blueprint.stdlib.functions.special.BpRunWithErrorHandling
import org.botdesigner.blueprint.stdlib.functions.special.BpSwitch
import org.botdesigner.blueprint.stdlib.functions.string.BpStringCreate
import org.botdesigner.blueprint.store.BlueprintNodeCategory
import org.botdesigner.blueprint.store.BlueprintNodeStoreOwner
import org.botdesigner.blueprint.store.BlueprintNodeStoreRecord


fun BlueprintNodeStoreOwner.registerStdlib() {
    BlueprintNodeStoreOwner.register_org_botdesigner_blueprint_stdlib_functions()

    BlueprintNodeStoreOwner.register(
        BlueprintNodeCategory.Stdlib,
        BlueprintNodeStoreRecord("String", ::BpStringCreate),
        BlueprintNodeStoreRecord("Float", ::BpEvaluate),

        BlueprintNodeStoreRecord("Object", ::BpParseObject),
        BlueprintNodeStoreRecord("Object", ::BpCreateObject),

        BlueprintNodeStoreRecord("Array", ::BpMakeArray),
        BlueprintNodeStoreRecord("Array", ::BpMap),
        BlueprintNodeStoreRecord("Array", ::BpFilter),
        BlueprintNodeStoreRecord("Special", ::BpSwitch),
        BlueprintNodeStoreRecord("Special", ::BpChain),
        BlueprintNodeStoreRecord("Special", ::BpChainAsync),
        BlueprintNodeStoreRecord("Special", ::BpRunWithErrorHandling),
    )
}
