package org.botdesigner.shared.domain.content.guides

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.pager.PagerState
import io.github.alexzhirkevich.cupertino.cupertinoTween

@OptIn(ExperimentalFoundationApi::class)
internal class DefaultGuideComponent<T : GuidePage>(
    override val pages: List<T>,
    private val onClose : () -> Unit
) : GuideComponent<T> {

    private val animationSpec = cupertinoTween<Float>()

    override val pagerState: PagerState = object : PagerState() {
        override val pageCount: Int get() = pages.size
    }

    override suspend fun onNextClicked() {
        if (pagerState.currentPage < pagerState.pageCount && !pagerState.isScrollInProgress) {
            pagerState.animateScrollToPage(
                page = pagerState.currentPage + 1,
                animationSpec = animationSpec
            )
        }
    }

    override suspend fun onPrevClicked() {
        if (pagerState.currentPage > 0 && !pagerState.isScrollInProgress){
            pagerState.animateScrollToPage(
                page = pagerState.currentPage - 1,
                animationSpec = animationSpec
            )
        }
    }

    override fun onCloseClicked() = onClose()
}