package org.botdesigner.shared.domain.content.list

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.router.slot.SlotNavigation
import com.arkivanov.decompose.router.slot.activate
import com.arkivanov.decompose.router.slot.childSlot
import com.arkivanov.decompose.router.slot.dismiss
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn
import kotlinx.serialization.Serializable
import org.botdesigner.core.Bot
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.BotsRepository
import org.botdesigner.shared.data.repo.impl.collectAsDataState
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.FormattedFix
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.dispatchers.Dispatchers


internal class DefaultBotListComponent(
    context: ComponentContext,
    override val selectedBot: StateFlow<Bot?>,
    private val onBotDeleted : (bot : Bot) -> Unit,
    private val onNavigateToBot: (Bot) -> Unit,
    private val onNavigateToEdit: (Bot) -> Unit,
    private val onNavigateToCreate: () -> Unit,
    private val botsRepository: BotsRepository,
    errorHandler: ErrorHandler,
    private val dispatchers: Dispatchers
) : CoroutineComponent(context, errorHandler),
    BotsListComponent {

    override val botsSearchQuery: MutableStateFlow<String> =
        instanceKeeper.getOrCreateSimple(keyFor(::botsSearchQuery)) {
            MutableStateFlow("")
        }

    override val botsFilter : MutableStateFlow<BotFilter> =
        instanceKeeper.getOrCreateSimple(keyFor(::botsFilter)) {
            MutableStateFlow(BotFilter.All)
        }

    private val isSearchFocused = MutableStateFlow(false)

    private val dialogNavigation = SlotNavigation<DialogConfig>()

    override val dialog: Value<ChildSlot<*, BotsListComponent.Dialog<*>>> = childSlot(
        source = dialogNavigation,
        serializer = DialogConfig.serializer(),
        handleBackButton = true,
        childFactory = ::dialogFactory,
        key = keyFor(::dialog)
    )

    override val bots: StateFlow<UIState<List<Bot>>> = combine(
        botsRepository.getAll()
            .map {
                it.sortedBy(Bot::status).sortedBy(Bot::name)
            }
            .flowOn(dispatchers.ioContext())
            .collectAsDataState(
                onError = {
                    @Suppress("uninitialized_variable")
                    if (bots.value is UIState.Success<*>)
                        null
                    else UIState.Error()
                }
            ),
        botsFilter,
        botsSearchQuery,
        isSearchFocused
    ) { b, filter, query, focused ->
        if (b is UIState.Success) {

            val filtered = b.value.filter {
                (it.status == filter.status || filter == BotFilter.All || focused) &&
                        (query.isBlank() || it.name.contains(query, true))
            }

            when {
                filtered.isNotEmpty() -> UIState.Success(filtered)

                query.isBlank() && filter == BotFilter.All -> {
                    UIState.Empty(
                        message = SharedRes.strings.bots_empty.desc(),
                    ) // TODO: bots empty
                }

                query.isNotBlank() -> {
                    UIState.Error(
                        title = SharedRes.strings.error_empty.desc(),
                        message = StringDesc.FormattedFix(
                            SharedRes.strings.error_no_bots_matching,
                            query
                        ),
                    )
                }

                else -> UIState.Error(
                    title = SharedRes.strings.error_empty.desc(),
                    message = StringDesc.FormattedFix(
                        SharedRes.strings.error_no_bots_type,
                        filter.name,
                    ),
                )
            }
        } else b
    }.stateIn(
        scope = componentScope,
        started = SharingStarted.Eagerly,
        initialValue = UIState.Loading
    )

    override var isTwoPane: Boolean by mutableStateOf(false)
        private set


    override fun onBotSelected(bot: Bot) {
        onNavigateToBot(bot)
    }

    override fun onBotEdit(bot: Bot) {
        onNavigateToEdit(bot)
    }

    override fun onBotDelete(bot: Bot) {
        dialogNavigation.activate(DialogConfig.Delete(bot))
    }

    override fun onCreateClicked() {
        onNavigateToCreate()
    }

    override fun onFilterChanged(filter: BotFilter) {
        this.botsFilter.value = filter
    }

    override fun onQueryChanged(query: String) {
        this.botsSearchQuery.value = query.take(5000)
    }

    override fun onSearchFocusChanged(focused: Boolean) {
        this.isSearchFocused.value = focused
    }

    private fun dialogFactory(
        config: DialogConfig,
        context: ComponentContext
    ) : BotsListComponent.Dialog<*> = when(config) {
        is DialogConfig.Delete -> BotsListComponent.Dialog.Delete(
            DeleteBotComponent(
                bot = config.bot,
                onConfirm = {
                    dispatchers.launchIO(componentScope) {
                        botsRepository.delete(config.bot.id)
                        onBotDeleted(config.bot)
                        dispatchers.runOnUI {
                            dialogNavigation.dismiss()
                        }
                    }
                },
                onCancel = dialogNavigation::dismiss
            )
        )
    }

    @Serializable
    private sealed interface DialogConfig {

        @Serializable
        class Delete(val bot: Bot) : DialogConfig
    }
}

internal fun DeleteBotComponent(
    bot: Bot,
    onConfirm : () -> Unit,
    onCancel : () -> Unit,
) : AlertConfirmationDialogComponent =
    AlertConfirmationDialogComponent.Default(
        message = StringDesc.FormattedFix(
            SharedRes.strings.confirm_delete_bot,
            bot.name
        ),
        confirm = SharedRes.strings.delete.desc(),
        isDestructive = true,
        onConfirm = onConfirm,
        onCancel = onCancel
    )