package org.botdesigner.shared.`data`.cache

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import org.botdesigner.botblueprints.BlueprintType

public class SqBlueprintQueries(
  driver: SqlDriver,
  private val SqBlueprintAdapter: SqBlueprint.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getAll(botId: String, mapper: (
    id: String,
    initialId: String,
    botId: String,
    type: BlueprintType,
    context: String,
    blueprint: String,
    createdAt: Long,
    editedAt: Long,
    deleted: Boolean,
  ) -> T): Query<T> = GetAllQuery(botId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      SqBlueprintAdapter.typeAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getLong(6)!!,
      cursor.getLong(7)!!,
      cursor.getBoolean(8)!!
    )
  }

  public fun getAll(botId: String): Query<SqBlueprint> = getAll(botId) { id, initialId, botId_,
      type, context, blueprint, createdAt, editedAt, deleted ->
    SqBlueprint(
      id,
      initialId,
      botId_,
      type,
      context,
      blueprint,
      createdAt,
      editedAt,
      deleted
    )
  }

  public fun <T : Any> `get`(
    botId: String,
    id: String,
    initialId: String,
    mapper: (
      id: String,
      initialId: String,
      botId: String,
      type: BlueprintType,
      context: String,
      blueprint: String,
      createdAt: Long,
      editedAt: Long,
      deleted: Boolean,
    ) -> T,
  ): Query<T> = GetQuery(botId, id, initialId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      SqBlueprintAdapter.typeAdapter.decode(cursor.getString(3)!!),
      cursor.getString(4)!!,
      cursor.getString(5)!!,
      cursor.getLong(6)!!,
      cursor.getLong(7)!!,
      cursor.getBoolean(8)!!
    )
  }

  public fun `get`(
    botId: String,
    id: String,
    initialId: String,
  ): Query<SqBlueprint> = get(botId, id, initialId) { id_, initialId_, botId_, type, context,
      blueprint, createdAt, editedAt, deleted ->
    SqBlueprint(
      id_,
      initialId_,
      botId_,
      type,
      context,
      blueprint,
      createdAt,
      editedAt,
      deleted
    )
  }

  public suspend fun create(
    id: String,
    initialId: String,
    botId: String,
    type: BlueprintType,
    context: String,
    blueprint: String,
    createdAt: Long,
    editedAt: Long,
  ) {
    driver.execute(248_688_774, """
        |INSERT OR REPLACE INTO SqBlueprint(id, initialId, botId,type,context,blueprint,createdAt, editedAt)
        |    VALUES (?,?,?,?,?,?,?,?)
        """.trimMargin(), 8) {
          bindString(0, id)
          bindString(1, initialId)
          bindString(2, botId)
          bindString(3, SqBlueprintAdapter.typeAdapter.encode(type))
          bindString(4, context)
          bindString(5, blueprint)
          bindLong(6, createdAt)
          bindLong(7, editedAt)
        }.await()
    notifyQueries(248_688_774) { emit ->
      emit("SqBlueprint")
    }
  }

  public suspend fun markDeleted(
    deleted: Boolean,
    botId: String,
    id: String,
  ) {
    driver.execute(-414_251_198,
        """UPDATE SqBlueprint SET deleted = ? WHERE botId = ? AND id = ? OR initialId = ?""", 4) {
          bindBoolean(0, deleted)
          bindString(1, botId)
          bindString(2, id)
          bindString(3, id)
        }.await()
    notifyQueries(-414_251_198) { emit ->
      emit("SqBlueprint")
    }
  }

  public suspend fun delete(botId: String, id: String) {
    driver.execute(265_524_533,
        """DELETE FROM SqBlueprint WHERE botId = ? AND id = ? OR initialId = ?""", 3) {
          bindString(0, botId)
          bindString(1, id)
          bindString(2, id)
        }.await()
    notifyQueries(265_524_533) { emit ->
      emit("SqBlueprint")
    }
  }

  public suspend fun deleteAll(botId: String) {
    driver.execute(-1_088_330_708, """DELETE FROM SqBlueprint WHERE botId = ?""", 1) {
          bindString(0, botId)
        }.await()
    notifyQueries(-1_088_330_708) { emit ->
      emit("SqBlueprint")
    }
  }

  public suspend fun update(
    context: String,
    blueprint: String,
    editedAt: Long,
    id: String,
  ) {
    driver.execute(762_136_659,
        """UPDATE SqBlueprint SET context = ?, blueprint = ?, editedAt = ? WHERE id = ? OR initialId = ?""",
        5) {
          bindString(0, context)
          bindString(1, blueprint)
          bindLong(2, editedAt)
          bindString(3, id)
          bindString(4, id)
        }.await()
    notifyQueries(762_136_659) { emit ->
      emit("SqBlueprint")
    }
  }

  public suspend fun replaceId(
    newId: String,
    botId: String,
    oldId: String,
  ) {
    driver.execute(-1_185_010_907, """UPDATE SqBlueprint SET id = ? WHERE botId = ? AND id = ?""",
        3) {
          bindString(0, newId)
          bindString(1, botId)
          bindString(2, oldId)
        }.await()
    notifyQueries(-1_185_010_907) { emit ->
      emit("SqBlueprint")
    }
  }

  private inner class GetAllQuery<out T : Any>(
    public val botId: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("SqBlueprint", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("SqBlueprint", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(351_615_477,
        """SELECT * FROM SqBlueprint WHERE botId = ? ORDER BY editedAt DESC""", mapper, 1) {
      bindString(0, botId)
    }

    override fun toString(): String = "SqBlueprint.sq:getAll"
  }

  private inner class GetQuery<out T : Any>(
    public val botId: String,
    public val id: String,
    public val initialId: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("SqBlueprint", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("SqBlueprint", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(455_588_876, """
    |SELECT * FROM SqBlueprint WHERE botId = ?
    |    AND (id = ? OR id = ? OR initialId = ? OR initialId = ? )
    """.trimMargin(), mapper, 5) {
      bindString(0, botId)
      bindString(1, id)
      bindString(2, initialId)
      bindString(3, id)
      bindString(4, initialId)
    }

    override fun toString(): String = "SqBlueprint.sq:get"
  }
}
