@file:OptIn(ExperimentalMaterial3Api::class)
@file:Suppress("INVISIBLE_MEMBER", "INVISIBLE_REFERENCE")


package org.botdesigner.blueprint.stdlib.pins

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DatePicker
import androidx.compose.material3.DatePickerDefaults
import androidx.compose.material3.DatePickerDialog
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.defaultLocale
import androidx.compose.material3.rememberDatePickerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.*
import org.botdesigner.blueprint.ui.components.WithDeviceDensity
import org.botdesigner.blueprint.ui.pins.BasePinWidget

@Serializable
@SerialName("DateTimePinFactory")
object DateTimePinFactory: PinFactory<Instant> {

    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ): Pin<Instant> = DateTimePin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}

@Serializable
@SerialName("DateTimePin")
data class DateTimePin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val name: String,
    override val isOut: Boolean,
    override val value: Instant? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : Pin<Instant>() {

    override val color: Color get() = BlueprintColors.Date

    override fun withReference(parent: Pin<*>?): DateTimePin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun withValue(value: String): DateTimePin {
        val v = kotlin.runCatching {
            Instant.parse(value)
        }.getOrElse {
            value.toLongOrNull()?.let { Instant.fromEpochMilliseconds(it) }
        }
        return copy(
            value = v,
            parentId = null,
            parentPinId = null
        )
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<Instant> {
        return DateTimePinFactory
    }

    @Composable
    override fun ColumnScope.Draw(
        modifier: Modifier,
        onInputChanged: (String) -> Unit,
        onTap: (Pin<*>) -> Unit,
        value: String?,
        isSelected: Boolean,
        pool: BlueprintNodesPool
    ) {

        val isActive = pool.isPinActive(this@DateTimePin)

        BasePinWidget(
            color = BlueprintColors.Date,
            name = name,
            isOut = isOut,
            required = required,
            modifier = modifier,
            isActive = isActive,
            isSelected = isSelected,
            onTap = { onTap(this@DateTimePin) }
        ) {
            if (!isActive) {

                var dialogVisible by rememberSaveable {
                    mutableStateOf(false)
                }

                val date = remember(value) {
                    val v = if (value != null)
                        kotlin.runCatching {
                            Instant.parse(value)
                        }.getOrElse {
                            value.toLongOrNull()?.let {
                                Instant.fromEpochMilliseconds(it)
                            }
                        }
                    else null

                    v?.toLocalDateTime(TimeZone.currentSystemDefault())
                }

                val state = rememberDatePickerState(
                    initialSelectedDateMillis = value?.toLongOrNull()
                )

                val formatter = DatePickerDefaults.dateFormatter()
                val locale = defaultLocale()



                val dateString = remember(date) {
                    if (date != null)
                        formatter.formatDate(date.toInstant(TimeZone.UTC).toEpochMilliseconds(), locale)
                            ?: "Select"
                    else "Select"
                }


                if (dialogVisible) {
                    WithDeviceDensity {
                        DatePickerDialog(
                            onDismissRequest = {
                                dialogVisible = false
                            },
                            confirmButton = {
                                Button(
                                    onClick = {
                                        onInputChanged(state.selectedDateMillis.toString())
                                        dialogVisible = false
                                    }
                                ) {
                                    Text("Confirm")
                                }
                            }
                        ) {
                            DatePicker(state)
                        }
                    }
                }

                Row {
                    Spacer(Modifier.width(5.dp))
                    TextButton(
                        border = BorderStroke(1.dp, Color.White),
                        colors = ButtonDefaults.textButtonColors(
                            contentColor = color
                        ),
                        onClick = {
                            dialogVisible = !dialogVisible
                        },
                    ) {

                        Text(text = dateString)
                    }
                }
            } else {
                Spacer(modifier = Modifier.width(5.dp))
            }
        }
    }
}

fun PinsDirectionalScope.date(name: String, order: UInt? = null) {
    pin { o, isOut ->
        DateTimePin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = isOut,
        )
    }
}
