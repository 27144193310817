package org.botdesigner.blueprint.store

import kotlinx.serialization.modules.PolymorphicModuleBuilder
import kotlinx.serialization.modules.subclass
import org.botdesigner.blueprint.components.BlueprintNode
import org.botdesigner.blueprint.components.Point

/**
 * Record of [BlueprintNode] in [BlueprintNodeStoreOwner].
 * */
class BlueprintNodeStoreRecord<out T : BlueprintNode> (
    val subcategory : String,
    val factory : (Point) -> T,
    val serializer : ((PolymorphicModuleBuilder<BlueprintNode>).() -> Unit)?=null
)

inline fun <reified T : BlueprintNode> BlueprintNodeStoreRecord(
    subcategory : String, noinline factory : (Point) -> T
) = BlueprintNodeStoreRecord(subcategory, factory){ subclass(T::class) }
