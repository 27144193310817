package org.botdesigner.blueprint.ui.components

import androidx.compose.animation.core.InfiniteRepeatableSpec
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.addOutline
import androidx.compose.ui.graphics.drawscope.Fill
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.unit.dp
import org.botdesigner.blueprint.ui.ConnectorShape
import org.botdesigner.blueprint.ui.pins.selectionGradient

@Composable
fun ColumnScope.ConnectorWidget(
    isActive : Boolean,
    isOut : Boolean,
    modifier: Modifier = Modifier,
    name : String = "",
    isSelected : Boolean = true,
    onTap : () -> Unit = {}
) {

    @Composable
    fun Button() {
        val animation = if (isSelected) {
            rememberInfiniteTransition().animateFloat(
                initialValue = 0f,
                targetValue = 2f,
                animationSpec = InfiniteRepeatableSpec(
                    tween(durationMillis = 2000, easing = LinearEasing)
                )
            ).value
        } else {
            0f
        }

        val width = 22.dp
        val height = 30.dp

        IconButton(onClick = onTap) {

            Spacer(
                Modifier
                    .padding(5.dp)
                    .width(width)
                    .height(height)
                    .align(if (isOut) Alignment.End else Alignment.Start)
                    .drawWithCache {

                        val path = Path().apply {
                            addOutline(ConnectorShape.createOutline(size, layoutDirection, this@drawWithCache))
                        }

                        val solidColorBrush = SolidColor(Color.White)

                        val stroke = Stroke(width = density)
                        val stroke2 = Stroke(width = 3 * density)

                        val brushCenter = Offset(width.toPx() / 2, height.toPx() / 2)

                        onDrawBehind {

                            drawPath(
                                path = path,
                                style =  when {
                                    isActive -> Fill
                                    isSelected -> stroke2
                                    else -> stroke
                                },
                                brush = if (isSelected){
                                    Brush.selectionGradient(animation, Color.White, brushCenter)
                                } else {
                                    solidColorBrush
                                }
                            )
                        }
                    }
            )
        }
    }

    @Composable
    fun Name() {
        Text(
            text = name,
            color = Color.White,
            maxLines = 1,
        )
    }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .align(if (isOut) Alignment.End else Alignment.Start)
    ) {
        if (isOut){
            Name()
            Button()
        } else {
            Button()
            Name()
        }
    }
}