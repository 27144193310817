package org.botdesigner.shared.domain.content.bot

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.router.slot.SlotNavigation
import com.arkivanov.decompose.router.slot.activate
import com.arkivanov.decompose.router.slot.childSlot
import com.arkivanov.decompose.router.slot.dismiss
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import dev.icerock.moko.resources.desc.StringDesc
import dev.icerock.moko.resources.desc.desc
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.stateIn
import kotlinx.serialization.Serializable
import org.botdesigner.api.SharedConstants
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.botblueprints.BlueprintType
import org.botdesigner.core.Bot
import org.botdesigner.core.BotStatus
import org.botdesigner.core.isRealtimeUpdatesEnabled
import org.botdesigner.core.tokenForDebug
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.BlueprintsRepository
import org.botdesigner.shared.data.repo.BotsRepository
import org.botdesigner.shared.data.repo.impl.collectAsDataState
import org.botdesigner.shared.domain.CoroutineComponent
import org.botdesigner.shared.domain.SnackbarIdiom
import org.botdesigner.shared.domain.SnackbarMessage
import org.botdesigner.shared.domain.content.AlertConfirmationDialogComponent
import org.botdesigner.shared.domain.content.bot.bpcontext.BlueprintContextSelectionDialogComponent
import org.botdesigner.shared.domain.content.bot.bpcontext.DefaultBlueprintContextSelectionDialogComponent
import org.botdesigner.shared.domain.content.bot.bptype.BlueprintTypeSelectionDialogComponent
import org.botdesigner.shared.domain.content.bot.bptype.DefaultBlueprintTypeSelectionDialogComponent
import org.botdesigner.shared.domain.content.list.DeleteBotComponent
import org.botdesigner.shared.domain.keyFor
import org.botdesigner.shared.util.ErrorHandler
import org.botdesigner.shared.util.FormattedFix
import org.botdesigner.shared.util.UIState
import org.botdesigner.shared.util.dispatchers.Dispatchers
import org.botdesigner.shared.util.intents.ViewUrlIntent
import org.botdesigner.shared.util.isSuccess
import org.botdesigner.shared.util.valueOrElse
import org.botdesigner.shared.util.valueOrNull
import kotlin.coroutines.cancellation.CancellationException

internal class DefaultBotComponent(
    context: ComponentContext,
    private val initialBot: Bot,
    private val onBotDeleted : (bot : Bot) -> Unit,
    private val onNavigateToBlueprint: (BlueprintData) -> Unit,
    private val onNavigateToEdit: (Bot) -> Unit,
    private val onNavigateToLogs: (Bot) -> Unit,
    private val onBack: () -> Unit,
    private val onMessage : (SnackbarMessage) -> Unit,
    private val errorHandler: ErrorHandler,
    private val botsRepository: BotsRepository,
    private val blueprintsRepository: BlueprintsRepository,
    private val viewUrlIntent: ViewUrlIntent,
    private val dispatchers: Dispatchers
) : CoroutineComponent(context, errorHandler = errorHandler), BotComponent {

    private val retryTrigger: MutableSharedFlow<Unit> = instanceKeeper
        .getOrCreateSimple(keyFor(::retryTrigger)) {
            MutableSharedFlow<Unit>()
        }

    private val showProgress: MutableStateFlow<Boolean> = instanceKeeper
        .getOrCreateSimple(keyFor(::showProgress)) {
            MutableStateFlow(false)
        }

    private val bot: StateFlow<UIState<Bot>> =
        instanceKeeper.getOrCreateSimple(keyFor(::bot)) {
            botsRepository
                .get(initialBot.id)
                .flowOn(dispatchers.ioContext())
                .collectAsDataState(
                    retryTrigger = retryTrigger,
                    autoRetry = true,
                    stateIn = componentScope,
                    initial = UIState.Success(initialBot),
                    onError = {
                        errorHandler.handle(it)
                        @Suppress("uninitialized_variable")
                        if (this.bot.value.isSuccess())
                            null
                        else
                            UIState.Error(error = it)
                    }
                )
        }

    private val blueprints: StateFlow<UIState<List<BlueprintData>>> =
        instanceKeeper.getOrCreateSimple(keyFor(::blueprints)) {
            blueprintsRepository
                .getAll(initialBot.id, componentScope)
                .flowOn(dispatchers.ioContext())
                .collectAsDataState(
                    stateIn = componentScope,
                    onError = {
                        errorHandler.handle(it)

                        @Suppress("uninitialized_variable")
                        if (blueprints.value.isSuccess())
                            null
                        else
                            UIState.Error(error = it)
                    }
                )
        }

    private val toggleEnabled: MutableStateFlow<Boolean> =
        instanceKeeper.getOrCreateSimple(keyFor(::toggleEnabled)) {
            MutableStateFlow(true)
        }

    private val contextDialogText: MutableStateFlow<String> =
        instanceKeeper.getOrCreateSimple(keyFor(::contextDialogText)) {
            MutableStateFlow("")
        }

    private val alertDialogNavigation = SlotNavigation<BotAlertDialogConfig>()
    private val componentDialogNavigation = SlotNavigation<BotComponentDialogConfig>()

    override val state: StateFlow<BotViewState> = combine(
        bot, blueprints, toggleEnabled, showProgress
    ) { b, bp, toggle, showP ->
        BotViewState(
            bot = b,
            blueprints = bp,
            toggleEnabled = toggle,
            showProgress = showP
        )
    }.stateIn(componentScope, SharingStarted.Eagerly, BotViewState())

    override val alertDialog: Value<ChildSlot<*, BotComponent.AlertDialog<*>>> = childSlot(
        source = alertDialogNavigation,
        serializer = BotAlertDialogConfig.serializer(),
        handleBackButton = true,
        key = keyFor(::alertDialog),
        childFactory = ::alertDialogFactory,
    )

    override val componentDialog: Value<ChildSlot<*, BotComponent.ComponentDialog<*>>> = childSlot(
        source = componentDialogNavigation,
        serializer = BotComponentDialogConfig.serializer(),
        handleBackButton = true,
        key = keyFor(::componentDialog),
        childFactory = ::componentDialogFactory,
    )


    override fun refresh() {
        retryTrigger.tryEmit(Unit)
    }

    override fun onLogsClicked() {
        onNavigateToLogs(bot.value.valueOrElse { initialBot })
    }

    override fun onEditClicked() {
        onNavigateToEdit(bot.value.valueOrElse { initialBot })
    }

    override fun onDownloadAppClicked() {
        viewUrlIntent.invoke(SharedConstants.DownloadAppUrl)
    }

    override fun onDeleteClicked() {
        bot.value.valueOrNull()?.let {
            alertDialogNavigation.activate(BotAlertDialogConfig.DeleteBot(it))
        }
    }

    override fun onBlueprintSelected(blueprintData: BlueprintData) {
        val bot = bot.value.valueOrNull() ?: return

        when {
            bot.tokenForDebug == null -> {
                alertDialogNavigation.activate(
                    BotAlertDialogConfig.UpdatesDisabled(
                        data = blueprintData,
                        reason = BotAlertDialogConfig.UpdatesDisabled.Reason.TokenNotConfigured
                    )
                )
            }
            !bot.isRealtimeUpdatesEnabled -> {
                alertDialogNavigation.activate(
                    BotAlertDialogConfig.UpdatesDisabled(
                        data = blueprintData,
                        reason = BotAlertDialogConfig.UpdatesDisabled.Reason.BotIsRunning
                    )
                )
            }
            else -> onNavigateToBlueprint(blueprintData)
        }
    }

    override fun onBlueprintDelete(blueprint: BlueprintData) {
        alertDialogNavigation.activate(BotAlertDialogConfig.DeleteBlueprint(blueprint))
    }

    override fun onBack() {
        onBack.invoke()
    }

    override fun onNewBlueprintClicked() {
        componentDialogNavigation.activate(BotComponentDialogConfig.TypeSelection)
    }

    override fun onToggle() {
        dispatchers.launchIO(componentScope) {
            try {
                toggleEnabled.emit(false)
                bot.value.valueOrNull()?.let {
                    val newStatus = if (it.status == BotStatus.Stopped)
                        BotStatus.Running else BotStatus.Stopped
                    botsRepository.toggle(it.id, newStatus)
                }
            } finally {
                toggleEnabled.tryEmit(true)
            }
        }
    }

    override fun onComponentDialogDismissed() {
        componentDialogNavigation.dismiss()
    }

    override fun onBlueprintEditClicked(blueprint: BlueprintData) {
        if (blueprint.type.contextName != null) {
            alertDialogNavigation.activate(
                BotAlertDialogConfig.ContextSelection(
                    data = blueprint, isUpdate = true
                )
            )
        }
    }

    private fun createAndNavigateToBlueprint(
        blueprint: BlueprintData,
    ) {
        val bot = bot.value.valueOrNull() ?: return

        dispatchers.launchIO(componentScope) {
            showProgress.emit(true)
            val res = blueprintsRepository.add(bot.id, blueprint)
            if (bot.status != BotStatus.Running) {
                onNavigateToBlueprint(res)
            }
        }.invokeOnCompletion {
            showProgress.tryEmit(false)
            if (it != null && it !is CancellationException) {
                onMessage(SnackbarMessage.NetworkError)
            }
        }
    }

    private fun updateBlueprintContext(
        blueprint: BlueprintData
    ) {
        dispatchers.launchIO(componentScope) {
            blueprintsRepository.update(initialBot.id, blueprint)
        }
    }

    private fun alertDialogFactory(
        config: BotAlertDialogConfig,
        context: ComponentContext
    ): BotComponent.AlertDialog<*> = when (config) {
        is BotAlertDialogConfig.ContextSelection -> BotComponent.AlertDialog.ContextSelection(
            contextSelectionDialogComponent(context, config)
        )

        is BotAlertDialogConfig.DeleteBlueprint -> BotComponent.AlertDialog.DeleteBlueprint(
            deleteBlueprintDialogComponent(context, config)
        )

        is BotAlertDialogConfig.DeleteBot -> BotComponent.AlertDialog.DeleteBot(
            deleteBotComponent(context, config)
        )

        is BotAlertDialogConfig.UpdatesDisabled -> BotComponent.AlertDialog.BotIsRunning(
            updatesDisabledDialogComponent(context, config)
        )
    }

    private fun componentDialogFactory(
        config: BotComponentDialogConfig,
        context: ComponentContext
    ): BotComponent.ComponentDialog<*> = when (config) {

        BotComponentDialogConfig.TypeSelection -> BotComponent.ComponentDialog.BlueprintTypeSelection(
            blueprintTypeSelectionComponent(context)
        )
    }

    private fun contextSelectionDialogComponent(
        context: ComponentContext,
        config: BotAlertDialogConfig.ContextSelection
    ): BlueprintContextSelectionDialogComponent = DefaultBlueprintContextSelectionDialogComponent(
        context = context,
        data = config.data,
        input = contextDialogText,
        onInputChanged = contextDialogText::tryEmit,
        onCancel = alertDialogNavigation::dismiss,
        onConfirm = {
            alertDialogNavigation.dismiss()

            if (config.isUpdate) {
                updateBlueprintContext(it)
            } else {
                createAndNavigateToBlueprint(it)
            }
        }
    )

    private fun deleteBlueprintDialogComponent(
        context: ComponentContext,
        config: BotAlertDialogConfig.DeleteBlueprint
    ): AlertConfirmationDialogComponent =
        AlertConfirmationDialogComponent.Default(
            onConfirm = {
                alertDialogNavigation.dismiss()

                dispatchers.launchIO(componentScope) {
                    blueprintsRepository.delete(
                        botId = bot.value.valueOrNull()?.id ?: return@launchIO,
                        id = config.data.id
                    )
                }.invokeOnCompletion {
                    if (it != null && it !is CancellationException) {
                        onMessage(SnackbarMessage.NetworkError)
                    }
                    if (it == null){
                        onMessage(
                            SnackbarMessage(
                                idiom = SnackbarIdiom.Success,
                                message = SharedRes.strings.delete_blueprint_success.desc()
                            )
                        )
                    }
                }
            },
            confirm = SharedRes.strings.delete.desc(),
            isDestructive = true,
            onCancel = alertDialogNavigation::dismiss,
            message = StringDesc.FormattedFix(
                SharedRes.strings.confirm_delete_blueprint,
                config.data.type.title
            )
        )

    private fun deleteBotComponent(
        context: ComponentContext,
        config: BotAlertDialogConfig.DeleteBot
    ): AlertConfirmationDialogComponent = DeleteBotComponent(
        bot = config.bot,
        onConfirm = {
            showProgress.value = true
            dispatchers.launchIO(componentScope) {
                botsRepository.delete(config.bot.id)
                onBotDeleted(config.bot)
            }.invokeOnCompletion {
                alertDialogNavigation.dismiss()
                showProgress.value = false
                if (it != null && it !is CancellationException) {
                    onMessage(SnackbarMessage.NetworkError)
                }
            }
        },
        onCancel = alertDialogNavigation::dismiss
    )

    private fun updatesDisabledDialogComponent(
        context: ComponentContext,
        config: BotAlertDialogConfig.UpdatesDisabled
    ): AlertConfirmationDialogComponent = AlertConfirmationDialogComponent.Default(
        title = config.reason.title,
        message = config.reason.message,
        confirm = SharedRes.strings.action_continue.desc(),
        onCancel = {
            alertDialogNavigation.dismiss()
        },
        onConfirm = {
            alertDialogNavigation.dismiss()
            onNavigateToBlueprint(config.data)
        }
    )

    @OptIn(ExperimentalStdlibApi::class)
    private fun blueprintTypeSelectionComponent(
        context: ComponentContext
    ): BlueprintTypeSelectionDialogComponent =
        DefaultBlueprintTypeSelectionDialogComponent(
            context = context,
            blueprintTypes = kotlin.run {
                val bot = state.value.bot.valueOrNull()
                    ?: return@run emptyList()
                val blueprints = state.value.blueprints.valueOrNull()
                    ?: return@run emptyList()

                val types = BlueprintType.entries.filter { type ->
                    bot.type in type.bots
                }

                types.map {
                    it to (it.multiple || blueprints.any { bp -> bp.type == it }.not())
                }
            },
            botType = initialBot.type,
            onRequestContext = {
                alertDialogNavigation.activate(
                    BotAlertDialogConfig.ContextSelection(
                        it,
                        isUpdate = false
                    )
                )
            },
            onCreateBlueprint = {
                componentDialogNavigation.dismiss()
                createAndNavigateToBlueprint(it)
            },
            onCancel = componentDialogNavigation::dismiss
        )

    @Serializable
    private sealed interface BotAlertDialogConfig {
        @Serializable
        class ContextSelection(val data: BlueprintData, val isUpdate: Boolean) :
            BotAlertDialogConfig

        @Serializable
        class DeleteBlueprint(val data: BlueprintData) : BotAlertDialogConfig

        @Serializable
        class DeleteBot(val bot: Bot) : BotAlertDialogConfig

        @Serializable
        class UpdatesDisabled(val data: BlueprintData, val reason : Reason) : BotAlertDialogConfig {

            enum class Reason(
                val title : StringDesc,
                val message : StringDesc
            ) {
                BotIsRunning(
                    title = SharedRes.strings.warning_bot_is_active.desc(),
                    message = SharedRes.strings.warning_bot_is_active_desc.desc()
                ),
                TokenNotConfigured(
                    title = SharedRes.strings.warning_no_bot_token.desc(),
                    message = SharedRes.strings.warning_no_bot_token_desc.desc()
                )
            }
        }
    }

    @Serializable
    private sealed interface BotComponentDialogConfig {

        @Serializable
        data object TypeSelection : BotComponentDialogConfig
    }
}