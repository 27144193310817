@file:OptIn(ExperimentalAdaptiveApi::class)

package org.botdesigner.ui.screens.guide

import androidx.compose.animation.core.AnimationVector
import androidx.compose.animation.core.AnimationVector2D
import androidx.compose.animation.core.FiniteAnimationSpec
import androidx.compose.animation.core.MutableTransitionState
import androidx.compose.animation.core.Transition
import androidx.compose.animation.core.TwoWayConverter
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.animateValue
import androidx.compose.animation.core.updateTransition
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Undo
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Done
import androidx.compose.material.icons.filled.Terminal
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.BiasAlignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalHapticFeedback
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.unit.dp
import dev.icerock.moko.resources.compose.readTextAsState
import io.github.alexzhirkevich.compottie.LottieAnimation
import io.github.alexzhirkevich.compottie.LottieCancellationBehavior
import io.github.alexzhirkevich.compottie.LottieComposition
import io.github.alexzhirkevich.compottie.LottieCompositionSpec
import io.github.alexzhirkevich.compottie.animateLottieCompositionAsState
import io.github.alexzhirkevich.compottie.rememberLottieComposition
import io.github.alexzhirkevich.cupertino.CupertinoHapticFeedback
import io.github.alexzhirkevich.cupertino.InternalCupertinoApi
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveButton
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.cupertinoTween
import io.github.alexzhirkevich.cupertino.section.CupertinoSection
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.BlueprintManagerImpl
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.InputConnector
import org.botdesigner.blueprint.components.OutputConnector
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.BpTrigger
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.stdlib.functions.long.BpLongSub
import org.botdesigner.blueprint.stdlib.functions.special.BpBranch
import org.botdesigner.blueprint.stdlib.functions.string.BpStringCharAt
import org.botdesigner.blueprint.stdlib.pins.string
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.blueprint.ui.BlueprintWidget
import org.botdesigner.blueprint.ui.components.BlueprintDemo
import org.botdesigner.blueprint.ui.components.BlueprintPreview
import org.botdesigner.shared.domain.LocalInterfaceIdiom
import org.botdesigner.shared.domain.content.guides.BlueprintGuidePage
import org.botdesigner.shared.domain.content.guides.GuideComponent
import org.botdesigner.sharedui.MR
import org.botdesigner.ui.common.PlatformDialogAnimationDuration
import org.botdesigner.ui.screens.profile.EditorLink
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable


@OptIn(ExperimentalFoundationApi::class, ExperimentalAdaptiveApi::class)
@Composable
internal fun BlueprintGuideScreen(
    component: GuideComponent<BlueprintGuidePage>,
    modifier: Modifier = Modifier,
) {
    val successLottie = MR.assets.done.readTextAsState().value?.let {
        rememberLottieComposition(LottieCompositionSpec.JsonString(it))
    }

    val connectorsManager = remember {
        BlueprintManagerImpl(
            isDragEnabled = false,
            isScaleEnabled = false,
        ).apply {
            load(
                Blueprint(
                    elements = listOf(
                        ExampleTrigger(coordinate = Point(-170f, -50f)),
                        ExampleProcedure(coordinate = Point(20f, -50f)),
                    )
                )
            )
        }
    }

    val isConnectorsComplete = connectorsManager.connectorLines.isNotEmpty()
    val connectorsLottieProgress = animateLottieCompositionAsState(
        composition = successLottie?.value,
        isPlaying = isConnectorsComplete,
        cancellationBehavior = LottieCancellationBehavior.OnIterationFinish
    )
    val isConnectorsAnimationComplete =
        connectorsLottieProgress.progress > .5f && isConnectorsComplete


    val pinsManager = remember {
        BlueprintManagerImpl(
            isDragEnabled = false,
            isScaleEnabled = false,
        ).apply {
            load(
                Blueprint(
                    elements = listOf(
                        ExampleFunction(coordinate = Point(-200f, -100f)),
                        ExampleProcedure(
                            id = Id("1"),
                            pins = Pins(Id("1")) {
                                input {
                                    string("Input String", required = true)
                                }
                            },
                            coordinate = Point(10f, -50f)
                        ),
                    )
                )
            )
        }
    }

    val isPinsComplete = pinsManager.pinLines.isNotEmpty()
    val pinsLottieProgress = animateLottieCompositionAsState(
        composition = successLottie?.value,
        isPlaying = isPinsComplete,
        cancellationBehavior = LottieCancellationBehavior.OnIterationFinish
    )
    val isPinsAnimationComplete = pinsLottieProgress.progress > .5f && isPinsComplete

    val haptic = LocalHapticFeedback.current

    val nodesTransitionState = remember {
        MutableTransitionState(false).apply {
            targetState = true
        }
    }


    @Composable
    fun hapticListener(value: Boolean) {
        LaunchedEffect(value) {
            if (value) {
                @OptIn(InternalCupertinoApi::class)
                haptic.performHapticFeedback(CupertinoHapticFeedback.Success)
            }
        }
    }

    hapticListener(isConnectorsComplete)
    hapticListener(isPinsComplete)

    LaunchedEffect(isConnectorsAnimationComplete) {
        if (isConnectorsAnimationComplete) {
            val index = component.pages.indexOf(BlueprintGuidePage.Connectors) + 1
            if (component.pagerState.currentPage < index) {
                component.pagerState.animateScrollToPage(index)
            }
        }
    }

    LaunchedEffect(isPinsAnimationComplete) {
        if (isPinsAnimationComplete) {

            val index = component.pages.indexOf(BlueprintGuidePage.Pins) + 1

            if (component.pagerState.currentPage < index) {
                component.pagerState.animateScrollToPage(index)
            }
        }
    }


    val isBlockedForwardByConnectors =
        component.pages[component.pagerState.currentPage] == BlueprintGuidePage.Connectors &&
                !isConnectorsComplete

    val isBlockedForwardByPins =
        component.pages[component.pagerState.currentPage] == BlueprintGuidePage.Pins &&
                !isPinsComplete

    GuideScreen(
        modifier = modifier,
        component = component,
        canMoveForward = !isBlockedForwardByConnectors && !isBlockedForwardByPins,
        title = {
            if (it == BlueprintGuidePage.Finish) {
                AdaptiveButton(
                    modifier = Modifier.pointerHoverIcon(PointerIcon.PlatformClickable),
                    onClick = component::onCloseClicked,
                ) {
                    Text(string(it.title))
                }
            } else {
                Text(string(it.title))
            }
        },

        illustration = {
            when (it) {
                BlueprintGuidePage.Node -> NodeIllustration(nodesTransitionState)
                BlueprintGuidePage.Connectors -> {
                    BlueprintIllustration(
                        manager = connectorsManager,
                        lottie = successLottie?.value?.takeIf { isConnectorsComplete },
                        progress = { connectorsLottieProgress.value }
                    )
                }

                BlueprintGuidePage.Pins -> {
                    BlueprintIllustration(
                        manager = pinsManager,
                        lottie = successLottie?.value?.takeIf { isPinsComplete },
                        progress = { pinsLottieProgress.value }
                    )
                }

                BlueprintGuidePage.Store -> StoreIllustration()
                BlueprintGuidePage.Finish -> FinishIllustration(successLottie?.value)
            }
        },
    )
}

private fun <T> nodePageTransition() = cupertinoTween<T>(
    durationMillis = 1000,
    delayMillis = PlatformDialogAnimationDuration/2
)

@Composable
private fun Transition<Boolean>.animateBiasAlignment(
    targetValue : BiasAlignment,
    animationSpec: FiniteAnimationSpec<BiasAlignment> = nodePageTransition(),
) = animateValue(
    typeConverter = TwoWayConverter<BiasAlignment, AnimationVector2D>(
        convertFromVector = {
            BiasAlignment(it.v1, it.v2)
        },
        convertToVector = {
            AnimationVector(it.horizontalBias, it.verticalBias)
        }
    ),
    transitionSpec = { animationSpec },
    targetValueByState = {
        if (it)
            targetValue
        else
            BiasAlignment(0f,0f)
    }
)

@Composable
private fun BoxScope.NodeIllustration(
    transitionState: MutableTransitionState<Boolean>
) {

    val settledTransition = updateTransition(transitionState)

    val expAlignment by settledTransition.animateBiasAlignment(
        BiasAlignment(0f, -1f)
    )

    val splitAlignment by settledTransition.animateBiasAlignment(
        BiasAlignment(-1f, .1f)
    )

    val branchAlignment by settledTransition.animateBiasAlignment(
        BiasAlignment(.9f, .8f)
    )

    val scale by settledTransition.animateFloat(
        transitionSpec = { nodePageTransition() }
    ) {
        if (it) .75f else .25f
    }

    BlueprintPreview {


        val exp = remember {
            BpLongSub().createHolder(it)
        }

        val split = remember {
            BpStringCharAt().createHolder(it)
        }

        val branch = remember {
            BpBranch().createHolder(it)
        }

        exp.Draw(
            modifier = Modifier
                .align(expAlignment)
                .graphicsLayer {
                    scaleX = scale
                    scaleY = scale
                },
            fieldSize = Float.MAX_VALUE,
            shape = MaterialTheme.shapes.medium
        )

        split.Draw(
            modifier = Modifier
                .align(splitAlignment)
                .graphicsLayer {
                    scaleX = scale
                    scaleY = scale
                },
            fieldSize = Float.MAX_VALUE,
            shape = MaterialTheme.shapes.medium
        )
        branch.Draw(
            modifier = Modifier
                .align(branchAlignment)
                .graphicsLayer {
                    scaleX = scale
                    scaleY = scale
                },
            fieldSize = Float.MAX_VALUE,
            shape = MaterialTheme.shapes.medium
        )
    }
}

@Composable
private fun BoxScope.BlueprintIllustration(
    manager: BlueprintManager,
    lottie : LottieComposition?,
    progress : () -> Float,
) {

    BlueprintDemo {
        BlueprintWidget(
            manager = manager,
            backgroundColor = Color.Transparent,
            fieldSize = 30000.dp,
            gridVisible = false,
            borderVisible = false
        )
    }

    SuccessLottie(lottie, progress)
}

@Composable
private fun ButtonSample(
    label : String,
    button : @Composable () -> Unit,
){
    Column(
        modifier = Modifier.height(100.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Spacer(Modifier.weight(1f))
        button()
        Spacer(Modifier.weight(1f))
        Text(label)
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
private fun BoxScope.StoreIllustration() {
    FlowRow(
        modifier = Modifier
            .align(Alignment.Center),
        verticalArrangement = Arrangement
            .spacedBy(Dimens.Spacing.Large, Alignment.CenterVertically),
        horizontalArrangement = Arrangement
            .spacedBy(Dimens.Spacing.Large, Alignment.CenterHorizontally)
    ) {

        ButtonSample(
            "Node Store"
        ){
            FloatingActionButton(
                onClick = {},
            ) {
                Icon(
                    imageVector = Icons.Default.Add,
                    contentDescription = null
                )
            }
        }
        ButtonSample(
            "Terminal"
        ){
            IconButton(
                onClick = {},
            ) {
                Icon(
                    imageVector = Icons.Default.Terminal,
                    contentDescription = null
                )
            }
        }
        ButtonSample(
            "Save"
        ){
            IconButton(
                onClick = {},
            ) {
                Icon(
                    imageVector = Icons.Default.Done,
                    contentDescription = null
                )
            }
        }
        ButtonSample(
            "Undo"
        ){
            IconButton(
                onClick = {},
            ) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.Undo,
                    contentDescription = null
                )
            }
        }
    }

    Box(Modifier.matchParentSize().pointerInput(0) {})
}

@Composable
private fun BoxScope.FinishIllustration(
    lottie : LottieComposition?,
) {

    val idiom = LocalInterfaceIdiom.current

    CupertinoSection(
        modifier = Modifier
            .widthIn(max = 400.dp)
            .align(Alignment.Center),
    ) {
        EditorLink(
            idiom = idiom,
            selected = false,
            onClick = {}
        )
    }

//    val progress by animateLottieCompositionAsState(lottie)
//
//    LottieAnimation(
//        progress = {
//            progress.coerceAtMost(24f/84)
//        },
//        composition = lottie,
//        modifier = Modifier
//            .size(LottieSize)
//            .align(Alignment.Center)
//    )
}

private val LottieSize = 175.dp

@Composable
private fun BoxScope.SuccessLottie(
    composition: LottieComposition?,
    progress: () -> Float
){
    LottieAnimation(
        progress = progress,
        composition = composition,
        modifier = Modifier
            .size(LottieSize)
            .align(Alignment.BottomCenter)
            .offset(y = LottieSize /2)
    )
}

private fun ExampleFunction(
    coordinate: Point = Point.Zero,
    id: Id = Id.uuid(),
    pins: List<Pin<Any?>>  = Pins(id) {
        output {
            string("Out String")
        }
    },
) : BpFunction = object : BpFunction(){
    override val coordinate: Point = coordinate
    override val id: Id = id
    override val pins: List<Pin<Any?>> = pins
    override val factory: FunctionFactory = ::ExampleFunction
    override val name: String
        get() = "Function"

    override val summary: AnnotatedString
        get() = "This is node description. Here you can read what this node do, which arguments it takes and other useful information".toAnnotatedString()

    override suspend fun calculate(input: List<*>): List<*> {
        return emptyList<Any?>()
    }
}

private fun ExampleTrigger(
    coordinate: Point = Point.Zero,
    id: Id = Id.uuid(),
    pins: List<Pin<Any?>> = emptyList(),
    connectors: List<Connector> = listOf(OutputConnector(id)),
) : BpTrigger<Any?> = object : BpTrigger<Any?>(){
    override val coordinate: Point = coordinate
    override val id: Id = id
    override val pins: List<Pin<Any?>> = pins
    override val connectors: List<Connector> = connectors
    override val factory: ProcedureFactory<in Any?> = ::ExampleTrigger
    override val name: String
        get() = "Trigger    "

    override val summary: AnnotatedString
        get() = "This is node description. Here you can read what this node do, which arguments it takes and other useful information".toAnnotatedString()
    override suspend fun execute(
        input: List<*>,
        context: Any?,
        pool: BlueprintNodesPool,
    ): Pair<Connector?, List<*>> {
        return outputConnectors.first() to emptyList<Any?>()
    }
}

private fun ExampleProcedure(
    coordinate: Point = Point.Zero,
    id: Id = Id.uuid(),
    pins: List<Pin<Any?>> = emptyList(),
    connectors: List<Connector> = listOf(InputConnector(id)),
) : BpProcedure<Any?> = object : BpProcedure<Any?>(){
    override val coordinate: Point = coordinate
    override val id: Id = id
    override val pins: List<Pin<Any?>> = pins
    override val connectors: List<Connector> = connectors

    override val factory: ProcedureFactory<in Any?> = ::ExampleProcedure
    override val name: String
        get() = "Procedure"

    override val summary: AnnotatedString
        get() = "This is node description. Here you can read what this node do, which arguments it takes and other useful information".toAnnotatedString()
    override suspend fun execute(
        input: List<*>,
        context: Any?,
        pool: BlueprintNodesPool,
    ): Pair<Connector?, List<*>> {
        return outputConnectors.first() to emptyList<Any?>()
    }
}