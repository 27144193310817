@file:Suppress("NOTHING_TO_INLINE", "FunctionName")

package org.botdesigner.blueprint.stdlib.functions.float

import org.botdesigner.blueprint.components.BlueprintExpressions
import org.botdesigner.blueprint.generator.BlueprintExpression
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.store.BlueprintNodeCategory
import kotlin.math.roundToLong
import kotlin.random.Random

@BlueprintExpression(
    expression = BlueprintExpressions.Equals,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Equals",
    summary = "Check if 2 float numbers are equal",
    serialName = "BpDoubleEquals"
)
inline fun DoubleEquals(@Pin("") a : Double?, @Pin("") b : Double?) :  Boolean {
    return a == b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Sum,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Sum",
    summary = "Sum a bunch of float numbers",
    serialName = "BpDoubleSum"
)
inline fun DoubleSum(  vararg values : Double) :  Double {
    return values.sum()
}

@BlueprintExpression(
    expression = BlueprintExpressions.Sub,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Subtract",
    summary = "Subtract second float number from the first",
    serialName = "BpDoubleSub"
)
inline fun DoubleSub(@Pin("") a : Double, @Pin("") b : Double) :  Double {
    return a - b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Mul,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Multiply",
    summary = "Multiply first float number by second",
    serialName = "BpDoubleMul"
)
inline fun DoubleMul(@Pin("") a : Double, @Pin("") b : Double) :  Double {
    return a * b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Div,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Divide",
    summary = "Divide first float number by second. Result is rounded downwards",
    serialName = "BpDoubleDiv"
)
inline fun DoubleDiv(@Pin("") a : Double, @Pin("") b : Double) :  Double {
    return a / b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Mod,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Mod",
    summary = "Remainder of the division",
    serialName = "BpDoubleMod"
)
inline fun DoubleMod(@Pin("") a : Double, @Pin("") b : Double) :  Double {
    return a % b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Greater,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Greater",
    summary = "Check if first 1st number is greater than 2nd. Flip pins to revert expression (make Less)",
    serialName = "BpDoubleGreater"
)
inline fun DoubleGreater(@Pin("") a : Double, @Pin("") b : Double) :  Boolean {
    return a > b
}


@BlueprintExpression(
    expression = BlueprintExpressions.GreaterOrEquals,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Greater Or Equals",
    summary = "Check if first 1st number is greater than 2nd or equal to. Flip pins to revert expression (make Less Or Equals)",
    serialName = "BpDoubleGreaterOrEquals"
)
inline fun DoubleGreaterOrEquals(@Pin("") a : Double, @Pin("") b : Double) :  Boolean {
    return a >= b
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "To String",
    summary = "Get string representation of the float number",
    serialName = "BpDoubleToString"
)
inline fun DoubleToString( @Pin("") a : Double?) :  String {
    return a.toString()
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "To Integer",
    summary = "Convert float number to the closest integer downwards",
    serialName = "BpDoubleToFloat"
)
inline fun DoubleToFloat( @Pin("") a : Double?) :  Long? {
    return a?.toLong()
}

@BlueprintExpression(
    expression = BlueprintExpressions.Cast,
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Round To Integer",
    summary = "Convert float number to the closes integer number",
    serialName = "BpDoubleToFloatRound"
)
inline fun DoubleToFloatRound( @Pin("") a : Double?) :  Long? {
    return a?.roundToLong()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Float,
    displayName = "Random",
    summary = "Generate random float number in given range. Random is NOT cryptographically strong. <b>Until</b> must be greater than <b>From</b>. <b>From</b> defaults to 0, <b>Until</b> defaults to ${Double.MAX_VALUE}",
    serialName = "BpDoubleRandom"
)
inline fun DoubleRandom(From : Double?, Until : Double?) :  Double {
    return Random.nextDouble(From ?: 0.0, Until ?: Double.MAX_VALUE)
}

