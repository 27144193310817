package org.botdesigner.shared.domain.content.subscription

import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import io.github.alexzhirkevich.onetap.NativeViewController
import kotlinx.coroutines.flow.StateFlow
import org.botdesigner.api.SubscriptionType
import org.botdesigner.shared.util.managers.Subscription

@Immutable
data class SubscriptionViewState(
    val isLoading : Boolean = false,
    val isHelpVisible : Boolean = false,
    val currentSubscriptionType : SubscriptionType? = null,
    val selectedOffering : Subscription? = null,
    val manageSubscriptionAvailable : Boolean = false,
    val availableOfferings : List<Subscription> = emptyList()
)

@Stable
val SubscriptionViewState.isAlreadyPurchased : Boolean
    get() = currentSubscriptionType != null && currentSubscriptionType == selectedOffering?.type

@Stable
interface SubscriptionComponent {

    val state : StateFlow<SubscriptionViewState>

    fun onOfferingSelected(offering: Subscription)

    fun onSubscribeClicked(nativeViewController: NativeViewController)

    fun onHelpClicked()

    fun onCloseClicked()
}
