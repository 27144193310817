package io.github.alexzhirkevich.onetap

interface OIDCResult {
    val code: String?
    val idToken: String?
    val state: String?

    companion object {
        fun parse(uri: String, expectedState : String? = null) : OIDCResult {
            val params = uri.queryParameters

            val receivedState = params["state"]

            check(expectedState == null || receivedState == expectedState){
                "Incorrect state received. Expected: '$expectedState', got: '$receivedState'"
            }

            return OIDCResult(
                code = params["code"],
                idToken = params["id_token"],
                state = receivedState
            )
        }
    }
}

fun OIDCResult(
    code : String? = null,
    idToken: String? = null,
    state: String? = null,
) : OIDCResult = OIDCResultImpl(
    code = code, idToken = idToken, state = randomState()
)

private class OIDCResultImpl(
    override val code : String?,
    override val idToken : String?,
    override val state: String?
) : OIDCResult {

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as OIDCResultImpl

        if (code != other.code) return false
        if (idToken != other.idToken) return false
        if (state != other.state) return false

        return true
    }

    override fun hashCode(): Int {
        var result = code?.hashCode() ?: 0
        result = 31 * result + (idToken?.hashCode() ?: 0)
        result = 31 * result + (state?.hashCode() ?: 0)
        return result
    }

    override fun toString(): String {
        return "OIDCResultImpl(code=$code, idToken=$idToken, state=$state)"
    }
}
