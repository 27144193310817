@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.datetime

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpDecomposeTimestamp")
public class BpDecomposeTimestamp(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.DateTimePinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Timestamp",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.EnumPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Time Zone",
              isOut = i,
              required = true
          ).copy(availableValues =
          org.botdesigner.blueprint.stdlib.functions.datetime.TimeZone.values().map(Enum<*>::name))
                  }
          }
         
          output {
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Year",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Month",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Day Of Year",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Day Of Month",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Day Of Week",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Hour",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Minute",
              isOut = i,
              required = true
          )
      }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Second",
              isOut = i,
              required = true
          )
      }
      }
      },
) : BpFunction() {
  override val summary: AnnotatedString
    get() =
        "Get <b>Timestamp</b> components in desired <b>Time Zone</b>. Day of week is in ISO format (1 - Monday, 7 - Sunday)".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4284132730)

  override val name: String
    get() = "Decompose Timestamp"

  override val factory: FunctionFactory
    get() = ::BpDecomposeTimestamp

  override suspend fun calculate(input: List<Any?>): List<Any?> = DecomposeTimestamp(
  		requireNotNull(input[0] as kotlinx.datetime.Instant?) { 
  "Timestamp can't be null" 
  },
  		org.botdesigner.blueprint.stdlib.functions.datetime.TimeZone.valueOf(input[1] as kotlin.String)
  	).values
}
