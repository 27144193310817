@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.special

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Pair
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpProcedure
import org.botdesigner.blueprint.components.functions.ProcedureFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpFor")
public class BpFor(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "From",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Until",
              isOut = i,
              required = true
          )
                  }
      pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "Step",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.LongPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "N",
                  isOut = i,
                  required = true
              )
          }
      }
      },
  override val connectors: List<Connector> = Connectors(id){ 
          input()
          output("End")
          output("Loop")
      },
) : BpProcedure<@Contextual Any?>() {
  override val summary: AnnotatedString
    get() =
        "Run For <b>Loop</b> starting from <b>Start</b> until <b>End</b> with <b>Step</b> (1 by default)".trimIndent().toAnnotatedString()

  override val color: Color
    get() = androidx.compose.ui.graphics.Color(4287137928)

  override val factory: ProcedureFactory<@Contextual Any?>
    get() = ::BpFor

  override suspend fun execute(
    input: List<Any?>,
    context: @Contextual Any?,
    pool: BlueprintNodesPool,
  ): Pair<Connector, List<Any?>> = outputConnectors[0] to context.run { listOf(For(
  		requireNotNull(input[0] as kotlin.Long?) { 
  "From can't be null" 
  },
  		requireNotNull(input[1] as kotlin.Long?) { 
  "Until can't be null" 
  },
  		input[2] as kotlin.Long?,
  		                    {
                          results = listOf(it)
  outputConnectors[1].invokeNext(context, pool)
                      }
  	))}
}
