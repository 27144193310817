@file:Suppress("NOTHING_TO_INLINE", "FunctionName")


package org.botdesigner.blueprint.stdlib.functions.datetime

import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.UtcOffset
import kotlinx.datetime.asTimeZone
import kotlinx.datetime.isoDayNumber
import kotlinx.datetime.number
import kotlinx.datetime.plus
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.generator.Tuple
import org.botdesigner.blueprint.generator.Tuple8
import org.botdesigner.blueprint.store.BlueprintNodeCategory
import kotlin.js.JsName

internal enum class TimeZone(val offset : Int) {
    UTC(0),

    @JsName("Utc_Plus_1")
    UTC1(1),
    @JsName("Utc_Plus_2")
    UTC2(2),
    @JsName("Utc_Plus_3")
    UTC3(3),
    @JsName("Utc_Plus_4")
    UTC4(4),
    @JsName("Utc_Plus_5")
    UTC5(5),
    @JsName("Utc_Plus_6")
    UTC6(6),
    @JsName("Utc_Plus_7")
    UTC7(7),
    @JsName("Utc_Plus_8")
    UTC8(8),
    @JsName("Utc_Plus_9")
    UTC9(9),
    @JsName("Utc_Plus_10")
    UTC10(10),
    @JsName("Utc_Plus_11")
    UTC11(11),

    @JsName("Utc_Minus_1")
    `UTC-1`(-1),
    @JsName("Utc_Minus_2")
    `UTC-2`(-2),
    @JsName("Utc_Minus_3")
    `UTC-3`(-3),
    @JsName("Utc_Minus_4")
    `UTC-4`(-4),
    @JsName("Utc_Minus_5")
    `UTC-5`(-5),
    @JsName("Utc_Minus_6")
    `UTC-6`(-6),
    @JsName("Utc_Minus_7")
    `UTC-7`(-7),
    @JsName("Utc_Minus_8")
    `UTC-8`(-8),
    @JsName("Utc_Minus_9")
    `UTC-9`(-9),
    @JsName("Utc_Minus_10")
    `UTC-10`(-10),
    @JsName("Utc_Minus_11")
    `UTC-11`(-11),
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.DateTime,
    displayName = "Current Timestamp",
    summary = "Current UNIX epoch timestamp",
    serialName = "BpCurrentTimestamp"
)
internal inline fun CurrentTimestamp():  Instant {
    return Clock.System.now()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.DateTime,
    displayName = "Current Timestamp Seconds",
    summary = "Current UNIX timestamp in epoch seconds",
    serialName = "BpCurrentTimestampSeconds"
)
internal inline fun CurrentTimestampSeconds():  Long {
    return Clock.System.now().epochSeconds
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.DateTime,
    displayName = "Decompose Timestamp",
    summary = "Get <b>Timestamp</b> components in desired <b>Time Zone</b>. Day of week is in ISO format (1 - Monday, 7 - Sunday)",
    serialName = "BpDecomposeTimestamp"
)
internal inline fun DecomposeTimestamp(Timestamp : Instant, `Time Zone`: TimeZone):
Tuple8<
    @Pin("Year") Long,
    @Pin("Month") Long,
    @Pin("Day Of Year") Long,
    @Pin("Day Of Month") Long,
    @Pin("Day Of Week") Long,
    @Pin("Hour") Long,
    @Pin("Minute") Long,
    @Pin("Second") Long
> {
    val date = Timestamp.toLocalDateTime(
        timeZone = UtcOffset(hours = `Time Zone`.offset).asTimeZone()
    )

    return Tuple(
        date.year.toLong(),
        date.month.number.toLong(),
        date.dayOfYear.toLong(),
        date.dayOfMonth.toLong(),
        date.dayOfWeek.isoDayNumber.toLong(),
        date.hour.toLong(),
        date.minute.toLong(),
        date.second.toLong(),
    )
}

@PublishedApi
internal const val SecondsInMinute = 60
@PublishedApi
internal const val SecondsInHour = SecondsInMinute * 60

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.DateTime,
    displayName = "Shift By",
    summary = "Shift timestamp by a time duration (add minutes, hours, days, etc..). Set negative values to shift backwards",
    serialName = "BpCurrentTimestampAdd"
)
internal inline fun CurrentTimestampAdd(
    Timestamp: Instant,
    Years : Long?,
    Months : Long?,
    Days : Long?,
    Hours : Long?,
    Minutes : Long?,
    Seconds : Long?,
):  Instant {
    val datetime =
        Instant
            .fromEpochSeconds(Timestamp.epochSeconds)
            .toLocalDateTime(kotlinx.datetime.TimeZone.UTC)

    val newDate = datetime
        .date
        .plus(
            DatePeriod(
                years = Years?.toInt() ?: 0,
                months = Months?.toInt() ?: 0,
                days = (Days?.toInt() ?: 0)
            )
        )

    val newDateTime = LocalDateTime(
        date = newDate,
        time = datetime.time
    ).toInstant(kotlinx.datetime.TimeZone.UTC)

    val seconds = newDateTime.epochSeconds +
            (Hours?.toInt() ?: 0) * SecondsInHour +
            (Minutes?.toInt() ?: 0) * SecondsInMinute +
            (Seconds?.toInt() ?: 0)

    return Instant.fromEpochSeconds(seconds)
}

