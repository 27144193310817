package org.botdesigner.blueprint.integrations.functions.custom

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import org.botdesigner.blueprint.components.BlueprintNodesPool
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.InputConnector
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.ProcedureFactory

@Serializable
@SerialName("BpCustomProcedureReturn")
class BpCustomProcedureReturn(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = emptyList(),
    override val connectors: List<Connector> = listOf(InputConnector(id)),
) : BpReturn<Any?>() {

    override val name: String get() = "Return"

    @Transient
    private var pinValues : List<Any?> = emptyList()

    override val factory: ProcedureFactory<Any?>
        get() = ::BpCustomProcedureReturn

    override fun value(): List<Any?> {
        return pinValues
    }

    override suspend fun execute(
        input: List<*>,
        context: Any?,
        pool: BlueprintNodesPool
    ): Pair<Connector?, List<*>> {
        pinValues = input
        return null to emptyList<Any?>()
    }
}