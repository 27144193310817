package org.botdesigner.shared.domain

import androidx.compose.runtime.staticCompositionLocalOf

enum class InterfaceIdiom {
    Mobile, Desktop
}

val LocalInterfaceIdiom = staticCompositionLocalOf {
    InterfaceIdiom.Mobile
}