package org.botdesigner.ui.screens.blueprint

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.WindowInsetsSides
import androidx.compose.foundation.layout.asPaddingValues
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.only
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.KeyboardTab
import androidx.compose.material.icons.filled.VerticalAlignBottom
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.PlainTooltip
import androidx.compose.material3.Text
import androidx.compose.material3.TooltipBox
import androidx.compose.material3.VerticalDivider
import androidx.compose.material3.rememberTooltipState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.LineHeightStyle
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.IntRect
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.PopupPositionProvider
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveIconButton
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.adaptive.icons.AdaptiveIcons
import io.github.alexzhirkevich.cupertino.adaptive.icons.Delete
import io.github.alexzhirkevich.cupertino.theme.CupertinoColors
import io.github.alexzhirkevich.cupertino.theme.systemYellow
import org.botdesigner.blueprint.BlueprintLogLevel
import org.botdesigner.blueprint.BlueprintTerminalLog
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.data.repo.ConsolePosition
import org.botdesigner.shared.domain.content.blueprint.terminal.BlueprintLogsComponent
import org.botdesigner.ui.common.PlatformVerticalScrollbar
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens

@Composable
fun BlueprintLogcat(
    component: BlueprintLogsComponent,
    orientation: ConsolePosition,
    isPane: Boolean = false,
    modifier: Modifier = Modifier,
) {
    CompositionLocalProvider(
        LocalDensity provides
            Density(
                density = LocalDensity.current.density,
                fontScale = LocalDensity.current.fontScale * component.fontScale,
            ),
    ) {
        Row(modifier) {
            Column(
                modifier =
                    Modifier
                        .fillMaxHeight()
                        .padding(
                            horizontal = Dimens.Spacing.ExtraSmall,
                            vertical = Dimens.Spacing.Medium,
                        )
                        .windowInsetsPadding(WindowInsets.safeDrawing.only(WindowInsetsSides.Vertical)),
                verticalArrangement =
                    Arrangement.spacedBy(
                        space = Dimens.Spacing.Small,
                        alignment = Alignment.CenterVertically,
                    ),
            ) {
                ClearButton(
                    orientation = orientation,
                    onClick = component::onClearClicked,
                )
                if (isPane) {
                    ChangeOrientationButton(
                        orientation = orientation,
                        onClick = component::onChangeOrientationClicked,
                    )
                }

                LevelButton(
                    orientation = orientation,
                    level = component.level,
                    onClick = component::onLogLevelChange,
                )
            }
            VerticalDivider()

            Box(Modifier.weight(1f)) {
                val lazyListState = rememberLazyListState()

                LaunchedEffect(component.logs.size) {
                    val lastVisibleLog =
                        lazyListState
                            .layoutInfo.visibleItemsInfo.lastOrNull()?.index ?: return@LaunchedEffect

                    if (lastVisibleLog >= component.logs.size - 2) {
                        lazyListState.scrollToItem(component.logs.size)
                    }
                }
                SelectionContainer {
                    LazyColumn(
                        state = lazyListState,
                        modifier =
                            Modifier
                                .fillMaxSize()
                                .padding(horizontal = Dimens.Spacing.Medium),
                        contentPadding =
                            WindowInsets.safeDrawing
                                .only(
                                    if (orientation == ConsolePosition.Bottom) {
                                        WindowInsetsSides.Horizontal
                                    } else {
                                        WindowInsetsSides.Vertical
                                    },
                                )
                                .asPaddingValues(),
                    ) {
                        item {
                            Spacer(Modifier.height(Dimens.Spacing.Medium))
                        }
                        items(component.logs, key = BlueprintTerminalLog::id) {
                            LogWidget(it)
                        }
                        item {
                            Spacer(Modifier.height(Dimens.Spacing.Medium))
                        }
                    }
                }
                PlatformVerticalScrollbar(
                    scrollableState = lazyListState,
                    modifier = Modifier.align(Alignment.CenterEnd),
                )
            }
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class, ExperimentalAdaptiveApi::class)
@Composable
private fun ClearButton(
    orientation: ConsolePosition,
    onClick: () -> Unit,
) {
    TooltipBox(
        positionProvider = rememberTooltipPositionProvider(consolePosition = orientation),
        state = rememberTooltipState(),
        tooltip = {
            PlainTooltip {
                Text(string(SharedRes.strings.clear))
            }
        },
    ) {
        AdaptiveIconButton(
            onClick = onClick,
        ) {
            Icon(
                imageVector = AdaptiveIcons.Outlined.Delete,
                contentDescription = string(SharedRes.strings.clear),
            )
        }
    }
}

@OptIn(ExperimentalAdaptiveApi::class, ExperimentalMaterial3Api::class)
@Composable
private fun LevelButton(
    orientation: ConsolePosition,
    level: BlueprintLogLevel,
    onClick: () -> Unit,
) {
    val color =
        when (level) {
            BlueprintLogLevel.Verbose -> Color.White
            BlueprintLogLevel.Warning -> CupertinoColors.systemYellow
            BlueprintLogLevel.Error -> MaterialTheme.colorScheme.error
        }

    TooltipBox(
        positionProvider = rememberTooltipPositionProvider(consolePosition = orientation),
        state = rememberTooltipState(),
        tooltip = {
            PlainTooltip {
                Text(string(SharedRes.strings.min_log_level, level.name))
            }
        },
    ) {
        AdaptiveIconButton(
            onClick = onClick,
        ) {
            LocalDensity.current.run {
                Text(
                    text = level.name[0].toString(),
                    fontSize = 24.dp.toSp(),
                    fontWeight = FontWeight.SemiBold,
                    color = color,
                    fontFamily = FontFamily.Monospace,
                )
            }
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class, ExperimentalAdaptiveApi::class)
@Composable
private fun ChangeOrientationButton(
    orientation: ConsolePosition,
    onClick: () -> Unit,
) {
    val isLtr = LocalLayoutDirection.current == LayoutDirection.Ltr
    val tooltip =
        when {
            orientation == ConsolePosition.Bottom && isLtr -> SharedRes.strings.dock_right
            orientation == ConsolePosition.Bottom -> SharedRes.strings.dock_left
            else -> SharedRes.strings.dock_bottom
        }
    TooltipBox(
        positionProvider = rememberTooltipPositionProvider(consolePosition = orientation),
        state = rememberTooltipState(),
        tooltip = {
            PlainTooltip {
                Text(string(tooltip))
            }
        },
    ) {
        AdaptiveIconButton(
            onClick = onClick,
        ) {
            Icon(
                imageVector =
                    if (orientation == ConsolePosition.Bottom) {
                        Icons.AutoMirrored.Filled.KeyboardTab
                    } else {
                        Icons.Filled.VerticalAlignBottom
                    },
                contentDescription = string(tooltip),
            )
        }
    }
}

@Composable
private fun rememberTooltipPositionProvider(
    padding: Dp = Dimens.Spacing.Small,
    consolePosition: ConsolePosition,
): PopupPositionProvider {
    val paddingPx =
        LocalDensity.current.run {
            padding.roundToPx()
        }

    return remember(consolePosition, paddingPx) {
        object : PopupPositionProvider {
            override fun calculatePosition(
                anchorBounds: IntRect,
                windowSize: IntSize,
                layoutDirection: LayoutDirection,
                popupContentSize: IntSize,
            ): IntOffset {
                return when (consolePosition) {
                    ConsolePosition.Bottom ->
                        if (anchorBounds.right + popupContentSize.width < windowSize.width) {
                            anchorBounds.centerRight +
                                IntOffset(
                                    paddingPx,
                                    -popupContentSize.height / 2,
                                )
                        } else {
                            anchorBounds.centerLeft -
                                IntOffset(
                                    paddingPx,
                                    -popupContentSize.height / 2,
                                )
                        }

                    ConsolePosition.End ->
                        if (anchorBounds.bottom + popupContentSize.height < windowSize.height) {
                            anchorBounds.bottomCenter +
                                IntOffset(
                                    -popupContentSize.width / 2,
                                    paddingPx,
                                )
                        } else {
                            anchorBounds.topCenter - IntOffset(-popupContentSize.width / 2, paddingPx)
                        }
                }
            }
        }
    }
}

@Composable
private fun LogWidget(log: BlueprintTerminalLog) {
    Text(
        text = log.message,
        color =
            when (log.level) {
                BlueprintLogLevel.Verbose -> Color.Unspecified
                BlueprintLogLevel.Warning -> CupertinoColors.systemYellow
                BlueprintLogLevel.Error -> MaterialTheme.colorScheme.error
            },
        lineHeight = LocalTextStyle.current.fontSize * 1.5f,
        style =
            LocalTextStyle.current.copy(
                lineHeightStyle =
                    LineHeightStyle(
                        alignment = LineHeightStyle.Alignment.Center,
                        trim = LineHeightStyle.Trim.None,
                    ),
            ),
        fontFamily = FontFamily.Monospace,
    )
}
