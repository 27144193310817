package org.botdesigner.shared.domain.content.blueprint

import kotlinx.datetime.Clock
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.botdesigner.blueprint.components.Blueprint
import org.botdesigner.blueprint.integrations.hasCorruptedNodes
import org.botdesigner.botblueprints.BlueprintData
import org.botdesigner.botblueprints.telegram.TelegramSerializersModule
import org.botdesigner.core.Bot
import org.botdesigner.core.BotStatus
import org.botdesigner.shared.data.repo.BlueprintsRepository

private const val AUTOSAVE_SYNC_TIMER = 60_000

class BlueprintSaveController(
    private val isAutoSaveEnabled : () -> Boolean,
    private val bot : Bot,
    private val blueprintData : () -> BlueprintData,
    private val onSaveBlueprint: () -> Blueprint,
    private val repository: BlueprintsRepository
) {
    private var lastRemoteUpdate: Long? = null

    private val canSyncLocally: Boolean
        get() = isAutoSaveEnabled() && bot.status == BotStatus.Stopped

    private val canSyncRemotely: Boolean
        get() = canSyncLocally && lastRemoteUpdate.let {
            it == null || nowMillis() - it > AUTOSAVE_SYNC_TIMER
        }

    private val json = Json {
        serializersModule = TelegramSerializersModule
        ignoreUnknownKeys = true
        isLenient = true
        explicitNulls = false
    }

    fun encodeToJson(blueprint: Blueprint) =
        json.encodeToString(blueprint)

    fun decodeFromJson(value : String) : Blueprint =
        json.decodeFromString(value)

    suspend fun tryAutoSave(forceRemote: Boolean = false) : Boolean {
        if (!canSyncLocally)
            return false

        return saveInternal(remote = forceRemote || canSyncRemotely) == SaveResult.Success
    }

    suspend fun save(): SaveResult {
        return saveInternal(remote = true)
    }

    private suspend fun saveInternal(remote : Boolean) : SaveResult {
        val data = blueprintData()

        val newBlueprint = onSaveBlueprint()

        if (newBlueprint.hasCorruptedNodes)
            return SaveResult.UnresolvedCorruptedNodes

        val newData = data.copy(
            blueprint = encodeToJson(onSaveBlueprint())
        )

        if (remote) {
            repository.updateLocally(
                botId = bot.id,
                blueprint = newData
            )
        } else {
            repository.update(
                botId = bot.id,
                blueprint = newData
            )
            lastRemoteUpdate = nowMillis()
        }

        return SaveResult.Success
    }

    private fun nowMillis() = Clock.System.now().toEpochMilliseconds()
}

enum class SaveResult {
    Success,
    UnresolvedCorruptedNodes,
}