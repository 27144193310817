package org.botdesigner.blueprint.stdlib.pins

import androidx.compose.ui.graphics.Color
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.*

@Serializable
@SerialName("StringArrayPinFactory")
object StringArrayPinFactory : PinFactory<Iterable<String>> {
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = StringArrayPin(
        id = id,
        order = order,
        elId = elementId,
        isOut = isOut,
        name = name,
        required = required
    )
}

@Serializable
@SerialName("StringArrayPin")
data class StringArrayPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val isOut: Boolean,
    override val name: String = "",
    override val value: Iterable<String>? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : ArrayPin<@Contextual String>() {

    override val childPinFactory: PinFactory<String>
        get() = StringPinFactory

    override val color: Color
        get() = BlueprintColors.String

    override fun withReference(parent: Pin<*>?): StringArrayPin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun withValue(value: String): StringArrayPin {
        return copy(value = null, parentId = null, parentPinId = null)
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<Iterable<String>> {
        return StringArrayPinFactory
    }
}

fun PinsDirectionalScope.stringArray(name: String = "", order : UInt? = null) {
    pin { o, i ->
        StringArrayPin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
        )
    }
}