package org.botdesigner.blueprint.stdlib.functions.arrays

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.components.functions.BpFunctionStateHolder
import org.botdesigner.blueprint.components.functions.FunctionFactory
import org.botdesigner.blueprint.components.GenericArrayPinFactory
import org.botdesigner.blueprint.components.GenericPinFactory
import org.botdesigner.blueprint.components.generic
import org.botdesigner.blueprint.components.genericArray
import org.botdesigner.blueprint.components.inputPins
import org.botdesigner.blueprint.components.outputPins
import org.botdesigner.blueprint.toAnnotatedString
import org.botdesigner.blueprint.ui.pins.PinsExtender

@kotlinx.serialization.Serializable
@SerialName("BpMakeArray")
class BpMakeArray(
    override val coordinate: Point = Point.Zero,
    override val id: Id = Id.uuid(),
    override val pins: List<Pin<@Contextual Any?>> = Pins(id) {
        input {
            generic("")
        }
        output {
            genericArray("Array")
        }
    }
) : BpFunction() {

    override val summary: AnnotatedString
        get() = "Make an array from set of objects. Array will automatically take it's type if all it's elements have single type".toAnnotatedString()

    override val color: Color
        get() = BlueprintColors.Util

    override val factory: FunctionFactory
        get() = ::BpMakeArray

    override suspend fun calculate(input: List<*>): List<*> = listOf(input)

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> {
        return object : BpFunctionStateHolder<BpMakeArray>(
            element = this,
            manager = manager
        ) {

            override fun updatePin(
                pin: Pin<*>
            ) {
                super.updatePin(pin)

                if (pin.isOut)
                    return

                val outPin = outputPins.last()

                val factories = inputPins
                    .map { it.factory(manager) }

                val isAllPinsSame = factories.map { it::class }.toSet().size == 1
                val arrayPin = factories
                    .takeIf { isAllPinsSame }
                    ?.first()
                    ?.array(
                        id = outPin.id,
                        order = outPin.order,
                        elementId = outPin.elId,
                        name = outPin.name,
                        isOut = outPin.isOut
                    ) ?: GenericArrayPinFactory.create(
                    id = outPin.id,
                    order = outPin.order,
                    elementId = outPin.elId,
                    name = outPin.name,
                    isOut = outPin.isOut
                )

                if (outPin::class == arrayPin::class) {
                    return
                }
                manager.resetPin(outPin)

                super.updatePin(arrayPin)
            }

            @Composable
            override fun Also(elementWidth: Float) {

                super.Also(elementWidth)

                PinsExtender(
                    addEnabled = true,
                    popEnabled = pins.size>2,
                    onAddPin = {
                        appendPin {
                            GenericPinFactory.create(
                                id = it,
                                order = pins.size.toUInt(),
                                elementId = element.id,
                                name = "",
                                isOut = false
                            )
                        }
                    },
                    onPopPin = {
                        val pin = inputPins.lastOrNull() ?: return@PinsExtender

                        removePin(pin)
                    }
                )
            }
        }
    }
}
