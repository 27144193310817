package org.botdesigner.shared.di

import app.cash.sqldelight.db.SqlDriver
import com.russhwolf.settings.Settings
import org.botdesigner.shared.util.SecureSettings
import org.botdesigner.shared.util.intents.SendEmailIntent
import org.botdesigner.shared.util.intents.ViewUrlIntent
import org.botdesigner.shared.util.managers.AdManager
import org.botdesigner.shared.util.managers.NotificationManager
import org.botdesigner.shared.util.managers.SubscriptionManager
import org.koin.core.definition.KoinDefinition
import org.koin.core.module.Module
import org.koin.dsl.module

val PlatformModule: Module
    get() = module {
        viewUrlIntent()
        sendEmailIntent()
        subscriptionManager()
        adManager()
        notificationManager()
        settings()
        secureSettings()
        sqlDriver()
    }

internal expect fun Module.viewUrlIntent(): KoinDefinition<ViewUrlIntent>
internal expect fun Module.sendEmailIntent(): KoinDefinition<SendEmailIntent>
internal expect fun Module.subscriptionManager(): KoinDefinition<SubscriptionManager>
internal expect fun Module.adManager(): KoinDefinition<AdManager>
internal expect fun Module.notificationManager(): KoinDefinition<NotificationManager>
internal expect fun Module.settings(): KoinDefinition<Settings>
internal expect fun Module.secureSettings(): KoinDefinition<SecureSettings>
internal expect fun Module.sqlDriver(): KoinDefinition<SqlDriver>
