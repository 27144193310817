package org.botdesigner.blueprint.stdlib.functions.arrays

import kotlinx.serialization.json.JsonObject
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.BlueprintProcedure
import org.botdesigner.blueprint.generator.DEFAULT_RETURN_ID
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.stdlib.functions.special.withFraudProtection
import org.botdesigner.blueprint.store.BlueprintNodeCategory

@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Array,
    exitName = "End",
    summary = "Run <b>Loop</b> for each array element",
    serialName = "BpForEach",
    color = BlueprintColors.UtilLong
)
internal suspend inline fun ForEach(
    @Pin(id = "0") Array : Iterable<Any?>,
    Loop : (Any?) -> Unit
) : @Pin(name = "N", id = DEFAULT_RETURN_ID, deriveTypeFromArray = "0") Any? {
    withFraudProtection {
        Array.forEach {
            Loop(it)
            cycle()
        }
    }

    return null
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Array,
    summary = "Returns array length (elements count)",
    serialName = "BpArraySize",
    displayName = "Size",
    color = BlueprintColors.UtilLong
)
internal inline fun ArraySize(
    Array : Iterable<Any?>,
) : Long {

    return when (Array) {
        is Collection -> Array.size.toLong()
        is LongRange -> (Array.last - Array.first)
        is IntRange -> (Array.last - Array.first).toLong()
        else -> Array.count().toLong()
    }
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Array,
    summary = "Returns <b>Array</b> element at specific <b>Index</b> or NULL if index is greater than array length",
    serialName = "BpArrayElementAtIndex",
    color = BlueprintColors.UtilLong
)
internal inline fun ElementAtIndex(
    @Pin(id = "0", name = "Array") Array : Iterable<Any?>,
    Index : Long
) : @Pin(id = DEFAULT_RETURN_ID, deriveTypeFromArray = "0") Any? {
    return Array.elementAtOrNull(Index.toInt())
}

@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Array,
    summary = "Returns copy of the <b>Array</b> with appended <b>Element</b> at the last position. Doesn't support appending to ranges. Total array memory size can't be greater then 1 MB",
    serialName = "BpArrayAppend",
    color = BlueprintColors.UtilLong
)
internal inline fun Append(
    @Pin(id = "0", name = "Array") Array : Iterable<Any?>,
    Element : Any?,
    Index: Long? = null
) : @Pin(id = DEFAULT_RETURN_ID, deriveTypeFromArray = "0") Iterable<Any?> {

    require(Array is Collection<*> && Array.arraySizeMB() < 1) {
        "Can't append to this array"
    }

    return Array.toMutableList().apply {
        if (Index != null) {
            add(Index.toInt(), Element)
        } else {
            add(Element)
        }
    }
}

@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Array,
    summary = "Returns copy of the <b>Array</b> without an element at <b>Index</b> if such element exists. Doesn't support removing from ranges",
    serialName = "BpArrayRemove",
    color = BlueprintColors.UtilLong
)
internal inline fun Remove(
    @Pin(id = "0", name = "Array") Array : Iterable<Any?>,
    Index : Long
) : @Pin(id = DEFAULT_RETURN_ID, deriveTypeFromArray = "0") Iterable<Any?> {

    require(Array is Collection<*>){
        "Can't remove elements from this array"
    }

    return if (Index in Array.indices) {
        Array.toMutableList().apply {
            removeAt(Index.toInt())
        }
    } else Array
}



@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Array,
    summary = "Returns first <b>Array</b> element or NULL if array contains no elements",
    serialName = "BpArrayFirstOrNull",
    color = BlueprintColors.UtilLong
)
internal inline fun FirstOrNull(
    @Pin(id = "0", name = "Array") Array : Iterable<Any?>,
) : @Pin(id = DEFAULT_RETURN_ID, deriveTypeFromArray = "0") Any? {
    return Array.firstOrNull()
}

@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Array,
    summary = "Returns last <b>Array</b> element or NULL if array contains no elements",
    serialName = "BpArrayLastOrNull",
    color = BlueprintColors.UtilLong
)
internal inline fun LastOrNull(
    @Pin(id = "0", name = "Array") Array : Iterable<Any?>,
) : @Pin(id = DEFAULT_RETURN_ID, deriveTypeFromArray = "0") Any? {
    return Array.firstOrNull()
}

private const val MB = 1024.0 * 1024

private fun Iterable<Any?>.arraySizeMB() : Double {

    if (this is Map<*, *>) {
        return keys.arraySizeMB() + values.arraySizeMB()
    }

    var size = 0.0

    forEach {
        size += when (it) {
            is String -> it.length / MB
            is JsonObject -> it.toString().length / MB
            is Iterable<*> -> arraySizeMB()
            is Float -> Float.SIZE_BYTES / MB
            is Int -> Int.SIZE_BYTES / MB
            is Long -> Long.SIZE_BYTES / MB
            is Double -> Double.SIZE_BYTES / MB
            else -> it.toString().length / MB
        }
    }

    return size
}