@file:OptIn(ExperimentalContracts::class)

package org.botdesigner.blueprint.components

import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.contract

/**
 * Blueprint component that can be executed
 * */
interface BlueprintInvocable<in T> : BlueprintNode {

    /**
     * Execute this element from [pool] with given [context].
     * */
    suspend operator fun invoke(context: T, pool: BlueprintNodesPool)
}

fun BlueprintNode.isInvocable() : Boolean {
    contract {
        returns(true) implies (this@isInvocable is BlueprintInvocable<*>)
    }

    return this@isInvocable is BlueprintInvocable<*>
}
/**
 * Tries to unchecked cast receiver to [BlueprintInvocable]<[T]> and invoke it.
 *
 * @return true if element was casted and successfully invoked
 * */
suspend fun <T> BlueprintNode.invokeIfPossible(context: T, pool: BlueprintNodesPool) : Boolean {
    contract {
        returns(true) implies (this@invokeIfPossible is BlueprintInvocable<*>)
    }

    return asInvocableOrNull<T>()?.invoke(context, pool) != null
}

/**
 * Tries to UNCHECKED cast this value to [BlueprintInvocable]
 * */
@Suppress("UNCHECKED_CAST")
fun <T> BlueprintNode.asInvocableOrNull() : BlueprintInvocable<T>? {
    contract {
        returnsNotNull() implies (this@asInvocableOrNull is BlueprintInvocable<*>)
    }

    return this as? BlueprintInvocable<T>
}