package org.botdesigner.blueprint.components

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName


@kotlinx.serialization.Serializable
@SerialName("GenericArrayPinFactory")
object GenericArrayPinFactory : PinFactory<Iterable<Any?>> {
    override fun create(
        id: Id,
        order: UInt,
        elementId: Id,
        name: String,
        isOut: Boolean,
        required: Boolean
    ) = GenericArrayPin(
        id = id,
        order = order,
        elId = elementId,
        name = name,
        isOut = isOut,
        required = required
    )
}

/**
 * Can receive and produce any type of [ArrayPin] content
 * */
@kotlinx.serialization.Serializable
@SerialName("GenericArrayPin")
data class GenericArrayPin(
    override val id: Id,
    override val order: UInt,
    override val elId: Id,
    override val isOut: Boolean,
    override val name: String = "",
    override val value: Iterable<@Contextual Any?>? = null,
    override val parentId: Id? = null,
    override val parentPinId: Id? = null,
    override val required: Boolean = false,
) : ArrayPin<@Contextual Any?>() {

    override val childPinFactory: PinFactory<Any?>
        get() = GenericPinFactory

    override fun fits(other: Pin<*>): Boolean {
        return other is ArrayPin<*>
    }

    override fun withReference(parent: Pin<*>?): GenericArrayPin {
        return copy(value = null, parentId = parent?.elId, parentPinId = parent?.id)
    }

    override fun withValue(value: String): GenericArrayPin {
        return copy(
            value = null,
            parentId = null,
            parentPinId = null,
        )
    }

    override fun factory(pool: BlueprintNodesPool): PinFactory<Iterable<Any?>> {
        return GenericArrayPinFactory
    }
}

fun PinsDirectionalScope.genericArray(name: String, order: UInt? = null) {
    pin { o, i ->
        GenericArrayPin(
            id = Id.randomId(),
            order = order ?: o,
            elId = elementId,
            name = name,
            isOut = i,
        )
    }
}