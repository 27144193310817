package org.botdesigner.blueprint.stdlib.functions.special

import kotlinx.coroutines.delay
import org.botdesigner.blueprint.BlueprintLogLevel
import org.botdesigner.blueprint.components.BlueprintColors
import org.botdesigner.blueprint.generator.BlueprintExpression
import org.botdesigner.blueprint.generator.BlueprintFunction
import org.botdesigner.blueprint.generator.BlueprintProcedure
import org.botdesigner.blueprint.generator.DEFAULT_RETURN_ID
import org.botdesigner.blueprint.generator.Pin
import org.botdesigner.blueprint.stdlib.context.LogcatContext
import org.botdesigner.blueprint.store.BlueprintNodeCategory

@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    exitName = "True",
    summary = "Represents <b>if</b> statement. Select the next element depending on the condition",
    serialName = "BpBranch",
    color = BlueprintColors.UtilLong
)
internal inline fun Branch(
   Condition : Boolean,
   False : () -> Nothing
) {
    if (!Condition) {
        False()
    }
}

@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    exitName = "End",
    summary = "Repeat <b>Loop</b> action while <b>Condition</b> is true",
    serialName = "BpWhile",
    color = BlueprintColors.UtilLong
)
internal suspend inline fun While(
    Condition : Boolean,
    Loop : () -> Unit
) {
    withFraudProtection {
        while (Condition) {
            Loop()
            cycle()
        }
    }
}

@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    exitName = "End",
    summary = "Repeat <b>Loop</b> action <b>Count</b> times. <b>N</b> is an index of current cycle",
    serialName = "BpRepeat",
    color = BlueprintColors.UtilLong
)
internal suspend inline fun Repeat(
    Count : Long,
    Loop : (Long) -> Unit
) : @Pin("N") Long {
    For(0, Count, 1, Loop)

    return 0
}

@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    exitName = "End",
    summary = "Run For <b>Loop</b> starting from <b>Start</b> until <b>End</b> with <b>Step</b> (1 by default)",
    serialName = "BpFor",
    color = BlueprintColors.UtilLong
)
internal suspend inline fun For(
    From : Long,
    Until : Long,
    Step : Long?,
    Loop : (Long) -> Unit
) : @Pin("N") Long {
    withFraudProtection {
        for (i in From until Until step (Step?.takeIf { it > 0 } ?: 1)) {
            Loop(i)
            cycle()
        }
    }

    return 0
}



@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    summary = "Print <b>Message</b> to logcat. Useful for debugging",
    serialName = "BpPrint",
    color = BlueprintColors.UtilLong
)
internal suspend inline fun LogcatContext.Print(
    Message : Any?
) = log(BlueprintLogLevel.Verbose, Message.toString())

@BlueprintExpression(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    summary = "Check if object is uninitialized (is NULL)",
    serialName = "BpIsNull",
    expression = "IS NULL"
)
internal inline fun IsNull(
     Object : Any?
) : Boolean = Object == null


@BlueprintFunction(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    summary = "Wait for the <b>Ms</b> milliseconds delay.",
    serialName = "BpDelay",
    color = BlueprintColors.UtilLong
)
internal suspend inline fun Delay(
    Ms : Long?,
) {
    delay(Ms?.takeIf { it >= 0 } ?: return)
}

@BlueprintProcedure(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    summary = "Perform <b>Not Null</b> action if <b>Value</b> is not null and <b>Is Null</b> otherwise. Returns the same <b>Value</b> that was passed",
    exitName = "Not Null",
    serialName = "BpIfNotNull",
    color = BlueprintColors.UtilLong
)
internal inline fun IfNotNull(
    @Pin(name = "Value", id = "0") Value : Any?,
    `Is Null` : (Any?) -> Nothing
) : @Pin(id = DEFAULT_RETURN_ID, deriveTypeFrom = "0") Any? {
    if (Value == null) {
        `Is Null`(null)
    }
    return Value
}

@BlueprintExpression(
    category = BlueprintNodeCategory.StdlibName,
    subCategory = BlueprintNodeCategory.Stdlib.Special,
    summary = "Returns <b>Nullable</b> if it is not null and <b>Replace</b> otherwise",
    serialName = "BpReplaceNull",
    expression = "??"
)
internal inline fun ReplaceNull(
    @Pin(id = "0", name = "Nullable") value: Any?,
    @Pin(id = "1",deriveTypeFrom = "0", name = "Replace") replacement : Any
) : @Pin(id = DEFAULT_RETURN_ID, deriveTypeFrom = "0") Any {
    return value ?: replacement
}
