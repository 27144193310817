package org.botdesigner.blueprint.components.functions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Shape
import kotlinx.serialization.Contextual
import org.botdesigner.blueprint.AbstractBlueprintNodeStateHolder
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.BlueprintNodeStateHolder
import org.botdesigner.blueprint.components.Connector
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.ui.components.Expression

typealias ExpressionFactory = (
    coordinate: Point,
    id : Id,
    pins : List<Pin<@Contextual Any?>>,
) -> BpExpression

@kotlinx.serialization.Serializable
abstract class BpExpression : BpFunction() {

    abstract val expression : String

    abstract override val factory : ExpressionFactory

    override fun createHolder(
        manager: BlueprintManager
    ): BlueprintNodeStateHolder<*> {
        return ExpressionNHolder(
            element = this,
            manager = manager
        )
    }
}

private class ExpressionNHolder(
    element: BpExpression,
    manager: BlueprintManager
) : BpFunctionStateHolder<BpExpression>(
    element = element,
    manager = manager
) {

    @Composable
    override fun Draw(
        modifier: Modifier,
        fieldSize: Float,
        shape: Shape
    ) {
        Expression(
            modifier = modifier,
            expression = element.expression,
            manager = manager,
            fieldSize = fieldSize,
            shape = shape
        )
    }

    override fun save(): BpExpression =
        element.factory(
            position.value,
            element.id,
            pins,
        )
}