package org.botdesigner.blueprint.components

/**
 * @property id Id of this connector. Unique across owner element connectors
 * @property order Connector sort order
 * @property elId Connector owner id
 * @property isOut Connector direction
 * @property refId Element id this connector is connected to
 * @property refConId Connected connector id
 * @property name Name of this connector
 * */
@kotlinx.serialization.Serializable
data class Connector(
    override val id: Id,
    override val order : UInt,
    val elId : Id,
    override val isOut : Boolean,
    val refId : Id? = null,
    val refConId : Id? = null,
    override val name : String = "",
) : Identifiable, Ordered, BiDirectional, Named

fun InputConnector(elementId: Id , order: UInt = UInt.MAX_VALUE) : Connector {
    return Connector(
        id = Id.randomId(),
        order = order,
        elId = elementId,
        isOut = false
    )
}

fun OutputConnector(elementId: Id, order: UInt = UInt.MIN_VALUE) : Connector {
    return Connector(
        id = Id.randomId(),
        order = order,
        elId = elementId,
        isOut = true
    )
}

fun Connector.withReference(other : Connector?) : Connector {
    require(other == null || isOut != other.isOut){
        "Cant merge single direction connectors"
    }
    return copy(refId = other?.elId, refConId = other?.id)
}

interface ConnectorsScope {
    fun input(name : String = "")
    fun output(name : String = "")
}

/**
 * Create a set of input and output connectors for node with [nodeId].
 *
 * Guaranteed that each connector have unique [Connector.id] in the node scope
 * */
fun Connectors(nodeId: Id, block : ConnectorsScope.() -> Unit) : List<Connector> {

    var connectors: List<Connector>
    do {
        connectors = ConnectorScopeImpl(nodeId).apply(block).connectors
    } while (connectors.map(Connector::id).toSet().size != connectors.size)

    return connectors
}

fun IOConnectors(elementId: Id) = Connectors(elementId){
    input()
    output()
}

fun Connector.next(pool: BlueprintNodesPool) : BlueprintNode? {
    return refId
        ?.takeIf { isOut }
        ?.let(pool::getElementById)
}

/**
 * Run element this connector connected to from given [context] in an blueprint [pool].
 *
 * Can be used for output connectors only
 *
 * @return true if next connector was invoked successfully
 * */
suspend fun <T> Connector.invokeNext(context: T, pool: BlueprintNodesPool) : Boolean {
    require(isOut) {
        "Input connectors must not be executed"
    }
    return next(pool)?.invokeIfPossible(context, pool) == true
}

private class ConnectorScopeImpl(
    private val elementId: Id
) : ConnectorsScope {

    val connectors = mutableListOf<Connector>()

    override fun input(name: String) {
        connectors.add(
            Connector(
                id = Id.randomId(),
                elId = elementId,
                isOut = false,
                name = name,
                order = connectors.size.toUInt()
            )
        )
    }

    override fun output(name: String) {
        connectors.add(
            Connector(
                id = Id.randomId(),
                elId = elementId,
                isOut = true,
                name = name,
                order = connectors.size.toUInt()
            )
        )
    }
}