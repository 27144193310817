package org.botdesigner.blueprint.components

import androidx.compose.ui.graphics.Color

object BlueprintColors {

    val Trigger = Color.Red
    val Return = Color(0xff8a2be2)
    val Function = Color(0xFF263D4D)
    val String = Color(0xff0a9024)
    val Boolean = Color.Red
    val Array = Color.Gray
    val Long = Color.Yellow
    val Date = Color(0xffa3ff4d)
    val Float = Color(0xffffcc99)
    const val UtilLong = 0xFF888888
    val Util = Color(UtilLong)
    val Variable = Color(0xff5aad7a)
}