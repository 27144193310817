@file:Suppress("UNCHECKED_CAST")

package org.botdesigner.blueprint.stdlib.functions.boolean

import androidx.compose.ui.text.AnnotatedString
import kotlin.Any
import kotlin.Boolean
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.botdesigner.blueprint.components.Connectors
import org.botdesigner.blueprint.components.IOConnectors
import org.botdesigner.blueprint.components.Id
import org.botdesigner.blueprint.components.Pin
import org.botdesigner.blueprint.components.PinFactory
import org.botdesigner.blueprint.components.Pins
import org.botdesigner.blueprint.components.Point
import org.botdesigner.blueprint.components.functions.BpExpressionN
import org.botdesigner.blueprint.components.functions.ExpressionNFactory
import org.botdesigner.blueprint.components.invokeNext
import org.botdesigner.blueprint.components.outputConnectors
import org.botdesigner.blueprint.toAnnotatedString

@Serializable
@SerialName("BpBooleanOr")
public class BpBooleanOr(
  override val coordinate: Point = Point.Zero,
  override val id: Id = Id.uuid(),
  override val pins: List<Pin<@Contextual Any?>> = Pins(id){
          input {
              pin { o, i ->
          org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
              id = Id.randomId(),
              elementId = elementId,
              order = o,
              name = "",
              isOut = i,
              required = false
          )
                  }
          }
         
          output {
         pin { o, i ->
              org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory.create(
                  id = Id.randomId(),
                  elementId = elementId,
                  order = o,
                  name = "",
                  isOut = i,
                  required = true
              )
          }
      }
      },
) : BpExpressionN<Boolean?>() {
  override val summary: AnnotatedString
    get() =
        "Check if at least one of booleans is true (logical 'or', '|'). Null Boolean is considered false".trimIndent().toAnnotatedString()

  override val name: String
    get() = "Or"

  override val factory: ExpressionNFactory<Boolean?>
    get() = ::BpBooleanOr

  override val expression: String
    get() = "||"

  override val pinFactory: PinFactory<Boolean?>
    get() = org.botdesigner.blueprint.stdlib.pins.BooleanPinFactory

  override suspend fun calculateCasted(input: List<Boolean?>): List<Any?> =
      listOf(BooleanOr(*input.toTypedArray()))
}
