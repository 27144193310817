package org.botdesigner.blueprint.components

//import org.botdesigner.blueprint.lang.pins.BooleanPin
//import org.botdesigner.blueprint.components.pins.DateTimePin
//import org.botdesigner.blueprint.lang.pins.DividerPin
//import org.botdesigner.blueprint.lang.pins.DoubleArrayPin
//import org.botdesigner.blueprint.lang.pins.DoublePin
//import org.botdesigner.blueprint.lang.pins.GenericArrayPin
//import org.botdesigner.blueprint.lang.pins.GenericPin
//import org.botdesigner.blueprint.components.pins.LongArrayPin
//import org.botdesigner.blueprint.components.pins.LongPin
//import org.botdesigner.blueprint.components.pins.StringArrayPin
//import org.botdesigner.blueprint.lang.pins.StringPin

interface PinsScope {
    fun input(block : PinsDirectionalScope.() -> Unit)
    fun output(block: PinsDirectionalScope.() -> Unit)
}

interface PinsDirectionalScope {

    val elementId : Id

//    fun generic(name : String = "",order : UInt?=null)
//    fun string(name : String = "",order : UInt?=null, default: String? = "", maxLines : Int = 1)
//    fun long(name : String = "", order : UInt?=null, default : Long? = 0)
//    fun double(name : String = "",order : UInt?=null)
//    fun bool(name : String = "", order : UInt?=null)
//    fun genericArray(name : String = "",order : UInt?=null)
//    fun stringArray(name : String = "",order : UInt?=null)
//    fun longArray(name : String = "",order : UInt?=null)
//    fun doubleArray(name : String = "",order : UInt?=null)
//    fun date(name : String, order: UInt? = null)

    fun pin(pin: (order : UInt, isOut : Boolean) -> Pin<*>)

//    fun divider()
}

/**
 * Create a set of input and output pin for element with [nodeId].
 *
 * Guaranteed that each pin have unique [Pin.id] in the node scope
 * */
fun Pins(nodeId: Id, block : PinsScope.() -> Unit) : List<Pin<*>> {

    var pins: List<Pin<*>>
    do {
        pins = PinsScopeImpl(nodeId).apply(block).pins
    } while (pins.map(Pin<*>::id).toSet().size != pins.size)

    return pins
}


private class PinsScopeImpl(
    private val elementId: Id,
) : PinsScope {

    val pins: MutableList<Pin<*>> = mutableListOf()

    override fun input(block: PinsDirectionalScope.() -> Unit) {
        PinsDirectionalScopeImpl(pins, elementId, isOut = false).apply(block)
    }

    override fun output(block: PinsDirectionalScope.() -> Unit) {
        PinsDirectionalScopeImpl(pins, elementId, isOut = true).apply(block)
    }
}

private class PinsDirectionalScopeImpl(
    val pins: MutableList<Pin<*>>,
    override val elementId: Id,
    private val isOut: Boolean
) : PinsDirectionalScope {
//    override fun generic(name: String,order : UInt?) {
//        pin { o, i ->
//            GenericPin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//            )
//        }
//    }
//
//    override fun date(name: String, order: UInt?) {
//        pin { o, isOut ->
//            DateTimePin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = isOut,
//            )
//        }
//    }
//
//    override fun string(name: String,order : UInt?, default: String?, maxLines: Int) {
//        pin { o, i ->
//            StringPin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//                value = default,
//                maxLines = maxLines
//            )
//        }
//    }
//
//    override fun long(name: String, order : UInt?, default : Long?) {
//        pin { o, i ->
//            LongPin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//                value = default
//            )
//        }
//    }
//
//    override fun double(name: String,order : UInt?) {
//        pin { o, i ->
//            DoublePin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//                value = 0.0
//            )
//        }
//    }
//
//    override fun bool(name: String, order: UInt?) {
//        pin { o, i ->
//            BooleanPin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//                value = false
//            )
//        }
//    }
//
//    override fun genericArray(name: String, order : UInt?) {
//        pin { o, i ->
//            GenericArrayPin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//            )
//        }
//    }
//
//    override fun stringArray(name: String, order : UInt?) {
//        pin { o, i ->
//            StringArrayPin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//            )
//        }
//    }
//
//    override fun longArray(name: String,order : UInt?) {
//        pin { o, i ->
//            LongArrayPin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//            )
//        }
//    }
//
//    override fun doubleArray(name: String,order : UInt?) {
//        pin { o, i ->
//            DoubleArrayPin(
//                id = Id.randomId(),
//                order = order ?: o,
//                elId = elementId,
//                name = name,
//                isOut = i,
//            )
//        }
//    }
//
    override fun pin(pin: (order: UInt, isOut: Boolean) -> Pin<*>) {
        pins += pin(if (!isOut) pins.size.toUInt() else (pins.size + 1000).toUInt(), isOut)
    }
//
//    override fun divider() {
//        pin { order, isOut ->
//            DividerPin(
//                id = Id.randomId(),
//                order = order,
//                elId = elementId
//            )
//        }
//    }
}