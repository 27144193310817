
package org.botdesigner.ui.screens.auth

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.spring
import androidx.compose.animation.expandVertically
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.shrinkVertically
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.focusable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsFocusedAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.WindowInsetsSides
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.only
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.ClickableText
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Visibility
import androidx.compose.material.icons.filled.VisibilityOff
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.FilledTonalButton
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Scaffold
import androidx.compose.material3.ScaffoldDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import io.github.alexzhirkevich.LocalContentColor
import io.github.alexzhirkevich.cupertino.CupertinoButton
import io.github.alexzhirkevich.cupertino.CupertinoButtonDefaults
import io.github.alexzhirkevich.cupertino.CupertinoButtonSize
import io.github.alexzhirkevich.cupertino.ExperimentalCupertinoApi
import io.github.alexzhirkevich.cupertino.ProvideTextStyle
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveCircularProgressIndicator
import io.github.alexzhirkevich.cupertino.adaptive.AdaptiveIconButton
import io.github.alexzhirkevich.cupertino.adaptive.ExperimentalAdaptiveApi
import io.github.alexzhirkevich.cupertino.theme.CupertinoTheme
import org.botdesigner.core.resources.images.Apple
import org.botdesigner.core.resources.images.Google
import org.botdesigner.core.resources.images.Logos
import org.botdesigner.resources.SharedRes
import org.botdesigner.shared.domain.auth.AuthComponent
import org.botdesigner.shared.domain.auth.AuthPage
import org.botdesigner.shared.domain.auth.signButtonEnabled
import org.botdesigner.ui.common.ClearFocusBox
import org.botdesigner.ui.common.NullableComposable
import org.botdesigner.ui.common.containerBackground
import org.botdesigner.ui.common.isImeVisible
import org.botdesigner.ui.screens.account.OauthSignProgressDialog
import org.botdesigner.ui.string
import org.botdesigner.ui.theme.Dimens
import org.botdesigner.ui.theme.PlatformClickable
import org.botdesigner.ui.theme.platformClickable

@OptIn(ExperimentalAdaptiveApi::class, ExperimentalCupertinoApi::class)
@Composable
internal fun AuthScreen(
    component : AuthComponent
) {

    val state by component.state.collectAsState()

    if (state.isOauthInProgress){
        OauthSignProgressDialog(onCancel = component::cancelAuth)
    }

    Scaffold(
        modifier = Modifier
            .fillMaxSize()
            .onPreviewKeyEvent {
                if (it.key == Key.Escape && state.page
                    != AuthPage.Login &&
                    state.page != AuthPage.VerifyEmail) {
                    component.onChangePageClicked()
                    true
                } else false
            }.focusable(true),
        containerColor = containerBackground(),
        contentWindowInsets = ScaffoldDefaults.contentWindowInsets.only(
            WindowInsetsSides.Top + WindowInsetsSides.Start + WindowInsetsSides.End
        )
    ) {
        ClearFocusBox(
            enabled = state.page != AuthPage.VerifyEmail,
            modifier = Modifier
                .imePadding()
                .fillMaxSize(),
            contentAlignment = Alignment.Center
        ) { focus ->

            if (!state.controlsEnabled && state.page != AuthPage.Login){
                AdaptiveCircularProgressIndicator()
            }

            Column(
                modifier = Modifier
                    .padding(horizontal = Dimens.Spacing.ExtraLarge)
                    .widthIn(max = 300.dp)
                    .align(Alignment.Center)
                    .verticalScroll(rememberScrollState())
//                    .height(IntrinsicSize.Max)
                    .padding(it),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
            ) {

                AnimatedContent(
                    targetState = state.page,
                    contentAlignment = Alignment.Center,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Text(
                        modifier = Modifier.fillMaxWidth(),
                        textAlign = TextAlign.Center,
                        text = string(
                            when (it) {
                                AuthPage.Register -> SharedRes.strings.create_account
                                AuthPage.Login -> SharedRes.strings.sign_in
                                AuthPage.VerifyEmail -> SharedRes.strings.verify_email
                                AuthPage.RestorePasswordEmail -> SharedRes.strings.restore_password
                                AuthPage.RestorePasswordConfirm -> SharedRes.strings.restore_password
                            }
                        ),
                        style = MaterialTheme.typography.titleLarge,
                        color = MaterialTheme.colorScheme.onSurface,
                        fontWeight = FontWeight.SemiBold
                    )
                }

                Spacer(Modifier.height(Dimens.Spacing.ExtraLarge))

                Column(
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    AnimatedVisibility(
                        visible = state.page == AuthPage.VerifyEmail
                    ){
                        val codeFocusRequester = remember {
                            FocusRequester()
                        }

                        LaunchedEffect(0) {
                            codeFocusRequester.requestFocus()
                        }

                        CodeTextField(
                            modifier = Modifier.focusRequester(codeFocusRequester),
                            value = state.code,
                            size = component.verificationCodeSize,
                            error = state.codeError?.let {
                                string(it)
                            },
                            onValueChange = component::onCodeChanged
                        )
                        Text(
                            string(
                                SharedRes.strings.email_sent_desc,
                                state.email
                            ),
                            style = MaterialTheme.typography.labelMedium,
                            color = MaterialTheme.colorScheme.secondary,
                            textAlign = TextAlign.Center
                        )
                        TextButton(
                            modifier = Modifier.fillMaxWidth(),
                            shape = MaterialTheme.shapes.medium,
                            onClick = component::onResendEmailClicked
                        ) {
                            Text(string(SharedRes.strings.send_again))
                        }
                    }
                    AnimatedVisibility(
                        visible = state.page == AuthPage.Register,
                    ) {
                        AuthTextField(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = Dimens.Spacing.Medium/2),
                            enabled = state.controlsEnabled,
                            value = state.name,
                            onValueChange = component::onNameChanged,
                            label = string(SharedRes.strings.name),
                            error = state.nameError?.let {
                                string(it)
                            },
                            keyboardType = KeyboardType.Email,
                            imeAction = ImeAction.Next
                        )
                    }
                    AnimatedVisibility(
                        visible = state.page != AuthPage.VerifyEmail && state.page != AuthPage.RestorePasswordConfirm
                    ) {
                        EmailTextField(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = Dimens.Spacing.Medium/2),
                            value = state.email,
                            onValueChange = component::onEmailChanged,
                            enabled = state.controlsEnabled,
                            error = state.emailError?.let {
                                string(it)
                            }
                        )
                    }
                    AnimatedVisibility(
                        visible = state.page == AuthPage.RestorePasswordConfirm
                    ) {
                        CodeTextField(
                            modifier = Modifier
                                .padding(vertical = Dimens.Spacing.Medium/2),
                            value = state.code,
                            size = component.verificationCodeSize,
                            error = state.codeError?.let {
                                string(it)
                            },
                            onValueChange = component::onCodeChanged
                        )
                    }
                    AnimatedVisibility(
                        visible = state.page != AuthPage.VerifyEmail && state.page != AuthPage.RestorePasswordEmail
                    ) {
                        PasswordTextField(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(top = Dimens.Spacing.Medium/2),
                            value = state.password,
                            isRestore = state.page == AuthPage.RestorePasswordConfirm,
                            onValueChange = component::onPasswordChanged,
                            enabled = state.controlsEnabled,
                            onImeDone = {
                                focus.clearFocus()
                                if (state.signButtonEnabled) {
                                    component.onSignClicked()
                                }
                            },
                            error = state.passwordError?.let {
                                string(it)
                            }
                        )
                    }
                }

                AnimatedVisibility(
                    visible = state.page == AuthPage.Login,
                    modifier = Modifier
                        .align(Alignment.End)
                ) {

                    PlainButton(
                        text = string(SharedRes.strings.forgot_password),
                        enabled = state.controlsEnabled,
                        onClick = component::onForgotPasswordClicked
                    )
                }

                Spacer(Modifier.height(Dimens.Spacing.ExtraLarge))

                SignInUpButton(
                    modifier = Modifier
                        .fillMaxWidth()
                        .pointerHoverIcon(PointerIcon.platformClickable(state.signButtonEnabled)),
                    enabled = state.signButtonEnabled,
                    page = state.page,
                    onClick = {
                        focus.clearFocus()
                        component.onSignClicked()
                    }
                )

                val density = LocalDensity.current
                val socialShrinkOffset = density.run { -32.dp.roundToPx() }
                AnimatedVisibility(
                    visible = state.page == AuthPage.Login &&
                            !WindowInsets.isImeVisible(),
                    modifier = Modifier.fillMaxWidth(),
                    enter = fadeIn() + expandVertically(initialHeight = { socialShrinkOffset }),
                    exit = fadeOut() + shrinkVertically(targetHeight = { socialShrinkOffset} ),
                ) {
                    Column(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {

                        SignMethodSeparator(
                            loading = !state.controlsEnabled,
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = Dimens.Spacing.Large)
                        )

                        ContinueInWithGoogleButton(
                            modifier = Modifier
                                .fillMaxWidth()
                                .pointerHoverIcon(PointerIcon.PlatformClickable),
                            enabled = state.controlsEnabled,
                            onClick = component::onSignInWithGoogleClicked
                        )

                        Spacer(Modifier.height(Dimens.Spacing.Medium))

                        ContinueWithAppleButton(
                            modifier = Modifier
                                .fillMaxWidth()
                                .pointerHoverIcon(PointerIcon.PlatformClickable),
                            enabled = state.controlsEnabled,
                            onClick = component::onSignInWithAppleClicked
                        )
                    }
                }

                Spacer(Modifier.height(Dimens.Spacing.ExtraLarge * 1.5f))

                GoToSignUpButton(
                    page = state.page,
                    enabled = state.controlsEnabled,
                    onClick = component::onChangePageClicked,
                    onPrivacyPolicyClick = {},
                    onTermsOfServiceClick = {}
                )
                Spacer(Modifier.height(Dimens.Spacing.ExtraLarge))
            }
        }
    }
}

private val ShakeAnimationSpec = spring<Float>(stiffness = Spring.StiffnessHigh * 2)


@OptIn(ExperimentalCupertinoApi::class)
@Composable
private fun PlainButton(
    text : String,
    enabled: Boolean,
    contentPadding : PaddingValues = CupertinoButtonSize.Small.contentPadding,
    onClick : () -> Unit
) {

    CupertinoButton(
        modifier = Modifier
            .pointerHoverIcon(PointerIcon.platformClickable(enabled)),
        onClick = onClick,
        enabled = enabled,
        size = CupertinoButtonSize.Small,
        contentPadding = contentPadding,
        colors = CupertinoButtonDefaults.borderlessButtonColors(
            contentColor = MaterialTheme.colorScheme.primary
        )
    ) {
        Text(
            text = text,
            style = MaterialTheme.typography.labelLarge,
        )
    }
}

@Composable
private fun CodeTextField(
    modifier: Modifier = Modifier,
    value: String,
    error : String?,
    size : Int,
    onValueChange: (String) -> Unit
) {

    val interactionSource = remember {
        MutableInteractionSource()
    }

    val focused by interactionSource.collectIsFocusedAsState()

    val shakeAnimatable = remember {
        Animatable(0f)
    }

    val shakeAnimation by shakeAnimatable.asState()

    LaunchedEffect(error != null) {
        if (error != null) {
            shakeAnimatable.animateTo(1f, animationSpec = ShakeAnimationSpec)
            shakeAnimatable.animateTo(-1f, animationSpec = ShakeAnimationSpec)
            shakeAnimatable.animateTo(1f, animationSpec = ShakeAnimationSpec)
            shakeAnimatable.animateTo(0f, animationSpec = ShakeAnimationSpec)
        } else {
            shakeAnimatable.animateTo(0f, animationSpec = ShakeAnimationSpec)
        }
    }


    Column(
        verticalArrangement = Arrangement.spacedBy(Dimens.Spacing.Small)
    ) {
        BasicTextField(
            modifier = modifier
                .offset {
                    if (error != null) {
                        IntOffset(
                            x = (5.dp.toPx() * shakeAnimation).toInt(),
                            y = 0
                        )
                    } else {
                        IntOffset.Zero
                    }
                },
            value = value,
            onValueChange = onValueChange,
            interactionSource = interactionSource
        ) {
            Row(
                horizontalArrangement = Arrangement
                    .spacedBy(Dimens.Spacing.Medium)
            ) {
                repeat(size) {

                    val selected = focused && it == value.lastIndex//tfValue.selection.start == it

                    Box(
                        modifier = Modifier
                            .width(35.dp)
                            .height(50.dp)
                            .border(
                                width = if (selected) 2.dp else 1.dp,
                                color = when {
                                    error != null -> MaterialTheme.colorScheme.error
                                    selected -> MaterialTheme.colorScheme.primary
                                    else -> MaterialTheme.colorScheme.secondary.copy(alpha = .5f)
                                },
                                shape = MaterialTheme.shapes.small
                            ),
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            text = value.getOrNull(it)?.toString().orEmpty(),
                            style = MaterialTheme.typography.bodyLarge
                        )
                    }
                }
            }
        }

        if (error != null) {
            Text(
                text = error,
                style = MaterialTheme.typography.labelMedium,
                color = MaterialTheme.colorScheme.error
            )
        }
    }
}

@Composable
private fun EmailTextField(
    modifier: Modifier,
    enabled: Boolean,
    error: String?,
    value: String,
    onValueChange: (String) -> Unit
) = AuthTextField(
    modifier = modifier,
    enabled = enabled,
    value = value,
    onValueChange = onValueChange,
    label = string(SharedRes.strings.email),
    error = error,
    keyboardType = KeyboardType.Email,
    imeAction = ImeAction.Next
)

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
private fun PasswordTextField(
    modifier: Modifier,
    isRestore : Boolean,
    enabled: Boolean,
    error: String?,
    value: String,
    onImeDone : () -> Unit,
    onValueChange: (String) -> Unit
) {

    var passwordVisible by rememberSaveable {
        mutableStateOf(false)
    }


    AuthTextField(
        modifier = modifier,
        enabled = enabled,
        value = value,
        onValueChange = onValueChange,
        label = string(
            if (isRestore)
                SharedRes.strings.new_password
            else
                SharedRes.strings.password),
        trailingIcon = {
            EyeButton(
                visible = passwordVisible,
                onClick = {
                    passwordVisible = !passwordVisible
                }
            )
        },
        error = error,
        keyboardType = KeyboardType.Password,
        imeAction = ImeAction.Done,
        keyboardActions = KeyboardActions {
            onImeDone()
        },
        visualTransformation = if (passwordVisible)
            VisualTransformation.None
        else remember { PasswordVisualTransformation() }
    )
}

@OptIn(ExperimentalAdaptiveApi::class)
@Composable
fun EyeButton(
    visible : Boolean,
    onClick : () -> Unit
) {
    AdaptiveIconButton(
        modifier = Modifier.pointerHoverIcon(PointerIcon.PlatformClickable),
        onClick = onClick
    ) {
        Icon(
            imageVector = if (visible)
                Icons.Default.VisibilityOff else Icons.Default.Visibility,
            contentDescription = null,
            tint = MaterialTheme.colorScheme.onSurfaceVariant
        )
    }
}

@Composable
fun SignInUpButton(
    modifier: Modifier,
    enabled: Boolean,
    page: AuthPage,
    onClick: () -> Unit
) {
    Button(
        modifier = modifier,
        enabled = enabled,
        onClick = onClick,
    ) {
        AnimatedContent(page) {
            Text(
                text = string(
                    when (page){
                        AuthPage.Register -> SharedRes.strings.sign_up
                        AuthPage.Login -> SharedRes.strings.sign_in
                        AuthPage.VerifyEmail -> SharedRes.strings.confirm
                        AuthPage.RestorePasswordEmail -> SharedRes.strings.action_continue
                        AuthPage.RestorePasswordConfirm -> SharedRes.strings.change_password
                    },
                ),
                modifier = Modifier.padding(
                    horizontal = 36.dp,
                    vertical = Dimens.Spacing.ExtraSmall
                ),
                style = MaterialTheme.typography.bodyLarge
            )
        }
    }
}

@Composable
private fun SignMethodSeparator(
    loading : Boolean,
    modifier: Modifier = Modifier
) {
    if (!loading) {
        Row(
            modifier = modifier,
            verticalAlignment = Alignment.CenterVertically
        ) {
            HorizontalDivider(
                modifier = Modifier.weight(1f),
                thickness = 2.dp
            )


            Text(
                text = string(SharedRes.strings.or_lowercase),
                color = MaterialTheme.colorScheme.onSurface,
                modifier = Modifier
                    .padding(horizontal = Dimens.Spacing.Large)
            )

            HorizontalDivider(
                modifier = Modifier.weight(1f),
                thickness = 2.dp
            )
        }
    } else {
        Box(
            modifier = modifier,
            contentAlignment = Alignment.Center
        ) {
            LinearProgressIndicator(Modifier.fillMaxWidth().height(2.dp))

            Text(
                text = string(SharedRes.strings.or_lowercase),
                modifier = Modifier
                    .background(containerBackground())
                    .padding(horizontal = Dimens.Spacing.Large)
            )
        }
    }
}

@Composable
private fun ContinueInWithGoogleButton(
    modifier : Modifier = Modifier,
    enabled: Boolean = true,
    onClick: () -> Unit
) {
    FilledTonalButton(
        modifier = modifier,
        enabled = enabled,
        shape = MaterialTheme.shapes.medium,
        onClick = onClick,
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                imageVector = Logos.Google,
                contentDescription = null,
                modifier = Modifier.size(24.dp)
            )

            Spacer(Modifier.width(Dimens.Spacing.Small))
            Text(string(SharedRes.strings.sign_in_with_google))
        }
    }
}

@Composable
private fun ContinueWithAppleButton(
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    onClick: () -> Unit
) {
    Button(
        modifier = modifier,
        enabled = enabled,
        shape = MaterialTheme.shapes.medium,
        onClick = onClick,
        colors = ButtonDefaults.buttonColors(
            containerColor = CupertinoTheme.colorScheme.label,
            contentColor = CupertinoTheme.colorScheme.systemBackground
        )
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Icon(
                imageVector = Logos.Apple,
                contentDescription = null,
                tint = LocalContentColor.current,
                modifier = Modifier.size(24.dp)
            )

            Spacer(Modifier.width(Dimens.Spacing.Small))
            Text(string(SharedRes.strings.sign_in_with_apple))
        }
    }
}

@OptIn(ExperimentalCupertinoApi::class)
@Composable
private fun GoToSignUpButton(
    page: AuthPage,
    enabled : Boolean,
    onPrivacyPolicyClick : () -> Unit,
    onTermsOfServiceClick : () -> Unit,
    onClick : () -> Unit
) {
    ProvideTextStyle(MaterialTheme.typography.labelLarge) {

        AnimatedContent(
            targetState = page,
            contentAlignment = Alignment.Center,
        ) { page ->
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement
                    .spacedBy(Dimens.Spacing.Large)
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                ) {

                    if (page == AuthPage.Login) {
                        Text(
                            text = string(SharedRes.strings.dont_have_an_account) + " ",
                            color = MaterialTheme.colorScheme.onSurface
                        )
                    }

                    val text = string(
                        if (page == AuthPage.Login)
                            SharedRes.strings.sign_up
                        else SharedRes.strings.back_to_sign_in
                    )

                    PlainButton(
                        text = text,
                        enabled = enabled,
                        contentPadding = PaddingValues(
                            horizontal = Dimens.Spacing.Small,
                            vertical = Dimens.Spacing.ExtraSmall,
                        ),
                        onClick = onClick
                    )
                }

                if (page == AuthPage.Login || page == AuthPage.Register) {
                    TermsOfService(
                        onPrivacyPolicyClick = onPrivacyPolicyClick,
                        onTermsOfServiceClick = onTermsOfServiceClick
                    )
                }
            }
        }
    }
}

@Composable
private fun TermsOfService(
    onPrivacyPolicyClick : () -> Unit,
    onTermsOfServiceClick : () -> Unit,
) {
    val fullText =
        string(SharedRes.strings.sign_up_terms_and_privacy)

    val terms = string(SharedRes.strings.terms_of_service)
    val privacy = string(SharedRes.strings.privacy_policy)

    val termsRange = fullText.indexOf(terms).let {
        it..it+terms.length
    }

    val policyRange = fullText.indexOf(privacy).let {
        it..it+privacy.length
    }

    val span = SpanStyle(
        color = MaterialTheme.colorScheme.primary,
//        fontWeight = FontWeight.SemiBold
    )

    ClickableText(
        text = AnnotatedString(
            text = fullText,
            spanStyles = listOf(
                AnnotatedString.Range(span, termsRange.first, termsRange.last),
                AnnotatedString.Range(span, policyRange.first, policyRange.last),
            )
        ),
        style = LocalTextStyle.current.copy(
            color = MaterialTheme.colorScheme.secondary,
            textAlign = TextAlign.Center
        ),
        onClick = {
            if (it in termsRange) {
                onTermsOfServiceClick()
            } else if (it in policyRange) {
                onPrivacyPolicyClick()
            }
        }
    )
}

@Composable
private fun AuthTextField(
    modifier: Modifier,
    enabled: Boolean,
    value: String,
    onValueChange: (String) -> Unit,
    label: String,
    trailingIcon: @Composable () -> Unit = {},
    error: String? = null,
    keyboardType: KeyboardType = KeyboardType.Email,
    imeAction: ImeAction = ImeAction.Next,
    keyboardActions: KeyboardActions = KeyboardActions.Default,
    visualTransformation: VisualTransformation = VisualTransformation.None
) {
    OutlinedTextField(
        modifier = modifier,
        value = value,
        onValueChange = onValueChange,
        isError = error != null,
        enabled = enabled,
        visualTransformation = visualTransformation,
        keyboardActions = keyboardActions,
        shape = MaterialTheme.shapes.medium,
        singleLine = true,
        maxLines = 1,
        keyboardOptions = KeyboardOptions(
            keyboardType = keyboardType,
            imeAction = imeAction
        ),
        label = {
            Text(label)
        },
        trailingIcon = trailingIcon,
        supportingText = NullableComposable(error) {
            Text(it)
        }
    )
}
