package org.botdesigner.blueprint.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.vector.ImageVector
import org.botdesigner.blueprint.AbstractBlueprintNodeStateHolder
import org.botdesigner.blueprint.BlueprintManager
import org.botdesigner.blueprint.components.functions.BpFunction
import org.botdesigner.blueprint.ui.components.Function


/**
 * Non-callable blueprint element that only calculates value of output pins.
 * Can be initialized before blueprint start.
 *
 * Should not be inherited directly. Use [BpFunction] instead
 * */
interface BlueprintFunction : BlueprintNode {

    val icon : ImageVector get() = BlueprintIcons.Function

    val color : Color get() = BlueprintColors.Variable

    val pinTypeDerivation : Map<Id, Pair<Id,Boolean>> get() = emptyMap()


    fun StringBuilder.script(){
        append("TODO(Not implemented)")
    }
}

val BlueprintFunction.outputPins : List<Pin<*>>
    get() = pins.filter { it.isOut && !it.isUtil }.sortedBy(Ordered::order)

val BlueprintFunction.inputPins : List<Pin<*>>
    get() = pins.filter { !it.isOut && !it.isUtil }.sortedBy(Ordered::order)

fun <T : BlueprintFunction > T.BlueprintFunctionStateHolder(
    manager: BlueprintManager,
    copy : (Point, List<Pin<*>>, List<Connector>) -> T
): BlueprintNodeStateHolder<T> = object : BlueprintFunctionStateHolder<T>(
    element = this,
    manager = manager
) {
    override fun save() = copy(position.value, pins, connectors)
}

abstract class BlueprintFunctionStateHolder<T: BlueprintFunction>(
    element : T,
    manager: BlueprintManager
) : AbstractBlueprintNodeStateHolder<T>(
    element = element,
    manager = manager
) {

    override fun updatePin(pin: Pin<*>) {
        super.updatePin(pin)

        val refs = element.pinTypeDerivation.filter { it.value.first == pin.id }

        refs.forEach { (k, v) ->

            val currentElement = manager.getElementById(pin.elId)
            val pinToDerive = currentElement.pins.firstOrNull { it.id == k } ?: return

            val newPin = pin.parentId?.let {
                val parent = manager.getElementById(it)

                val parentPin = parent.pins.firstOrNull {
                    it.id == pin.parentPinId
                } ?: return@let pinToDerive

                if (v.second && parentPin is ArrayPin<*>) {
                    parentPin.childPinFactory.create(
                        id = pinToDerive.id,
                        order = pinToDerive.order,
                        elementId = pinToDerive.elId,
                        name = pinToDerive.name,
                        isOut = pinToDerive.isOut
                    )
                } else {
                    parentPin.factory(manager).create(
                        id = pinToDerive.id,
                        order = pinToDerive.order,
                        elementId = pinToDerive.elId,
                        name = pinToDerive.name,
                        isOut = pinToDerive.isOut
                    )
                }
            } ?: GenericPinFactory.create(
                id = pinToDerive.id,
                order = pinToDerive.order,
                elementId = pinToDerive.elId,
                name = pinToDerive.name,
                isOut = pinToDerive.isOut
            )

            manager.resetPin(pinToDerive)
            super.updatePin(newPin)
        }
    }

    @Composable
    override fun Draw(
        modifier: Modifier,
        fieldSize : Float,
        shape: Shape,
    ) {
        Function(
            modifier = modifier,
            color = element.color,
            name = element.name,
            manager = manager,
            icon = element.icon,
            shape = shape,
            fieldSize = fieldSize
        )
    }
}