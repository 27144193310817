package org.botdesigner.api.auth

import org.botdesigner.api.ErrorCode

enum class LoginErrorCode(
    override val isSuccess : Boolean = false
) : ErrorCode {
    Success(true),
    InvalidCredentials,
    CredentialsInUse,
    InvalidToken,
    UnknownError
}